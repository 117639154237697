import {
    ADD_ZONE,
    CHANGE_ZONE_PROPERTY,
    COPY_ZONE,
    DELETE_ZONE,
    LOAD_ZONES,
    PUBLISH_ZONES,
    SAVE_ZONES,
    SELECT_ZONE,
    TOGGLE_DRAW_ZONE,
    TOGGLE_MODIFY_ZONE,
    TOGGLE_MOVE_ZONE,
    UPDATE_ZONE
} from '../constants/ActionTypes';

const initialState = {
    zoneId: null,

    drawingZone: false,
    modifyingZone: false,
    movingZones: false,

    zones: {},
    clipboardZone:null,

    published: false,
    dirty: false
};

function zone(state,action){
    let _state = {...state};
    delete _state.geojson;

    switch(action.type){

        case ADD_ZONE:
            return action.zone;

        case UPDATE_ZONE:
            return {
                ..._state,
                ...action.updates
            };
        default:
            return state;
    }
}

export function zoneBuilder(state=initialState,action){

    switch(action.type){

        case LOAD_ZONES:
            return {
                ...state,
                zones: action.zones.reduce((o,z)=>{
                    o[z._id] = z;
                    return o;
                },{})
            };

        case CHANGE_ZONE_PROPERTY:
            return {
                ...state,
                zones:{
                    ...state.zones,
                    [state.zoneId]:{
                        ...state.zones[state.zoneId],
                        [action.key]:action.value
                    }
                },                
                dirty: true,
                published: false
            };

        case SELECT_ZONE:
            return {
                ...state,
                zoneId: action.zone._id
            };

        case TOGGLE_DRAW_ZONE:
            return {
                ...state,
                drawingZone: !state.drawingZone
            };

        case TOGGLE_MODIFY_ZONE:
            return {
                ...state,
                modifyingZone: !state.modifyingZone
            };

        case TOGGLE_MOVE_ZONE:
            return {
                ...state,
                movingZones: !state.movingZones
            };

        case ADD_ZONE:
            return {
                ...state,
                zones: {
                    ...state.zones,
                    [action.zone._id]: zone(undefined,action)
                },
                zoneId: action.zone._id,
                dirty: true,
                published: false
            };

        case UPDATE_ZONE:
            return {
                ...state,
                zones: {
                    ...state.zones,
                    [action.zoneId]:zone(state.zones[action.zoneId],action)
                },
                dirty: true,
                published: false
            };

        case DELETE_ZONE:
            return {
                ...state,
                zones: (
                    (o)=>{
                        let ret = {...o};
                        delete ret[action.zoneId];
                        return ret;
                    }
                )(state.zones),
                dirty: true,
                published: false
            };

        case COPY_ZONE:
            return {
                ...state,
                clipboardZone: state.zones[state.zoneId]
            };

        case SAVE_ZONES:
            console.log("ZONES SAVED");
            return {
                ...state,
                published: false,
                dirty: false
            };

        case PUBLISH_ZONES:
            console.log("ZONES PUBLISHED");
            return {
                ...state,
                published: true,
                dirty: false
            };
        default:
            return state;
    }

}
