import React from 'react';
import PropTypes from 'prop-types';

import Chart from 'chart.js';
import {MOCK_CHART_DATA} from '../../../utils/MockData';

function generateDataset(raw){

    const {label,color,data} = raw;

    return {
        label,
        //fill: false,
        lineTension: 0.1,
        backgroundColor: color,//"rgba(75,192,192,0.4)",
        borderColor: color,//"rgba(75,192,192,1)",
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: color,//"rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: color,//"rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data,//: [65, 59, 80, 81, 56, 55, 40],
        spanGaps: false,
    };
}



const MOCK_CHART_DATASETS = MOCK_CHART_DATA.map(generateDataset);

const MOCK_CHART_OBJECT = {
    type: 'line',
    data: {
        labels: ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"],
        datasets: MOCK_CHART_DATASETS.reverse()
    },
    options: {

        legend:{
            position:'right',
            //reverse:true
            labels:{
                boxWidth:20
            }
        },

        scales: {
            yAxes: [{
                stacked:true,
                ticks: {
                    beginAtZero:true
                }
            }]
        }
    }
};

export default class ZChart extends React.Component{

    static get propTypes(){
        return {
            chartObject: PropTypes.object
        };
    }

    static get defaultProps(){
        return {
            chartObject: MOCK_CHART_OBJECT
        };
    }

    constructor(){
        super();
        this.handleChartClick = this.handleChartClick.bind(this);
    }

    componentDidMount(){
        this.initializeChart();
    }

    componentDidUpdate(){
        this.initializeChart();
    }

    initializeChart(){
        if(this.chart) this.chart.destroy(); // needs to be called before reusing canvas component.

        this.width = this.refs.container.offsetWidth;
        this.height = this.refs.container.offsetHeight;

        this.context = this.refs.canvas.getContext('2d');
        this.context.canvas.width = this.width;
        this.context.canvas.height = this.height;


        this.chart = new Chart(this.context,{
            ...this.props.chartObject,
            options:{
                ...this.props.chartObject.options,
                responsive: true,
                maintainAspectRatio:false
            }
        });
    }

    render(){

        return (
            <div className="filled-up" style={{padding:'1em 2em',position:'relative',boxSizing:'border-box'}}>
                <div ref="container" className="filled-up">
                    <canvas ref="canvas" onClick={this.handleChartClick}/>
                </div>
            </div>
        );
    }

    handleChartClick(evt) {
        const chart = this.chart;
        var item = chart.getElementAtEvent(evt)[0];

        if (item) {
            var label = chart.data.labels[item._index];
            var value = chart.data.datasets[item._datasetIndex].data[item._index];
            console.log({label,value,item}); // eslint-disable-line
        }
    }
}
