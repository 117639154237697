import React from 'react';
import OLMap from 'ol/Map';
import Tile from 'ol/layer/Tile';
import View from 'ol/View';
import OSM from 'ol/source/OSM';
import Overlay from 'ol/Overlay';
import {fromLonLat} from 'ol/proj';
import Feature from "ol/Feature";
import {Point} from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import {Style, Icon, Stroke, Fill} from 'ol/style'
import {Translate   } from 'ol/interaction';
import Collection from 'ol/Collection';




const SiteMapDevices = () => {





    let newLayer = new Tile({
       source: new OSM({
           // url: "assets/images/Selection_Map.png"
           url: "https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png"
       })
    });

    let pointSource = new VectorSource({updateWhileInteracting: true});

    const pointLayer = new VectorLayer({
        /*source: new VectorSource({
            features: [pointFeature]
        }),*/
        source: pointSource,
        style: new Style({
            image: new Icon({
                anchor: [0.5, 46],
                anchorXUnits: 'fraction',
                anchorYUnits: 'pixels',
                opacity: 0.95,
                src: 'assets/images/icon.png'
            })
        }),
        stroke: new Stroke({
            width: 3,
            color: [255, 0, 0, 1]
        }),
        fill: new Fill({
            color: [0, 0, 255, 0.6]
        })
    });


    let mapLayer = new OLMap({
        layers: [
            newLayer,
            pointLayer
        ],
        controls: [],
        target: 'map',
        view: new View({
            center: fromLonLat([4.666389, 50.009167]),
            zoom: 4,
            minZoom: 1,
            maxZoom: 20
        })
    });


    mapLayer.on('click', (evt) =>{
        let translate = new Translate();
        mapLayer.addInteraction(translate);
    });

    /*let translate1 = new Translate({
        features: new Collection([pointFeature])
    });

    mapLayer.addInteraction(translate1);*/

    mapLayer.on('pointermove',(e) => {
        // console.log("MapEvt: ", e.dragging);
        if (e.dragging) return;
        let hit = mapLayer.hasFeatureAtPixel(mapLayer.getEventPixel(e.originalEvent));
        mapLayer.getTargetElement().style.cursor = hit ? 'pointer' : '';
    });

    let menuItems = [
        {name: "RFID_1", x: 22, y: 22},
        {name: "RFID_2", x: 22, y: 22},
        {name: "RFID_3", x: 22, y: 22},
        {name: "RFID_4", x: 22, y: 22},
        {name: "RFID_5", x: 22, y: 22},
        {name: "RFID_6", x: 22, y: 22},
        {name: "RFID_7", x: 22, y: 22},
    ];

    const menuDevices = document.createElement('div');
    menuDevices.className = 'menu-devices-container';
    menuDevices.id = 'menu-devices-container';
    for(let i=0; i<menuItems.length;i++) {
        const menuItem = document.createElement('div');
        menuItem.className = 'menu-devices-item';
        menuItem.textContent = menuItems[i].name;
        menuItem.key = i;
        let translate;
        menuItem.addEventListener('mousedown', (evt)=>{
            /*console.log("Listener draw: ", draw);
            if(!draw){
                addInteraction();
            }*/
            console.log("Evt: ", );
            /*let buttonFeature = mapLayer.getFeaturesAtPixel(evt.pixel)[0];
            if(buttonFeature) {*/
                let coordinate = mapLayer.getEventCoordinate(evt);
                // console.log("coordinate: ", coordinate);

                let pointerFeature = new Feature(
                    new Point(coordinate)
                );
                // console.log("AddPointerFeature: ", pointerFeature);
                pointSource.addFeature(pointerFeature);
                translate = new Translate({
                    features: new Collection([pointerFeature])
                });
                mapLayer.addInteraction(translate);

               /* menuItem.addEventListener('pointermove', (evt1) => {
                    /!*if (evt1.dragging) return;
                    let hit = pointerFeature;
                    mapLayer.getTargetElement().style.cursor = hit ? 'pointer' : '';*!/
                    let newCoordinates = mapLayer.getEventCoordinate(evt1);
                    console.log("NewCoordinates: ", newCoordinates);
                    pointerFeature.setGeometry(new Point(newCoordinates));
                },false);*/


            // }
        }, false);

        menuItem.addEventListener('pointermove', (evt) => {
            console.log("Evt: ", evt.dragging);
            if (evt.dragging) return;
            /*let hit = mapLayer.hasFeatureAtPixel(mapLayer.getEventPixel(evt.originalEvent));
            mapLayer.getTargetElement().style.cursor = hit ? 'pointer' : '';*/
        });

        /*menuItem.addEventListener('mouseup', evt => {
            console.log("Drop")
        },false);*/
        menuDevices.appendChild(menuItem);
    }

    const menuContainer = new Overlay({
        element: menuDevices,
        stopEvent: true,
        dragging: false,
        className: 'menu-devices-main-container'
    });

    mapLayer.addOverlay(menuContainer);

    return (
        <div style={{flexGrow: 70}}>
            <div id={"map"} />
        </div>
    )
};

export default SiteMapDevices;