import React, {useState, useEffect} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Button} from '@material-ui/core';
import { NavigateBefore as BackArrow} from '@material-ui/icons';
import { useSelector, useDispatch } from "react-redux";
import {
    addTemplate, updateTemplate, cleanFormTemplate, initValidatedFields, loadFormTemplate,
    cleanLinkedDevices, loadDeviceManagerScreen, validateFieldsTemplate, disableNotification, detectSaveChanges
} from "../../../actions";
// import {validateFields} from "../../../actions";
import TemplateManagerToolbar from '../toolbars/TemplateManagerToolbar';
import FetchScreen from '../common/FetchScreen';
import TemplateSidebar from './TemplateSidebar';
import Accordion from '../common/Accordion';
// import ErrorValidation from '../common/accordionItems/ErrorValidation';
import EditTemplateHeader from '../common/accordionItems/EditTemplateHeader';
import DeviceTypePanel from '../common/accordionItems/DeviceTypePanel';
import OperationPanel from '../common/accordionItems/OperationPanel';
import AntennasPanel from '../common/accordionItems/AntennasPanel';
import TriggersPanel from '../common/accordionItems/TriggersPanel';
import DataUrlPanel from '../common/accordionItems/DataUrlPanel';
import ProtocolPanel from '../common/accordionItems/ProtocolPanel';

import GPOPanel from '../common/accordionItems/GPOPanel';
import LinkedDevicesPanel from '../common/accordionItems/LinkedDevicesPanel';
import DialogSaveChanges from '../common/dialogs/DialogSaveChanges';
import DialogAddTemplate from '../common/dialogs/DialogAddTemplate';
import DialogConfirm from '../common/dialogs/DialogConfirm';
import {ADD_TEMPLATE, EDIT_TEMPLATE, PATH_ADD_TEMPLATE, PATH_EDIT_TEMPLATE, ADD_TEMPLATE_SCREEN, EDIT_TEMPLATE_SCREEN} from '../../../constants/DeviceManager';
import {CONF_R2C} from '../../../constants/DeviceManager';

const FormTemplate = props => {
    //from store
    const isFetching = useSelector(state => state.deviceManager.isFetching);
    const templateFormLoaded = useSelector(state => state.deviceManager.templateFormLoaded);
    const deviceType = useSelector(state => state.deviceManager.templateForm.deviceType);
    const successful = useSelector(state => state.deviceManager.successfulValidatedFields);
    const rfidConfigurationType = useSelector(state => state.deviceManager.rfidConfigurationType);
    const canEdit = useSelector(state => state.user.permissions['edit-infrastructure-devices']);
    // const templateMsgError = useSelector(state => state.deviceManager.templateMsgError);
    const focusedSection = useSelector(state => state.deviceManager.focusedSection);

    const [dialog, setDialog] = useState(null);
    const [save, setSave] = useState(null);
    const {pathParam} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const pathTemplateList = '/device-manager/templates';

    const scrollView = id => {
        const elmntToView = document.getElementById(id);
        if (elmntToView)
            elmntToView.scrollIntoView({behavior: 'smooth'});
    }

    useEffect(() => {
        if(focusedSection){
            scrollView(focusedSection);
        }
    }, [focusedSection]);

    const loadTemplate = () => {
        //mounting
        if(pathParam){
            dispatch(loadDeviceManagerScreen(EDIT_TEMPLATE_SCREEN));
            dispatch(loadFormTemplate(EDIT_TEMPLATE, pathParam));
        }else{
            dispatch(loadDeviceManagerScreen(ADD_TEMPLATE_SCREEN));
            dispatch(loadFormTemplate(ADD_TEMPLATE));
        }
        
        const unmount = () => {
            //unmounting
            dispatch(cleanFormTemplate());
            dispatch(initValidatedFields());
            if(cleanLinkedDevices === EDIT_TEMPLATE){
                dispatch(cleanLinkedDevices());
            }
        }
        return unmount;
    }

    useEffect(loadTemplate, [pathParam]);

    useEffect(() => {
        if(canEdit !== undefined && canEdit === false){
            history.replace('/device-manager/templates');
        }
    }, [canEdit, history]);

    const closeDialog = () => {
        dispatch(initValidatedFields());
        setDialog(null);
    }

    const handleAddTemplate = () => {
        //closeDialog();
        dispatch(disableNotification());
        dispatch(addTemplate(history));
    }

    const handleUpdateTemplate = () => {
        closeDialog();
        dispatch(updateTemplate(history));
    }

    const savingFields = (type) => {
        dispatch(validateFieldsTemplate());
        setSave(type);
        // setWasChecked(true);
    };

    const handleReturnBack = () => {
        dispatch(cleanFormTemplate());
        dispatch(initValidatedFields());
        dispatch(detectSaveChanges(false));
        history.push(pathTemplateList);
    }

    // save device
    const saveDevice = () => {
        if(successful && save){
            switch(save){
                case 'new-template':
                    setDialog(save);
                    break;
                case 'edit-template':
                    setDialog(save);
                    break;
                default:
                    break;
            }
        }

        if(!successful && save){
            // scrollView('errorValidation');
            setSave(null);
        }
    }

    useEffect(saveDevice, [successful, save]);

    return (
        <div className={"device-manager-container"}>
            <div className={"device-manager-header"}>
                <TemplateManagerToolbar
                    location={pathParam ? PATH_EDIT_TEMPLATE: PATH_ADD_TEMPLATE}
                />
            </div>
            <div className={"device-manager-content"} style={{overflowY: 'auto'}}>
                <div style={{display: 'flex', height: '100%'}}>
                    <div className={"template-sidebar"}>
                        <TemplateSidebar
                            scrollView={(e) => scrollView(e)} 
                            deviceId={deviceType}
                            />
                    </div>
                    <div className={"template-container"} id={'mainContainer'}>
                         {/*{!successful && wasChecked && <ErrorValidation type={'template'} message={templateMsgError}/>}*/}
                        {templateFormLoaded === EDIT_TEMPLATE && <EditTemplateHeader />}
                            
                        <Accordion displayName={"Device Type"} id={"type"}> 
                            <DeviceTypePanel />
                        </Accordion>
                        {deviceType === "RFID Reader" &&
                        <React.Fragment>
                            <Accordion displayName={"Reader Management Protocol"} id={"protocol"}>
                                <ProtocolPanel />
                            </Accordion>

                            <Accordion displayName={"Data URL"} id={"dataURL"}>
                                <DataUrlPanel />
                            </Accordion>

                            <Accordion displayName={"Operation"} id={"operation"}> 
                                <OperationPanel />
                            </Accordion>

                            <Accordion displayName={"Antennas"} id={"rfidAntennas"}> 
                                <AntennasPanel isTemplate={true} />
                            </Accordion>

                            {rfidConfigurationType !== CONF_R2C && <React.Fragment>
                                <Accordion displayName={"Trigger settings"} id={"triggers"}> 
                                    <TriggersPanel />
                                </Accordion>

                                <Accordion displayName={"GPO (General Purpose Output) Programming"} id={"gpo"}> 
                                    <GPOPanel />
                                </Accordion>
                            </React.Fragment>}

                            {templateFormLoaded === EDIT_TEMPLATE &&
                                <Accordion displayName={"Linked Devices"} id={"linkedDevices"}> 
                                    <LinkedDevicesPanel showWarning={true} checkColumn={true}/>
                                </Accordion>
                            }
                        </React.Fragment>}
                    </div>
                </div>
                <ContainerButtonsTemplate templateFormLoaded={templateFormLoaded} savingFields={savingFields} handleReturnBack={handleReturnBack} />

                {(dialog==='new-template') && 
                    <DialogAddTemplate 
                        openDialog={Boolean(dialog==='new-template')}
                        closeDialog={closeDialog}
                        actionSuccess={handleAddTemplate} />}    

                {(dialog==='edit-template') &&
                    <DialogSaveChanges 
                        openDialog={Boolean(dialog==='edit-template')}
                        closeDialog={closeDialog}
                        actionSuccess={handleUpdateTemplate} />}
                
                {isFetching? <FetchScreen />: null}
            </div>
        </div>
    )
}

export default FormTemplate;

const ContainerButtonsTemplate = props => {
    const {templateFormLoaded, savingFields, handleReturnBack} = props;
    const isDeviceChanging = useSelector(state => state.deviceManager.isDeviceChanging);
    const [showMessage, setShowMessage] = useState(false);

    
    const handleBackButton = () => {
        if(isDeviceChanging){
            setShowMessage(true);
        }else{
            handleReturnBack();
        }
    }

    return (
        <React.Fragment>
            <div className="template-bottom-popper">
                <div className="template-bottom-popper-content">
                    <div className="text-backwards" onClick={handleBackButton}>
                        <BackArrow style={{position: 'absolute', top: 14}}/> 
                        <span style={{marginLeft: 20}}>Back</span>
                    </div>
                    <div className="bottom-container-buttons">
                        {templateFormLoaded === ADD_TEMPLATE &&
                            <Button className="bottom-template-button" style={{backgroundColor:'#007CB0',color:'#fff'}}
                            onClick={() => savingFields('new-template')}>Create Template</Button>
                        }
                        {templateFormLoaded === EDIT_TEMPLATE &&
                            <React.Fragment>
                                <Button className="bottom-template-button" style={{backgroundColor:'#4c4c4c',color:'#fff',marginRight:'1em'}}
                                    onClick={e=>savingFields('new-template')}>Save as new Template</Button>
                                <Button className="bottom-template-button" style={{backgroundColor:'#007CB0',color:'#fff'}}
                                    onClick={e=>savingFields('edit-template')}>Save Changes</Button>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>

            {showMessage && <DialogConfirm 
                open={showMessage}
                closeDialog={() => setShowMessage(false)}
                actionSuccess={handleReturnBack}
                actionCancel={()=>{}}
                message={'There are changes not saved. Are you sure to leave this page?'}
            />}
        </React.Fragment>
    )
}