import React from 'react';
import PropTypes from 'prop-types';
import {Tab, Tabs} from '@material-ui/core';
import {ZONE_BUILDER_ENABLED} from '../../constants/Misc';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../actions';
import ConfigureMapCoordinates from "./mapCoordinates/ConfigureMapCoordinates";
import OlMapContainer from "../../utils/ol/OlMapContainer";


const SITE_MANAGER_TABS = {
    MAP: 'map',
    DEVICES:'devices',
    ZONES:'zones'
};

// const CHARACTER_MIDDOT = String.fromCharCode(183);

const ABSOLUTE_FIT = {position:'absolute',top:0,bottom:0,left:0,right:0};

// function indexByAttribute(arr,attribute){ // assumes attribute is a key.
//     return arr.reduce((obj,entry)=>{
//         obj[entry[attribute]] = entry;
//         return obj;
//     },{});
// }

class SitesMapPanelInfrastucture extends React.Component{

    static get propTypes(){
        return {
            actions: PropTypes.object.isRequired,
            sites: PropTypes.object,
            maps: PropTypes.object,

            selectedFloorMap: PropTypes.any,
            resizeTrigger: PropTypes.any,
            hoverUID: PropTypes.any,

            onHoverSite: PropTypes.func,

            currentMap: PropTypes.any
        };
    }

    static get defaultProps(){
        return {
            onHoverSite: ()=>{},
            currentMap: "World"
        };
    }

    constructor(){
        super();
        this.state = {
            tab: SITE_MANAGER_TABS.MAP
        };
        this.handleChangeTab = this.handleChangeTab.bind(this);
    }

    render(){

        const {currentMap
            // ,selectedFloorMap
        } = this.props;
        let tabs = [
            {
                label:"Map",
                value: SITE_MANAGER_TABS.MAP,
                content: this.renderMap()
            }
        ];

        // Since Zone Builder needs to be checked into CVS in order to get built
        // but the customer shouldn't see it until its complete, it needs to be
        // toggled.
        //
        // Frankly, pulling from a different branch in Git is preferable.
        //
        if(ZONE_BUILDER_ENABLED){
            tabs = tabs.concat([
                {
                    label:"Edit Devices",
                    value: SITE_MANAGER_TABS.DEVICES,
                    content: this.renderDeviceEditor()
                },
                {
                    label:"Edit Zones",
                    value: SITE_MANAGER_TABS.ZONES,
                    content: this.renderZoneEditor()
                }
            ]);
        }

        const mapTabs = tabs.map(({label,value})=>(
            <Tab
                style={{color:'white',padding:'0 2em'}}
                label={label}
                value={value}
                key={value}
            />
        ));

        const selectedTab = tabs.find(t=>t.value===this.state.tab);

        return (
            <div style={ABSOLUTE_FIT}>
                <div className="map-tabs-container">
                    <Tabs
                        style={{width:'auto',display:'inline-block',color: '#fff'}}
                        TabIndicatorProps={{style: {background:'black'}}}
                        onChange={this.handleChangeTab}
                        value={selectedTab.value}
                        className={'site-manager-tab'}
                    >
                        {mapTabs}
                    </Tabs>
                    <div style={{...ABSOLUTE_FIT,left:'auto',color:'white',lineHeight:'48px',paddingRight:'1em',fontStyle:'italic'}}>
                        {currentMap}
                    </div>
                </div>
                <div style={{...ABSOLUTE_FIT,top:'48px',display:'block'}} key={selectedTab.value}>
                    {selectedTab.content}
                </div>
                <ConfigureMapCoordinates/>
            </div>
        );
    }

    handleChangeTab(e, tab){
        this.setState({
            tab
        });
    }

    renderMap(){

        // const {
        //     sites,
        //     maps,
        //     selectedFloorMap,
        //     resizeTrigger,
        //     validConfiguration,
        //     actions
        // } = this.props;

        // const isHovered = this.isHoveredInMap.bind(this);
        // const onHoverSite = this.handleHoverSiteInMap.bind(this);
        // const onSelectSite = this.handleSelectSiteInMap.bind(this);
        // const handleLoadError = this.handleLoadError.bind(this);

        // let mapType=LOCAL_MAP;
        // if(!selectedFloorMap||!maps[selectedFloorMap]){
        //     // return (
        //     //     <WorldMap
        //     //         worldOnly
        //     //         onSelectSite={onSelectSite}
        //     //         onHoverSite={onHoverSite}
        //     //         isSelected={isHovered}
        //     //         sites={toArray(sites)}
        //     //         resizeTrigger={resizeTrigger}
        //     //     />
        //     // );
        //
        //     mapType=WORLD_MAP;
        //
        //
        // }

        //const maxZoom = parseInt(maps[selectedFloorMap].maxZoom)-1;

        return (
            // <MinimalLocalMap
            //     mapId={selectedFloorMap}
            //     maxZoom={maxZoom}
            //     validConfiguration={validConfiguration}
            //     resizeTrigger={resizeTrigger}
            //     requestMapBounds={actions.requestMapBounds}
            //     onLoadError={handleLoadError}
            // />
            <OlMapContainer sites={this.props.sites} hoverUID={this.props.hoverUID}/>
        );
    }

    // renderDeviceEditor(){
    //     return (
    //         <div>
    //             ...yeah, not quite here yet.
    //         </div>
    //     );
    // }

    // renderZoneEditor(){
    //     const {selectedFloorMap} = this.props;
    //
    //     return (
    //         <ZoneBuilder map={selectedFloorMap} />
    //     );
    // }

    // isHoveredInMap(point){
    //     return this.props.hoverUID === point.id || this.isSiteSelected(point.name);
    // }

    // isSiteSelected(siteId){
    //     return this.props.sites[siteId] && this.props.sites[siteId].checked;
    // }

    // handleHoverSiteInMap(site){
    //     if( (site && site._id) === this.props.hoverUID) return;
    //     this.props.onHoverSite(site);
    // }

    // handleSelectSiteInMap(site){
    //     this.props.actions.selectSite(site.name,!this.isSiteSelected(site.name));
    // }

    // handleLoadError(){
    //     if(!this.expired){
    //         this.expired = true;
    //         //this.props.actions.expireSession();
    //     }
    // }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions,dispatch)
});

const mapStateToProps = (state,{selectedFloorMap}) => {
    return{}
    // const sites = indexByAttribute(state.sites.sites,"_id");
    // const maps = indexByAttribute(state.sites.maps,"_id");
    //
    // let currentMap = "World";
    //
    // let selectedMap = selectedFloorMap && maps[selectedFloorMap];
    //
    // if(selectedMap){
    //     currentMap = `${sites[selectedMap.siteId].name} ${CHARACTER_MIDDOT} ${selectedMap.description}`;
    // }
    //
    // return {
    //     sites,
    //     maps,
    //     currentMap
    // };
};

export default connect(mapStateToProps,mapDispatchToProps)(SitesMapPanelInfrastucture);
