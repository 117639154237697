import React from 'react';
import MapThumbnail from './MapThumbnail';
import {MAP_VIEW_URL, SITE_VIEW_HOST} from "../../../constants/Misc";
import {useSelector} from "react-redux";

const MapThumbnailsContainer = ({siteId, mapSelected, maps}) => {
    const windowOrientation = useSelector(state => state.view?.DEVICE_MANAGER);
    const thumbnailsMaps = maps && maps.map((mapItem, index) => {
        return (
            <MapThumbnail
                key={index}
                siteId={siteId}
                mapId={mapItem._id}
                mapName={mapItem.description}
                selected={mapSelected === mapItem._id}
                disabled={!Boolean(mapItem.coordinates)}
                src={SITE_VIEW_HOST + MAP_VIEW_URL +"/maps/" + mapItem._id + "/tiles/0_0_0"}
            />
        )
    }); 
    const calculatePadding = ( windowOrientation === 'MAP_VIEW' || windowOrientation === 'MAP_REPORT_VERT_SPLIT')?'2.85em':'3.5em';
    return (

        <div className={"map-thumbnails-container"} style={{marginLeft:calculatePadding}}>
            {thumbnailsMaps}
        </div>
    );
};

export default React.memo(MapThumbnailsContainer, ((prevProps, nextProps) => {
    return prevProps.siteId === nextProps.siteId && prevProps.mapSelected === nextProps.mapSelected && prevProps.maps === nextProps.maps
}));