import React from 'react';
import PropTypes from 'prop-types';
//openlayers
import {fromLonLat, toLonLat} from 'ol/proj';
import OSM from 'ol/source/OSM';

import * as ZMap from './../../../../../common/app/views/map/ZMap';
// layers
import PinLayer from './../../util/map/layers/PinLayer';

import {OSM_SOURCE} from '../../../constants/Misc';

import {getToken} from '../../../actions/util';

const {Map,TileLayer,DragInteraction} = ZMap;



const CENTER = fromLonLat([0,0]);


class AddSitesFormMap extends React.Component{
    constructor(){
        super();
        this.state={
            pins:[],
            center:CENTER,
            zoom:1
        };
        this.handlePointerMove = this.handlePointerMove.bind(this);
        this.handleOnPress = this.handleOnPress.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.openStreetMap = new OSM({
            url:`${OSM_SOURCE}/{z}/{x}/{y}.png?jwt=${getToken()}`
        });
    }

    static get propTypes(){
        return {
            movingPin: PropTypes.bool,
            onPinDrop: PropTypes.func,
            pinLatLong: PropTypes.array,
            zoomOnChange: PropTypes.bool
        };
    }

    static get defaultProps(){
        const nop = function(){};
        return {
            onPinDrop: nop,
            zoomOnChange: false
        };
    }

    shouldComponentUpdate(nextProps,nextState){
        return (this.props !== nextProps) || (this.state.pins !== nextState.pins);
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(nextProps.zoomOnChange && this.props.pinLatLong !== nextProps.pinLatLong){

            if(nextProps.pinLatLong.length < 2){

                this.setState({
                    pins:[]
                });

                return; // don't use an improper coordinate.
            }

            const coord = fromLonLat( ( [].concat(nextProps.pinLatLong) ).reverse());
            this.setState({
                pins:[{
                    x:coord[0],
                    y:coord[1]
                }],
                center:coord,
                zoom:16
            });
        }
    }

    handlePointerMove(event){

        if(!this.props.movingPin) return;


        const this_pin = {
            x:event.coordinate[0],
            y:event.coordinate[1]
        };

        this.setState({
            pins:[this_pin],
            coordinate: event.coordinate
        });
    }

    handleMouseUp(){

        if(!this.props.movingPin) return;

        const this_pin = {
            x:this.state.coordinate[0],
            y:this.state.coordinate[1]
        };

        this.setState({
            pins:[this_pin]
        });

        this.props.onPinDrop(
            toLonLat(this.state.coordinate).reverse()
        );
    }

    handleOnPress(event){

        const this_pin = {
            x:event.coordinate[0],
            y:event.coordinate[1]
        };

        const coordinate = [event.coordinate[0], event.coordinate[1]];

        this.setState({
            pins:[this_pin],
            coordinate: coordinate
        });

        this.props.onPinDrop(
            toLonLat(coordinate).reverse()
        );
    }

    render(){

        const handleDragEnd = (c,feature)=>{
            const coord = feature.getGeometry().getCoordinates();
            this.setState({
                pins:[{
                    x:coord[0],
                    y:coord[1]
                }]
            });

            this.props.onPinDrop(
                toLonLat(coord).reverse()
            );
        };

        return(
            <div>
                <div style={{top:0,right:0,left:0,bottom:0, height:'476px'}}   className="map-zoom-button" onMouseUp={this.handleMouseUp}>
                    <Map
                        {...this.props}
                        center={this.state.center}
                        zoom={this.state.zoom}
                        onZoom={(z)=>{this.setState({
                            zoom: z
                        });}}

                        style={{
                            height:'476px',
                            width:'100%',
                            top:0,right:0,left:0,bottom:0
                        }}
                        onPointerMove={this.handlePointerMove}
                        onPress={this.handleOnPress}
                        >
                        <TileLayer source={this.openStreetMap} />
                        <PinLayer pins={this.state.pins} />
                        <DragInteraction onStopDragging={handleDragEnd}/>
                    </Map>

                </div>
            </div>

        );
    }
}

export default AddSitesFormMap;
