import React, {useState} from 'react';
import {useDispatch, useSelector}  from 'react-redux';
import {downloadDeviceLogs} from '../../../actions';

const CustomLinkLogsDownload = ({itemKey, rowIndex}) => {
    const selectedDevices = useSelector( state => state.deviceManager.devicesSelected[rowIndex]);
    const logsDownloadTypes = useSelector(state => state.deviceManager.logsDownloadTypes);
    const [downloaded, setDownloaded] = useState(false);
    const dispatch = useDispatch();

    const getValue = () => {
        dispatch(downloadDeviceLogs(selectedDevices._id, logsDownloadTypes, selectedDevices.title));
        setDownloaded(true);
    };

    return (
        <div style={{textAlign: 'center', textDecoration: 'underline', color: downloaded ? '#B23A0F': '#5296b2'}} >
            <span style={{cursor: 'pointer'}} onClick={getValue}>Download</span>
        </div>
    )
};

export default  CustomLinkLogsDownload;