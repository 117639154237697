import React from 'react';
import PropTypes from 'prop-types';

import {Feature, VectorLayer} from '../../../../../../common/app/views/map/ZMap';
//openlayers
import Style from 'ol/style/Style';
import CircleStyle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Point from 'ol/geom/Point';

const MAX = (a,b)=>((a>b)?a:b);

function solidDotWithColor(color){
    return (relativeSize)=>{
        return new Style({
            image: new CircleStyle({
              radius: MAX(relativeSize*20,8),
              fill: new Fill({color: color}),
              stroke: new Stroke({color: 'white', width: 2})
            })
        });
    };
}

const SIZE_DOT = solidDotWithColor("#00779f");
const GRAY_DOT = solidDotWithColor("#aaa")(0);


class PinLayer extends React.Component{

    constructor(props){
        super(props);
        this.props = props;
    }

    static get propTypes(){
        return {
            pins: PropTypes.array.isRequired
        };
    }

    styleFunction(feature,number){ // eslint-disable-line

        const data = feature.get('data');
        //console.log(data);

        if(data.relativeSize === 0){
            return [GRAY_DOT];
        }
        return [SIZE_DOT(data.relativeSize)];
    }

    render(){


        let _pins =  this.props.pins.map((v,i)=>{
            return (
                <Feature
                    key={"tag"+i}
                    geometry={ new Point([v.x,v.y]) }
                    data={v}
                    style={this.styleFunction}
                />
            );
        });


        return (
            <VectorLayer {...this.props}>
                {_pins}
            </VectorLayer>
        );
    }
}

export default PinLayer;
