import React from 'react';
import PropTypes from 'prop-types';
import FlatButton from '@material-ui/core/Button';


const styles={
    iconButton:{
        margin:'0 0.5em',
        minWidth:'auto',
        padding:'0 0.5em'
    },

    iconButtonText:{
        verticalAlign:'middle',
        display:'inline-block',
        fontSize:'0.95em',
        lineHeight:'24px',
        marginLeft:'0.1em',
        marginRight:'0.1em',
        textTransform:'none' // 2017-4-5
    }
};

class IconFlatButton extends React.Component{
    static get propTypes(){
        return {
            icon: PropTypes.any,
            label: PropTypes.string,
            style: PropTypes.object
        };
    }

    render(){
        const {icon,label,isMobile,style,...other} = this.props;
        const Icon = icon;


        return (
            <FlatButton style={{...styles.iconButton,...style}} {...other} variant="text">
                <Icon style={{verticalAlign:'middle',color:'inherit',transition:'none'}}/>
                {
                    isMobile ? "" : <span style={styles.iconButtonText}>{label}</span>
                }
            </FlatButton>
        );

        /*
        // ALTERNATIVE DESIGN -- Can't decide between them.
        return (
            <FlatButton icon={<Icon />} label={label} labelStyle={styles.iconButtonText} {...other} />
        );
        */
    }
}

export default IconFlatButton;
