import React from 'react';
import Table from "./Table";
import TableIe from "./TableIe";

class CrossBrowserTable extends React.Component{

    constructor(props){
        super(props);
        this.modernBrowser=props.modernBrowser;
    }

    // shouldComponentUpdate(nextProps){
    //     if(
    //         this.props.data!==nextProps.data
    //         //||this.props.rowCount!==nextProps.rowCount
    //         //||this.props.showCheckColumn!==nextProps.showCheckColumn
    //         //||this.props.headerRow!==nextProps.headerRow
    //         //||this.props.stickyColumn!==nextProps.stickyColumn
    //         //||this.props.headerRender!==nextProps.headerRender
    //         //||this.props.cellRender!==nextProps.cellRender
    //         //||this.props.selectedRows!==nextProps.selectedRows
    //         //||this.props.onClickRow!==nextProps.onClickRow
    //         //||this.props.onSelectAll!==nextProps.onSelectAll
    //         //||this.props.appliedFiltersHeight!==nextProps.appliedFiltersHeight
    //         // ||this.props.appliedFilters!==nextProps.appliedFilters
    //     ){
    //         return true;
    //     }
    //     return false;
    // }

    render() {

    //Extra Column
        //let extraColumn = {key:EXTRA_COLUMN,value:"",width: 0,clickable:false,sortable:false,enable:true,order:13};
        //let headerRow = [...this.props.headerRow,extraColumn];

    // header row for internet explorer
    const checkboxColumn = {key:"checkbox",value:"",width:50,clickable:true};
    const headerRowIE = this.props.showCheckColumn ? [checkboxColumn, ...this.props.headerRow/*,extraColumn*/] : this.props.headerRow;//this.props.headerRow;


        if (this.props.headerRow && this.props.headerRow.length > 0) {
            if (this.modernBrowser === true ) {
                return <Table
                    rowsValidationError={this.props.rowsValidationError}
                    data={this.props.data}
                    rowCount={this.props.rowCount}
                    showCheckColumn={this.props.showCheckColumn}
                    //headerRow={[...this.props.headerRow,extraColumn]}
                    headerRow={this.props.headerRow}
                    stickyColumn={this.props.stickyColumn}
                    headerRender={this.props.headerRender}
                    cellRender={this.props.cellRender}
                    selectedRows={this.props.selectedRows}
                    onClickRow={this.props.onClickRow}
                    onSelectAll={this.props.onSelectAll}
                    appliedFiltersHeight={this.props.appliedFiltersHeight || 0}
                    appliedFilters={this.props.appliedFilters}
                    fillWidth={true}
                />
            }
            else {
                return <TableIe
                    rowsValidationError={this.props.rowsValidationError}
                    data={this.props.data}
                    rowCount={this.props.rowCount}
                    showCheckColumn={this.props.showCheckColumn}
                    headerRow={headerRowIE}
                    stickyColumn={this.props.stickyColumn}
                    headerRender={this.props.headerRender}
                    cellRender={this.props.cellRender}
                    selectedRows={this.props.selectedRows}
                    onClickRow={this.props.onClickRow}
                    onSelectAll={this.props.onSelectAll}
                    appliedFiltersHeight={this.props.appliedFiltersHeight || 0}
                    appliedFilters={this.props.appliedFilters}
                    fillWidth={true}
                    setHeaderHeight={this.props.setHeaderHeight}
                />
            }
        }
        else
            return null;
    }

}

export default CrossBrowserTable;



/*
import React from 'react';
import Table from "./Table";
import TableIe from "./TableIe";

class CrossBrowserTable extends React.PureComponent{

    constructor(props){
        super(props);
        this.modernBrowser=true;
    }


    render(){

        if(this.modernBrowser===true){
            return <Table
                data={this.props.data}
                rowCount={this.props.rowCount}
                showCheckColumn={this.props.showCheckColumn}
                headerRow={this.props.headerRow}
                stickyColumn={this.props.stickyColumn}
                headerRender={this.props.headerRender}
                cellRender={this.props.cellRender}
                selectedRows={this.props.selectedRows}
                onClickRow={this.props.onClickRow}
                onSelectAll={this.props.onSelectAll}
                appliedFiltersHeight={this.props.appliedFiltersHeight}
                appliedFilters={this.props.appliedFilters}
            />
        }
        else{
            return <TableIe
                data={this.props.data}
                rowCount={this.props.rowCount}
                showCheckColumn={this.props.showCheckColumn}
                headerRow={this.props.headerRow}
                stickyColumn={this.props.stickyColumn}
                headerRender={this.props.headerRender}
                cellRender={this.props.cellRender}
                selectedRows={this.props.selectedRows}
                onClickRow={this.props.onClickRow}
                onSelectAll={this.props.onSelectAll}
                appliedFiltersHeight={this.props.appliedFiltersHeight}
                appliedFilters={this.props.appliedFilters}
            />
        }
    }
}

export default CrossBrowserTable;
* */