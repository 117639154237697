import React,{useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { updateStateFormTemplate } from "../../../../actions";
import { makeStyles } from '@material-ui/core/styles';
import {Tooltip, Divider} from '@material-ui/core';
import {
    ADD_TEMPLATE,
    HIGHLIGHT_STYLE,
    TEMPLATE_MAX_FIELD_DESCRIPTION,
    MAX_LENGTH_DEVICE_TITLE
} from '../../../../constants/DeviceManager';

const useStyles = makeStyles({
    checked: {
        color: "#007CB0 !important",
    },
    tooltip:{
        color:'#000',
        backgroundColor:'#e6ee31',
        fontSize:'14px'
    },
    arrow:{
        color: '#e6ee31'
    },popper:{
        zIndex: 0
    }
});

const IdentityPanel = () => {
    const dispatch = useDispatch();
    const requiredFields = useSelector(state => state.deviceManager.needsValidationFields.identity);
    const title = useSelector(state => state.deviceManager.templateForm.template.title);
    const description = useSelector(state => state.deviceManager.templateForm.template.description);

    //device type fields
    const templateFormLoaded = useSelector(state => state.deviceManager.templateFormLoaded);
    const isIpV6 = useSelector(state => state.deviceManager.templateForm.template.isIpV6);
    const deviceType = useSelector(state => state.deviceManager.templateForm.template.deviceType);
    const rfidType = useSelector(state => state.deviceManager.templateForm.template.rfidType);
    const macAddress = useSelector(state => state.deviceManager.templateForm.template.macAddress);
    const ipAddress = useSelector(state => state.deviceManager.templateForm.template.ipAddress);
    const location = useSelector(state => state.deviceManager.templateForm.template.location);

    //initial values for macAddress
    const INIT_MACADDRESS = ['','','','','',''];

    useEffect(()=>{}, [isIpV6]);
    useEffect(()=>{
    }, [requiredFields]);

    const classes = useStyles();

    //flag to enable highlights in input fields
    const isHighlight = templateFormLoaded !== ADD_TEMPLATE;

    const handleChange = event => {
        const key = event.target.name;
        //highlighting
        if(isHighlight && key){
            document.getElementById(`identity-${key}`).style["box-shadow"] = HIGHLIGHT_STYLE;
        }

        if(key.startsWith('location')){
            const [nameParentKey, nameChildKey] = key.split('-');
            dispatch(updateStateFormTemplate(nameParentKey, nameChildKey, Number(event.target.value)));
        }else if(key === 'isIpV6'){
            dispatch(updateStateFormTemplate(key, null, event.target.value === "true"));
        }else if(key === 'title'){
            const deviceTitle = getTextLabel(event.target.value, MAX_LENGTH_DEVICE_TITLE);
            dispatch(updateStateFormTemplate(key, null, deviceTitle));
        }else if(key === 'description'){
            dispatch(updateStateFormTemplate(key, null, event.target.value));
        }else{
            let elementValue = event.target.value.trim();
            dispatch(updateStateFormTemplate(key, null, elementValue));
        }
    };

    const getTextLabel = (textValue, maxLength) => {
        return textValue.substr(0, maxLength);
    }

    const handleChangeMacAddress = event => {
        const key = event.target.name;

        //highlighting
        if(isHighlight){
            const id = event.target.id;
            document.getElementById(id).style["box-shadow"] = HIGHLIGHT_STYLE;
        }

        let format = INIT_MACADDRESS;
        if((macAddress && macAddress.split(':').length>1)){
            format = macAddress.split(':');
        }
        const [nameParentKey, position]= key.split('-');
        format[Number(position)] = event.target.value.trim();
        dispatch(updateStateFormTemplate(nameParentKey, null, format.join(':')));       
    }
    
    const getDeviceTypeContent = () => {
        return (
        <div style={{marginTop:'1em'}}>
            <span className="items-header" style={{paddingBottom: '10px'}}>Device Type</span>
            <span>{`${deviceType} - ${rfidType}`}</span>
            <Divider style={{marginTop: 5, marginBottom: 0}}/>
        </div>)
    };

    const getMacAddress = () =>{
        let format = (macAddress && macAddress.length>0)?macAddress.split(':'):[...INIT_MACADDRESS];
        let inputContainerStyles={flexBasis:'72px',flexShrink:1,textAlign:'center'};
        let inputStyles={paddingLeft:0,textAlign: 'center'};
        return (
            <React.Fragment>
                {format.map((item, index) => (
                    <React.Fragment key={index}>
                        {(index<=format.length-2)?
                            <React.Fragment>
                                <div className="field" style={inputContainerStyles}>
                                    <input id={`identity-macAddress-${index}`} type={"text"} 
                                            style={{...inputStyles, boxShadow: requiredFields.macAddress.error?'0px 0px 4px #e4ed15':''}}
                                            value={item} maxLength={4} name={`macAddress-${index}`}
                                        onChange={handleChangeMacAddress} placeholder={"0"} />
                                </div>
                                <span>:</span>
                            </React.Fragment>:
                            <Tooltip open={requiredFields.macAddress.error}
                                     arrow={true} title={'Mandatory Field'} placement={"right"} PopperProps={{disablePortal: true}}
                                        classes={{tooltip:classes.tooltip, arrow:classes.arrow, popper: classes.popper }}>
                                    <div className="field" style={inputContainerStyles}>
                                        <input id={`identity-macAddress-${index}`} type={"text"} style={{...inputStyles, boxShadow: requiredFields.macAddress.error?'0px 0px 4px #e4ed15':''}}
                                            value={item} maxLength={4} name={`macAddress-${index}`}
                                            onChange={handleChangeMacAddress} placeholder={"0"} />
                                    </div>
                            </Tooltip>
                        }
                    </React.Fragment>
                ))}
            </React.Fragment>
        );
    }

    const getHostname = () => {
        return (
            <div className="field">
                <Tooltip open={requiredFields.ipAddress.error} arrow={true} title={'Mandatory Field'} placement={"right"} PopperProps={{ disablePortal: true }}
                        classes={{tooltip:classes.tooltip, arrow:classes.arrow, popper: classes.popper }} >
                    <input id='identity-ipAddress' type={"text"}
                        style={{width:'360px',boxShadow: requiredFields.ipAddress.error?'0px 0px 4px #e4ed15':''}}
                        placeholder={"Hostname"}
                        name={'ipAddress'}
                        value={ipAddress || ''}
                        onChange={handleChange}/>
                </Tooltip>
            </div>
        );
    }


    const getAdditionalFields = () =>{
        const inputCoordStyles={flexBasis:'120px',flexShrink:1,textAlign:'center',display:'inline-flex', padding: '0.2em',marginRight: '0.8em'};
        const coordinatesText={ fontSize:'14px', padding: '0.3em'};
        return(
            <React.Fragment>
                <Divider style={{marginTop: 15, marginBottom: 0}}/>
                <span className="items-header">Hostname*</span>
                <div className="basic-input" style={{display:'flex',flexDirection:'row',alignItems:'center',flexWrap:'wrap',margin:'0 -0.5em'}} >
                    {getHostname()}
                </div>
                <span className="items-header" style={{paddingTop: '10px'}}>MAC Address</span>
                <div className="basic-input" style={{display:'flex',flexDirection:'row',alignItems:'center',flexWrap:'wrap',margin:'0 -0.5em'}}>
                    {getMacAddress()}
                </div>

                <Divider style={{marginTop: 15, marginBottom: 15}}/>
                <span className="items-header">Coordinates</span>
                <div className="basic-input" style={{display:'flex',flexDirection:'row',flexWrap:'wrap',margin:'0 -0.2em'}} >
                    <div className="field" style={inputCoordStyles}>
                        <span style={coordinatesText}>X</span>
                        <input id={'identity-location-x'} type={"number"} value={location.x || ''} name={'location-x'} onChange={handleChange} placeholder={"0"}/>
                    </div>
                    <div className="field" style={inputCoordStyles}>
                        <span style={coordinatesText}>Y</span>
                        <input id={'identity-location-y'} type={"number"} value={location.y || ''} name={'location-y'} onChange={handleChange} placeholder={"0"}/>
                    </div>
                    <div className="field" style={inputCoordStyles}>
                        <span style={coordinatesText}>Z</span>
                        <input id={'identity-location-z'} type={"number"} value={location.z || ''} name={'location-z'} onChange={handleChange} placeholder={"0"}/>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    return (<div style={{padding:'0 0.3em'}}>
        {templateFormLoaded.startsWith('EDIT_DEVICE') && getDeviceTypeContent()}
        <div className="device-items">
            <div className="basic-input" style={{margin:'0 -0.5em'}}>
                <div className="field">
                    <span className="items-header" style={{paddingBottom: '13px'}}>Name</span>
                    <Tooltip open={requiredFields.title.error} arrow={true} title={'Mandatory Field'} placement={"right"} PopperProps={{ disablePortal: true }}
                            classes={{tooltip:classes.tooltip, arrow:classes.arrow, popper: classes.popper }} >
                        <input id='identity-title' type={"text"} maxLength={MAX_LENGTH_DEVICE_TITLE}
                            style={{boxShadow: requiredFields.title.error?'0px 0px 4px #e4ed15':''}}
                            placeholder={"Full Name"}
                            name={'title'}
                            value={title}
                            onChange={handleChange}/>
                    </Tooltip>
                    <span className="identity-char-indicator">{MAX_LENGTH_DEVICE_TITLE-title.length} characters left.</span>
                </div>
            </div>
            <div className="basic-input" style={{margin:'0 -0.5em'}}>
                <div className="field">
                    <span className="items-header" style={{paddingBottom: '13px'}}>Description</span>
                    <Tooltip open={requiredFields.description.error} arrow={true} title={'Mandatory Field'} placement={"right"} PopperProps={{ disablePortal: true }}
                            classes={{tooltip:classes.tooltip, arrow:classes.arrow, popper: classes.popper }} >
                        <textarea id='identity-description' type={"text"} maxLength={TEMPLATE_MAX_FIELD_DESCRIPTION}
                            placeholder={"Enter Description"}
                            style={{resize: 'none', height: '6em', boxShadow: requiredFields.description.error?'0px 0px 4px #e4ed15':''}}
                            name={'description'}
                            value={description || ''}
                            onChange={handleChange} />
                    </Tooltip>
                    <span className="identity-char-indicator">{description?TEMPLATE_MAX_FIELD_DESCRIPTION-description.length:TEMPLATE_MAX_FIELD_DESCRIPTION} characters left.</span>
                </div>
            </div>
        </div>
        {templateFormLoaded.startsWith('EDIT_DEVICE') && getAdditionalFields()}
    </div>);
}

export default IdentityPanel;