import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import {Button, DialogActions, DialogTitle} from '@material-ui/core';
import {DialogContent} from '@material-ui/core/es'
import dateFormat from "dateformat";

const STATUS_HISTORY_TITLE = 'Status History';

const DialogStatusHistory = ({statusHistory, open, setOpenHistory}) => {

    const handleClose = () => {
        setOpenHistory(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle style={{paddingRight: 50}}>
                <div style={{fontWeight:'300',fontSize:'0.9em'}}>
                    {STATUS_HISTORY_TITLE}
                </div>
            </DialogTitle>
            <DialogContent>
                <div style={{width: 552,display:'inline-block',textAlign:'left', height: 480}}>
                    <div style={{marginBottom:'1em'}} key={1}>
                        <span style={{fontWeight:'bold'}}>{"Date: "}</span>
                        <span>{statusHistory && dateFormat(new Date(statusHistory.time), 'mm-dd-yyyy HH:MM:ss')}</span>
                    </div>
                    <div style={{width: '552px'}}>
                        <div style={{background: 'rgba(0, 0, 0, 0.1)', border: '1px solid', overflow: 'auto', maxHeight: '500px'}}>
                            <pre style={{margin: '0px', overflow: 'visible' }}>
                                {statusHistory && JSON.stringify(statusHistory, null, 4)}
                            </pre>
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button style={{background: '#fff', color: '#007CB0', padding: '0.5em', textTransform: 'none'}} onClick={handleClose} component="span">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )

};

export default DialogStatusHistory;