import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector, useDispatch} from "react-redux";
import {
    commitSelectedCloudDevices,
    loadDevicesViaZebraDataService,
    selectAllCloudDevice,
    selectRowCloudDevice,
    unSelectRowClodDevice,
    initAddCloudDevices,
    requestSites,
    cleanDeviceCloudStatus
} from "../../../actions";
import {NUMBER_ANTENNAS} from "../../../constants/DeviceManager";
import DialogConfirm from '../common/dialogs/DialogConfirm';
import CrossBrowserTable from "../common/tableComponent/CrossBrowserTable";
import Button from "@material-ui/core/Button";
import FetchScreen from "../common/FetchScreen";
import r2cdevicePayload from "../../../constants/r2cdevicePayload.json";
import rfidAntenna from "../../../constants/rfidAntenna.json";
import {EnabledPorts} from "../addDevicesManually/SecondStepContent";

function FirstStepDataService(props){
    const statusCloudDevices = useSelector(state => state.zebraDataService.statusCloudDevices);
    const cloudDevices = useSelector(state => state.zebraDataService.cloudDevices);
    const deviceModelAndType = useSelector(state => state.zebraDataService.deviceModelAndType);
    const selectedRows = useSelector(state => state.zebraDataService.selectedRows);
    const isFetching = useSelector(state => state.deviceManager.isFetching);
    const mapSelected = useSelector(state => state.deviceManager.mapSelected);
    const sites = useSelector(state => state.sites.sites);
    const [listDevices, setListDevices] = useState([]);
    // const [sitesObject, setSitesObject] = useState({});
    // const [numberAntennaPorts, setNumberAntennaPorts] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const history = useHistory();
    const {currentStep, changeStep} = props;
    let headerRow = [{key:"id",value:"Device Id",clickable:false,width:80},
                    {key:'deviceAntennaPorts',value:'# Antenna Ports',width: 120,clickable:false},
                    {key:'deviceEnabledPorts',value:'Enabled ports for data collection',width: 220,clickable:false},
                    {key:"deviceStatus",value:"Import Status",clickable:false,width:100},
                    {key:"deviceMessage",value:"Message",clickable:false, width:200}];

    if(!(mapSelected && mapSelected.siteName)){
        let siteColumn = {key:"siteId",value:"Site",clickable:false,width:80}
        headerRow.splice(1, 0, siteColumn);
    }

    const buildingDeviceList = () => {
        if(cloudDevices.length > 0){
            let newDevices = [];
            cloudDevices.forEach(item => {
                let deviceObject = {...r2cdevicePayload};
                //setting data
                deviceObject.ipAddress = item;
                deviceObject.title = item;
                deviceObject.protocol = 'https';
                deviceObject.numberAntennaPorts = 0
                //type and model
                if(deviceModelAndType.hasOwnProperty(item)){
                    deviceObject.deviceType=deviceModelAndType[item].type;
                    deviceObject.rfidType=deviceModelAndType[item].model;
                }

                // configuring site and map
                let mapID = (mapSelected && mapSelected.id) ? `${mapSelected.id}`:'';
                if(mapSelected && mapSelected.siteId){
                    deviceObject.siteId = mapSelected.siteId;
                    deviceObject.location = {...item.location, mapID: mapID}
                }

                newDevices.push(deviceObject);
            });
            setListDevices(newDevices);
        }
    }

    useEffect(buildingDeviceList, [cloudDevices]);
    const dispatch = useDispatch();
    const loadAddDevices = () => {
        //mounting
        if(!mapSelected){
            dispatch(requestSites());
        }
        //unmounting        
        const unmount = () => {
            dispatch(initAddCloudDevices());
            dispatch(cleanDeviceCloudStatus());
        };
        return unmount;
    }

    useEffect(loadAddDevices, []);

    function commitCloudDevices(){
        const listSelectedRows = Object.keys(selectedRows);        
        const devicesToSave = listDevices.filter(device => listSelectedRows.indexOf(device.ipAddress) > -1);
        dispatch(commitSelectedCloudDevices(devicesToSave));
    }

    function getSelectedRows(){
        const devicesChecked = {};
        cloudDevices.forEach((element, index)=> {
            if(Object.keys(selectedRows).indexOf(element) > -1){
                devicesChecked[index] = element;
            }
        });
        return devicesChecked;
    }

    function clickRow(rowIndex){
        const deviceId = cloudDevices[rowIndex];
        // const newState = Object.assign({}, selectedRows);

        if (selectedRows.hasOwnProperty(deviceId)) {
            dispatch(unSelectRowClodDevice(rowIndex));
        } else {
            dispatch(selectRowCloudDevice(rowIndex));
        }
    }
    function handleSelectAll(){
        if(Object.keys(selectedRows).length === 0){
            let newState = {};
            cloudDevices.forEach(item => newState[item] = item);
            dispatch(selectAllCloudDevice(newState));
        }else{
            dispatch(selectAllCloudDevice({}));
        }
    }

    function getDevicesFromZebraDataService(){
        if(cloudDevices.length === 0){
            dispatch(loadDevicesViaZebraDataService());
        }else{
            setOpenDialog(true);
        }
    }

    function getDevicesFromZebraDataServiceAgain(){
        dispatch(loadDevicesViaZebraDataService());
    }


    function customCellDevice ({rowIndex, headerKey}){
        const keyDevice = listDevices[rowIndex].title;
        switch(headerKey){
            case 'id':
                return keyDevice;
            case 'deviceAntennaPorts':
                return renderAntennaPortsCell(rowIndex);
            case 'deviceEnabledPorts':
                return <EnabledPorts rfidAntennas={listDevices[rowIndex].rfidAntennas} rowIndex={rowIndex} updateItem={updateItem} disabled={listDevices[rowIndex].rfidType.indexOf('ATR') > -1}/>;
            case 'siteId':
                return renderSiteCell(rowIndex);
            case 'deviceStatus':
                return statusCloudDevices[keyDevice]?renderResponse(statusCloudDevices[keyDevice].status):'';
            case 'deviceMessage':
                return statusCloudDevices[keyDevice]?statusCloudDevices[keyDevice].text:'';
            default:
                return '';
        }
    }

    const renderResponse = (status) => {
        let styleResponse = {backgroundColor: status === 'SUCCESS' ? '#009c34': '#f02618', color: '#fff', textAlign: 'center'};
        return <div style={styleResponse}>{status}</div>
    }

    function updateItem(index, key, value){
        let listDevicesUpdated = [...listDevices];
        listDevicesUpdated[index][key] = value; 
        setListDevices(listDevicesUpdated);
    }

    const renderAntennaPortsCell = (rowIndex) => {        
        const deviceModel = listDevices[rowIndex].rfidType;
        const numberAntennaPorts = listDevices[rowIndex].numberAntennaPorts;
        return <select disabled={listDevices[rowIndex].rfidType.indexOf('ATR') > -1} style={{width:'112px'}} value={numberAntennaPorts} onChange={e=> changeAntennasNumber(rowIndex, e.target.value)}>
                <option value={0} style={{display:'none'}}></option>
                {NUMBER_ANTENNAS[deviceModel].map((v,i) => <option key={i} value={v}>{v}</option>)}
            </select>
    }

    const renderSiteCell = (rowIndex) => {
        const siteId = listDevices[rowIndex].siteId;
        return <select value={siteId || ''} onChange={e=> updateItem(rowIndex, 'siteId', e.target.value)}>
            <option value="">No site</option>
            {sites.map((item,index) => <option key={index} value={item.id}>{item.name}</option>)}
        </select>
    }

    function changeAntennasNumber(rowIndex, value){
        updateItem(rowIndex, "numberAntennaPorts", Number(value));

        // declarating antennas
        let arrayAntennas = [];
        let i = 0;
        while(i < Number(value)){
            i++;
            let antenna = {...rfidAntenna};
            antenna.id = Number(i);
            antenna.title = `Antenna ${i}`;
            if(mapSelected && mapSelected.id){
                antenna.location = {...antenna.location, 
                    mapID: `${mapSelected.id}`
                };
            }
            arrayAntennas.push(antenna);
        }
        updateItem(rowIndex, "rfidAntennas", arrayAntennas);
    }

    const disableGetDevicesButton = Object.keys(statusCloudDevices).length>0;
    const disabledButtonCondition = cloudDevices.length===0 || Object.keys(selectedRows).length === 0 || Object.keys(statusCloudDevices).length>0;
    const disableButtonContinueCondition = Object.keys(statusCloudDevices).filter(key => statusCloudDevices[key] && statusCloudDevices[key].status === 'SUCCESS').length===0;
    const styleContainerGetButton = {flexGrow:1, display:'flex', flexDirection:'column',alignItems:'flex-end'};
    const styleGetButton = {padding: '10px', backgroundColor: '#007CB0', color: '#fff', textTransform: 'none', opacity:disableGetDevicesButton?0.4:1};
    const classCommitButton = disabledButtonCondition?'next-step-button-disabled':'next-step-button';
    const classContinueButton = disableButtonContinueCondition?'next-step-button-disabled':'next-step-button';

    const pathDeviceList = '/device-manager/devices';
    const dialogMessage = 'The current data will be removed. Are you sure remove current data and get devices from zebra data service again?';

    const renderHeaderContent = (title, value) =>{
        const styleContent = {display:'flex',flexDirection:'row',flexWrap:'no-wrap', alignItems:'flex-start',justifyContent:'flex-start', paddingRight:'1em'};
        return (
            <div style={styleContent}>
                <b>{`${title}: `}</b> &nbsp; <span>{value}</span>
            </div>
        )
    };

    return(
        <React.Fragment>
            {isFetching ? <FetchScreen />: null}
            <div className={"imported-devices-container"} style={{flexBasis: 'auto'}}>
                {renderHeaderContent('Site', (mapSelected && mapSelected.siteName)? mapSelected.siteName: 'n/a')}
                {renderHeaderContent('Map', (mapSelected && mapSelected.name)? mapSelected.name: 'n/a')}
                {renderHeaderContent('Number of selected devices', Object.keys(selectedRows).length)}

                <div style={styleContainerGetButton}>
                    <Button style={styleGetButton} onClick={getDevicesFromZebraDataService} disabled={disableGetDevicesButton}>Get Devices from Zebra Data Service</Button>
                </div>
            </div>
            <div className={"container-body"}>
                {listDevices.length>0 &&
                    <CrossBrowserTable
                        rowCount={listDevices.length}
                        showCheckColumn={true}
                        headerRow={headerRow}
                        cellRender={customCellDevice}
                        onClickRow={clickRow}
                        selectedRows={getSelectedRows()}
                        onSelectAll={handleSelectAll}
                        modernBrowser={true}
                        appliedFilters={() => null}
                    />}
            </div>
            <div className={"container-step-content-buttons"}>
                <Button className={'cancel-button'} style={{marginRight:'8px'}} component="span" onClick={()=>history.push(pathDeviceList)}>{disableButtonContinueCondition?"Cancel":"Finish"}</Button>
                <Button className={classCommitButton} style={{marginRight:'8px'}} onClick={commitCloudDevices} component="span" disabled={disabledButtonCondition}>Save</Button>
                <Button className={classContinueButton} onClick={() => changeStep(currentStep + 1)} component="span" disabled={disableButtonContinueCondition}>Continue - Place on Map</Button>
            </div>
            {openDialog && <DialogConfirm 
                open={openDialog}
                closeDialog={() => setOpenDialog(false)}
                actionSuccess={getDevicesFromZebraDataServiceAgain}
                actionCancel={()=>{}}
                message={dialogMessage}
            />}
        </React.Fragment>        
    );
}

export default FirstStepDataService;