import React from 'react';
import PropTypes from 'prop-types';

export default class SiteInfoBox extends React.Component{
    static get propTypes(){
        return {
            site: PropTypes.object,
            attributes: PropTypes.array
        };
    }

    static get defaultProps(){
        return {
            site:{},
            attributes:[]
        };
    }

    render(){
        const {site,attributes} = this.props;

        const labelStyle={
            color:"#00779f",
            padding:'0.25em 1em',
            paddingLeft:'0',
            textAlign:'right'
        };

        return (
            <div style={{backgroundColor:'white',padding:'1em',fontSize:'0.9em'}}>
                <table>
                    <tbody>
                        {
                            attributes.map(({label,key,defaultValue})=>(
                                <tr key={key}>
                                    <td style={labelStyle}>{label}</td>
                                    <td>{site[key] || defaultValue}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}
