import React from 'react';
import PropTypes from 'prop-types';
import StyledDialog from "../../util/Dialog";
import Button from '@material-ui/core/Button';

class SiteGroupDialog extends React.Component{

    constructor(props){
        super(props);

        this.state={
            name:props.name || ""
        };

        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    static get propTypes(){
        return {
            name: PropTypes.string,
            onSave: PropTypes.func,
            open: PropTypes.bool,
            onRequestClose: PropTypes.func
        };
    }

    static get defaultProps(){
        const nop = function(){};
        return {
            onRequestClose: nop
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(!this.props.open && nextProps.open){
            this.setState({
                name:nextProps.name || ""
            });
        }
    }

    handleNameChange(e){
        const name = e.currentTarget.value;
        this.setState({
            name
        });
    }

    renderForm(){
        return (
            <div className="add-site-form">
                <div>
                    <div className="label" style={{color: '#616C6F'}}>Site Group Name</div>
                    <div className="field"><input value={this.state.name} onChange={this.handleNameChange}/></div>
                </div>
            </div>
        );
    }

    handleSave(){
        const {onSave,onRequestClose} = this.props;
        (onSave || onRequestClose)({
            name:this.state.name
        });
    }

    render(){

        // Design workaround: align 'Save' button with text fields.
        const BUTTON_OFFSET = 176; // (need to re-design)
        const {onRequestClose} = this.props;
        const mainButtons = (
            <div className="dialog-buttons">
                <Button style={{color:'#00779f'}} onClick={this.handleSave}>Save</Button>
                <Button style={{color:'#777',position: 'absolute', left: 8}} onClick={onRequestClose}>Cancel</Button>
            </div>
        );

        return (
            <StyledDialog
                title="Add Site Group"
                buttonOffset={BUTTON_OFFSET}
                onClose={onRequestClose}
                {...this.props}
                //onSave={this.handleSave}
                maxWidth={false}
                actions={mainButtons}
            >
                <div style={{width:'336px'}}>
                        {this.renderForm()}
                </div>
            </StyledDialog>
        );
    }
}

export default SiteGroupDialog;
