import React from 'react';
import PropTypes from 'prop-types';

import StyledDialog, {CancelButton, SaveButton} from '../../../util/Dialog';

import {ErrorMsg, Field, Form} from '../../../util/DataEntryTabularForm'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../../../../actions';
import {USERMGMT_DELETE_USER} from '../../../../constants/DataEntryForms';


class DeleteUserDialog extends React.Component {

    constructor(){
        super();

        this.state = {
            showErrorMsg: false,
        };

        this.handleSave = this.handleSave.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    static get propTypes(){
        return {
            open: PropTypes.bool,
            closeDataEntry: PropTypes.func.isRequired,
            data: PropTypes.object,
            user: PropTypes.shape({
                username:PropTypes.string,
                firstName:PropTypes.string,
                lastName:PropTypes.string,
                id: PropTypes.string
            }),
            deleteUser: PropTypes.func.isRequired
        };
    }

    static get defaultProps(){
        return {
            open: false,
            data:{
                appliances:[]
            }
        };
    }

    handleClose ()  {
        this.setState({
            showErrorMsg: false
        });
        this.props.closeDataEntry();
    }

    handleSave(){
        if(this.props.userLogged === this.props.user.username){
            this.setState({
                showErrorMsg: true
            });
            return;
        }
        this.props.deleteUser(this.props.user.id)
        .then(this.props.closeDataEntry);
    }

    renderForm(){

        const {user} = this.props;
        const {showErrorMsg} = this.state;

        return (
            <Form fullWidth>
                <Field label={"Username"}>
                    {user && user.username}
                </Field>
                <Field label={"First Name"}>
                    {user && user.firstName}
                </Field>
                <Field label={"Last Name"}>
                    {user && user.lastName}
                </Field>
                {showErrorMsg ? <ErrorMsg label={"Cannot delete the same user logged in."} /> : null}
            </Form>
        );
    }

    render() {

        // const {
        //     //actions, // eslint-disable-line
        //     //...other
        // } = this.props;

        const mainButtons = (
            <>
                <CancelButton style={{position: 'absolute', left: 8, bottom: 8}} onClick={this.handleClose} />
                <SaveButton onClick={this.handleSave} label="Delete" />
            </>
        );

        return (
            <StyledDialog
                title="Delete User"
                open={this.props.open}
                onRequestClose={this.handleClose}
                onClose={this.handleClose}
                actions={mainButtons}
                >
                <div style={{display:'inline-block',textAlign:'left', width: 360}}>
                    {this.renderForm()}
                </div>
            </StyledDialog>
        );
    }
}


/*
 ██████  ██████  ███    ██ ███    ██ ███████  ██████ ████████     ████████  ██████
██      ██    ██ ████   ██ ████   ██ ██      ██         ██           ██    ██    ██
██      ██    ██ ██ ██  ██ ██ ██  ██ █████   ██         ██           ██    ██    ██
██      ██    ██ ██  ██ ██ ██  ██ ██ ██      ██         ██           ██    ██    ██
 ██████  ██████  ██   ████ ██   ████ ███████  ██████    ██           ██     ██████

███████ ████████  █████  ████████ ███████
██         ██    ██   ██    ██    ██
███████    ██    ███████    ██    █████
     ██    ██    ██   ██    ██    ██
███████    ██    ██   ██    ██    ███████
*/

const mapStateToProps = state => ({
    open: state.dataEntry.open && state.dataEntry.activeForm === USERMGMT_DELETE_USER,
    user: (state.dataEntry.activeForm === USERMGMT_DELETE_USER || undefined) && state.dataEntry.initialFormData.user,
    userLogged: state.user.username
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        closeDataEntry: actions.closeDataEntry,
        deleteUser: actions.deleteUser
    },dispatch)
});

export default connect(mapStateToProps,mapDispatchToProps)(DeleteUserDialog);
