import React from 'react';
import ZPage from '../../../../common/app/views/ZPage';

import TitleLayout from './toolbars/TitleLayout';
import FormImport from './toolbars/FormImport'

import {connect} from "react-redux";
import {bindActionCreators} from 'redux';
import * as actions from '../../actions';


class ImportPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            name: "Import",
            filters: !this.props.isMobile,
            numResults: 0,
            //formatExport:null,
        };

        this.showFilters = this.showFilters.bind(this);
        this.countResults = this.countResults.bind(this);

        // this.exportResults = this.exportResults.bind(this);
        // this.finishedExportResults = this.finishedExportResults.bind(this);
        // this.reloadReport = this.reloadReport.bind(this);

        this.handleReloadReport = this.handleReloadReport.bind(this);
        this.handleExportReport = this.handleExportReport.bind(this);
    };


    showFilters(){
        const showsidebar = this.state.filters;
        this.setState({filters: !showsidebar});
    }

    countResults(number){
        this.setState({numResults: number});
    }

    // exportResults(format){
    //     this.setState({formatExport: format});
    // }

    // finishedExportResults(){
    //     this.setState({formatExport: null});
    // }

    componentDidMount() {
        this.props.actions.requestImport('import');
        this.props.actions.getImportList();
    }

    handleReloadReport() {
        this.props.actions.getImportList();
    }

    handleExportReport(format) {
        this.props.actions.exportImportResults(format);
    }

    render() {
        return (
            <ZPage zBreadCrumbs={["Home"]} zSubHeaderHeight={100} zShowSubHeader={false} >
                <div>
                    <TitleLayout 
                        name={this.state.name} 
                        collapsed={this.state.filters}
                        numberResults={this.state.numResults}
                        exportResults={this.exportResults} 
                        showSidebar={this.showFilters}
                        isMobile={this.props.isMobile}
                        
                        onRunReport = {this.handleReloadReport}
                        onExportReport = {this.handleExportReport}
                        />
                    <FormImport 
                        collapsed = {this.state.filters}
                        countingResults = {this.countResults}
                        //formatExport = {this.state.formatExport}
                        //finishedExportResults = {this.finishedExportResults}

                        importHistoryList = {this.props.importHistoryList}
                        filters = {this.props.filters}
                        //filteredImportHistoryList = {this.props.filteredImportHistoryList}
                        nonDefaultFiltersCounter = {this.props.nonDefaultFiltersCounter}
                        reloadReport = {this.handleReloadReport}
                    />
                </div>
            </ZPage>
        );
    };
};


const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = state => ({
    isMobile: state.resize.isMobile,
    importHistoryList: state.importReport.importHistoryList,
    filters: state.importReport.filters,
    nonDefaultFiltersCounter: state.importReport.nonDefaultFiltersCounter,
    // filteredImportHistoryList: state.importReport.filteredImportHistoryList,
    
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportPage);