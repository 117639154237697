import React, {useState} from "react";
import {getColor, getAntennaStatusLabel} from "./tableComponent/Util";

const AntennasStatus = ({antennas}) => {
    const containerStyles = {width:'100%', height: '100%', display:'flex',justifyContent:'flex-start',flexWrap:'wrap'};
    return (
        <div className={'device-status-container'} style={containerStyles}>
            {antennas&&antennas.map((item, index)=> <AntennaStatus key={index} item={item} />)}
        </div>
    );
}
export const MemoizedAntennasStatus=React.memo(AntennasStatus);
export default AntennasStatus;

const AntennaStatus = props => {
    const {item} = props;
    const [showMassageTooltip, setShowMassageTooltip] = useState(false);
    const showTooltip=()=>{
        setShowMassageTooltip(true);
    }
    const hiddenTooltip=()=>{
        setShowMassageTooltip(false);
    }
    let styleAntenna = {width:'12px',height:'12px',borderRadius:'12px', overflow: 'hidden'};
    styleAntenna.backgroundColor = getColor(item.status, item.disabled);
    let labelTooltip = getAntennaStatusLabel(item.status, item.disabled);
    return (<React.Fragment>
            <div className={'device-status-tooltip-container'} style={{margin: 0, width: '15px'}} onMouseOver={showTooltip} onMouseLeave={hiddenTooltip}>
                <div style={styleAntenna}/>
                {(showMassageTooltip)?<div className={'device-status-tooltip'} style={{bottom:'15px'}}><span>{labelTooltip}</span></div>:null}
            </div>
    </React.Fragment>)
}