import React, {useState, useEffect} from "react";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {useDispatch, useSelector} from "react-redux";
import {operateMultipleDevices, getOperationOptions, devicesDeleteMultipleRows} from '../../../actions';
import {
    EDIT,
    UPGRADE_FIRMWARE,
    PATH_EDIT_DEVICE,
    LOGS,
    DOWNLOAD_LOGS, CONFIGURE_LOGS,
    DELETE, REBOOT
} from '../../../constants/DeviceManager';
import {useHistory} from 'react-router-dom';
import DialogUpdateFirmware from '../common/dialogs/DialogUpdateFirmware';
import CustomAction from "./CustomAction";
import DialogLogMultiselectDownload from "../common/dialogs/DialogLogMultiselectDownload";
import DeviceHeaderSubMenu from "./DeviceHeaderSubMenu";
import AlertMessage from "../common/AlertMessage";
import DialogLogSettings from "../common/dialogs/DialogLogSettings";
import DialogConfirm from "../common/dialogs/DialogConfirm";

function EditMenuHeader() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialogFirmware, setOpenDialogFirmware] = useState(false);
    const [openConfigLogs, setOpenConfigLogs] = useState(false);
    const [openDownloadLogs, setOpenDownloadLogs] = useState(false);
    const [devicesArray, setDevicesArray] = useState([]);
    const [openSubMenu, setOpenSubMenu] = useState(false);
    const [subMenuKey, setSubMenuKey] = useState(null);
    const [openDownloadAlert, setOpenDownloadAlert] = useState(false);
    const [messageAlert, setMessageAlert] = useState("");
    const [value, setValue] = useState(0);
    const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
    const [devicesId, setDevicesId] = useState(false);
    const [options, setOptions] = useState(null);
    const [actionToExecute, setActionToExecute] = useState(null);
    const [messageDialog, setMessageDialog] = useState('');
    const dispatch = useDispatch();
    const history = useHistory();
    // const windowHeight = useSelector(state => state.resize.windowHeight);
    const selectedRows = useSelector(state => state.deviceManager.selectedRows);
    // const mapSelected = useSelector(state => state.deviceManager.mapSelected);
    const canEdit = useSelector(state => state.user.permissions['edit-infrastructure-devices']);
    const isOnCloud = useSelector(state => state.deviceManager.isOnCloud);
    const fetchingDownloadLogs = useSelector(state => state.deviceManager.fetchingDownloadLogs);


    const handleOptions = () => {
        if(Object.keys(selectedRows).length === 0 || !canEdit){
            const defaultOptions = {
                update_firmware: false,
                initialize: false,
                reset: false,
                resync: false,
                // viewDetails: true,
                edit: false,
                logs: false,
                publish: false,
                start: false,
                stop: false,
                reboot: false,
                enable: false,
                disable: false,
                delete: false,
                // cancel: false
            }

            setOptions(defaultOptions);
        }else{
            const deviceIds = Object.keys(selectedRows);
            if(deviceIds.length > 0){
                let optionsForMultiple = Object.keys(selectedRows).reduce((acc, idDevice) => {
                    const optionsCurrentDevice = getOperationOptions(selectedRows[idDevice], isOnCloud);
                    if(Object.keys(acc).length === 0){
                        return optionsCurrentDevice;
                    }else{
                        let updateOptions = {}
                        for(const property in optionsCurrentDevice){
                            updateOptions[property] = Boolean(optionsCurrentDevice[property]) && Boolean(acc[property]);
                        }
                        return updateOptions;
                    }   
                }, {});
                const {cancel, viewDetails, ...headerOptions} = optionsForMultiple;
                setOptions(headerOptions);
            }
        }
    }

    useEffect(handleOptions, [selectedRows]);

    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClickItem = (action) => {
        if(action === EDIT){
            const pathEditDevice = `/device-manager/devices/${PATH_EDIT_DEVICE}/multiple`;
            history.replace(pathEditDevice);
        }else if(action === UPGRADE_FIRMWARE){
            setOpenDialogFirmware(true);
        }else if (action === LOGS) {
            setSubMenuKey(action);
            setOpenSubMenu(true)
        }else if(action === DELETE){
            const keys = Object.keys(selectedRows);
            const devicesToDelete = keys.map((key) => selectedRows[key]._id);
            setDevicesId(devicesToDelete);
            setOpenDialogConfirm(true);
            setActionToExecute(action);
            let message = keys.length === 1 ? `${selectedRows[keys[0]].title} will be removed. Are you sure to remove it?`: `${keys.length} devices will be removed. Are you sure to remove them?`;
            setMessageDialog(message)
            // dispatch(devicesDeleteMultipleRows(devicesToDelete));
        }else if(action === REBOOT){
            const keys = Object.keys(selectedRows);
            setOpenDialogConfirm(true);
            setActionToExecute(action);
            let message = keys.length === 1 ? `${selectedRows[keys[0]].title} will be rebooted. Are you sure to reboot it?`: `${keys.length} devices will be rebooted. Are you sure to reboot them?`;
            setMessageDialog(message)
        }else{
            dispatch(operateMultipleDevices(action))
        }

        if (action !== LOGS) {
            setAnchorEl(null);
        }
    };

    const handleExecuteAction = () => {
        if(actionToExecute === DELETE){
            dispatch(devicesDeleteMultipleRows(devicesId))
        } else if(actionToExecute === REBOOT){
            dispatch(operateMultipleDevices(actionToExecute))
        }
        setActionToExecute(null);
        setMessageDialog(null);
    };

    const handleOpenConfigurationLog = () => {
        const keys = Object.keys(selectedRows);
        const devicesToAdd = keys.map((key) => selectedRows[key]);
        setDevicesArray(devicesToAdd);
        setOpenConfigLogs(true)
    }

    const handleClickAway= () => {
        setAnchorEl(null);
        setOpenSubMenu(false);
    };

    const handleCloseConfigLogs = () => {
        setOpenConfigLogs(false)
    };

    const handleCloseDownloadLogs = () => {
        setOpenDownloadLogs(false)
    };

    const handleCloseDownloadAlert = () => {
        setOpenDownloadAlert(false);
    };

    const handleActionLogs = (action) => {
        setAnchorEl(null);
        setOpenSubMenu(false);
        if (action === CONFIGURE_LOGS) {
            handleOpenConfigurationLog(true);
            setValue(0)
        }else if (action === DOWNLOAD_LOGS) {
            if(Object.keys(fetchingDownloadLogs).length === 0) {
                handleOpenConfigurationLog(true);
                setValue(1)
            } else {
                const key = Object.keys(fetchingDownloadLogs)[0];
                setMessageAlert(`Device "${fetchingDownloadLogs[key].name}" has a download in progress.`)
                setOpenDownloadAlert(true);
            }
        }
    };

    const handleCloseLogsSubMenu = () => {
        setAnchorEl(null);
        setOpenSubMenu(false);
    };

    return (
        <React.Fragment>
            <ClickAwayListener onClickAway={handleClickAway}>
                <div className="actionContainer" style={{justifyContent:'flex-end'}}>
                    <div className="editMainContainer" onClick={handleClick}>
                        <MoreVertIcon className="editIcon" style={{marginRight: '2px'}}/>
                    </div>
                    {anchorEl && options && <DeviceActionList options={options} handleClickItem={handleClickItem}/>}
                    {anchorEl && openSubMenu ?
                        <DeviceHeaderSubMenu
                            itemKey={subMenuKey}
                            options={options}
                            handleActionLogs={handleActionLogs}
                            handleCloseLogsSubMenu={handleCloseLogsSubMenu}
                        />
                        : null}
                </div>
            </ClickAwayListener>
            {openDialogFirmware && <DialogUpdateFirmware open={openDialogFirmware} closeDialog={()=>setOpenDialogFirmware(false)}/>}
            {openConfigLogs && <DialogLogSettings open={openConfigLogs} handleClose={handleCloseConfigLogs} devicesArray={devicesArray} value={value} isMultiselect={true} logOptions={options && options.logs}/>}
            {/*{openConfigLogs && <DialogConfigurationLogs open={openConfigLogs} handleClose={handleCloseConfigLogs} devicesArray={devicesArray}/>}*/}
            {openDownloadLogs && <DialogLogMultiselectDownload open={openDownloadLogs} handleClose={handleCloseDownloadLogs} devicesArray={devicesArray} />}
            {openDownloadAlert && <AlertMessage openModal={openDownloadAlert} message={messageAlert} onClose={handleCloseDownloadAlert} />}
            {openDialogConfirm && <DialogConfirm
                open={openDialogConfirm}
                closeDialog={() => setOpenDialogConfirm(false)}
                actionSuccess={handleExecuteAction}
                actionCancel={()=>{}}
                message={messageDialog}
            />}
        </React.Fragment>
    );
}

export default EditMenuHeader;


const DeviceActionList = props => {
    const {options, handleClickItem} = props;
    const selectedRows = useSelector(state => state.deviceManager.selectedRows);
    const isOnCloud = useSelector(state => state.deviceManager.isOnCloud);
    
    const getProtocol = () => {
        let objProtocol = {protocol: '', all: false};

        if(Object.keys(selectedRows).length > 0){
            const protocols = Object.keys(selectedRows).map(deviceId => selectedRows[deviceId].protocol?selectedRows[deviceId].protocol:'');
            objProtocol.all = Boolean(protocols.reduce((acc, item) => acc === item?acc: null));
            objProtocol.protocol = objProtocol['all']?protocols[0]:''
        }

        return objProtocol;
    }
 
    const objectProtocol = getProtocol(); 
    const labelOptions = {
        update_firmware: 'Upgrade Firmware',
        initialize: 'Initialize',
        // reset: 'Reset Configuration',
        resync: 'Resync Reader Status',
        viewDetails: 'View Details',
        edit: 'Edit on Map',
        logs: 'Logs',
        publish: 'Publish',
        //test: 'Test', //line commented by ticket MWE-4463
        start: 'Start',
        stop: 'Stop',
        reboot: 'Reboot',
        enable: 'Enable',
        disable: 'Disable',
        delete: 'Delete',
        cancel: 'Cancel'
    };

    const getEnabledAction = (key) => {
        const specialOperations = ['initialize', 'reset'];
        if(objectProtocol.all && objectProtocol.protocol === 'https' && specialOperations.indexOf(key) > -1){
            return false;
        }else if(isOnCloud && specialOperations.indexOf(key) > -1){
            return false;
        }else{
            return options[key];
        }
    }
    
    return (
        <div className="ListMenu">
            <div className="liMenu">
                {Object.keys(options).map((key, index) =>
                    <CustomAction key={index}
                        enabled={getEnabledAction(key)} value={labelOptions[key]} 
                        protocol={(objectProtocol.all && objectProtocol.protocol) || ''} actionKey={key} 
                        handleAction={(e)=>handleClickItem(key)} />
                )}
            </div>
        </div>
    );
}