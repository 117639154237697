import React, {useState, useEffect} from 'react';

import MapInformation from './MapInformation';

const RecentMaps = ({maps, selectMap}) => {
    let [recentMapsState, setRecentMapsState] = useState([]);

    useEffect(() => {
        setRecentMapsState(maps);
    },[maps]);


    const renderMaps = () => {

        if(recentMapsState){
            const reverseArray = [];
            for(let i=recentMapsState.length-1; i >= 0; i--){
                reverseArray.push(<MapInformation key={i} siteName={recentMapsState[i].siteName} map={recentMapsState[i]} selectMap={selectMap}/>)
            }
            return reverseArray;
        } else {
            return null;
        }
    };

    return (
        <div className={"recent-maps-container"}>
            <div className={"recent-maps-content"}>
                {renderMaps()}
            </div>
        </div>
    )

};

export default RecentMaps;