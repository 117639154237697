import React, {useState} from 'react';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import {Popover, IconButton} from '@material-ui/core';
import SortedChecklist from '../../util/SortedChecklist';
import {deviceMoveColumn, deviceToggleColumn, deviceToggleAllColumns} from '../../../actions';
import {useDispatch} from 'react-redux';

const ColumnsDropDown = (props) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const {name, columns} = props;

    const handleTouchTap = e => {
        e.preventDefault();
        setOpen(true);
        setAnchorEl(e.currentTarget);
    }

    const handleRequestClose = () => {
        setOpen(false);
        setAnchorEl(null);
    }

    const handleMoveColumn = (dragIndex, hoverIndex) => {
        dispatch(deviceMoveColumn(name, dragIndex, hoverIndex));
    }

    const handleToggleColumn = (index, checked) => {
        dispatch(deviceToggleColumn(name, index, checked));
    }

    const handleToggleAllColumns = (e, checked) => {
        dispatch(deviceToggleAllColumns(name, checked));
    }

    return (
        <React.Fragment>
            <IconButton className="icon-button" onClick={handleTouchTap}>
                <ViewColumnIcon />
            </IconButton>
            <Popover open={open}
                         anchorEl={anchorEl}
                         anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                         transformOrigin={{horizontal: 'right', vertical: 'top'}}
                         onClose={handleRequestClose}
                         transitionDuration={100}>
                    <SortedChecklist
                        selectAllText={"Columns"}
                        className={"columns-drop-down"}
                        data={columns}
                        onMoveItem={handleMoveColumn}
                        onCheckItem={handleToggleColumn}
                        onCheckSelectAll={handleToggleAllColumns}
                        keyAttribute={"name"}
                        labelAttribute={"label"}
                        checkedAttribute={"enabled"}/>
                </Popover>
        </React.Fragment>
    )
}

export default ColumnsDropDown;