import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { updateStateFormTemplate } from "../../../../actions";
import { makeStyles } from '@material-ui/core/styles';
import {RadioGroup, FormControlLabel, Radio, Divider} from '@material-ui/core';
import {ADD_TEMPLATE, HIGHLIGHT_STYLE} from '../../../../constants/DeviceManager';

const useStyles = makeStyles({
    checked: {
        color: "#007CB0 !important",
    }
});

const TriggersPanel = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const triggerSettings = useSelector(state => state.deviceManager.templateForm.template.triggerSettings);
    const reporting = useSelector(state => state.deviceManager.templateForm.template.reporting);
    const templateFormLoaded = useSelector(state => state.deviceManager.templateFormLoaded);

    //flag to enable highlights in input fields
    const isHighlight = templateFormLoaded !== ADD_TEMPLATE;

    //CONSTANTS KEYS OF TRIGGER SETTINGS
    const TRIGGER = 'trigger';
    const TRANSITION_STATE = 'transitionState';
    const NUM_READS = 'numReads';
    const MILLISECONDS = 'milliseconds';
    const ROUNDS = 'rounds';

    //CONSTANTS transitionState
    const HIGH = "HIGH";
    const LOW = "LOW";

    //states for radios
    const [startTriggerGroup, setStartTriggerGroup] = useState(getStartState(triggerSettings.start));
    const [stopTriggerGroup, setStopTriggerGroup] = useState(getStopState(triggerSettings.stop));
    
    //states for values
    const [startTrigger, setStartTrigger] = useState(triggerSettings.start.trigger || "");
    const [startTransition, setStartTransition] = useState(triggerSettings.start.transitionState || LOW);
    const [stopTrigger, setStopTrigger] = useState(triggerSettings.stop.trigger || "");
    const [stopNumReads, setStopNumReads] = useState(triggerSettings.stop.numReads || 0);
    const [stopMilis, setStopMilis] = useState(triggerSettings.stop.milliseconds || 0);
    const [stopRounds, setStopRounds] = useState(triggerSettings.stop.rounds || 0);
    const [stopTransition, setStopTransition] = useState(triggerSettings.stop.rounds || LOW);

    useEffect(()=>{

    },[startTrigger, startTransition]);

    function getStartState(start){
        if(Object.keys(start).length === 0){
            return 1;
        }else{
            return 2;
        }
    }

    function getStopState(stop){
        if(Object.keys(stop).length === 0){
            return 1;
        }else if(NUM_READS in stop){
            return 2;
        }else if(MILLISECONDS in stop){
            return 3;
        }else if(ROUNDS in stop){
            return 4;
        }else{
            return 5;
        }
    }

    let styleRadios = {color: '#000', paddingTop: 5, paddingBottom: 5, backgroundColor:'transparent'};
    let styleInput = {width: '3em', margin: '0 8px', backgroundColor: 'white', textAlign:'center'};

    const handleChangeReport = (key, value) => {
        //highlighting
        if(isHighlight && key){
            document.getElementById(`trigger-${key}`).style["box-shadow"] = HIGHLIGHT_STYLE;
        }
        dispatch(updateStateFormTemplate('reporting', key, value));
    };

    const handleChangeStartValue = (action, name, value, option) => {
        //highlighting the radiobutton group
        if(isHighlight){
            document.getElementById('trigger-startTriggerGroup').style["box-shadow"] = HIGHLIGHT_STYLE;
        }

        //changing in redux
        const object = {};
        if(name === TRIGGER){
            object.trigger = value;
            object.transition = startTransition;
        }else{
            object.trigger = startTrigger;
            object.transition = value;
        }
        dispatch(updateStateFormTemplate('triggerSettings', 'start', object));
        action(value);
        setStartTriggerGroup(option);
    }

    const handleChangeStopValue = (action, name, value, option) => {
        //highlighting the radiobutton group
        if(isHighlight){
            document.getElementById('trigger-stopTriggerGroup').style["box-shadow"] = HIGHLIGHT_STYLE;
        }
        
        //changing in redux
        const object = {};
        switch(name){
            case TRIGGER:
                object.trigger = value;
                object.transition = startTransition;
            break;
            case TRANSITION_STATE:
                object.trigger = startTrigger;
                object.transition = value;
            break;
            case NUM_READS:
                object.numReads = value;
            break;
            case MILLISECONDS:
                object.milliseconds = value;
            break;
            case ROUNDS:
                object.rounds = value;
            break;
            default:
            break;
        }
        dispatch(updateStateFormTemplate('triggerSettings', 'stop', object));
        action(value);
        setStopTriggerGroup(option);
    }

    const handleChangeStart = (name, option) => {
        //highlighting
        if(isHighlight && name){
            document.getElementById(`trigger-${name}`).style["box-shadow"] = HIGHLIGHT_STYLE;
        }

        let object = {};
        switch(option){
            case 2:
                object.trigger = startTrigger;
                object.transition = startTransition;
            break;
            case 1:
            default:
            break;
        }
        setStartTriggerGroup(option);
        dispatch(updateStateFormTemplate('triggerSettings', 'start', object));
    }

    const handleChangeStop = (name, option) => {
        //highlighting
        if(isHighlight && name){
            document.getElementById(`trigger-${name}`).style["box-shadow"] = HIGHLIGHT_STYLE;
        }

        let object = {}
        switch(option){
            case 5:
                object.trigger = stopTrigger;
                object.transition = stopTransition;
            break;
            case 4:
                object.rounds = stopRounds;
            break;
            case 3:
                object.milliseconds = stopMilis;
            break;
            case 2:
                object.numReads = stopNumReads;
            break;
            case 1:
            default:
            break;
        }
        setStopTriggerGroup(option);
        dispatch(updateStateFormTemplate('triggerSettings', 'stop', object));
    }

    return (
        <div style={{width: '100%'}}>
            <div className="trigger-items">
                <span className="items-header">Start reading</span>
                <RadioGroup id={'trigger-startTriggerGroup'} name={'startTriggerGroup'} value={startTriggerGroup} 
                            onChange={e => handleChangeStart(e.target.name, Number(e.target.value))}>
                    <FormControlLabel value={1} control={<Radio classes={{checked:classes.checked}} style={styleRadios} size={"small"} />} label="When START is pressed" />
                    <FormControlLabel value={2} control={<Radio classes={{checked:classes.checked}} style={styleRadios} size={"small"} />} 
                        label={<label>
                            When START is pressed and GPI 
                            <select value={startTrigger} name={TRIGGER} onChange={e=>handleChangeStartValue(setStartTrigger,e.target.name,e.target.value,2)}
                                 style={styleInput}>
                                <option value="">-</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                            </select>
                            goes
                            <div className="toggle">
                                <label className={startTransition === HIGH ? "btn disabled" : "btn enabled"}
                                        onClick={e=>handleChangeStartValue(setStartTransition,TRANSITION_STATE,LOW,2)}>LO</label>
                                <label className={startTransition === HIGH ? "btn enabled" : "btn disabled"}
                                        onClick={e=>handleChangeStartValue(setStartTransition,TRANSITION_STATE,HIGH,2)}>HIGH</label>
                            </div>
                            </label>} />
                </RadioGroup>
                <span className="items-header" style={{paddingTop: 10}}>Stop reading</span>
                <RadioGroup id={'trigger-stopTriggerGroup'} name={'stopTriggerGroup'} value={stopTriggerGroup} 
                            onChange={e => handleChangeStop(e.target.name, Number(e.target.value))}>
                    <FormControlLabel value={1} control={<Radio classes={{checked:classes.checked}} style={styleRadios} size={"small"} />} 
                        label="When STOP is pressed" />
                    
                    <FormControlLabel value={2} control={<Radio classes={{checked:classes.checked}} style={styleRadios} size={"small"} />} 
                        label={<label>After <input type="number" placeholder={'0'} value={stopNumReads} name={NUM_READS}
                        onChange={e=>handleChangeStopValue(setStopNumReads,e.target.name,Number(e.target.value),2)} style={styleInput} /> tag read(s) </label>} />
                    
                    <FormControlLabel value={3} control={<Radio classes={{checked:classes.checked}} style={styleRadios} size={"small"} />} 
                        label={<label>After <input type="number" placeholder={'0'} value={stopMilis} name={MILLISECONDS}
                        onChange={e=>handleChangeStopValue(setStopMilis,e.target.name,Number(e.target.value),3)} style={styleInput} /> ms </label>} />
                    
                    <FormControlLabel value={4} control={<Radio classes={{checked:classes.checked}} style={styleRadios} size={"small"} />}
                        label={<label>After <input type="number" placeholder={'0'} value={stopRounds} name={ROUNDS}
                        onChange={e=>handleChangeStopValue(setStopRounds,e.target.name,Number(e.target.value),4)} style={styleInput} /> inventory rounds </label>} />
                    
                    <FormControlLabel value={5} control={<Radio classes={{checked:classes.checked}} style={styleRadios} size={"small"} />} 
                        label={<label>
                            When GPI 
                            <select value={stopTrigger} name={TRIGGER} onChange={e=>handleChangeStopValue(setStopTrigger,e.target.name,e.target.value,5)} style={styleInput}>
                                <option value="" disabled>-</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                            </select>
                            goes
                            <div className="toggle">
                                <label className={stopTransition === HIGH ? "btn disabled" : "btn enabled"} 
                                        onClick={e=>handleChangeStopValue(setStopTransition,TRANSITION_STATE,LOW,5)}>LO</label>
                                <label className={stopTransition === HIGH ? "btn enabled" : "btn disabled"} 
                                        onClick={e=>handleChangeStopValue(setStopTransition,TRANSITION_STATE,HIGH,5)}>HIGH</label>
                            </div>
                            </label>} />
                </RadioGroup>
                <Divider style={{marginTop: 15, marginBottom: 15}} />


                <div className="gpo-items">
                    <span className="items-header" style={{marginBottom:'8px'}}>Report Tag Data</span>

                    <div className="gpo-options">
                        <div style={{marginBottom:'8px'}}>
                            After
                            <input id={'trigger-numTags'} name={'numTags'} type={'number'} placeholder={'0'} style={styleInput}
                                    value={reporting.numTags} onChange={e=>handleChangeReport(e.target.name, Number(e.target.value))} />  
                                tags and after each being read for
                            <input id={'trigger-seconds'} name={'seconds'} type={'number'} placeholder={'0'} style={styleInput}
                                    value={reporting.seconds} onChange={e=>handleChangeReport(e.target.name, Number(e.target.value))} />
                                seconds
                        </div>
                        <div style={{marginBottom:'8px'}}>
                            <label style={{fontWeight:'bold',fontSize:'14px'}}>Autonomous Mode</label>
                            <input id="trigger-autoMode" name={'autoMode'}  checked={reporting.autoMode} onChange={e=>handleChangeReport(e.target.name, e.target.checked)}
                                    type="checkbox" className="checkbox" />
                            <label htmlFor="trigger-autoMode" className="switch" />
                        </div>

                        <div>
                            <label>
                                New Tag Event
                                <select id={'trigger-newTagEvent'} name={'newTagEvent'} style={{...styleInput, width:'auto'}} value={reporting.newTagEvent}
                                    onChange={e=>handleChangeReport(e.target.name, e.target.value)}>
                                    <option value="" disabled>-</option>
                                    <option value="Inmediate">Inmediate</option>
                                    <option value="Never">Never</option>
                                    <option value="Moderated">Moderated</option>
                                </select>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TriggersPanel;