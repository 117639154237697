import Card from "@material-ui/core/Card/Card";
import CardHeader from "@material-ui/core/CardHeader/CardHeader";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import React from "react";
import {makeStyles} from "@material-ui/core";
import {getColor, getAntennaStatusLabel} from "../common/tableComponent/Util";
import {DETAILS_TEMPLATE} from "../../../constants/DeviceManager";

const useStyles = makeStyles(theme =>({
    root: {
        minWidth: '166px',
        marginLeft: '7px',
        marginRight:'7px',
        marginTop:'7px',
        borderStyle:'solid',
        borderWidth:'1px',
        borderColor:'#D4D1D1',
        color:'#484848',
        fontSize:'12px',
        borderRadius:0,
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        background: '#f2f6f6',
    },
}));

const CardsAntennas=({antennas, detailsScreen})=>{
    const classes = useStyles();

    const antennaColor = (status, disabled) => {
        return <div style={{width:'12px',height:'12px',borderRadius:'12px',backgroundColor:getColor(status, disabled)}} />
    };

    const styleField = {display:'flex', flexDirection: 'row', alignItems: 'center'};
    return (
    <div style={{background:"#F6F2F2"}}>
        <div className="dd-antennas-title-container">
                    <span className="dd-title">
                    Antennas
                    </span>
        </div>
        {antennas.map((antenna, index)=>(
            <Card className={classes.root} key={index}>
                <CardHeader title={<div>Antenna { (index + 1)}</div>} disableTypography={true}
                            className="dd-card-header"/>
                            
                <CardContent style={{padding:'7px', background: '#f6f2f2'}}>
                    <div className="dd-card-content">
                        <Checkbox
                            checked={!antenna.disabled}
                            value="checkedB"
                            color="primary"
                            className="dd-checkbox"
                            size="small"
                        /><span style={{display:'block'}}>Enabled</span>
                        {detailsScreen && detailsScreen === DETAILS_TEMPLATE ? null : 
                            <div style={styleField}><b>Name:</b>&nbsp;<span>{antenna.title}</span></div>}
                        {detailsScreen && detailsScreen === DETAILS_TEMPLATE ? null : 
                            <div style={styleField}><b>Status:</b>&nbsp;<span style={{textTransform: "capitalize"}}>{getAntennaStatusLabel(antenna.status, antenna.disabled)}</span>
                            &nbsp;&nbsp;<span style={{display:'inline-block'}}>{antennaColor(antenna.status, antenna.disabled)}</span></div>}
                    </div>
                </CardContent>
            </Card>
        ))}
    </div>
)};
export default CardsAntennas;