//import SvgIcon from '@material-ui/core/SvgIcon';
import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';
import PropTypes from 'prop-types';

const PinGroupIcon = (props) => {

    const {color,borderColor,...other} = props;


    return (
        <SvgIcon {...other}>
            <g fill={color} stroke={borderColor} strokeWidth={8.5} transform="scale(0.16)">
              <g>
                <path
                    d="M99.498 52.72c0-26.49-21.27-47.963-47.51-47.963-26.238 0-47.51 21.473-47.51 47.963 0 11.647 4.117 22.326 10.957 30.632l36.594 51.379 37.13-52.156a47.417 47.417 0 0 0 3.657-5.328l.375-.543-.061-.304c4.043-7.06 6.368-14.94 6.368-23.68"
                    transform="matrix(.53274 0 0 .50592 -4.554 19.305)" />
                <path
                    d="M75.35 50.56c0 12.83-10.671 23.238-23.835 23.238-13.165 0-23.834-10.408-23.834-23.237 0-12.833 10.67-23.238 23.834-23.238 13.167 0 23.835 10.404 23.835 23.238"
                    transform="matrix(.53274 0 0 .50592 -4.554 19.305)"
                    fill="#fff"/>
              </g>
              <g>
                <path
                    transform="matrix(.53274 0 0 .50592 99.163 19.305)" d="M99.498 52.72c0-26.49-21.27-47.963-47.51-47.963-26.238 0-47.51 21.473-47.51 47.963 0 11.647 4.117 22.326 10.957 30.632l36.594 51.379 37.13-52.156a47.417 47.417 0 0 0 3.657-5.328l.375-.543-.061-.304c4.043-7.06 6.368-14.94 6.368-23.68"/>
                <path
                    fill="#fff"
                    transform="matrix(.53274 0 0 .50592 99.163 19.305)" d="M75.35 50.56c0 12.83-10.671 23.238-23.835 23.238-13.165 0-23.834-10.408-23.834-23.237 0-12.833 10.67-23.238 23.834-23.238 13.167 0 23.835 10.404 23.835 23.238"/>
              </g>
              <g>
                <path
                    d="M124.743 83.122c0-25.184-20.766-45.598-46.385-45.598-25.617 0-46.385 20.414-46.385 45.598 0 11.073 4.02 21.225 10.698 29.122l35.727 48.845 36.251-49.584a46.294 45.079 0 0 0 3.57-5.065l.367-.517-.06-.289c3.947-6.711 6.217-14.203 6.217-22.512"
                    fill="#fff"
                    strokeWidth={0}
                    transform="matrix(1.00392 0 0 .91041 -3.665 3.339)"/>
                <g>
                  <path
                      d="M99.498 52.72c0-26.49-21.27-47.963-47.51-47.963-26.238 0-47.51 21.473-47.51 47.963 0 11.647 4.117 22.326 10.957 30.632l36.594 51.379 37.13-52.156a47.417 47.417 0 0 0 3.657-5.328l.375-.543-.061-.304c4.043-7.06 6.368-14.94 6.368-23.68"
                      transform="matrix(.80184 0 0 .76377 33.586 41.66)"/>
                  <path d="M75.35 50.56c0 12.83-10.671 23.238-23.835 23.238-13.165 0-23.834-10.408-23.834-23.237 0-12.833 10.67-23.238 23.834-23.238 13.167 0 23.835 10.404 23.835 23.238"
                      transform="matrix(.80184 0 0 .76377 33.586 41.66)"
                      fill="#fff"/>
                </g>
              </g>
          </g>
        </SvgIcon>
    );

};

PinGroupIcon.propTypes = function(){
    return {
        color: PropTypes.string,
        borderColor: PropTypes.string
    };
};




export default PinGroupIcon;
