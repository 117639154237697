import {VALIDATION_TYPE_CUSTOM, VALIDATION_TYPE_REGEXP, VALIDATION_TYPE_REQUIRED, VALIDATION_JSON_FORMAT, VALIDATION_ANTENNAS_REQUIRED} from "../../constants/DeviceManager";

export const validateDevice=(templateForm,templateFormLoaded,needsValidationFields)=>{
    let error=false;
    let antennasWithIssues = [];
    let firstSectionWithError = null;
    Object.keys(needsValidationFields).forEach(sectionKey=>{
        const section=needsValidationFields[sectionKey];
        Object.keys(section).forEach(field=>{
            const validationInfo=section[field];
            if(validationInfo.validateForms!=null&&validationInfo.validateForms.indexOf(templateFormLoaded)>=0) {
                const pathToField = field.split('.');
                const fieldValue = pathToField.reduce((reducedObject, currentKey) => reducedObject[currentKey], templateForm);

                switch(validationInfo.type){
                    case VALIDATION_TYPE_CUSTOM:
                        validationInfo.error = validateCustom(field, fieldValue, validationInfo, templateForm);
                        break;
                    case VALIDATION_TYPE_REQUIRED:
                        validationInfo.error = validateRequired(fieldValue);
                        break;
                    case VALIDATION_TYPE_REGEXP:
                        validationInfo.error = validateRegexp(fieldValue, validationInfo.regexp);
                        break;
                    case VALIDATION_JSON_FORMAT:
                        validationInfo.error = validateJsonString(fieldValue);
                        break;
                    case VALIDATION_ANTENNAS_REQUIRED:
                        antennasWithIssues = checkAntennas(fieldValue);
                        validationInfo.error  = antennasWithIssues.length > 0;
                        break;
                    default:
                        break;
                }

                if(validationInfo.error === true){
                    error = true;

                    // putting the first section with error validation
                    if(!firstSectionWithError){
                        firstSectionWithError = sectionKey;
                    }
                }
            }
        });
    });

    let customNeedsValidationFields = {...needsValidationFields};
    customNeedsValidationFields.rfidAntennas.rfidAntennas.issues = antennasWithIssues;
    return {error,needsValidationFields: customNeedsValidationFields, focusedSection: firstSectionWithError};

}

const checkAntennas = rfidAntennas => {
    let titlesArray = rfidAntennas.map(item => item.title);
    let findDuplicatesOrEmptyNames = titlesArray.filter((item, index) => item === "" || titlesArray.indexOf(item) !== index);
    let antennasWithIssues = rfidAntennas.reduce((acc, item, index) => findDuplicatesOrEmptyNames.indexOf(item.title) > -1? [...acc, index]: acc, []);
    return antennasWithIssues;
}

const validateJsonString = str => {
    if(str === undefined || str === null || typeof(str) === 'object'){
        return false;
    }

    try {
        JSON.parse(str);
    } catch (e) {
        return true;
    }
    return false;
}

function validateRequired(fieldValue){
    let error=false;
    if(fieldValue==null||fieldValue===''){
        error=true;
    }
    return error;


}
function validateRegexp(fieldValue,regexp){
    let error=false;

        error=true;
// console.log("error",error);
    return error;
}
function validateCustom(field,fieldValue,validationInfo,templateForm){
    let error=false;
    if(field==='minRssi'&&templateForm.operationMode==='inventory'){
        if(fieldValue!=null&&fieldValue!==''&&(isNaN(fieldValue)||fieldValue>=0)){
            error=true;
        }
    } else if(field==='tagFilter.value'){

        if ((fieldValue == null || fieldValue === '')&&
            ((templateForm.tagFilter.match != null && templateForm.tagFilter.match !== '')
            || (templateForm.tagFilter.operation != null && templateForm.tagFilter.operation !== ''))) {
            error=true;
        }
    } else if(field==='tagFilter.match'){
        if((fieldValue==null || fieldValue==='') && (templateForm.tagFilter.value!=='' && templateForm.tagFilter.value != null)){
            error=true;
        }
    } else if(field==='tagFilter.operation'){
        if((fieldValue==null || fieldValue==='') &&
            templateForm.tagFilter.value!=='' && templateForm.tagFilter.value!= null &&
            templateForm.tagFilter.match!=='' && templateForm.tagFilter.match!= null){
            error=true;
        }
    }else if(field==='tagFilter.length'){
        if((fieldValue==null || fieldValue==='') &&
            templateForm.tagFilter.value!=='' && templateForm.tagFilter.value!= null && templateForm.tagFilter.value?.length % 2 !== 0 ){
            error=true;
        }
    }else if(field==='inventoryInterval.value'&&templateForm.operationMode==='inventory'){

        if ((fieldValue == null ||fieldValue === ''|| isNaN(fieldValue) || fieldValue <= 0) &&
            (templateForm.inventoryInterval.unit != null && templateForm.inventoryInterval.unit !== '')) {
            error=true;
        }
    } else if(field==='inventoryInterval.unit'&&templateForm.operationMode==='inventory'){
        if((fieldValue==null || fieldValue==='') && parseFloat(templateForm.inventoryInterval.value) > 0){
            error=true;
        }
    } else if(field==='portalSettings.startTrigger.port'&&templateForm.operationMode==='portal'){
        if((fieldValue==null ||fieldValue === ''|| isNaN(fieldValue) || fieldValue<=0)&&
            (templateForm.portalSettings.startTrigger.signal != null && templateForm.portalSettings.startTrigger.signal !== '')){
            error=true;
        }
    } else if(field==='portalSettings.startTrigger.signal'&&templateForm.operationMode==='portal'){
        if((fieldValue==null || fieldValue==='') && parseInt(templateForm.portalSettings.startTrigger.port) > 0){
            error=true;
        }
    }

    return error;
}