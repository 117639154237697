import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import {Button, DialogActions, DialogTitle} from '@material-ui/core';
import {DialogContent} from '@material-ui/core/es';
import Checkbox from "@material-ui/core/Checkbox/Checkbox";

import FilterTree from "../../landing/FilterTree";
import {LANDING_DEVICE_TABLE} from "../../../../constants/DeviceManager";
import CrossBrowserTable from "../tableComponent/CrossBrowserTable";
import CustomCell from "../../landing/CustomCell";
import {
    cleanDownloadLogs,
    changeLogDownloadType,
    cleanDownloadTypes,
    setDownloadError
} from '../../../../actions';
import FetchScreenLogs from "../FetchScreenLogs";
const DOWNLOAD_LOGS_TITLE = "Download Logs From Device";
const FETCH_MESSAGE = "Getting Log From Device";

const DialogLogMultiselectDownload = ({open, handleClose, devicesArray}) => {

    const [sysLog, setSysLog] = useState(false);
    const [packetLog, setPacketLog] = useState(false);
    const [header, setHeader] = useState([]);
    const [headerHeight, setHeaderHeight] = React.useState(0);

    const logDialogColumns = useSelector(state => state.deviceManager.logMultiselectDialogColumns);
    const logsDownloadTypes = useSelector(state => state.deviceManager.logsDownloadTypes);
    const {error, message} = useSelector(state => state.deviceManager.errorDownload);
    const fetchingDownloadLogs = useSelector(useState => useState.deviceManager.fetchingDownloadLogs);

    const dispatch = useDispatch();

    const handleLoadColumns = () => {
        const keys = Object.keys(logDialogColumns);
        const headerColumns = keys.map((key) => logDialogColumns[key]);
        setHeader(headerColumns);

        return () => {
            dispatch(cleanDownloadLogs());
            dispatch(cleanDownloadTypes());
        }
    };

    useEffect(handleLoadColumns, []);


    const customHeader = ({text, tableWidth, tableHeight, itemKey}) => {
        const items = devicesArray && devicesArray.map(item => item).filter((value, index, self) => self.indexOf(value) === index);
        let headerDescription = header.filter( headerDescription =>{ return headerDescription.key === itemKey; });
        const columnConfig = logDialogColumns[itemKey];
        let statusSortable = false;
        if( headerDescription && headerDescription.length > 0){
            statusSortable = headerDescription[0].sortable;
        }

        return (
            <FilterTree sortIndex={null}
                        items={items}
                        text={text}
                        tableWidth={tableWidth}
                        tableHeight={tableHeight}
                        tree={false}
                        itemKey={itemKey}
                        type={'devices'}
                        statusSortable={statusSortable}
                        columnConfig={columnConfig}
                        tableScreen={LANDING_DEVICE_TABLE}
            />
        )
    };


    const handleChangeSysLog = (e) => {
        setSysLog(!sysLog);

        const logType = [...logsDownloadTypes];
        const index = logType.indexOf(e.target.value);
        if(index > -1){
            logType.splice(index, 1)
        } else {
            logType.push(e.target.value);
        }
        dispatch(changeLogDownloadType(logType))
    };

    const handleChangePacketLog = (e) => {
        setPacketLog(!packetLog);
        const logType = [...logsDownloadTypes];
        const index = logType.indexOf(e.target.value);
        if(index > -1){
            logType.splice(index, 1)
        } else {
            logType.push(e.target.value);
        }
        dispatch(changeLogDownloadType(logType))
    };

    const handleCloseErrorDialog = () => {
        dispatch(setDownloadError(false, null));
    };


    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
            >
                <DialogTitle style={{paddingRight: 50}}>
                    <div style={{fontWeight:'300',fontSize:'0.9em'}}>
                        {DOWNLOAD_LOGS_TITLE}
                    </div>
                </DialogTitle>
                <DialogContent style={{maxHeight: '415px', overflow:'hidden'}}>

                    <div className="data-entry-form" style={{ padding: '1em 0', border: '1px solid #cecece'}}>
                        <div className="basic-input"
                             style={{
                                 display: 'flex',
                                 justifyContent: 'space-between',
                                 flex: '1 1 auto'
                             }}
                        >
                            <div style={{display:'flex', justifyContent: 'start', flex: '1 0'}}>
                                <div style={{marginRight: '25px'}} className="dd-logs-type">
                                    <Checkbox
                                        className='dd-logs-type-checkbox'
                                        checked={logsDownloadTypes.indexOf('syslog') !== -1}
                                        onChange={handleChangeSysLog}
                                        size="medium"
                                        disableTouchRipple={true}
                                        value="syslog"
                                    />
                                    <span style={{fontWeight:'bold'}}>Sys Log</span>
                                </div>
                            </div>
                            <div style={{display:'flex', justifyContent: 'start', flex: '3 1'}}>
                                <div className="dd-logs-type">
                                    <Checkbox
                                        value="radioPacketLog"
                                        className='dd-logs-type-checkbox'
                                        checked={logsDownloadTypes.indexOf('radioPacketLog') !== -1}
                                        onChange={handleChangePacketLog}
                                        size="medium"
                                        disableTouchRipple={true}
                                    />
                                    <span style={{fontWeight:'bold'}}>Radio Packet Log</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div className="data-entry-form" style={{padding: '1em 0.5em', border: '1px solid #cecece'}}>
                        <div className="basic-input"
                             style={{
                                 display: 'flex',
                                 justifyContent: 'space-between',
                                 flex: '1 1 auto'
                             }}
                        >
                            <div style={{flex: '3 1'}}>
                                <label style={{fontWeight:'bold'}}>Level: </label>
                                <select disabled={logLevel}>
                                    <option>Select an option</option>
                                    <option>Info</option>
                                    <option>Verbose</option>
                                    <option>Debug</option>
                                    <option>Error</option>
                                </select>
                            </div>
                        </div>
                    </div>*/}
                    <Divider/>
                    <div className="data-entry-form" style={{display: 'flex', justifyContent: 'space-between', padding: '1em 0'}}>
                        <span style={{fontWeight: 'bold'}}>Devices selected:</span>
                    </div>
                    <div style={{width: '800px', height:'245px'}}>
                        {Object.keys(fetchingDownloadLogs).length > 0 && <FetchScreenLogs message={FETCH_MESSAGE} />}
                        <CrossBrowserTable
                            data={devicesArray}
                            rowCount={devicesArray.length}
                            showCheckColumn={false}
                            headerRow={header}
                            headerRender={(...rest) => customHeader(...rest)}
                            cellRender={(...rest) => CustomCell(...rest)}
                            selectedRows={{}}
                            onClickRow={()=>{}}
                            onSelectedAll={()=>{}}
                            modernBrowser={true}
                            appliedFiltersHeight={headerHeight}
                            setHeaderHeight={setHeaderHeight}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    {/*<Button style={{background: '#007CB0', color: '#fff', padding: '0.5em'}} onClick={handleDownloadLog} component="span">
                        <FontIcon className="material-icons">
                            get_app
                        </FontIcon>
                        Download Logs
                    </Button>*/}
                    <Button style={{background: '#fff', color: '#007CB0', padding: '0.5em', textTransform: 'none'}} onClick={handleClose} component="span">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            {error &&  <Dialog
                open={error}
                onClose={() => {}}
                maxWidth="xs"
            >
                <DialogContent style={{maxHeight: '415px', overflow:'hidden'}}>
                    <div style={{width: 250, display:'inline-block',textAlign:'left', height: 'auto'}}>
                        {message}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button style={{background: '#fff', color: '#007CB0', padding: '0.5em'}} onClick={handleCloseErrorDialog} component="span">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>}
        </React.Fragment>
    )
};

export default DialogLogMultiselectDownload;