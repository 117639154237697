import React, {useRef, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import MapOSM from "../../../utils/ol/MapOSM";
import {loadDevicesSites, loadSiteAndMap, changeMapView} from '../../../actions/device-manager';
import {LOCAL_MAP_VIEW} from "../../../constants/DeviceManager";
import {fromLonLat} from "ol/proj";
import {Fill, Icon, Stroke, Style} from "ol/style";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import * as ReactDOMServer from "react-dom/server";
import PinIcon from '../../icons/PinIcon';
import PinShadowIcon from '../../icons/PinShadowIcon';
import Feature from "ol/Feature";
import {Point} from "ol/geom";
import {Pointer as PointerInteraction} from "ol/interaction";

const getImageSourceFromReactIcon = (icon) => {
    icon = React.cloneElement(icon,{xmlns:"http://www.w3.org/2000/svg"});
    let svg_string = ReactDOMServer.renderToStaticMarkup(icon);

    return "data:image/svg+xml;charset=utf8,"+ encodeURIComponent(svg_string);
};

let PIN_IMAGE = getImageSourceFromReactIcon(<PinIcon height={512} width={512} color={"#269bc5"} borderColor={"#2084a8"} style={{height:'auto',width:'auto'}}/>);
let PIN_SHADOW = getImageSourceFromReactIcon(<PinShadowIcon height={512} width={720} color="rgba(0,0,0,0.6)" style={{height:'auto',width:'auto'}}/>);

const PIN_STYLE = new Style({
    image: new Icon({
        anchor:[0.5,1],
        src:PIN_IMAGE,
        imgSize:[512,512],
        scale:(25.0 / 512)
    })
});

const PIN_STYLE_LARGE = new Style({
    image: new Icon({
        anchor:[0.5,1],
        src:PIN_IMAGE,
        imgSize:[512,512],
        scale:(35.0 / 512)
    })
});

const PIN_STYLE_HOVER = new Style({
    image: new Icon({
        anchor:[0.5,(30/40)],
        src:PIN_SHADOW,
        imgSize:[720,512],
        scale:(35.0 / 512)
    })
});

const WorldMapContainer = (props) => {
    const {resizeTrigger, orientation} = props;
    const mapRef = useRef();
    const sourceRef = useRef();
    let cursorRef = useRef();
    cursorRef.current = 'pointer';
    let  featureRef = useRef();
    let previousCursorRef = useRef();
    const [center, setCenter] = useState([0,0]);
    //const [zoom, setZoom] = useState(1);
    const [boundingBoxState, setBoundingBoxState] = useState([]);
    //const deviceManager = useSelector(state => state.deviceManager);
    const sitesForDevices = useSelector(state => state.deviceManager.sitesForDevices);
    const dispatch = useDispatch();

    const loadSites = () => {
        dispatch(loadDevicesSites());
    };

    useEffect(loadSites, []);

    const getPins = (sites) => {
        return sites.filter(s=>(s.lat && s.long)).map(getPinFromPoint);
    };

    const getPinFromPoint = (point) => {
        let coord = fromLonLat([point.long,point.lat]);

        return {
            ...point,
            x:coord[0],
            y:coord[1]
        };

    };

    const getBoundingBox = (pins) => {
        const firstPin = pins[0];
        return pins.reduce((boudingBox, pin)=>{
            if(pin.x < boudingBox[0]) boudingBox[0] = pin.x;
            if(pin.x > boudingBox[2]) boudingBox[2] = pin.x;
            if(pin.y < boudingBox[1]) boudingBox[1] = pin.y;
            if(pin.y > boudingBox[3]) boudingBox[3] = pin.y;

            return boudingBox;
        },[firstPin.x,firstPin.y,firstPin.x,firstPin.y]);
    };
    /*
    const getBoundingBoxByLonLat = (pins) => {
        const firstPin = pins[0];
        return pins.reduce((boudingBox, pin)=>{
            if(pin.long < boudingBox[0]) boudingBox[0] = pin.long;
            if(pin.long > boudingBox[2]) boudingBox[2] = pin.long;
            if(pin.lat < boudingBox[1]) boudingBox[1] = pin.lat;
            if(pin.lat > boudingBox[3]) boudingBox[3] = pin.lat;

            return boudingBox;
        },[firstPin.long,firstPin.lat,firstPin.long,firstPin.lat]);
    };

    const getBoundingBoxByLonLatShort = (pins) => {
        const firstPin = pins[0];
        return pins.reduce((boudingBox, pin)=>{
            if(pin.long < boudingBox[0]) boudingBox[0] = pin.long;
            if(pin.long < boudingBox[2] && pin.long > boudingBox[0]) boudingBox[2] = pin.long;
            if(pin.lat < boudingBox[1]) boudingBox[1] = pin.lat;
            if(pin.lat < boudingBox[3] && pin.lat > boudingBox[1]) boudingBox[3] = pin.lat;

            return boudingBox;
        },[firstPin.long,firstPin.lat,firstPin.long,firstPin.lat]);
    };*/

    const drawPins = (sites) => {
        if(sourceRef.current ){
            let arrayFeatures = [];
            for (let i = 0; i < sites.length; i++){
                let siteFeature = new Feature({
                    label: sites[i].name,
                    type: 'antenna',
                    draggable: false,
                    geometry: new Point(fromLonLat([sites[i].long, sites[i].lat]))
                });
                siteFeature.setId(sites[i].id);
                siteFeature.setStyle(PIN_STYLE);
                arrayFeatures.push(siteFeature);
            }

            sourceRef.current.addFeatures(arrayFeatures);
        }
    };

    const handleMoveEvent = (event) => {
        if(cursorRef.current) {
            let feature = mapRef.current.forEachFeatureAtPixel(event.pixel, (feature) => feature);
            let element = mapRef.current.getTargetElement();
            if (feature) {
               if (element.style.cursor !== cursorRef.current) {
                   previousCursorRef.current = element.style.cursor;
                   element.style.cursor = cursorRef.current;
                   featureRef.current = feature;
                   featureRef.current.setStyle([PIN_STYLE_HOVER, PIN_STYLE_LARGE]);
               }
            } else if (previousCursorRef.current !== undefined) {
                element.style.cursor = previousCursorRef.current;
                previousCursorRef.current = undefined;
                featureRef.current.setStyle(PIN_STYLE);
                featureRef.current = undefined;
            }
        }
    };

    const loadVectorLayer = () => {
        if(mapRef.current) {

            const vectorSource = new VectorSource({
                updateWhileInteracting: true,
                style: new Style({
                    fill: new Fill({
                        color: 'rgba(0, 0, 0, 0.3)'
                    }),
                    stroke: new Stroke({
                        width: 3,
                        color: 'rgba(0, 100, 240, 0.8)'
                    }),
                    text: "Device"
                })
            });

            const vectorLayer = new VectorLayer({
                zIndex: 30,
                source: vectorSource,
                stroke: new Stroke({
                    width: 3,
                    color: [255, 0, 0, 1]
                }),
                fill: new Fill({
                    color: [0, 0, 255, 0.6]
                })
            });
            mapRef.current.addLayer(vectorLayer);

            sourceRef.current = vectorSource;

            mapRef.current.addInteraction(new PointerInteraction({
                handleMoveEvent: handleMoveEvent
            }));
            let featureCount=0;
            mapRef.current.on('singleclick', (event) => {
                let feature = mapRef.current.forEachFeatureAtPixel(event.pixel, feature => {
                    if(featureCount===0) {
                        dispatch(loadSiteAndMap(feature.id_, null));
                        dispatch(changeMapView(LOCAL_MAP_VIEW));
                    }
                    featureCount++;
                });

                return !!feature;
            })
        }
    };

    const loadPins = () => {
        const pins = sitesForDevices && getPins(sitesForDevices);
        if(!pins || !pins.length) return;

        const boundingBox = getBoundingBox(pins);

        const center = [
            ( boundingBox[0] + boundingBox[2] ) / 2,
            ( boundingBox[1] + boundingBox[3] ) / 2
        ];
        setCenter(center);
        /*
        const boundingBoxByLonLat = getBoundingBoxByLonLat(pins);
        const distance = Math.sqrt(
            Math.abs(
            Math.pow(boundingBoxByLonLat[0]-boundingBoxByLonLat[2], 2) + Math.pow(boundingBoxByLonLat[1]-boundingBoxByLonLat[3], 2)
            ));

        const boundingBoxShort = getBoundingBoxByLonLatShort(pins);
        const shortDistance = Math.sqrt(
            Math.abs(
                Math.pow(boundingBoxShort[0]-boundingBoxShort[2], 2) + Math.pow(boundingBoxShort[1]-boundingBoxShort[3], 2)
            ));

        const zoomAux = (distance/(distance-shortDistance))*1.5;

        setZoom(zoomAux);*/
        setBoundingBoxState(boundingBox);
        loadVectorLayer();

        drawPins(sitesForDevices);
    };

    useEffect(loadPins, [sitesForDevices]);

    const handleFinishLoadMap = () => {
        mapRef.current.updateSize();
    };

    const handleResizeMap = () => {
        mapRef.current.updateSize();
        mapRef.current.getView().fit(boundingBoxState, {padding: [170, 50, 30, 150]});
    };

    useEffect(handleResizeMap, [resizeTrigger, orientation,boundingBoxState]);

    return (
        <>
            <MapOSM ref={mapRef} coordinates={center}  onFinishLoadMap={handleFinishLoadMap} />
        </>
    );
};

export default WorldMapContainer;