import React from 'react';
import PropTypes from 'prop-types';

import StyledDialog, {CancelButton, SaveButton} from '../../../util/Dialog';
import {Field, Form} from '../../../util/DataEntryTabularForm';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../../../../actions';
import {USERMGMT_RESET_PASSWORD} from '../../../../constants/DataEntryForms';

class ResetPasswordDialog extends React.Component{
    constructor(props) {
        super(props);
        this.handleSave = this.handleSave.bind(this);
    }

    static get propTypes(){
        return {
            onRequestClose: PropTypes.func.isRequired,
            resetUserPassword: PropTypes.func.isRequired,
            user: PropTypes.shape({
                id: PropTypes.string
            }),
        };
    }

    static get defaultProps(){
        return {
            user:{}
        };
    }

    handleSave(){
        let uuid = this.props.user.id;
        this.props.resetUserPassword(uuid).then(this.props.onRequestClose);
    }

    handleCancel() {
        this.props.onRequestClose();
    }

    render() {
        const { user } = this.props;

        return (
            <StyledDialog
                {...this.props}
                title={"Reset To Default Password"}
                contentStyle={{display:'inline-block',textAlign:'left', width: 360}}
                onClose={this.handleCancel.bind(this)}
                //style={{textAlign:'center'}}
                actions={
                    <>
                        <SaveButton label="Reset" id="reset-password" onClick={this.handleSave} />
                        <CancelButton style={{position:'absolute',left:8,bottom:8}} onClick={this.handleCancel.bind(this)} />
                    </>
                }
            >
                <Form fullWidth>
                    <Field label={"Username"}>
                        {user.username}
                    </Field>
                </Form>
            </StyledDialog>
        );
    }
}


/*
 ██████  ██████  ███    ██ ███    ██ ███████  ██████ ████████     ████████  ██████
██      ██    ██ ████   ██ ████   ██ ██      ██         ██           ██    ██    ██
██      ██    ██ ██ ██  ██ ██ ██  ██ █████   ██         ██           ██    ██    ██
██      ██    ██ ██  ██ ██ ██  ██ ██ ██      ██         ██           ██    ██    ██
 ██████  ██████  ██   ████ ██   ████ ███████  ██████    ██           ██     ██████

███████ ████████  █████  ████████ ███████
██         ██    ██   ██    ██    ██
███████    ██    ███████    ██    █████
     ██    ██    ██   ██    ██    ██
███████    ██    ██   ██    ██    ███████
*/


const mapStateToProps = state => ({
    open: state.dataEntry.open && state.dataEntry.activeForm === USERMGMT_RESET_PASSWORD,
    user: (state.dataEntry.activeForm === USERMGMT_RESET_PASSWORD || undefined) && state.dataEntry.initialFormData.user,
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        onRequestClose: actions.closeDataEntry,
        resetUserPassword: actions.resetUserPassword
    },dispatch)
});

export default connect(mapStateToProps,mapDispatchToProps)(ResetPasswordDialog);
