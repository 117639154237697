import React from 'react';
import PropTypes from 'prop-types';
import dateFormat from 'dateformat';
import StyledDialog from '../../../util/Dialog';
import FlatButton from '@material-ui/core/Button';
//import IconButton from "material-ui/IconButton";
import IconButton from "@material-ui/core/IconButton";
import FontIcon from '@material-ui/core/Icon';

class ViewConfigDialog extends React.Component{

    // constructor(){
    //     super();
    // }

    static get propTypes(){
        return {
            open: PropTypes.bool,
            configuration: PropTypes.any,
            onRequestClose: PropTypes.func,
            timestamp:PropTypes.any
        };
    }

    static get defaultProps(){
        const nop = function(){};
        return {
            open: false,
            onRequestClose: nop
        };
    }
    requestDownload(configuration,timestamp) {
        let stamp = new Date(timestamp)
        stamp = dateFormat(stamp, "mm_dd_yy_HH_MM_s_Z");
        let element = document.createElement("a");
        let content = JSON.stringify(configuration,null,'    ')
        let file = new Blob([content], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = "config_"+stamp+".json";
        element.click();
    }
    render() {

        const {
            configuration,
            onRequestClose,
            timestamp
        } = this.props;

        const mainButtons = (
            <>
                <IconButton style={{backgroundColor: 'transparent', color: 'inherit', height: 40, width: 40}} onClick={() => {this.requestDownload(configuration,timestamp)}} >
                    <FontIcon className="material-icons">
                    get_app
                    </FontIcon>
                </IconButton>
                <FlatButton label="OK" onClick={onRequestClose} variant="text">OK</FlatButton>
            </>
        );



        return (
            <StyledDialog
                {...this.props}
                title={"View Configuration"}
                modal={false}
                maxWidth={false}
                style={{padding:'0 24px'}}
                actions={mainButtons}
                onClose={onRequestClose}
            >
                <div style={{width: 800}}>
                    <div style={{background:'rgba(0,0,0,0.1)',border:'solid 1px',overflow:'auto',maxHeight:window.innerHeight*0.7}} ref={"container"}>
                        <pre style={{margin:'0',overflow:'visible'}}>
                            {JSON.stringify(configuration,null,'    ')}
                        </pre>
                    </div>
                </div>
            </StyledDialog>
        );
    }
}

export default ViewConfigDialog;
