import React, {useEffect, useRef, useState} from "react";
import MapOSM from "./MapOSM";
import {useDispatch, useSelector} from "react-redux";
import {changeRefMap, changeSiteManagerSiteSelected} from "../../actions";
import FeaturesMapOSM from './FeaturesMapOSM';


const WorldMapContainer=({coordinates=[0,0]})=>{

    const dispatch = useDispatch();
    const mapRefOSM = useRef();
    const updateMapSize = useSelector(state => state.reportMap.updateMapSize);
    const sites = useSelector(state => state.sites.sites);
    const siteManagerSiteHover = useSelector(state => state.sites.siteManagerSiteHover);
    const [pinsSites,setPinSites] = useState(null);
    function drawPinSites(){
        if( pinsSites !== null ){
            pinsSites.setHoverUID(siteManagerSiteHover);
            pinsSites.removeOldLayer();
            pinsSites.loadPins(sites);
            pinsSites.hoverSite();
        }
    }
    useEffect(drawPinSites,[sites,pinsSites,siteManagerSiteHover]);

    function loadFeaturesClass(){
        if(mapRefOSM.current) {
            setPinSites(new FeaturesMapOSM(mapRefOSM.current,siteManagerSiteHover,updateSiteManagerSiteSelected))
        }
    }
    useEffect(loadFeaturesClass, []);

    const updateSiteManagerSiteSelected = (siteId) =>{
        dispatch(changeSiteManagerSiteSelected(siteId));
    }

    function handleFinishLoadMapOSM(){
        drawPinSites();
        dispatch(changeRefMap(mapRefOSM.current));
    }

    // Render self hosted world map.
    return (
        <React.Fragment>
            <MapOSM ref={mapRefOSM}
                    coordinates={coordinates}
                    onFinishLoadMap={handleFinishLoadMapOSM}
                    updateMapSize={updateMapSize}
            />
        </React.Fragment>);
}

export default React.memo(WorldMapContainer,((prevProps,nextProps) => {
    return (prevProps.coordinates===nextProps.coordinates);
}));

// export default WorldLocalMap;