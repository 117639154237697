import React from 'react';
import PropTypes from 'prop-types';

import Paper from "@material-ui/core/Paper";

// import ChevronLeft from '@material-ui/icons/ChevronLeft';
// import ChevronRight from '@material-ui/icons/ChevronRight';
import {VerticalDragLine} from "../../../../common/app/views/table/header/DragLine";

/******************************************************************************
██   ██ ██ ██████  ███████         ██     ██████  ███████ ███████ ██ ███████ ███████
██   ██ ██ ██   ██ ██             ██      ██   ██ ██      ██      ██    ███  ██
███████ ██ ██   ██ █████         ██       ██████  █████   ███████ ██   ███   █████
██   ██ ██ ██   ██ ██           ██        ██   ██ ██           ██ ██  ███    ██
██   ██ ██ ██████  ███████     ██         ██   ██ ███████ ███████ ██ ███████ ███████

██████   █████  ███    ██ ███████ ██      ███████
██   ██ ██   ██ ████   ██ ██      ██      ██
██████  ███████ ██ ██  ██ █████   ██      ███████
██      ██   ██ ██  ██ ██ ██      ██           ██
██      ██   ██ ██   ████ ███████ ███████ ███████
******************************************************************************/

export default class HideResizePanels extends React.Component{

    static get propTypes(){
        return {
            leftPanel: PropTypes.node,
            rightPanel: PropTypes.node,
            onResize: PropTypes.func,
            onLeftPanelVisible: PropTypes.func,
            leftPanelVisible: PropTypes.bool
        };
    }

    constructor(props){
        super(props);
        this.lastSize = 300;
        this.state = {
            leftWidth: this.lastSize
        };

        this.handleToggleLeftPanel = this.handleToggleLeftPanel.bind(this);
        this.handleLeftResize = this.handleLeftResize.bind(this);
        this.handleLeftResizeEnd = this.handleLeftResizeEnd.bind(this);
        this.refLeft = this.refLeft.bind(this);
        this.refRight = this.refRight.bind(this);
    }

    render(){

        const {
            leftPanel,
            rightPanel,
            leftPanelVisible
        } = this.props;

        const {
            leftWidth
        } = this.state;

        // const TAB_SIZE = '2.5em';
        // const ICON_SIZE = '40px';

        // const tabStyle = {
        //     height:TAB_SIZE,
        //     width:TAB_SIZE,
        //     top:0,
        //     right:`-${TAB_SIZE}`,
        //     position:'absolute',
        //     borderRadius:'0 0.5em 0.5em 0',
        //     zIndex:2,
        //     color:'rgb(117,117,117)',
        //     cursor:'pointer'
        // };

        const leftWidthStr = `${leftWidth}px`;

        return (
            <div className="filled-up bucko" style={{position:'relative',margin:'0 -2em'}}>

                    <div className="filled-up" style={{width:leftWidthStr,left:(leftPanelVisible ? '2em' : `-${leftWidthStr}`)}} ref={this.refLeft}>
                        <Paper className="filled-up" >
                            {leftPanel}
                            {/*<Paper style={tabStyle} onClick={this.handleToggleLeftPanel}>*/}
                            {/*    {leftPanelVisible ?*/}
                            {/*        (<ChevronLeft style={{height:ICON_SIZE,width:ICON_SIZE,padding: 4,color:'#000'}} />):*/}
                            {/*        (<ChevronRight style={{height:ICON_SIZE,width:ICON_SIZE,padding: 4,color:'#000'}} />)*/}
                            {/*    }*/}
                            {/*</Paper>*/}
                            <VerticalDragLine
                                onResize={this.handleLeftResize}
                                onResizeEnd={this.handleLeftResizeEnd}
                            />
                        </Paper>
                    </div>


                    <div style={{position:'absolute',left:(leftPanelVisible ? `${leftWidth+32}px` : 0),top:0,bottom:0,right:'-2em'}} ref={this.refRight}>
                        <div style={{position:'absolute',left:'5px',right:0,top:0,bottom:0}}>
                            {rightPanel}
                        </div>
                    </div>

            </div>
        );
    }

    refLeft(ref){
        this.left = ref;
    }

    refRight(ref){
        this.right = ref;
    }

    handleLeftResize(size){
        if(!this.left || !this.right) return;

        // the following is (presumably) faster than going through a render cycle.
        this.left.style.width = `${size}px`;
        this.right.style.left = `${size+32}px`;
        this.lastSize = size;

        this.props.onResize(size);
    }

    handleLeftResizeEnd(){
        this.setState({
            leftWidth: this.lastSize
        });
        // this.props.onResize();
    }

    handleToggleLeftPanel(){
        this.left.style.transition = '1s';
        this.right.style.transition = '1s';
        this.props.onLeftPanelVisible();

        const refreshInterval = setInterval(()=>{
            this.props.onResize();
        },50);

        setTimeout(()=>{
            this.left.style.transition = 'none';
            this.right.style.transition = 'none';
            clearInterval(refreshInterval);
        },1100);
    }
}
