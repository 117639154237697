import React, {Component} from 'react';
import StyledDialog from "../../util/Dialog";
import WrappedTable from "../../util/report/WrappedTable";
import FlatButton from '@material-ui/core/Button';

class ModalImportDetails extends Component {

    constructor (props) {
        super(props);
        this.state = {
            errorColumns : [
                {
                    caption: "Row",
                    displayName: "Row",
                    name: "row",
                    enabled: true,
                    filter: 125,
                    sort: 0
                }/*,
                {
                   caption: "Code",
                   displayName: "Code",
                   name: "code",
                   enabled: true,
                   filter: 123,
                   sort: 0
               }*/, {
                   caption: "Message",
                   displayName: "Message",
                   name: "msg", 
                   enabled: true,
                   filter: 124,
                   sort: 0
               },{
                    caption: "Data",
                    displayName: "Data",
                    name: "data", 
                    enabled: true,
                    filter: 126,
                    sort: 0
               }               
            ],
            columnWidths:{
                code:100,
                row:100,
                msg:300,
                data:300
            }
        };
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose() {
        this.props.onRequestClose();
    }

    renderTable(data){
        const columns = this.state.errorColumns;
        const tableData = data.resultRow.map(item => {
            const row = {};
            columns.forEach(column => {
                if (column.name === 'row') {
                    row[column.name] = item[column.name] + 1;
                } else {
                    row[column.name] = item[column.name];
                }
            });
            return row;
        });

        return (
            <WrappedTable 
                actions={{}}
                data = {tableData} 
                columns = {columns}
                getRowHeight={this.getRowHeight}
                isSelected={null}
                maxInitialColumnSize={500}
                initiaColumnSizeOffset={60}
                adjustColumns
                onSelectAll={null}
                disableFillTable
                disableFilters
                disableSort
                getBaseColumnWidth={this.getBaseColumnWidth}
                onResizeColumn={this.handleResizeColumn}
                backgroundRow={data.resultRow.map(item => 0)}
                onTouchTapRow={null}

                sites={[]}//The component require this prop
                maps={[]}//The component require this prop
            />
        );
    }

    renderResults() {
        const { data, isMobile } = this.props;
        if (!data) {return;}

        let results = "The import process was successfully completed.";

        if (data.error) {
            results = (
                <div style={{overflow:'hidden',position:'relative',minHeight: '80px',height:'100%'}}>
                    <span style={{fontSize: isMobile? '12px': '14px' }}>{data.error}</span>
                    {(data.resultRow && data.resultRow.length > 0) && 
                        <div style={{overflow:'hidden',position:'relative',minHeight: '70px',height: isMobile ? '87%': '95%'}}>
                            {this.renderTable(data)}
                        </div>
                    }
                </div>
            );
        } else if (data.importing) {
            results = "The import process is running";
        } else if (data.resultRow && data.resultRow.length > 0) {
            results = (
                <div style={{overflow:'hidden',position:'relative',minHeight: '80px',height:'100%'}}>
                    {this.renderTable(data)}
                </div>
            );
        } else if (data.rows == null) {
            results = "Invalid File";
        }
        return results;
    }

    render() {
        const {open} = this.props;

        const modalImportButtons = (
            <>
                <FlatButton label="Confirm" onClick={this.handleClose} style={{visibility:'hidden',color:'#00779f'}} variant="text">Confirm</FlatButton>
                <FlatButton label="Close" onClick={this.handleClose} style={{float:'right',color:'#00779f'}} variant="text">Close</FlatButton>
            </>
        );

        return (
            <StyledDialog
                open={open}
                onClose={this.handleClose}
                title={"Import Result Details"}
                actions={modalImportButtons}
                contentStyle={{maxWidth: 800, height: 400}}
            >
                <div style={{width: '80vw', maxWidth: 800, height: 400}}>
                    {this.renderResults()}
                </div>
            </StyledDialog>
        );
    }
}

export default ModalImportDetails;