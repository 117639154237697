import React from 'react';
import {List, 
        ListItem, 
        ListItemText, 
        ListItemIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from "react-redux";
import items from './DeviceTypes';
import {ADD_TEMPLATE, EDIT_TEMPLATE, EDIT_DEVICE_CONFIG, EDIT_DEVICE_SAVED, EDIT_DEVICE_MANUALLY_ADDED} from '../../../constants/DeviceManager';
import {CONF_R2C} from '../../../constants/DeviceManager';

const useStyles = makeStyles({
    itemIcon: {
        minWidth: '40px'
    }
});

const TemplateSidebar = React.memo(props => {
    const {scrollView, deviceId} = props;
    const windowWidth = useSelector(state => state.resize.windowWidth);
    const templateFormLoaded = useSelector(state => state.deviceManager.templateFormLoaded);
    const rfidConfigurationType = useSelector(state => state.deviceManager.rfidConfigurationType);

    const classes = useStyles();
    const getList = (formLoaded) => {
        let excludeItems = (rfidConfigurationType === CONF_R2C)? ['Triggers', 'GPO']: [];
        let renderedItems = [];
        switch(formLoaded){
            case ADD_TEMPLATE:
                renderedItems = items.filter(object => [...excludeItems, 'Linked Devices', 'Identity'].indexOf(object.name) === -1);
                return !deviceId ? items.filter(object => object.name === 'Type') : renderedItems;
            case EDIT_TEMPLATE:
                renderedItems = items.filter(object => [...excludeItems, 'Identity'].indexOf(object.name) === -1);
                return renderedItems;
            case EDIT_DEVICE_MANUALLY_ADDED:
            case EDIT_DEVICE_CONFIG:
            case EDIT_DEVICE_SAVED:
                renderedItems = items.filter(object => [...excludeItems, 'Type', 'Linked Devices'].indexOf(object.name) === -1);
                return renderedItems;
            default:
                return null;
        }
    }

    return (
        <div className={"add-template-sidebar"}>
            {templateFormLoaded && <List>
                {getList(templateFormLoaded).map(v => (
                    <ListItem button 
                        key={v.name}
                        className={"add-template-sidebar-item"} 
                        onClick={() => scrollView(v.id)}
                        >
                        <ListItemIcon classes={{root:classes.itemIcon}} >
                            {v.icon}
                        </ListItemIcon>

                        {windowWidth > 500 && <ListItemText 
                            className={"add-template-sidebar-text"} 
                            primary={v.name} 
                            style={{fontWeight: 'bold'}} />}
                        
                    </ListItem>
                ))}
            </List>}
        </div>
    );
},(prevProps, nextProps) =>{
    return (
        prevProps.deviceId === nextProps.deviceId &&
        prevProps.windowWidth === nextProps.windowWidth
    );
})

export default TemplateSidebar;