import React, {useState} from "react";
// import FirmwareUpdateHistory from "./FirmwareUpdateHistory";
import GetAppIcon from '@material-ui/icons/GetApp';
import DialogUpdateFirmware from "./dialogs/DialogUpdateFirmware";
import IconButton from "@material-ui/core/IconButton";

const FirmwareVersion=({rowIndex, firmwareVersion})=>{
    const [open,setOpen]=useState(false);

    return (<div className='firmware-version-container'>
        <span>{firmwareVersion}</span>
        <IconButton alt='download' onClick={()=>setOpen(true)} style={{ color:'#449ccc',padding:8}}>
            <GetAppIcon />
        </IconButton>
        {open&&<DialogUpdateFirmware open={open} closeDialog={()=>setOpen(false)} rowIndex={rowIndex} />}
    </div>);
}

export default FirmwareVersion;