import React from 'react';
import PropTypes from 'prop-types';

export default class QuickStat extends React.Component{

    static get propTypes(){
         return {
             data: PropTypes.any,
             title: PropTypes.string,
             color: PropTypes.string
         };
    }

    static get defaultProps(){
        return {
            color: '#00779F'
        };
    }

    render(){

        const {data,title,color} = this.props;

        return (
            <div className="zebra-quick-stat">

                <div className="stat" style={{color}}>
                    {data}
                </div>

                <div className="footer">

                    <span>{title}</span>

                </div>

            </div>
        );
    }
}
