import React from 'react';

import BaseFilterForm from './BaseFilterForm';
import ControlledFilterForm from './ControlledFilterForm';
import CheckboxControlledFilterForm from './CheckboxControlledFilterForm';

import {CALL_FILTER_SCHEMA, MEDIAN_FILTER_SCHEMA, RATE_FILTER_SCHEMA} from './schemas';

const MedianFilterForm = (props)=>(<ControlledFilterForm {...props} schema={MEDIAN_FILTER_SCHEMA} />);
const RateFilterForm = (props) => (<CheckboxControlledFilterForm {...props} schema={RATE_FILTER_SCHEMA} />);
const CallFilterForm = (props) => (<ControlledFilterForm {...props} schema={CALL_FILTER_SCHEMA} />);

export {
    BaseFilterForm,
    ControlledFilterForm,
    MedianFilterForm,
    RateFilterForm,
    CallFilterForm
};
export const TEST = "test";
