import React from 'react';
import PropTypes from 'prop-types';
import {grey} from '@material-ui/core/colors';
import ZUtil from '../../utils/ZUtils';
import {getSpecialLoginAuthType} from '../../utils/auth'


function redirectTo(path) {
    window.location = path;
}

function SpecialLogin(props) {
    const specialAuthSettings = getSpecialLoginAuthType(props.authSettings);
    if (!specialAuthSettings) {
        return null;
    }

    const handleSpecialLogin = () => {
        redirectTo(specialAuthSettings.settings.redirectTo)
    };
    return (
        <p>
            <span style={{fontSize: '13px', color: '#fff'}}>{specialAuthSettings.settings.description}</span>
            <br />
            <button
                label={specialAuthSettings.settings.display}
                type="button"
                name="button"
                onClick={handleSpecialLogin}
                className="button-02"
                style={{width: '100%'}}
            >
                {specialAuthSettings.settings.display.toUpperCase()}
            </button>
            <br /><br /><br /><br /><br /><br /><br />
            {Object.keys(props.authSettings).length > 1 &&
                <span style={{float: 'right', color: 'white',cursor:"pointer",textDecoration:"underline"}} onClick={(e) => props.onLoginOptionChange(e)}>Local Login</span>
            }

        </p>
    )
}


/**
 * @description
 * Login page is common component that every application should use. That provide common look and feel for login pages.
 * It has some of the props that needs to be implemented and passed from the child component.
 *
 * @example
 *
 * import ZLoginPage from "<common_package_path>";
 *
 * <ZLoginPage  zLoginBGUrl="image_url_path"
 *              zLoginBGColor="hex color"
 *              zPunchLine="When Motion Matters"/>
 */

/**
 * Hello comom
 */
class ZLoginPage extends React.Component{
    constructor (props){
        super(props);
        /**
         * @type {object}
         * @property {string} zUserNameError - LoginPage error
         * @property {string} username - Login username
         * @property {password} password - Login Password
         *
         */
        this.state={
            zUserNameError   : "",
            username:undefined,
            password:undefined,
            loginDisabled: props.loginDisabled,
            displayNormalLogin: false,
        };

        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.getStandardLogin = this.getStandardLogin.bind(this);
        this.getSpecialLogin = this.getSpecialLogin.bind(this);
        this.handleLoginOptionChange = this.handleLoginOptionChange.bind(this);
    }


    /**
     *
     * propTypes - If URL has not provided then it will take zLoginBGColor otherwise it will bypass this property.
     * if None of props has provided then default color as per theme will take which is Color.grey300
     *
     * @property {string} zLoginBGUrl - Background image url from your local project. example if your images are located under
     * /<project>/assets/images/some.png. Then pass that same URL. zLoginBGUrl={"/<project>/assets/images/some.png"}
     * @property {string} zLoginBGColor - Alternative of the background image. If you don't want to provide background image
     * then pass background color of choice
     * @property {string} zPunchLine=VISIBILITY THAT'S VISIONARY - Sub title of the zebra brand as per brand guide line. If you don't want anything pass empty line.
     * @property {object} zLoginSizeData={isPortrait,windowWidth,windowHeight} get data from parent to display it based on that
     */
    static get propTypes () {
        return {
            zLoginBGUrl : PropTypes.string,
            zLoginBGColor : PropTypes.string,
            zPunchLine : PropTypes.string,
            zLoginSizeData: PropTypes.object
        };
    }

    /**
     * If zLoginBGUrl is not specified then zLoginBGColor will be in effect otherwise zLoginBGColor will bypass.
     *
     * @property {string} zLoginBGColor=grey300 - Background color for the login page
     * @property {string} zLoginBGUrl - Background image for the login page.
     */

    static get defaultProps () {
        return {
            zLoginBGColor : grey[300],
            zLoginBGUrl : null,
            zPunchLine : "",
            zLoginSizeData: {}
        };
    }


    /**
     * set local stats to next props
     */
    UNSAFE_componentWillReceiveProps  (nextProps) {
        if(!nextProps.zLoginBGUrl && !nextProps.zLoginBGColor){
            this.setState({
                zLoginBGColor : grey[300]
            });
            //eslint-disable-next-line
            console.error("You haven't provide zLoginBGURL and zLoginBGColor props so we are setting default color.\n" +
                "It is important that you can keep same look and feel");
        }else if (nextProps.zLoginBGColor){
            this.setState({
                zLoginBGColor : nextProps.zLoginBGColor
            });
        }else if(nextProps.zLoginBGUrl){
            this.setState({
                zLoginBGUrl : nextProps.zLoginBGUrl
            });
        }

        if(nextProps.loginDisabled !== this.props.loginDisabled){
            this.setState({
                loginDisabled: nextProps.loginDisabled
            })
        }

        if (nextProps.authSettings !== this.props.authSettings && !this.props.disableAutoRedirect) {
            const authSettings = nextProps.authSettings;
            if (Object.keys(authSettings).length === 1) {
                const authType = Object.keys(authSettings)[0];
                if (nextProps.authSettings[authType].specialLogin) {
                    redirectTo(authSettings[authType].settings.redirectTo);
                } else {
                    this.setState({
                        displayNormalLogin: true,
                    });
                }
            }
        }
    }

    handleKeyDown(e){
        if(e.which === 13){
            this.handleLogin();
        }
    }

    handleLoginOptionChange (e) {
        e.preventDefault();
        this.setState({
            displayNormalLogin: !this.state.displayNormalLogin
        });
    }

    getSpecialLogin() {
        return <SpecialLogin
            onLoginOptionChange={this.handleLoginOptionChange}
            authSettings={this.props.authSettings}
        />
    }

    getStandardLogin() {
        const {loginDisabled} = this.state;
        let button01Class = loginDisabled ? "button-01 button-01-disabled" : "button-01";
        return <div>
            <p>
                <input type="text"
                    ref="username"
                    value={this.state.username || ''}
                    onChange={e => this.setState({username : e.target.value})}
                    placeholder="USERNAME" />
                <img src="assets/images/login-username.svg" alt="" className="user-img"/>
            </p>
            <p>
                <input
                    ref="password"
                    type="password"
                    value={this.state.password || ""}
                    onChange={(e) => this.setState({password : e.target.value})}
                    onKeyDown={this.handleKeyDown}
                    placeholder="PASSWORD" />
                <img src="assets/images/login-password.svg" alt="" className="pass-img"/>
            </p>
            <p>
                <button
                    label="Login"
                    type="button"
                    name="button"
                    onClick={this.handleLogin.bind(this)}
                    className={button01Class}
                    disabled={loginDisabled}
                >
                    LOGIN
                </button>
                <button
                    label="Reset"
                    type="button"
                    name="button"
                    onClick={this.handleReset.bind(this)}
                    className="button-02">
                    RESET
                </button>
                <br />
                <br />
                {getSpecialLoginAuthType(this.props.authSettings) &&
                    <span style={{float: 'right', color: 'white',cursor:"pointer",textDecoration:"underline"}} onClick={this.handleLoginOptionChange}>Go back</span>
                }
            </p>
        </div>
    }

    /**
     * Render Login Page
     * @return {ReactElement}
     */

    render () {
        const {zLoginSizeData} = this.props;
        const{isPortrait,windowWidth,windowHeight}= zLoginSizeData;

        //const { zLoginBGUrl, zLoginBGColor } = this.props;
        // var bgColorWithPicture = {
        //     background:"url("+zLoginBGUrl+")",
        //     backgroundRepeat : "no-repeat",
        //     backgroundSize: "cover",
        //     height:"100%"
        // };
        // var bgColor = {
        //     backgroundColor:zLoginBGColor
        // };

        //var style = zLoginBGUrl ? bgColorWithPicture : bgColor;

        let loginImage = "assets/images/background/login-background.jpg";
        if(isPortrait) {
            loginImage = 'assets/images/background/login-background-responsive.jpg';
        }

        let loginImageOriginal = "assets/images/login-background.jpg";
        if(isPortrait) {
            loginImageOriginal = 'assets/images/login-background-responsive.jpg';
        }

        return (
            <div className="login-bg" >
                <div className="login-block">
                    <img src={loginImage} style={{maxWidth:windowWidth,maxHeight:windowHeight}} alt="login background"
                         onError={(e)=>{e.target.onerror = null; e.target.src=loginImageOriginal}} />
                    <div className="login-card">
                        <img src="assets/images/login-zebra-logo.svg" alt="zebra logo" width="160px" />
                        <h6> MotionWorks Enterprise </h6>
                        {this.state.displayNormalLogin
                            ? this.getStandardLogin()
                            : this.getSpecialLogin()
                        }
                    </div>
                </div>
            </div>
        );
    }

    /**
     *  handleReset - Reset Username and Password.
     * Reset Username and Password
     */
    handleReset () {
        this.setState({
            username : "",
            password :""
        });
        /*username.setValue(null);
        password.setValue(null);*/
    }


    /**
     * Pressing Login button will invoke this method and fire custom event
     *
     * @emits {zLogin} - Emit ZLogin event. See example how to handle this event.
     *
     * @example
     * document.getElementById("mainId").addEventListener("zLogin", <handler>)
     *
     *
     */
    handleLogin () {
        const { username, password } = this.state;
        let id = document.body.children[0].id;
        if(username){
            if(!id) {
                // eslint-disable-next-line
                console.error("please provide id of the element which is under the body tag. We use this id to fire log in " +
                    "event on that id");
            } else {
                new ZUtil().createCookie("username", username);
                var loginEvent = new ZUtil().createCustomEvent("zLogin", { username, password });
                document.getElementById(id).dispatchEvent(loginEvent);

                setTimeout(()=>{
                    new ZUtil().userLoggedIn(username);
                },200);
            }
        } else {
            this.setState({
                zUserNameError : "Username is required!"
            });
        }
    }

    /**
     * While you type in usertext input this method get invoke
     * @param event - Event parameter
     */
    handleChange(event){
        this.setState({
            username:event.target.value
        });
    }

}
export default ZLoginPage;
