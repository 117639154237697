import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {applySelectedTemplate, loadTemplateList, unloadTemplateList} from "../../../../actions";
import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import {Button} from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {localeTimestamp} from '../../../../utils/TimestampFormats';

const ConfigurationTemplate = () => {
    const emptyField = 'Configure without a template';
    const dispatch = useDispatch();
    const templates = useSelector(state => state.deviceManager.templates);
    const templateTitle = useSelector(state => (state.deviceManager.templateForm.template) ? state.deviceManager.templateForm.template.template: null);
    const [filteredTemplates, setFilteredTemplates]=useState(emptyField);

    const [openSelect, setOpenSelect] = useState(false);
    const [inputValue, setInputValue] = useState(emptyField);
    //const [valueInput, setValueInput] = useState(emptyField);
    const [selected, setSelected] = useState(null);
    const [openPanel, setOpenPanel] = useState(false);

    const loadTemplates=() => {
        // Load first time on mount component.
        dispatch(loadTemplateList());
        const unmount = () => {
            dispatch(unloadTemplateList());
        }
        return unmount;
    }

    useEffect(()=>{
        if(templateTitle){
            setInputValue(templateTitle);
        }
    },[templateTitle]);

    // Only call once when mount.
    useEffect(loadTemplates, []);
    useEffect(()=>{setFilteredTemplates(templates)},[templates])
    useEffect(() => {
        if (inputValue===''){
            document.getElementById(`combo-box-select-template`).focus();
        }
    },[inputValue]);

    const handleSelectItem = (object) => {
        if (typeof(object) !== 'object') {
            setInputValue(object);
            setSelected(null);
            setOpenSelect(false);
            setOpenPanel(false);
        }else{
            setSelected(object);
            setInputValue(object.title);
            setOpenSelect(false);
            setOpenPanel(true);
        }
    }

    const handleButtonComboBox = () => {
        setOpenSelect(!openSelect);
        if(!openSelect){setInputValue('');}
        setFilteredTemplates(templates);
    }

    const handleClickAwayListener = () => {
        setOpenSelect(false);
        if(templateTitle){
            setInputValue(selected ? selected.title: templateTitle);
        }else{
            setInputValue(selected ? selected.title: emptyField);
        }
    }

    const searchTemplate = value => {
        setInputValue(value);
        //if select options container isnt opened
        if(!openSelect){
            setOpenSelect(true);
        }

        //searching template
        if(value===""){
            setFilteredTemplates(templates);
        }else{
            setFilteredTemplates(templates.filter(item => 
                item.title.toLowerCase().indexOf(value.toLowerCase()) !== -1     
            ));
        }
    }

    const handleApplyTemplate = () => {
        dispatch(applySelectedTemplate(selected));
        setOpenPanel(false);
    }

    const getSelectContent = () => {
        const headerTemplates = [{id:"title",label:"Template Name",width:"40%"},
                                {id:"createBy",label:"Author",width:"40%"},
                                {id:"createDate",label:"Creation Date",width:"20%"}];
        
        const styleContainer = {padding:'1em',border: '1px solid #7E868C',overflow:'auto', maxWidth: '420px', maxHeight: '230px'};
        return (
            <div style={styleContainer} >
                <div style={{backgroundColor: (selected===null)?'#d8d8d8':'#fff', cursor:'pointer'}}>
                    <span onClick={()=>handleSelectItem(emptyField)}>{emptyField}</span>
                </div>
                <hr />
                <div style={{display:'flex', flexDirection: 'column', flexWrap: 'nowrap'}}>
                    <div style={{display:'flex',flexDirection: 'row', paddingTop:'0.25em',paddingBottom:'0.25em'}}>
                        {headerTemplates.map((header, index)=>
                            <div style={{flex:'1 1 0',fontWeight:'bold',width:header.width, minWidth:'130px'}} key={index}>{header.label}</div>
                        )}
                    </div>

                    {filteredTemplates.map((item, indexItem) => {
                        return (
                            <div style={{display:'flex',flexDirection: 'row', backgroundColor: item.title===selected? '#d8d8d8':'#fff',
                                paddingTop:'0.25em',paddingBottom:'0.25em', cursor: 'pointer'}} key={indexItem} onClick={()=>handleSelectItem(item)}>
                                {
                                    headerTemplates.map((header, index)=>
                                    <div style={{flex:'1 1 0', width:header.width, minWidth:'130px',overflow:'hidden',textOverflow:'ellipsis'}} key={index}>
                                        {header.id==="createDate"? localeTimestamp(new Date(item[header.id])) : item[header.id]}
                                    </div>)
                                }
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
    
    return (
        <div style={{backgroundColor:'#6f6d71',padding:'1em', marginBottom: '1em'}}>
            <div style={{display: 'flex',flexDirection:'row', alignItems:'center'}}>
                <span style={{color:'#fff'}}>Do you want to use a template? &nbsp;&nbsp; </span>
                <div style={{position:'relative', width: '240px'}}>
                    <InputBase id={'combo-box-select-template'} type={'text'} style={{fontSize: '13px', width:'100%',padding:'0.3em 0.5em', backgroundColor:'#fff'}}
                            value={inputValue} onChange={e=>searchTemplate(e.target.value)} readOnly={!openSelect}
                            endAdornment = {                                        
                                <InputAdornment style={{cursor:'pointer'}} onClick={handleButtonComboBox} >
                                    {openSelect?<ArrowDropUpIcon />:<ArrowDropDownIcon/>}
                                </InputAdornment>
                            }
                    />
                    {openSelect &&
                    <ClickAwayListener onClickAway={handleClickAwayListener}>
                        <div style={{position: 'absolute', zIndex:2,background:'#fff', fontSize: '13px'}}>
                            {getSelectContent()}
                        </div>
                    </ClickAwayListener>}
                </div>
            </div>
            {(openPanel && selected) &&
            <React.Fragment>
                <hr/>
                <div style={{color:'#fff',fontSize:'13px'}}>
                    <div style={{marginBottom:'0.25em'}}><b>Description</b></div>
                    <div style={{marginBottom:'0.25em'}}><span>{selected.description}</span></div>
                    <div style={{marginBottom:'0.25em'}}>
                        <span style={{marginRight: '2em'}}>Create by: &nbsp; {selected.createBy} </span>
                        <span>Creation Date: &nbsp; {localeTimestamp(new Date(selected.createDate))} </span>
                    </div>
                </div>
                <Button style={{backgroundColor:'#007aba',color:'#fff', textTransform:'none',padding:'0.4em 1em', marginTop:'1em'}} onClick={handleApplyTemplate}>
                    Apply selected template
                </Button>
            </React.Fragment>}
        </div>
    )
}
export default ConfigurationTemplate;