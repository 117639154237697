export const getVectorImage = (src, size, selected = false) => {
    let canvas = document.createElement('canvas');
    canvas.setAttribute("width", size);
    canvas.setAttribute("height", size);
    let ctx = canvas.getContext("2d");
    ctx.fillStyle = selected ? "#d8d8d8" : "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    let img = new Image();
    img.onload = function() {
        ctx.drawImage(img, 0, 0, size, size);
    };
    img.src = src;
    return canvas;
};

export const getVectorAntennaImage = (src, size, autoZone, selected = false) => {
    let canvas = document.createElement('canvas');
    canvas.setAttribute("width", size);
    canvas.setAttribute("height", size);
    let ctx = canvas.getContext("2d");
    ctx.fillStyle = selected ? "#d8d8d8" : "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    let img = new Image();
    img.onload = function() {
        ctx.drawImage(img, 0, 0, size, size);
    };
    img.src = src;
    
    if(autoZone){
        ctx.font = "10px Calibri,sans-serif";
        ctx.fillStyle = "#000";
        ctx.textAlign = "right";
        ctx.fillText("AZ", size, size-2);
    }
    return canvas;
};