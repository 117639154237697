import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import DatetimeFilterDialog from "../filters/DatetimeFilterDialog";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import FetchScreenLogs from "../../FetchScreenLogs";
import CrossBrowserTable from "../../tableComponent/CrossBrowserTable";
import CustomCell from "../../../landing/CustomCell";
import {
changeDateFilterDownloadsHistory,
changeDateValueDownloadsHistory, changeLogType, changeRangeFilterDownloadsHistory, cleanDownloadHistoryTypes,
getLogsDevicesByDate
} from "../../../../../actions";
import FilterTree from "../../../landing/FilterTree";
import {LOG_HISTORY_DIALOG} from "../../../../../constants/DeviceManager";

const FETCH_MESSAGE = "Getting logs data";

const LogHistoryComponent = ({deviceId}) => {

    const [header, setHeader] = useState([]);
    const [headerHeight, setHeaderHeight] = React.useState(0);
    const [sysLog, setSysLog] = useState(true);
    const [packetLog, setPacketLog] = useState(false);

    const logsHistoryData = useSelector(useState => useState.deviceManager.logsHistoryData);
    const logsHistoryColumns = useSelector(useState => useState.deviceManager.logsHistoryColumns);
    const filtersLogsHistory = useSelector(useState => useState.deviceManager.filtersLogsHistory);
    const logsHistoryTypes = useSelector(useState => useState.deviceManager.logsHistoryTypes);
    const fetchingHistory = useSelector(useState => useState.deviceManager.fetchingHistory);

    const dispatch = useDispatch();
    const handleLoadHistoryLogs = () => {
        dispatch(getLogsDevicesByDate(deviceId, null, null, 1461,1462))
    };

    useEffect(handleLoadHistoryLogs, []);

    const handleLoadColumns = () => {
        const keys = Object.keys(logsHistoryColumns);
        const headerColumns = keys.map((key) => logsHistoryColumns[key]);
        setHeader(headerColumns);

        return () => {
            dispatch(cleanDownloadHistoryTypes());
        }
    };

    useEffect(handleLoadColumns, []);

    const handleChangeFilterValue = (id,value) => {
        dispatch(changeDateValueDownloadsHistory(id, value));
    };

    const handleChangeFilterFromToValue = (fromId, fromValue, toId, toValue) => {
        dispatch(changeDateFilterDownloadsHistory(fromId, fromValue, toId, toValue));
        dispatch(getLogsDevicesByDate(deviceId, fromValue, toValue, fromId, toId));
    };

    const handleChangeSpecialFilterValue = (filterKey,filterValue, from, to) => {

        dispatch(changeRangeFilterDownloadsHistory(filterKey, filterValue, from, to));
    };

    const handleChangeDateAndData = (ss, newDateTimeFrom, newDateTimeTo, fromId,toId) => {
        dispatch(changeDateFilterDownloadsHistory(fromId, newDateTimeFrom, toId, newDateTimeTo));
        dispatch(getLogsDevicesByDate(deviceId, newDateTimeFrom, newDateTimeTo, fromId, toId));
    };

    const handleChangeSysLog = (e) => {
        setSysLog(!sysLog);
        // logTypeRef.current = e.target.value
        const logType = [...logsHistoryTypes];
        const index = logType.indexOf(e.target.value);
        if(index > -1){
            logType.splice(index, 1)
        } else {
            logType.push(e.target.value);
        }
        dispatch(changeLogType(logType));
        dispatch(getLogsDevicesByDate(deviceId, null, null, 1461,1462))
    };

    const handleChangePacketLog = (e) => {
        setPacketLog(!packetLog);
        // logTypeRef.current = e.target.value;
        const logType = [...logsHistoryTypes];
        const index = logType.indexOf(e.target.value);
        if(index > -1){
            logType.splice(index, 1)
        } else {
            logType.push(e.target.value);
        }
        dispatch(changeLogType(logType));
        dispatch(getLogsDevicesByDate(deviceId, null, null, 1461,1462))
    };

    const customHeader = ({text, tableWidth, tableHeight, itemKey}) => {
        const items = logsHistoryData && logsHistoryData.map(item => item).filter((value, index, self) => self.indexOf(value) === index);
        let headerDescription = header.filter( headerDescription =>{ return headerDescription.key === itemKey; });
        const columnConfig = logsHistoryColumns[itemKey];
        let statusSortable = false;
        if( headerDescription && headerDescription.length > 0){
            statusSortable = headerDescription[0].sortable;
        }

        return (
            <FilterTree sortIndex={null}
                        items={items}
                        text={text}
                        tableWidth={tableWidth}
                        tableHeight={tableHeight}
                        tree={false}
                        itemKey={itemKey}
                        type={'devices'}
                        statusSortable={statusSortable}
                        columnConfig={columnConfig}
                        tableScreen={LOG_HISTORY_DIALOG}
            />
        )
    };

    return(
        <React.Fragment>
            <div className="data-entry-form" style={{padding: '1em 0.5em', border: '1px solid #cecece', flex:'1 1'}}>
                <div >
                    <DatetimeFilterDialog
                        name={'timestamp'}
                        actions={{
                            changeFilterValue: handleChangeFilterValue,
                            changeFilterFromToValue: handleChangeFilterFromToValue,
                            changeSpecialFilterValue: handleChangeSpecialFilterValue
                        }}
                        filters={filtersLogsHistory}
                        reportId={"statusHistory"}
                        nextPrevButtons={true}
                        deviceId={deviceId}
                        onChangeDateAndData={handleChangeDateAndData}
                    />
                </div>
            </div>
            <div className="data-entry-form" style={{ padding: '1em 0', border: '1px solid #cecece', flex:'1 1'}}>
                <div className="basic-input"
                     style={{
                         display: 'flex',
                         justifyContent: 'space-between',
                         flex: '1 1 auto'
                     }}
                >
                    <div style={{display:'flex', justifyContent: 'start', flex: '1 0'}}>
                        <div style={{marginRight: '25px'}} className="dd-logs-type">
                            <Checkbox
                                value="syslog"
                                className='dd-logs-type-checkbox'
                                checked={logsHistoryTypes.indexOf('syslog') !== -1}
                                onChange={handleChangeSysLog}
                                size="medium"
                                disableTouchRipple={true}
                            />
                            <span style={{fontWeight:'bold'}}>Sys Log</span>
                        </div>
                    </div>
                    <div style={{display:'flex', justifyContent: 'start', flex: '3 1'}}>
                        <div className="dd-logs-type">
                            <Checkbox
                                value="radioPacketLog"
                                className='dd-logs-type-checkbox'
                                checked={logsHistoryTypes.indexOf('radioPacketLog') !== -1}
                                onChange={handleChangePacketLog}
                                size="medium"
                                disableTouchRipple={true}
                            />
                            <span style={{fontWeight:'bold'}}>Radio Packet Log</span>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{width: '100%', flex:'1 1 290px',position: 'relative'}}>
                {fetchingHistory && <FetchScreenLogs message={FETCH_MESSAGE} />}
                <CrossBrowserTable
                    data={logsHistoryData}
                    rowCount={logsHistoryData.length}
                    showCheckColumn={false}
                    headerRow={header}
                    headerRender={(...rest) => customHeader(...rest)}
                    cellRender={(...rest) => CustomCell(...rest)}
                    selectedRows={{}}
                    onClickRow={()=>{}}
                    onSelectedAll={()=>{}}
                    modernBrowser={true}
                    appliedFiltersHeight={headerHeight}
                    setHeaderHeight={setHeaderHeight}
                />
            </div>
        </React.Fragment>
    )
};

export default LogHistoryComponent