import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import Divider from "@material-ui/core/Divider/Divider";
import FetchScreenLogs from "../../FetchScreenLogs";
import CrossBrowserTable from "../../tableComponent/CrossBrowserTable";
import {useSelector} from "react-redux";
import FilterTree from "../../../landing/FilterTree";
import {LANDING_DEVICE_TABLE, OPE_STATUS_FAILURE, OPE_STATUS_NA} from "../../../../../constants/DeviceManager";
import {changeLogLevel, unsetLogLevel, loadLogLevel, cleanDeviceLogConfiguration} from "../../../../../actions";
// import {Button} from "@material-ui/core";

const FETCH_MESSAGE = "Publishing log setting on device";
const FETCH_MESSAGE_MULTIPLE = "Publishing log setting on device";

const LogSettingsComponent = ({
    devicesArray,
    isMultiselect
}) => {
    /*const [sysLog, setSysLog] = useState(false);
    const [packetLog, setPacketLog] = useState(false);
    const [logLevel,setLogLevel] = useState("INFO");*/
    const [header, setHeader] = useState([]);
    const [headerHeight, setHeaderHeight] = React.useState(0);

    const dispatch = useDispatch();

    const logDialogColumns = useSelector(state => state.deviceManager.logDialogColumns);
    const fetchingLogsSave = useSelector(state => state.deviceManager.fetchingLogsSave);
    const fetchingLogs = useSelector(state => state.deviceManager.fetchingLogs);
    const logLevelState = useSelector(state => state.deviceManager.logLevel);
    // const deviceLogConfig = useSelector(state => state.deviceManager.deviceLogConfig);

    const handleLoadConfigurationValues = () => {
        if(devicesArray.length === 1 && !isMultiselect){
            dispatch(loadLogLevel(devicesArray[0].logLevel));
            // setLogLevelState(devicesArray[0].logLevel)
        }

        return () => {
            dispatch(unsetLogLevel());
            dispatch(cleanDeviceLogConfiguration())
        }
    };

    useEffect(handleLoadConfigurationValues, [])

    const handleLoadColumns = () => {
        const keys = Object.keys(logDialogColumns);
        const headerColumns = keys.map((key) => logDialogColumns[key]);
        setHeader(headerColumns);
    };

    useEffect(handleLoadColumns, []);


    const customHeader = ({text, tableWidth, tableHeight, itemKey}) => {
        const items = devicesArray && devicesArray.map(item => item).filter((value, index, self) => self.indexOf(value) === index);
        let headerDescription = header.filter( headerDescription =>{ return headerDescription.key === itemKey; });
        const columnConfig = logDialogColumns[itemKey];
        let statusSortable = false;
        if( headerDescription && headerDescription.length > 0){
            statusSortable = headerDescription[0].sortable;
        }

        return (
            <FilterTree sortIndex={null}
                        items={items}
                        text={text}
                        tableWidth={tableWidth}
                        tableHeight={tableHeight}
                        tree={false}
                        itemKey={itemKey}
                        type={'devices'}
                        statusSortable={statusSortable}
                        columnConfig={columnConfig}
                        tableScreen={LANDING_DEVICE_TABLE}
            />
        )
    };

    const customCell = ({rowIndex, headerKey, content}) => {
        switch(headerKey){
            default:
                if(devicesArray[rowIndex].status.title===OPE_STATUS_NA||
                    devicesArray[rowIndex].status.title===''||
                    devicesArray[rowIndex].status.title==null||
                    devicesArray[rowIndex].status.title===OPE_STATUS_FAILURE){
                    return <span style={{color:'grey', fontStyle:'italic'}}>{content}</span>
                } else {
                    return <React.Fragment>{content}</React.Fragment>
                }
        }
    };


    const handleChangePacketLog = (e) => {
        dispatch(changeLogLevel(e.target.name, !logLevelState?.radioPacketLog))
    };

    const handleChangeLogLevel = (e) => {
        dispatch(changeLogLevel(e.target.name, e.target.value));
    };

    const getLevelValue = (key) => {
        let keys = key.split('.');
        let value ='INFO';
        if(logLevelState?.components){
            for(let i =0; i < logLevelState.components.length; i++){
                if(logLevelState.components[i].componentName === keys[1]){
                    value = logLevelState.components[i].level;
                    break;
                }
            }
        }

        return value;
    };

    /*const handleGetDeviceLogSettings = () => {
        dispatch(getDeviceLogConfiguration(devicesArray[0]._id))
    };

    const getLogLevelData = () => {
            return (
                <>
                    <div>
                    {deviceLogConfig?.components && deviceLogConfig?.components.length > 0 ?
                    deviceLogConfig.components.map((item, index) => {
                        let title = "";
                        if(item.componentName==='cloud_agent'){
                            title = 'Cloud Connect App (R2C)';
                        } else if(item.componentName === 'radio_control'){
                            title = item.componentName.replace('_', ' ') + ' (RC)'
                        }
                        return (
                            <span key={item.componentName} className="log-level-components-title">{title}: {item.level.toLowerCase()}, </span>
                        )
                    }) : ''}
                    </div>
                    <div>
                        <span key={"radioPacketLog"} className="log-level-components-title">Radio Packet Log (RPL): {deviceLogConfig.radioPacketLog ? 'Enabled' : 'Disabled'}</span>
                    </div>
                </>
            )
    }*/

    return (
        <React.Fragment>
            <div className="data-entry-form" style={{ padding: '1em 0.5em', border: '1px solid #cecece', flex:'1 1'}}>
                <div className="basic-input"
                     style={{
                         display: 'flex',
                         justifyContent: 'space-between',
                         flex: '1 1 auto'
                     }}
                >
                    <div style={{display:'flex', justifyContent: 'start', flex: '1 1 auto'}}>
                        <div className="dd-logs-type">
                            <Checkbox
                                className='dd-logs-type-checkbox dd-logs-type-checkbox-disabled'
                                checked={true}
                                disabled={true}
                                onChange={() => {}}
                                size="small"
                                disableTouchRipple={true}
                                value="packetLog"
                            />
                            <span style={{fontWeight:'bold'}}>Sys Log</span>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flex: '1 1 auto'
                    }}
                    >
                        <div style={{flex: '3 1'}}>
                            <label style={{fontWeight:'bold'}}>Cloud Connect App (R2C): </label>
                            <select name={"components.cloud_agent"} disabled={devicesArray.length === 1 && fetchingLogsSave[devicesArray[0]._id]} value={getLevelValue('components.cloud_agent')} onChange={handleChangeLogLevel}>
                                <option value="INFO">Info</option>
                                <option value="OFF">Off</option>
                                <option value="FATAL">Fatal</option>
                                <option value="WARNING">Warning</option>
                                <option value="ERROR">Error</option>
                                <option value="DEBUG">Debug</option>
                                <option value="TRACE">Trace</option>
                            </select>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flex: '1 1 auto',
                        marginLeft:'12px'
                    }}
                    >
                        <div style={{flex: '3 1'}}>
                            <label style={{fontWeight:'bold'}}>Radio Control (RC): </label>
                            <select name={"components.radio_control"} disabled={devicesArray.length === 1 && fetchingLogsSave[devicesArray[0]._id]} value={getLevelValue('components.radio_control')} onChange={handleChangeLogLevel}>
                                <option value="INFO">Info</option>
                                <option value="OFF">Off</option>
                                <option value="WARNING">Warning</option>
                                <option value="ERROR">Error</option>
                                <option value="DEBUG">Debug</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="data-entry-form" style={{padding: '1em 0.5em', border: '1px solid #cecece', flex:'1 1'}}>
                <div className="basic-input"
                     style={{
                         display: 'flex',
                         justifyContent: 'space-between',
                         flex: '1 1 auto'
                     }}
                >
                    <div style={{display:'flex', justifyContent: 'start', flex: '1 0'}}>
                        <div className="dd-logs-type">
                            <Checkbox
                                value="packetLog"
                                className={devicesArray.length === 1 && fetchingLogsSave[devicesArray[0]._id] ? 'dd-logs-type-checkbox dd-logs-type-checkbox-disabled':'dd-logs-type-checkbox'}
                                disabled={devicesArray.length === 1 && fetchingLogsSave[devicesArray[0]._id]}
                                checked={!!logLevelState?.radioPacketLog}
                                onChange={handleChangePacketLog}
                                size="small"
                                disableTouchRipple={true}
                                name='radioPacketLog'
                            />
                            <span style={{fontWeight:'bold'}}>Radio Packet Log (RPL)</span>
                        </div>
                    </div>
                </div>
            </div>
           {/* {!isMultiselect && <div className="data-entry-form" style={{padding: '1em 0.5em', border: '1px solid #cecece', flex:'1 1'}}>
                <div className="basic-input"
                     style={{
                         display: 'flex',
                         flex: '1 1 auto'
                     }}
                >
                    <Button style={{
                                background: '#007CB0',
                                color: '#fff', padding: '0.5em',
                                textTransform: 'none',
                                flex:'1 1 30%'
                            }} onClick={handleGetDeviceLogSettings} component="div">
                        Get Log Settings From Device
                    </Button>
                    <div className="log-level-components">
                    {deviceLogConfig && Object.keys(deviceLogConfig).length > 0 ?
                        getLogLevelData() : null
                    }
                    </div>
                </div>
            </div>}*/}
            <Divider/>
            <div className="data-entry-form" style={{display: 'flex', justifyContent: 'space-between', padding: '1em 0', flex:'1 1'}}>
                <span style={{fontWeight: 'bold'}}>Devices selected:</span>
            </div>
            <div style={{width: '100%', flex: '1 1 245px'}}>
                {devicesArray.length === 1 && fetchingLogsSave[devicesArray[0]._id] ? <FetchScreenLogs message={FETCH_MESSAGE} /> : null}
                {devicesArray.length > 1 && fetchingLogs ? <FetchScreenLogs message={FETCH_MESSAGE_MULTIPLE} /> : null}
                <CrossBrowserTable
                    data={devicesArray}
                    rowCount={devicesArray.length}
                    showCheckColumn={false}
                    headerRow={header}
                    headerRender={(...rest) => customHeader(...rest)}
                    cellRender={customCell}
                    selectedRows={{}}
                    onClickRow={()=>{}}
                    onSelectedAll={()=>{}}
                    modernBrowser={true}
                    appliedFiltersHeight={headerHeight}
                    setHeaderHeight={setHeaderHeight}
                />
            </div>
        </React.Fragment>
    )
};

export default LogSettingsComponent;