import React from 'react';
import PropTypes from 'prop-types';
import StyledDialog, {CancelButton, SaveButton} from '../../util/Dialog';
import LinearProgress from '@material-ui/core/LinearProgress';
import {withStyles} from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Warning';
import {connect} from 'react-redux';
import {serverLogsDownloadStatus as statusValues} from "../../../constants/Appliances";


const ColoredLinearProgress = withStyles({
    colorPrimary:{
        backgroundColor: 'gray'
    },
    barColorPrimary: {
        backgroundColor: '#4b95af'
    }
})(LinearProgress);

class DownloadServerLogsDialog extends React.Component{
    constructor(){
        super();
        this.state = {
            progress : false,
            label:'In Progress',
            finished: false,
            error: false,
            busy: false
        }
        this.handleClose = this.handleClose.bind(this);
        this.handleSave = this.handleSave.bind(this);


    }

    static get propTypes(){
        return {
            open: PropTypes.bool
        };
    }

    shouldComponentUpdate(nextProps){

        if ((nextProps.open || this.props.open)
            || nextProps.serverLogsDownloadStatus !== this.props.serverLogsDownloadStatus
        ){
            return true;
        }
        return false;
    }

    UNSAFE_componentWillReceiveProps(nextProps){

        if(nextProps.openErrorDialog){
            this.setState({progress : false,finished: false, error:true});
        }
    }

    handleSave(){
        this.props.actions.requestServerLogs();
    }

    handleClose() {
        const {serverLogsDownloadStatus} = this.props;
        this.props.actions.closeDataEntry();
        if(serverLogsDownloadStatus===statusValues.BUSY||serverLogsDownloadStatus===statusValues.ERROR){
            this.props.actions.resetServerLogsStatus();
        }
        this.setState({error: false});
    }

    render() {

        const {serverLogsDownloadStatus} = this.props;

        let actions=[];

        if(serverLogsDownloadStatus===statusValues.IN_PROGRESS
            ||serverLogsDownloadStatus===statusValues.BUSY
            ||serverLogsDownloadStatus===statusValues.ERROR)
        {
            actions.push(<SaveButton label='Close' key={"download-server-closed"} onClick={this.handleClose} />);
        }
        else {
            actions.push(<SaveButton label='Yes' key={"download-server-yes"} onClick={this.handleSave}/>);
            actions.push(<CancelButton style={{position: 'absolute', left: 8, bottom: 8}} key={"download-server-cancel"} label="Cancel" onClick={this.handleClose}/>);
        }

        return (
            <StyledDialog
                title={"Download Linux Server Logs"}
                onClose={this.handleClose}
                contentStyle={{display:'inline-block',textAlign:'left',maxWidth: 360, maxHeight:'20em', minHeight: 80}}
                bodyStyle={{overflowY:'auto'}}
                {...this.props}
                actions={actions}>
                Do you want to retrieve application logs from the Linux server?
                <span style={{fontSize:'12px',display:'block', marginTop:'5px'}}>It will take several minutes to process the request</span>

                {serverLogsDownloadStatus===statusValues.IN_PROGRESS &&
                    <div style={{marginTop:'5px'}}>                        
                        <ColoredLinearProgress mode="indeterminate" style={{height:'8px', marginTop:'10px', marginBottom:'4px'}} />
                        <span style={{fontSize:'12px',float:'right'}}>{this.state.label}</span>
                        <br/>
                        <span style={{fontSize:'12px',float:'right'}}>You can close this dialog box, the process is in the background and will begin to download once the file is ready, please pay attention to the download bar of your browser</span>
                    </div>}

                {serverLogsDownloadStatus===statusValues.FINISHED &&
                    <div style={{marginTop:'5px'}}>
                        <ColoredLinearProgress mode="determinate" value={100} style={{height:'8px', marginTop:'10px', marginBottom:'4px'}} />
                        <span style={{fontSize:'12px',float:'right'}}>Downloaded</span>
                    </div>}

                {serverLogsDownloadStatus===statusValues.ERROR &&
                    <div style={{marginTop:'5px'}}>
                        <ColoredLinearProgress mode="determinate" value={0} style={{height:'8px', marginTop:'10px', marginBottom:'4px'}} />
                        <span style={{fontSize:'12px',float:'right'}}>Error downloading logs, please try later.</span>
                    </div>}

                {serverLogsDownloadStatus===statusValues.BUSY &&
                    <div style={{marginTop:'5px',display:'flex',alignItems:'center'}}>
                        <ErrorIcon style={{color:'red',display:'block'}} />
                        <span style={{marginLeft:'5px',fontSize:'12px', color:'red',display:'block'}}>The server is busy in this moment with other download</span>
                    </div>}
            </StyledDialog>
        );
    }
}


const mapStateToProps = state =>({
    openErrorDialog: state.errorDialog.open,
    serverLogsDownloadStatus: state.appliances.serverLogsDownloadStatus,
});

export default connect(mapStateToProps,null)(DownloadServerLogsDialog);