import React from 'react';
import PropTypes from 'prop-types';

import StyledDialog, {CancelButton, SaveButton} from '../../util/Dialog';

import SummaryTable from './SummaryTable';

import Scheduler from './scheduler';

import ConfirmDialog from './ConfirmDialog';

import dateFormat from 'dateformat';

class UpdateFirmwareDialog extends React.Component {

    constructor(){
        super();

        this.handleSave = this.handleSave.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleTimeChange = this.handleTimeChange.bind(this);

        this.handleCloseConfirm = this.handleCloseConfirm.bind(this);
        this.handleSubmitConfirm = this.handleSubmitConfirm.bind(this);

        this.state={
            versions:{},
            time:0,
            confirming:false
        };
    }

    static get propTypes(){
        return {
            open: PropTypes.bool,
            actions: PropTypes.object.isRequired,
            data: PropTypes.shape({
                appliances: PropTypes.array,
                firmwareVersions: PropTypes.array
            })
        };
    }

    static get defaultProps(){
        return {
            open: false,
            data:{
                appliances:[],
                firmwareVersions:[]
            }
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps){

        if(!nextProps.data.appliances) return;

        var obj = {};

        nextProps.data.appliances.forEach( a => {
            obj[a._id] = nextProps.data.firmwareVersions[0] || "";
        });

        this.setState({
            versions:{...obj,...this.state.versions}
        });
    }

    generateConfirmContent(){
        var list = this.props.data.appliances.map((a,i) => (
            <div style={{fontSize:'0.9em'}} key={i}>
                <span style={{fontWeight:'bold',marginRight:'0.5em'}}>{a.name}:</span>
                <span>{this.state.versions[a._id]}</span>
            </div>
        ));

        return (
            <div>
                {list}
                <div style={{fontSize:'0.9em'}}>
                    <span style={{fontWeight:'bold',marginRight:'0.5em'}}>Update On:</span>
                    <span>{dateFormat(new Date(Math.max(this.state.time,new Date().getTime())),"mmmm dd, yyyy - hh:MM TT")}</span>
                </div>
            </div>
        );
    }

    handleSave(){

        if(this.state.time && this.state.time < new Date().getTime()){
            this.props.actions.displayErrorDialog({
                title:"Invalid Time",
                message:"Please select a time in the future."
            });
            return;
        }

        this.setState({
            confirming: true,
            confirmContent:this.generateConfirmContent()
        });
    }

    handleSubmitConfirm(){
       this.props.data.appliances.forEach(appliance =>{
            this.props.actions.createTask(
                appliance._id,
                "UpgradeSoftware",
                {version:this.state.versions[appliance._id]},
                (!this.state.time) ? undefined : this.state.time
            );

       })
        this.handleCloseConfirm();
    }

    handleCloseConfirm(){
        this.setState({
            confirming: false
        });
    }

    handleVersionChange(_id,e){
        this.setState({
            versions:{...this.state.versions, [_id]: e.target.value}
        });

        return true;
    }

    handleTimeChange(time){
        this.setState({
            time:time
        });
    }

    handleClose ()  {
        this.props.actions.closeDataEntry();
        this.setState({
            versions:{}
        });
    }

    renderDropDown(props){
        let {_id} = props;
        //remove duplicate firmware versions
        let firmwareVersions = this.props.data.firmwareVersions.filter((item,i,ar)=> ar.indexOf(item) === i);
        return(
            <select style={{minWidth:'75%'}} onChange={this.handleVersionChange.bind(this,_id)} value={this.state.versions[_id]}>
                {
                    firmwareVersions.map(fv => (
                        <option key={fv}>{fv}</option>
                    ))
                }
            </select>
        );
    }

    render() {
        const {
            data,
            actions, // eslint-disable-line no-unused-vars
            ...other
        } = this.props;


        if(!data.appliances) return null; // obviously not visible.

        const VersionOptions = this.renderDropDown.bind(this);

        const table = (
            <SummaryTable
                columns={[
                    {name:'a',caption:'Appliance Name'},
                    {name:'b',caption:'Current Version'},
                    {name:'c',caption:'Version Available'}
                ]}
                data={data.appliances.map(app=>({
                    a: app.name,
                    b: app.softwareVersion,
                    c: (<VersionOptions {...app}/>)
                }))}
            />
        );

        const scheduler = (
            <div style={{margin:'1em',textAlign:'left',padding:'0.5em',border:'solid #ccc',borderWidth:'1px 0',fontSize:'0.9em'}}>
                <span style={{fontWeight:'bold'}}>Update</span>
                <div style={{marginLeft:'0.5em',padding:'0.5em'}}>
                    <Scheduler onChange={this.handleTimeChange}/>
                </div>
            </div>
        );

        const mainButtons = (
            <div>
                {scheduler}
                <div>
                    <SaveButton style={{position: 'absolute', right: 8, color: '#00779F'}} onClick={this.handleSave} label={"Submit"}/>
                    <CancelButton onClick={this.handleClose} />
                </div>
            </div>
        );

        return (
            <StyledDialog
                title="Upgrade Firmware"
                open={this.props.open}
                onClose={this.handleClose}
                contentStyle={{minWidth: 300 ,maxWidth:'550px'}}
                actions={mainButtons}
                {...other}
                >
                {table}
                <ConfirmDialog subtitle={"Upgrade Firmware"} open={this.state.confirming} content={this.state.confirmContent} onClose={this.handleCloseConfirm} onSubmit={this.handleSubmitConfirm} />
            </StyledDialog>
            );
        }
    }

export default UpdateFirmwareDialog;
