import {updateDeviceCoordinates, updateAntennaCoordinates} from "../../../../actions";
import {Fill, Icon, Stroke, Style, Text} from "ol/style";
import Feature from "ol/Feature";
import {Point, Polygon, LineString} from "ol/geom";
import {getVectorImage, getVectorAntennaImage} from "../mapUtils";
import {ZoneVisibility} from "../../../util/map/LocalMap";

// GENERATE ANTENNAS COORDINATES
export const generateAntennasCoordinates = (count, centerPixel, mapRef) => {
    // const currentResolution = mapRef ? mapRef.current.getView().getResolution(): 1;
    // let separation = 60 * currentResolution;    //separation from center
    let separation = 500; //separation from center
    let twoPi = Math.PI * 2;
    let start_angle = twoPi / 12;
    let circumference = separation * (2 + count * 0.5);
    let radius = circumference / twoPi;  //radius from circumference
    let angleStep = twoPi / count; //distance between antennas
    let res = [];

    for(let i = count - 1; i >= 0; i--){
        let angle = start_angle + i * angleStep;
        res[i] = [
            centerPixel[0] + radius * Math.cos(angle),
            centerPixel[1] + radius * Math.sin(angle)
        ];
    }
    return res;
};

// LOAD THE FEATURES IN THE MAP

export const loadFeatures = (mapRef, sourceRef, devicesBatch, showDeviceLabels, selectedRows = {}, idSelected, dragEnabledObj) => {

    if (devicesBatch && devicesBatch.length>0) {
        devicesBatch.forEach((item) => {
            //rendering devices
            const idItem = item._id;
            const enableDrag = dragEnabledObj && dragEnabledObj[idItem] && dragEnabledObj[idItem].enableDrag;
            if (sourceRef.current && (item.location.x !== undefined || item.location.y !== undefined)) {
                const isCheckedDevice = selectedRows.indexOf(item._id) !== -1 || idSelected === item._id;
                const style_cluster = getDeviceStyle(showDeviceLabels, item.title, isCheckedDevice, 1);

                let pointerFeature = new Feature({
                    type: 'cluster',
                    children: [],
                    label: item.title,
                    coord: [item.location.x, item.location.y],
                    geometry: new Point([item.location.x, item.location.y]),
                    deviceObject: item,
                    indexObject: idItem,
                });
                pointerFeature.setId(idItem);
                pointerFeature.setStyle(style_cluster);
                sourceRef.current.addFeature(pointerFeature);
            }

            // rendering antennas and lines
            const antennas = item.rfidAntennas;
            const coordinatesDefault = generateAntennasCoordinates(antennas.length , [item.location.x, item.location.y], mapRef);

            if(antennas.length > 0){
                antennas.forEach((antenna, i) => {
                    const idAntenna = idItem+"_"+(i+1);
                    const isCheckedAntenna = idAntenna === idSelected;
                    const antennaHasAutozone = Boolean(antenna.autoZone);

                    // render antenna configured
                    if (sourceRef.current && item.location != null && (item.location?.x !== undefined || item.location?.y !== undefined) && (antenna.location?.x !== undefined || antenna.location?.y !== undefined)) {
                        const style_antenna = getAntennaStyle(showDeviceLabels, antenna.title, true, isCheckedAntenna, antennaHasAutozone);

                        //rendering antennas
                        let antennaFeature = new Feature({
                            label: antenna.title,
                            type: 'antenna',
                            indexObject: idItem,
                            indexAntenna: i,
                            autoZone: antenna.autoZone,
                            draggable: enableDrag,
                            coord: [antenna.location.x, antenna.location.y],
                            geometry: new Point([antenna.location.x, antenna.location.y])
                        });
                        
                        antennaFeature.setId(idAntenna);                        
                        antennaFeature.setStyle(style_antenna);
                        sourceRef.current.addFeature(antennaFeature);

                        //rendering lines
                        const idLine = idItem+"_"+(i+1)+'_line';
                        const style_line = getLineStyle();

                        let lineFeature = new Feature({
                            geometry: new LineString([[item.location.x, item.location.y], [antenna.location.x, antenna.location.y]]),
                            type: 'line',
                            label: antenna.title,
                            draggable: false
                        });
                        lineFeature.setId(idLine);
                        lineFeature.setStyle(style_line);
                        sourceRef.current.addFeature(lineFeature);

                    }
                    
                    // render antenna not configured
                    if(sourceRef.current && item.location != null && antenna.location != null && (item.location?.x !== undefined || item.location?.y !== undefined) && (antenna.location?.x === undefined || antenna.location?.y === undefined)){
                        const style_antenna = getAntennaStyle(showDeviceLabels, antenna.title, false, isCheckedAntenna, antennaHasAutozone);

                        //rendering antennas
                        let antennaFeature = new Feature({
                            label: antenna.title,
                            type: 'antenna',
                            indexObject: idItem,
                            indexAntenna: i,
                            autoZone: antenna.autoZone,
                            draggable: enableDrag,
                            coord: coordinatesDefault[i],
                            geometry: new Point(coordinatesDefault[i])
                        });
                        
                        antennaFeature.setId(idAntenna);
                        antennaFeature.setStyle(style_antenna);
                        sourceRef.current.addFeature(antennaFeature);

                        //rendering lines
                        const idLine = idItem+"_"+(i+1)+'_line';
                        const style_line = getLineStyle();

                        let lineFeature = new Feature({
                            geometry: new LineString([[item.location.x, item.location.y], coordinatesDefault[i]]),
                            type: 'line',
                            label: antenna.title,
                            draggable: false
                        });
                        lineFeature.setId(idLine);
                        lineFeature.setStyle(style_line);
                        sourceRef.current.addFeature(lineFeature);
                    }

                })
            }

            // sourceRef.current.refresh();
            // mapRef.current.updateSize();

        })
    }
};

// SAVE FEATURE POSITION IN REDUX
export const saveDeviceData = (event, idDevice, coordinate, dispatch, dragAllow) => {
    const coordinateX = parseFloat(Number(coordinate[0]).toFixed(2));
    const coordinateY = parseFloat(Number(coordinate[1]).toFixed(2));
    const coordinateZ = parseFloat(Number(0).toFixed(2));

    let coordinates = {};
    coordinates['x'] = coordinateX;
    coordinates['y'] = coordinateY;
    coordinates['z'] = coordinateZ;

    dispatch(updateDeviceCoordinates(idDevice, coordinates, dragAllow));
    if(event != null){
        event.preventDefault();
    }
};

export const saveAntennaData = (event, idDevice, indexAntenna, coordinate, dispatch) => {
    const coordinateX = parseFloat(Number(coordinate[0]).toFixed(2));
    const coordinateY = parseFloat(Number(coordinate[1]).toFixed(2));
    const coordinateZ = parseFloat(Number(0).toFixed(2));

    let coordinates = {x: coordinateX, y: coordinateY, z: coordinateZ};
    dispatch(updateAntennaCoordinates(idDevice, indexAntenna, coordinates));
    if(event != null){
        event.preventDefault();
    }
};

// STYLES OF FEATURES (DEVICES, ANTENNAS, LINES and LABELS)

const DEVICE_IMAGE = getVectorImage('assets/images/devices/04.svg', 18);
const DEVICE_IMAGE_SELECTED = getVectorImage('assets/images/devices/04-1.svg', 18);

const ANTENNA_SIZE = 28;    //28
const ANTENNA_IMAGE_NOCONF = getVectorAntennaImage('assets/images/devices/09.svg', ANTENNA_SIZE, false, false);
const ANTENNA_IMAGE_CONF = getVectorAntennaImage('assets/images/devices/09-active.svg', ANTENNA_SIZE, false, false);
const ANTENNA_IMAGE_NOCONF_SELECTED = getVectorAntennaImage('assets/images/devices/09.svg', ANTENNA_SIZE, false, true);
const ANTENNA_IMAGE_CONF_SELECTED = getVectorAntennaImage('assets/images/devices/09-active.svg', ANTENNA_SIZE, false, true);
const ANTENNA_IMAGE_NOCONF_AZ = getVectorAntennaImage('assets/images/devices/09.svg', ANTENNA_SIZE, true, false);
const ANTENNA_IMAGE_CONF_AZ = getVectorAntennaImage('assets/images/devices/09-active.svg', ANTENNA_SIZE, true, false);
const ANTENNA_IMAGE_NOCONF_SELECTED_AZ = getVectorAntennaImage('assets/images/devices/09.svg', ANTENNA_SIZE, true, true);
const ANTENNA_IMAGE_CONF_SELECTED_AZ = getVectorAntennaImage('assets/images/devices/09-active.svg', ANTENNA_SIZE, true, true);

export const getDeviceStyle = (showDeviceLabels, name, isChecked, zIndex=0) => {
    const deviceStyle = new Style({
        image: new Icon({
            anchor: [0.5, 0.5],
            anchorXUnits: 'fraction',
            anchorYUnits: 'fraction',
            img: isChecked? DEVICE_IMAGE_SELECTED: DEVICE_IMAGE,
            imgSize:[18,18],
            scale: 1
        }),
        zIndex: zIndex
    });

    deviceStyle.setText(new Text({
        text: showDeviceLabels  ? name : '',
        textBaseline: 'bottom',
        font: '12px Calibri,sans-serif',
        fill: new Fill({color: '#000'}),
        offsetY: -12,
        stroke: new Stroke({
            color: 'yellow', width: 8
        })
    }));

    return deviceStyle;
};

export const getAntennaStyle = (showDeviceLabels, label, isConfigured, isSelected, antennaHasAutozone, zIndex=0) => {
    let antennaImage = null;
    if(!isConfigured && !isSelected && !antennaHasAutozone){ antennaImage = ANTENNA_IMAGE_NOCONF};
    if(isConfigured && !isSelected && !antennaHasAutozone){ antennaImage = ANTENNA_IMAGE_CONF};
    if(!isConfigured && isSelected && !antennaHasAutozone){ antennaImage = ANTENNA_IMAGE_NOCONF_SELECTED};
    if(isConfigured && isSelected && !antennaHasAutozone){ antennaImage = ANTENNA_IMAGE_CONF_SELECTED};
    if(!isConfigured && !isSelected && antennaHasAutozone){ antennaImage = ANTENNA_IMAGE_NOCONF_AZ};
    if(isConfigured && !isSelected && antennaHasAutozone){ antennaImage = ANTENNA_IMAGE_CONF_AZ};
    if(!isConfigured && isSelected && antennaHasAutozone){ antennaImage = ANTENNA_IMAGE_NOCONF_SELECTED_AZ};
    if(isConfigured && isSelected && antennaHasAutozone){ antennaImage = ANTENNA_IMAGE_CONF_SELECTED_AZ};

    const antennaStyle = new Style({
        image: new Icon({
            anchor: [0.5, 0.5],
            anchorXUnits: 'fraction',
            anchorYUnits: 'fraction',
            img: antennaImage,
            imgSize:[ANTENNA_SIZE,ANTENNA_SIZE],
            scale: 1
        }),
        zIndex: zIndex
    });

    antennaStyle.setText(new Text({
        text: showDeviceLabels  ? label : '',
        textBaseline: 'bottom',
        font: '12px Calibri,sans-serif',
        fill: new Fill({color: '#000'}),
        offsetY: -18,
        stroke: new Stroke({
            color: 'yellow', width: 8
        })
    }));
    // antennaStyle.setText(new Text({
    //     text: antennaHasAutozone?'AZ':'',
    //     font: '10px Calibri,sans-serif',
    //     fill: new Fill({color: '#000'}),
    //     offsetY: 10,
    //     offsetX: 10,
    //     stroke: new Stroke({
    //         color: '#fff', width: 2
    //     })
    // }));

    return antennaStyle;
};

export const getLineStyle = () => {
    return new Style({
        stroke: new Stroke({color: '#9e9e9e', width: 3})
    })
};


const getLabelStyle = (feature, visibility) => {
    const labelStyle = new Style(visibility === ZoneVisibility.ZONES_AND_LABELS ? {
        text: new Text({
            text: feature.get('data').name,
            font: '16px Calibri,sans-serif',
            fontWeight: '600',
            fill: new Fill({color: '#000'}),
            backgroundFill: new Fill({color: "#fff"}),
            backgroundStroke: new Stroke({color: "#000", width: 1}),
            offsetY: 0,
            overflow: true,
            padding: [1.5, 5, 1.5, 5]
        })
    }: null);
    return labelStyle;
};

// DRAW ZONES AND LABELS

const getZoneStyle = (feature, visibility) => {
    const zoneStyle = new Style(feature.get('data').color===null? null:( visibility !== ZoneVisibility.NO_ZONES ?{
        fill: new Fill({
            color: [feature.get('data').color[0], feature.get('data').color[1], feature.get('data').color[2], feature.get('data').color[3] || 0.5]
        }),
        stroke: new Stroke({color: '#000000'})
    }: null));

    // if(visibility === ZoneVisibility.ZONES_AND_LABELS){
    //     zoneStyle.setText(new Text({
    //         text: feature.get('data').name,
    //         textBaseline: 'bottom',
    //         font: '12px Calibri,sans-serif',
    //         fill: new Fill({color: '#000'}),
    //         stroke: new Stroke({
    //             color: '#fff', width: 2
    //         })
    //     }))
    // }

    //TODO
    
    return zoneStyle;
}

export const getUpdateCoordinatesZone = (coordinates) => {
    //getting the min and max (x, y) coordinates
    let minX, maxX, minY, maxY;
    for(var i = 0; i < coordinates.length; i++){
        const coor = coordinates[i];
        minX = (coor[0] < minX || minX == null) ? coor[0] : minX;
        maxX = (coor[0] > maxX || maxX == null) ? coor[0] : maxX;
        minY = (coor[1] < minY || minY == null) ? coor[1] : minY;
        maxY = (coor[1] > maxY || maxY == null) ? coor[1] : maxY;
    }

    return [
        [minX - 5, minY - 5],
        [maxX + 5, minY - 5],
        [maxX + 5, maxY + 5],
        [minX - 5, maxY + 5],
        [minX - 5, minY - 5]
    ];
}

export const buildZonesWithAntennas = (devicesBatch) => {
    let zonesObject = {};
    for(var i = 0; i < devicesBatch.length; i++){
        const device = devicesBatch[i];
        for(var j = 0; j < device.rfidAntennas.length; j++){
            const antenna = device.rfidAntennas[j];
            if(antenna.autoZone){
                if(antenna.autoZone in zonesObject){
                    zonesObject[antenna.autoZone].push([antenna.location.x, antenna.location.y])
                }else{
                    zonesObject[antenna.autoZone] = [[antenna.location.x, antenna.location.y]];
                }
            }
        }
    }
    return zonesObject;
}


export const updateZoneLayers = (devicesBatch, zonesWithAntennas, zonesOnMap, autoZoneVisibility, sourceZoneRef, sourceZoneLabelRef) => {
    if(sourceZoneRef.current){
        let zonesObject = buildZonesWithAntennas(devicesBatch);
    
        for(const locationGuid in zonesObject){
            const zoneFeature = sourceZoneRef.current.getFeatureById(locationGuid);
            const zoneLabelFeature = sourceZoneLabelRef.current.getFeatureById(locationGuid);

            const zoneNewCoordinates = zonesWithAntennas[locationGuid]? getUpdateCoordinatesZone([...zonesObject[locationGuid], ...zonesWithAntennas[locationGuid]])
                    :getUpdateCoordinatesZone(zonesObject[locationGuid]);

            if(zoneFeature && zoneLabelFeature){
                zoneFeature.getGeometry().setCoordinates([zoneNewCoordinates]);
                zoneLabelFeature.getGeometry().setCoordinates([zoneNewCoordinates]);

                // setting auto zone name
                const zone = zonesOnMap.find(z => z.locationGuid === locationGuid);
                if(locationGuid && zone && zoneLabelFeature.get('data').name !== zone.name){
                    const zoneData = {...zoneLabelFeature.get('data'), name: zone.name};
                    zoneLabelFeature.set('data', zoneData);
                }
            }else{
                let newZone = zonesOnMap.find(z => z.locationGuid === locationGuid);
                if(newZone){
                    newZone.shape = zoneNewCoordinates;
                    createNewZone(newZone, autoZoneVisibility, sourceZoneRef, sourceZoneLabelRef);
                }
            }
        }

        // removing zones without antennas
        const zoneFeaturesForDelete = sourceZoneRef.current.getFeatures().filter(feature => Object.keys(zonesObject).indexOf(feature.getId()) === -1 && feature.get('data').layerPriority === -1);
        zoneFeaturesForDelete.forEach(feature => sourceZoneRef.current.removeFeature(feature));

        const zoneLabelFeaturesForDelete = sourceZoneLabelRef.current.getFeatures().filter(feature => Object.keys(zonesObject).indexOf(feature.getId()) === -1 && feature.get('data').layerPriority === -1);
        zoneLabelFeaturesForDelete.forEach(feature => sourceZoneLabelRef.current.removeFeature(feature));
    }
}

export const initZonesLayer = (zonesOnMap, visibility, sourceZoneRef, sourceZoneLabelRef) => {
    // rendering only zones
    if(sourceZoneRef.current.getFeatures().length === 0){
        for(var i = 0; i < zonesOnMap.length; i++){
            const zone = zonesOnMap[i];
            if(zone.layerPriority !== -1){
                createNewZone(zone, visibility, sourceZoneRef, sourceZoneLabelRef);
            }
        }
    }
}

export const loadZoneLayers = (visibility, autoZoneVisibility, sourceZoneRef, sourceZoneLabelRef) => {
    if(sourceZoneRef.current.getFeatures().length > 0){
        sourceZoneRef.current.getFeatures().forEach(feature => {
            const zoneVisibility = feature.get('data').layerPriority === -1? autoZoneVisibility: visibility;
            feature.setStyle(getZoneStyle(feature, zoneVisibility));
        });
        sourceZoneLabelRef.current.getFeatures().forEach(feature => {
            const zoneVisibility = feature.get('data').layerPriority === -1? autoZoneVisibility: visibility;
            feature.setStyle(getLabelStyle(feature, zoneVisibility));
        });
    }
}

export const createNewZone = (zone, visibility, sourceZoneRef, sourceZoneLabelRef) => {
    let zoneFeature = new Feature({
        geometry: new Polygon([zone.shape]),
        data: {...zone}
    });
    zoneFeature.setId(zone.locationGuid);
    zoneFeature.setStyle(getZoneStyle(zoneFeature, visibility));
    sourceZoneRef.current.addFeature(zoneFeature);

    let labelFeature = new Feature({
        geometry: new Polygon([zone.shape]),
        data: {...zone}
    });
    labelFeature.setId(zone.locationGuid);
    labelFeature.setStyle(getLabelStyle(labelFeature, visibility));
    sourceZoneLabelRef.current.addFeature(labelFeature);
}

export const removeLayers = (mapRef, layers) => {
    for(const key in layers){
        const layer=layers[key];
        mapRef.current.removeLayer(layer);
        delete layers[key];
    }
};

// CREATE OVERLAY OF A FEATURE
export const createOverlay = (mapRef, coordinate, deviceObject, indexObject, overlay, feature, dispatch) => {
    let mainElement = getContainerOverlay(mapRef, coordinate, deviceObject, indexObject, overlay, feature, dispatch);
    overlay.setElement(mainElement);
    overlay.setPosition(coordinate);
};

const calculatePositionPopup = (mapRef, coordinate) => {
    const extentMap = mapRef.current.getView().calculateExtent();
    // const mapLeftBottomPixel= mapRef.current.getPixelFromCoordinate([extentMap[0],extentMap[1]]);
    const mapRightTopPixel= mapRef.current.getPixelFromCoordinate([extentMap[2],extentMap[3]]);
    const coordinatesPixel = mapRef.current.getPixelFromCoordinate(coordinate);

    const mapTop = mapRightTopPixel[1];
    // const mapBottom = mapLeftBottomPixel[1];
    // const mapLeft = mapLeftBottomPixel[0];
    const mapRight = mapRightTopPixel[0];

    const selectedX = coordinatesPixel[0];
    const selectedY = coordinatesPixel[1];

    const distanceTop = selectedY - mapTop;
    const distanceRight= mapRight - selectedX;
    // const distanceBottom = mapBottom - selectedY;
    // const distanceLeft = selectedX - mapLeft;

    const maxWidthPopup = 145;
    const maxHeightPopup = 222;

    let className = 'feature-popup-main';

    //horizontal comparison
    if(distanceRight > maxWidthPopup){
        className += ' overlay-right'
    }else{
        className += ' overlay-left'
    }

    //vertical comparison
    if(distanceTop > maxHeightPopup){
        className += '-top'
    }else{
        className += '-bottom'
    }

    return className;
}

const getContainerOverlay = (mapRef, coordinate, deviceObject, indexObject, overlay, feature, dispatch) => {
    let zVal = deviceObject.location.z;
    let divContainerElement = document.createElement('div');
    divContainerElement.className = calculatePositionPopup(mapRef, coordinate);

    let divForm = document.createElement('div');
    divForm.className = 'feature-popup-content';
    divContainerElement.appendChild(divForm);

    // Section of X value for coordinates

    let divContentX = document.createElement('div');
    divContentX.className = 'feature-popup-child';
    divForm.appendChild(divContentX);

    let labelX = document.createElement('label');
    labelX.className = 'feature-popup-label';
    labelX.innerText = 'X Coord.';

    let valueX = document.createElement('input');
    valueX.id = 'value-x';
    valueX.type = 'text';
    valueX.className = 'feature-popup-text';
    valueX.value =coordinate[0].toFixed(2);

    divContentX.appendChild(labelX);
    divContentX.appendChild(valueX);


    // Section of Y value for coordinates

    let divContentY = document.createElement('div');
    divContentY.className = 'feature-popup-child';
    divForm.appendChild(divContentY);

    let labelY = document.createElement('label');
    labelY.className = 'feature-popup-label';
    labelY.innerText = 'Y Coord.';

    let valueY = document.createElement('input');
    valueY.id = 'value-y';
    valueY.type = 'text';
    valueY.className = 'feature-popup-text';
    valueY.value =coordinate[1].toFixed(2);

    divContentY.appendChild(labelY);
    divContentY.appendChild(valueY);


    // Section of Z value for coordinates

    let divContentZ = document.createElement('div');
    divContentZ.className = 'feature-popup-child';
    divForm.appendChild(divContentZ);

    let labelZ = document.createElement('label');
    labelZ.className = 'feature-popup-label';
    labelZ.innerText = 'Z Coord.';

    let valueZ = document.createElement('input');
    valueZ.id = 'value-z';
    valueZ.type = 'text';
    valueZ.className = 'feature-popup-text';
    valueZ.value = zVal.toFixed(2);

    divContentZ.appendChild(labelZ);
    divContentZ.appendChild(valueZ);



    // Section for Save link

    let divContentSave = document.createElement('div');
    divContentSave.className = 'feature-popup-save';
    divForm.appendChild(divContentSave);

    let saveLink = document.createElement('a');
    saveLink.href = '#';
    saveLink.innerText = 'Save';
    saveLink.className = 'feature-popup-link';
    saveLink.onclick = (event) => saveDeviceData(
        mapRef,
        event,
        deviceObject,
        indexObject,
        document.getElementById('value-x').value,
        document.getElementById('value-y').value,
        document.getElementById('value-z').value,
        overlay,
        feature,
        coordinate,
        dispatch
    );

    divContentSave.appendChild(saveLink);

    return divContainerElement;
};