import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
// import FlatButton from 'material-ui/FlatButton';
import {Button} from '@material-ui/core';

import {generateComponentId} from "../../../utils/ComponentUtils";
import CarotIcon from '../../icons/CarotIcon';

class MenuNavItem extends React.Component{

    constructor(props, context){
        super(props,context);

        this.state = {
            hovered: false
        };

        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    shouldComponentUpdate(nextProps,nextState){
        return this.props !== nextProps || nextState !== this.state;
    }

    handleMouseEnter(e){
        this.setState({hovered:true});
        this.props.onHover(this.props.menuList,e.currentTarget,true);
    }
    handleClick(e){
        this.setState({hovered:true});
        this.props.onClick(this.props.menuList,e.currentTarget,true);
    }
    handleMouseLeave(e){
        this.setState({hovered:false});
        this.props.onHover(this.props.menuList,e.currentTarget,false);
    }



    render(){
        let menuList = this.props.menuList;
            //styles = this.props.styles;
            //side = this.props.side;
        let childrenSize = menuList.hasOwnProperty("children") ? menuList.children.length : [];


            if(!menuList.hasOwnProperty("routes")) throw Error("Your menu.json doesn't contain routes");

            //let is_current_page = (menuList.routes === location.hash.split("/").pop()) || this.props.submenuOpen || this.state.hovered;
            let is_current_page =this.props.submenuOpen || this.state.hovered;

            /////////////////////////////////////////////////////////////////////////////
            /////////////////////////////////////////////////////////////////////////////
            ///////////////////////////////////////////////////////////////////////////////
            // Define css class for main menu.
            let mainMenuClass="main-menu-item";
            if(is_current_page)
                mainMenuClass+=" main-menu-item-current";
        const userLabelStyle = {
            textTransform:'none',
            fontSize:'1em',
            fontWeight:'normal',
            top: '2px',
            left: '5px',
            paddingLeft: 0,
            paddingRight: 0,
            float: 'left',
        };

        let paddingLeft = menuList.displayIcon ? '6px' : '16px';
        let paddingRight=menuList.displayIcon ? 0:'16px';
        //let paddingTop = menuList.displayIcon ? 0 : '6px';

        let stylesButton={};
        if(menuList.displayIcon){
            stylesButton=Object.assign({},userLabelStyle);
            stylesButton.paddingLeft=paddingLeft;
            stylesButton.paddingRight=paddingRight;
        }
        else{
            stylesButton.paddingLeft=paddingLeft;
            stylesButton.paddingRight=paddingRight;
        }

            const button = (
                <Button
                    ref={"button"}
                    id={generateComponentId(menuList.name.toLowerCase(), null, "button")}
                    className={"main-menu-button"}
                    //label={menuList.name}
                    style={stylesButton}
                    onClick={this.handleClick}
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                >

                    { menuList.displayIcon ?
                        <div>
                            <div className={"icon-config"}>
                                <CarotIcon/>
                            </div>
                            <span style={{position:'relative', verticalAlign:'middle',
                                letterSpacing:0, textTransform:'none', fontWeight:'normal', fontSize:'1em', top:13,  float:'left'}}>
                                        {menuList.name}
                                </span>
                        </div>
                        :
                        menuList.name

                    }
                </Button>
            );

            if(childrenSize === 0) {
                return (
                    <Link className={mainMenuClass} style={{textDecoration:'none'}} to={"/" + menuList.routes} key={menuList.name} replace>
                        {button}
                    </Link>
                );
            } else {
                return (
                    <Link className={mainMenuClass} style={{textDecoration:'none'}} to={"/" + menuList.routes} onClick={e => e.preventDefault()} key={menuList.name}>
                        {button}
                    </Link>
                );
            }
    }
}

MenuNavItem.propTypes = {
    menuList: PropTypes.object.isRequired,
    styles: PropTypes.object,
    side: PropTypes.string,
    onHover: PropTypes.func,
    onClick: PropTypes.func,
    submenuOpen: PropTypes.bool
};

export default MenuNavItem;
