import React from 'react';
import PropTypes from 'prop-types';
//import FlatButton from 'material-ui/FlatButton';
import FlatButton from '@material-ui/core/Button';

import StyledDialog from '../../util/Dialog';
import DialogTitle from "@material-ui/core/es/DialogTitle/DialogTitle";

class SiteDialog extends React.Component {
    static get propTypes(){
        return {
            open: PropTypes.bool,
            onSave: PropTypes.func,
            onRequestClose: PropTypes.func,
            buttonOffset: PropTypes.number
        };
    }

    static get defaultProps(){
        return {
            open: false,
            onSave: function(){}
        };
    }

    render() {
        const {onSave, onRequestClose,...other} = this.props;

        const mainButtons = (
            <div>
                <FlatButton key={"save-button"} label={"Save"} style={{color:'#00779f'}} onClick={onSave || onRequestClose} variant="text">Save</FlatButton>
                <FlatButton key={"cancel-button"} label="Cancel" style={{color:'#777',float:'left'}} onClick={onRequestClose} variant="text">Cancel</FlatButton>
            </div>
        );

        return (
            <StyledDialog
                title="Site Form"
                modal={false}
                contentStyle={{maxWidth:'inherit'}}
                {...other}
                onRequestClose={onRequestClose}
                onClose={onRequestClose}
                actions={mainButtons}
            >
                <DialogTitle>Site Form</DialogTitle>
                {/*<DialogActions>{[mainButtons]}</DialogActions>*/}
            </StyledDialog>
        );
    }
}

export default SiteDialog;
