import FileSaver from "file-saver";
import moment from "moment/moment";
import {FILTER_TYPE, getLabelForFilter, getRangeMode, isAllOption} from "../../views/util/report/filter/util";
import {getLocalTimestamp} from "../../utils/LocaleTimestamp";
import {OPTION_ALL_TEXT} from "../../constants/Misc";

const NUMBER_ROWS_SUMMARY=10;

/**
 *
 * @param report Report data from redux store.
 * @param filters Filters info from redux store.
 * @param reportId Id of current report.
 * @param format Format csv or html.
 */
export default function downloadReport(report,filters,reportId,format){

    // Gets visible columns and their order.
    let columnOrder = report.columnOrder[reportId] || [];
    let columnMeta = report.columnMeta[report.reportId] || {};
    const columns = columnOrder.map(col => ({...col, ...columnMeta[col.name]}));
    const visibleColumns = columns.filter((v) => v.enabled);

    // Generates the content of report.
    const bodyData = filterData(visibleColumns, report.data);
    //console.log(bodyData); // is an array of objects
    // Generates the header and the filters-values of report.
    const headerData = headerFilters(visibleColumns, filters);
    //console.log(headerData); // is an array of strings (titles of the columns)
    // Generates the filters summary.
    const filtersSummary=summaryFilters(visibleColumns, filters, format);
    //console.log(filtersSummary); //is an array of strings (Resource ID = All)
    // Name of report.
    const reportName = report.meta.name + "_" + moment().format("YYYY-MM-DD_HHmmss");


    if (format === "csv") {
        const csvData = formatCSV(filtersSummary,headerData, bodyData);
        downloadFile(reportName + ".csv", csvData, format);
    }
    else if (format === "html") {
        const htmlData = formatHTML(filtersSummary,headerData, bodyData);
        downloadFile(reportName + ".html", htmlData, format);
    }
}

export function downloadImport(filtersSummary,headerData,bodyData,format){
    // Name of report.
    const reportName = "import_" + moment().format("YYYY-MM-DD_HHmmss");
    if (format === "csv") {
        const csvData = formatCSV(filtersSummary,headerData, bodyData);
        downloadFile(reportName + ".csv", csvData, format);
    }
    else if (format === "html") {
        const htmlData = formatHTML(filtersSummary,headerData, bodyData);
        downloadFile(reportName + ".html", htmlData, format);
    }
}


//region Private methods

/**
 * Builds the summary of filters, includes filters and their values.
 * @param visibleColumns Columns that should be exported.
 * @param filters Actual filters an their values.
 * @param type Type of export "csv" or "html".
 * @returns {*}
 */
function summaryFilters(visibleColumns, filters, type) {

    const filtersValues =Object.keys(filters).map(e => filters[e]);

    let summary=[];

    if(visibleColumns!=null&&visibleColumns.length>0) {

        // Find only columns that are filters.
        let filters=[];
        visibleColumns.forEach(item => {
            const result = filtersValues.find(filter => filter.id === item.filter);

            let headerString = item.displayName;

            if (result != null) {

                let value = result.value;

                //region Filters Types
                if(result.type===FILTER_TYPE.DATETIME)
                {
                    // TODO: The way of associate both (from and to )filters does not exists, then using the same way implemented in DatetimeFilter.js
                    const fomName='from'+item.name.charAt(0).toUpperCase() + item.name.substr(1),
                          toName='to'+item.name.charAt(0).toUpperCase() + item.name.substr(1);
                    const fromValue=filtersValues.find(filter => filter.name === fomName),
                          toValue = filtersValues.find(filter => filter.name === toName);

                    // Find the range mode (TODAY,YESTERDAY,LAST_WEEK,LAST_MONTH or CUSTOM).
                    const fromTime = new Date(fromValue.value),
                          toTime = new Date(toValue.value);
                    const rangeMode = getRangeMode(fromTime,toTime);

                    value=getLabelForFilter({rangeMode,fromTime,toTime});
                }
                else if(result.type===FILTER_TYPE.INEQUALITY){
                    const label = result.name.match(/min|max/)[0];
                    value= label.toUpperCase()+" "+value;
                }
                //endregion



                // TODO: Check values of other special filters dates and range for example.
                if (result.value == null || isAllOption(result.value))
                    value = OPTION_ALL_TEXT;

                headerString = headerString + " = " + value;

                filters.push(headerString);
            }
        });

        // At least one filter exist in the grid.
        if (filters != null && filters.length > 0) {

            // Calculates the number of filters per column.
            const numberColsSummary = Math.ceil(filters.length / NUMBER_ROWS_SUMMARY);
            const numberRowsSummary=Math.min(NUMBER_ROWS_SUMMARY,filters.length);

            for(let i=0;i<numberRowsSummary;i++){

                if(summary[i]==null)
                    summary[i]=[];

                for(let j=0;j<numberColsSummary;j++){

                    const filterPosition=(numberRowsSummary*j)+i;

                    if(filterPosition<=filters.length)
                        summary[i].push(filters[filterPosition]);
                    else
                        summary[i].push("");
                }
            }
        }
    }

    return summary;
}

/**
 * Builds the header of report (filters).
 * @param visibleColumns Columns that should be exported.
 * @param filters Actual filters an their display name.
 * @returns {*}
 */
function headerFilters(visibleColumns, filters) {

    let headerWithFilters = visibleColumns.map(item => (item.displayName));

    return headerWithFilters;
}

/**
 * Filters the data and return only the allowed properties.
 * @param visibleColumns Allowed properties.
 * @param unfilteredData Raw data.
 * @returns {*|Array} Filtered data.
 */
function filterData(visibleColumns,unfilteredData){

    let filteredData=[];

    // Filter data with the allowed keys criteria.
    filteredData=unfilteredData.map(item=>{
        let rowData={};
        visibleColumns.forEach(allowedKey=>{

            rowData[allowedKey.name]=item[allowedKey.name];

            if(allowedKey.type==="DATETIME"&&rowData[allowedKey.name]!=null) {
                rowData[allowedKey.name] = getLocalTimestamp(new Date(rowData[allowedKey.name]));
            }
        });

        return rowData;
    });

    return filteredData;
}

/**
 * Convert the report data to CSV format.
 * @param filtersSummary Filters and theirs values.
 * @param headerData Header data of report.
 * @param data  Body data of report.
 * @returns {*}
 */
function formatCSV(filtersSummary,headerData, data) {

    let csv = [];

    if (data != null && data.length > 0) {
        const header = Object.keys(data[0]);
        csv = data.map(row => header.map(fieldName => {
            let value = row[fieldName];
            let csvValue = "";
            if (value === null)
                csvValue = '';
            else if (typeof value === 'string' || value instanceof String)
                csvValue = value.replace(/"/g, '""')
            else
                csvValue = value;

            return '"' + csvValue + '"';
        }).join(','));
    }

    // Adds header data.
    csv.unshift(joinString(headerData));

    // Adds filters summary.
    const summary=filtersSummary.map(row=>joinString(row));
    // Adds blank row.
    summary.push(",");
    csv.unshift(...summary);

    csv = csv.join('\r\n');

    return (csv);
}

/**
 * Join string and escape quotes.
 * @param arrayString
 * @returns {string}
 */
function joinString(arrayString){

    let result="";
    if(arrayString!=null&&arrayString.length>0)
    {
        const arrayQuoted=arrayString.map(item=>(item)?item.replace(/"/g, '""'):"");

        result='"'+arrayQuoted.join('","')+'"';
    }

    return result;
}
/**
 * Convert the report data to HTML format.
 * @param filtersSummary
 * @param headerData Header data of report.
 * @param data Body data of report.
 * @returns {string|*}
 */
function formatHTML(filtersSummary,headerData, data) {

    // Build html header and body.
    let htmlBody = [];
    if (data != null && data.length > 0) {
        const header = Object.keys(data[0]);
        htmlBody = data.map(row => header.map(fieldName => (row[fieldName])).join('<td/><td>'));
    }
    htmlBody.unshift(headerData.join('<td/><td>'));
    htmlBody = htmlBody.join('</td></tr><tr><td>');

    // Build html filters summary.
    let summaryHtml="";
    if(filtersSummary!=null&&filtersSummary.length>0){

        filtersSummary.forEach(row=>{
            summaryHtml += '<tr><td>' + row.join('<td/><td>') + '</td></tr>';
        });
    }

    let html = '<html>' +
        '<header>' +
        '<style>' +
        '    table {' +
        '        font-family:\'Roboto\',sans-serif;' +
        '        white-space:nowrap;' +
        '        border-collapse: collapse;' +
        '        border-spacing: 0;' +
        '    }' +
        '    table td {' +
        '        border-bottom: 1px solid #c0c0c0;' +
        '        padding: 0.3rem 0.25rem 0.2rem 0.25rem;' +
        '    }' +
        '    .export_html_header{' +
        '        border-bottom: 3px solid #c0c0c0;' +
        '        font-weight: bold;' +
        '        text-align: left;' +
        '    }' +
        '</style>' +
        '</header>';

    html+='<table>' + summaryHtml + '</table>';
    html+='<br/>';
    html+='<table><tr class="export_html_header"><td>' + htmlBody + '</td></tr></table>';
    html+='</html>';

    return (html);
}

/**
 * Convert a string into a file and starts downloading it.
 * @param fileName Name of file.
 * @param dataString Data of file.
 * @param type Extension of file (csv or html).
 */
function downloadFile(fileName, dataString,type){
    var blob = new Blob([dataString], {type: "text/"+type+";charset='utf-8'"});
    FileSaver.saveAs(blob, fileName);
}
//endregion