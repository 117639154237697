import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Button, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip} from '@material-ui/core';
import { Close as CloseIcon} from '@material-ui/icons';
import { useSelector, useDispatch} from "react-redux";
import {updateStateKeyFormTemplate} from '../../../../actions';
import {TEMPLATE_MAX_FIELD_DESCRIPTION, TEMPLATE_MAX_FIELD_NAME} from "../../../../constants/DeviceManager";

const useStyles = makeStyles({
    paperDialog: {
        overflowY: "visible"
    },
    tooltip:{
        color:'#000',
        backgroundColor:'#e6ee31',
        fontSize:'14px'
    },
    arrow:{
        color: '#e6ee31'
    },
    popper:{
        zIndex: 0
    }
});

const DialogAddTemplate = ({openDialog, closeDialog, actionSuccess}) => {
    const windowWidth = useSelector(state => state.resize.windowWidth);
    const userName = useSelector(state => state.user.username);

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [requiredFields, setRequiredFields] = useState({name:false, description:false});
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleCreateTemplate = () => {
        if(name.length > 0 && description.length > 0){
            handleAddTemplate();
        }else{
            let updateRequiredFields = {name:false, description:false};
            if (name.length === 0) updateRequiredFields.name = true;
            if (description.length === 0) updateRequiredFields.description = true;
            setRequiredFields(updateRequiredFields);
        }        
    }

    const handleAddTemplate = () => {
        let now = new Date();
        const currentDate = new Date(now).toISOString();
        dispatch(updateStateKeyFormTemplate('title', name.trim()));
        dispatch(updateStateKeyFormTemplate('description', description.trim()));
        dispatch(updateStateKeyFormTemplate('createDate', currentDate));
        dispatch(updateStateKeyFormTemplate('createBy',userName));
        actionSuccess();
    }

    const renderCloseButton = () => {
        return (
            <div style={{position:'absolute',top:'-0.5em',right:'-0.5em'}}>
                <IconButton aria-label="close"
                    size="small"
                    onClick={closeDialog}
                    style={{cursor:'pointer',position:'relative',zIndex:'100',backgroundColor:'#000'}}
                    >
                    <CloseIcon style={{color: '#fff'}} />
                </IconButton>
            </div>
        );
    }

    return (
        <Dialog 
            open={openDialog} fullWidth={true}
            onClose={closeDialog}
            classes={{paper:classes.paperDialog}}>
            <DialogTitle style={{paddingBottom:0}}><span style={{fontSize:'0.8em',fontWeight:'bold'}}>New Template Details</span></DialogTitle>
            {renderCloseButton()}
            <DialogContent style={{fontSize:'0.9em',display:'flex',flexDirection:'column',maxHeight:'100%'}}>
                <div style={{flex:0, marginBottom:'5px'}}>
                    No device will be updated
                </div>
                <div className="device-items">
                    <div className="basic-input">
                        <div className="field" style={{paddingLeft:0,paddingRight:0}}>
                            <span className="items-header">Name*</span>
                            <Tooltip open={requiredFields.name} arrow={true} title={'Mandatory Field'} placement={windowWidth>600?"right":"bottom"} PopperProps={{ disablePortal: true }}
                                classes={{tooltip:classes.tooltip, arrow:classes.arrow, popper: classes.popper}} >
                                <input type={"text"} maxLength={TEMPLATE_MAX_FIELD_NAME} value={name} onChange={e=>setName(e.target.value)}/>
                            </Tooltip>
                            <span className="identity-char-indicator">{TEMPLATE_MAX_FIELD_NAME-name.length} characters left.</span>
                        </div>
            
                        <div className="field" style={{paddingLeft:0,paddingRight:0,marginTop:'2px'}}>
                            <span className="items-header">Description*</span>
                            <Tooltip open={requiredFields.description} arrow={true} title={'Mandatory Field'} placement={windowWidth>600?"right":"bottom"} PopperProps={{ disablePortal: true }}
                                classes={{tooltip:classes.tooltip, arrow:classes.arrow, popper: classes.popper}} >
                                <textarea type={"text"} maxLength={TEMPLATE_MAX_FIELD_DESCRIPTION} value={description} onChange={e=>setDescription(e.target.value)}
                                    placeholder={"Enter Description here..."} style={{resize: 'none', height: '12em'}} />
                            </Tooltip>                            
                            <span className="identity-char-indicator">{TEMPLATE_MAX_FIELD_DESCRIPTION-description.length} characters left.</span>
                        </div>
                    </div>
                </div>
            </DialogContent> 
            <DialogActions>
                <Button style={{background: '#fff', color: '#007CB0', padding: '0.5em', textTransform: 'none'}} onClick={closeDialog} component="span">
                    Cancel
                </Button>
                <Button style={{background: '#007CB0',color: '#fff', padding: '0.5em', width: '200px', textTransform: 'none'}} 
                        onClick={handleCreateTemplate} component="span">
                    Create Template
                </Button>
            </DialogActions>               
        </Dialog>
    )
}

export default DialogAddTemplate;