import React, {useEffect, useState, useRef} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {
    unloadSiteAnMap,
    loadEditDevice,
    loadEditMultipleDevices,
    createDevicesBatch,
    unloadDevicesOptions,
    loadDevicesOptions,
    detectSaveChanges,
    loadOtherAntennasInZones,
    reloadSitesMaps,
    loadSiteAndMap,
    getZones,
    loadCoordinatesOfZonesOnMap
} from "../../../actions";
import {PATH_EDIT_DEVICE, EDIT_DEVICES_SCREEN} from '../../../constants/DeviceManager';
import DeviceManagerToolbar from '../toolbars/DeviceManagerToolbar';
import LocalMapContainer from "../common/localMapComponent/LocalMapContainer";
import DevicesListContainer from "../common/DevicesListContainer";
import FetchScreen from '../common/FetchScreen';

const EditDeviceMap = () => {
    const {pathParam} = useParams();
    const history = useHistory();
    const localMapContainerRef = useRef();
    const mapRef = useRef();
    const sourceRef = useRef();
    
    // const deviceManagerScreen = useSelector(state => state.deviceManager.deviceManagerScreen);
    const [showDeviceLabels, setShowDeviceLabels] = useState(false);
    const [showThumbnails, setShowThumbnails] = useState(false);
    const devicesBatch = useSelector(state => state.deviceManager.devicesBatch);
    const siteId = useSelector(state => state.deviceManager.siteId);
    const mapId = useSelector(state => state.deviceManager.mapId);
    const mapDevices = useSelector(state => state.deviceManager.mapDevices);
    const mapBounds = useSelector(state => state.deviceManager.mapBounds);
    const mapZoom = useSelector(state => state.deviceManager.mapZoom);
    const zones = useSelector(state => state.deviceManager.zones[siteId] || []);
    const isFetching = useSelector(state => state.deviceManager.isFetching);
    const sites = useSelector(state => state.deviceManager.sites);

    const canEdit = useSelector(state => state.user.permissions['edit-infrastructure-devices']);

    const [idSelected, setIdSelected] = useState(null);

    const selectItemPanel = (id) => {
        setIdSelected(id);
    }

    //const mapView = useSelector(state => state.deviceManager.mapView);
    const dispatch = useDispatch();

    const deviceLabelsCallback = (val) => {
        setShowDeviceLabels(val);
    };

    const showThumbnailsCallback = (val) => {
        setShowThumbnails(val);
    };

    const loadDevice = () => {
        //mounting           
        if(pathParam==='multiple'){
            dispatch(loadEditMultipleDevices());

        }else{
            dispatch(loadEditDevice(pathParam));
        }


        if(!sites){
            dispatch(reloadSitesMaps(null, null, true));
        }

        const unmount = () => {
            dispatch(unloadSiteAnMap());
            dispatch(createDevicesBatch([]));
            dispatch(unloadDevicesOptions());
            dispatch(detectSaveChanges(false));
            dispatch(loadOtherAntennasInZones({}));
        };
        return unmount;
    }

    useEffect(loadDevice, []);

    const handleLoadObjectDevices = () => {
        dispatch(loadDevicesOptions(devicesBatch));

    };

    useEffect(handleLoadObjectDevices, [devicesBatch]);

    const redirectLandingDevices = () => {
        if(canEdit !== undefined && canEdit === false){
            history.replace('/device-manager/devices');
        }
    }

    useEffect(redirectLandingDevices, [canEdit]);

    const putSiteDefault = () => {
        if(sites && sites.length > 0){
            const defaultSiteIdSelected = siteId?siteId:sites[0].id;
            const defaultMapIdSelected = mapId?mapId:null;
            dispatch(loadSiteAndMap(defaultSiteIdSelected, defaultMapIdSelected));
        }
    }

    useEffect(putSiteDefault, [sites]);

    const loadZones = () => {
        if(siteId){
            dispatch(getZones(siteId));
        }

        if(siteId && mapId){
            const deviceIds = pathParam==='multiple'? null: [pathParam];
            dispatch(loadCoordinatesOfZonesOnMap(siteId, mapId, deviceIds));
        }
    }

    useEffect(loadZones, [siteId]);

    const changeCoordinatesFeature = (evt, id, coordinates) => {
        if(localMapContainerRef.current !== undefined){
            localMapContainerRef.current.changeCoordinatesFeature(evt, id, coordinates);
        }
    }

    const addDeviceFeature = (evt, id) => {
        if(localMapContainerRef.current !== undefined){
            localMapContainerRef.current.addDeviceFeature(evt, id);
        }
    };

    const styleEditDevicesContainer = {display: 'flex',flex:'1 1 0%', position: 'absolute', right: '0px', left:'0px', height:'calc(100vh - 108px)'};
    // const styleLeftContainer = {flex: '0 0 0%', position: 'relative'};
    const styleRightContainer = {flex: '1 1 0%',display:'flex',flexDirection:'row', position: 'relative'};

    return (
        <React.Fragment>
            {isFetching? <FetchScreen />: null}
            <div className={"device-manager-container"}>
                <div className={"device-manager-header"}>
                    <DeviceManagerToolbar location={PATH_EDIT_DEVICE} />
                </div>
                <div className={"device-manager-content"} style={{overflowY:'auto'}}>
                    <div className={'device-manager-landing-container'}>
                        <div style={styleEditDevicesContainer}>
                        <DevicesListContainer devicesBatch={devicesBatch}
                                        idSelected={idSelected}
                                        selectItemPanel={selectItemPanel}
                                        mapRef={mapRef.current}
                                        sourceLayer={sourceRef.current}
                                        zones={zones || []}
                                        showDeviceLabels={showDeviceLabels}
                                        addDeviceFeature={mapBounds?addDeviceFeature:()=>{}}
                                        changeCoordinatesFeature={mapBounds?changeCoordinatesFeature:()=>{}}
                                        />
                            <div style={styleRightContainer}>
                                <LocalMapContainer
                                    ref={localMapContainerRef}
                                    devicesBatch={devicesBatch.filter(item => item.siteId === siteId && Number(item.location.mapID) === mapId)}
                                    siteId={siteId}
                                    mapId={mapId}
                                    mapDevices={mapDevices}
                                    mapBounds={mapBounds}
                                    mapZoom={mapZoom}
                                    zonesOnMap={zones}
                                    showDeviceLabels={showDeviceLabels}
                                    deviceLabelsCallback={deviceLabelsCallback}
                                    showThumbnails={showThumbnails}
                                    parentCallback={showThumbnailsCallback}
                                    deviceManagerScreen={EDIT_DEVICES_SCREEN}
                                    idSelected={idSelected}
                                    selectItemPanel={selectItemPanel}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default EditDeviceMap;