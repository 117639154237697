import React from 'react';
import PropTypes from 'prop-types';

import StyledDialog from '../../util/Dialog';
import DialogActions from '@material-ui/core/DialogActions';

import {DataEntryTabularField, DataEntryTabularForm} from '../../util/DataEntryTabularForm';
//import RaisedButton from 'material-ui/RaisedButton';
import RaisedButton from '@material-ui/core/Button';


import SummaryTable from './SummaryTable';

import IconButton from '@material-ui/core/IconButton';
//import DownloadIcon from 'material-ui/svg-icons/file/file-download';
import DownloadIcon from '../../icons/ExportIcon';
import DeleteIcon from '@material-ui/icons/Delete';

import dateFormat from 'dateformat';
import Paper from "@material-ui/core/Paper";

import ConfirmDialog from './ConfirmDialog';

import CollectLogsDialog, {TASK_GET_LOGS} from './CollectLogsDialog';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../../../actions';

const sortBy = require('lodash.sortby');



class DownloadDebugPackageDialog extends React.Component {

    constructor(){
        super();

        this.handleClose = this.handleClose.bind(this);
        this.renderPackage = this.renderPackage.bind(this);

        this.handleOpenCollect = this.handleOpenCollect.bind(this);
        this.handleCloseCollect = this.handleCloseCollect.bind(this);
        this.handleErrorCollect = this.handleErrorCollect.bind(this);
        this.handleSubmitCollect = this.handleSubmitCollect.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleSubmitConfirm = this.handleSubmitConfirm.bind(this);
        this.handleCloseConfirm = this.handleCloseConfirm.bind(this);
        this.refreshPackages = this.refreshPackages.bind(this);
        this.refresh = this.refresh.bind(this);

        this.state = {
            collectDialogOpen: false,
            deletePackageConfirmOpen: false,
            onConfirmSubmit:function(){}
        };
    }

    static get propTypes(){
        return {
            open: PropTypes.bool,
            data: PropTypes.object,
            appliance: PropTypes.object,
            logTasks: PropTypes.array,

            requestTasks: PropTypes.func.isRequired,
            createTask: PropTypes.func.isRequired,
            closeDataEntry: PropTypes.func.isRequired,
            requestPackages: PropTypes.func.isRequired,
            downloadPackage: PropTypes.func.isRequired,
            deletePackage: PropTypes.func.isRequired,
            displayErrorDialog: PropTypes.func.isRequired

        };
    }

    static get defaultProps(){
        return {
            open: false,
            data:{},
            logTasks:[]
        };
    }

    handleClose ()  {
        this.props.closeDataEntry();
    }

    refreshPackages(props){
        const {appliance} = props;
        if(appliance){
            this.props.requestPackages(appliance._id);
            this.props.requestTasks(appliance._id,TASK_GET_LOGS);
        }
    }

    refresh(){
        this.refreshPackages(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        const currentAppliance = this.props.appliance,
              nextAppliance = nextProps.appliance;

        if(nextAppliance && (!currentAppliance || (currentAppliance._id !== nextAppliance._id))){
            this.refreshPackages(nextProps);
        }
    }

    handleDownload(packageId){
        this.props.downloadPackage(packageId);
    }

    handleSubmitConfirm(packageId){
        this.props.deletePackage(packageId,()=>{
            this.handleCloseConfirm();
            this.refreshPackages(this.props);
        });
    }

    handleCloseConfirm(){
        this.setState({
            deletePackageConfirmOpen: false
        });
    }

    handleDelete(packageId){
        this.setState({
            deletePackageConfirmOpen: true,
            onConfirmSubmit: this.handleSubmitConfirm.bind(null,packageId)
        });
    }

    renderPackage(props){
        const pkg = props.package;

        let iconStyle = {
            width:'36px',
            height:'36px',
            padding:'6px',
            backgroundColor: 'transparent',
            color: 'inherit'
        };

        var time = new Date(pkg.timeCreated);

        return (
            <div style={{display:'table',width:'100%'}}>
                <div style={{display:'table-cell',verticalAlign:'middle'}}>
                    {dateFormat(time,"yyyy-mm-dd hh:MMTT")}
                </div>
                <div style={{display:'table-cell',width:'72px'}}>
                    <IconButton style={iconStyle} onClick={this.handleDownload.bind(this,pkg._id)}>
                        <DownloadIcon />
                    </IconButton>
                    <IconButton style={iconStyle} onClick={this.handleDelete.bind(this,pkg._id)}>
                        <DeleteIcon />
                    </IconButton>
                </div>
            </div>
        );
    }


    handleOpenCollect(){
        this.setState({
            collectDialogOpen: true
        });
    }

    handleCloseCollect(){
        this.setState({
            collectDialogOpen: false
        });
    }

    handleErrorCollect(err){
        this.props.displayErrorDialog(err);
    }

    handleSubmitCollect(){
        this.props.createTask.apply(this,arguments)
        .then(()=>{
            this.refreshPackages(this.props);
            this.handleCloseCollect();
        });
    }


    renderForm(){

        const {appliance, data, logTasks} = this.props;

        if(!appliance) return null;

        const packages = data.packages[appliance._id] || [];

        let _packages = sortBy(packages,['timeCreated']).reverse();

        const Package = this.renderPackage;

        const table = (
            <SummaryTable
                columns={[
                    {name:'a',caption:''}
                ]}
                data={
                    _packages.map( p => ({
                        a: (
                            <Package package={p} />
                        )
                    }))
                }
            />

        );



        const lastLogTask = logTasks[logTasks.length - 1];
        const lastLogTaskStatus = lastLogTask && lastLogTask.status;
        let fyiMessage = null;

        const sharedStyle = {
            padding:'0.25em 0 0.25em 1em',
            fontSize:'0.9em',
            color:'white',
            fontStyle:'italic',
            textAlign:'left'
        };

        if(lastLogTaskStatus === "processing"){
            fyiMessage = (
                <div style={{...sharedStyle, background:'#00779f'}}>
                    Collecting logs now...
                    <button style={{float:'right',background:'transparent',border:'none',fontSize:'1em'}} onClick={this.refresh}>Refresh</button>
                </div>
            );
        }else if(lastLogTaskStatus === "error"){
            fyiMessage = (
                <div style={{...sharedStyle, background:'#e8483a'}}>
                    Last attempt to collect logs failed.
                </div>
            );
        }


        var content = (_packages.length > 0) ? (
            <Paper>
                {fyiMessage}
                {table}
            </Paper>
        ): (
            <div>
                {fyiMessage}
                <div style={{textAlign:'center',border:'solid #aaa',borderWidth:'1px 0',padding:'0.5em 0',marginTop:'0.5em'}} key={2}>
                    {"No Packages."}
                </div>
            </div>

        );

        return (
            <DataEntryTabularForm fullWidth>
                <DataEntryTabularField>
                    <div style={{display:'table',fontSize:'0.9em'}}>
                        <span style={{fontWeight:'bold',display:'table-cell',letterSpacing:'0.5px',paddingRight:'1em'}}>Appliance </span>
                        <span style={{display:'table-cell'}}>{appliance.name}</span>
                    </div>
                </DataEntryTabularField>
                <DataEntryTabularField fieldStyle={{textAlign:'center',padding:'2em  0'}}>
                    <RaisedButton
                        label="Create New Log Zip File"
                        className="raised-button"
                        style={{width:'80%'}}
                        onClick={this.handleOpenCollect}
                        variant="contained"
                    >Create New Log Zip File
                    </RaisedButton>
                </DataEntryTabularField>
                <DataEntryTabularField>
                    <div style={{fontWeight:'bold',letterSpacing:'0.5px',paddingBottom:'0.5em',fontSize:'0.9em'}}>Downloadable Packages (most recent first)</div>
                    {content}
                </DataEntryTabularField>
            </DataEntryTabularForm>
        );
    }

    render() {

        const {open,appliance} = this.props;
        const {collectDialogOpen,deletePackageConfirmOpen,onConfirmSubmit} = this.state;

        return (
            <StyledDialog
                title="Logs"
                modal={false}
                open={open}
                onRequestClose={this.handleClose}
                onClose={this.handleClose}
                style={{
                    overflowY:'auto'
                }}
                contentStyle={{display:'inline-block',textAlign:'left'}}
                {...this.props}
                >
                <div style={{display:'inline-block',textAlign:'left',maxWidth: 360}}>
                    {this.renderForm()}
                </div>
                <DialogActions>

                <CollectLogsDialog
                    open={collectDialogOpen}
                    data={{appliances:[appliance]}}
                    onClose={this.handleCloseCollect}
                    onSubmit={this.handleSubmitCollect}
                    onError={this.handleErrorCollect}
                />
                <ConfirmDialog
                    open={deletePackageConfirmOpen}
                    onClose={this.handleCloseConfirm}
                    onSubmit={onConfirmSubmit}
                    content={"Permanently delete package?"}
                    subtitle={"Delete Debug Package"}
                    confirmText="Delete"
                    confirmColor="red"
                />
                </DialogActions>
            </StyledDialog>
        );
    }
}




const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        requestTasks: actions.requestTasks,
        createTask: actions.createTask,
        closeDataEntry: actions.closeDataEntry,
        requestPackages: actions.requestPackages,
        downloadPackage: actions.downloadPackage,
        deletePackage: actions.deletePackage,
        displayErrorDialog: actions.displayErrorDialog
    },dispatch)
});

const mapStateToProps = (state) => {

    const data = state.dataEntry.initialFormData;

    const appliance = data && data.appliances && data.appliances[0];

    if(!appliance) return {};

    const tasks = state.appliances.tasks[appliance._id];

    return {
        appliance,
        logTasks: tasks && tasks[TASK_GET_LOGS]
    };
};

export default connect(mapStateToProps,mapDispatchToProps)(DownloadDebugPackageDialog);
