import React, {useState} from "react";
import {TOOLTIPS_OPERATIONS, TEST, REBOOT, DISABLE} from "../../../constants/DeviceManager";
import {useSelector} from "react-redux";
import Divider from '@material-ui/core/Divider';

const CustomAction = props => {
    const {value, handleAction, protocol, actionKey, enabled} = props;
    const isOnCloud = useSelector(state => state.deviceManager.isOnCloud);
    const [showMassageTooltip,setShowMassageTooltip]=useState(false);
    const showTooltip=()=>{
        setShowMassageTooltip(true);
    }
    const hiddenTooltip=()=>{
        setShowMassageTooltip(false);
    }

    const getDivider = (item) => {
        switch(item){
            case TEST:
            case REBOOT:
            case DISABLE:
                   return <Divider style={{backgroundColor:'#7e868c', margin: '0 2px'}}/>;
            default:
                return null;
        }
    };

    const getTooltipMessage = () => {
        const specialOperations = ['initialize', 'reset'];

        if(specialOperations.indexOf(actionKey)>-1){
            if(protocol === 'https'){
                return null;
            }else if(isOnCloud){
                return TOOLTIPS_OPERATIONS[actionKey][protocol];
            }else{
                const tooltipMessage = TOOLTIPS_OPERATIONS[actionKey]['all'];
                return tooltipMessage;
            }
        }else{
            const tooltipMessage = TOOLTIPS_OPERATIONS[actionKey]['all'];
            return tooltipMessage;
        }
    }

    const tooltipMessage = getTooltipMessage();
    const numberStringsPerLine = 30;
    const heightLine = 17;
   
    return <>
        {(showMassageTooltip)?<div className={'tooltip-action-item'} style={{marginTop:`-${Math.ceil(tooltipMessage.length/numberStringsPerLine)*heightLine}px`}}>{tooltipMessage}</div>:null}
        <div className={enabled?"items-list":"items-list-disabled"} onClick={enabled?handleAction:()=>{}}> 
            {(tooltipMessage) ? <div className='items-list-content' onMouseOver={showTooltip} onMouseLeave={hiddenTooltip}>{value}</div>:<div className='items-list-content'>{value?.replace('_', ' ')}</div>}
            {getDivider(actionKey)}
        </div>
    </>;
}

export default CustomAction;
