import React from 'react';
import PropTypes from 'prop-types';
// import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import {Popover} from '@material-ui/core';

import {Field, Form} from '../../../../util/DataEntryTabularForm';
import DateTimePickerFilter from '../../../../util/datetimepicker';


import {getLabelForFilter, getRangeMode, getTimes, PERIOD} from '../../../../util/report/filter/util';
import NextPrevButtonsDialog from "./NextPrevButtonsDialog";
import moment from "moment";

/*
███    ███ ███████ ███    ██ ██    ██
████  ████ ██      ████   ██ ██    ██
██ ████ ██ █████   ██ ██  ██ ██    ██
██  ██  ██ ██      ██  ██ ██ ██    ██
██      ██ ███████ ██   ████  ██████
*/

class FilterMenu extends React.Component{
    constructor(props) {
        super(props);

        this.handleRangeChange = this.handleRangeChange.bind(this);
        this.handleCustomChange = this.handleCustomChange.bind(this);
    }

    static get propTypes(){
        return {
            open: PropTypes.bool,
            anchorEl: PropTypes.any,
            onRequestClose: PropTypes.func.isRequired,
            onChange: PropTypes.func.isRequired,
            width: PropTypes.number,

            // trio of "values"
            rangeMode: PropTypes.string,
            fromTime: PropTypes.any,
            toTime: PropTypes.any,
        };
    }

    componentDidUpdate(){
        // this is to solve a render problem
        // with the time filter.  It probably should
        // be similar with the external datepicker.
        // (there was no problem with that...)
        var elm = this.refs.the_div;
        if(!elm) return;
        elm = elm.parentNode.parentNode;
        elm.style.overflowY = "visible";
    }

    handleTouchTap(event){
        // This prevents ghost click.
        event.preventDefault();

        this.setState({
            open: true,
            anchorEl: event.currentTarget,
        });
    }

    handleSelectValue(val){
        this.props.onChange(val);
        this.props.onRequestClose();
    }

    handleRangeChange(e){
        var rangeMode = e.target.value;
        var times = getTimes(rangeMode);
        this.props.onChange({
            rangeMode,
            fromTime:times.from,
            toTime: times.to
        });
    }

    handleCustomChange(which,value){
        const {rangeMode,fromTime,toTime} = this.props;

        var r = {
            rangeMode,
            fromTime,
            toTime
        };
        if(which==="from"){
            r.fromTime = value;
            const differenceDays = moment(toTime).diff(moment(value), 'days');
            if(differenceDays>7 ||differenceDays<0){
                r.toTime = this.calculateNewDate(value,7);
            }
        }
        if(which==="to"){
            r.toTime = value;
            /*const differenceDays = moment(toTime).diff(moment(value), 'days');
            if(differenceDays>7 ||differenceDays<0){
                r.fromTime = this.calculateNewDate(value,-7);
            }*/
        }
        this.props.onChange(r);
    }
    calculateNewDate(dateForm,increment){
        let d = new Date(dateForm);
        d.setDate(d.getDate() + increment);
        return d;
    }
    render(){
        const {rangeMode,fromTime,toTime,nextPrevButtons} = this.props;
        const minDateTo = fromTime;
        const maxDateTo = this.calculateNewDate(fromTime,7);

        return (
            <Popover open={this.props.open}
                     anchorEl={this.props.anchorEl}
                     key={"datetimefilter"}
                     className={"filter-date-history"}
                     anchorOrigin={{vertical:'bottom', horizontal:'left'}}
                     style={{maxWidth: 480, zIndex:10000}}
                     >
                <ClickAwayListener onClickAway={this.props.onRequestClose}>

                <div className={"popper-style"} style={{minWidth:this.props.width+'px'}}>
                    <Form fullWidth>
                        <Field label="From / To">
                            <select onChange={this.handleRangeChange} value={rangeMode}>
                                <option value={PERIOD.TODAY}>{nextPrevButtons?'One day':'Today'}</option>
                                <option value={PERIOD.LAST_WEEK}>{nextPrevButtons?'7 days':'Last 7 days'}</option>
                                <option value={PERIOD.CUSTOM}>Custom</option>
                            </select>
                        </Field>
                        <Field label="From (Custom)">
                            <DateTimePickerFilter defaultValue={fromTime} nextPrevButtons = {nextPrevButtons} minDate={""} maxDate={""} disabled={rangeMode!==PERIOD.CUSTOM} onChange={this.handleCustomChange.bind(null,"from")}/>
                        </Field>
                        <Field label="To (Custom)">
                            <DateTimePickerFilter defaultValue={toTime} nextPrevButtons = {nextPrevButtons} minDate={minDateTo} maxDate={maxDateTo} disabled={rangeMode!==PERIOD.CUSTOM} onChange={this.handleCustomChange.bind(null,"to")}/>
                        </Field>
                    </Form>
                </div>
                </ClickAwayListener>
            </Popover>
        );
    }
}

//
const styles={
    buttonStyle :{
        fontSize:'0.9em',
        width:'calc(100% - 6px)',
        margin:'3px 0px',
        height:'2.222em',
        borderRadius:'4px',
        backgroundColor:'white',
        border:'1px solid #aaa',
        textAlign:'left',
        whiteSpace:'nowrap',
        paddingRight:'1em',
        textOverflow:'ellipsis',
        overflow:'hidden',
        position:'relative',
    }
};

const Carot = ()=>(
    <div className="carotDatepicker"></div>
);

Carot.propTypes = {style:PropTypes.object};

/*
███████ ██ ██   ████████ ███████ ██████
██      ██ ██      ██    ██      ██   ██
█████   ██ ██      ██    █████   ██████
██      ██ ██      ██    ██      ██   ██
██      ██ ███████ ██    ███████ ██   ██
*/

class Filter extends React.Component{

    static get propTypes(){
        return {
            fromFilter: PropTypes.shape({
                id: PropTypes.any,
                value: PropTypes.any

            }),
            toFilter: PropTypes.shape({
                id: PropTypes.any,
                value: PropTypes.any
            }),
            onChange: PropTypes.func.isRequired,
            onChangeSet: PropTypes.func,
            onSetDateRange: PropTypes.func,
            buttonStyle:PropTypes.object,
            carotStyle: PropTypes.object,
            onChangeDateAndData: PropTypes.func
        };
    }

    static get defaultProps(){
        return {
            filter:{},
            buttonStyle:{},
            carotStyle:{},
            onChangeSet:()=>{}
        };
    }

    constructor(props){
        super(props);

        this.state = {
            open: false,
            anchorEl: null,

            ...this.getNewStateFromProps(props)
        };

        this.handleTouchTap = this.handleTouchTap.bind(this);
        this.handleRequestClose = this.handleRequestClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }



    handleChange(o){
        const {fromFilter,toFilter,name} = this.props;
        const {fromTime,toTime} = this.state;

        let changeset = {};
        let dateChangeset = {};
        // prevent unnecessary updates.
        if(o.fromTime.toJSON() !== fromTime.toJSON()){
            changeset[fromFilter.id] = o.fromTime.toJSON();
            dateChangeset[fromFilter.id] = o.fromTime.toJSON();
        }
        // prevent unnecessary updates.
        if(o.toTime.toJSON() !== toTime.toJSON()){
            changeset[toFilter.id] = o.toTime.toJSON();
            dateChangeset[toFilter.id] = o.toTime.toJSON();
        }
        if(Object.keys(changeset).length !== 0){
            this.props.onChangeSet(changeset);
        }

        // Change the value of predefined time in redux store and local storage.
        if(this.props.onChangeDatePredefinedTime!=null) {
            this.props.onChangeDatePredefinedTime(name, o.rangeMode, fromFilter.id, toFilter.id);
        }


        let len = Object.keys(dateChangeset).length;
        if(len !== 0){
            if(len === 1) {
                let from = null;
                let fromId = 1461;
                let to = null;
                let toId = 1462;
                Object.keys(dateChangeset).forEach(k => {
                    this.props.onChange(k, dateChangeset[k]);
                    /*if(this.props.filters[k].displayName.toLowerCase() === 'from'){
                        from = dateChangeset[k];
                        fromId = k;
                    }
                    if(this.props.filters[k].displayName.toLowerCase() === 'to'){
                        to = dateChangeset[k];
                        fromId = k;
                    }*/
                });

                if(o.rangeMode === 'TODAY'){
                    if(!from || from == null){
                        let today = new Date();
                        today.setHours(0,0,0,0);
                        from = today.toISOString();
                    }
                    let newToValue = new Date(from);
                    newToValue.setDate(newToValue.getDate() + 1);
                    to = newToValue.toISOString();
                    this.props.onChangeDateSet(fromId, from, toId, to);
                } else if (o.rangeMode === 'CUSTOM'){

                    this.props.onChangeDateSet(fromId, from, toId, to);
                }
                /*const differenceInTime = newDateTimeTo.getTime() - newDateTimeFrom.getTime();
                let differenceInDays = (differenceInTime / (1000 * 3600 * 24));
                differenceInDays = differenceInDays<1?1:valueFrom===PERIOD.TODAY?differenceInDays-1:differenceInDays;*/

            } else if (len === 2) {
                let fromId, fromFilter, toId, toFilter;
                Object.keys(dateChangeset).forEach((k, i) => {
                    if(i === 0) {
                        fromId = k; fromFilter = dateChangeset[k];
                    } else if(i === 1) {
                        toId = k; toFilter = dateChangeset[k];
                    }
                });
                if(this.props.onChangeDateSet) {
                    this.props.onChangeDateSet(fromId, fromFilter, toId, toFilter);
                } else {
                    this.props.onChange(fromId, fromFilter);
                    this.props.onChange(toId, toFilter);
                    this.props.onChangeDateSet(fromId, fromFilter, toId, toFilter);
                }
            }
        }

        this.setState({
            rangeMode: o.rangeMode,
            fromTime: o.fromTime,
            toTime: o.toTime
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        this.setState({
            ...this.getNewStateFromProps(nextProps,this.state)
        });
    }

    shouldComponentUpdate(nextProps, nextState){
        return (nextProps !== this.props) || (nextState !== this.state);
    }

    getNewStateFromProps(props,state={}){
        const from = props.fromFilter.value;
        const to = props.toFilter.value;

        if (from && to&&props.rangeMode) {
            let rangeMode, fromTime, toTime;

            if (props.rangeMode !== PERIOD.CUSTOM) {
                rangeMode = props.rangeMode;
                /*const times=getTimes(rangeMode);

                fromTime =new Date(times.from);
                toTime = new Date(times.to);
                */
                fromTime = new Date(from);
                toTime = new Date(to);
            }
            else{
                rangeMode = PERIOD.CUSTOM;
                fromTime = new Date(from);
                toTime = new Date(to);
            }

            return {
                rangeMode: rangeMode,
                fromTime,
                toTime
            };
        }
        else if(from && to){

            let rangeMode = PERIOD.CUSTOM,
                fromTime = new Date(from),
                toTime = new Date(to);

            // Gets the range mode (TODAY,YESTERDAY,LAST_WEEK,LAST_MONTH or CUSTOM)
            rangeMode=getRangeMode(fromTime,toTime);
            return {
                rangeMode: rangeMode,
                fromTime,
                toTime
            };
        }

        let today = getTimes(PERIOD.TODAY);

        return {
            rangeMode: PERIOD.TODAY,
            fromTime: new Date(today.from),
            toTime: new Date(today.to)
        };
    }

    handleTouchTap(event){
        // This prevents ghost click.
        event.preventDefault();

        let width = this.refs.button.offsetWidth;

        this.setState({
            open: true,
            anchorEl: event.currentTarget,
            width
        });
    }

    handleRequestClose(){
        this.setState({
            open:false
        });
    }

    handleChangeDateTime(fromId, newDateTimeFrom,toId, newDateTimeTo){

    }



    render(){
        const {rangeMode,fromTime,toTime} = this.state;
        const {buttonStyle,nextPrevButtons} = this.props;

        return (
            <div style={{display:'flex', justifyContent:'space-between'}}>
                <div>
                    <button className="datepicker-carot" style={{...styles.buttonStyle,...buttonStyle}} onClick={this.handleTouchTap} ref="button">
                        {getLabelForFilter({rangeMode,fromTime,toTime,nextPrevButtons})}
                    </button>
                    <FilterMenu
                        open={this.state.open}
                        anchorEl={this.state.anchorEl}
                        onRequestClose={this.handleRequestClose}
                        onChange={this.handleChange}
                        width={this.state.width}
                        rangeMode={rangeMode}
                        fromTime={fromTime}
                        toTime={toTime}
                        nextPrevButtons={nextPrevButtons}
                    />
                </div>
                {nextPrevButtons? <NextPrevButtonsDialog
                    onChangeDateSet={this.props.onChangeDateSet}
                    onChangeDatePredefinedTimeLast = {this.props.onChangeDatePredefinedTime}
                    fromFilter={this.props.fromFilter}
                    toFilter={this.props.toFilter}
                    nameFilter={this.props.name}
                    reportId={this.props.reportId}
                    filters={this.props.filters}
                    deviceId={this.props.deviceId}
                    onChangeDateAndData={this.props.onChangeDateAndData}
                />:null}
            </div>
        );
    }


}

export const DumbDateTimeFilter = Filter;



/*
 ██ ██     ██ ██████   █████  ██████  ██████  ███████ ██████  ██
██  ██     ██ ██   ██ ██   ██ ██   ██ ██   ██ ██      ██   ██  ██
██  ██  █  ██ ██████  ███████ ██████  ██████  █████   ██████   ██
██  ██ ███ ██ ██   ██ ██   ██ ██      ██      ██      ██   ██  ██
 ██  ███ ███  ██   ██ ██   ██ ██      ██      ███████ ██   ██ ██
*/

export default class DatetimeFilter extends React.Component{


    static get propTypes(){
        return {
            actions: PropTypes.shape({
                changeFilterValue: PropTypes.func.isRequired,
                changeFilterFromToValue: PropTypes.func.isRequired,
                changeSpecialFilterValue: PropTypes.func.isRequired,
            }),
            reportId: PropTypes.string,
            name: PropTypes.string,
            filters: PropTypes.object
        };
    }

    constructor(){
        super();
        this.handleChange = this.handleChange.bind(this);
        this.onChangeDateSet = this.onChangeDateSet.bind(this);
        this.handleChangeDatePredefinedTime = this.handleChangeDatePredefinedTime.bind(this);
        this.handleDaysRange = this.handleDaysRange.bind(this);
    }

    shouldComponentUpdate(nextProps){
        return this.props.filters !== nextProps.filters;
    }

    handleChange(id,value){
        this.props.actions.changeFilterValue(id,value);
    }

    onChangeDateSet(fromId, fromValue, toId, toValue){
        this.props.actions.changeFilterFromToValue(fromId, fromValue, toId, toValue);
    }

    handleChangeDatePredefinedTime(filterKey,filterValue,from,to){

        this.props.actions.changeSpecialFilterValue(filterKey,filterValue,from,to);
    }

    handleDaysRange(){
        this.props.actions.changeDateRange();
    }

    render(){
        const {name,filters,reportId,nextPrevButtons,deviceId} = this.props;
        let fromFilter, toFilter;

        Object.keys(filters).forEach(f=>{
            if(filters[f].name === 'from'+name[0].toUpperCase()+name.substr(1)){
                fromFilter = filters[f];
            }

            if(filters[f].name === 'to'+name[0].toUpperCase()+name.substr(1)){
                toFilter = filters[f];
            }
        });

        const rangeMode=(filters.datetimeFilters&&filters.datetimeFilters[reportId]&&filters.datetimeFilters[reportId][name])?filters.datetimeFilters[reportId][name].value:null;

        return (
            <Filter
                fromFilter={fromFilter}
                toFilter={toFilter}
                onChange={this.handleChange}
                onChangeDateSet={this.onChangeDateSet}
                onChangeDatePredefinedTime={this.handleChangeDatePredefinedTime}
                onSetDateRange={this.handleDaysRange}
                name={name}
                rangeMode={rangeMode}
                nextPrevButtons={nextPrevButtons}
                reportId={reportId}
                filters={filters}
                deviceId={deviceId}
                onChangeDateAndData={this.props.onChangeDateAndData}
            />
        );
    }
}
