import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    cleanDownloadLogs,
    cleanDownloadTypes,
    downloadDeviceLogs,
    updateFetchHistoryStatus
} from "../../../../../actions";
import FetchScreenLogs from "../../FetchScreenLogs";
import CrossBrowserTable from "../../tableComponent/CrossBrowserTable";
import FilterTree from "../../../landing/FilterTree";
import {LANDING_DEVICE_TABLE, OPE_STATUS_FAILURE, OPE_STATUS_NA} from "../../../../../constants/DeviceManager";
import WarningAlert from "./WarningAlert";
const FETCH_MESSAGE = "Getting log from device";

const LogDownloadComponent = ({devicesArray}) => {
    const aditionalColumns = {
        "syslog": {key:"syslog",value:"Sys Log",width:120,clickable:false,sortable:false,enable:true,order:5},
        "radioPacketLog": {key:"radioPacketLog",value:"Radio Packet Log",width:120,clickable:false,sortable:false,enable:true,order:6}};
    const [header, setHeader] = useState([]);
    const [headerHeight, setHeaderHeight] = React.useState(0);
    const fetchingDownloadLogs = useSelector(useState => useState.deviceManager.fetchingDownloadLogs);
    const logDialogColumns = useSelector(state => ({...state.deviceManager.logDialogColumns,...aditionalColumns}));
    const dispatch = useDispatch();

    const handleLoadColumns = () => {
        const keys = Object.keys(logDialogColumns);
        const headerColumns = keys.map((key) => logDialogColumns[key]);
        setHeader(headerColumns);

        return () => {
            dispatch(cleanDownloadLogs());
            dispatch(cleanDownloadTypes());
            dispatch(updateFetchHistoryStatus(false));
        }
    };

    useEffect(handleLoadColumns, []);

    const customHeader = ({text, tableWidth, tableHeight, itemKey}) => {

        const items = devicesArray && devicesArray.map(item => item).filter((value, index, self) => self.indexOf(value) === index);
        let headerDescription = header.filter( headerDescription =>{ return headerDescription.key === itemKey; });
        const columnConfig = logDialogColumns[itemKey];
        let statusSortable = false;
        if( headerDescription && headerDescription.length > 0){
            statusSortable = headerDescription[0].sortable;
        }

        return (
            <FilterTree sortIndex={null}
                        items={items}
                        text={text}
                        tableWidth={tableWidth}
                        tableHeight={tableHeight}
                        tree={false}
                        itemKey={itemKey}
                        type={'devices'}
                        statusSortable={statusSortable}
                        columnConfig={columnConfig}
                        tableScreen={LANDING_DEVICE_TABLE}
            />
        )
    };


    const customCell = ({rowIndex, headerKey, content}) => {
        switch(headerKey){
            case "syslog":
                /*if(devicesArray[rowIndex].status.title===OPE_STATUS_NA||
                    devicesArray[rowIndex].status.title===''||
                    devicesArray[rowIndex].status.title==null||
                    devicesArray[rowIndex].status.title===OPE_STATUS_FAILURE){
                    return <div style={{color:'grey', fontStyle:'italic'}}>Cannot download</div>
                } else {*/
                    return <DownloadLogButton deviceId={devicesArray[rowIndex]._id} name={devicesArray[rowIndex].title}
                                              type={headerKey} status={devicesArray[rowIndex].status?.title}/>;
                // }
            case "radioPacketLog":
                /*if(devicesArray[rowIndex].status.title===OPE_STATUS_NA||
                    devicesArray[rowIndex].status.title===''||
                    devicesArray[rowIndex].status.title==null||
                    devicesArray[rowIndex].status.title===OPE_STATUS_FAILURE){
                    return <div style={{color:'grey', fontStyle:'italic'}}>Cannot download</div>
                } else */if(!devicesArray[rowIndex].logLevel || Object.keys(devicesArray[rowIndex].logLevel).length === 0 || !devicesArray[rowIndex].logLevel.radioPacketLog){
                    return <div style={{color:'grey', fontStyle:'italic'}}>Not configured</div>
                } else {
                    return <DownloadLogButton deviceId={devicesArray[rowIndex]._id} name={devicesArray[rowIndex].title}
                                              type={headerKey} status={devicesArray[rowIndex].status?.title}/>;
                }
            default:
                return <React.Fragment>{content}</React.Fragment>
        }
    };
    
    const styleContainerTable = {flex: '1 1 380px', display: 'flex', flexDirection: 'row', width: '100%'};

    return(
        <React.Fragment>
            <div className="data-entry-form" style={{display: 'flex', justifyContent: 'space-between', padding: '0.3em 0 1em', flex:'1 1'}}>
                <span style={{fontWeight: 'bold'}}>Devices selected:</span>
            </div>
            <div style={styleContainerTable}>
            {fetchingDownloadLogs[devicesArray[0]._id] && fetchingDownloadLogs[devicesArray[0]._id].loading ? <FetchScreenLogs message={FETCH_MESSAGE} /> : null}
            {/*{fetchAlreadyDownload && <FetchScreenLogs message={FETCH_MESSAGE_ADDITIONAL_DOWNLOAD} />}*/}
            <CrossBrowserTable
                data={devicesArray}
                rowCount={devicesArray.length}
                showCheckColumn={false}
                headerRow={header}
                headerRender={(...rest) => customHeader(...rest)}
                cellRender={customCell}
                selectedRows={{}}
                onClickRow={()=>{}}
                onSelectedAll={()=>{}}
                modernBrowser={true}
                appliedFiltersHeight={headerHeight}
                setHeaderHeight={setHeaderHeight}
            />
            </div>
        </React.Fragment>
    )
};

export default LogDownloadComponent;

const DownloadLogButton = ({deviceId, name, type, status}) => {
    const dispatch = useDispatch();
    const [count, setCount] = useState(0);
    const [warningOpen, setWarningOpen] = useState(0);
    const [warningMessage, setWarningMessage] = useState(0);

    const clickDownloadButton = () => {
        if(!status || status===OPE_STATUS_NA||
            status===''||
            status==null||
            status===OPE_STATUS_FAILURE){
            setWarningMessage(`The actual status of device is ${status}. Are you sure to download?`);
            setWarningOpen(true)
        } else {
            setCount(count + 1);
            dispatch(downloadDeviceLogs(deviceId, [type], name));
        }
    }

    const handleCloseWarningDialog = () => {
        setWarningOpen(false)
    };

    const handleContinueAction = () => {
        setCount(count + 1);
        dispatch(downloadDeviceLogs(deviceId, [type], name));
        setWarningOpen(false)
    };

    return (
        <>
        <div aria-disabled={"true"} style={{textAlign: 'center', textDecoration: 'underline', color: count>0? '#B23A0F':'#327cc7'}}>
            <span style={{cursor: 'pointer'}} onClick={clickDownloadButton}>Download</span>
        </div>
            {warningOpen && <WarningAlert open={warningOpen} message={warningMessage} closeAlert={handleCloseWarningDialog} continueAction={handleContinueAction}/>}
        </>
    );
}