import reducer from './reducers';
import {applyMiddleware, compose, createStore} from 'redux';
import {WINDOW_RESIZE} from './constants/ActionTypes';

import thunk from 'redux-thunk';
import {throttle} from 'lodash';
import {renewServices} from './actions/util';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));

// The rest of this page is used for subscribing functions. (Usually for configuration
// or peristing information using cookies, localStorage, etc.)
//
// It may be advisable to put these in a separate 'subscribers' folder.

/*
 ██████  ██████  ██      ██    ██ ███    ███ ███    ██      ██████  ██████  ██████  ███████ ██████
██      ██    ██ ██      ██    ██ ████  ████ ████   ██     ██    ██ ██   ██ ██   ██ ██      ██   ██
██      ██    ██ ██      ██    ██ ██ ████ ██ ██ ██  ██     ██    ██ ██████  ██   ██ █████   ██████
██      ██    ██ ██      ██    ██ ██  ██  ██ ██  ██ ██     ██    ██ ██   ██ ██   ██ ██      ██   ██
 ██████  ██████  ███████  ██████  ██      ██ ██   ████      ██████  ██   ██ ██████  ███████ ██   ██

██████  ███████ ██████  ███████ ██ ███████ ████████
██   ██ ██      ██   ██ ██      ██ ██         ██
██████  █████   ██████  ███████ ██ ███████    ██
██      ██      ██   ██      ██ ██      ██    ██
██      ███████ ██   ██ ███████ ██ ███████    ██
*/


function persistToLocalStorage(key, data) {
    window.localStorage.setItem(key, JSON.stringify(data));
}


let oldState;
store.subscribe(()=>{
    const state = store.getState();

    // (init)
    if(!oldState){
        oldState = state;
        return;
    }

    const username = state.user.username;
    if (oldState.report.columnOrder !== state.report.columnOrder) {
        persistToLocalStorage(`mwe-persisted-state/report/column-order/${username}`, state.report.columnOrder);
    }

    if (oldState.report.sidebarColumnOrder !== state.report.sidebarColumnOrder) {
        persistToLocalStorage(`mwe-persisted-state/report/sidebar-column-order/${username}`, state.report.sidebarColumnOrder);
    }

    if (oldState.filters !== state.filters) {
        persistToLocalStorage(`mwe-persisted-state/filters/${username}`, state.filters);
    }

    if (oldState.deviceManager.selectedFilters !== state.deviceManager.selectedFilters) {
        persistToLocalStorage(`mwe-persisted-state/deviceManager/selectedFilters/${username}`, state.deviceManager.selectedFilters);
    }

    if (oldState.deviceManager.columnOrder !== state.deviceManager.columnOrder) {
        persistToLocalStorage(`mwe-persisted-state/deviceManager/columnOrder/${username}`, state.deviceManager.columnOrder);
    }

    if (oldState.reportMap.dateRange !== state.reportMap.dateRange) {
        persistToLocalStorage(`mwe-persisted-state/playback/date-range/${username}`, state.reportMap.dateRange)
    }

    if (oldState.reportMap.currentLocalMap !== state.reportMap.currentLocalMap) {
        persistToLocalStorage(`mwe-persisted-state/reportMap/current-local-map/${username}`, state.reportMap.currentLocalMap);
    }

    oldState = state;
});
// function loadJSONFromStorage(key){
//     var val = window.localStorage.getItem(key);
//     try{
//         val = JSON.parse(val);
//         return val;
//     }catch(e){
//         return null;
//     }
// }

/*
 █████  ███████ ██    ██ ███    ██  ██████
██   ██ ██       ██  ██  ████   ██ ██
███████ ███████   ████   ██ ██  ██ ██
██   ██      ██    ██    ██  ██ ██ ██
██   ██ ███████    ██    ██   ████  ██████


 ██████  ██████  ███    ██ ███████ ██  ██████
██      ██    ██ ████   ██ ██      ██ ██
██      ██    ██ ██ ██  ██ █████   ██ ██   ███
██      ██    ██ ██  ██ ██ ██      ██ ██    ██
 ██████  ██████  ██   ████ ██      ██  ██████
*/



// Initial State
let USERNAME = window.localStorage.getItem("username");
let JWT_TOKEN = window.localStorage.getItem("jwtToken");

renewServices({USERNAME,JWT_TOKEN});

store.subscribe(()=>{
    var state = store.getState();
    if(state.user.username !== USERNAME || state.user.jwtToken !== JWT_TOKEN){
        // cache the values
        USERNAME = state.user.username;
        JWT_TOKEN = state.user.jwtToken;

        // update the instances.
        renewServices({USERNAME,JWT_TOKEN});
    }
});


function notifyResize() {
    store.dispatch({ type: WINDOW_RESIZE, width: window.innerWidth, height: window.innerHeight });
}

const RESIZE_THROTTLE_MILLISECONDS = 200;
window.addEventListener('resize', throttle(notifyResize, RESIZE_THROTTLE_MILLISECONDS));

export default store;
