import React from 'react';
import PropTypes from 'prop-types';
import {AutoSizer, Grid} from 'react-virtualized';
import Checkbox from '@material-ui/core/Checkbox';
import cn from 'classnames';

import scrollbarSize from 'dom-helpers/scrollbarSize';

import assign from 'object-assign';
//import CustomGrid from "./custom/CustomGrid";
import CustomGridVirtualized from "./custom/CustomGridVirtualized";

/*

██████  ███████ ██████  ███████
██   ██ ██      ██   ██ ██
██████  █████   ██████  █████
██      ██      ██   ██ ██
██      ███████ ██   ██ ██

*/


class WrappedCheckbox extends React.Component{

    static get propTypes(){
        return {
            checked: PropTypes.bool
        };
    }

    componentDidMount(){
        this.rerenderFlag = true;
    }

    shouldComponentUpdate(np){
        return (this.props.checked !== np.checked);
    }
    render(){
        return (
            <Checkbox checked={this.props.checked} className="report-checkbox" disableTouchRipple={true}/>
        );
    }
}

/*

████████  █████  ██████  ██      ███████ ██████   ██████  ██████  ██    ██
   ██    ██   ██ ██   ██ ██      ██      ██   ██ ██    ██ ██   ██  ██  ██
   ██    ███████ ██████  ██      █████   ██████  ██    ██ ██   ██   ████
   ██    ██   ██ ██   ██ ██      ██      ██   ██ ██    ██ ██   ██    ██
   ██    ██   ██ ██████  ███████ ███████ ██████   ██████  ██████     ██

*/

const localeTimestamp = (date) => {
    return date.toLocaleDateString(window.navigator.language,{
        year:"2-digit",
        month:"numeric",
        day:"numeric",
        hour:"numeric",
        minute:"numeric",
        second:"numeric"
    });
};


export { localeTimestamp }

export default class TableBody extends React.Component {

    constructor (props) {
        super(props);

        const {columns,data} = this.props;
        this.state = {
            columnCount: columns.length,
            overscanColumnCount: 1,
            overscanRowCount: 2,
            rowHeight: 40,
            rowCount: data.length,
            hoverRow: -1
        };

        this.forceGridUpdate = this.forceGridUpdate.bind(this);
        this.jumpToRow = this.jumpToRow.bind(this);

        // data providers
        this.getColumnWidths = this.getColumnWidths.bind(this);
        this.getRowHeights = this.getRowHeights.bind(this);
        this.getWidthOfFirstXColumns = this.getWidthOfFirstXColumns.bind(this);
        this._getDatum = this._getDatum.bind(this);

        // reference establishment
        this.addFixedColumnRef = this.addFixedColumnRef.bind(this);
        this.addScrollableColumnRef = this.addScrollableColumnRef.bind(this);

        // renderers
        this._fixedCellRenderer = this._fixedCellRenderer.bind(this);
        this._scrollableCellRenderer = this._scrollableCellRenderer.bind(this);
        this._noContentRenderer = this._noContentRenderer.bind(this);
        this._noContentBodyRenderer = this._noContentBodyRenderer.bind(this);
        this._renderBodyCell = this._renderBodyCell.bind(this);
        this._renderBodyCellContent = this._renderBodyCellContent.bind(this);

        // handlers
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
        this.handleMouseOverCell = this.handleMouseOverCell.bind(this);
        this.handleTouchTapCell = this.handleTouchTapCell.bind(this);
        this.handleTouchTapCheckbox = this.handleTouchTapCheckbox.bind(this);

        // Header render
        this.staticRangeRenderer=this.staticRangeRenderer.bind(this);
    }

    static get propTypes(){
        return {
            data: PropTypes.array,
            onScroll: PropTypes.func,
            columns: PropTypes.array,
            getColumnWidth: PropTypes.func.isRequired,
            onHoverRow: PropTypes.func,
            onTouchTapRow: PropTypes.func,
            onTouchTapCheckbox: PropTypes.func,
            scrollTop: PropTypes.any.isRequired,
            scrollLeft: PropTypes.any.isRequired,
            fixedBoxShadow: PropTypes.string,
            fixedColumnCount: PropTypes.number,
            theme: PropTypes.object,
            isSelected: PropTypes.func.isRequired,
            isHovered: PropTypes.func.isRequired,
            bodyCellContentRenderer: PropTypes.func,
            getRowHeight: PropTypes.func,
            detailsItem:PropTypes.any,
            isSelectedDetails: PropTypes.func,
            showRightSidebar:PropTypes.bool,
            modernBrowser:PropTypes.bool,
            filters:PropTypes.any,
            headerRender:PropTypes.func,
        };
    }
    static get defaultProps(){
        const nop = function(){};
        return {
            onHoverRow: nop,
            onTouchTapRow: nop,
            fixedColumnCount: 1,
            modernBrowser:false,
        };
    }

    UNSAFE_componentWillReceiveProps(np){
        const {columns,data} = np;
        this.setState({
            columnCount: columns.length,
            rowCount: data.length
        });
    }

    shouldComponentUpdate(np,ns){
        return (np !== this.props) || (ns !== this.state);
    }

    componentDidUpdate(pp){
        if(pp.data !== this.props.data&&this.props.modernBrowser!==true){
            this.FixedGrid.forceUpdate();
        }
    }

    // DATA PROVIDERS ----------------------------------------------------------

    forceGridUpdate(){
        if(this.props.modernBrowser!==true)
            this.FixedGrid.recomputeGridSize();
        this.Grid.recomputeGridSize();
        if(this.props.modernBrowser!==true)
            this.FixedGrid.forceUpdate();
        this.Grid.forceUpdate();
        this.setState({});
    }

    jumpToRow(i){
        if(this.props.modernBrowser!==true)
            this.FixedGrid.scrollToCell({rowIndex:i});
        this.Grid.scrollToCell({rowIndex:i});
    }

    getColumnWidths({index}){
        if(index < 1) return this.props.backgroundRow ? 8 : 75;
        else return this.props.getColumnWidth({index:index-1});
    }

    getWidthOfFirstXColumns(x){
        let s = 0,i;
        for(i = 0; i < x; i++){
            s += this.getColumnWidths({index:i});
        }
        return s;
    }

    _getDatum (index) {
        const { data } = this.props;
        return data[index % data.length];
    }
    getRowHeights({ index: number }){
        return this.state.rowHeight;
    }

    // REFERENCE ESTABLISHMENT -------------------------------------------------

    addFixedColumnRef(ref){
        if(ref){
            this.FixedGrid = ref.refs.fixed;
        }
    }

    addScrollableColumnRef(ref){
        if(ref){
            this.Grid = ref.refs.scrollable;
        }
    }

    // RENDERERS ---------------------------------------------------------------

    _noContentRenderer () {
        return (
            <div>                
            </div>
        );
    }

    _noContentBodyRenderer(){

        let w = 0, i, l = this.state.columnCount;

        for(i=0;i<l;i++) w += this.getColumnWidths({index:i});

        return (
            <div style={{width:w}}>
                No Results
            </div>
        );
    }

    _fixedCellRenderer({columnIndex,rowIndex,key,style}){
        if(columnIndex >= this.state.fixedColumnCount) return;
        return this._cellRenderer({columnIndex,rowIndex,key,style});
    }

    _scrollableCellRenderer({columnIndex,rowIndex,key,style}){
        if(columnIndex < this.state.fixedColumnCount) return;
        return this._cellRenderer({columnIndex,rowIndex,key,style});
    }

    _cellRenderer({columnIndex,rowIndex,key,style}){

        // Using position sticky.
        if (this.props.modernBrowser === true) {
            return this._renderBodyCell({columnIndex: columnIndex - 1, key, rowIndex: rowIndex, style});
        }
        // Old configuration.
        else{
            if (columnIndex === 0) {
                return this._checkboxCellRenderer({key, rowIndex, style});
            } else {
                return this._renderBodyCell({columnIndex: columnIndex - 1, key, rowIndex, style});
            }
        }
    }


    _checkboxCellRenderer({ key, rowIndex, style}){
        const datum = this._getDatum(rowIndex), prevRow = this._getDatum(rowIndex-1);
        const {theme,isSelected,isHovered,detailsItem,isSelectedDetails,showRightSidebar,fixedBoxShadow,backgroundRow} = this.props;

        let adjustedStyles = {lineHeight:style.height+'px'};

        const _hover = (isHovered(rowIndex,datum)),
              _selected = (isSelected(rowIndex,datum)),
              _underSelected = (rowIndex !== 0 && (isHovered(rowIndex-1,prevRow) || isSelected(rowIndex-1,prevRow))),
              _selectedDetails=(isSelectedDetails(detailsItem,datum,showRightSidebar));

        let th = theme && theme.table && theme.table.body;
        if(th){
            adjustedStyles.backgroundColor = ((_hover || _selected) && th.hover && th.hover.backgroundColor) || th.backgroundColor;
            adjustedStyles.borderBottomColor = ((_hover || _selected) && th.hover && th.hover.borderColor) || th.rowSeparatorColor;
            adjustedStyles.borderTopColor = (_hover || _selected) && !_underSelected && adjustedStyles.borderBottomColor;
            adjustedStyles.borderLeftColor = (_hover || _selected) && adjustedStyles.borderBottomColor;
        }

        style = assign({},style,adjustedStyles);

            let className = cn('checkbox-cell',{
                hover:_hover,
                selected:_selected,
                underSelected:_underSelected,
                selectedDetailsCheckBox:_selectedDetails,
            });

            // Style for shadow in checkbox when horizontal scroll happens.
            let shadowClass="";
            if(fixedBoxShadow!=null&&fixedBoxShadow!=="")
                shadowClass=" checkbox-cell-shadow";
            
            return (
                <div
                    key={key}
                    style={style}
                    data-row={rowIndex}
                    onMouseOver={this.handleMouseOverCell}
                    onClick={this.props.onTouchTapCheckbox ? this.handleTouchTapCheckbox : this.handleTouchTapCell}
                    className={className +shadowClass}>
                    {backgroundRow === undefined && <WrappedCheckbox checked={_selected} />}
                </div>
            );
    }

    _renderBodyCell ({ columnIndex, key, rowIndex, style }) {
        const datum = this._getDatum(rowIndex) , prevRow = this._getDatum(rowIndex-1);
        const {columns,theme,isSelected,isHovered,bodyCellContentRenderer,detailsItem,isSelectedDetails,showRightSidebar} = this.props;
        const coloredIndexex = [];
        columns.forEach((column, index) => {
            if (column.coloring) {
                coloredIndexex.push(index);
            }
        });

        let adjustedStyles = {lineHeight:style.height+'px'};

        const _hover = (isHovered(rowIndex,datum)),
              _selected = (isSelected(rowIndex,datum)),
              _last = (columnIndex === columns.length),
              _underSelected = (rowIndex !== 0 && (isHovered(rowIndex-1,prevRow) || isSelected(rowIndex-1,prevRow))),
              _selectedDetails=(isSelectedDetails(detailsItem,datum,showRightSidebar));

        let th = theme && theme.table && theme.table.body;
        if(th){
            adjustedStyles.backgroundColor = ((_hover || _selected) && th.hover && th.hover.backgroundColor) || th.backgroundColor;
            adjustedStyles.borderBottomColor = ((_hover || _selected) && th.hover && th.hover.borderColor) || th.rowSeparatorColor;
            adjustedStyles.borderTopColor = (_hover || _selected) && !_underSelected && adjustedStyles.borderBottomColor;
            adjustedStyles.borderRightColor = (_hover || _selected) && _last && adjustedStyles.borderBottomColor;
        }

        style = assign({},style,adjustedStyles);

        let className = cn('data-cell',{
            hover:_hover,
            selected:_selected,
            last:_last,
            underSelected:_underSelected,
            selectedDetails:_selectedDetails,
        });

        let content = null;
        if(columnIndex < columns.length){
            content = (bodyCellContentRenderer || this._renderBodyCellContent)({columnIndex,rowIndex,datum});
        }
        if(this.props.backgroundRow) {
            style.background =  this.props.backgroundRow[rowIndex] && (coloredIndexex.indexOf(columnIndex)!==-1) ? "rgba(255, 0, 0, 0.1)" : "";
        }
        return (
            <div key={key} style={style} data-row={rowIndex} onClick={this.handleTouchTapCell} onMouseOver={this.handleMouseOverCell} className={className}>
                {content}
            </div>
        );
    }

    _renderBodyCellContent({columnIndex,rowIndex}){
        const datum = this._getDatum(rowIndex);
        const {columns} = this.props;
        if(columnIndex<0||columnIndex >= columns.length ||!datum) return "";

        const {type,name} = columns[columnIndex];

        const d = datum[name];

        switch(type){
            case "DATETIME":
                if(!d) return "";
                return localeTimestamp(new Date(d)) || "";

            case "TEXT":
            default:
                return (d === undefined || d === null) ? "" : datum[name];
        }

    }

    // HANDLERS ----------------------------------------------------------------
    //

    handleMouseLeave(){
        this.props.onHoverRow(-1);
    }

    handleMouseOverCell(e){

        let elm = e.currentTarget;
        let row = parseInt(elm.dataset.row||"-1");

        this.props.onHoverRow(row,this._getDatum(row));

    }

    handleTouchTapCell(e){
        const {sites, maps, reportId} = this.props;
        let elm = e.currentTarget;
        let row = parseInt(elm.dataset.row||"-1");
        const datum = this._getDatum(row);
        const siteFilter = sites && sites.filter(s => s._id === datum.siteId);
        const mapFilter = maps && maps.filter(m => m._id === datum.mapId);
        this.props.onTouchTapRow(row,datum,reportId,siteFilter && siteFilter[0],mapFilter && mapFilter[0]);
    }

    handleTouchTapCheckbox(e){
        const {sites, maps, reportId} = this.props;
        let elm = e.currentTarget;
        let row = parseInt(elm.dataset.row||"-1");
        const datum = this._getDatum(row);
        const siteFilter = sites && sites.filter(s => s._id === datum.siteId);
        const mapFilter = maps && maps.filter(m => m._id === datum.mapId);
        this.props.onTouchTapCheckbox(row,datum,reportId,siteFilter && siteFilter[0],mapFilter && mapFilter[0]);
    }

    //region Header Render in modern browser

    /**
     * Renders static row and column using position sticky for modern browsers.
     * @param props
     * @returns {{staticRow: Array, staticColumn: Array, staticContentColumn: Array, staticRowCol: Array, staticContentRowCol: Array}}
     */
    staticRangeRenderer(props) {

        const {modernBrowser, headerRender, fixedBoxShadow, headerHeight, fixedColumnCount} = this.props;
        let staticChildren = {staticRow: [], staticColumn: [], staticContentColumn: [], staticRowCol: [], staticContentRowCol: []};
        if(modernBrowser) {
            const {columnStartIndex, columnStopIndex, rowStartIndex, rowStopIndex, rowSizeAndPositionManager, columnSizeAndPositionManager, horizontalOffsetAdjustment, verticalOffsetAdjustment} = props;

            let columnDatum = null;
            //region Select all
            if (columnStartIndex >= 0 && columnStopIndex >= 0) {

                let selectAll = null;
                columnDatum = columnSizeAndPositionManager.getSizeAndPositionOfCell(0);

                selectAll = headerRender({
                    columnIndex: 0,
                    rowIndex: 0,
                    key: '0-0',
                    style: {
                        height: headerHeight,
                        left: 0,
                        position: 'absolute',
                        top: 0,
                        width: columnDatum.size
                    },
                    fixedBoxShadow: fixedBoxShadow
                });
                if (this.props.backgroundRow) {
                    selectAll.props.style.border = 'solid';
                    selectAll.props.style.borderWidth = '4px';
                    selectAll.props.style.color = '#333d47';
                }
                staticChildren.staticRowCol.push(selectAll);
            }
            //endregion
            
            //Header second column and more columns
            if ((fixedColumnCount > 1) && (columnStartIndex >= 0 && columnStopIndex >= 0)) {
                for (let i = 1; i < fixedColumnCount; i++) {
                    let column = null;
                    columnDatum = columnSizeAndPositionManager.getSizeAndPositionOfCell(i);
                    column = headerRender({
                        columnIndex: i,
                        rowIndex: 0,
                        key: "0-" + i,
                        style: {
                            height: headerHeight,
                            left: columnDatum.offset + horizontalOffsetAdjustment,
                            position: 'absolute',
                            top: 0,
                            width: columnDatum.size,
                        }
                        ,fixedBoxShadow: fixedBoxShadow
                    });
                    staticChildren.staticContentRowCol.push(column);
                }                
            }
            //endregion

            //region First static row

            if (columnStartIndex >= 0 && columnStopIndex >= 0) {
                for (let i = columnStartIndex; i <= columnStopIndex; i++) {
                    if (i > 0) {
                        const columnDatum = columnSizeAndPositionManager.getSizeAndPositionOfCell(i);

                        let style = {
                            height: headerHeight + 'px',
                            left: columnDatum.offset + horizontalOffsetAdjustment,
                            position: 'absolute',
                            top: 0,
                            width: columnDatum.size
                        };

                        const key = "0-" + i;

                        staticChildren.staticRow.push(headerRender({
                            columnIndex: i,
                            rowIndex: 0,
                            key: key,
                            style: style
                        }));

                    }
                }
            }
            //endregion

            //region First static column
            
            if (columnDatum && rowStartIndex >= 0 && rowStopIndex >= 0) {
                for (let i = rowStartIndex; i <= rowStopIndex; i++) {
                    for (let j = 0; j < fixedColumnCount; j++) {
                        columnDatum = columnSizeAndPositionManager.getSizeAndPositionOfCell(j);
                        let rowDatum = rowSizeAndPositionManager.getSizeAndPositionOfCell(i);
                        let style = {
                            height: rowDatum.size,
                            left: columnDatum.offset + horizontalOffsetAdjustment,
                            position: 'absolute',
                            top: rowDatum.offset + verticalOffsetAdjustment,
                            width: columnDatum.size,
                            //fixedBoxShadow: fixedBoxShadow
                            boxShadow:(fixedColumnCount>1 && fixedBoxShadow)?'none':''
                        };

                        if (this.props.backgroundRow) {
                            style.display = 'none';
                        }

                        const key = i+"-"+j;
                    
                        if(j === 0){
                            staticChildren.staticColumn.push(this._checkboxCellRenderer({
                                columnIndex: j,
                                rowIndex: i,
                                key: key,
                                style: style
                            }));
                        }else{
                            staticChildren.staticColumn.push(this._cellRenderer({
                                columnIndex: j,
                                rowIndex: i,
                                key: key,
                                style: style
                            }));
                        }
                    
                    }

                }
            }
            
            //endregion

            //region Second  and more statics columns

            if ((fixedColumnCount > 1) && (columnDatum && rowStartIndex >= 0 && rowStopIndex >= 0)) {

                for (let i = rowStartIndex; i <= rowStopIndex; i++) {
                    for (let j = 0; j < fixedColumnCount; j++) {

                    let rowDatum = rowSizeAndPositionManager.getSizeAndPositionOfCell(i);
                    columnDatum = columnSizeAndPositionManager.getSizeAndPositionOfCell(j);

                    let style = {
                        height: rowDatum.size,
                        left: columnDatum.offset + horizontalOffsetAdjustment,
                        position: 'absolute',
                        top: rowDatum.offset + verticalOffsetAdjustment,
                        width: columnDatum.size,
                        boxShadow: fixedBoxShadow ? '1px 4px 10px 0px #0000003b' :'',
                    };

                    const key = i+"-"+j;
                    
                        if(j === 0){
                            staticChildren.staticContentColumn.push(this._checkboxCellRenderer({
                                columnIndex: 0,
                                rowIndex: i,
                                key: key,
                                style: style
                            }));
                        }else{
                            staticChildren.staticContentColumn.push(this._cellRenderer({
                                columnIndex: j,
                                rowIndex: i,
                                key: key,
                                style: style
                            }));
                        }
                    }
                }
            }
        }
        return staticChildren;
    }
    //endregion

    // RENDER ------------------------------------------------------------------


    render () {
        const {
            columnCount,
            overscanColumnCount,
            overscanRowCount,
            rowCount
        } = this.state;

        const {
            scrollTop,
            scrollLeft,
            onScroll,
            fixedBoxShadow,
            fixedColumnCount,
            getRowHeight,
            modernBrowser,
            headerHeight
        } = this.props;

        const _onScroll = (r) => {
            onScroll({
                ...r,
                scrollLeft:scrollLeft
            });
        };


        const fixedColWidth = this.getWidthOfFirstXColumns(fixedColumnCount);
        const fixedColStyles = {
            bottom:scrollbarSize()+"px",
            width:fixedColWidth,
            boxShadow:fixedBoxShadow,
        };

        const avgColumnWidth = this.getWidthOfFirstXColumns(columnCount) / columnCount;

        return (
            <div onMouseLeave={this.handleMouseLeave} className="filled-up table-body">
                {(modernBrowser===true)?"":<div style={fixedColStyles} className="fixed-columns-container">
                    <AutoSizer ref={this.addFixedColumnRef}>
                        {({height}) =>(
                            <Grid
                                ref={"fixed"}
                                cellRenderer={this._fixedCellRenderer}
                                columnWidth={this.getColumnWidths}
                                columnCount={fixedColumnCount}
                                height={height}
                                noContentRenderer={this._noContentRenderer}
                                overscanColumnCount={overscanColumnCount}
                                overscanRowCount={overscanRowCount}
                                rowHeight={getRowHeight || this.getRowHeights}
                                rowCount={rowCount}
                                width={fixedColWidth+scrollbarSize()}
                                scrollTop={scrollTop}
                                onScroll={_onScroll}
                                className="fixed-columns"
                            />
                        )}
                    </AutoSizer>
                </div>}
                <div className="filled-up">
                    <AutoSizer ref={this.addScrollableColumnRef}>
                        {({height, width}) => (

                                <CustomGridVirtualized
                                    ref={"scrollable"}
                                    cellRenderer={this._scrollableCellRenderer}
                                    columnWidth={this.getColumnWidths}
                                    columnCount={columnCount + 2}
                                    height={(modernBrowser === true) ?height-headerHeight:height}
                                    noContentRenderer={this._noContentBodyRenderer}
                                    overscanColumnCount={overscanColumnCount}
                                    overscanRowCount={overscanRowCount}
                                    rowHeight={getRowHeight || this.getRowHeights}
                                    rowCount={rowCount}
                                    width={width}
                                    onScroll={onScroll}
                                    //scrollTop={scrollTop}
                                    className="report-table-body"
                                    estimatedColumnSize={isNaN(avgColumnWidth) ? 150 : avgColumnWidth}
                                    modernBrowser={modernBrowser}
                                    cellStaticRenderer={this.staticRangeRenderer}
                                    scrollToAlignment={"auto"}
                                    heightStaticRow={(modernBrowser === true)?headerHeight:0}
                                />
                        )}

                    </AutoSizer>
                </div>
            </div>
        );
    }

}
