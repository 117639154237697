import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const StatusAliveIcon = (props) => {

    return (
        <SvgIcon {...props} >
            <path d="M2 12h5l2.98-7 2.12 14 2.88-7H22"/>
        </SvgIcon>
    );

};



export default StatusAliveIcon;
