import {
    CHANGE_DETAILS_ITEM,
    CHANGE_VIEW,
    CONNECT_WS,
    DISCONNECT_WS,
    HIGHLIGHT_ITEM_MAP,
    LOAD_COLUMN_ORDER,
    LOAD_PRINTER_DEVICES,
    LOAD_REPORT,
    LOAD_REPORT_CONTENT,
    LOAD_SAVED_REPORT,
    LOAD_SIDEBAR_COLUMN_ORDER,
    MAP_HOVER_FEATURE,
    MAP_SELECT_FEATURE,
    OPEN_RIGHT_SIDEBAR,
    REPORT_CHANGE_SORT,
    REPORT_CLICK_ON_ROW,
    REPORT_HOVER_OVER_ROW,
    REPORT_MOVE_COLUMN,
    REPORT_MOVE_FILTER,
    REPORT_SELECT_ALL,
    REPORT_SELECT_MANY,
    REPORT_TOGGLE_ALL_COLUMNS,
    REPORT_TOGGLE_ALL_FILTERS,
    REPORT_TOGGLE_COLUMN,
    REPORT_TOGGLE_FILTER,
    REQUEST_REPORT,
    REQUEST_REPORT_CONTENT,
    REQUEST_SAVED_REPORT,
    SIGN_OUT,
    SITE_SELECTED,
    STOP_FETCHING_DATA,
    TOGGLE_RIGHT_SIDEBAR,
    UPDATE_WEBSOCKET,
    WEBSOCKET_STATUS,
    WEBSOCKET_STOP_BLINK,
    SET_SESSION,
    TOOGLE_MAP_DRAWER,
} from '../constants/ActionTypes';

import {PLAYBACK_VIEW} from '../constants/ViewTypes';
import {TAG_HISTORY_REPORT} from "../constants/History";

const RESOURCE_REPORT = 'C0FB7CAD-8C25-11D5-9066-00105A6AE011';
const TAG_REPORT = '2D50E6C5-F1C7-4544-A91F-B1DA38045411';
//export const TAG_HISTORY_REPORT = '9756F150-B733-11D4-9045-00105A6AE011';
const RESOURCE_HISTORY_REPORT = 'B9AF8F31-453D-4847-B48C-99523CC36776';

const initialState = {
    reportId:"",
    realTimeIsDisabled: false,
    playBack: {
        RESOURCE_REPORT: false,
        TAG_REPORT: false
    },
    savedReportId:null,
    columns:[],
    columnMeta:{import:{importReport:{
        name:'report',
        type:'normal',
        displayName:'Report',
        caption:'Report',
        filter: 'importReport'
    },
    importAction:{
        name:'action',
        type:'normal',
        displayName:'Action',
        caption:'Action',
        filter: 'importAction'     
    },
    importImportedBy:{
        name:'importedBy',
        type:'normal',
        displayName:'Imported by',
        caption:'Imported by',
        filter:'importImportedBy'
    },
    importDate:{
        type: "datetime",
        name: "importDate",
        displayName:'Imported Date',
        caption:'Imported Date',
        filter:'importFromDate'     
    }}},

    columnOrder:{}, // persisted, actually.

    // This two fields are used for sidebar filters
    // to have an independent behavior in terms of
    // order or filters being displayed.
    sidebarColumnMeta: {import:{importReport:{
        name:'report',
        type:'normal',
        displayName:'Report',
        caption:'Report',
        filter: 'importReport'
    },
    importAction:{
        name:'action',
        type:'normal',
        displayName:'Action',
        caption:'Action',
        filter: 'importAction'     
    },
    importImportedBy:{
        name:'importedBy',
        type:'normal',
        displayName:'Imported by',
        caption:'Imported by',
        filter:'importImportedBy'
    },
    importDate:{
        type: "datetime",
        name: "importDate",
        displayName:'Imported Date',
        caption:'Imported Date',
        filter:'importFromDate'     
    }}},
    sidebarColumnOrder: {
        import:[
            {name: "importReport", enabled: true, sort:0},
            {name: "importAction", enabled: true, sort:0},
            {name: "importImportedBy", enabled: true, sort:0},
            {name: "importDate", enabled: true, sort:0},           
        ]
    },

    filters:{},

    needsRefresh: false,
    fetching: false,

    sortColumn:"",
    sortDirection:-1,

    data:[],
    meta:{},
    hoverIndex:-1,
    hoverUIDs: [],
    dataEntryForms:[],
    clickedItem: undefined,
    selected:{}, // indexed by |-delimited key field values.
    keyFields:[],
    showRightSidebar:false,
    detailsItem:null,
    highlightedItemMap:null,
    siteSelected: '%',
    printerDevices: [],
    isSessionActive: true,
    showMapDrawer: true
};

function getLookupKey(datum,fields){
    return fields.map(f=>datum[f]).join('|');
}


function updateColumnOrder(oldOrder,columns){
    var colNames = {};
    columns.forEach(col=>{colNames[col.name] = true;});

    var newOrder = oldOrder.map(col=>{
        if(colNames[col.name]){
            delete colNames[col.name];
            return col; // no change.
        }
        return null; // not there (column removed from definition)
    }).filter(v=>!!v);

    // no columns to add.  No columns removed.
    if(Object.keys(colNames)===0 && oldOrder.length === newOrder.length){
        return oldOrder;
    }

    columns.forEach(col=>{
        if(colNames[col.name]){
            newOrder.push({
                name: col.name,
                enabled: true,
                sort: 0
            });
        }
    });
    return newOrder;
}


function toggleColumn(col,enabled){
    return {...col,enabled};
}

function specificColumnOrder(state,action){
    let cols;
    switch(action.type){

        case REPORT_TOGGLE_COLUMN: // action = {index,enabled}
        case REPORT_TOGGLE_FILTER:
            cols = [].concat(state);
            cols.splice(action.index,1,toggleColumn(cols[action.index],action.enabled));
            return cols;

        case REPORT_MOVE_COLUMN: // action = {dragIndex,hoverIndex}
        case REPORT_MOVE_FILTER:
            cols = [].concat(state);
            cols.splice(action.dragIndex,1);
            cols.splice(action.hoverIndex,0,state[action.dragIndex]);
            return cols;

        case REPORT_TOGGLE_ALL_COLUMNS: // action = {enabled}
        case REPORT_TOGGLE_ALL_FILTERS:
            cols = state.map((col)=>toggleColumn(col,action.enabled));
            return cols;
        default:
            return state;
    }
}

function columnOrder(state,action){

    switch(action.type){

        // if upon loading the report, there is no column order
        // already there, add it.  If it changes, we assume that
        // the count of columns changes as well (so we can refresh)
        case LOAD_REPORT:
            return {
                ...state,
                [action.reportId] : updateColumnOrder(
                    (state && state[action.reportId]) || [], // current column order in state for the report
                    action.reportDefinition.columns        // the column order as represented in the report definition.
                )
            };

        case LOAD_SAVED_REPORT:
            return {
                ...state,
                [action.reportId] : updateColumnOrder(
                    action.report.columnOrder || [],
                    (state && state[action.report.baseReportId]) || []
                )
            };


        case REPORT_TOGGLE_COLUMN: // action = {index,enabled}
        case REPORT_MOVE_COLUMN: // action = {dragIndex,hoverIndex}
        case REPORT_TOGGLE_ALL_COLUMNS: // action = {enabled}
            return {
                ...state,
                [action.reportId] : specificColumnOrder(state[action.reportId], action)
            };

        case REPORT_TOGGLE_FILTER:
        case REPORT_MOVE_FILTER:
        case REPORT_TOGGLE_ALL_FILTERS:
            return {
                ...state,
                [action.reportId]: specificColumnOrder(state[action.reportId], action)
            }
        default:
            return state;
    }
}

function filterOrder(filters, action) {

    switch (action.type) {
        case LOAD_SAVED_REPORT:
            return {
                ...filters,
                [action.reportId]: action.report.filterOrder,
            };
        default:
            return filters;
    }
}


function columnMeta(state,action){

    let filterNameMap = arrayToObject(action.reportDefinition.filters,"name");


    var cols = {};
    action.reportDefinition.columns.forEach(col=>{
        cols[col.name] = {
            ...col,
            caption: col.displayName,
            filter: getAssociatedFilter(filterNameMap,col),
        };

    });

    return {
        ...state,
        [action.reportId]:cols
    };
}

// function selectMultipleData(stateSelected, actionSelected){
//     let selected = {...stateSelected,...actionSelected};
//     let newSelected ={};
//     //rewriting in property selected, only the records with ___selected === true
//     Object.keys(selected).forEach(key => {
//         if(selected[key].___selected === true){
//             newSelected[key] = selected[key];
//         }
//     });
//
//     return newSelected;
// }

function toggleSelectDatum(state,action,selectedTag){
    if (!action.datum) {
        return null;
    }

    // Get key for 'selected' rows.
    let k = getLookupKey(action.datum,state.keyFields);
    const clickedItem = action.datum;

    // tweak selected rows.
    let selected = {...state.selected};
    if(selected[k]){
         delete selected[k];
    }
    else {
        selected[k] = action.datum;
    }

    // replace the row in the data.
    let i,d;

    i = action.datum.___index;
    d = [].concat(state.data);
    d.splice(i,1,{
        ...state.data[i],
        ___selected: !!selected[k]
    });


    let rightSidebar=rightSidebarParams(state,clickedItem,selected);


    // return the changeset.
    return {
        data:d,
        clickedItem,
        selected:selected,
        ...rightSidebar,
        selectedTag: selectedTag,
    };
}

function rightSidebarParams(state,selectedObject,allSelectedObjects){

    // Find the item to show details in right sidebar.
    let detailsItem=null;
    if(state.showRightSidebar&&allSelectedObjects!=null)
        detailsItem=findDetailsItem(selectedObject,allSelectedObjects,state.detailsItem);

    // Close permanently details sidebar if no items is selected.
    let showRightSidebar=state.showRightSidebar;
    if(allSelectedObjects===null||Object.keys(allSelectedObjects).length===0)
        showRightSidebar=false;

    return {detailsItem,showRightSidebar};
}

// Find the item to show details in right sidebar.
function findDetailsItem(clickedItem,selected,detailsItem){

    let newDetailsItem = null;

    let uid = null;
    let index = null;

    // After selecting one or more items.
    if (clickedItem != null && selected != null) {
        uid = clickedItem['___uid'];
        index = clickedItem['___index'];

        // Item is selected.
        if (selected.hasOwnProperty(uid))
            newDetailsItem = uid;
        // Item is unselected.
        else {
            // Only if unselecting the current detailsItem.
            if (detailsItem === null || detailsItem === uid) {
                const uidArray = Object.keys(selected);

                let next = null;
                let prev = null;
                let prevItemUid = null;
                let nextItemUid = null;
                uidArray.forEach((key, i) => {
                    const item = selected[key];
                    const itemIndex = item['___index'];

                    if (itemIndex < index && (prev == null || (index - itemIndex) < (index - prev))) {
                        prev = itemIndex;
                        prevItemUid = item['___uid'];
                    }
                    if (itemIndex > index && (next == null || (index - itemIndex) > (index - next))) {
                        next = itemIndex;
                        nextItemUid = item['___uid'];
                    }

                });

                if (nextItemUid != null)
                    newDetailsItem = nextItemUid;
                else if (prevItemUid != null)
                    newDetailsItem = prevItemUid;

            }
            else
                newDetailsItem = findFirstItem(selected);
        }
    }
    else if (selected != null && Object.keys(selected).length > 0)
        newDetailsItem = findFirstItem(selected);

    return newDetailsItem;
}

/**
 * Find the first item in a object collection, this is the minor value of "___index".
 * @param selected
 * @returns {*}
 */
function findFirstItem(selected){
    let firstItem = null;
    let minIndex = null;
    Object.keys(selected).forEach((key, index) => {
        if (minIndex === null || selected[key]['___index'] < minIndex) {
            minIndex = selected[key]['___index'];
            firstItem = key;
        }
    });
    return firstItem;
}

/**
 * Return the config of left details sidebar.
 * @param showRightSidebar
 * @param detailsItem
 * @param selected
 * @returns {{showRightSidebar: *, detailsItem: *}}
 */
function detailsSidebarConfig(showRightSidebar,detailsItem,selected){

    let config={showRightSidebar:showRightSidebar,detailsItem:detailsItem};

    if(showRightSidebar)
    {
        if(selected!=null&&Object.keys(selected).length>0) {
            if (selected.hasOwnProperty(detailsItem))
                config.detailsItem = detailsItem;
            else if (!selected.hasOwnProperty(detailsItem))
                config.detailsItem = findFirstItem(selected);
        }
        else{
            //config.showRightSidebar=false;
            config.detailsItem=null;
        }
    }

    return config;
}

function arrayToObject(arr,key){
    if(!arr.length) return {};
    return arr.reduce(function(obj,item){
        obj[ item[ key ] ] = item;
        return obj;
    },{});
}

function getAssociatedFilter(filters,col){

    // Determine which filters are associated with this column.
    var r = new RegExp("^(min|max|from|to)?"+col.name+"$","i");
    var assocFilters = Object.keys(filters).reduce((applicableFilters,f)=>{
        if(r.test(f)){
            applicableFilters.push(filters[f]);
        }
        return applicableFilters;
    },[]);

    if(assocFilters.length === 0) return null; // no filters.

    return assocFilters[0].id;
}

function fixSelected(selected,content,keyFields){
    let keys = content.map(d=>getLookupKey(d,keyFields));
    let oldSelected = selected;
    selected = {};
    let i, k, l = keys.length;
    for(i=0;i<l;i++){
        k = keys[i];
        if(oldSelected[k]) selected[k] = content[i];
    }
    return selected;
}

export function report(state = initialState, action){
    switch(action.type){
        case SIGN_OUT:
            return initialState;  // effective reset.
        case REQUEST_REPORT:
            return {
                ...state,
                reportId: action.reportId,
                realTimeIsDisabled: action.realTimeIsDisabled,
                savedReportId: null,
                fetching:true,
                sortColumn:"", // reset sort.
                showRightSidebar:false, // Close details sidebar.
                detailsItem:null, // Reset details sidebar.
            };

        case REQUEST_SAVED_REPORT:
            return {
                ...state,
                reportId: action.report.baseReportId,
                savedReportId: action.report.id,
                fetching:true
            };

        case REQUEST_REPORT_CONTENT:
            return {
                ...state,
                fetching: !action.silent
            };

        case STOP_FETCHING_DATA:
            return {
                ...state,
                fetching:false
            };

        case LOAD_REPORT:
            return {
                ...state,
                columnMeta: columnMeta(state.columnMeta, {...action,reportId:state.reportId}),
                columnOrder: columnOrder(state.columnOrder, {...action,reportId:state.reportId}),
                sidebarColumnMeta: columnMeta(state.sidebarColumnMeta, {...action,reportId:state.reportId}),
                sidebarColumnOrder: columnOrder(state.sidebarColumnOrder, {...action,reportId:state.reportId}),
                data: [],
                meta:{
                    ...action.reportDefinition,
                    filters: action.reportDefinition.filters.map(filter=>filter.id)
                },
                dataEntryForms:action.reportDefinition.dataEntryActions,
                keyFields: action.reportDefinition.keys,
                selected:{},
                filters:{
                    ...state.filters
                },
                showRightSidebar:false, // Reset details sidebar.
                detailsItem:null, // Reset details sidebar.

            };

        case LOAD_REPORT_CONTENT:
            const data=action.reportContent.map((d,i)=>({
                ...d,
                ___index: i,
                ___uid: getLookupKey(d,state.keyFields), // just to determine row position.
                ___selected:!!state.selected[getLookupKey(d,state.keyFields)]
            }));

            const selected=fixSelected(state.selected,data,state.keyFields);

            // Find the config for details sidebar.
            let showRightSidebarConfig=detailsSidebarConfig(state.showRightSidebar,state.detailsItem,selected);

            var o = {
                ...state,
                data: data,
                selected:selected,
                fetching: false,
                showRightSidebar:showRightSidebarConfig.showRightSidebar, // Close details sidebar.
                detailsItem:showRightSidebarConfig.detailsItem, // Reset details sidebar.
                clickedItem:null,
            };
            return o;

        case LOAD_SAVED_REPORT:
            return {
                ...state,
                columnOrder: columnOrder(state.columnOrder,{...action,reportId:action.report.id}),
                sortColumn: action.report.sortColumn,
                sortDirection: action.report.sortDirection,
                sidebarColumnOrder: filterOrder(state.sidebarColumnOrder, {...action,reportId:action.report.id}),
            };

        case REPORT_MOVE_COLUMN:
        case REPORT_TOGGLE_COLUMN:
        case REPORT_TOGGLE_ALL_COLUMNS:
            return {
                ...state,
                columnOrder:columnOrder(
                    state.columnOrder,
                    {
                        ...action,
                        reportId: state.savedReportId || state.reportId
                    }
                )
            };
        case REPORT_MOVE_FILTER:
        case REPORT_TOGGLE_FILTER:
        case REPORT_TOGGLE_ALL_FILTERS:
            return {
                ...state,
                sidebarColumnOrder: columnOrder(
                    state.sidebarColumnOrder,
                    {
                        ...action,
                        reportId: state.savedReportId || state.reportId
                    }
                )
            };

        case LOAD_COLUMN_ORDER:
            return {
                ...state,
                columnOrder: action.columnOrder
            };

        case LOAD_SIDEBAR_COLUMN_ORDER:
            return {
                ...state,
                sidebarColumnOrder: {...initialState.sidebarColumnOrder, ...action.sidebarColumnOrder} 
            }

        case REPORT_HOVER_OVER_ROW:
        case MAP_HOVER_FEATURE:
            if(state.hoverUIDs.length === 0 && action.datum.length === 0) return state; // might not be right place for check.

            return {
                ...state,
                hoverIndex: action.index,
                hoverUIDs: action.datum.map(d=>d && d.___uid),
                selectedTag: false,
            };

        case REPORT_CLICK_ON_ROW:
            return {
                ...state,
                ...toggleSelectDatum(state,action,true)
            };
        case MAP_SELECT_FEATURE:
            return {
                ...state,
                ...toggleSelectDatum(state,action,false)
            };

        case REPORT_SELECT_ALL:

            // If all items are unselected close the details sidebar.
            const showRightSidebar=(state.showRightSidebar&&action.selected)?true:false;

            return {
                ...state,
                data:state.data.map((v)=>({
                    ...v,
                    ___selected: action.selected
                })),
                selected: (action.selected) ? (()=>{
                    var obj = {};
                    state.data.forEach(d=>{
                        obj[getLookupKey(d,state.keyFields)] = d;
                    });
                    return obj;
                })() : {},
                showRightSidebar:showRightSidebar,
            };
        case REPORT_SELECT_MANY:
            const rightSidebar=rightSidebarParams(state,action.lastObjectSelected,action.selected);
            return {
                ...state,
                data:action.data,
                ...rightSidebar,
                selected:action.selected,

            };
        case REPORT_CHANGE_SORT:
            return {
                ...state,
                sortColumn: action.sortColumn,
                sortDirection: action.direction
            };
        case TOGGLE_RIGHT_SIDEBAR:
            const toggle=!state.showRightSidebar;
            let newDetailsItem=null;
            if(toggle)
                newDetailsItem=findDetailsItem(state.clickedItem,state.selected,state.detailsItem);
            return {
                ...state,
                showRightSidebar: toggle,
                detailsItem:newDetailsItem
            };
        case OPEN_RIGHT_SIDEBAR:
            return {
                ...state,
                showRightSidebar: true,
                detailsItem:action.itemId,
                highlightedItemMap:action.highlightedItemMap,
            };
        case HIGHLIGHT_ITEM_MAP:
            return {
                ...state,
                highlightedItemMap:action.itemId
            }
        case CHANGE_DETAILS_ITEM:
            let newItemSelected=null;
            // Only right sidebar is visible.
            if(state.showRightSidebar)
                newItemSelected=action.newItemSelected;
            return{
                ...state,
                detailsItem:newItemSelected
            }
        case CHANGE_VIEW:
            return {
                ...state,
                playBack: {
                    ...(state.playBack),
                    [state.reportId]: action.view === PLAYBACK_VIEW
                }
            }
        case CONNECT_WS:
            return {
                ...state,
                realTime: {
                    ...(state.realTime),
                    [state.reportId]: true,
                },
                connectedRealTime: {
                    ...(state.connectedRealTime),
                    [state.reportId]: true
                }
            };
        case DISCONNECT_WS:
            return {
                ...state,
                realTime: {
                    ...(state.realTime),
                    [state.reportId]: false,
                },
                connectedRealTime: {
                    ...(state.connectedRealTime),
                    [state.reportId]: false
                }
            };
        case WEBSOCKET_STATUS:
            return {
                ...state,
                connectedRealTime: {
                    ...(state.connectedRealTime),
                    [state.reportId]: action.status
                }
            };
        case WEBSOCKET_STOP_BLINK:
            return {
                ...state,
                data: state.data.map(tagResource => {
                    if(tagResource.recent) {
                        tagResource.recent = false;
                        tagResource.state=null;
                    }
                    return tagResource;
                }),
                trackedBlink: false
            };

        case UPDATE_WEBSOCKET:
            const { playBack, realTime } = state;
            if(playBack && realTime && !playBack[state.reportId] && realTime[state.reportId]) {
                let newData;
                let property;
                let trackedBlink = false;
                if(state.reportId === RESOURCE_REPORT || state.reportId === RESOURCE_HISTORY_REPORT) {
                    newData = action.data.resourceMap;
                    property = 'objectId';
                } else if(state.reportId === TAG_REPORT || state.reportId === TAG_HISTORY_REPORT) {
                    newData = action.data.tagMap;
                    property = 'tagId';
                }
                let dataToSend =  state.data.map(tagResource => {
                    if(tagResource && tagResource[property] && newData[tagResource[property].toLowerCase()]) {
                        Object.assign(tagResource, newData[tagResource[property].toLowerCase()]);
                        tagResource.recent = true;
                        trackedBlink = true;
                        return tagResource;
                    } else {
                        if(tagResource.recent) {
                            tagResource.recent = false;
                        }
                        return tagResource;
                    }
                });

                return {
                    ...state,
                    data:dataToSend,
                    trackedBlink: trackedBlink
                };
            } else {
                return state;
            }
        case SITE_SELECTED:
            return {
                ...state,
                siteSelected: action.siteName
            };

        case LOAD_PRINTER_DEVICES:
            return {
                ...state,
                printerDevices: action.data
            };
        case SET_SESSION:
            return {
                ...state,
                isSessionActive: action.isSessionActive
            };
        case TOOGLE_MAP_DRAWER:
            return {
                ...state,
                showMapDrawer: action.showMapDrawer
            };
        default:
            return state;
    }
}
