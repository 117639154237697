import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import DatetimeFilter from "./filters/DatetimeFilterDialog"
import ProgressStatus from '../ProgressStatus';
import Dialog from '@material-ui/core/Dialog';
import {Button, DialogActions, DialogTitle} from '@material-ui/core';
import {DialogContent} from '@material-ui/core/es';
import FontIcon from '@material-ui/core/Icon';
import {AutoSizer} from "react-virtualized";
import {FixedSizeList as List} from 'react-window';
import dateFormat from "dateformat";
import {getDeviceStatusHistory, changeRangeFilterStatusHistory, changeDateFilterStatusHistory, changeDateValueStatusHistory} from "../../../../actions";
const STATUS_HISTORY_TITLE = 'Status History';



const DialogListStatus = ({open, setOpenHistory, setOpenStatusDialog, setStatusToShow, deviceId}) => {

    const statusHistory = useSelector(state => state.deviceManager.statusHistory);
    const filtersHistory = useSelector(state => state.deviceManager.filtersHistory);
    const fetchingHistory = useSelector(state => state.deviceManager.fetchingHistory);

    const dispatch = useDispatch();

    const handleLoadStatusHistory = () => {
        dispatch(getDeviceStatusHistory(deviceId, null, null, 1461,1462));
    };

    useEffect(handleLoadStatusHistory, []);

    const handleDownload = (event) => {
        if(!statusHistory && statusHistory.length === 0){
            event.stopPropagation();
            return null;
        }
        let stamp = new Date();
        stamp = dateFormat(stamp, "mm_dd_yy_HH_MM_s_Z");
        let element = document.createElement("a");
        let content = JSON.stringify(statusHistory,null,'    ')
        let file = new Blob([content], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = "status_history_"+stamp+".json";
        element.click();
    };

    const handleClose = () => {
        setOpenHistory(false);
    };

    const handleOpenStatusDialog = (e, statusToShow) => {
        e.preventDefault();
        setStatusToShow(statusToShow);
        setOpenStatusDialog(true)
    };

    const handleChangeFilterValue = (id,value) => {
        dispatch(changeDateValueStatusHistory(id, value));
    };

    const handleChangeFilterFromToValue = (fromId, fromValue, toId, toValue) => {
        dispatch(changeDateFilterStatusHistory(fromId, fromValue, toId, toValue));
        dispatch(getDeviceStatusHistory(deviceId, fromValue, toValue, fromId, toId));
    };

    const handleChangeSpecialFilterValue = (filterKey,filterValue, from, to) => {

        dispatch(changeRangeFilterStatusHistory(filterKey, filterValue, from, to));
    };

    const handleChangeDateAndData = (deviceId, newDateTimeFrom, newDateTimeTo, fromId,toId) => {
        dispatch(changeDateFilterStatusHistory(fromId, newDateTimeFrom,toId, newDateTimeTo, deviceId));
        dispatch(getDeviceStatusHistory(deviceId, newDateTimeFrom, newDateTimeTo, fromId,toId));
    };

    const Row = ({index, style}) => {
        const timeData = statusHistory && new Date(statusHistory[index].time);
        const date = dateFormat(timeData, 'mm-dd-yyyy HH:MM:ss');
        return (
            <div key={statusHistory[index]._id + index} style={style}>
                <div style={{
                    border: '1px solid #aaa',
                    borderCollapse: 'collapse',
                    padding: '0.5em',
                    paddingBottom: '0.2em',
                    paddingLeft: '2.5em',
                    position: 'relative'
                }}>
                    <span style={{fontSize: '1.1em', fontWeight: 'bold'}}>
                        Date: <span style={{fontWeight: 'normal'}}>{date}</span>
                    </span>
                    <span style={{
                        fontSize: '0.9em',
                        paddingLeft: '1em',
                        float: 'right'
                    }}>
                        <a className="dd-status-history-link" href="/#"
                           onClick={(e) => handleOpenStatusDialog(e, statusHistory[index])}>View Status</a>
                    </span>
                </div>
            </div>
        )
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle style={{paddingRight: 50}}>
                <div style={{fontWeight:'300',fontSize:'0.9em'}}>
                    {STATUS_HISTORY_TITLE}
                </div>
            </DialogTitle>
            <DialogContent style={{maxHeight: '415px', overflow:'hidden'}}>
                <div style={{width: 552,display:'inline-block',textAlign:'left', height: 'auto'}}> {/*// height: 480 */}
                    <div style={{marginBottom:'15px'}} key={1}>
                        {/*<span style={{fontSize:'1.1em',fontWeight:'bold'}}>Select Date: </span>*/}
                        <div >
                            <DatetimeFilter
                                name={'timestamp'}
                                actions={{
                                    changeFilterValue: handleChangeFilterValue,
                                    changeFilterFromToValue: handleChangeFilterFromToValue,
                                    changeSpecialFilterValue: handleChangeSpecialFilterValue
                                }}
                                filters={filtersHistory}
                                reportId={"statusHistory"}
                                nextPrevButtons={true}
                                deviceId={deviceId}
                                onChangeDateAndData={handleChangeDateAndData}
                            />
                        </div>
                    </div>

                    <div style={{width: '552px', height:'361px', overflowY: 'auto'}}>
                        <AutoSizer>
                        {
                            ({height, width}) => {
                                return fetchingHistory ? <ProgressStatus/> :
                                    statusHistory && statusHistory.length > 0 ?(
                                        <List
                                            height={height}
                                            width={width}
                                            itemCount={statusHistory.length}
                                            itemSize={35}
                                        >
                                            {Row}
                                        </List>
                                    ):
                                        <div className="history-status-progress"
                                             style={{color: '#777777', fontStyle: 'italic'}}>
                                            There is no history status for this date.
                                        </div>
                            }
                        }
                        </AutoSizer>
                    </div>
                </div>

            </DialogContent>
            <DialogActions>
                {/*<span>Total Records: {statusHistory && statusHistory.length}</span>*/}
                <Button style={{background: '#fff', color: '#007cb0', padding: '0.5em', textTransform: 'none'}} onClick={handleDownload} component="span">
                    <FontIcon className="material-icons">
                        get_app
                    </FontIcon>
                    Download History
                </Button>
                <Button style={{background: '#fff', color: '#007CB0', padding: '0.5em', textTransform: 'none'}} onClick={handleClose} component="span">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default DialogListStatus;