import React from 'react';
import PropTypes from 'prop-types';
import ZPage from './../../../../common/app/views/ZPage';
import Paper from "@material-ui/core/Paper";
import Tabs from '@material-ui/core/Tabs';

import UserGroupsView from './views/UserGroupsView';
import UsersView from './views/UsersView';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../actions';
import {generateComponentId} from "../../utils/ComponentUtils";


// const tabStyles = {
//     marginLeft:'1em',
//     position:'relative',
//     padding:'0.25em 1.5em 7px 1.5em',
//     float:'left',
//     cursor:'pointer',
//     color:'#777',
//     fontWeight:'500',
//     letterSpacing:'0.5px'
// };

// const inactiveTabStyles = {
//     ...tabStyles,
//     backgroundColor:'#f7f7f7',
//     color:'#aaa'
// };

const overflowStyles = {
    width:'100%',
    position:'absolute',
    bottom:'-8px',
    height:'10px',
    background:'white',
    left:0
};

const shadowStyle = {
    width:'100%',
    position:'absolute',
    bottom:0,
    boxShadow:'rgba(0,0,0,0.117647) 0px 1px 6px, rgba(0,0,0,0.117647) 0px 1px 4px',
    borderTop:'solid 1px rgba(0,0,0,0.2)',
    left:0
};

class Tab extends React.Component{
    static get propTypes(){
        return {
            active: PropTypes.bool,
            title: PropTypes.string,
            subtitle: PropTypes.string
        };
    }

    render(){
        const {
            active,
            title,
            subtitle,
            ...other
        } = this.props;

        other.className += (active ? ' active' : '');
        const divProps = Object.assign({}, other);
        delete divProps.fullWidth;
        delete divProps.indicator;
        delete divProps.textColor;

        return (
            <Paper onClick={this.handleGoToTab} {...divProps} >
                <div>
                    <div className="title">{title}</div>
                    <div className="subtitle">{subtitle}</div>
                </div>
                <div style={overflowStyles} />
                {!active && (<div style={shadowStyle} />)}
            </Paper>
        );
    }
}


/**
 * Users Page
 */
class UsersPage extends React.Component{
    /**
     *
     * constructor
     * @param {object} props
     */
    constructor(props,context){
        super(props,context);
        this.handleGoToTab = this.handleGoToTab.bind(this);
    }

    handleGoToTab(e){
        const page = parseInt(e.currentTarget.dataset.page);
        this.props.actions.clickUsersTab(page);
    }

    /**
     *
     * @returns {ReactElement} markup
     */
    render (){

        const {page,isLocal} = this.props;
        const userGroupTitle = "USER GROUPS";
        const userTitle = "USERS";

        let tabs = [
            (
                <Tab
                    id={generateComponentId(userGroupTitle,null,"button")}
                    key="tab-one"
                    data-page={0}
                    onClick={this.handleGoToTab}
                    active={page === 0}
                    title={userGroupTitle}
                    subtitle={"and permissions"}
                    className={"tab tab-user-groups"}
                />
            )];

        if(isLocal){
            tabs.push((
                    <Tab
                        id={generateComponentId(userTitle,null,"button")}
                        key="tab-two"
                        data-page={1}
                        onClick={this.handleGoToTab}
                        active={page === 1}
                        title={userTitle}
                        subtitle={"and associated Groups"}
                        className={"tab tab-users"}
                    />
                ));
        }

        const my_view = (page === 0 || !isLocal) ? (
            <UserGroupsView {...this.props} />
        ) : (
            <UsersView {...this.props} />
        );


        return (
            <ZPage zBreadCrumbs={["Home"]}
                zSubHeaderHeight={100}
                zShowSubHeader={false} >
                <div>
                    <div className={"users-page"}>
                        <div className="tabs">
                            <Tabs value={page} TabIndicatorProps={{style: {background:'#5296b2'}}}>
                                {tabs}
                            </Tabs>
                        </div>
                        <div className={"main-view-container"}>
                            <Paper className={"content-view-container"}>
                                {my_view}
                            </Paper>
                        </div>
                    </div>
                </div>
            </ZPage>

        );
    }

    static get propTypes(){
        return {
            actions: PropTypes.object,
            view: PropTypes.string,
            dataEntryOpen: PropTypes.bool,
            data: PropTypes.array,
            columns: PropTypes.array,
            reportHoverIndex: PropTypes.number,
            reportHoverUID: PropTypes.number,
            page: PropTypes.number,
            isLocal: PropTypes.bool,
            isMobile: PropTypes.bool,
        };
    }

    static get defaultProps(){
        return {
            dataEntryOpen: false
        };
    }

}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions,dispatch)
});

const mapStateToProps = state =>({
    dataEntryOpen: state.dataEntry.open,
    columns: state.userMgmt.columns,
    data: state.userMgmt.data,
    page: state.userMgmt.tab,
    isLocal: state.user.isLocal,
    isMobile: state.resize.isMobile,
});

export default connect(mapStateToProps,mapDispatchToProps)(UsersPage);
