import React, {useState} from 'react'
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import ClickAwayListener from "@material-ui/core/es/ClickAwayListener/ClickAwayListener";
import { Popover, MenuItem, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import BackArrow from '@material-ui/icons/NavigateBefore';
import Place from '@material-ui/icons/Place';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import InfoIcon from '@material-ui/icons/Info';
import Cancel from '@material-ui/icons/Cancel';
import CachedIcon from '@material-ui/icons/Cached';
import AddBoxIcon from '@material-ui/icons/AddBox';
import BatchIcon from '@material-ui/icons/GetApp';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import ForwardIcon from '@material-ui/icons/ArrowForwardIos';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ListIcon from '@material-ui/icons/ListAlt';
import {
    changeView,
    changeStateShowMaps,
    cleanDevicesBatch,
    loadDeviceList,
    requestSnapshotInfoBySite,
    detectSaveChanges,
    changePage
} from '../../../actions';
import {Link} from "react-router-dom";
import MapViewIcon from "../../icons/MapViewIcon";
import ClassedIconButton from "../../home/toolbars/ClassedIconButton";
import IconButton from '@material-ui/core/IconButton';
import {
    MAP_REPORT_HORIZONTAL_REVERSE_SPLIT,
    MAP_REPORT_HORIZONTAL_SPLIT,
    MAP_REPORT_VERTICAL_SPLIT, REPORT_VIEW, MAP_VIEW
} from "../../../constants/ViewTypes";
import RemoveRedEye from "@material-ui/icons/RemoveRedEye";
import MapIcon from "../../icons/ViewsIcon";
import {
    PATH_ADD_DEVICES_MANUALLY,
    PATH_ADD_BATCH_DEVICES,
    PATH_EDIT_DEVICE,
    PATH_TEMPLATES,
    EDIT_DEVICES_SCREEN,
    PATH_ADD_SYNC_VIA_ZEBRA_DATA_SERVICE
} from '../../../constants/DeviceManager';
import ReactToolTip from 'react-tooltip';
import ColumnsDropDown from './ColumnsDropDown';
import ViewConfigDialog from '../templates/ViewConfigDialog';
import DialogConfirm from '../common/dialogs/DialogConfirm';


const renderFilterName = (selectedMap, countTotalDevices) => {
    if(!selectedMap || selectedMap.allMaps){
        const totalDevices = countTotalDevices ? `(${countTotalDevices})` : '';
        return 'All Maps ' + totalDevices;
    }
    if(!selectedMap.name){
        return `${selectedMap.siteName} (${selectedMap.countDevices})`
    }else{
        return `${selectedMap.siteName} - ${selectedMap.name} (${selectedMap.countDevices})`
    }
}

const Breadcrumbs = () => {
    return (
        <div className={"tb-appliances-breadcrumbs-container"}>
            <>
                <span>Infrastructure</span>
                <span> / </span>
                <span>Devices</span>
            </>
        </div>
    );
};

const MainToolbar = (props) => {

    const MENU_ADD='MENU_ADD';
    const MENU_TABLE_MAP='MENU_TABLE_MAP';

    // const mapSelected = useSelector(state => state.deviceManager.mapSelected);
    const siteId = useSelector(state => state.deviceManager.siteId);
    const showMaps = useSelector(state => state.deviceManager.showMaps);
    const columns = useSelector(state => state.deviceManager.columnOrder.devices);
    const countDevices = useSelector(state => state.deviceManager.countDevices);
    const paginationInfo = useSelector(state => state.deviceManager.paginationInfo);
    const endIcon = showMaps ? <Cancel className={"tb-map-cancel-button"}/> : <ArrowDropDown/>;
    const classNameButtonMap = showMaps ? "tb-device-manager-map-button" : "";
    const canEdit = useSelector(state => state.user.permissions['edit-infrastructure-devices']);
    const dispatch = useDispatch();
    const [anchorElAdd, setAnchorElAdd] = useState(null);
    const [openMenuOptions, setOpenMenuOptions] = useState(null);
    // const disableAddButton = (!props.mapSelected || !props.mapSelected.isMap);
    const showedColumns = columns.filter(column => column.name !== 'actions');

    const handleOpenDropDown = (e,menuId) => {
        setAnchorElAdd(e.currentTarget);
        setOpenMenuOptions(menuId);
    };

    const handleChangeView=(viewKey)=>{
        dispatch(changeView(viewKey,"DEVICE_MANAGER"));
        setOpenMenuOptions(null);
    }

    let styles = {
        menuItem:{padding: '4px 8px', width:'100%',display: 'flex',justifyContent:'flex-start'},
        characterItem:{paddingLeft:'4px', fontSize: '14px',flexGrow: 1},
        linkItem:{textDecoration:'none', color:'#000'}
    }

    //disable batch import and add devices manually
    // if(disableAddButton){
    //     styles.linkItem = {...styles.linkItem, pointerEvents:'none', color:'#cecece'}
    // }

    const dropdownContent = (
        <Popover open={openMenuOptions===MENU_ADD}
                anchorEl = {anchorElAdd}
                placement={'bottom-start'}
                anchorOrigin={{vertical: 'bottom',horizontal: 'left'}}             
                transformOrigin={{vertical: 'top',horizontal: 'left'}}>
                
                <ClickAwayListener onClickAway={()=>setOpenMenuOptions(null)}>
                    <div style={{display: 'flex',flexDirection:'column'}} >
                        <Link style={styles.linkItem} to={`/device-manager/devices/${PATH_ADD_DEVICES_MANUALLY}`}>
                            <MenuItem style={styles.menuItem}> 
                                <AddBoxIcon/> <span style={styles.characterItem}>Add Devices Manually</span>
                            </MenuItem>
                        </Link>

                        <Link style={styles.linkItem} to={`/device-manager/devices/${PATH_ADD_BATCH_DEVICES}`}>
                            <MenuItem style={styles.menuItem}>
                                <BatchIcon/> <span style={styles.characterItem} >Batch Import</span>
                            </MenuItem>
                        </Link>

                        <Link style={{textDecoration:'none', color:'#000'}} to={`/device-manager/devices/${PATH_ADD_SYNC_VIA_ZEBRA_DATA_SERVICE}`}>
                            <MenuItem style={styles.menuItem}>
                                <CloudDownloadIcon/> <span style={styles.characterItem} >Add/Sync Device List from Zebra Data Service</span>
                            </MenuItem>
                        </Link>
                    </div>
                </ClickAwayListener>
        </Popover>
    );

    const renderAddButton = () => {
        if (!canEdit) {
            return null;
        }
        return (
            <React.Fragment>
                <Button
                    style={{textTransform: 'none', height: '100%'}}
                    variant="text"
                    onClick={(e)=>handleOpenDropDown(e,MENU_ADD)}
                    startIcon={<AddIcon />}>Add</Button>
                {dropdownContent}
            </React.Fragment>
        );
    };

    const renderSiteInfoButton = () => {
        return (
            <React.Fragment>
                <Button
                    className={(!siteId || !props.mapSelected) ? 'tb-map-add-button-disabled' : ''}
                    style={{textTransform: 'none', height: '100%', marginLeft: '10px'}}
                    variant="text"
                    onClick={(e)=>handleOpenSiteInfo(e,siteId)}
                    startIcon={<InfoIcon />} data-tip={"Select a site or map"} data-for={'siteInfo'}>Site Info</Button>
                    {(!siteId || !props.mapSelected)? <ReactToolTip className={"tooltip-toolbar-class"} place={"bottom"} id={"siteInfo"} effect={"solid"} /> : null}
            </React.Fragment>
        )
    };

    const handleOpenSiteInfo = (e, siteId) => {
        if(!siteId || !props.mapSelected){
            e.stopPropagation();
            return null;
        }
        // SET_CONFIG
        dispatch(requestSnapshotInfoBySite(siteId));
        props.setOpenView(true);
    };

    const tableMapOptions=()=>{
        return (
            <React.Fragment>
                <ClassedIconButton
                    id={"view_btn"}
                    onClick={(e)=>handleOpenDropDown(e,MENU_TABLE_MAP)}
                >
                    <MapViewIcon />
                </ClassedIconButton>
                <Popover open={openMenuOptions===MENU_TABLE_MAP}
                         anchorEl = {anchorElAdd}
                         placement={'bottom-start'}
                         anchorOrigin={{
                             vertical: 'bottom',
                             horizontal: 'left',
                         }}
                         transformOrigin={{
                             vertical: 'top',
                             horizontal: 'left',
                         }}>
                    <ClickAwayListener onClickAway={()=>setOpenMenuOptions(null)}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <MenuItem style={styles.menuItem} onClick={()=>handleChangeView(REPORT_VIEW)}>
                                <RemoveRedEye/> <span style={styles.characterItem}>Report View</span>
                            </MenuItem>
                            <MenuItem style={styles.menuItem} onClick={()=>handleChangeView(MAP_VIEW)}>
                                <MapIcon /> <span style={styles.characterItem}>Map View</span>
                            </MenuItem>
                            <MenuItem style={styles.menuItem} onClick={()=>handleChangeView(MAP_REPORT_VERTICAL_SPLIT)}>
                                <MapIcon /> <span style={styles.characterItem}>Report & Map Vertical</span>
                            </MenuItem>
                            <MenuItem style={styles.menuItem} onClick={()=>handleChangeView(MAP_REPORT_HORIZONTAL_SPLIT)}>
                                <MapIcon /> <span style={styles.characterItem}>Report & Map Horizontal</span>
                            </MenuItem>
                            <MenuItem style={styles.menuItem} onClick={()=>handleChangeView(MAP_REPORT_HORIZONTAL_REVERSE_SPLIT)}>
                                <MapIcon /> <span style={styles.characterItem}>Map & Report Horizontal</span>
                            </MenuItem>
                        </div>
                    </ClickAwayListener>
                </Popover>
            </React.Fragment>
        );
    }

    const handleChangePage = (nextPage, prevPage) => {
        if(nextPage && !paginationInfo.nextPageAllow){
            return;
        }

        if(prevPage && !paginationInfo.prevPageAllow){
            return;
        }
        dispatch(changePage(nextPage, prevPage));
        dispatch(loadDeviceList())
    }

    const renderPagination = () => {
        /*return <TablePagination
            rowsPerPage={500}
            count={countDevices}
            page={0}
        />;*/
        return(
            <div style={{display:'flex', height: '100%', lineHeight:'48px', margin: '0 20px'}}>
                {paginationInfo.totalRecords > 0 && <div style={{margin: '0 20px', minWidth:'90px'}}>{((paginationInfo.totalRecords > 0 ?
                    paginationInfo.offset+1 : paginationInfo.offset) + " - " + paginationInfo.totalPerPage + " of " + paginationInfo.totalRecords)}</div> }
                <div style={{display:'flex'}}>
                    <div style={{cursor:'pointer', lineHeight: '62px', decoration:'underline', color: !paginationInfo.prevPageAllow ? '#cecece': 'inherit'}} onClick={() => handleChangePage(false, true)}><BackIcon/></div>
                    <div style={{textAlign: 'center'}}>{paginationInfo.page}</div>
                    <div style={{cursor:'pointer', lineHeight: '62px', decoration:'underline', color: !paginationInfo.nextPageAllow ? '#cecece': 'inherit', paddingLeft: '5px'}} onClick={() => handleChangePage(true, false)}><ForwardIcon/></div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className={"tb-device-manager-left-container"}>
                <Breadcrumbs />
            </div>
            <div className={"tb-device-manager-buttons-container"}>
                {renderPagination()}
                <Button
                    className={classNameButtonMap}
                    style={{textTransform: 'none', height: '100%', padding: "0 0.3em", lineHeight: '17px'}}
                    variant="text"
                    onClick={()=>dispatch(changeStateShowMaps(!showMaps))}
                    startIcon={<Place />}
                    endIcon={endIcon}
                >{renderFilterName(props.mapSelected, countDevices)}</Button>
                <div className="report-separator">&nbsp;</div>
                <Button
                    style={{textTransform: 'none', height: '100%', padding: "0 1.3em"}}
                    onClick={()=>dispatch(loadDeviceList())}
                    variant="text"
                    startIcon={<CachedIcon />}>Refresh</Button>
                {renderAddButton()}
                {renderSiteInfoButton()}
                <Link style={{textDecoration:'none', color:'#fff'}} to={`/device-manager/${PATH_TEMPLATES}`} push={'true'}>
                    <MenuItem style={styles.menuItem}>
                        <ListIcon/> <span style={styles.characterItem}>Templates</span>
                    </MenuItem>
                </Link>

                <div className="report-separator">&nbsp;</div>
                <ColumnsDropDown name="devices" columns={showedColumns} />
                {tableMapOptions()}
            </div>
        </>
    );
};

const DeviceManagerToolbar = props => {
    const history = useHistory();
    const siteId = useSelector(state => state.deviceManager.siteId);
    const isDeviceChanging = useSelector(state => state.deviceManager.isDeviceChanging);
    const deviceManagerScreen = useSelector(state => state.deviceManager.deviceManagerScreen);
    const [openDialog, setOpenDialog] = useState(false);
    let labelTop = '';
    if(props.mapSelected){
        labelTop = labelTop + props.mapSelected.siteName;
        labelTop = props.mapSelected.name ? labelTop + ' - ' +props.mapSelected.name: labelTop;
    }
    const [openView, setOpenView] = useState(false);

    const handleCloseView = () => {
        setOpenView(false);
    };
    
    const handleReturnPage = () => {
        dispatch(cleanDevicesBatch());
        dispatch(detectSaveChanges(false));
        history.replace('/device-manager/devices');
    }

    const handleBack = () => {
        if(isDeviceChanging && deviceManagerScreen===EDIT_DEVICES_SCREEN){
            setOpenDialog(true);
        }else{
            dispatch(cleanDevicesBatch());
            history.replace('/device-manager/devices');
        }
    }

    const dispatch = useDispatch();
    const getToolbar = (location) => {
        if(!location){
            return <MainToolbar setOpenView={setOpenView} mapSelected={props.mapSelected} />
        }else{
            return (
                <React.Fragment>
                    <div className={"tb-device-manager-left-container"}>
                        <IconButton style={{padding:0, color:'white'}} onClick={handleBack}>
                            <BackArrow />
                        </IconButton>
                        {location === PATH_ADD_DEVICES_MANUALLY && <h4>Add Devices Manually</h4>}
                        {location === PATH_ADD_BATCH_DEVICES && <h4>Add Batch Devices</h4>}
                        {location === PATH_ADD_SYNC_VIA_ZEBRA_DATA_SERVICE && <h4>Add/Sync Device List from Zebra Data Service</h4>}
                        {(location === PATH_EDIT_DEVICE) && <h4>Edit Device</h4>}
                    </div>
                    <div className={"tb-device-manager-buttons-container"}>
                        <span>{labelTop}</span>
                    </div>
                </React.Fragment>
            )
        }
    }

    return (
        <React.Fragment>
            <div className="toolbar-main-container save-share-toolbar">
                {getToolbar(props.location)}
                {openView && <ViewConfigDialog openBox={openView} title={"Site " + props.mapSelected.siteName + " Snapshot Information"} siteId={siteId} onRequestClose={handleCloseView}/>}
            </div>

            {openDialog && <DialogConfirm 
                open={openDialog}
                closeDialog={() => setOpenDialog(false)}
                actionSuccess={handleReturnPage}
                actionCancel={()=>{}}
                message={'There are changes not saved. Are you sure to leave this page?'}
            />}
        </React.Fragment>
    )
}
export default React.memo(DeviceManagerToolbar, ((prevProps, nextProps) => {
    return prevProps.mapSelected === nextProps.mapSelected
}) );