import React, {useRef} from 'react';
import ReactToolTip from 'react-tooltip';
import ZoneToggleButton from '../../util/map/misc/ZoneToggleButton';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import WorldIcon from '@material-ui/icons/Public';
import MapsIcon from '@material-ui/icons/Collections';
import TagLabelIcon from '@material-ui/icons/Crop75';
import ZoomInIcon from '@material-ui/icons/AddBox';
import CenterIcon from '@material-ui/icons/CenterFocusStrong';
import ZoomOutIcon from '@material-ui/icons/IndeterminateCheckBox';
// import DeviceGpsFixed from '@material-ui/icons/GpsFixed';
// import DeviceGpsOff from '@material-ui/icons/GpsOff';
// import DeviceGpsNotFixed from '@material-ui/icons/GpsNotFixed';
// import PlayIcon from '@material-ui/icons/PlayArrow';
import {easeOut} from "ol/easing";
import {getCenter} from "ol/extent";
import {changeMapView, selectDevicesMap, toggleMapDrawer} from "../../../actions";
import {LANDING_PAGE_SCREEN, WORLD_MAP_VIEW} from "../../../constants/DeviceManager";
import {useDispatch, useSelector} from "react-redux";
import {ZOOM_INCREMENT} from "../../../constants/SiteManager";

const MapToolbar = ({
        map,
        bounds,
        zoneVisibility,
        autoZoneVisibility,
        toggleZoneVisibility,
        showDeviceLabels,
        //showThumbnails,
        //parentCallback,
        deviceLabelsCallback,
        deviceManagerScreen
}) => {
    const leftToolbar = useRef();
    const showMapDrawer = useSelector(state => state.report.showMapDrawer);
    const dispatch = useDispatch();

    const zoomMapByDeltaWithDuration = (delta,duration) => {

        let view = (map) ? map.getView() : null;
        if (!view) {
            // the map does not have a view, so we can't act
            // upon it
            // eslint-disable-next-line
            throw "Map does not have view.  Cannot zoom.";
        }
        let currentResolution = view.getResolution();

        if(!currentResolution){
            // eslint-disable-next-line
            throw "No current resolution.";
        }

        //let newResolution = view.constrainResolution(currentResolution, delta);
        //let currentResolutions = view.getResolutions();
        const newZoom = view.getZoom() + delta;
        if (duration > 0) {
            if (view.getAnimating()) {
                view.cancelAnimations();
            }
            view.animate({
                //resolution: currentResolution - delta,
                zoom:newZoom,
                duration: duration,
                easing: easeOut
            });
        } else {
            //view.setResolution(currentResolution - newDelta);
            view.setZoom(newZoom);
        }
    };

    const zoomIn = () => {
        zoomMapByDeltaWithDuration(ZOOM_INCREMENT,250);
    };

    const zoomOut = () => {
        zoomMapByDeltaWithDuration(-ZOOM_INCREMENT,250);
    };

    const centerMap = () => {
        let view = (map) ? map.getView() : null;
        if (!view) {
            // the map does not have a view, so we can't act
            // upon it
            // eslint-disable-next-line
            throw "Map does not have view.  Cannot zoom.";
        }
        view.setCenter(getCenter(bounds));
        view.setZoom(1);
    };


    const handleShowDevicesLabel = () => {
        deviceLabelsCallback(!showDeviceLabels);
    };

    const handleShowMapThumbnails = () => {
        //parentCallback(!showThumbnails);
        dispatch(toggleMapDrawer(!showMapDrawer));
    };

    const handleWorldMap = () => {
        dispatch(selectDevicesMap(null));
        dispatch(changeMapView(WORLD_MAP_VIEW));
    };

    return (

        <Paper className="toolbar-map-container" ref={leftToolbar} elevation={2}>

            {deviceManagerScreen === LANDING_PAGE_SCREEN ? (
                <>
                    <IconButton onClick={handleWorldMap} style={{backgroundColor: 'transparent', display:'block', color: '#000'}} data-tip={"Show WorldMap"} data-for={'worldMap'}>
                        <WorldIcon />
                    </IconButton>
                    <ReactToolTip className={"tooltip-toolbar-class"} place={"left"} id={"worldMap"} effect={"solid"} />
                </>
            ) : null}
            <IconButton style={styles.zoomInButton} disableRipple onClick={zoomIn} data-tip={"Zoom In"} data-for={"zoomIn"} disabled={!Boolean(bounds)}>
                <ZoomInIcon />
            </IconButton>
            <ReactToolTip className={"tooltip-toolbar-class"} place={"left"} id={"zoomIn"} effect={"solid"} />

            <IconButton style={styles.zoomOutButton} onClick={zoomOut} disableRipple data-tip={"Zoom Out"} data-for={"zoomOut"} disabled={!Boolean(bounds)}>
                <ZoomOutIcon />
            </IconButton>
            <ReactToolTip className={"tooltip-toolbar-class"} place={"left"} id={"zoomOut"} effect={"solid"} />

            <IconButton onClick={centerMap} style={{backgroundColor: 'transparent', color: '#000'}} disableRipple data-tip={"Center Map"} data-for={"centerMap"} disabled={!Boolean(bounds)}>
                <CenterIcon />
            </IconButton>
            <ReactToolTip className={"tooltip-toolbar-class"} place={"left"} id={"centerMap"} effect={"solid"} />

            <ZoneToggleButton style={styles.standardButton} onClick={() => toggleZoneVisibility('zones')} mode={zoneVisibility} data-tip={"Show/Hide Zones"} data-for={"zones"} />

            <ReactToolTip className={"tooltip-toolbar-class"} place={"left"} id={"zones"} effect={"solid"} />

            {/* <IconButton style={{backgroundColor: 'transparent', color: '#000'}} disableRipple data-tip={"Show/Hide Auto Zones"} data-for={"autoZones"} disabled={!Boolean(bounds)}>
                <Crop32Icon />
            </IconButton> */}

            
            <ZoneToggleButton style={styles.standardButton} onClick={() => toggleZoneVisibility('autoZones')} mode={autoZoneVisibility} data-tip={"Show/Hide Auto zones"} data-for={"autoZones"} />

            <ReactToolTip className={"tooltip-toolbar-class"} place={"left"} id={"autoZones"} effect={"solid"} />
            

            {/*showTagLabels ? styles.enabledButton : styles.standardButton}*/}
            <IconButton style={showDeviceLabels ? styles.enabledButton : styles.standardButton} disableRipple onClick={handleShowDevicesLabel}
                        data-tip={"Show/Hide Device Labels"} data-for={"showTagLabel"} disabled={!Boolean(bounds)}
            >
                <TagLabelIcon />
            </IconButton>
            <ReactToolTip className={"tooltip-toolbar-class"} place={"left"} id={"showTagLabel"} effect={"solid"} />

            <div style={styles.spacer}/>

            <IconButton style={showMapDrawer ? styles.enabledButton : styles.standardButton} onClick={handleShowMapThumbnails} disableRipple data-tip={"Show/Hide Map Thumbnails"} data-for={"showMapThumbnails"}>
                <MapsIcon />
            </IconButton>
            <ReactToolTip className={"tooltip-toolbar-class"} place={"left"} id={"showMapThumbnails"} effect={"solid"} />

            <div style={styles.spacer} />

            {/*{goPlaybackEnabled ? styles.enabledButton : styles.standardButton
            <IconButton style={styles.enabledButton} onClick={() => {}}
                        disableTouchRipple data-tip={"Playback"} data-for={"playback"}
            >
                <PlayIcon />
            </IconButton>
            <ReactToolTip className={"tooltip-toolbar-class"} place={"left"} id={"playback"} effect={"solid"} />

            <div style={styles.spacer}></div>
            realTime && realTime[reportId] ? styles.enabledButton : styles.standardButton
            disableRipple data-tip={realTimeIsDisabled?"Real Time is Disabled in Current Report":"Toggle Real Time"}
            <IconButton style={styles.enabledButton} onClick={() => {}}
                        disableRipple data-tip={"Toggle Real Time"} data-for={"realTime"}
            >
                { realTime && realTime[reportId] ? (connectedRealTime && connectedRealTime[reportId] ? <DeviceGpsFixed /> : <DeviceGpsOff />) : <DeviceGpsNotFixed /> }
                <DeviceGpsFixed />
            </IconButton>*/}
            <ReactToolTip className={"tooltip-toolbar-class"} place={"left"} id={"realTime"} effect={"solid"} />
        </Paper>
    );
};

const styles = {
    zoomInButton:{
        display:'block',
        height:'32px',
        padding:'8px 12px 0px',
        backgroundColor: 'transparent',
        color: '#000'
    },
    zoomOutButton:{
        display:'block',
        height:'32px',
        padding:'0px 12px 8px',
        backgroundColor: 'transparent',
        color: '#000'
    },
    standardButton:{
        display:'block',
        height:'35px',
        padding:'10px 12px',
        backgroundColor: 'transparent',
        color: '#aaa'
    },
    enabledButton: {
        display:'block',
        height:'35px',
        padding:'10px 12px',
        backgroundColor: 'transparent',
        color: '#000'
    },
    spacer:{
        height:'1em',
        width:'48px'
    },
};

export default MapToolbar;