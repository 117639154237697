import React from 'react';
import PropTypes from 'prop-types';
import ZPage from './../../../../common/app/views/ZPage';

import SaveAndConfigToolbar from './toolbars/SaveAndConfigToolbar';

import TileLayout from './TileLayout';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../../actions';

class DashboardPage extends React.Component{

    static get propTypes(){
        return {
            actions: PropTypes.object,
            view: PropTypes.string,
            dataEntryOpen: PropTypes.bool,
            data: PropTypes.array,
            columns: PropTypes.array,
            requestDashboard: PropTypes.func.isRequired,
            match: PropTypes.object
        };
    }

    static get defaultProps(){
        return {
            dataEntryOpen: false
        };
    }

    constructor(props,context){
        super(props,context);
        this.state = {
            layout:[],
            components:{},
            height:3,
            width:4,
            name:"(Unnamed)"
        };

        this.getDashboardID = this.getDashboardID.bind(this);
    }

    componentDidMount(){
        this.requestDashboard();
    }

    componentDidUpdate(prevProps){
        if(prevProps.match !== this.props.match){
            this.requestDashboard();
        }
    }

    getDashboardID(){
        return this.props.match.params.dashboardId || 0;
    }

    requestDashboard(){
        this.props.requestDashboard(this.getDashboardID())
        .then(m=>{
            this.setState({
                layout: m.LAYOUT,
                components: m.COMPONENTS,
                height: m.META.height || 3,
                width: m.META.width || 4,
                name: m.META.title || "(Unnamed)"
            });
        });
    }

    render (){
        const {actions} = this.props;
        const {layout,components,height,width} = this.state;

        return (
            <ZPage zBreadCrumbs={["Home"]}
                zSubHeaderHeight={100}
                zShowSubHeader={false} >
                <div>
                    <SaveAndConfigToolbar actions={actions} name={this.state.name}/>

                    <div className={"dashboard-page"}>
                        <TileLayout layout={layout} components={components} height={height} width={width} key={this.getDashboardID()}/>
                    </div>
                </div>
            </ZPage>

        );
    }


}


const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        requestDashboard:actions.requestDashboard,
    },dispatch)
});

const mapStateToProps = state =>({
    view: state.view.default,
    dataEntryOpen: state.dataEntry.open,
    data: state.report.data
});

export default connect(mapStateToProps,mapDispatchToProps)(DashboardPage);
