/* jshint esversion: 6 */

import React from 'react';
import PropTypes from 'prop-types';
//openlayers
import PointerInteraction from 'ol/interaction/Pointer';
//import Pixel from 'ol/pixel';
//import Feature from 'ol/Feature';
// import {inherits as olInherits} from 'ol';
//import MapBrowserEvent from 'ol/MapBrowserEvent';
//import SimpleGeometry from 'ol/geom/SimpleGeometry';

let app = {};
/**
       * @constructor
       * @extends {PointerInteraction}
       */
      app.Drag = function(options) {

        PointerInteraction.call(this, {
          handleDownEvent: app.Drag.prototype.handleDownEvent,
          handleDragEvent: app.Drag.prototype.handleDragEvent,
          handleMoveEvent: app.Drag.prototype.handleMoveEvent,
          handleUpEvent: app.Drag.prototype.handleUpEvent
        });

        /**
         * @type {Pixel}
         * @private
         */
        this.coordinate_ = null;

        /**
         * @type {string|undefined}
         * @private
         */
        this.cursor_ = 'pointer';

        /**
         * @type {Feature}
         * @private
         */
        this.feature_ = null;

        /**
         * @type {string|undefined}
         * @private
         */
        this.previousCursor_ = undefined;

        this.onStopDragging = options.onStopDragging || function(){};

      };

      const olInherits = (child, parent) => {
        child.prototype = Object.create(parent.prototype);
        child.prototype.constructor = child;
      }

      olInherits(app.Drag, PointerInteraction);


      /**
       * @param {MapBrowserEvent} evt Map browser event.
       * @return {boolean} `true` to start the drag sequence.
       */
      app.Drag.prototype.handleDownEvent = function(evt) {
        var map = evt.map;

        var feature = map.forEachFeatureAtPixel(evt.pixel,
            function(feature) {
              return feature;
            });

        if (feature) {
          this.coordinate_ = evt.coordinate;
          this.feature_ = feature;
        }

        return !!feature;
      };


      /**
       * @param {MapBrowserEvent} evt Map browser event.
       */
      app.Drag.prototype.handleDragEvent = function(evt) {
        var deltaX = evt.coordinate[0] - this.coordinate_[0];
        var deltaY = evt.coordinate[1] - this.coordinate_[1];

        var geometry = /** @type {SimpleGeometry} */
            (this.feature_.getGeometry());
        geometry.translate(deltaX, deltaY);

        this.coordinate_[0] = evt.coordinate[0];
        this.coordinate_[1] = evt.coordinate[1];
      };


      /**
       * @param {MapBrowserEvent} evt Event.
       */
      app.Drag.prototype.handleMoveEvent = function(evt) {
        if (this.cursor_) {
          var map = evt.map;
          var feature = map.forEachFeatureAtPixel(evt.pixel,
              function(feature) {
                return feature;
              });
          var element = evt.map.getTargetElement();
          if (feature) {
            if (element.style.cursor !== this.cursor_) {
              this.previousCursor_ = element.style.cursor;
              element.style.cursor = this.cursor_;
            }
          } else if (this.previousCursor_ !== undefined) {
            element.style.cursor = this.previousCursor_;
            this.previousCursor_ = undefined;
          }
        }
      };


      /**
       * @return {boolean} `false` to stop the drag sequence.
       */
      app.Drag.prototype.handleUpEvent = function() {
          this.onStopDragging(this.coordinate_,this.feature_);
        this.coordinate_ = null;
        this.feature_ = null;
        return false;
      };



/**
 * Simple select interaction component.  When included, it will call its onSelect
 * function when features are hovered over in the map.
 */
class DragInteraction extends React.Component{

    /**
     * @private
     */
    constructor(props){
        super(props);

        /**
         * @type {Object}
         * @property {function} onSelect Fires when features are selected.  Should accept an array of features.
         */
        this.props = props;
    }

    /**
     * @private
     */
    static get propTypes(){
        return {
            map: PropTypes.any, // passed in by <Map />
            onStopDragging: PropTypes.func
        };
    }

    /**
     * @private
     * Runs after mounting.
     */
    componentDidMount(){
        if(!this.props.map) return;

        this.props.map.addInteraction(new app.Drag({
            onStopDragging:this.props.onStopDragging
        }));
    }

    /**
     * @private
     * Renders the UI to the Virtual DOM (which updates the actual DOM)
     */
    render(){
        return (<div />);
    }
}

export default DragInteraction;
