const localeTimestamp = (date) => {

    // return date.toLocaleDateString(window.navigator.language,{
    //     year:"numeric",
    //     month:"long",
    //     day:"numeric"
    // });

    return date.toLocaleDateString(window.navigator.language,{
        year:"2-digit",
        month:"numeric",
        day:"numeric",
        hour:"numeric",
        minute:"numeric",
        second:"numeric"
    });
};

export {localeTimestamp};
