import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {changeStatusDetails, loadDeviceDetailsData, startPoolingDetails, stopPoolingDetails, unloadDeviceDetails} from "../../../actions";
import ConfigurationDetails from './ConfigurationDetails';
import CardsAntennas from './CardsAntennas';
import Identity from './Identity';
import OperationPanel from './OperationPanel';
import NavigateDetails from './NavigateDetails';
import ProgressDetails from "../common/ProgressDetails";
import {localeTimestamp} from "../../../utils/TimestampFormats";
import Resizer from '../../home/sidebar/Resizer';
import ReaderStatusPanel from './ReaderStatusPanel';
// import LogConfigurationPanel from "./LogConfigurationPanel";

const DeviceDetails = (props) => {
    const isMobile = useSelector(state => state.resize.isMobile);
    const showDetails = useSelector(state => state.deviceManager.showDetails);
    const windowHeight = useSelector(state => state.resize.windowHeight);
    const detailsData = useSelector(state => state.deviceManager.detailsDevice);
    const selectedRows = useSelector(state => state.deviceManager.selectedRows);
    const isFetchingDetails = useSelector(state => state.deviceManager.isFetchingDetails);
    const idsArray = Object.keys(selectedRows);
    const totalItems = idsArray.length;

    const [index,setIndex] = useState(0);
    const dispatch = useDispatch();

    const handleClose = () => {
        props.onClose();
        dispatch(changeStatusDetails(false));
    }

    const loadData =()=>{
        if (showDetails){
            dispatch(loadDeviceDetailsData(idsArray[index]));
            dispatch(startPoolingDetails(idsArray[index]));
        }
        return () => {
            dispatch(stopPoolingDetails());
        }
    };
    useEffect(loadData,[showDetails, index]);

    useEffect(()=>{
        if(index+1 > totalItems){
            setIndex(index - 1);
        }

        if(totalItems === 0){
            dispatch(changeStatusDetails(false));
        }
    },[totalItems, index, setIndex, dispatch]);

    const destroyDetails=() => {
        // Load first time on mount component.
        const unmount = () => {
            dispatch(unloadDeviceDetails())
            handleClose();
        }
        return unmount;
    }

    // Only call once when mount.
    useEffect(destroyDetails, []);

    const CreateBy=({createBy, lastUpdate})=>(
        <div className="dd-createby">
            {createBy===""? <span>Created by: &nbsp;&nbsp;&nbsp;</span> : <span>Created by: {createBy} &nbsp;&nbsp;&nbsp;</span>}
            <span>Last Update: {lastUpdate ? localeTimestamp(new Date(lastUpdate)): ''}</span>
        </div>
    );

    const width = showDetails ? props.width : 0;

    let detailsSidebarClassName = 'details-sidebar-container-wrapper';
    if (props.animationEnabled) {
        detailsSidebarClassName += ' animation';
    }
    return(
        <Resizer
            className={detailsSidebarClassName}
            width={width}
            handleResize={props.handleResize}
            resizing={props.resizing}
            collapsed={!showDetails}
            separatorPosition="left"
            identifier={props.identifier}
            onTransitionEnd={()=>props.onTransitionEnd()}
            isMobile={isMobile}>
            <div className="dd-main-component">
                {isFetchingDetails?<ProgressDetails width={width} height={windowHeight - 108} />: null}
                {detailsData && <>
                    <NavigateDetails index={index} setIndex={setIndex} totalItems={totalItems} title={detailsData?detailsData.title:''} handleClose={handleClose} />
                    <div style={{padding:' 5px 7px', height: `${windowHeight - 163}px`, overflow:'auto', wordWrap: 'break-word'}}>
                        <ConfigurationDetails configuration={detailsData ?detailsData.configuration_state:''} template={detailsData ?detailsData.templateTitle:''} 
                                            version={detailsData ?detailsData.version:''} operationalStatus={detailsData ?detailsData.status?.title:''}/>
                        <CreateBy createBy={detailsData ?detailsData.createBy:''} lastUpdate={detailsData ?detailsData.lastUpdate:''}/>
                        <Identity deviceType={detailsData ?detailsData.deviceType:''} description={detailsData ?detailsData.description:''} 
                                    address={detailsData ?detailsData.ipAddress:''} location={detailsData ?detailsData.location:''} />
                        <OperationPanel
                            operationMode={detailsData ? detailsData.operationMode : ''}
                            tagFilter={detailsData ? detailsData.tagFilter : {} }
                            inventoryInterval={detailsData ? detailsData.inventoryInterval : {}}
                            minRssi={detailsData ? detailsData.minRssi : ''}
                            portalSettings={detailsData ? detailsData.portalSettings : {}}
                        />
                        <ReaderStatusPanel setOpenHistory={props.setOpenHistory} setDeviceId={props.setDeviceId} deviceId={detailsData.deviceIdStr}/>
                        {/*<LogConfigurationPanel/>*/}
                        <CardsAntennas antennas={detailsData.rfidAntennas}/>
                    </div>
                </>}
            </div>
        </Resizer>
    )
};

export default DeviceDetails;