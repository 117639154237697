const FILTERS = [

    {
      "name": "medianFilter24730",
      "type": "median",
      "technology": "WHERENET",
      "detectMoving": false,
      "description": "Filter settings for smoothing location tag output",
      "enabled": false,
      "medianZCount": 3,
      "moveMedian": 1,
      "jumpMoveCount": 3,
      "jumpMoveRadius": 20,
      "medianCount": 3
    },
    {
      "name": "rateFilter24730",
      "type": "rate",
      "technology": "WHERENET",
      "description": "Filter settings for reduce blink rate",
      "enabled": false,
      "minMoveDistance": 0,
      "minWaitTime": 0,
      "maxWaitTime": 0
    },
    {
      "name": "medianFilterBLE",
      "type": "median",
      "technology": "BLE",
      "detectMoving": false,
      "description": "Filter settings for smoothing location tag output",
      "enabled": false,
      "medianZCount": 3,
      "moveMedian": 1,
      "jumpMoveCount": 3,
      "jumpMoveRadius": 20,
      "medianCount": 3
    },
    {
      "name": "rateFilterBLE",
      "type": "rate",
      "technology": "BLE",
      "description": "Filter settings for reduce blink rate",
      "enabled": false,
      "minMoveDistance": 0,
      "minWaitTime": 0,
      "maxWaitTime": 0
    },
    {
      "name": "medianFilterRFID",
      "type": "median",
      "detectMoving": false,
      "technology": "RFID",
      "description": "Filter settings for smoothing location tag output",
      "enabled": false,
      "medianZCount": 3,
      "moveMedian": 1,
      "jumpMoveCount": 3,
      "jumpMoveRadius": 20,
      "medianCount": 3
    },
    {
      "name": "rateFilterRFID",
      "type": "rate",
      "technology": "RFID",
      "description": "Filter settings for reduce blink rate",
      "enabled": false,
      "minMoveDistance": 0,
      "minWaitTime": 0,
      "maxWaitTime": 0
    },
    {
      "name": "callFilter24730",
      "type": "call",
      "technology": "WHERENET",
      "description": "Filter settings to suppress call button blinks",
      "enabled": false,
      "suppressionWindow": 3
    },
    {
        "name": "medianFilterUWB",
        "type": "median",
        "technology": "DART",
        "detectMoving": false,
        "description": "Filter settings for smoothing location tag output",
        "enabled": false,
        "medianZCount": 3,
        "moveMedian": 1,
        "jumpMoveCount": 3,
        "jumpMoveRadius": 20,
        "medianCount": 3
    },
    {
        "name": "rateFilterUWB",
        "type": "rate",
        "technology": "DART",
        "description": "Filter settings for reduce blink rate",
        "enabled": false,
        "minMoveDistance": 0,
        "minWaitTime": 0,
        "maxWaitTime": 0
    }
];

export default FILTERS;
