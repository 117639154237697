import * as types from '../constants/ActionTypes';
import {displayErrorFromAxios, ZoneService} from './util';
import {requestSites} from './sites';
import {requestMaps} from './site-maps';

export const sayHello = ()=>{
    return ()=>{
        console.log("hello"); // eslint-disable-line
    };
};

export const saveToStaging = (mapId)=>{
    return (dispatch,getState)=>{

        mapId = mapId || 0;

        //console.log(`Auto-saving map #${mapId}...`);

        const state = getState();
        const allZones = state.zoneBuilder.zones;
        const zonesForMap = Object.keys(allZones).reduce((zones,id)=>{
            if(allZones[id].mapId === mapId){
                zones[id] = allZones[id];
            }

            return zones;
        },{});

        return ZoneService.instance().put(`/staging/${mapId}`,{
            _id:mapId,
            zones: zonesForMap
        })
        .then(()=>{
            dispatch({type:types.SAVE_ZONES});
        })
        .catch(displayErrorFromAxios.bind(null,dispatch));

    };
};

export const saveCurrentMapZonesToStaging = () => {
    return (dispatch,getState)=>{
        const state = getState();
        return dispatch(saveToStaging(state.sites.selectedFloorMap));
    };
};

export const publishCurrentMapZones = () => ({type: types.PUBLISH_ZONES});

export const selectZone = (zone)=>({type:types.SELECT_ZONE, zone});
export const toggleDrawZone = ()=>({type:types.TOGGLE_DRAW_ZONE});
export const toggleModifyZone = ()=>({type:types.TOGGLE_MODIFY_ZONE});
export const toggleMoveZone = ()=>({type:types.TOGGLE_MOVE_ZONE});


export const copyZone = ()=>({type:types.COPY_ZONE});
export const pasteZone = ()=>{
    return (dispatch,getState)=>{
        const state = getState();
        const clipboardZone = state.zoneBuilder.clipboardZone;

        if(!clipboardZone) return;

        const zoneCopy = getCopyOfZone(clipboardZone);

        dispatch({
            type: types.ADD_ZONE,
            zone:{
                _id: generateObjectId({existingZones:state.zoneBuilder.zones}),
                ...zoneCopy
            }
        });
    };
};

const copyCounts = {};
function getCopyOfZone(zone){
    const originalName = zone.name;
    if(!copyCounts[originalName]) copyCounts[originalName] = 0;
    const copyCount = ++copyCounts[originalName];

    return {
        color: zone.color,
        mapId: zone.mapId,
        name: `${zone.name} (${copyCount})`,
        shape: getGeoJSONGeometryWithOffset(zone.shape,10*copyCount,10*copyCount)
    };
}

function getGeoJSONGeometryWithOffset(shape,offsetX,offsetY){
    return {
        ...shape,
        geometry:{
            ...shape.geometry,
            coordinates:shape.geometry.coordinates.map(c => {
                return c.map( ([x,y]) => ([ x + offsetX, y + offsetY ]) );
            })
        }
    };
}

export const loadZones = (zones) => {
    return dispatch => {

        const defaultProperties = {
            color: '#00779f'
        };

        const adjustedZones = zones.filter(z=>z.shape).map(z=>({
            ...defaultProperties,
            ...z,
            geojson: z.shape
        }));

        dispatch({
            type: types.LOAD_ZONES,
            zones: adjustedZones
        });
    };
};

export const requestZones = ()=>{
    return (dispatch) => {
        return ZoneService.instance().get('/zones')
        .then(d=>d.data)
        //.then(zones=>{
        //    dispatch( loadZones(zones) );
        //})
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};

export const requestZonesForMap = (mapid) => {
    return (dispatch) => {
        return ZoneService.instance().get(`/staging/${mapid}`)
        .then(d=>d.data)
        .then(stage=>{
            const zones = Object.keys(stage.zones || {}).map(i=>stage.zones[i]);
            //console.log(`Loading zones for map #${mapid}`);
            dispatch( loadZones(zones) );
        })
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};

let ZONEID = 1;

export const addZoneFromGeoJSON = (geojson,{color,mapId}) => {
    return (dispatch,getState) => {

        const state = getState().zoneBuilder;

        const zone = {
            _id: generateObjectId({existingZones:state.zones}),
            name: `ZONE ${ZONEID++}`,
            mapId: mapId,
            shape: geojson,
            color
        };

        dispatch({
            type: types.ADD_ZONE,
            mapId,
            zone
        });
    };
};

function generateObjectId({existingZones}){
    let _id = generateSimpleObjectId();
    while(_id in existingZones){
        _id = generateSimpleObjectId();
    }
    return _id;
}

function generateSimpleObjectId(){
    let s = "";
    const HEX_ARRAY = "0123456789abcdef".split("");
    for(let i = 0; i < 24; i++){
        s += HEX_ARRAY[randInt(HEX_ARRAY.length-1)];
    }
    return s;
}

function randInt(max){
    return Math.floor(Math.random()*(max+1));
}

export const deleteZone = (zone) => {
    return (dispatch) => {
        const zoneId = zone._id;
        dispatch({type:types.DELETE_ZONE, zoneId});
    };
};

export const modifyZone = (zoneId,geojson) => {
    return (dispatch) => {

        dispatch(({
            type:types.UPDATE_ZONE,
            zoneId,
            updates:{
                shape: geojson
            }
        }));

    };
};

const syncActiveZoneWithChanges = (changes) =>{
    return (dispatch,getState) => {
        const state = getState().zoneBuilder;
        const zoneId = state.zoneId;

        dispatch(({
            type:types.UPDATE_ZONE,
            zoneId,
            updates: changes
        }));
    };
};

export const changeZoneProperty = (key,value)=>{
    return (dispatch) => {

        return dispatch(syncActiveZoneWithChanges({
            [key]:value
        }));
    };
};


/*
██ ███    ██ ██ ████████ ██  █████  ██      ██ ███████ ███████
██ ████   ██ ██    ██    ██ ██   ██ ██      ██    ███  ██
██ ██ ██  ██ ██    ██    ██ ███████ ██      ██   ███   █████
██ ██  ██ ██ ██    ██    ██ ██   ██ ██      ██  ███    ██
██ ██   ████ ██    ██    ██ ██   ██ ███████ ██ ███████ ███████
*/


export const initialize = ()=>{
    return dispatch => {
        dispatch(requestSites());
        dispatch(requestMaps());
        dispatch(requestZones());
    };
};
