import React, {useEffect, useImperativeHandle, useRef, useState} from 'react';
import MapXYZ from "../../../../utils/ol/MapXYZ";
import MapToolbar from '../MapToolbar'
import MapThumbnailsContainer from '../MapThumbnailsContainer'
import {Fill, Stroke, Style} from "ol/style";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import {Pointer as PointerInteraction} from "ol/interaction";
import ContextMenu from "ol-contextmenu";
import {clickFeatureRow, getOperationOptions, operateDevice,
        loadModalEditDevice, changeStatusModalEditDevices, cleanSiteAndMapDevice} from "../../../../actions";
import {ZoneVisibility} from "../../../util/map/LocalMap";
import {useSelector, useDispatch} from "react-redux";
import {
    saveDeviceData,
    saveAntennaData,
    // createOverlay,
    // generateAntennasCoordinates,
    initZonesLayer,
    updateZoneLayers,
    loadZoneLayers,
    getAntennaStyle,
    getDeviceStyle,
    loadFeatures
} from './localMapUtils';
import {useHistory} from 'react-router-dom';
import {LANDING_PAGE_SCREEN} from '../../../../constants/DeviceManager';
import {START, STOP, PUBLISH, EDIT, REBOOT, ENABLE, DISABLE, CANCEL, OPE_STATUS_PENDING} from '../../../../constants/DeviceManager';
import Overlay from "ol/Overlay";
import TestingDialog from '../dialogs/TestingProcessDialog';
import DialogEditDevice from '../dialogs/DialogEditDevice';


const DEFAULT_BOUNDS = [0,0,100,100];

const LocalMapContainer = React.forwardRef((props, localMapContainerRef) => {
    const {
        siteId,
        mapId,
        mapDevices,
        mapBounds,
        mapZoom,
        zonesOnMap,
        //parentCallback,
        showDeviceLabels,
        deviceLabelsCallback,
        devicesBatch,
        deviceManagerScreen,
        resizeTrigger,
        orientation,
        idSelected,
        selectItemPanel
    } = props;

    const selectedRows = useSelector(state => Object.keys(state.deviceManager.selectedRows));
    const showModalEditDevice = useSelector(state => state.deviceManager.showModalEditDevice);
    const deviceOptions = useSelector(state => state.deviceManager.deviceOptions);
    const zonesWithAntennas = useSelector(state => state.deviceManager.zonesWithAntennas);

    const [mapConfig, setMapConfig] = useState({});
    const [deviceTest, setDeviceTest] = useState(null);
    const [indexDevice, setIndexDevice] = useState(null);
    const [zoneVisibility, setZoneVisibility] = useState(ZoneVisibility.NO_ZONES);
    const [autoZoneVisibility, setAutoZoneVisibility] = useState(ZoneVisibility.NO_ZONES);

    // const [zones, setZones] = useState([]);
    const mapRef = useRef();
    const sourceRef = useRef();
    const sourceZoneRef = useRef();
    const sourceZoneLabelRef = useRef();

    const contextMenuRef = useRef();
    const overlayRef = useRef();
    const dispatch = useDispatch();
    const featureRef = useRef();
    const coordinateRef = useRef();
    const dragEnabled = useRef();
    const canEdit = useSelector(state => state.user.permissions['edit-infrastructure-devices']);
    const [isEditable, setIsEditable] = useState(true);
    const isOnCloud = useSelector(state => state.deviceManager.isOnCloud);
    const showMapDrawer = useSelector(state => state.report.showMapDrawer);
    let zIndexFeature = 0;
    let cursor_ = useRef();
    cursor_.current = 'pointer';
    let previousCursor_ = useRef();
    const history = useHistory();

    useEffect(() => {
        if(canEdit !== undefined){
            setIsEditable(canEdit);
        }
    }, [canEdit, setIsEditable]);

    const removeListeners = () => {
        document.removeEventListener('pointerup', handleUpEvent);
        document.removeEventListener('pointermove', handleDragEvent);
    };

    const handleAddListeners = () => {
        if(mapRef.current) {
            document.addEventListener('pointerup', handleUpEvent);
            if(deviceManagerScreen !== LANDING_PAGE_SCREEN){
                document.addEventListener('pointermove', handleDragEvent);
            }
        }
        const unmount = () => {
            mapRef.current.setTarget(null);
            mapRef.current = null;
            removeListeners()
        };
        return unmount;
    };

    useEffect(handleAddListeners, []);

    const loadVectorLayer = () => {
        if(mapRef.current){
            
            const vectorSource=new VectorSource({
                updateWhileInteracting: true,
                style: new Style({
                    fill: new Fill({
                        color: 'rgba(0, 0, 0, 0.3)'
                    }),
                    stroke: new Stroke({
                        width: 3,
                        color: 'rgba(0, 100, 240, 0.8)'
                    }),
                    text: "Device"
                })
            });

            const vectorLayer = new VectorLayer({
                zIndex: 1,
                source: vectorSource,
                stroke: new Stroke({
                    width: 3,
                    color: [255, 0, 0, 1]
                }),
                fill: new Fill({
                    color: [0, 0, 255, 0.6]
                })
            });
            mapRef.current.addLayer(vectorLayer);
            sourceRef.current = vectorSource;

            // layer for zones
            const vectorSourceZones= new VectorSource ({
                updateWhileInteracting: true
            });

            const zoneLayer = new VectorLayer({
                source: vectorSourceZones
            });

            mapRef.current.addLayer(zoneLayer);
            sourceZoneRef.current = vectorSourceZones;

            // layer for label's zones
            const vectorSourceLabelZones = new VectorSource ({
                updateWhileInteracting: true
            });

            const labelZoneLayer = new VectorLayer({
                source: vectorSourceLabelZones,
                zIndex: 2,
                renderMode: 'image'
            });

            mapRef.current.addLayer(labelZoneLayer);
            sourceZoneLabelRef.current = vectorSourceLabelZones;

            mapRef.current.addInteraction(new PointerInteraction({
                handleDownEvent: handleDownEvent,
                handleMoveEvent: handleMoveEvent,
                handleUpEvent: handleUpEvent
            }));

            overlayRef.current = new Overlay({
                autoPan: true,
                autoPanAnimation: {
                    duration: 250
                }
            });

            mapRef.current.addOverlay(overlayRef.current);

            /****************ContextMenu functionality************************/


            contextMenuRef.current = new ContextMenu({
                width: 150,
                defaultItems: false
            });
            mapRef.current.addControl(contextMenuRef.current);

            contextMenuRef.current.on('open', async (event) => {
                let feature = await mapRef.current.forEachFeatureAtPixel(event.pixel, feat => feat);
                if(feature && feature.get('type') === 'cluster'){
                    contextMenuRef.current.clear();

                    let removeMarkerItem = getContextMenuArray(feature);

                    contextMenuRef.current.extend(removeMarkerItem);
                }
            });

            contextMenuRef.current.on('beforeopen', function (evt) {
                let feature = mapRef.current.forEachFeatureAtPixel(evt.pixel, function (ft, l) {
                    return ft;
                });

                if (feature && feature.get('type') === 'cluster') { // open only on features
                    contextMenuRef.current.enable();
                } else {
                    contextMenuRef.current.disable();
                }
            });

            /*olMapRef.current = mapRef.current;
            olSourceRef.current = sourceRef.current;*/
        }
    };

    useEffect(loadVectorLayer, []);

    useEffect(() => {
        if(!!mapId && !!mapBounds && !!mapZoom){
            setMapConfig({
                mapId: mapId,
                maxZoom: mapZoom-1,
                bounds: mapBounds || DEFAULT_BOUNDS
            });
        }else{
            setMapConfig({});
        }

    },[mapBounds, mapId, mapZoom]);

    useEffect(() => {
        if(mapId){
            sourceZoneRef.current.clear();
            sourceZoneLabelRef.current.clear();
        }
    }, [mapId]);

    const handleResizeMap = () => {
        mapRef.current.updateSize()
    };

    useEffect(handleResizeMap, [resizeTrigger, orientation]);

    const handleLoadDevices = () => {
        // initing zones
        if(zonesOnMap.length>0){
            // const zonesToRender =  zonesOnMap.filter(zone => zone.layerPriority !== -1 && zone.map === mapId && zone.locationTypeId === 0);
            const zonesToRender =  zonesOnMap.filter(zone => zone.layerPriority !== -1 && zone.map === mapId);
            initZonesLayer(zonesToRender, zoneVisibility, sourceZoneRef,sourceZoneLabelRef);
        }

        //setting visibility of zones and autozones
        loadZoneLayers(zoneVisibility, autoZoneVisibility, sourceZoneRef, sourceZoneLabelRef);
        //cleaning the features
        sourceRef.current.clear();
        //loading the features
        let currentSelectedRows = deviceManagerScreen === LANDING_PAGE_SCREEN ? selectedRows : [];
        loadFeatures(mapRef, sourceRef, devicesBatch, showDeviceLabels, currentSelectedRows, idSelected, deviceOptions);
        updateZoneLayers(devicesBatch, zonesWithAntennas, zonesOnMap, autoZoneVisibility, sourceZoneRef, sourceZoneLabelRef);        
    };
    // useEffect(handleLoadDevices, [selectedRows, showDeviceLabels]);
    useEffect(handleLoadDevices, [devicesBatch, selectedRows, showDeviceLabels, deviceOptions]);

    useImperativeHandle(localMapContainerRef, () => ({

        addDeviceFeature: addDeviceFeature,
        changeCoordinatesFeature: changeCoordinatesFeature

    }));

    const changeCoordinatesFeature = (evt, id, coordinates) => {
        let currentFeature = sourceRef.current.getFeatureById(id);
        // let currentFeature = sourceRef.current.getFeatures().find(feature => feature.getId() === id);

        if(currentFeature){
            let prevCoordinates = currentFeature.getGeometry().getCoordinates();
            let currentCoordinates = [Number(coordinates.x).toFixed(2), Number(coordinates.y).toFixed(2)];
            moveFeature(evt, currentFeature, currentCoordinates, prevCoordinates);
        }
    }

    const moveFeature = (evt, currentFeature, coordinates, prevCoordinates) => {
        let deltaX = coordinates[0] - prevCoordinates[0];
        let deltaY = coordinates[1] - prevCoordinates[1];
        currentFeature.getGeometry().translate(deltaX, deltaY);

        let deviceId = (currentFeature.id_+"_").split("_")[0];
        let dragAllow = deviceId && dragEnabled.current[deviceId] && !dragEnabled.current[deviceId].enableDrag;

        if(currentFeature.get("type") === 'cluster'){
            saveDeviceData(evt, currentFeature.get('indexObject'), currentFeature.getGeometry().getCoordinates(), dispatch, dragAllow);
        }else if(currentFeature.get("type") === 'antenna'){
            saveAntennaData(evt, currentFeature.get('indexObject'), currentFeature.get('indexAntenna'), currentFeature.getGeometry().getCoordinates(), dispatch);
        }
    }


    const selectFeature = () => {
        let featuresArray = sourceRef.current.getFeatures();
        for(let i=0; i<featuresArray.length;i++){
            const feature = featuresArray[i];

            let deviceId = Number.isInteger(feature.id_)? feature.id_ : (feature.id_+"_").split("_")[0];
            let dragAllow = deviceId && deviceOptions && deviceOptions[deviceId] && !deviceOptions[deviceId].enableDrag;
            if(feature.get("type") === 'antenna' && dragAllow){
                let antennaStyle = null;
                let isDragable = feature.get('draggable') && dragAllow;
                let antennaHasAutozone = Boolean(feature.get('autoZone'));
                if(feature.getId() === idSelected){
                    // select an antenna
                    ++zIndexFeature;
                    antennaStyle = getAntennaStyle(showDeviceLabels, feature.get('label'), isDragable, true, antennaHasAutozone, zIndexFeature + 1);
                }else if(feature.getId().indexOf(idSelected) === 0){
                    //select a device with antennas
                    antennaStyle = getAntennaStyle(showDeviceLabels, feature.get('label'), isDragable, true, antennaHasAutozone, zIndexFeature);
                }else{
                    antennaStyle = getAntennaStyle(showDeviceLabels, feature.get('label'), isDragable, false, antennaHasAutozone, 0);
                }
                feature.setStyle(antennaStyle);
            }
    
            if(feature.get("type") === 'cluster'){
                let clusterStyle = null;
                if(feature.getId() === idSelected){
                    ++zIndexFeature;
                    clusterStyle = getDeviceStyle(showDeviceLabels, feature.get('label'), true, zIndexFeature + 2);
                }else{
                    clusterStyle = getDeviceStyle(showDeviceLabels, feature.get('label'), false, zIndexFeature + 1);
                }
                feature.setStyle(clusterStyle);
            }
        }
    }

    useEffect(selectFeature, [idSelected]);

    const onClickFeature = (id, feature) => {
        if(feature.get("type") === 'cluster'){
            dispatch(clickFeatureRow(id, feature.get('deviceObject')));
        }
    }

    const addDeviceFeature = (evt, idDevice) => {
        if(mapRef.current && sourceRef.current ) {
            let coordinate = mapRef.current.getView().getCenter();
            saveDeviceData(evt, idDevice, coordinate, dispatch, false);
        }
    };

    const handleDownEvent = (evt) => {
        if(overlayRef.current){
            overlayRef.current.setPosition(undefined);
        }
        if(contextMenuRef.current){
            contextMenuRef.current.close();
        }

        let feature = mapRef.current.forEachFeatureAtPixel(evt.pixel, (feature) => feature);

        let isDraggableFeature = feature && (feature.get("type") === 'cluster' || feature.get("type") === 'antenna');

        if(isDraggableFeature){
            const id = feature.getId();

            if(id !== null) {
                if(deviceManagerScreen === LANDING_PAGE_SCREEN){
                    feature.set('draggable', true);
                    onClickFeature(id, feature);
                }else{
                    selectItemPanel(id);
                }
            }

            coordinateRef.current = evt.coordinate;
            featureRef.current = feature;
        }

        return isDraggableFeature;
    };

    const handleDragEvent = (evt) => {
        if ( featureRef.current && coordinateRef.current) {
            let deviceId = featureRef.current && (featureRef.current.id_+"_").split("_")[0];
            let dragAllow = deviceId && dragEnabled.current[deviceId] && !dragEnabled.current[deviceId].enableDrag;
            if(featureRef.current.get('type') === 'cluster' || (featureRef.current.get('type') === 'antenna' && dragAllow)) {
                let coordinates = mapRef.current.getEventCoordinate(evt);
                moveFeature(evt, featureRef.current, coordinates, coordinateRef.current);
                coordinateRef.current[0] = coordinates[0];
                coordinateRef.current[1] = coordinates[1];
            }
        }
    };

    const handleMoveEvent = (evt) => {
        if(cursor_.current){
            let feature = mapRef.current.forEachFeatureAtPixel(evt.pixel, (feature) => feature);
            let element = mapRef.current.getTargetElement();
            if(feature && (feature.get("type") === 'cluster' || (feature.get("type") === 'antenna'  ))){
                if(element.style.cursor !== cursor_.current){
                    previousCursor_.current = element.style.cursor;
                    element.style.cursor = cursor_.current;
                }
            } else if(previousCursor_.current !== undefined){
                element.style.cursor = previousCursor_.current;
                previousCursor_.current = undefined;
            }
        }
    };

    const handleUpEvent = (evt) => {
        if(featureRef.current){
            const [coorX, coorY] = mapRef.current.getEventPixel(evt);

            //putting the device in the center in Add Devices screen
            if((coorX < 0 || coorY < 0) && featureRef.current.get("type") === 'cluster'){
                let coordinateCenter = mapRef.current.getView().getCenter();
                let index = featureRef.current.get('indexObject');
                saveDeviceData(evt, index, coordinateCenter, dispatch);
            }
        }
        coordinateRef.current = null;
        featureRef.current = null;
        return false;
    };

    const editDeviceCallback = (obj) => {
        if(deviceManagerScreen === LANDING_PAGE_SCREEN){
            const pathDeviceManager = `/device-manager/devices/edit-dev/${obj.data.indexDevice}`;
            history.push(pathDeviceManager);
        }else{
            setIndexDevice(obj.data.indexDevice);
            dispatch(loadModalEditDevice(obj.data.indexDevice));
        }
    };

    const actionOption = obj => {
        dispatch(operateDevice(obj.data.deviceObject, obj.data.action));
    }

    const removeDeviceFromMap = obj => {
        dispatch(cleanSiteAndMapDevice(obj.data.indexDevice));
    }

    /*
    //line commented by ticket MWE-4463
    const testOption = obj => {
        let object = {_id: obj.data._id, title: obj.data.title};
        setDeviceTest(object);
    }*/

    const getContextMenuArray = (feature) => {
        const deviceObject = feature.get('deviceObject');
        const options = getOperationOptions(deviceObject,isOnCloud);

        if (!isEditable) {
            Object.keys(options).forEach(key => {
                options[key] = false;
            });
            options.viewDetails = true;
        }

        let contextMenuItems = [
            {
                text: 'Edit',
                classname: options[EDIT]?'marker':'marker-disabled',
                callback: editDeviceCallback,
                data: {deviceObject: deviceObject, indexDevice: feature.get('indexObject')}
            },
            {
                text: 'Publish',
                classname: options[PUBLISH]?'marker':'marker-disabled',
                callback : actionOption,
                data: {action: PUBLISH, deviceObject: deviceObject}
            },
            /*{
                text: 'Test',
                classname: options[TEST]?'marker':'marker-disabled',
                callback : testOption,
                data: deviceObject
            },*/
            '-',
            {
                text: 'Start',
                classname: options[START]?'marker':'marker-disabled',
                callback : actionOption,
                data: {action: START, deviceObject: deviceObject}
            },
            {
                text: 'Stop',
                classname: options[STOP]?'marker':'marker-disabled',
                callback : actionOption,
                data: {action: STOP, deviceObject: deviceObject}
            },
            {
                text: 'Reboot',
                classname: options[REBOOT]?'marker':'marker-disabled',
                callback : actionOption,
                data: {action: REBOOT, deviceObject: deviceObject}
            },
            '-',
            {
                text: 'Enabled',
                classname: options[ENABLE]?'marker':'marker-disabled',
                callback : actionOption,
                data: {action: ENABLE, deviceObject: deviceObject}
            },
            {
                text: 'Disabled',
                classname: options[DISABLE]?'marker':'marker-disabled',
                callback : actionOption,
                data: {action: DISABLE, deviceObject: deviceObject}
            },
            {
                text: 'Remove from map',
                classname: 'marker',
                callback : removeDeviceFromMap,
                data: {indexDevice: feature.get('indexObject')}
            }
            // {
            //     text: 'Delete',
            //     classname: options[DELETE]?'marker':'marker-disabled',
            //     callback : actionOption,
            //     data: {action: DELETE, deviceObject: deviceObject}
            // }
        ];

        if(deviceObject.operation_status?.current_status === OPE_STATUS_PENDING){
            let cancelItem = {
                text: 'Cancel',
                classname: 'marker',
                callback : actionOption,
                data: {action: CANCEL, deviceObject: deviceObject}
            }

            contextMenuItems.push(cancelItem);
        }
        return contextMenuItems; 
    };

    const handleFinishLoadMap=(mapId)=>{
        if (mapId) {
            mapRef.current.updateSize();
        }
    };

    const handleEnableDrag = () => {
        dragEnabled.current = {...deviceOptions};
    };

    useEffect(handleEnableDrag, [deviceOptions]);

    const toggleZoneVisibility = (type) => {
        const {NO_ZONES,ZONES_ONLY,ZONES_AND_LABELS} = ZoneVisibility;
        let vis = NO_ZONES;
        let valueZone = type === 'zones' ? zoneVisibility: autoZoneVisibility;
        switch(valueZone){
            case NO_ZONES:
                vis = ZONES_ONLY;
                break;
            case ZONES_ONLY:
                vis = ZONES_AND_LABELS;
                break;
            case ZONES_AND_LABELS:
            default:
                vis = NO_ZONES;
                break;
        }

        if(type === 'zones'){
            setZoneVisibility(vis);
        }else{
            setAutoZoneVisibility(vis);
        }
    };

    return (
        <>
            {showMapDrawer ? <MapThumbnailsContainer siteId={siteId} mapSelected={mapId} maps={mapDevices} /> : null}
            <MapToolbar
                map={mapRef.current}
                bounds={mapConfig.bounds}
                zoneVisibility={zoneVisibility}
                autoZoneVisibility={autoZoneVisibility}
                toggleZoneVisibility={toggleZoneVisibility}
                showDeviceLabels={showDeviceLabels}
                //showThumbnails={showMapDrawer}
                //parentCallback={parentCallback}
                deviceLabelsCallback={deviceLabelsCallback}
                deviceManagerScreen={deviceManagerScreen}
            />
            <div className={'margin-coordinates'} id={'margin-coordinates'}>
                <MapXYZ
                    ref={mapRef}
                    maxZoom={mapConfig.maxZoom}
                    bounds={mapConfig.bounds}
                    mapDeviceId={mapConfig.mapId}
                    onFinishLoadMap={handleFinishLoadMap}
                />
            </div>

            {deviceTest && <TestingDialog open={Boolean(deviceTest)} closeDialog={()=>setDeviceTest(null)} deviceName={deviceTest.title} deviceId={deviceTest._id}/>}
            {showModalEditDevice && <DialogEditDevice indexDevice={indexDevice} open={showModalEditDevice} closeDialog={()=>dispatch(changeStatusModalEditDevices(false))} />}
        </>
    );
});

export default LocalMapContainer;