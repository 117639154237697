import React, { useState, useEffect } from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Button} from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { NavigateBefore as BackArrow} from '@material-ui/icons';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useSelector, useDispatch } from "react-redux";
import {
    loadDeviceManagerScreen,
    changeOperationCheck,
    initValidatedFields,
    cleanFormTemplate,
    detectSaveChanges,
    deviceActionForm,
    editDevice,
    addTemplateSaveDevice,
    updateOnlyDevice,
    updateDeviceAndTemplate,
    cleanDialogNotification,
    startPollingOperationStatus, stopPollingOperationStatus,
    verifyUpdateTemplate, validateFieldsDevice, disableNotification,
    getOperationOptions,cleanMessagesDevicesBatch
} from "../../../actions";
import DeviceManagerToolbar from '../toolbars/DeviceManagerToolbar';
import FetchScreen from '../common/FetchScreen';
import TemplateSidebar from './TemplateSidebar';
import Accordion from '../common/Accordion';
import EditDeviceHeader from '../common/accordionItems/EditDeviceHeader';
import ConfigurationTemplate from '../common/accordionItems/ConfigurationTemplate';
import IdentityPanel from '../common/accordionItems/IdentityPanel';
import OperationPanel from '../common/accordionItems/OperationPanel';
import AntennasPanel from '../common/accordionItems/AntennasPanel';
import TriggersPanel from '../common/accordionItems/TriggersPanel';
import GPOPanel from '../common/accordionItems/GPOPanel';
// import DataUrlPanel from '../common/accordionItems/DataUrlPanel';
// import ErrorValidation from '../common/accordionItems/ErrorValidation';
import OperationNotification from '../common/accordionItems/OperationNotification';
import ProtocolPanel from "../common/accordionItems/ProtocolPanel";

import DialogSaveChanges from '../common/dialogs/DialogSaveChanges';
import DialogAddTemplate from '../common/dialogs/DialogAddTemplate';
import DialogSaveDevices from '../common/dialogs/DialogSaveDevices';
import TestingProcessDialog from '../common/dialogs/TestingProcessDialog';
import StopDeviceDialog from '../common/dialogs/StopDeviceDialog';
import DialogConfirm from '../common/dialogs/DialogConfirm';

import {PATH_EDIT_DEVICE, EDIT_DEVICES_SCREEN} from '../../../constants/DeviceManager';

import {EDIT_DEVICE_CONFIG, EDIT_DEVICE_MANUALLY_ADDED, EDIT_DEVICE_SAVED, PUBLISH, START, STOP, REBOOT, ENABLE, DISABLE} from '../../../constants/DeviceManager';
import {CONF_STATE_SAVED, CONF_STATE_PUBLISHED, CONF_STATE_TESTED, OPE_STATUS_RUNNING, OPE_STATUS_STOPPED, OPE_STATUS_DISABLED} from '../../../constants/DeviceManager';
import {CONF_R2C} from '../../../constants/DeviceManager';


const EditDevice = () => {
    const templateFormLoaded = useSelector(state => state.deviceManager.templateFormLoaded); //only CONFIG or SAVED
    const rfidConfigurationType = useSelector(state => state.deviceManager.rfidConfigurationType);
    const template = useSelector(state => state.deviceManager.templateForm.template);
    const successful = useSelector(state => state.deviceManager.successfulValidatedFields);
    const dialogNotification = useSelector(state => state.deviceManager.dialogNotification);
    const isFetching = useSelector(state => state.deviceManager.isFetching);
    const focusedSection = useSelector(state => state.deviceManager.focusedSection);
    const canEdit = useSelector(state => state.user.permissions['edit-infrastructure-devices']);
    const history = useHistory();
    const dispatch = useDispatch();
    const {pathParam} = useParams();
    const pathDeviceManager = '/device-manager/devices';
    const [operation, setOperation] = useState(null);
    const [save, setSave] = useState(null);

    const scrollView = id => {
        var elmntToView = document.getElementById(id);
        if (elmntToView)
            elmntToView.scrollIntoView({behavior: 'smooth'});
    }

    useEffect(() => {
        if(focusedSection){
            scrollView(focusedSection);
        }
    }, [focusedSection]);
    
    const loadDevice = () => {
        //mounting
        dispatch(editDevice(pathParam));
        dispatch(loadDeviceManagerScreen(EDIT_DEVICES_SCREEN));
        dispatch(startPollingOperationStatus(pathParam));        
        const unmount = () => {
            //unmounting
            dispatch(stopPollingOperationStatus());
            dispatch(changeOperationCheck(EDIT_DEVICES_SCREEN, false));
            dispatch(cleanFormTemplate());
            dispatch(initValidatedFields());
            dispatch(cleanDialogNotification());
            dispatch(cleanMessagesDevicesBatch());
        }
        return unmount;
    }

    useEffect(loadDevice, []);

    useEffect(() => {
        if(canEdit !== undefined && canEdit === false){
            history.replace('/device-manager/devices');
        }
    }, [canEdit, history]);

    // operate device
    const operateDevice = () => {
        switch(operation){
            case 'publish-device':
                dispatch(deviceActionForm(PUBLISH));
            break;
            case 'start-device':
                dispatch(deviceActionForm(START));
            break;
            case 'enable-device':
                dispatch(deviceActionForm(ENABLE));
            break;
            default:
            break;
        }
        scrollView('operationNotification');
    }

    useEffect(operateDevice, [operation, dispatch]);

    // save device
    const saveDevice = () => {
        if(successful && save){
            switch(save){
                case 'save-configuration':
                    handleUpdateDeviceAndTemplate();
                break;
                default:
                break;
            }
        }

        if(!successful && save){
            // scrollView('errorValidation');
            setSave(null);
        }
    }

    useEffect(saveDevice, [successful, save]);

    // validating the form, in case to save device or template

    const handleSave = (type) => {
        dispatch(verifyUpdateTemplate())
        dispatch(validateFieldsDevice());
        setSave(type);
        // setWasChecked(true);
    }

    //save device and/or template


    const handleUpdateOnlyDevice = () => {
        //save the changes in backend
        dispatch(updateOnlyDevice());
        //closing dialog save dialog
        setSave(null);
    }

    const handleUpdateDeviceAndTemplate = () => {
        //save the changes in backend
        dispatch(updateDeviceAndTemplate());
        //closing dialog save dialog
        setSave(null);
    }

    const handleAddNewTemplate = () => {
        dispatch(disableNotification());
        dispatch(addTemplateSaveDevice(null,setSave));
        setSave(null);
    }


    const handleReturnBack = () => {
        dispatch(cleanFormTemplate());
        dispatch(initValidatedFields());
        dispatch(detectSaveChanges(false));
        history.push(pathDeviceManager);
    }

    return (
        <React.Fragment>
            {isFetching ? <FetchScreen />: null}
            <div className={"device-manager-container"}>
                <div className={"device-manager-header"}>
                    <DeviceManagerToolbar location={PATH_EDIT_DEVICE} />
                </div>
                <div className={'device-manager-content'}>
                    <div style={{display: 'flex'}} className={"device-manager-main-container"}>
                        <div className={"template-sidebar"}>
                            <TemplateSidebar scrollView={(e) => scrollView(e)} deviceId={'EditDevice'}/>
                        </div>
                        <div className={"device-container"} id={'mainContainer'} style={{paddingTop: 0, overflow: 'hidden'}}>
                            {dialogNotification && <OperationNotification duration={6000} />}
                            {templateFormLoaded && <React.Fragment>
                                {templateFormLoaded === EDIT_DEVICE_CONFIG ? <ConfigurationTemplate />:<EditDeviceHeader />}
                            </React.Fragment>}
                            {templateFormLoaded && templateFormLoaded.startsWith('EDIT_DEVICE') && 
                            <div className="device-main-content">
                                <Accordion displayName={"Identity"} id={"identity"}>
                                    <IdentityPanel />
                                </Accordion>
                                <Accordion displayName={"Protocol"} id={"protocol"}>
                                    <ProtocolPanel />
                                </Accordion>
                                {/*<Accordion displayName={"Tag Data URL"} id={"Data URL"}>
                                    <DataUrlPanel />
                                </Accordion>*/}

                                <Accordion displayName={"Operation"} id={"operation"}> 
                                    <OperationPanel />
                                </Accordion>

                                <Accordion displayName={"Antennas"} id={"rfidAntennas"}  open={template?.rfidType?.indexOf('ATR') < 0}>
                                    <AntennasPanel isTemplate={false} />
                                </Accordion>

                                {rfidConfigurationType !== CONF_R2C && <React.Fragment>
                                    <Accordion displayName={"Trigger settings"} id={"triggers"}> 
                                        <TriggersPanel />
                                    </Accordion>

                                    <Accordion displayName={"GPO (General Purpose Output) Programming"} id={"gpo"}> 
                                        <GPOPanel />
                                    </Accordion>
                                </React.Fragment>}
                            </div>}
                        </div>
                    </div>
                    <div className="edit-bottom-popper">
                        <div className="edit-bottom-popper-content">
                            <ContainerButtonsDialog templateFormLoaded={templateFormLoaded} setOperation={setOperation} handleSave={handleSave} handleReturnBack={handleReturnBack} />
                        </div>
                    </div>
                </div>
            </div>

            {(successful && save==='save-device') &&
                <DialogSaveDevices 
                    openDialog={Boolean(save)}
                    actionLabel={'save'}
                    closeDialog={() => setSave(null)}
                    updateOnlyDevice={handleUpdateOnlyDevice}
                    updateDeviceAndTemplate={handleUpdateDeviceAndTemplate} />}

            {(successful && save==='new-template') &&
                <DialogAddTemplate
                    openDialog={Boolean(save)}
                    closeDialog={() => setSave(null)}
                    actionSuccess={handleAddNewTemplate} />}

            {(successful && save==='edit-template') &&
                <DialogSaveChanges
                    openDialog={Boolean(save)}
                    closeDialog={() => setSave(null)}
                    actionSuccess={handleUpdateDeviceAndTemplate} />}
                    
            {operation==='test-device' &&
                <TestingProcessDialog
                        open={Boolean(operation)} 
                        closeDialog={() => setOperation(null)}/>}

            {operation==='stop-device' &&
                <StopDeviceDialog
                        open={Boolean(operation)}
                        type={'stop'}
                        closeDialog={() => setOperation(null)}
                        actionSuccess={() => dispatch(deviceActionForm(STOP))} />}

            {operation==='reboot-device' &&
                <StopDeviceDialog
                        open={Boolean(operation)}
                        type={'reboot'}
                        closeDialog={() => setOperation(null)}
                        actionSuccess={() => dispatch(deviceActionForm(REBOOT))} />}

            {operation==='disable-device' &&
                <StopDeviceDialog
                        open={Boolean(operation)}
                        type={'disable'}
                        closeDialog={() => setOperation(null)}
                        actionSuccess={() => dispatch(deviceActionForm(DISABLE))} />}
        </React.Fragment>
    )
}

export default EditDevice;

export const ContainerButtonsDialog = (props) => {
    const {templateFormLoaded, setOperation, handleSave, handleReturnBack} = props;
    const templateId = useSelector(state => (state.deviceManager.templateForm.template) ? state.deviceManager.templateForm.template.templateId: null);
    const configuration_state = useSelector(state => (state.deviceManager.templateForm.template) ? state.deviceManager.templateForm.template.configuration_state: null);
    const deviceExtraFields = useSelector(state => (state.deviceManager.templateForm.template) ? 
                {status: state.deviceManager.templateForm.template.status, operation_status: state.deviceManager.templateForm.template.operation_status}: {});
    const isOnCloud = useSelector(state => state.deviceManager.isOnCloud);
    const options = getOperationOptions(deviceExtraFields, isOnCloud);    
    const isDeviceChanging = useSelector(state => state.deviceManager.isDeviceChanging);
    const showModalEditDevice = useSelector(state => state.deviceManager.showModalEditDevice);

    const [containerOptions, setContainerOptions] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const backStyle = {backgroundColor:'#fff',color:'#007CB0',marginRight:'1em', textTransform: 'none'};
    const successStyle = {backgroundColor: '#007CB0', color: '#fff', marginRight: '1em', padding: '0 14px', fontWeight: 'bolder', textTransform: 'none'};
    const disabledSuccessStyle = {backgroundColor: '#007CB0', color: '#fff', opacity: 0.4, marginRight: '1em', padding: '0 14px', fontWeight: 'bolder', textTransform: 'none'};
    const optionsStyle = {padding:0, border:'1px solid #000',minWidth:'30px'};    
    const itemOptionFixed = {padding: '0.25em', textAlign: 'end', cursor: 'pointer'};
    const saveTemplateStyle = (templateId && templateId !== '')?{...itemOptionFixed}:{...itemOptionFixed, pointerEvents:'none', opacity: '0.4'};

    const handleBackButton = () => {
        if(isDeviceChanging){
            setShowMessage(true);
        }else{
            handleReturnBack();
        }
    }

    const renderLeftButtons = () => {
        return (
            <React.Fragment>
                {!showModalEditDevice ? <Button style={backStyle} onClick={handleBackButton} startIcon={<BackArrow />}>Back</Button>:
                <Button style={backStyle} onClick={handleBackButton}>Cancel</Button>}
            </React.Fragment>
        )
    }

    const renderRightButtons = () => {
        switch(templateFormLoaded){
            case EDIT_DEVICE_MANUALLY_ADDED:
                return (
                    <React.Fragment>
                        {isDeviceChanging?<Button style={successStyle} onClick={() => handleSave('save-new-device')}>Save</Button>:
                        <Button style={successStyle} onClick={handleReturnBack}>Close</Button>}
                    </React.Fragment>
                );
            case EDIT_DEVICE_CONFIG:
                return (
                    <Button style={successStyle} onClick={() => handleSave('save-configuration')}>Save</Button>
                );
            case EDIT_DEVICE_SAVED:
                return (
                    <React.Fragment>
                        <Button style={successStyle} onClick={() => handleSave('save-device')}>Save</Button>
                        {renderOperationButton()}

                        {containerOptions &&
                        <ClickAwayListener onClickAway={()=>setContainerOptions(false)}>
                            <div className="container-operation-options">
                                {deviceExtraFields.status && deviceExtraFields.status.title !== OPE_STATUS_STOPPED && 
                                <React.Fragment>
                                    <div style={itemOptionFixed} onClick={e=>handleSave('new-template')}>Save and Create New Template</div>
                                    <div style={saveTemplateStyle} onClick={e=>handleSave('edit-template')}>Save and Update Template</div>
                                </React.Fragment>}
                                {deviceExtraFields.status && deviceExtraFields.status.title === OPE_STATUS_RUNNING && 
                                <React.Fragment>
                                    <div className={options[STOP]?'operation-option':'operation-option-disabled'} onClick={e=>setOperation('stop-device')}>Stop</div>
                                    <div className={options[REBOOT]?'operation-option':'operation-option-disabled'} onClick={e=>setOperation('reboot-device')}>Reboot</div>
                                    <div className={options[DISABLE]?'operation-option':'operation-option-disabled'} onClick={e=>setOperation('disable-device')}>Disable</div>
                                </React.Fragment>}
                                {deviceExtraFields.status && deviceExtraFields.status.title === OPE_STATUS_STOPPED && 
                                <React.Fragment>
                                    <div className={options[REBOOT]?'operation-option':'operation-option-disabled'} onClick={e=>setOperation('reboot-device')}>Reboot</div>
                                    <div className={options[DISABLE]?'operation-option':'operation-option-disabled'} onClick={e=>setOperation('disable-device')}>Disable</div>
                                </React.Fragment>}
                            </div>
                        </ClickAwayListener>}
                    </React.Fragment>
                );
            default: 
                return null;
        }
    }
    const rederOptionButton=()=>{
        if(deviceExtraFields.status &&deviceExtraFields.status.title!==OPE_STATUS_DISABLED && templateFormLoaded !== EDIT_DEVICE_MANUALLY_ADDED){
            return  (<Button style={optionsStyle} onClick={()=>setContainerOptions(!containerOptions)}>
                        <MoreVertIcon style={{color: '#000',width:'1em'}} />
                    </Button>);
        }
    };

    const renderOperationButton = () => {
        if(configuration_state===CONF_STATE_SAVED || configuration_state===CONF_STATE_PUBLISHED){
            return <Button style={(options[PUBLISH] && !isDeviceChanging)?successStyle:disabledSuccessStyle} disabled={!options[PUBLISH] || isDeviceChanging}
                    onClick={e=>setOperation('publish-device')}>Publish</Button>
        }
        
        if(deviceExtraFields.status && (configuration_state===CONF_STATE_PUBLISHED || configuration_state===CONF_STATE_TESTED)){
            switch(deviceExtraFields.status.title){
                case OPE_STATUS_STOPPED:
                    return <Button style={options[START]?successStyle:disabledSuccessStyle} disabled={!options[START]}
                    onClick={e=>setOperation('start-device')}>Start</Button>;
                case OPE_STATUS_DISABLED:
                    return <Button style={options[ENABLE]?successStyle:disabledSuccessStyle} disabled={!options[ENABLE]}
                     onClick={e=>setOperation('enable-device')}>Enable</Button>;
                default:
                    return null;
            }
        }
    }

    return (
        <React.Fragment>
            <div className='container-buttons'>
                <div>{renderLeftButtons()}</div>
                <div>{renderRightButtons()}</div>
                <div>{rederOptionButton()}</div>
            </div>
            {showMessage && <DialogConfirm 
                open={showMessage}
                closeDialog={() => setShowMessage(false)}
                actionSuccess={handleReturnBack}
                actionCancel={()=>{}}
                message={'There are changes not saved. Are you sure to leave this page?'}
            />}
        </React.Fragment>
    )
}