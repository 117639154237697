import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
//import FilterMenu from './FilterMenu';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import {OPTION_ALL_TEXT, OPTION_ALL_WILDCARD} from "../../../../constants/Misc";
import {getFilterValue, isAllOption, isAllWrite} from "./util";
import {generateComponentId} from "../../../../utils/ComponentUtils";
import {SuggestionsPopover} from "./Filter";
import FilterMobile from './FilterMobile';

const circularProgress = ( <IconButton style={{float: "right", padding: 0, height:'auto', width: 'auto', top: '6px', left: '-7px', position: "relative"}}>
    <CircularProgress thickness={2} size={14} style={{color: "#00779f"}} />
</IconButton> );


const getSuggestionValue = suggestion => suggestion;

const renderSuggestion = suggestion => {
    return (
        <span>
            {suggestion}
        </span>
    );
};


class InputSuggestion extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            inputValue:null,
            values:[circularProgress],
            suggestions: [],
            focused: false,
            anchorEl: null,
            searches: []
        };

        this.onKeyDown = this.onKeyDown.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onChangeInputValue = this.onChangeInputValue.bind(this);
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
        this.associateAnchor = this.associateAnchor.bind(this);
        this.renderInputComponent = this.renderInputComponent.bind(this);
        this.renderSuggestionsContainer = this.renderSuggestionsContainer.bind(this);
        this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
        //adding methods for multiple sections
        this.renderSectionTitle = this.renderSectionTitle.bind(this);
        this.getSectionSuggestions = this.getSectionSuggestions.bind(this);
    }

    static get propTypes(){
        return {
            value: PropTypes.any,
            values: PropTypes.array,
            onFocus: PropTypes.func.isRequired,
            isAllAllowed: PropTypes.bool,
            filter: PropTypes.object,
            onChangeValue:PropTypes.func
        };
    }

    static get defaultProps(){
        return {
            isAllAllowed: false
        };
    }

    componentDidMount(){
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        this.setState({
            inputValue:null,
            values:nextProps.values || this.state.values,
            searches:nextProps.searches || this.state.searches,
            suggestions: this.getSuggestionsFromValues(nextProps,nextProps.values)
        });
    }

    changeValue(newValue){
        //const value=(newValue===OPTION_ALL_TEXT)?OPTION_ALL_WILDCARD:newValue;
        const value=(isAllWrite(newValue))?OPTION_ALL_WILDCARD:newValue;
        this.props.onChangeValue(value);
    }

    onKeyDown(event){
        if(event.key === 'Enter')
        {
            this.changeValue(event.target.value);
            this.setState({inputValue:null});
        }
    }

    onFocus(){
        this.props.onFocus();
        if(this.props.isMobileDevice){
            this.props.onRequestDialog();
            this.setState({
                inputValue: null,
                focused: false,
            });
        }else{
            this.setState({
                focused: true
            });
        }      
    }

    onBlur(event) {
        if (this.state.inputValue !== null && event && event.target)
            this.changeValue(event.target.value);

        this.setState({
            inputValue: null,
            focused: false,
        });
    }

    onChangeInputValue(event, { newValue }){
        this.setState({inputValue:newValue});
    }

    onSuggestionsFetchRequested({value}){
        const {values} = this.state;
        let suggestions = this.getSuggestionsFromValues(this.props,values);
        this.setState({
            suggestions
        });
    }

    getSuggestionsFromValues(props,values){
        if(props.isAllAllowed===true)
            return values[0] !== OPTION_ALL_TEXT ? [OPTION_ALL_TEXT].concat(values) : [OPTION_ALL_TEXT].concat([circularProgress]);
         else
            return values[0] !== OPTION_ALL_TEXT ? values : [circularProgress];
    }

    onSuggestionsClearRequested(){}

    associateAnchor(ref){
        this.setState({
            anchorEl: ref
        });
    }


    renderInputComponent(inputProps){
        const {filter, isAllAllowed} = this.props;
        const textInputStyles = {
            paddingLeft:'3.6em',
            margin:'0'
        };
        let value=(isAllOption(inputProps.value))?OPTION_ALL_TEXT:inputProps.value;
        if (value === OPTION_ALL_TEXT && !isAllAllowed) {
            value = '';
        }

        return (
            <div ref={this.associateAnchor} style={{width: 'calc(100% - 28px)',position:'relative'}}>
                {this.props.additionalLabel}
                <input id={generateComponentId(filter.displayName, null, "text")} {...inputProps} value={value} style={textInputStyles} />
            </div>
        );
    }

    
    renderSuggestionsContainer({containerProps,children}){
        let anchor = this.state.anchorEl;
        return (
            <div {...containerProps} style={{maxHeight:220}}>
                <SuggestionsPopover anchorEl={anchor} width={anchor && anchor.offsetWidth}>
                    {children}
                </SuggestionsPopover>
            </div>
        );
    }

    onSuggestionSelected(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }){
        //this hack. Better way to do it to pass this in props itself;
        if(method === "enter"){
            this.setState({inputValue:null});
            // this.props.onChangeValue();
        }
        this.changeValue(suggestionValue);
    }

    renderSectionTitle(section) {
        if(section.suggestions.length > 0){
            return (
                <strong style={{fontSize:'10px'}}>{section.title}</strong>
            );
        }
    }

    getSectionSuggestions(section){
        return section.suggestions;
    }

    
    render() {

        const {suggestions,inputValue,searches} = this.state;
        const {value,isMobileDevice} = this.props;
        const inputProps = {
            value: (inputValue===null)?String(value):inputValue,
            onChange: this.onChangeInputValue,
            onBlur: this.onBlur,
            onKeyDown: this.onKeyDown,
            onFocus: this.onFocus,
            readOnly: isMobileDevice
        };

        let searchesfixed = searches.map(function(item) { return item === '%' ? 'All' : item; });
        let suggestions_sections = [{'title':'Recent Searches','suggestions':searchesfixed},
                            {'title':'Options','suggestions':suggestions}];
        return (
            <Autosuggest
                multiSection={true}
                focusInputOnSuggestionClick={false}
                suggestions={suggestions_sections}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                shouldRenderSuggestions={()=>true}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                renderInputComponent={this.renderInputComponent}
                renderSuggestionsContainer={this.renderSuggestionsContainer}
                onSuggestionSelected={this.onSuggestionSelected}
                inputProps={inputProps}
                renderSectionTitle={this.renderSectionTitle}
                getSectionSuggestions={this.getSectionSuggestions}
            />
        );
    }
}


class Filter extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            options:[],
            dialogOpen:false
        };
        this.handleChangeValue = this.handleChangeValue.bind(this);
        this.onRequestDialog = this.onRequestDialog.bind(this);
        this.onRequestClose = this.onRequestClose.bind(this);
    }

    static get propTypes(){
        return {
            isMobile:PropTypes.bool,
            isMobileDevice:PropTypes.bool,
            filter: PropTypes.object,
            actions: PropTypes.shape({
                requestFilterValues: PropTypes.func.isRequired,
                changeFilterValue: PropTypes.func.isRequired,
                //upgradeReact
                //changeFilterValue: <PropTypes className="func isRequired"></PropTypes>
            }).isRequired
        };
    }

    static get defaultProps(){
        return {
            filter:{}
        };
    }

    getValue(props){
        const {filter} = props;
        return ((isAllOption(filter.value)) ? OPTION_ALL_TEXT : filter.value) || "";
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        const _value = this.getValue(nextProps);
        if(this.props === nextProps) return; // shouldn't get here...
        if(_value !== this.getValue(this.props)){
            this.setState({
                value:_value
            });
        }
    }

    shouldComponentUpdate(nextProps,nextState){
        return (this.props.filter !== nextProps.filter || this.state.value !== nextState.value || this.state.options !== nextState.options || this.state.dialogOpen !== nextState.dialogOpen);
    }

    requestFilterValues(){
        const {filter} = this.props;        
        if(!filter.hasOwnProperty('searches')){
            filter['searches'] = [];
        }        
        this.props.actions.requestFilterValues(filter.id)
        .then(([options])=>{
            this.setState({
                options
            });
        });
    }

    onRequestDialog(){
        this.setState({dialogOpen:true});
    }

    onRequestClose(){
        this.setState({dialogOpen:false});
    }

    handleChangeValue(value){
        if(value !== ''){
            this.props.actions.changeFilterValue(this.props.filter.id,value);
        }
    }

    render(){
        const {filter, isMobileDevice} = this.props;
        const {options, dialogOpen} = this.state;

        var label = filter.name.match(/min|max/)[0];

        const additionalLabel = (
            <div style={{
                position:'absolute',
                left:'0.5em',
                top:0,
                bottom:0,
            }}>
                <div style={{
                    position:'relative',
                    top:'50%',
                    transform:'translateY(-50%)',
                    background:'rgba(0,36,77,0.5)',
                    color:'white',
                    padding:'0.25em 0.5em',
                    borderRadius:'0.25em',
                    fontSize:'0.9em',
                    textTransform:'uppercase'
                }}>
                    {label}
                </div>
            </div>
        );


        const value=getFilterValue(filter);

        return (
            <div style={{paddingTop:'3px',paddingBottom:'3px',paddingLeft:'0px', paddingRight:'8px'}}>
                <InputSuggestion
                    value={value}
                    values={options}
                    onFocus={()=>{this.requestFilterValues();}}
                    searches={this.props.filter.searches}
                    isAllAllowed={this.props.filter.isAllAllowed}
                    filter={filter}
                    onChangeValue={this.handleChangeValue}
                    additionalLabel={additionalLabel}
                    isMobileDevice={isMobileDevice}
                    onRequestDialog={this.onRequestDialog}
                />

                {isMobileDevice && <FilterMobile
                    value={value} 
                    open={dialogOpen}
                    onRequestClose={this.onRequestClose}
                    values={options}
                    title={filter.displayName}
                    onChangeValue={this.handleChangeValue}
                    />}
            </div>
        );
    }
}

export default Filter;
