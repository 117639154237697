

export const getLatLonDistance = (startLat, startLong, endLat, endLong) => {
    const R = 6371.0710; // Radius of the earth in km
    let lat1 = deg2rad(startLat);
    let long1 = deg2rad(startLong);
    let lat2 = deg2rad(endLat);
    let long2 = deg2rad(endLong);
    const dLat = lat2-lat1;  // deg2rad below
    const dLon = long2-long1;

    const a =
        Math.pow(Math.sin(dLat/2),2) +
        Math.cos(lat1) * Math.cos(lat2) *
        Math.pow(Math.sin(dLon/2), 2)
    ;

    const c = 2 * Math.asin(Math.sqrt(a));
    const distance = (R * c) * 3280.84; // Distance in feet
    return distance;
};

const deg2rad = (deg) => {
    return deg * (Math.PI/180)
};

export const validateLatsAndLons = (...args) => {
    return args.every(([lat, lon]) => {
        if (
            !isNaN(+lat) && !isNaN(+lon) &&
            -90 <= lat && lat <= 90 &&
            -180 <= lon && lon <= 180
        ) {
            return true;
        }
        return false;
    });
}

const EQUATORIAL_RADIUS = 5280. * 3963.189;
const POLAR_RADIUS = 5280. * 3949.901;

const radiusAtLatitude = (latitude) => {
    let Esq = Math.pow(EQUATORIAL_RADIUS, 2);
    let Psq = Math.pow(POLAR_RADIUS, 2);
    let cosLat = Math.cos(Math.PI * latitude / 180.0);

    return (EQUATORIAL_RADIUS * POLAR_RADIUS) / Math.sqrt(Esq - (Esq - Psq) * Math.pow(cosLat, 2));
};

const calculateFeetPerDegreeLatitude = (latitude) => {
    const radiusLat = radiusAtLatitude(latitude);
    return 2 * Math.PI * radiusLat / 360.0;
};

const calculateFeetPerDegreeLongitude = (latitude) => {
    const radiusLong = EQUATORIAL_RADIUS;
    const degreeAtEquator = 2 * Math.PI * radiusLong / 360.0;
    return Math.cos(Math.abs(latitude) * Math.PI / 180.0) * degreeAtEquator;
};

export const calculateLatLongDistance = (startLat, startLong, endLat, endLong) => {
    const feetPerDegreeLatitude = calculateFeetPerDegreeLatitude(startLat);
    const feetPerDegreeLongitude = calculateFeetPerDegreeLongitude(startLat);
    const longDiffFeet = (endLong - startLong) * feetPerDegreeLongitude;
    const latDiffFeet = (endLat - startLat) * feetPerDegreeLatitude;

    return Math.sqrt(Math.pow(longDiffFeet, 2) + Math.pow(latDiffFeet, 2))
};