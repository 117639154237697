import React from 'react';
import {useSelector} from "react-redux";
import Button from "@material-ui/core/Button";
import LinkedDevicesPanel from '../accordionItems/LinkedDevicesPanel';
import Dialog from '@material-ui/core/Dialog';
import {DialogActions, DialogTitle} from "@material-ui/core";
import {DialogContent} from '@material-ui/core/es';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/Icon';
import InfoIcon from '@material-ui/icons/Info';

const MESSAGE_DIALOG = "Are you sure to delete this template?";
const MESSAGE_CONTENT = "The following devices will be dissociated";

const DialogTemplateDelete = (props) => {
    const linkedDevices = useSelector(state => state.deviceManager.linkedDevices);

    const actionButtons = (
        <div className='dialog-buttons'>
            <Button className={'alert-confirm-button'} onClick={()=>props.onDelete(props.templateId)} style={{left: 8}}>Delete</Button>
            <Button className={'alert-close-button'} onClick={props.onClose} style={{left: 8}}>Close</Button>
        </div>
    );

    const contentData = () => {
        return (<div>
            <div style={{display: 'flex', margin: '10px 5px', color: '#2891bc', fontStyle: 'italic'}}><InfoIcon/>{MESSAGE_CONTENT}</div>
            <LinkedDevicesPanel showWarning={false} checkColumn={false} />
        </div>)
    };

    const renderCloseButton = () => {
        return (
            <div style={{position:'absolute',top:'1em',right:'1em',color:'#777'}}>
                <IconButton aria-label="close"
                            onClick={props.onClose}
                            style={{cursor:'pointer',position:'relative',zIndex:'100'}}
                >
                    <CloseIcon />
                </IconButton>
            </div>
        );
    };

    return (
        <Dialog
            open={props.openModal}
            onClose={props.onClose}
            maxWidth={'md'}
            PaperProps={{style: {overflowY: 'visible'}}}
            fullWidth={(linkedDevices && linkedDevices.length > 0)}
            >
            {renderCloseButton()}
            <DialogTitle style={{paddingRight: 50}}>
                <div style={{fontWeight:'300',fontSize:'0.9em'}}>
                    {(linkedDevices && linkedDevices.length > 0)  && MESSAGE_DIALOG}
                </div>
            </DialogTitle>
            <DialogContent>
                <div style={{color: '#0009', minHeight: 40, height: 'auto', width:"auto"}}>
                    {(linkedDevices && linkedDevices.length > 0) ? contentData() : MESSAGE_DIALOG}
                </div>
            </DialogContent>
            <DialogActions className='dialog-buttons'>
                {actionButtons}
            </DialogActions>
        </Dialog>
    )
};

export default DialogTemplateDelete;