import React from 'react';

const ProppedButton = (props) => {
    const { icon, label, ...other } = props;
    return <button
        {...other}
    >
        {icon}
        <span>{label}</span>
    </button>
};

export default ProppedButton;
