import React, {useEffect} from 'react';
import {useSelector,useDispatch} from "react-redux";
import {DEVICE_ALERT} from "./util/DashboardConstants";
import {getReportWidgetData} from '../../../actions';
import IconButton from '@material-ui/core/IconButton';
import LookupIcon from '@material-ui/icons/FindInPage';
import {Link} from "react-router-dom";

const DevicesWidget = () => {
    const devicesAlert = useSelector(state => state.dashboards.devicesData);
    const dispatch = useDispatch();

    const handleLoadDevices = () => {
        dispatch(getReportWidgetData(DEVICE_ALERT), {});
    };

    useEffect(handleLoadDevices, []);

    const handleBuildData = () => {
        return devicesAlert.map((d, index) => {
            return (
                <tr className={index%2===0 ? "row-body-transparent" : "row-body"} key={index}>
                    <td className={"main-column-body"}>{d.devicetype}</td>
                    <td className={"column-body"}>{d.alert+d.noalert}</td>
                    <td className={"column-body"}>{d.noalert}</td>
                    <td className={"column-body column-alert"}>{d.alert}</td>
                </tr>
            )
        })
    }

    return (
        <div className={"chart-devices"}>
            <div className={"devices-main-container"}>
                <div className={"devices-container"}>
                    <div className={"devices-header"}>
                        <span className={"devices-title"}>No. Devices: All Sites</span>
                    </div>
                    <div className={"devices-body"}>
                        <div className={"devices-body-container"}>
                            <table className={"devices-table"}>
                                <thead>
                                    <tr>
                                        <th className={"column-header main-column-header"}>
                                            Devices
                                        </th>
                                        <th className={"column-header"}>
                                            Total
                                        </th>
                                        <th className={"column-header"}>
                                            No Alert
                                        </th>
                                        <th className={"column-header"}>
                                            On Alert
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {handleBuildData()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <Link to={DEVICE_ALERT.reportLink}>
                        <IconButton
                            className={"edit-button"}
                        >
                            <LookupIcon />
                        </IconButton>
                    </Link>
                </div>
            </div>
        </div>
    )
};

export default  DevicesWidget;