import React from 'react';
import createMuiTheme from "@material-ui/core/es/styles/createMuiTheme";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import ThemeProvider from "@material-ui/styles/ThemeProvider/ThemeProvider";
import {withStyles} from "@material-ui/core";
import Paper from "@material-ui/core/Paper/Paper";
import Button from "@material-ui/core/Button"
import {useDispatch} from "react-redux";
import {cancelDownloadLogs} from "../../../actions";

const ColorLinearProgress = withStyles({
    barColorPrimary: {
        backgroundColor: '#00779f'
    }
})(LinearProgress);

const outerTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#00779F',
        },
    },
    shadows: Array(25).fill('none')
});


const FetchScreenLogs = props => {
    const {message, haveCancel} = props;
    const dispatch = useDispatch();
    const styleButton = {backgroundColor: '#007cb0', color: '#fff', fontSize: '12px', padding: '0 2px', marginTop: '0.5em'};

    return (
        <div style={{position:'absolute',top:0,right:0,left:0,bottom:0,background:'rgba(0,0,0,0.7)',textAlign:'center', zIndex: '100000'}}>
            <div style={{position:'absolute',top:'50%',transform:'translateY(-50%)',left:0,right:0}}>
                <Paper style={{display:'inline-block',padding:'2em'}}>
                <span style={{fontSize: '12px'}}>{message}</span>
                <ThemeProvider theme={outerTheme}>
                    <ColorLinearProgress mode="indeterminate" style={{background:'rgba(0,0,0,0.25)',marginTop:'1em'}}/>
                </ThemeProvider>
                {haveCancel && <Button style={styleButton} onClick={() => dispatch(cancelDownloadLogs())}>Cancel</Button>}
                </Paper>
            </div>
        </div>
    )
};

export default FetchScreenLogs;