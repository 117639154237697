import React from 'react';
import PropTypes from 'prop-types';
import scrollbarSize from 'dom-helpers/scrollbarSize';
import {getToken} from '../../actions/util';
import {SiteMapThumbnail} from './SiteMapThumbnailOverlay';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {requestMaps} from '../../actions';

import {MAP_VIEW_URL, SITE_VIEW_HOST} from '../../constants/Misc';

export const overlayWidth = 115+scrollbarSize();

/*
████████ ██   ██ ██    ██ ███    ███ ██████  ███    ██  █████  ██ ██
   ██    ██   ██ ██    ██ ████  ████ ██   ██ ████   ██ ██   ██ ██ ██
   ██    ███████ ██    ██ ██ ████ ██ ██████  ██ ██  ██ ███████ ██ ██
   ██    ██   ██ ██    ██ ██  ██  ██ ██   ██ ██  ██ ██ ██   ██ ██ ██
   ██    ██   ██  ██████  ██      ██ ██████  ██   ████ ██   ██ ██ ███████
*/


class SiteMapThumbnailOverlay extends React.Component{
    static get propTypes(){
        return {
            maps: PropTypes.arrayOf(
                PropTypes.shape({
                    src: PropTypes.string.isRequired,
                    name: PropTypes.string.isRequired
                })
            ).isRequired,
            onSelectMap: PropTypes.func,
            timestamp: PropTypes.number
        };
    }

    static get defaultProps(){
        return {
            onSelectMap: ()=>{},
            maps:[]
        };
    }

    render(){

        const {maps,onSelectMap,timestamp,verticalPosition} = this.props;

        const thumbnails = maps.map((v,i)=>(
            <SiteMapThumbnail
                key={i}
                name={v.name}
                img={
                    <img alt={v.name} src={`${v.src}?timestamp=${timestamp}&jwt=${getToken()}`}/>
                }
                onClick={onSelectMap.bind(null,i)}
                style={{display:'block',margin:'0.25em',padding:'0 0.25em 0.25em'}}
                verticalPosition={verticalPosition}
            />
        ));

        const styles = {
            position:'absolute',
            bottom:0,
            top:0,
            right:0,
            width:overlayWidth+'px',
            padding:'0.25em 0',
            background:'rgba(0,0,0,0.7)',
            overflowY:'scroll',
            overflowX:'hidden',
            whiteSpace:'nowrap',
        };

        return (
            <div style={styles}>
                {thumbnails}
            </div>
        );
    }
}

export default SiteMapThumbnailOverlay;



class DumbSiteMapThumbnailDrawer extends React.Component{
    static get propTypes(){
        return {
            onSelectMap: PropTypes.func,
            maps:PropTypes.array,
            requestMaps: PropTypes.func.isRequired
        };
    }

    static get defaultProps(){
        return {
            onSelectMap:()=>{}
        };
    }
    constructor(){
        super();
        this.handleSelectMap = this.handleSelectMap.bind(this);
    }

    componentDidMount(){
        this.props.requestMaps();
    }

    render(){
        const {maps} = this.props;
        return (
            <SiteMapThumbnailOverlay
                maps={maps}
                onSelectMap={this.handleSelectMap}
                verticalPosition={true}
             />
        );
    }

    handleSelectMap(index){
        const {onSelectMap,maps} = this.props;
        onSelectMap(maps[index]);
    }
}


const SmartSiteMapThumbnailDrawer = connect(
    (state,{siteId})=>({
        maps: state.sites.maps.filter(m=>m.siteId === siteId).map(m=>({
            ...m,
            name: m.description,
            src:SITE_VIEW_HOST + MAP_VIEW_URL +"/maps/" + m._id + "/tiles/0_0_0"
        })),
    }),
    dispatch => ({
        ...bindActionCreators({
            requestMaps
        },dispatch)
    })
)(DumbSiteMapThumbnailDrawer);

export {SmartSiteMapThumbnailDrawer};
