import React from 'react';
import PropTypes from 'prop-types';

import StyledDialog, {CancelButton, SaveButton} from '../../../util/Dialog';

import Checklist from '../../../util/TreeSelect';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../../../../actions';
import {USERMGMT_UPDATE_USER} from '../../../../constants/DataEntryForms';

const WrappedInput = (props)=>{
    if(props.type === "display"){
        return (
            <span>
                {props.defaultValue}
            </span>
        );
    }
    return (
        <input {...props} />
    );
};

WrappedInput.propTypes = {
    type: PropTypes.string,
    defaultValue: PropTypes.any
};


class UpdateUserDialog extends React.Component{

    constructor(){
        super();

        this.state = {
            user:{}
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleTextInputChange = this.handleTextInputChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    static get propTypes(){
        return {
            userGroups: PropTypes.array,
            onRequestClose: PropTypes.func.isRequired,
            updateUser: PropTypes.func.isRequired,
            user: PropTypes.shape({
                username:PropTypes.string,
                firstName:PropTypes.string,
                lastName:PropTypes.string,
                id: PropTypes.string
            }),
        };
    }

    static get defaultProps(){
        return {
            user:{}
        };
    }

    shouldComponentUpdate(nextProps){
        return this.props !== nextProps;
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(this.props.user !== nextProps.user){
            this.setState({
                user:{
                    ...nextProps.user,
                    groups:(nextProps.user.groups || []).map(g=>g.name)
                }
            });
        }
    }

    handleChange(attribute,value){
        this.setState({
            user:{
                ...this.state.user,
                [attribute]:value
            }
        });
    }

    handleTextInputChange(attribute,e){
        this.handleChange(attribute,e.target.value);
    }

    handleSave(){
        let uuid = this.props.user.id;
        let user = {
            ...this.state.user,
            groups:this.state.user.groups.map(g=>({name:g}))
        };


        this.props.updateUser(uuid,user)
        .then(this.props.onRequestClose);
    }

    renderBasicInfo(){

        const {user} = this.state;

        const StandardField = (props)=>{

            const id = props.id || props.label.toLowerCase().split(" ").join("");

            const labelStyles = (props.first) ? {paddingTop:0} : {};

            return (
                <div className="users-form-standard-field">
                    <div style={{position:'relative'}}>
                        <label htmlFor={id} className="label" style={labelStyles}>{props.label}</label>
                        {props.help}
                    </div>
                    <WrappedInput className="input" id={id} type={props.type || "text"} defaultValue={props.defaultValue} onChange={this.handleTextInputChange.bind(null,props.attribute)}/>
                </div>
            );
        };

        const fields = [
            {
                label:"Username",
                type:"display",
                attribute:"username",
                defaultValue:user.username
            },
            {
                label:"First Name",
                type:"text",
                attribute:'firstName',
                defaultValue: user.firstName
            },
            {
                label:"Last Name",
                type:"text",
                attribute:'lastName',
                defaultValue: user.lastName
            },
            {
                label:"User Description",
                type:"text",
                attribute:'description',
                defaultValue: user.description
            }
        ];

        const form_fields = fields.map((v,i)=>{
            return <StandardField key={i} {...v} first={i===0}/>;
        });


        return (
            <div style={{paddingRight:'1em'}}>
                {form_fields}
            </div>
        );
    }

    render(){
        const {user,userGroups} = this.props;

        const _groups = (user.groups || []).map(g=>g.name);

        return (
            <StyledDialog
                {...this.props}
                title={"Edit User"}
                contentClassName="update-user-group-dialog"
                onRequestClose={this.props.onRequestClose}
                onClose={this.props.onRequestClose}
                actions={
                    <>
                        <SaveButton onClick={this.handleSave} />
                        <CancelButton style={{position: 'absolute', left: 8, bottom: 8}} onClick={this.props.onRequestClose} />
                    </>
                }
            >
                <div style={{width:'400px'}}>
                    {this.renderBasicInfo()}
                    <div className="users-form-standard-field">
                        <div style={{position:'relative',paddingRight:'1em'}}>
                            <label htmlFor={"group"} className="label" >{"Groups"}</label>
                            <Checklist defaultValue={_groups} options={userGroups} onChange={this.handleChange.bind(null,"groups")} style={{width:'100%',boxSizing:'border-box'}}/>
                        </div>
                    </div>
                </div>
            </StyledDialog>
        );
    }
}


/*
 ██████  ██████  ███    ██ ███    ██ ███████  ██████ ████████     ████████  ██████
██      ██    ██ ████   ██ ████   ██ ██      ██         ██           ██    ██    ██
██      ██    ██ ██ ██  ██ ██ ██  ██ █████   ██         ██           ██    ██    ██
██      ██    ██ ██  ██ ██ ██  ██ ██ ██      ██         ██           ██    ██    ██
 ██████  ██████  ██   ████ ██   ████ ███████  ██████    ██           ██     ██████

███████ ████████  █████  ████████ ███████
██         ██    ██   ██    ██    ██
███████    ██    ███████    ██    █████
     ██    ██    ██   ██    ██    ██
███████    ██    ██   ██    ██    ███████
*/


const mapStateToProps = state => ({
    open: state.dataEntry.open && state.dataEntry.activeForm === USERMGMT_UPDATE_USER,
    user: (state.dataEntry.activeForm === USERMGMT_UPDATE_USER || undefined) && state.dataEntry.initialFormData.user,
    userGroups: state.userMgmt.groups.map(g=>g.groupName)
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        onRequestClose: actions.closeDataEntry,
        updateUser: actions.updateUser
    },dispatch)
});

export default connect(mapStateToProps,mapDispatchToProps)(UpdateUserDialog);
