import React from 'react';
import PropTypes from 'prop-types';
import {Toolbar} from '@material-ui/core';
import styled from '@material-ui/styles/styled';
import {Breadcrumbs, DataEntryButtons} from '../../home/toolbars/ReportsToolbar';
import RightArrow from '@material-ui/icons/ChevronRight';
import LeftArrow from '@material-ui/icons/ChevronLeft';
import {generateComponentId} from "../../../utils/ComponentUtils";
import ToolbarButton from '../../icons/ToolbarButton';

const NavigationArrow = props => {
    if (!props.collapsed) {
        return <LeftArrow style={{border:"1px solid #fff",borderRadius:"4px", color: "#fff"}}/>;
    }
    return <RightArrow style={{border:"1px solid #fff",borderRadius:"4px", color: "#fff"}}/>;
}

const ToolbarStyle = styled(Toolbar)({
    maxHeight: '48px'
});

class TitleLayout extends React.Component {

    constructor(props) {
        super(props);
        this.handleExportCSV = this.handleExportCSV.bind(this);
        this.handleExportHTML = this.handleExportHTML.bind(this);
        this.handleRunReport = this.handleRunReport.bind(this);
    };

    static get propTypes() {
        return {
            name: PropTypes.string
        };
    };

    handleExportCSV(){
        this.props.onExportReport('csv');
    }   

    handleExportHTML(){
        this.props.onExportReport('html');
    }

    handleRunReport() {
        this.props.onRunReport();
    }

    render() {
        const { name, collapsed, numberResults, isMobile} = this.props;
        // Define fixed buttons for export.
        const exportButtons=[
            {id: 'import', name: "Export CSV", type: "EXPORT", action:this.handleExportCSV},
            {id: 'import', name: "Export HTML", type: "EXPORT", action:this.handleExportHTML}
        ];

        const message = numberResults > 0 ? `${numberResults} Results` : '';
        return (
            <ToolbarStyle disableGutters className="save-share-toolbar">

                <button
                    title="Toggle Filters"
                    className="button-container-import"
                    onClick ={this.props.showSidebar}
                >
                    <NavigationArrow collapsed={!collapsed}/>
                </button>
                
                    <Breadcrumbs category="Reports" name={name} />

                <div className="toolbar-right-container">
                    <ToolbarButton id={generateComponentId("run report", null, "button")} iconType={"RUN"} isMobile={isMobile} click={this.handleRunReport} />
                    <DataEntryButtons actions={{}} forms={exportButtons} isMobile={isMobile} />
                    <div className="separator">&nbsp;</div>
                    <div ref={"NumResults"}>{message}</div>
                    <div className="separator-right">&nbsp;</div>
                </div>
            </ToolbarStyle>
        );
    };

};

export default TitleLayout;