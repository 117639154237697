import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Button, IconButton, Dialog, DialogActions, DialogContent, DialogTitle,Divider} from '@material-ui/core';
import { NavigateBefore as BackArrow, Close as CloseIcon} from '@material-ui/icons';
import {useSelector, useDispatch} from "react-redux";
import LinkedDevicesPanel from '../accordionItems/LinkedDevicesPanel';
import {loadLinkedDevices, cleanLinkedDevices} from '../../../../actions';

const useStyles = makeStyles({
    paperDialog: {
        overflowY: "visible"
    }
});

const DialogSaveDevices = ({openDialog, closeDialog, updateOnlyDevice, updateDeviceAndTemplate, actionLabel}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const styleButton = {background: '#007CB0',color: '#fff',marginLeft:'25px',width:'340px',padding: '0.5em', textTransform:'capitalize'};
    const isMobile = useSelector(state => state.resize.isMobile);
    const templateId = useSelector(state => state.deviceManager.templateForm.template.templateId);
    const updateTemplate = useSelector(state => state.deviceManager.updateTemplate);
    const [updateDeviceTemplate, setUpdateDeviceTemplate] = useState(false);

    const loadDialog = () => {
        //unmouting
        const unmount = () => {
            dispatch(cleanLinkedDevices());
        }
        return unmount;
    }

    useEffect(loadDialog, []);

    const renderCloseButton = () => {
        return (
            <div style={{position:'absolute',top:'-0.5em',right:'-0.5em'}}>
                <IconButton aria-label="close"
                    size="small"
                    onClick={closeDialog}
                    style={{cursor:'pointer',position:'relative',zIndex:'100',backgroundColor:'#000'}}
                    >
                    <CloseIcon style={{color: '#fff'}} />
                </IconButton>
            </div>
        );
    }


    const renderItemList = (content) => {
        return (
            <div style={{display:'flex',flexDirection:'row'}}>
                <div style={{flexBasis:'25px'}}>*</div>
                <div style={{flex:1}}>{content}</div>
            </div>
        )
    }
    const titleDialog = ((templateId && templateId !== '') && updateTemplate) ? 'Device connected to a template - what do you want to do?':
                        `Are you sure you want to ${actionLabel} changes for this device?`;

    const mainActionButtons = (templateId && templateId !== '' && updateTemplate) ?
        <Button style={{background: '#fff', color: '#007CB0', padding: '0,5em', textTransform: 'none'}} onClick={closeDialog} component="span">Cancel</Button>:
        <>
            <Button style={{background: '#fff', color: '#007CB0', padding: '0,5em', textTransform: 'none'}} onClick={closeDialog} component="span">Cancel</Button>
            <Button style={{background: '#007CB0',color: '#fff', padding: '0.5em',textTransform:'none'}} onClick={updateOnlyDevice} component="span">
                {`Yes, ${actionLabel} device`}
            </Button>
        </>;

    const handleShowAssociatedDevices = () => {
        dispatch(loadLinkedDevices(templateId));
        setUpdateDeviceTemplate(true);
    }

    const renderMain = () => {
        return (
            <React.Fragment>
                <DialogTitle style={{paddingBottom:0}}>
                    <span style={{fontSize:'0.8em',fontWeight:'bold'}}>{titleDialog}</span>
                </DialogTitle>
                {renderCloseButton()}
                {((templateId && templateId !== '') && updateTemplate) &&
                    <DialogContent style={{fontSize:'0.9em',display:'flex',flexDirection:isMobile?'column':'row',maxHeight:'100%', justifyContent: 'space-between'}}>
                    <div style={{backgroundColor:'#eee9e9',flex:'1',padding:'1em',display:'flex',flexDirection:'column'}}>
                        <div style={{flex: '1 1 auto',paddingBottom:'1em'}}>
                            <div><b style={{textTransform:'capitalize'}}>{`${actionLabel} device only`}</b></div>
                            {renderItemList('the device will be disconnected from its current template')}
                            {renderItemList(`the device only will be ${actionLabel}d`)}
                            {renderItemList('the template and connected devices will not be updated')}
                        </div>
                        <div style={{flexBasis:'auto'}}>
                            <Button style={styleButton} component="span" onClick={updateOnlyDevice}>
                                {`${actionLabel} Device Only and Disassociate Template`}
                            </Button>
                        </div>
                    </div>
                    <Divider orientation={isMobile?'horizontal':'vertical'} flexItem style={{background:'#fff',flexBasis:'0.5em'}}/>
                    <div style={{backgroundColor:'#eee9e9',flex:'1',padding:'1em',display:'flex',flexDirection:'column'}}>
                        <div style={{flex: '1 1 auto',paddingBottom:'1em'}}>
                            <div><b style={{textTransform:'capitalize'}}>{`${actionLabel} both device and template`}</b></div>
                            {renderItemList('devices currently connected to the template will be updated by default')}
                            {renderItemList('you can confirn in the next screen which devices should stay connected to the template (and be updated) and which ones should be disconnected (and not updated)')}
                        </div>
                        <div style={{flexBasis:'auto'}}>
                            <Button style={styleButton} component="span" onClick={handleShowAssociatedDevices}>
                                {actionLabel === 'save' ? 'Save device and update template':'Update device and template'}
                            </Button>
                        </div>
                    </div>
                </DialogContent>
                }
                <DialogActions>
                    {mainActionButtons}
                </DialogActions>
            </React.Fragment>
        )
    }

    const renderUpdateTemplate = () => {
        return (
            <React.Fragment>
                <DialogTitle style={{paddingBottom:0}}><span style={{fontSize:'0.8em',fontWeight:'bold'}}>Update both device and template</span></DialogTitle>
                <DialogContent>
                    <LinkedDevicesPanel showWarning={true} checkColumn={true}/>
                </DialogContent>
                <DialogActions>
                    <Button style={{background: '#fff', color: '#007CB0', padding: '0.5em',textTransform:'capitalize'}}
                            onClick={()=>setUpdateDeviceTemplate(false)} component="span" startIcon={<BackArrow />} >
                        Back
                    </Button>
                    <Button style={{background: '#007CB0',color: '#fff', padding: '0.5em',textTransform:'capitalize'}}
                            onClick={updateDeviceAndTemplate} component="span">
                        {`${actionLabel}`}
                    </Button>
                </DialogActions>
            </React.Fragment>
        )
    }


    return (
        <Dialog
            fullWidth
            maxWidth={'md'}
            open={openDialog}
            onClose={closeDialog}
            classes={{paper:classes.paperDialog}}>
            {!updateDeviceTemplate? renderMain(): renderUpdateTemplate()}
        </Dialog>
    )
}

export default DialogSaveDevices;