import React from 'react';
import PropTypes from 'prop-types';

import {VectorLayer} from '../../../../../../common/app/views/map/Layer';
import Feature from '../../../../../../common/app/views/map/Feature';
//openlayers
import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import CircleStyle from 'ol/style/Circle';
import Point from 'ol/geom/Point';
import LineString from 'ol/geom/LineString';

const normalTagStyle = new Style({
    image: new CircleStyle({
      radius: 5,
      fill: new Fill({color:'white'}),
      stroke: new Stroke({color: 'red', width: 2})
    })
});

const hoverTagStyle = new Style({
    image: new CircleStyle({
        radius: 6,
        fill: new Fill({color:'red'}),
        stroke: new Stroke({color:'red',width:2})
    })
});


const currentLocStyle = new Style({
    image: new CircleStyle({
        radius: 6,
        fill: new Fill({color:'red'}),
        stroke: new Stroke({color:'red',width:2})
    }),
    zIndex:3
});

/**
 * Displays tags on a map.
 */
class TagLayer extends React.Component{

    /**
     * @private
     */
    constructor(props){
        super(props);

        this.props = props;

        this.renderPoints = this.renderPoints.bind(this);
        this.renderPaths = this.renderPaths.bind(this);
        this.renderCurrentLocation = this.renderCurrentLocation.bind(this);
    }

    static get propTypes(){
        return {
            tags: PropTypes.arrayOf(PropTypes.shape({
                raw: PropTypes.shape({
                    ___uid: PropTypes.any.isRequired
                }).isRequired,
                history: PropTypes.arrayOf(PropTypes.shape({
                    x: PropTypes.number,
                    y: PropTypes.number
                })).isRequired
            })).isRequired,
            startIndex: PropTypes.number.isRequired,
            endIndex: PropTypes.number,
            endTime: PropTypes.number,
            showPath: PropTypes.bool
        };
    }

    renderPoints(point){
        const styles = point.selected ? hoverTagStyle : normalTagStyle;

        const id = point.raw.___uid;

        const {startIndex, endIndex, endTime} = this.props;

        let history = point.history;

        if(endIndex !== undefined){
            history = history.slice(startIndex,endIndex+1);
        }
        if(endTime !== undefined){
            history = history.filter(v=>v.t <= endTime);
        }

        return history.map((v,i) => (
            <Feature
                key={"tag"+id+"_"+i}
                geometry={ new Point([v.x,v.y]) }
                style={styles}
                data={point}
            />
        ));
    }

    renderPaths(point,index){

        const pathStyle = new Style({
            stroke: new Stroke({color:'red',width:2})
        });

        const {startIndex, endIndex, endTime} = this.props;

        let history = point.history;

        if(endIndex !== undefined){
            history = history.slice(startIndex,endIndex+1);
        }
        if(endTime !== undefined){
            history = history.filter(v=>(v.t <= endTime));
        }

        history.push(this.getCurrentLocation(point));

        return (
            <Feature
                key={"the_path"+index}
                geometry={ new LineString( history.map(v=>[v.x,v.y]) ) }
                style={pathStyle}
                data={point}
            />
        );
    }

    renderCurrentLocation(point){
        const loc = this.getCurrentLocation(point);

        return (
            <Feature
                key={"current_location_"+point.raw.___uid}
                geometry={ new Point([loc.x,loc.y]) }
                style={currentLocStyle}
                data={point}
            />
        );
    }

    getCurrentLocation(point){
        const {endTime} = this.props;

        let i, l = point.history.length;
        for(i=0;i<l;i++){
            if(endTime <= point.history[i].t) break;
        }

        let pt_a = (i === 0) ? point.history[0] : point.history[i-1];
        let pt_b = (i === point.history.length) ? point.history[i-1] : point.history[i];

        if(pt_a === pt_b) return pt_a;

        let amt_through = (endTime - pt_a.t) / (pt_b.t - pt_a.t);


        return {
            x: (amt_through)*(pt_b.x) + (1-amt_through)*(pt_a.x),
            y: (amt_through)*(pt_b.y) + (1-amt_through)*(pt_a.y),
        };

    }

    /**
     * @private
     */
    render(){

        let {tags,showPath} = this.props;

        let _tags =  tags.map(this.renderPoints);
        let _paths = (showPath) ? tags.map(this.renderPaths) : null;
        let _currentLocs = tags.map(this.renderCurrentLocation);

        return (
            <VectorLayer {...this.props}>
                {_paths}
                {_tags}
                {_currentLocs}
            </VectorLayer>
        );
    }
}

export default TagLayer;
