import React from 'react';
import PropTypes from 'prop-types';

import StyledDialog, {CancelButton, SaveButton} from '../../util/Dialog';

import {DataEntryTabularField, DataEntryTabularForm} from '../../util/DataEntryTabularForm';
import TextFileInput from './TextFileInput';


class AddApplianceDialog extends React.Component {

    constructor(){
        super();

        this.handleSave = this.handleSave.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    static get propTypes(){
        return {
            open: PropTypes.bool,
            actions: PropTypes.object.isRequired,
            data: PropTypes.object
        };
    }

    static get defaultProps(){
        return {
            open: false
        };
    }

    handleClose ()  {
        this.props.actions.closeDataEntry();
    }

    handleSave(){
        var obj = {};
        obj.name = this.refs.name.value;
        obj.uuid = this.refs.uuid.value;
        obj.publicKey = this.refs.publicKey.getContent();
        obj.siteId = this.refs.site.value;

        if(!obj.uuid){
            this.props.actions.displayErrorDialog({message:"Must provide UUID."});
            return;
        }
        if(!obj.publicKey){
            this.props.actions.displayErrorDialog({message:"Must provide public key"});
            return;
        }

        if(obj.siteId === "null") obj.siteId = null;

        this.props.actions.registerAppliance(obj);
    }

    renderForm(){

        let sites = Object.keys(this.props.data.sites).map(k=>this.props.data.sites[k]).sort((a,b)=>{
            if(a.name < b.name) return -1;
            else if(a.name > b.name) return 1;
            return 0;
        });

        sites = [{_id:"null",name:"(Unassigned)"}].concat(sites);

        return (
            <DataEntryTabularForm fullWidth>
                <DataEntryTabularField label={"Appliance Name"}>
                    <input type={"text"} ref="name"/>
                </DataEntryTabularField>

                <DataEntryTabularField label={"UUID"} required>
                    <input type={"text"} placeholder={"####-####-###"} ref="uuid"/>
                </DataEntryTabularField>

                <DataEntryTabularField label={"Public Key"} required>
                    <TextFileInput ref="publicKey" label="upload pub key file" accept={".pub"} onError={(msg)=>{this.props.actions.displayErrorDialog({message:msg});}}/>
                </DataEntryTabularField> 

                <DataEntryTabularField label={"Site"}>
                    <select ref="site">
                        {sites.map((s,i) => (
                            <option key={i} value={s._id}>{s.name}</option>
                        ))}
                    </select>
                </DataEntryTabularField>
            </DataEntryTabularForm>
        );
    }

    render() {

        const {
            actions, // eslint-disable-line
            ...other
        } = this.props;

        return (
            <StyledDialog
                open={this.props.open}
                onClose={this.handleClose}
                title={'Add Appliance'}
                actions={
                    <>
                        <SaveButton onClick={this.handleSave} />
                        <CancelButton style={{position: 'absolute', left: 8, bottom: 8}} onClick={this.handleClose} />
                    </>                    
                }
                contentStyle={{maxWidth: 360}}
                {...other}
                >
                {this.renderForm()}
            </StyledDialog>
        );
    }
}

export default AddApplianceDialog;
