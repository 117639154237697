import React from 'react';
import PropTypes from 'prop-types';
import StyledDialog, {CancelButton, SaveButton} from '../util/Dialog';

import {Field, Form} from '../util/DataEntryTabularForm';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../../actions';
import {withRouter} from 'react-router-dom';

class SaveReportDialog extends React.Component{
    constructor(){
        super();
        this.handleClose = this.handleClose.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.redirectToSavedReport = this.redirectToSavedReport.bind(this);
    }
    static get propTypes(){
        return {
            open: PropTypes.bool,
            close: PropTypes.func.isRequired,
            name: PropTypes.string,
            showError: PropTypes.func.isRequired,
            saveReport: PropTypes.func.isRequired,
            history: PropTypes.any
        };
    }
    shouldComponentUpdate(nextProps){
        return (nextProps.open || this.props.open);
    }
    handleSave(){

        let name = this.refs.name.value.trim();

        if(name === ""){
            this.props.showError({
                message:"The name cannot be blank."
            });
            return;
        }

        this.props.saveReport(name)
        .then(res=>{
            this.handleClose();
            return res;
        })
        .then(this.redirectToSavedReport);
    }
    handleClose(){
        this.props.close();
    }

    redirectToSavedReport(id){
        this.props.history.push('/saved/'+id);
    }

    render() {
        const {name, open} = this.props;

        return (
            <StyledDialog
                title={"Save Report"}
                onClose={this.handleClose}
                open={open}
                contentStyle={{width: 360}}
                bodyStyle={{overflowY:'auto'}}
                actions={
                    <>
                        <SaveButton onClick={this.handleSave} />
                        <CancelButton style={{position: 'absolute', left: 8, bottom: 8}} onClick={this.handleClose} />
                    </>
                }
                >
                    <Form>
                        <Field label={"Name"} required>
                            <input type={"text"} ref="name" style={{width:350}} defaultValue={name}/>
                        </Field>
                    </Form>
            </StyledDialog>
        );
    }
}

const mapStateToProps = state => ({
    name: (state.report.savedReportId && state.user.savedReports[state.report.savedReportId].name) || ""
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        close:actions.closeDataEntry,
        showError: actions.displayErrorDialog,
        saveReport: actions.saveReport
    },dispatch)
});


export default withRouter( connect(mapStateToProps,mapDispatchToProps)(SaveReportDialog) );
