import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import MapsBySiteContainer from './MapsBySiteContainer';
import MapInformation from "./MapInformation";
import Search from '@material-ui/icons/Search';
import Autosuggest from 'react-autosuggest'
import { searchData, reloadSitesMaps, /* loadSuggestions, unloadSuggestions,*/ selectDevicesMap, listGroups, unloadGroups } from "../../../actions";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Menu from "@material-ui/core/Menu/Menu";

const KEYBOARD_KEYS = {
    ENTER: 'Enter'
};

const SitesMapsContainer = ({setToggleMap}) => {

    let [suggestions, setSuggestions] = useState([]);
    let [value, setValue] = useState('');
    const results = useSelector(state => state.deviceManager.results);
    const windowWidth = useSelector(state => state.resize.windowWidth);
    const siteGroups = useSelector(state => state.deviceManager.siteGroups);
    const groupSelected = useSelector(state => state.deviceManager.groupSelected);
    const sites = useSelector(state => state.deviceManager.sites);
    const maps = useSelector(state => state.deviceManager.maps);
    const countDevices = useSelector(state => state.deviceManager.countDevices);
    const dispatch = useDispatch();

    useEffect(() => {
        setSuggestions(results);
    }, [results, dispatch]);

    /*const loadSiteMaps = () => {
        dispatch(reloadSitesMaps(null, null,true));
        //setProgressDetails(false);
        const unmount = () => {
            dispatch(unloadSiteMaps())
        };

        return unmount();
    };

    useEffect(loadSiteMaps, []);*/


    const requestGroups = () => {
        dispatch(listGroups());

        const unmount = () => {
            dispatch(unloadGroups())
        };

        return unmount();
    };

    useEffect(requestGroups, []);
    /*
    const listSuggestions = () => {
        dispatch(loadSuggestions());

        const unmount = () => {
            dispatch(unloadSuggestions())
        };

        return unmount();
    };

    useEffect(listSuggestions, []);
    */
    const onChange = (event, {newValue, method}) => {
        setValue(newValue)
    };

    const onKeyDown = event => {
        if (event.key === KEYBOARD_KEYS.ENTER) {
            const { value } = event.target;
            dispatch(reloadSitesMaps(null, null, false, value));
        }
    }

    const inputProps = {
        placeholder: "Search site or map",
        value: value,
        onChange: onChange,
        onKeyDown: onKeyDown
    };

    const onSuggestionsFetchRequested = ({value}) => {
        dispatch(searchData(value));
    };

    const onSuggestionsClearRequested = () => {
        return setSuggestions([]);
    };

    const renderSuggestion = (suggestion) => {
        return (
            <span>{suggestion.name}</span>
        )
    };

    const getSuggestionValue = (suggestion) => {
        return suggestion.name;
    };

    const onSuggestionSelected = (event, {suggestion}) => {
        dispatch(reloadSitesMaps(null, suggestion,false));
    };

    const selectMap = (mapObj) => {
        dispatch(selectDevicesMap(mapObj));
        setToggleMap();
    };

    const selectSiteByGroup = (groupId) => {
        dispatch(reloadSitesMaps(groupId, null,false));
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const listSiteGroups = () => {
        if(siteGroups!=null) {
            if(siteGroups.length>5 || windowWidth<850) {
                return (<div>
                    <div aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}
                         className={allGroupsClass}>
                        Site Groups
                    </div>
                    <Menu
                        className={"site-groups-menu"}
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {siteGroups && siteGroups.map((group, index) => {
                            return (<div key={group.name}><MenuItem className={"site-groups"}
                                                                    onClick={() => selectSiteByGroup(group._id)}>&nbsp;{group.name}&nbsp;</MenuItem>
                            </div>)
                        })}

                    </Menu>
                </div>);

            }
        else
            {
                return siteGroups && siteGroups.map((group, index) => {
                    let className = group._id === groupSelected ? "site-link site-link-selected" : "site-link";
                    return (<div key={index}>
                        <div className="link-separator">&nbsp;</div>
                        <div className={className} onClick={() => selectSiteByGroup(group._id)}>{group.name}</div>
                    </div>)
                });
            }

        }


    };


    const allMaps = {
        name: "All sites / all maps",
        countDevices: countDevices,
        allMaps: true
    };

    let allGroupsClass = groupSelected ? "site-link" : "site-link site-link-selected";

    return (
        <div className={"site-maps-container"}>
            <div className={"header-container-title"}>
                <div className={"header-title"}><h2>All sites and maps</h2></div>
                <div className={"header-site-links"}>
                    <div className={allGroupsClass} onClick={() => selectSiteByGroup(null)}>All</div>
                    {listSiteGroups()}
                </div>
                <div className={"header-search-box"}>
                    <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={onSuggestionsClearRequested}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputProps}
                        onSuggestionSelected={onSuggestionSelected}
                        renderSectionTitle={()=> null}
                        getSectionSuggestions={()=> null}

                    />
                    <Search/>
                </div>
            </div>
            <div className={"all-maps-container"}>
                <MapInformation map={allMaps} selectMap={selectMap}/>
            </div>
            <MapsBySiteContainer sitesProps={sites} mapsProps={maps} selectMap={selectMap}/>
        </div>
    )
};

export default SitesMapsContainer;