import React from 'react';
import PropTypes from 'prop-types';
// import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import DateTimePickerFilter from '../../../util/datetimepicker';
import FormControlLabel from "@material-ui/core/es/FormControlLabel/FormControlLabel";

class Scheduler extends React.Component{

    constructor(){
        super();

        this.handlePickerChange = this.handlePickerChange.bind(this);
        this.handleChange = this.handleChange.bind(this);

        let _now = new Date();
        _now.setSeconds(0);

        this.state = {
            now: true,
            pickerTime:_now.getTime()
        };
    }

    static get propTypes(){
        return {
            onChange: PropTypes.func
        };
    }

    static get defaultProps(){
        return {
            onChange: function(){}
        };
    }

    handleChange(event, value){
        this.setState({
            now: (value === "now")
        });
        this.props.onChange((value === "now") ? 0 : this.state.pickerTime);
    }

    handlePickerChange(date){

        date.setSeconds(0);
        let _t = date.getTime();

        this.setState({
            pickerTime:_t
        });
        this.props.onChange(_t);
    }

    render(){
        //const {} = this.props;
        const {now} = this.state;

        var datetimefields = (
            /*
            <div style={{marginLeft:'0.5em'}}>
                <span> On </span>
                <input type="text" value="03/03/17" disabled={now}/>
                <input type="text" value="03:00 PM" disabled={now}/>
            </div>
            */
           <DateTimePickerFilter
               label=""
               disabled={now}
               defaultValue={this.state.pickerTime}
               style={{display:'inline-block',marginLeft:'0.5em'}}
               grid={6}
               timePickerStyle={{lineHeight: '38px'}}
               className={'input-sizes'}
               onChange={this.handlePickerChange}
           />
        );

        var radioButtonStyle = {margin:'0.5em',display:'inline-block',width:'auto'};

        return (
            <div>
                <RadioGroup name={"scheduler"} onChange={this.handleChange} style={{display:'inline-block'}}>
                    <FormControlLabel value="now" control={<Radio checked={now} style={{color: 'inherit', backgroundColor: 'transparent'}}/>} label="Now" style={{...radioButtonStyle,paddingRight:'5em'}}/>
                    <FormControlLabel value="later" control={<Radio checked={!now} style={{color: 'inherit', backgroundColor: 'transparent'}}/>} label="On" style={radioButtonStyle}/>
                </RadioGroup>
                {datetimefields}
            </div>
        );
    }
}

export default Scheduler;
