import React from "react";
import {getDateFromTimestamp} from "../../../../utils/LocaleTimestamp";
import LegendPlayback from "./LegendPlayback";
//openlayers
import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Icon from 'ol/style/Icon';
import Text from 'ol/style/Text';
import CircleStyle from 'ol/style/Circle';
import Point from 'ol/geom/Point';
import LineString from 'ol/geom/LineString';
import {fromLonLat, transformExtent} from 'ol/proj';
import Overlay from 'ol/Overlay';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import {Image as ImageLayer} from "ol/layer";
import {ImageCanvas as ImageCanvasSource} from "ol/source";
import KDBush from "kdbush";

class MapPlayback extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            highlightTags: [],
            zoom: null,
        }

        this.layers = {};
        // this.handleSelectFeature=this.handleSelectFeature.bind(this);
        this.handleBackgroundMouseUp = this.handleBackgroundMouseUp.bind(this);
        this.findLinesClicked = this.findLinesClicked.bind(this);
        this.hidePopup = this.hidePopup.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMoveStart = this.handleMoveStart.bind(this);
        this.handleMoveEnd = this.handleMoveEnd.bind(this);
        this.createPointFeature = this.createPointFeature.bind(this);
        this.handleSelectTag = this.handleSelectTag.bind(this);
        this.assignTagColors = this.assignTagColors.bind(this);
        this.buildHtmlObject = this.buildHtmlObject.bind(this);
        this.inRange = this.inRange.bind(this);

        // Canvas render
        this.drawCanvasLayer = this.drawCanvasLayer.bind(this);
        this.itemStyle = this.itemStyle.bind(this);
        this.canvasFunction = this.canvasFunction.bind(this);

        this.mouseDown = false;
        this.mapMoving = false;

        this.highlightColor = "#00FF2A";
        this.blinkColor = "#000000";

        this.baseColorArray = [
            "#00BDFF",
            "#00A25D",
            "#FF0000",
            "#7600FF",
            "#0000FF",
            "#F4DC00",
            "#F800FF",
            "#FF7F00",
            "#00EAA2",
            "#7EC600"
        ];

        this.lineColorArray = this.baseColorArray.map(hexColor => this.colorHexToRgb(hexColor, 0.7));
        this.arrowColorArray = this.baseColorArray.map(hexColor => this.colorHexToRgb(hexColor, 0.5));
        this.colorByTag = {};

        // Number of blinks in each coordinate.
        this.xyBlinks = {};

        // Coordinates where there is more than one blink.
        this.multipleBlinksArray = [];

        this.currentZoom = null;
        this.currentClusterItems = {};
        this.clustersData = [];
        this.nonClustersData = [];
    }

    componentDidMount() {

        this.map = window.MY_MAP;
        this.colorByTag = this.assignTagColors(this.props);
        this.addCanvasLayer();
        this.addPopupOverlay();

        this.map.getTargetElement().addEventListener("mouseup", this.handleMouseUp);
        this.map.getTargetElement().addEventListener("mousedown", this.handleMouseDown);

        this.map.on("click", this.handleBackgroundMouseUp);
        this.map.on("movestart", this.handleMoveStart);
        this.map.on("moveend", this.handleMoveEnd);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        let stateObject = {};
        if (this.props.blinkObject !== nextProps.blinkObject) {

            stateObject.tagBlinksByPosition = this.blinksByPosition(nextProps.blinkObject);
        }

        if (this.props.mapId !== nextProps.mapId
            || this.props.positionStart !== nextProps.positionStart
            || this.props.positionEnd !== nextProps.positionEnd
            || this.props.selectedTagIds !== nextProps.selectedTagIds
            || this.props.tagsQtyByMaps !== nextProps.tagsQtyByMaps
            || this.props.blinkObject !== nextProps.blinkObject
            || this.props.currentPosition !== nextProps.currentPosition
        ) {
            this.hidePopup();
        }
        this.setState({...stateObject});
    }

    shouldComponentUpdate(nextProps, nextState) {

        if (this.props.currentPosition !== nextProps.currentPosition
            || this.props.positionRepeatCount !== nextProps.positionRepeatCount
            || this.props.positionStart !== nextProps.positionStart
            || this.props.positionEnd !== nextProps.positionEnd
            || this.props.blinkObject !== nextProps.blinkObject
            || this.props.mapId !== nextProps.mapId
            || this.props.selectedTagIds !== nextProps.selectedTagIds
            || this.props.zoneVisibility !== nextProps.zoneVisibility
            || this.props.hideDrawer !== nextProps.hideDrawer
            || this.props.tagsQtyByMaps !== nextProps.tagsQtyByMaps
            || this.props.showTagLabels !== nextProps.showTagLabels
            || this.props.message !== nextProps.message
            || this.props.resizeTrigger !== nextProps.resizeTrigger
            || this.props.showLegend !== nextProps.showLegend
            || this.props.renderLayers !== nextProps.renderLayers
            || this.state.highlightTags !== nextState.highlightTags
            // ||this.state.zoom!==nextState.zoom

        ) {

            if (this.props.blinkObject !== nextProps.blinkObject || this.props.requestTagIds !== nextProps.requestTagIds) {
                this.colorByTag = this.assignTagColors(nextProps);
            }

            if (this.props.mapId !== nextProps.mapId || this.props.blinkObject !== nextProps.blinkObject) {
                this.removeLayers();
            }

            if (nextProps.selectedTagIds != null && nextProps.selectedTagIds.length > 0 && nextProps.renderLayers === true) {

                // console.log("update blinks");
                this.drawLayersByPosition(nextProps, nextState);
            } else {
                this.removeLayers();
            }

            return true;
        }

        return false;
    }

    componentDidUpdate() {

    }

    componentWillUnmount() {

        this.map.getTargetElement().removeEventListener("mouseup", this.handleMouseUp);
        this.map.getTargetElement().removeEventListener("mousedown", this.handleMouseDown);

        this.map.un("click", this.handleBackgroundMouseUp);
        this.map.un("movestart", this.handleMoveStart);
        this.map.un("moveend", this.handleMoveEnd);
    }

    handleBackgroundMouseUp(e) {


        const coordinates = e.coordinate;
        const x = coordinates[0];
        const y = coordinates[1];

        var resolution = this.map.getView().getResolution();

        const itemValues = [];
        const itemIndexes = [];
        if (this.linesIndex) {
            this.linesIndex.forEach(i => {
                const valueFinded = this.findLinesClicked(x, y, i.x1, i.y1, i.x2, i.y2);
                itemValues.push(valueFinded);
            });
            //console.log(itemValues);
            itemValues.forEach((value, index) => {
                if (value < resolution * 2)
                    itemIndexes.push(index);
            });
            // console.log(itemIndexes);
        }

        this.indexCluster = new KDBush(this.clustersData, p => p.x, p => p.y, 64, Float64Array);
        this.indexNonCluster = new KDBush(this.nonClustersData, p => p.x, p => p.y, 64, Float64Array);

        const foundClusters = this.indexCluster.within(x, y, 8 * resolution);
        const foundNonClusters = this.indexNonCluster.within(x, y, 4 * resolution);

        const newHighlightTags = [];

        const selectedCluster = foundClusters.map(index => this.clustersData[index]);
        const selectedNonCluster = foundNonClusters.map(index => this.nonClustersData[index]);
        const selectedLines = itemIndexes.map(index => this.linesData[index]);

        if (foundClusters.length > 0)
            newHighlightTags.push(selectedCluster[0].elementId);
        else if (foundNonClusters.length > 0)
            newHighlightTags.push(selectedNonCluster[0].elementId);
        else if (itemIndexes.length > 0)
            newHighlightTags.push(selectedLines[0].elementId);

        const objectsClustered = selectedCluster.map(cluster => {
            return this.clusteredItems[cluster.elementId][cluster.indexInArray];
        });
        const objectsNonClustered = selectedNonCluster.map(nonCluster => {
            console.log("this.clusteredItems",this.clusteredItems,nonCluster);
            return this.clusteredItems[nonCluster.elementId][nonCluster.indexInArray];
        });

        const {clusters, blinkObject, currentPosition, elementIdLabel} = this.props

        if ((objectsClustered != null && objectsClustered.length > 0) || (objectsNonClustered != null && objectsNonClustered.length > 0)) {
            const objects = objectsClustered.concat(objectsNonClustered);
            this.showPopup(coordinates, objects, clusters, blinkObject, currentPosition, elementIdLabel);
        } else if ((!newHighlightTags.length || itemIndexes.length) && this.openPopUp) {
            this.hidePopup();
        }

        if (this.state.highlightTags[0] === newHighlightTags[0]) newHighlightTags[0] = [];
        else if (!newHighlightTags.length) newHighlightTags.push(this.state.highlightTags[0]);
        this.setState({highlightTags: newHighlightTags});
    }

    findLinesClicked(x, y, x1, y1, x2, y2) {
        const A = x - x1;
        const B = y - y1;
        const C = x2 - x1;
        const D = y2 - y1;

        const dot = A * C + B * D;
        const len_sq = C * C + D * D;
        let param = -1;
        if (len_sq !== 0) //in case of 0 length line
            param = dot / len_sq;

        let xx, yy;

        if (param < 0) {
            xx = x1;
            yy = y1;
        } else if (param > 1) {
            xx = x2;
            yy = y2;
        } else {
            xx = x1 + param * C;
            yy = y1 + param * D;
        }

        const dx = x - xx;
        const dy = y - yy;
        return Math.sqrt(dx * dx + dy * dy);
    }

    // handleSelectFeature(e){
    //     const {clusters,blinkObject,currentPosition}= this.props
    //     const features = e.features;
    //
    //     if(features!=null&&features.length>0) {
    //         this.showPopup(e,clusters,blinkObject,currentPosition);
    //     }
    // }

    handleMouseUp() {
        this.mouseDown = false;
        this.props.startPlay();
    }

    handleMouseDown(event) {
        this.mouseDown = true;
        this.props.stopPlay();
        this.hidePopup(event);
    }

    handleMoveStart() {
        this.mapMoving = true;
        this.props.stopPlay();
    }

    handleMoveEnd() {

        this.mapMoving = false;
        const newZoom = this.map.getView().getZoom();
        if (this.currentZoom !== newZoom) {
            this.currentZoom = newZoom;

            this.setState({zoom: newZoom});
            this.hidePopup();

        }
        this.props.startPlay();

        // if(this.props.renderLayers===true) {
        //     this.drawLayersByPosition(this.props, this.state);
        // }
    }

    handleSelectTag(elementId) {

        const {highlightTags} = this.state;
        let newHighlightTags = [];

        if (elementId) {
            newHighlightTags.push(elementId + "");
        }

        // If there is only one highlight, toggle status of highlight.
        if (highlightTags && highlightTags.length === 1 && elementId === highlightTags[0]) {
            newHighlightTags = [];
        }

        this.setState({highlightTags: newHighlightTags});
    }

    /**
     * Return the points (clusterized or not) in the visible area.
     * @param map
     * @returns {Array}
     */
    clusterizePoints(mapClusters, blinks, clusterIndex, map, extentMap, positionStart, currentPosition, positionRepeatCount, positionEnd, elementId) {

        let blinksToDraw = {};
        let arrowsToDraw ={};
        let prevIdArrow=null;
        if (mapClusters != null) {
            let countItemsCurrentPosition = 0;
            // console.log("blinks",blinks,"mapClusters",mapClusters,"positionRepeatCount",positionRepeatCount);
            Object.keys(mapClusters.itemsByPosition).some(timeFramePosition => {


                // if (this.inRange(blinkInfo.pos, positionStart, currentPosition, positionEnd, positionRepeatCount,countItemsCurrentPosition)) {
                if (timeFramePosition <= currentPosition) {

                    const itemsPosition = mapClusters.itemsByPosition[timeFramePosition];
                    itemsPosition.some(clusterInfo => {
                        // const clusterInfo = mapClusters[timeFramePosition];
                        const blinkInfo = blinks[clusterInfo.index];

                        let raw= {___uid:'cluster_'+clusterInfo.clusterId, elementId: elementId};
                        if (blinkInfo.pos === currentPosition) {
                            if (countItemsCurrentPosition <= positionRepeatCount - 1) {
                                countItemsCurrentPosition++;
                                const clusterPosition = mapClusters.clusterPositions[clusterInfo.clusterId];
                                // console.log("clusterPosition",clusterPosition,"mapClusters",mapClusters,clusterInfo.clusterId);//
                                if (!blinksToDraw.hasOwnProperty(clusterInfo.clusterId)) {
                                    blinksToDraw[clusterInfo.clusterId] = {
                                        items: [],
                                        raw:raw,
                                        ...clusterPosition
                                    };
                                }
                                blinksToDraw[clusterInfo.clusterId].items.push(blinkInfo);

                                if(prevIdArrow!=null&&!arrowsToDraw.hasOwnProperty(prevIdArrow+"-"+clusterInfo.clusterId)) {
                                    const prevCluster=mapClusters.clusterPositions[prevIdArrow];
                                    arrowsToDraw[prevIdArrow+"-"+clusterInfo.clusterId]={end:{x: prevCluster.x, y: prevCluster.y},start:{x: clusterPosition.x, y: clusterPosition.y},elementId:elementId};
                                    prevIdArrow=clusterInfo.clusterId;
                                }
                                else{
                                    prevIdArrow=clusterInfo.clusterId;
                                }


                            } else {
                                return true;
                            }

                        } else {
                            const clusterPosition = mapClusters.clusterPositions[clusterInfo.clusterId];
                            if (!blinksToDraw.hasOwnProperty(clusterInfo.clusterId)) {

                                blinksToDraw[clusterInfo.clusterId] = {
                                    items: [],
                                    raw:raw,
                                    ...clusterPosition
                                };
                            }
                            blinksToDraw[clusterInfo.clusterId].items.push(blinkInfo);

                            if(prevIdArrow!=null&&!arrowsToDraw.hasOwnProperty(prevIdArrow+"-"+clusterInfo.clusterId)) {
                                const prevCluster=mapClusters.clusterPositions[prevIdArrow];
                                arrowsToDraw[prevIdArrow+"-"+clusterInfo.clusterId]={end:{x: prevCluster.x, y: prevCluster.y},start:{x: clusterPosition.x, y: clusterPosition.y},elementId:elementId};
                                prevIdArrow=clusterInfo.clusterId;
                            }
                            else{
                                prevIdArrow=clusterInfo.clusterId;
                            }
                        }
                        return false;
                    });
                } else
                    return true;

                return false;
            });
        }

        // console.log("arrowsToDraw", arrowsToDraw);
        const blinkInfo = [];
        Object.keys(blinksToDraw).forEach((clusterId, index) => {
            const blinkObject = blinksToDraw[clusterId];
            blinkInfo.push({
                label: "",
                cluster: blinkObject.cluster,
                point_count: blinkObject.items.length,
                point_count_abbreviated: blinkObject.items.length,
                map: null,
                raw: blinkObject.raw,
                selected: blinkObject.selected,
                x: blinkObject.x,
                y: blinkObject.y,
                //selectedCount: selectedCount,
                clusterCount: blinkObject.items.length,
                recent: false,
                index: index,
                id: clusterId,
                pos: blinkObject.pos,
                itemCount: blinkObject.items.length,
                indexInCluster: blinkObject.indexInCluster,

            });
        });

        const arrowsInfo=Object.keys(arrowsToDraw).map(key=>arrowsToDraw[key]);
        // console.log("blinkInfoDraw", blinkInfoDraw);
        return {blinkInfo,arrowsInfo};
    }

    mapClusters(clusterIndex, blinks, map, extentMap, elementId) {

        let itemsByPosition = {};
        let clusterPositions = {};

        if (clusterIndex != null && map != null && map.getView() != null && extentMap != null) {

            // Calculates the limits of current visible area of map.
            const bounds = transformExtent(extentMap, 'EPSG:3857', 'EPSG:4326');

            let zoom = Math.round(map.getView().getZoom());
            const maxZoom = 8;
            if (zoom > maxZoom) {
                zoom = maxZoom;
            }

            // Gets clustered items.
            this.currentClusterItems[elementId] = null;

            this.currentClusterItems[elementId] = clusterIndex.getClusters(bounds, zoom);

            if (this.currentClusterItems[elementId] != null && this.currentClusterItems[elementId].length > 0) {


                this.currentClusterItems[elementId].forEach((item, itemIndex) => {
                    const clusterPosition = fromLonLat(item.geometry.coordinates);

                    let timeFramePosition = null;
                    if (item.properties.cluster === true) {

                        // TODO: use "getLeaves" when the library supercluster is fixed
                        // const items = clusterIndex.getLeaves(clusterId, Infinity, 0);
                        const clusterId = item.id;
                        const items = item.properties.selected;

                        const clusterIdObj = "c" + clusterId;
                        clusterPositions[clusterIdObj] = {x: clusterPosition[0], y: clusterPosition[1],selected:items,cluster:true};

                        items.forEach(itemIndex => {
                            timeFramePosition = blinks[itemIndex].pos;

                            if (!itemsByPosition.hasOwnProperty(timeFramePosition))
                                itemsByPosition[timeFramePosition] = [];

                            itemsByPosition[timeFramePosition].push({index: itemIndex, clusterId: clusterIdObj});
                        });

                    }
                    // Non clustered items.
                    else {
                        const clusterIdObj = "nc" + item.properties.index;
                        clusterPositions[clusterIdObj] = {x: clusterPosition[0], y: clusterPosition[1],selected:0,cluster:false};
                        timeFramePosition = item.properties.pos;
                        if (!itemsByPosition.hasOwnProperty(timeFramePosition))
                            itemsByPosition[timeFramePosition] = [];

                        itemsByPosition[timeFramePosition].push({
                            index: item.properties.index,
                            clusterId: clusterIdObj
                        });
                    }
                });

            }


            // Order index inside each position.
            Object.keys(itemsByPosition).forEach(timeFramePosition => {
                itemsByPosition[timeFramePosition] = itemsByPosition[timeFramePosition].sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
            });
        }


        return {itemsByPosition, clusterPositions};
    }

    render() {

        const {selectedTagIds, currentPosition, showLegend, hideDrawer, getBounds, elementIdLabel} = this.props;
        const {highlightTags, tagBlinksByPosition} = this.state;

        return (
            <div>
                <LegendPlayback
                    highlightTags={highlightTags}
                    tagBlinksByPosition={tagBlinksByPosition}
                    selectedTagIds={selectedTagIds}
                    currentPosition={currentPosition}
                    showLegend={showLegend}
                    colorByTag={this.colorByTag}
                    handleSelectTag={this.handleSelectTag}
                    highlightColor={this.highlightColor}
                    blinkColor={this.blinkColor}
                    miniMaps={!hideDrawer}
                    getBounds={getBounds}
                    elementIdLabel={elementIdLabel}
                />
                {React.cloneElement(this.props.children, {
                    onMoveStart: this.handleMoveStart,
                    onMoveEnd: this.handleMoveEnd
                })}
            </div>
        )
    }

    blinksByPosition(blinkObject) {

        let tagIdByPosition = {};

        Object.keys(blinkObject).forEach(elementId => {

            const tagBlinks = blinkObject[elementId];

            if (tagBlinks != null && tagBlinks.blinks) {

                tagBlinks.blinks.forEach(blink => {

                    if (blink.groupSize > 0) {

                        if (tagIdByPosition[blink.pos] == null) {
                            tagIdByPosition[blink.pos] = [];
                        }

                        if (tagIdByPosition[blink.pos].indexOf(elementId) < 0) {
                            tagIdByPosition[blink.pos].push(elementId);
                        }
                    }
                });

            }
        });

        return tagIdByPosition;
    }

    assignTagColors(props) {
        const {blinkObject, requestTagIds} = props;

        let tagsColorObj = {};

        for (const key in blinkObject) {
            const styleCode = requestTagIds.indexOf(key);
            tagsColorObj[key + ""] = this.randomColor(styleCode)[0];
        }

        return tagsColorObj;
    }

    buildHtmlObject(key, value, enableEvent, elementId) {

        const containerElement = document.createElement('span');
        const nameElement = document.createElement('b');
        nameElement.innerText = key;
        const contentElement = document.createElement('span');
        contentElement.innerText = value;

        if (enableEvent === true && elementId != null) {
            const that = this;
            contentElement.className = "playback-popup-clickable-element"
            contentElement.addEventListener("click", function (evt) {
                that.handleSelectTag(elementId);
                evt.preventDefault();
            }, false);

            const colorElement = document.createElement('span');

            const color = this.colorByTag[elementId];
            colorElement.className = "playback-popup-color-element";
            colorElement.style.background = color;
            colorElement.innerText = " ";
            contentElement.appendChild(colorElement);

        }

        containerElement.appendChild(nameElement);
        containerElement.appendChild(contentElement);

        return containerElement;
    }

    showPopup(coordinates, objects, clusters, blinkObject, currentPosition, elementIdLabel) {
        this.openPopUp = true;
        if (this.overlay != null && this.overlayContent != null) {

            // Format html for popup.
            let htmlObject = null;

            htmlObject = document.createElement('div');
            htmlObject.id = 'popupOl';

            let itemsSelected = {};

            // Builds object of blinks grouped by element.
            for (const i in objects) {

                const element = objects[i];

                const elementId = element.raw.elementId;

                const cluster = element.cluster;

                if (!itemsSelected.hasOwnProperty(elementId)) {
                    itemsSelected[elementId] = [];
                }

                const blinks = blinkObject[elementId].blinks;

                if (cluster === true) {
                    // const clusterId = element.id;
                    // const clusterIndex = clusters[elementId];
                    const indexInCluster = element.index;

                    let items = this.currentClusterItems[elementId][indexInCluster].properties.selected;
                    if (items) {
                        items = items.sort((a, b) => a - b);

                        items.forEach(item => {
                            const indexItem = item;
                            const blinkInfoItem = blinks[indexItem];
                            if (blinkInfoItem.pos <= currentPosition) {

                                itemsSelected[elementId].push(blinkInfoItem);
                            }
                        });
                    }
                } else {

                    const indexItem = element.index;
                    const blinkInfoItem = blinks[indexItem];

                    if (blinkInfoItem.pos <= currentPosition) {
                        itemsSelected[elementId].push(blinkInfoItem);
                    }
                }
            }

            // Build html object for render in open layers.
            let count = 0;
            for (const elementId in itemsSelected) {
                const elementBlinks = itemsSelected[elementId];

                if (count > 0) {
                    htmlObject.appendChild(document.createElement('br'));
                }

                let showLink = false;

                if (elementId && elementId !== '') {
                    showLink = true;

                    htmlObject.appendChild(this.buildHtmlObject(elementIdLabel + ": ", elementId, showLink, elementId));
                    htmlObject.appendChild(document.createElement('br'));
                    showLink = false;

                }

                elementBlinks.forEach(blink => {

                    const blinkTime = getDateFromTimestamp(new Date(blink.blinkTime).getTime());

                    htmlObject.appendChild(this.buildHtmlObject("Blink Time: ", blinkTime, false));
                    htmlObject.appendChild(document.createElement('br'));

                    htmlObject.appendChild(this.buildHtmlObject("x: ", blink.x, false));
                    htmlObject.appendChild(this.buildHtmlObject("  y: ", blink.y, false));
                    htmlObject.appendChild(document.createElement('br'));
                });

                count++;
            }

            // Render popup.
            if (count > 0) {
                this.overlayContent.innerHTML = "";
                this.overlayContent.appendChild(htmlObject);
                this.overlay.setPosition(coordinates);
            }

            // if (tagIdPoints.length === 1) {
            //     this.handleSelectTag(selectedTagIdPoint);
            // }
            // else {
            //     this.handleSelectTag();
            // }
        }
    }

    hidePopup(event) {
        this.openPopUp = false;
        if (event != null) {
            if (document.getElementById('popupOl') != null && !document.getElementById('popupOl').contains(event.target)) {
                this.overlay.setPosition(undefined);
                event.preventDefault();
            }
        } else {
            this.overlay.setPosition(undefined);
        }

    }

    addPopupOverlay() {

        var element = document.createElement('div');

        this.overlayContainer = element;
        this.overlayContainer.className = 'ol-popup';

        this.overlayCloser = document.createElement('a');
        this.overlayCloser.className = 'ol-popup-closer';
        this.overlayCloser.href = '#';
        this.overlayContainer.appendChild(this.overlayCloser);

        var that = this;
        this.overlayCloser.addEventListener('click', function (evt) {
            that.overlay.setPosition(undefined)
            that.overlayCloser.blur();
            evt.preventDefault();
        }, false);

        this.overlayContent = document.createElement('div');
        this.overlayContent.className = 'ol-popup-content';
        this.overlayContainer.appendChild(this.overlayContent);

        /**
         * Create an overlay to anchor the popup to the map.
         */
        this.overlay = new Overlay({
            element: element,
            autoPan: true,
            autoPanAnimation: {
                duration: 250
            }
        });


        this.map.addOverlay(this.overlay);
    }

    drawLayersByPosition(props, state) {

        const {blinkObject, requestTagIds, showTagLabels, clusters, positionStart, currentPosition, positionRepeatCount, positionEnd} = props;
        const {highlightTags} = state;

        if (this.map != null && blinkObject != null && Object.keys(blinkObject).length > 0) {

            let styleCode = 0;
            this.xyBlinks = {};
            this.multipleBlinksArray = [];
            this.linesIndex = [];
            this.clustersData = [];
            this.nonClustersData = [];
            this.linesData = [];

            this.clustersData = [];
            this.nonClustersData = [];
            this.clusteredItems = {};

            this.removeLayers();

            for (const elementId in blinkObject) {
                styleCode = requestTagIds.indexOf(elementId);

                const blinkInfoObject = blinkObject[elementId];

                //const highlight=(highlightTags.indexOf(elementId)>=0);
                //console.time("time spent in clustering");
                // Only gets the items less or equal to current position.
                //const positionItems=this.filterItemsByPosition(positionStart,currentPosition,positionEnd,blinkInfoObject.blinks);


                // console.log("currentPosition",currentPosition,"positionRepeatCount",positionRepeatCount);
                const mapClusters = this.mapClusters(clusters[elementId], blinkInfoObject.blinks, this.map, props.extentMap, elementId);
                // Gets the clustered objects for the current zoom.
                const clusterInfo = this.clusterizePoints(mapClusters, blinkInfoObject.blinks, clusters[elementId], this.map, props.extentMap, positionStart, currentPosition, positionRepeatCount, positionEnd, elementId);

                // // Create the position of lines of current position.
                // this.clusteredItems[elementId]=clusterInfo.clusteredItems;

                this.clusteredItems[elementId] = clusterInfo.blinkInfo;
                // console.log("this.clusteredItems[elementId]",this.clusteredItems[elementId]);
                this.linesToDraw = clusterInfo.arrowsInfo;
                // this.linesToDraw=this.linesFromClusters(clusters[elementId],this.clusteredItems[elementId],blinkInfoObject.blinks,clusterInfo.indexIncluded,clusterInfo.clusterTag,clusterInfo.tagCluster,clusterInfo.clusterToIndex,clusterInfo.clusterIdIndexPosition,positionStart,currentPosition,positionRepeatCount,positionEnd,elementId);
                // console.log("this.linesToDraw",this.linesToDraw);
                this.linesToDraw.forEach(i => {
                    const x1 = i.start.x;
                    const y1 = i.start.y;
                    const x2 = i.end.x;
                    const y2 = i.end.y;
                    this.linesIndex.push({x1,y1,x2,y2});
                });
                //
                //
                // Draw the points an lines.
                this.drawCanvasLayer(elementId, currentPosition, clusterInfo.maxIndex, this.clusteredItems[elementId], this.linesToDraw, styleCode, highlightTags, showTagLabels);
                //
                // //count++;
                //
                // if(blinkInfoObject.blinks&&blinkInfoObject.blinks.length>0){
                //     firstsBlinks.push(blinkInfoObject.blinks[0]);
                // }
            }

            this.canvasLayer.changed();

            this.props.handleSetTagsColor(this.colorByTag);
        } else
            this.removeLayers();
    }

    inRange2(positionItem, positionStart, currentPosition, positionEnd) {
        return positionItem >= positionStart && positionItem <= currentPosition && positionItem <= positionEnd;
    }

    inRange(positionItem, positionStart, currentPosition, positionEnd, positionRepeatCount, countItemsPosition) {

        // If position of item is less than current position of timeline.
        if (positionItem >= positionStart && positionItem <= currentPosition && positionItem <= positionEnd) {

            if (positionItem === currentPosition) {
                if (countItemsPosition <= positionRepeatCount - 1) {
                    return true;
                } else {
                    return false
                }
            }
            return true;


        }
        return false;
    }

    linesFromClusters(clusterIndex, clusteredObjects, blinks, indexIncluded, clusterTag, tagCluster, clusterToIndex, clusterIdIndexPosition, positionStart, currentPosition, positionRepeatCount, positionEnd, elementId) {

        let linesMap = {};
        const lengthItems = blinks.length;
        let linesCoordinates = [];

        clusteredObjects.forEach(item => {
            let excluded = [];
            if (item.cluster === true) {

                const clusterId = item.id;
                //const items = clusterIndex.getLeaves(clusterId, Infinity, 0);
                const items = item.selected;

                const clusterItems = clusterTag["c" + clusterId];
                excluded = [...excluded, ...clusterItems];

                for (let i = 0; i < items.length; i++) {

                    // const indexItem = items[i].properties.index;
                    const indexItem = items[i];

                    const nextIndexItem = indexItem + 1;

                    if (blinks[nextIndexItem])

                        if (nextIndexItem < lengthItems && this.inRange(blinks[nextIndexItem].pos, positionStart, currentPosition, positionEnd) === true) {

                            if (excluded.indexOf(nextIndexItem) < 0) {
                                if (linesMap[indexItem] == null) {
                                    linesMap[indexItem] = [];
                                }

                                linesMap[indexItem].push(nextIndexItem);

                                const nextClusterItems = clusterTag[tagCluster[nextIndexItem]];

                                if (nextClusterItems != null) {
                                    excluded = [...excluded, ...nextClusterItems];
                                }
                            }
                        }
                }
            } else {

                const indexItem = item.index;
                const nextIndexItem = indexItem + 1;

                if (nextIndexItem < lengthItems && this.inRange(blinks[nextIndexItem].pos, positionStart, currentPosition, positionEnd)) {

                    if (excluded.indexOf(nextIndexItem) < 0) {
                        if (linesMap[indexItem] == null) {
                            linesMap[indexItem] = [];
                        }

                        linesMap[indexItem].push(nextIndexItem);
                        excluded.push(nextIndexItem);
                    }
                }
            }
        });
        // console.log("linesMap",linesMap);

        for (const key in linesMap) {

            const currentClusterStart = tagCluster[linesMap[key]];
            const currentClusterEnd = tagCluster[key];

            let lineObject = {start: null, end: null, elementId};
            if (currentClusterStart != null && clusteredObjects[clusterIdIndexPosition[currentClusterStart]]) {
                const clusterObject = clusteredObjects[clusterIdIndexPosition[currentClusterStart]];
                lineObject.start = clusterObject;
            } else {
                const blinkObjectStart = blinks[linesMap[key]];
                lineObject.start = blinkObjectStart;
            }
            if (currentClusterEnd != null && clusteredObjects[clusterIdIndexPosition[currentClusterEnd]]) {
                const clusterObject = clusteredObjects[clusterIdIndexPosition[currentClusterEnd]];
                lineObject.end = clusterObject;
            } else {
                const blinkObjectEnd = blinks[key];
                lineObject.end = blinkObjectEnd;
            }
            linesCoordinates.push(lineObject);
        }

        return linesCoordinates;
    }

    drawLayer(elementId, blinkingTags, position, maxIndex, styleCode, showTagLabels, selectedTagInfo, highlight, tagLayerIndex, linesToDraw) {

        if (blinkingTags != null) {

            let lineFeatures = [];
            let pointFeatures = [];

            blinkingTags.forEach((blinkInfo, index) => {

                const itemPosition = blinkInfo.pos;
                const itemIndex = blinkInfo.index;

                // Only draw features to received position.
                if (itemPosition <= position) {

                    // Mark the last blink of current tag.
                    let lastBlink = false;

                    if (itemIndex === maxIndex && itemPosition === position) {
                        lastBlink = true;
                    }

                    const pointFeature = this.createPointFeature(elementId, blinkInfo, position, itemIndex, index, lastBlink, styleCode, showTagLabels, selectedTagInfo, highlight, tagLayerIndex);

                    pointFeatures.push(pointFeature);

                    //this.overlapPointsDetection(blinkInfo.x,blinkInfo.y);
                }

                return (itemPosition > position);
            });
            linesToDraw.forEach(line => {
                const lineFeature = this.createLineFeature(elementId, line.start, line.end, position, styleCode, highlight, tagLayerIndex);
                lineFeatures.push(lineFeature);
            });

            const source = new VectorSource({
                features: [...lineFeatures, ...pointFeatures],
            });

            const blinkLayer = new VectorLayer({
                source: source,
                zIndex: (highlight) ? tagLayerIndex * 20 : tagLayerIndex, // for show the tags in front of zone layer
            });


            if (this.layers.hasOwnProperty(elementId)) {
                this.map.removeLayer(this.layers[elementId]);
            }

            this.layers[elementId] = null;
            this.layers[elementId] = blinkLayer;

            this.map.addLayer(blinkLayer);
        }
    }

    removeLayers() {

        if (this.canvasContext != null) {
            this.canvasContext.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
            this.canvasLayer.changed();
        }

    }

    createLineFeature(elementId, blinkInfo, prevBlinkInfo, position, styleCode, highlight, tagLayerIndex) {

        let feature;

        const item = blinkInfo;

        const prevItem = prevBlinkInfo;
        const distanceItems = item.pos - prevItem.pos;

        const lineString = new LineString([[prevItem.x, prevItem.y], [item.x, item.y]]);

        feature = new Feature({
            geometry: lineString
        });

        const lineStyles = this.lineStyles(elementId, styleCode, distanceItems, feature, highlight);
        feature.setStyle(lineStyles);
        feature.set("elementId", elementId);
        feature.set("tagLayerIndex", tagLayerIndex);
        feature.set("featureType", "line");

        return feature;
    }

    createPointFeature(elementId, blinkInfo, position, itemIndex, index, lastBlink, styleCode, showTagLabels, selectedTagInfo, highlight, tagLayerIndex) {

        const tagId = selectedTagInfo.tagId;
        const resourceId = selectedTagInfo.elementId;

        let feature;
        let clusterStyle = false;
        let cluster = false;
        let clusterId = null;
        let indexInCluster = null;

        const indexItem = blinkInfo.index;

        if (blinkInfo.cluster === true) {

            cluster = true;
            clusterId = blinkInfo.id;
            indexInCluster = blinkInfo.indexInCluster;

            if (blinkInfo.itemCount > 1) {
                clusterStyle = true;
            }
        }

        let dataItem = blinkInfo;
// console.log("dataItem",dataItem);
        let pointStyle = this.pointStyle(elementId, styleCode, itemIndex, index, lastBlink, showTagLabels, highlight, clusterStyle, blinkInfo.itemCount);

        const x = dataItem.x;
        const y = dataItem.y;
        //const currentPosition = dataItem.pos;
        // const groupSize = dataItem.groupSize;
        // const timeStart = dataItem.timeStart;
        // const timeEnd = dataItem.timeEnd;
        const blinkTime = dataItem.blinkTime;

        // Only show label in first tag in the history.
        if (index > 0) {
            pointStyle = this.pointStyle(elementId, styleCode, itemIndex, index, lastBlink, showTagLabels, highlight, clusterStyle, blinkInfo.itemCount);
        }

        feature = new Feature(new Point([x, y]), {});

        feature.setStyle(pointStyle);
        feature.set("styleCode", styleCode);
        feature.set("elementId", elementId);
        feature.set("tagId", tagId);
        feature.set("resourceId", resourceId);
        feature.set("blinkTime", blinkTime);
        feature.set("x", x);
        feature.set("y", y);
        feature.set("tagLayerIndex", tagLayerIndex);
        feature.set("featureType", "point");
        feature.set("cluster", cluster);
        feature.set("clusterId", clusterId);
        feature.set("indexItem", indexItem);
        feature.set("indexInCluster", indexInCluster);

        return feature;
    }

    colorHexToRgb(hexColor, opacity) {

        var bigint = parseInt(hexColor.substring(1), 16);
        var r = (bigint >> 16) & 255;
        var g = (bigint >> 8) & 255;
        var b = bigint & 255;

        return "rgba(" + r + "," + g + "," + b + "," + opacity + ")";
    }

    randomColor(position) {

        const color = this.baseColorArray[position];
        const lineColor = this.lineColorArray[position];
        const arrowColor = this.arrowColorArray[position];
        return [color, lineColor, arrowColor];
    }

    lineStyles(elementId, styleCode, distanceItems, feature, highlight) {

        const colors = this.randomColor(styleCode);

        const baseColor = colors[0];
        const colorRGBAlpha = colors[1];

        let lineStyles = [];
        let extraStyle = {};

        if (distanceItems > 1) {
            extraStyle = {lineDash: [5, 5]};
        }

        if (highlight === true) {


            const lineStyle = new Style({
                stroke: new Stroke({
                    color: this.highlightColor,
                    width: 8,
                    ...extraStyle,
                }),
            });

            lineStyles.push(lineStyle);

            extraStyle.width = 4;
            extraStyle.color = baseColor;
        }

        const lineStyle = new Style({
            stroke: new Stroke({
                color: colorRGBAlpha,
                width: 3,
                ...extraStyle,
            }),
        });

        lineStyles.push(lineStyle);

        const geometry = feature.getGeometry();
        geometry.forEachSegment(function (start, end) {

            const pStartX = start[0];

            const pStartY = start[1];
            const pEndX = end[0];
            const pEndY = end[1];

            const dx = pEndX - pStartX;
            const dy = pEndY - pStartY;

            const rotation = Math.atan2(dy, dx);
            const arrowsPosition = [0.25, 0.5, 0.75];

            for (const i in arrowsPosition) {
                const percentage = arrowsPosition[i];
                const pX = pStartX + ((pEndX - pStartX) * percentage);
                const pY = pStartY + ((pEndY - pStartY) * percentage);

                lineStyles.push(new Style({
                    geometry: new Point([pX, pY]),
                    image: new Icon({

                        src: 'assets/images/playback/arrow' + styleCode + '.png',
                        //src: 'assets/images/playback/arrow.svg',
                        rotateWithView: true,
                        rotation: -rotation,
                        color: baseColor,
                    })
                }));

            }

        });
        return lineStyles;
    }

    pointStyle(elementId, position, itemIndex, index, currentPosition, showTagLabels, highlight, cluster, itemCount) {

        const color = this.randomColor(position);
        let fillColor = color[0];
        let strokeColor = color[0];
        let strokeWidth = 1;
        let radius = 4;
        let styleArray = [];

        let clusterTextStyle = {};
        if (cluster === true) {
            radius = 8;
            clusterTextStyle = {
                text: new Text({
                    font: '11px sans-serif,helvetica',
                    text: itemCount.toString(),
                    fill: new Fill({
                        color: '#fff'
                    }),
                    stroke: new Stroke({
                        color: '#000', width: 1
                    }),
                })
            };
        }

        if (currentPosition === true) {
            strokeColor = this.blinkColor;
            //fillColor=fillColor;

            strokeWidth++;
            radius++;
        }

        if (highlight === true) {

            const styleHighlight = new Style({

                    image: new CircleStyle({
                        radius: radius + 3,
                        fill: new Fill({color: this.highlightColor}),
                    }),
                }
            );
            styleArray.push(styleHighlight);
        }

        let labelTextStyle = {};

        if (showTagLabels === true && index === 0) {
            labelTextStyle = {
                text: new Text({
                    font: '12px Calibri,sans-serif',
                    fontWeight: '600',
                    fill: new Fill({color: '#000'}),
                    stroke: new Stroke({
                        color: '#fff', width: 2
                    }),
                    text: elementId + "",
                    offsetY: -11,
                })
            };
        }

        const stylePoint = new Style({

                image: new CircleStyle({
                    radius: radius,
                    fill: new Fill({color: fillColor}),
                    stroke: new Stroke({color: strokeColor, width: strokeWidth})
                }),
                ...labelTextStyle,
                ...clusterTextStyle,
                zIndex: itemIndex,
            }
        );
        styleArray.push(stylePoint);

        return styleArray;

    }

    overlapPointsDetection(x, y) {

        const key = x + "," + y;
        if (this.xyBlinks.hasOwnProperty(key)) {

            if (this.xyBlinks[key] === 1) {
                this.multipleBlinksArray.push([x, y]);
            }

            this.xyBlinks[key]++;

        } else {
            this.xyBlinks[key] = 1;
        }
    }


    //region Canvas render


    addCanvasLayer() {
        this.canvasLayer = new ImageLayer({
            source: new ImageCanvasSource({
                canvasFunction: this.canvasFunction,
                projection: this.map.getView().getProjection()
            })
        });
        this.canvasLayer.setZIndex(10);
        this.map.addLayer(this.canvasLayer);
    }

    canvasFunction(extent, resolution, pixelRatio, size, projection) {

        this.extent = extent;
        this.pixelRatio = pixelRatio;
        if (this.canvasWidth !== size[0] || this.canvasHeight !== size[1] || this.canvas == null) {

            // this.canvasWidth = size[0]*this.pixelRatio;
            // this.canvasHeight = size[1]*this.pixelRatio;

            this.canvasWidth = size[0];
            this.canvasHeight = size[1];

            const canvas = document.createElement('canvas');
            canvas.width = this.canvasWidth;
            canvas.height = this.canvasHeight;
            const canvasContext = canvas.getContext('2d');

            this.canvas = canvas;
            this.canvasContext = canvasContext;
            // this.canvasContext.scale(this.pixelRatio, this.pixelRatio);
        }
        if (this.props.renderLayers === true) {
            this.drawLayersByPosition(this.props, this.state);
        }
        return this.canvas;
    }

    drawCanvasLayer(elementId, currentPosition, maxIndex, clusteredItems, linesToDraw, styleCode, highlightTags, showTagLabels) {

        const clusteredTags = clusteredItems;
        let elementStatus = false;
        if (elementId === highlightTags[0]) elementStatus = true;

        // Only if there is a map and a canvas context.
        if (this.map != null && this.canvasContext != null) {

            const mapExtent = this.map.getView().calculateExtent(this.map.getSize());
            const canvasOrigin = this.map.getPixelFromCoordinate([this.extent[0], this.extent[3]]);
            const mapOrigin = this.map.getPixelFromCoordinate([mapExtent[0], mapExtent[3]]);
            let delta = [mapOrigin[0] - canvasOrigin[0], mapOrigin[1] - canvasOrigin[1]];

            const colors = this.randomColor(styleCode);
            //const blinksLength=clusteredTags.length;
            // Create data for render points.
            const data = clusteredTags.map((blinkInfo, index) => {

                const coordinatesPixel = this.map.getPixelFromCoordinate([blinkInfo.x, blinkInfo.y]);

                const blinkInfoForIndex = {
                    x: blinkInfo.x,
                    y: blinkInfo.y,
                    cluster: null,
                    ___uid: null,
                    ___index: null,
                    indexInArray: index,
                    elementId: blinkInfo.raw.elementId
                };
                if (blinkInfo.cluster === true) {
                    blinkInfoForIndex.clusterId = blinkInfo.id;
                    blinkInfoForIndex.cluster = blinkInfo.cluster;
                    blinkInfoForIndex.items = blinkInfo.items;
                    this.clustersData.push(blinkInfoForIndex);
                } else {
                    blinkInfoForIndex.___uid = blinkInfo.raw.___uid;
                    blinkInfoForIndex.___index = blinkInfo.raw.___index;
                    blinkInfoForIndex.label = blinkInfo.label;
                    this.nonClustersData.push(blinkInfoForIndex);
                }

                let lastBlink = false;
                if (currentPosition === blinkInfo.pos && blinkInfo.index === maxIndex)
                    lastBlink = true;

                const style = this.itemStyle(elementId, blinkInfo, colors[0], elementStatus, showTagLabels, lastBlink);
                return {
                    x: coordinatesPixel[0] * this.pixelRatio + delta[0] * this.pixelRatio,
                    y: coordinatesPixel[1] * this.pixelRatio + delta[1] * this.pixelRatio,
                    radius: style.radius,
                    fillColor: style.fillColor,
                    strokeColor: style.strokeColor,
                    strokeWidth: style.strokeWidth,
                    textArc: style.textArc,
                    label: style.label
                }
            });

            // Create position of lines for render.
            const lines = linesToDraw.map((line, index) => {
                const coordinatesPixelStart = this.map.getPixelFromCoordinate([line.start.x, line.start.y]);
                const coordinatesPixelEnd = this.map.getPixelFromCoordinate([line.end.x, line.end.y]);
                const linesDrawInfo = {
                    start: {
                        x: coordinatesPixelStart[0] * this.pixelRatio + delta[0] * this.pixelRatio,
                        y: coordinatesPixelStart[1] * this.pixelRatio + delta[1] * this.pixelRatio
                    },
                    end: {
                        x: coordinatesPixelEnd[0] * this.pixelRatio + delta[0] * this.pixelRatio,
                        y: coordinatesPixelEnd[1] * this.pixelRatio + delta[1] * this.pixelRatio
                    },
                    elementId: line.elementId
                }
                this.linesData.push(linesDrawInfo);
                return linesDrawInfo;
            });

            // Draw in canvas.
            this.drawPath(this.canvasContext, lines, colors[1], elementStatus);
            this.drawArrow(this.canvasContext, lines, colors[2], elementStatus);
            this.drawArc(this.canvasContext, data);

        }
    }


    drawArc(context, data) {

        const endAngle = 2 * Math.PI;
        const font = '11px sans-serif,helvetica';

        context.font = font;

        data.forEach(item => {
            context.fillStyle = item.fillColor;
            context.beginPath();
            context.arc(item.x, item.y, item.radius, 0, endAngle);
            context.closePath();
            context.fill();

            if (item.lastBlink === true) {
                context.lineWidth = item.strokeWidth;
                context.strokeStyle = this.blinkColor;
                context.stroke();
            } else {
                context.lineWidth = item.strokeWidth;
                context.strokeStyle = item.strokeColor;
                context.stroke();
            }
            if (item.textArc != null) {
                //context.strokeStyle = 'black';
                //context.lineWidth = 1;
                context.textBaseline = 'middle';
                context.textAlign = "center";
                //context.strokeText(item.textArc, item.x, item.y);
                context.fillStyle = "white";
                context.fillText(item.textArc, item.x, item.y);
            }

            if (item.label != null) {
                context.strokeStyle = 'yellow';
                context.lineWidth = 8;
                context.lineJoin = "round";
                context.miterLimit = 1;
                context.textBaseline = 'bottom';
                context.textAlign = "center";
                context.strokeText(item.label, item.x, item.y - item.radius-4);
                context.fillStyle = "black";
                context.fillText(item.label, item.x, item.y - item.radius-4);
            }
        });
    }

    /**
     * Return styles for a current item.
     * @param item
     * @returns {Style}
     */
    itemStyle(elementId, item, color, highlight, showTagLabels, lastBlink) {

        const highlightColor = this.highlightColor;
        let strokeWidth = 1;
        let label = null;
        let textCluster = null;

        let radius = 4;
        let fillColor = color;
        let strokeColor = color;
        if (item.cluster === true && item.itemCount > 1) {
            radius = 8;

            if (item.hasOwnProperty('itemCount') && item.itemCount != null)
                textCluster = item.itemCount;
        }

        if (lastBlink === true) {
            strokeWidth = 1;
            strokeColor = this.blinkColor;
            radius += strokeWidth;

        } else if (highlight) {
            strokeWidth = 2;
            strokeColor = highlightColor;
            radius += strokeWidth;

        }

        if (showTagLabels === true) {
            label = "" + elementId;
        }

        return {
            radius: radius,
            fillColor: fillColor,
            strokeColor: strokeColor,
            strokeWidth: strokeWidth,
            textArc: textCluster,
            label: label,
        }
    }

    drawPath(context, data, color, highlight) {

        const highlightColor = this.highlightColor;
        context.beginPath();
        data.forEach(line => {
            context.moveTo(line.start.x, line.start.y);
            context.lineTo(line.end.x, line.end.y);

        });

        if (highlight) {
            context.lineWidth = 7;
            context.strokeStyle = highlightColor;
            context.stroke();
        }
        context.lineWidth = 3;
        context.strokeStyle = color;
        context.stroke();
    }

    roundNumber(number) {
        let rounded = (0.5 + number) | 0;
        // A double bitwise not.
        rounded = ~~(0.5 + number);
        // Finally, a left bitwise shift.
        rounded = (0.5 + number) << 0;

        return rounded;
    }

    drawArrow(context, data, color, status) {
        const highlightColor = this.highlightColor;

        data.forEach(line => {

            // const dx = line.end.x-line.start.x;
            // const dy = line.end.y-line.start.y;
            const dx = line.start.x - line.end.x;
            const dy = line.start.y - line.end.y;

            const rotation = Math.atan2(dy, dx);
            const arrowsPosition = [0.25, 0.5, 0.75];

            context.beginPath();
            const headLength = 10;
            const directionHeadX1 = headLength * Math.cos(rotation - Math.PI / 4);
            const directionHeadX2 = headLength * Math.cos(rotation + Math.PI / 4);
            const directionHeadY1 = headLength * Math.sin(rotation - Math.PI / 4);
            const directionHeadY2 = headLength * Math.sin(rotation + Math.PI / 4);

            for (const i in arrowsPosition) {

                const percentage = arrowsPosition[i];
                let pX = line.start.x + ((line.end.x - line.start.x) * percentage);
                let pY = line.start.y + ((line.end.y - line.start.y) * percentage);

                context.moveTo(pX, pY);
                context.lineTo(pX - directionHeadX1, pY - directionHeadY1);
                context.moveTo(pX, pY);
                context.lineTo(pX - directionHeadX2, pY - directionHeadY2);
            }
            if (status) {
                context.lineWidth = 4;
                context.strokeStyle = highlightColor;
                context.stroke();
            }
            context.lineWidth = 2;
            context.strokeStyle = color;
            context.stroke();


        });
    }

    //endregion
}

export default MapPlayback;