const ParameterAsyncConfigurations = require('./ParameterAsyncConfigurations');


module.exports = {
    type:'FLOOR-MAP',
    dataProvider:{
        service:"report",
        url:"/dashboards/widgets/19/data"
    },
    reportLink:"/reports/C0FB7CAD-8C25-11D5-9066-00105A6AE011", // resources report
    title:"Resources by Zone",
    parameters:[
        {
            name:"site",
            label:"Site",
            async:ParameterAsyncConfigurations.SITE_NAMES
        },
        {
            type:"enum",
            name:"zonegroup",
            label:"Zone Group",
            providers:["site"],
            async:ParameterAsyncConfigurations.ZONE_GROUPS,
            includeFrontOptions:[
                {label:'All',value:'%'}
            ]
        }
    ],
    showHeader:false,
    columns:[{
        caption:"Custom Attribute",
        key:"attribute"
    }]

};
