import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputBase from '@material-ui/core/InputBase';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {loadTemplateList, unloadTemplateList} from "../../../../actions";
import {localeTimestamp} from '../../../../utils/TimestampFormats';

const useStyles = makeStyles({
    paperDialog: {
        overflowY: "visible",
    }
});

function SelectTemplateDialog({nameTemplate, selectTemplate, open, closeDialog}){
    const dispatch = useDispatch();
    const classes = useStyles();
    const styleCell = {flex: '1 1 0',minWidth:'130px', textOverflow:'ellipsis',whiteSpace:'no-wrap',overflow:'hidden'};
    const styleRow = {display:'flex',flexDirection: 'row', cursor:'pointer',paddingTop:'0.25em',paddingBottom:'0.25em'};
    const styleCancelButton = {background: '#fff', color: '#007CB0', padding: '0.5em', textTransform: 'none'};
    const styleOKButton = {background: '#007CB0', textTransform: 'none', color: '#fff', padding: '0.5em', width: '200px'};
    const headerTemplates = [{id:"title",label:"Template Name",width:"40%"},
                            {id:"createBy",label:"Author",width:"40%"},
                            {id:"createDate",label:"Creation Date",width:"20%"}];

    const templates = useSelector(state => state.deviceManager.templates);
    const [template,setTemplate] = useState("");    // word to search
    const [selected,setSelected] = useState(null);
    const [filteredTemplates, setFilteredTemplates] = useState(templates);

    const loadTemplates=() => {
        // Load first time on mount component.
        dispatch(loadTemplateList());
        const unmount = () => {
            dispatch(unloadTemplateList());
        }
        return unmount;
    }

    // Only call once when mount.
    useEffect(loadTemplates, []);

    useEffect(()=>{setFilteredTemplates(templates)},[templates])

    function searchTemplate(searchValue){
        setSelected(undefined);
        if(searchValue===""){
            setFilteredTemplates(templates);
        }else{
            let filteredTemplates = templates.filter(item =>
                (item.title && item.title?.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1) ||
                (item.createBy && item.createBy?.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 )||
                (item.createDate && localeTimestamp(new Date(item.createDate)).toString()?.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1));
            setFilteredTemplates(filteredTemplates);
        }
    }

    function handleSelectTemplate(){
        selectTemplate(selected.templateSelected);
    }

    function handleChooseTemplate(withoutTemplate, templateSelected){
        setSelected({withoutTemplate: withoutTemplate, templateSelected: templateSelected});
    }

    function renderCloseButton(){
        return (
            <div style={{position:'absolute',top:'-0.5em',right:'-0.5em'}}>
                <IconButton aria-label="close"
                            size="small"
                            onClick={closeDialog}
                            style={{cursor:'pointer',position:'relative',zIndex:'100',backgroundColor:'#000'}}
                >
                    <CloseIcon style={{color: '#fff'}} />
                </IconButton>
            </div>
        );
    }
    
    return(
        <React.Fragment>
            <Dialog
                open={open}
                fullWidth
                onClose={closeDialog}
                classes={{paper:classes.paperDialog}}
            >
                {renderCloseButton()}
                <DialogTitle style={{paddingBottom:0}}>
                    <span style={{fontSize:'0.8em',fontWeight:'bold'}}>
                    Current Template: {nameTemplate===''?'No Template':nameTemplate}<br/>Select Template
                    </span>
                </DialogTitle>
                <DialogContent style={{fontSize:'0.9em',display:'flex',flexDirection:'column',height:`${window.innerHeight - 230}px`,maxHeight:'100%'}}>

                    <div style={{display:'flex',border: '1px solid #7E868C',borderBottom:'none',paddingLeft:'1em',flex:'0 0 auto'}}>
                        <InputBase fullWidth autoFocus value={template} placeholder={" Search Templates..."}
                                   onChange={e => {setTemplate(e.target.value); searchTemplate(e.target.value)}}/>
                        <IconButton size="small" onClick={e => searchTemplate(template)}>
                            <SearchIcon />
                        </IconButton>
                    </div>

                    <div style={{padding:'1em',border: '1px solid #7E868C',overflow:'auto',flex:'1 1 70%'}} >
                        <div onClick={()=>handleChooseTemplate(true, null)} style={{cursor:'pointer', backgroundColor: (selected && selected.withoutTemplate)? '#d8d8d8':'#fff'}}>
                            <span>Configure the device without template</span>
                        </div>
                        <hr />
                        <div style={{display:'flex', flexDirection: 'column'}}>
                            <div style={{display:'flex',flexDirection: 'row', paddingTop:'0.25em',paddingBottom:'0.25em'}}>
                                {headerTemplates.map((header, index)=>
                                    <div style={{...styleCell, fontWeight:'bold',width:header.width}} key={index}>{header.label}</div>
                                )}
                            </div>

                            {filteredTemplates.map((item, indexItem) =>
                                <div style={{...styleRow, backgroundColor: (selected && selected.templateSelected && selected.templateSelected._id===item._id)? '#d8d8d8':'#fff'}} 
                                    key={indexItem} onClick={()=>handleChooseTemplate(false, item)}>
                                    {headerTemplates.map((header, index)=>
                                        <div style={{...styleCell, width:header.width}} key={index}>
                                            {header.id==="createDate"? localeTimestamp(new Date(item[header.id])) : item[header.id]}
                                        </div>)}
                                </div>
                            )}
                        </div>
                    </div>

                    {filteredTemplates[selected] &&
                    <div style={{paddingTop:'1em',flex:'1 1 0'}} >
                        <div>
                            <strong>Description</strong>
                        </div>
                        <div style={{overflowY:'auto',maxHeight:'120px'}}>{filteredTemplates[selected].description}</div>
                    </div>
                    }
                </DialogContent>
                <DialogActions>
                    <Button style={styleCancelButton} onClick={closeDialog} component="span">
                        Cancel
                    </Button>
                    <Button style={{...styleOKButton, opacity: selected? 0.9: 0.3}} onClick={handleSelectTemplate} disabled={!selected} component="span">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default SelectTemplateDialog;