import React from 'react';
import ZPage from './../../../../common/app/views/ZPage';
import {connect} from "react-redux";
import ZebraLogoIcon from "../icons/ZebraLogoIcon";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";

const RELEASE_VERSION="release-version";
class LandingPage extends React.Component{

    constructor(props){
       super(props);
       this.handleShowVersion=this.handleShowVersion.bind(this);
       this.handleClosePopup=this.handleClosePopup.bind(this);

       this.version=window.VERSION;

       this.state={
           showAbout:false
       };
    }

    handleShowVersion(){
        if(this.version!=null){
            this.setState({showAbout:true});
        }
    }

    handleClosePopup() {
        this.setState({showAbout: false});
    }

    renderPopup(){
        const {showAbout}=this.state;

        let popup=null;



        if(this.version!=null&&this.version[RELEASE_VERSION]!=="@release-version@") {

            const microservicesVersion=[];
                Object.keys(this.version).forEach((key, index)=>{
                if(key!==RELEASE_VERSION) {
                    microservicesVersion.push(<div key={index}>
                        <strong>{key} </strong>
                        <span>{this.version[key]}</span>
                    </div>);
                }
            });

            return <Dialog
                className={"about-dialog"}
                onClose={this.handleClosePopup}
                open={showAbout}
                // titleStyle={{fontSize:'initial',fontWeight:'500',paddingBottom:'12px'}}
                // bodyStyle={{overflowY: 'auto'}}
                // contentStyle={{maxWidth: "240px"}}
            >
                <div className={"about-container"}>
                    <div className={"about-image"}>
                        <IconButton className={"icon-logo-menu"}>
                            <ZebraLogoIcon/>
                        </IconButton>
                    </div>
                    <div className={"about-app-name"}>MotionWorks Enterprise</div>
                    <div className={"about-app-version"}>Version {this.version[RELEASE_VERSION]}</div>
                    <br/>
                    <div className={"about-service-version-container"}>
                        {microservicesVersion}
                    </div>
                </div>
            </Dialog>
        }

        return popup;
    }

    render (){

        const{isPortrait,windowWidth,windowHeight}=this.props;

        let cssPortrait="landing-background";
        const imgWidth=windowWidth+"px";
        const imgHeight=(windowHeight-60)+"px";

        let imgBackground = 'assets/images/background/landing-pc.jpg';
        if(isPortrait===true)
            imgBackground='assets/images/background/landing-mobile.jpg';

        let imgBackgroundOriginal = 'assets/images/landing-pc.jpg';
        if(isPortrait===true)
            imgBackgroundOriginal='assets/images/landing-mobile.jpg';

        const aboutPopup=this.renderPopup();

        return (
            <ZPage
                zShowSubHeader={false} >
                <div className="landing-container">
                    <div className={cssPortrait}>
                        <img  src={imgBackground} style={{maxWidth:imgWidth,maxHeight:imgHeight}} alt="landing background"
                            onError={(e)=>{e.target.onerror = null; e.target.src=imgBackgroundOriginal}} />
                        <div className="landing-text">
                            <h3>MotionWorks Enterprise</h3>
                            <h6>VISIBILITY THAT’S VISIONARY.</h6>
                            {(this.version!=null&&this.version[RELEASE_VERSION]!=="@release-version@")?<span onClick={this.handleShowVersion}>v {this.version[RELEASE_VERSION]}</span>:null}
                        </div>
                    </div>
                </div>
                {aboutPopup}
            </ZPage>

        );
    }
}
const mapDispatchToProps = dispatch => ({
});

const mapStateToProps = state =>({
    isPortrait:state.resize.isPortrait,
    windowWidth:state.resize.windowWidth,
    windowHeight:state.resize.windowHeight,

});

export default connect(mapStateToProps,mapDispatchToProps)(LandingPage);
