import React from 'react';
import PropTypes from 'prop-types';

import StyledDialog, {CancelButton, SaveButton} from '../../../util/Dialog';
import Checklist from '../../../util/TreeSelect';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../../../../actions';
import {USERMGMT_ADD_USER} from '../../../../constants/DataEntryForms';

//import Checkbox from 'material-ui/Checkbox';


class AddUserDialog extends React.Component{

    constructor(){
        super();

        this.state = {
            user:{}
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleTextInputChange = this.handleTextInputChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    static get propTypes(){
        return {
            userGroups: PropTypes.array,
            onRequestClose: PropTypes.func.isRequired,
            addUser: PropTypes.func.isRequired
        };
    }

    shouldComponentUpdate(nextProps){
        return this.props !== nextProps;
    }

    handleChange(attribute,value){
        this.setState({
            user:{
                ...this.state.user,
                [attribute]:value
            }
        });
    }

    handleTextInputChange(attribute,e){
        this.handleChange(attribute,e.target.value);
    }

    handleSave(){
        let user = {
            ...this.state.user,
            groups: this.state.user.groups?.map(g=>({name:g})) || []
        };
        this.props.addUser(user)
        .then(this.props.onRequestClose);
    }

    renderBasicInfo(){

        const StandardField = (props)=>{

            const id = props.id || props.label.toLowerCase().split(" ").join("");

            const labelStyles = (props.first) ? {paddingTop:0} : {};

            return (
                <div className="users-form-standard-field">
                    <div style={{position:'relative'}}>
                        <label htmlFor={id} className="label" style={labelStyles}>{props.label}</label>
                        {props.help}
                    </div>
                    <input className="input" id={id} type={props.type || "text"} onChange={this.handleTextInputChange.bind(null,props.attribute)}/>
                </div>
            );
        };

        const fields = [
            {
                label:"Username",
                type:"text",
                attribute:"username"
            },
            {
                label:"First Name",
                type:"text",
                attribute:'firstName'
            },
            {
                label:"Last Name",
                type:"text",
                attribute:'lastName'
            },
            {
                label:"User Description",
                type:"text",
                attribute:'description'
            }
        ];

        const form_fields = fields.map((v,i)=>{
            return <StandardField key={i} {...v} first={i===0}/>;
        });


        return (
            <div style={{paddingRight:'1em'}}>
                {form_fields}
            </div>
        );
    }

    render(){
        const {userGroups} = this.props;

        return (
            <StyledDialog
                {...this.props}
                title={"Add User"}
                //contentStyle={{maxWidth:'600px',minWidth:'400px'}}
                onClose={this.props.onRequestClose}
                contentClassName="update-user-group-dialog"
                actions={
                    <>
                        <SaveButton onClick={this.handleSave} />
                        <CancelButton style={{position: 'absolute', left: 8, bottom: 8}} onClick={this.props.onRequestClose} />
                    </>
                }
            >
                <div style={{maxWidth:'600px',minWidth:'400px'}}>
                    {this.renderBasicInfo()}
                    <div className="users-form-standard-field">
                        <div style={{position:'relative',paddingRight:'1em'}}>
                            <label htmlFor={"group"} className="label" >{"Groups"}</label>
                            <Checklist defaultValue={[]} options={userGroups} onChange={this.handleChange.bind(null,"groups")} style={{width:'100%',boxSizing:'border-box', maxHeight:"10.5em"}}/>
                        </div>
                    </div>
                </div>
            </StyledDialog>
        );
    }
}


/*
 ██████  ██████  ███    ██ ███    ██ ███████  ██████ ████████     ████████  ██████
██      ██    ██ ████   ██ ████   ██ ██      ██         ██           ██    ██    ██
██      ██    ██ ██ ██  ██ ██ ██  ██ █████   ██         ██           ██    ██    ██
██      ██    ██ ██  ██ ██ ██  ██ ██ ██      ██         ██           ██    ██    ██
 ██████  ██████  ██   ████ ██   ████ ███████  ██████    ██           ██     ██████

███████ ████████  █████  ████████ ███████
██         ██    ██   ██    ██    ██
███████    ██    ███████    ██    █████
     ██    ██    ██   ██    ██    ██
███████    ██    ██   ██    ██    ███████
*/




const mapStateToProps = state => ({
    open: state.dataEntry.open && state.dataEntry.activeForm === USERMGMT_ADD_USER,
    userGroups: state.userMgmt.groups.map(g=>g.groupName)
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        onRequestClose: actions.closeDataEntry,
        addUser: actions.addUser
    },dispatch)
});

export default connect(mapStateToProps,mapDispatchToProps)(AddUserDialog);
