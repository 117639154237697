import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

import StyledDialog from '../../util/Dialog';

class ConfirmDialog extends React.Component {

    constructor(){
        super();

        this.handleSave = this.handleSave.bind(this);
        this.handleClose = this.handleClose.bind(this);

    }

    static get propTypes(){
        return {
            open: PropTypes.bool,
            onClose: PropTypes.func,
            onSubmit: PropTypes.func,
            content: PropTypes.any,
            subtitle: PropTypes.string,
            confirmText: PropTypes.string,
            confirmColor: PropTypes.string
        };
    }

    static get defaultProps(){
        return {
            open: false,
            onClose:function(){},
            onSubmit:function(){}
        };
    }

    handleClose ()  {
        this.props.onClose();
    }

    handleSave(){
        this.props.onSubmit();
    }


    render() {

        const {
            actions, // eslint-disable-line
            content,
            confirmText,
            confirmColor,
            styleWidth,
            ...other
        } = this.props;

        const mainButtons = (
            <div className='dialog-buttons'>
                <Button style={{color:confirmColor || '#00779f'}} onClick={this.handleSave}>{confirmText || "Confirm"}</Button>
                <Button onClick={this.handleClose} style={{position: 'absolute', left: 8, color:'#777'}}>Cancel</Button>
            </div>
        );

        return (
            <StyledDialog
                title={(<div><span>Confirm</span> <span style={{fontWeight:'300',fontSize:'0.9em'}}>{this.props.subtitle}</span></div>)}
                open={this.props.open}
                onRequestClose={this.handleClose}
                onClose={this.handleClose}
                actions={mainButtons}
                {...other}
                >
                <div style={styleWidth?{width:styleWidth}:{width: 360}}>
                    {content}
                </div>
            </StyledDialog>

        );
    }
}

export default ConfirmDialog;
