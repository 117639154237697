import React from 'react';
import moment from "moment/moment";
import TimePicker from 'rc-time-picker';

class TimePickerComponent extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            timeInit: {hour: 0, min: 0, sec: 0},
            timeEnd: {hour: 23, min: 59, sec: 59}
        };
        this.onClick = this.onClick.bind(this);
        this.onChangeInit = this.onChangeInit.bind(this);
        this.onChangeEnd = this.onChangeEnd.bind(this);
    }

    UNSAFE_componentWillMount(){
        if(this.props.timeInit != null && this.props.timeEnd != null){
            this.setState({
                timeInit: {
                    hour: this.props.timeInit ? this.props.timeInit.getHours() : "",
                    min: this.props.timeInit ? this.props.timeInit.getMinutes() : "",
                    sec: this.props.timeInit ? this.props.timeInit.getSeconds() : ""
                },
                timeEnd: {
                    hour: this.props.timeEnd ? this.props.timeEnd.getHours() : "",
                    min: this.props.timeEnd ? this.props.timeEnd.getMinutes() : "",
                    sec: this.props.timeEnd ? this.props.timeEnd.getSeconds() : ""
                }
            })
        }
    }

    componentDidMount(){
        if(this.props.timeInit != null && this.props.timeEnd != null) {
            this.refs.initTime.value = moment(this.props.timeInit);
        }
    }

    onClick(e){
        e.stopPropagation();
        this.props.requestTagHistory(this.state.timeInit, this.state.timeEnd, e);
    }

    onChangeInit(value, e){
        this.setState({
            timeInit: {
                hour: value ? value.toDate().getHours() : "",
                min: value ? value.toDate().getMinutes() : "",
                sec: value ? value.toDate().getSeconds() : ""
            }
        });
    }

    onChangeEnd(value){
        this.setState({
            timeEnd: {
                hour: value ? value.toDate().getHours() : "",
                min: value ? value.toDate().getMinutes() : "",
                sec: value ? value.toDate().getSeconds() : ""
            }
        })
    }

    render(){
        const {use12Hours} = this.props;
        return (
            <div key={"timeWrapper"}>
                <table>
                    <tbody>
                    <tr>
                        <td><span>Start Time:</span></td>
                        <td><TimePicker
                            ref={"initTime"}
                            defaultValue={moment().hour(0).minute(0).second(0)}
                            value={moment().hour(this.state.timeInit.hour).minute(this.state.timeInit.min).second(this.state.timeInit.sec)}
                            onChange={this.onChangeInit}
                            use12Hours={use12Hours}
                        /></td>
                    </tr>
                    <tr>
                        <td><br/></td>
                    </tr>
                    <tr>
                        <td><span>End Time:</span></td>
                        <td><TimePicker
                            ref={"endTime"}
                            defaultValue={moment().hour(23).minute(59).second(59)}
                            value={moment().hour(this.state.timeEnd.hour).minute(this.state.timeEnd.min).second(this.state.timeEnd.sec)}
                            onChange={this.onChangeEnd}
                            use12Hours={use12Hours}
                        /></td>
                    </tr>
                    <tr>
                        <td><br/></td>
                    </tr>
                    <tr>
                        <td colSpan={2} style={{textAlign: 'center'}} ><button style={{
                            width: '6em',
                            minWidth: '5em',
                            height: '2em',
                            fontSize: '0.8em',
                            lineHeight: '1.5em',
                            border: 'none',
                            backgroundColor: '#00779f',
                            color: 'white',
                            margin: '0 0.2em',
                            float: 'left',
                            boxSizing: 'border-box',
                            overflow: 'hidden',
                            verticalAlign: 'middle',
                            display: 'inline-block',
                            position: 'absolute',
                            left: '40%',
                            top: '80%'
                        }} onClick={this.onClick} >Set Time</button></td>
                    </tr>

                    </tbody>
                </table>
            </div>
        );
    }
}

export default TimePickerComponent;