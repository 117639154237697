import React from 'react';
import PropTypes from 'prop-types';

import {Field} from '../../../../util/DataEntryTabularForm';
import Checkbox from '@material-ui/core/Checkbox';
import BaseFilterForm from './BaseFilterForm';

export default class ControlledFilterForm extends React.Component{
    static get propTypes(){
        return {
            schema: PropTypes.arrayOf(
                PropTypes.shape({
                    key: PropTypes.string.isRequired,
                    type: PropTypes.string.isRequired,
                    label: PropTypes.string
                })
            ),
            onChange: PropTypes.func
        };
    }

    static get defaultProps(){
        return {
            schema: [],
            onChange: ()=>{}
        };
    }

    constructor(){
        super();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.renderField = this.renderField.bind(this);
    }

    handleInputChange(field, event){

        const {key,type} = field;

        let value = event.currentTarget.value;

        if(type==="number"){
            value = parseInt(value);
        }
        if(type==="checkbox"){
            value= event.currentTarget.checked;
        }

        this.props.onChange({
            [key]: value
        });
    }

    render(){

        const {schema} = this.props;

        return (
            <BaseFilterForm {...this.props}>
                {schema.map(this.renderField)}
            </BaseFilterForm>
        );
    }

    renderField(field){
        let innerField = null;
        switch(field.type){
            case "group":
                innerField = (
                    <fieldset>
                        <table style={{width:'100%'}}><tbody>
                            {field.fields.map(this.renderField)}
                        </tbody></table>
                    </fieldset>
                );
                break;
            case "checkbox":
                innerField = (
                        <div>
                                <Checkbox
                                    onChange={this.handleInputChange.bind(null,field)}
                                    checked={this.props[field.key]}
                                    style={{backgroundColor:'transparent', color: this.props[field.key]?'#43a047':'inherit', maxHeight: 20, padding:0}}
                                    disableTouchRipple/>
                            <span style={{paddingLeft:'0.5em'}}>{field.name}</span>
                        </div>);
                break;
            default:
                innerField = (<input type={field.type} defaultValue={this.props[field.key]} onChange={this.handleInputChange.bind(null,field)}/>);
                break;
        }

        return (
            <Field label={field.label} key={field.key}>
                {innerField}
            </Field>
        );
    }
}
