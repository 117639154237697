import React from 'react';
import PropTypes from 'prop-types';
import ZPage from './../../../../common/app/views/ZPage';
//import Paper from "@material-ui/core/Paper";
import Paper from '@material-ui/core/Paper';


import AppliancesToolbar from './toolbars/AppliancesToolbar';
// FORMS
import * as FORMS from '../../constants/DataEntryForms';
import AddApplianceDialog from './dataentry/AddApplianceDialog';
import UpdateApplianceDialog from './dataentry/UpdateApplianceDialog';
import UpdateFirmwareDialog from './dataentry/UpdateFirmwareDialog';
import DeleteApplianceDialog from './dataentry/DeleteApplianceDialog';
import DownloadDebugPackageDialog from './dataentry/DownloadDebugPackageDialog';
import RebootApplianceDialog from './dataentry/RebootApplianceDialog';

import ConfigHistoryDialog from './dataentry/taskHistory/ConfigHistoryDialog';
import FirmwareHistoryDialog from './dataentry/taskHistory/FirmwareHistoryDialog';
import StartStopServicesDialog from './dataentry/StartStopServicesDialog';

import ProfileManager from './dataentry/ProfileManager';

import WrappedTable from '../util/report/WrappedTable';

import NeedsUpdateIcon from '@material-ui/icons/Warning';
import UpToDateIcon from '@material-ui/icons/VerifiedUser';
import PendingUpdateIcon from '@material-ui/icons/SettingsBackupRestore';
import NoneAvailableIcon from '@material-ui/icons/NotInterested';

import StatusAliveIcon from '../icons/StatusAliveIcon';
import StatusDeadIcon from '../icons/StatusDeadIcon';

import RaisedButton from '@material-ui/core/Button';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../actions';
import DownloadServerLogsDialog from './dataentry/DownloadServerLogsDialog';
const TASK_STATUS_LABELS = {
    "pending": "Scheduled",
    "scheduled": "Scheduled",
    "processing": "Processing",
    "completed": "Successful",
    "error": "Failed",
    "cancelled": "Cancelled"
};

/**
 * Home Page for Nexus component
 */
class Appliances extends React.Component{
    /**
     *
     * @param props
     * @param context
     */
    constructor(props,context){
        super(props,context);

        this.state = {
            columnWidths:{
                "health":100,
                "serviceStatus":100,
                "softwareVersion":250,
                "softwareUpdateStatus":175,
                "configStatus":175,
                "siteId":125
            }
        };

        this.renderBodyCellContent = this.renderBodyCellContent.bind(this);
        this.getBaseColumnWidth = this.getBaseColumnWidth.bind(this);
        this.handleResizeColumn = this.handleResizeColumn.bind(this);
        this.handleAutoSizeColumns = this.handleAutoSizeColumns.bind(this);
    }

    componentDidMount(){
        this.props.actions.requestSites();
        this.props.actions.requestAppliances();
        this.props.actions.requestFirmwareVersions();

        this.requestInterval = setInterval(this.props.actions.requestAppliances,5000);
    }

    componentWillUnmount(){
        clearInterval(this.requestInterval);
    }

    handleResizeColumn(index,width){
        const {columns} = this.props;
        const {columnWidths} = this.state;

        this.setState({
            columnWidths:{
                ...columnWidths,
                [columns[index].name] : width
            }
        });
    }

    getAutoSizeWidth(column,data){
        data = data || this.props.data;
        const key = column.name;

        const getWidthFromTextLength = (data)=>{
            let nCharacters = column.caption.length;

            data.forEach(d=>{
                let len = (d || "").length;
                if(len > nCharacters) nCharacters = len;
            });

            return (nCharacters+1) * 10;
        };

        const {sites} = this.props;

        switch(column.name){
            case "siteId":
                return getWidthFromTextLength(data.map(d=>((sites[d.siteId] && sites[d.siteId].name) || "")));
            case "softwareUpgradeStatus":
                return 200;
            case "configStatus":
                return 200;
            default:
                return getWidthFromTextLength(data.map(d=>d[key]));
        }
    }

    handleAutoSizeColumns(index){
        const {columns} = this.props;

        const column = columns[index];

        const width = this.getAutoSizeWidth(column);

        this.setState({
            columnWidths:{
                ...this.state.columnWidths,
                [column.name] : width
            }
        });
    }



    renderCellStatus(datum){
        let indicator;

        if(datum === "good"){
            indicator = (
               <StatusAliveIcon style={{margin:'8px', fill:'none',stroke:'currentColor', color:'#0a0', strokeWidth:'2px'}} />
           );
       }else{
           indicator = (
               <StatusDeadIcon style={{margin:'8px', fill:'none',stroke:'currentColor', color:'#f00', strokeWidth:'2px'}} />
           );
       }

       return (
           <div>
               {indicator}
           </div>
       );
    }

    handleNewVersionAvailable(av_version, version){
        if(av_version !== null) {
            const arr_av_version = av_version.toString().split(".");
            const arr_version = version.toString().split(".");

            if(parseInt(arr_av_version[0]) > parseInt(arr_version[0])){
                return true;
            }

            if(parseInt(arr_av_version[0]) === parseInt(arr_version[0])){
                if(parseInt(arr_av_version[1]) > parseInt(arr_version[1])){
                    return true;
                }

                if (parseInt(arr_av_version[1]) === parseInt(arr_version[1])){
                    if(arr_av_version[2].indexOf("-") && arr_version[2].indexOf("-")){
                        const av_release = arr_av_version[2].split("-");
                        const curr_release = arr_version[2].split("-");
                        if(parseInt(av_release[0]) > parseInt(curr_release[0])){
                            return true;
                        }

                        if(parseInt(av_release[0])===parseInt(curr_release[0])){
                            if(parseInt(av_release[1]) > parseInt(curr_release[1])){
                                return true;
                            }
                        }
                    }

                    if(arr_av_version[2].indexOf("-") && !arr_version[2].indexOf("-")){
                        const av_release = arr_av_version[2].split("-");
                        if(parseInt(av_release[0]) > parseInt(arr_version[2])){
                            return true;
                        }
                    }

                    if(!arr_av_version[2].indexOf("-") && arr_version[2].indexOf("-")){
                        const curr_release = arr_version[2].split("-");
                        if(parseInt(arr_av_version[2]) > parseInt(curr_release[0])){
                            return true;
                        }
                    }
                }
            }
        }

        return false;
    }

    renderFirmwareVersion(version){
        if(!version) return null;

        let av_version = (this.props.firmwareVersions.length && this.props.firmwareVersions[0] !== version) && this.props.firmwareVersions[0];
        let updateAvailable = this.handleNewVersionAvailable(av_version, version);
        let updateText =  av_version && updateAvailable ? (
            <div style={{color:'#999',fontStyle:'italic',display:'table-cell',fontSize:'0.8em',lineHeight:'1em',paddingLeft:'0.5em',textAlign:'right',verticalAlign:'middle'}}>
                { av_version + " available"}
            </div>
        ) : null;

        return (
            <div style={{display:'table-row'}}>
                <div style={{display:'table-cell',whiteSpace:'nowrap'}}>
                    {version}
                </div>
                {updateText}
            </div>
        );
    }

    renderStatus(props){
        const {status,...other} = props;

        const iconStyle = {
            verticalAlign:'middle',
            margin:'2px 4px',
            flex: 2,
            color:'#aaa'
        };


        let icon,
            label,
            labelStyles = {letterSpacing:'0.5px'};



        switch(status){
            case "completed":
                icon = (
                    <UpToDateIcon style={{...iconStyle}} />
                );
                break;
            case "pending":
            case "scheduled":
            case "processing":
                icon = (
                    <PendingUpdateIcon style={{...iconStyle}} />
                );
                break;
            case "error":
                icon = (
                    <NeedsUpdateIcon style={{...iconStyle,color:'red'}}/>
                );
                labelStyles={...labelStyles, color:"#d00"};
                break;

            case "cancelled":
                icon = (
                    <NoneAvailableIcon style={{...iconStyle}} />
                );
                break;
            default:
                icon = (
                    <NoneAvailableIcon style={{...iconStyle}} />
                );
                break;
        }

        label = TASK_STATUS_LABELS[status] || "None";

        const heightConstraints = {
            height:'28px',
            lineHeight:'28px',
            overflow:'hidden'
        };



        return (
            <RaisedButton
                // overlayStyle={{padding:'0 0.5em'}}
                style={{...heightConstraints,verticalAlign:'middle',width:'100%', fontSize:'0.9em'}}
                variant="contained"
                {...other}
            >
                    {icon}
                    <div style={{flex: 6, textAlign: 'right', marginRight: 8 , textTransform: 'none', ...labelStyles}}>{label}</div>
            </RaisedButton>
        );

    }

    renderFirmwareStatus(status,datum){

        const StatusButton = this.renderStatus;

        const handleRequestFirmwareHistory= ()=>{
            this.props.actions.openApplianceDataEntry(FORMS.APPLIANCES_FIRMWARE_HISTORY,[datum._id]);
        };

        return (
            <StatusButton status={status} onClick={handleRequestFirmwareHistory} />
        );
    }

    renderConfigStatus(status,datum){

        const StatusButton = this.renderStatus;

        const handleRequestConfigHistory = ()=>{
            this.props.actions.openApplianceDataEntry(FORMS.APPLIANCES_UPDATE_CONFIG,[datum._id]);
        };

        return (
            <StatusButton status={status} onClick={handleRequestConfigHistory} />
        );

    }

    renderServices(datum){
        switch(datum){
            case "active":
                return "Running";
            case "inactive":
                return "Stopped";
            case "offline":
                return (<span style={{color:'red'}}>Offline</span>);
            default:
                return (<span style={{textTransform:'capitalize'}}>{datum}</span>);
        }
    }

    renderInnerBodyCellContent({columnIndex,datum}){
        const {columns} = this.props;
        if(columnIndex<0||columnIndex >= columns.length) return "";
        const col = columns[columnIndex];
        const d = datum[col.name];

        switch(col.name){
            case "health":
                return this.renderCellStatus(d);
            case "serviceStatus":
                return this.renderServices(d);
            case "softwareVersion":
                return this.renderFirmwareVersion(d,datum);
            case "softwareUpgradeStatus":
                return this.renderFirmwareStatus(d,datum);
            case "configStatus":
                return this.renderConfigStatus(d,datum);
            case "siteId":
                return this.props.sites[d] && this.props.sites[d].name;
            default:                
                return d;
        }
    }


    renderBodyCellContent(o){
        return (
            <div style={{padding:'0 5%',boxSizing:'border-box'}}>
                {this.renderInnerBodyCellContent(o)}
            </div>
        );
    }

    getBaseColumnWidth({index}){
        const {columns} = this.props;
        const {columnWidths} = this.state;

        return columnWidths[columns[index].name] || 250;
    }

    /**
     *
     * @returns {ReactElement} markup
     */
    render (){

        const {
            actions,
            view,
            dataEntryOpen,
            dataEntryActiveForm,
            data,
            columns,
            initialFormData,
            selectedAppliances,
            sites,
            tasks,
            packages,
            firmwareVersions,
            canEdit,
            isMobile
        } = this.props;

        const filteredColumns = columns.filter((v)=>v.enabled);

        const sitesWithoutAppliances = filterSitesWithAppliances(sites,data);

        const currentlySelectedAppliance = (initialFormData && initialFormData.appliances && initialFormData.appliances[0]);
        const sitesWithoutAppliancesAndCurrent =  currentlySelectedAppliance ? {
            ...sitesWithoutAppliances,
            [currentlySelectedAppliance.siteId] : sites[currentlySelectedAppliance.siteId]
        } : {};

        return (
            <ZPage zBreadCrumbs={["Home"]}
                zSubHeaderHeight={100}
                zShowSubHeader={false} >
                <div>
                    <AppliancesToolbar actions={actions} nrows={data.length} columns={columns} isMobile={isMobile} rowsSelected={Object.keys(selectedAppliances).filter(app=>selectedAppliances[app])} canEdit={canEdit}/>

                    <div className={"appliances-page page"}>
                        <Paper className={"appliances-container"}>
                            <WrappedTable
                                view={view}
                                data={data}
                                columns={filteredColumns}
                                actions={actions}
                                bodyCellContentRenderer={this.renderBodyCellContent}
                                getBaseColumnWidth={this.getBaseColumnWidth}
                                disableFilters
                                onTouchTapRow={function(){}}
                                onTouchTapCheckbox={(index,datum)=>{
                                    this.props.actions.toggleAppliance(datum._id);
                                }}
                                isSelected={(index,datum)=>{
                                    return this.props.selectedAppliances[datum._id] || false;
                                }}
                                onSelectAll={this.props.actions.toggleAllAppliances}
                                onResizeColumn={this.handleResizeColumn}
                                onRequestAutoSize={this.handleAutoSizeColumns}
                                disableFillTable={false}
                                disableSort
                            />
                        </Paper>
                    </div>
                </div>
                <ProfileManager />
                <RebootApplianceDialog />
                <AddApplianceDialog open={dataEntryOpen && dataEntryActiveForm === FORMS.APPLIANCES_ADD_APPLIANCE} actions={actions} data={{...initialFormData,sites:sitesWithoutAppliances}}/>
                <UpdateApplianceDialog open={dataEntryOpen && dataEntryActiveForm === FORMS.APPLIANCES_UPDATE_APPLIANCE} actions={actions}  data={{...initialFormData,sites:sitesWithoutAppliancesAndCurrent}}/>
                <DeleteApplianceDialog open={dataEntryOpen && dataEntryActiveForm === FORMS.APPLIANCES_DELETE_APPLIANCE} actions={actions} data={{...initialFormData,sites}} />

                <ConfigHistoryDialog open={dataEntryOpen && dataEntryActiveForm === FORMS.APPLIANCES_UPDATE_CONFIG} actions={actions}  data={{...initialFormData,tasks}}/>
                <FirmwareHistoryDialog open={dataEntryOpen && dataEntryActiveForm === FORMS.APPLIANCES_FIRMWARE_HISTORY} actions={actions} data={{...initialFormData,tasks}} />
                <DownloadServerLogsDialog open={dataEntryOpen && dataEntryActiveForm === FORMS.APPLIANCES_DOWNLOAD_SERVICES_LOGS} actions= {actions} />
                <DownloadDebugPackageDialog open={dataEntryOpen && dataEntryActiveForm === FORMS.APPLIANCES_DEBUG_PACKAGE} data={{...initialFormData,packages}} />

                <UpdateFirmwareDialog open={dataEntryOpen && dataEntryActiveForm === FORMS.APPLIANCES_UPDATE_FIRMWARE} actions={actions}  data={{...initialFormData,firmwareVersions}}/>
                <StartStopServicesDialog open={dataEntryOpen && dataEntryActiveForm === FORMS.APPLIANCES_SERVICES} actions={actions} data={initialFormData} />
            </ZPage>

        );
    }

    static get propTypes(){
        return {
            actions: PropTypes.object,
            view: PropTypes.any,
            dataEntryOpen: PropTypes.bool,
            dataEntryActiveForm: PropTypes.string,
            initialFormData: PropTypes.object,
            data: PropTypes.array,
            columns: PropTypes.array,
            sites: PropTypes.object,
            tasks: PropTypes.object,
            packages: PropTypes.object,
            firmwareVersions: PropTypes.array,
            selectedAppliances: PropTypes.object,
            canEdit: PropTypes.bool
        };
    }

    static get defaultProps(){
        return {
            dataEntryOpen: false
        };
    }

}

function filterSitesWithAppliances(sites,appliances){
    let o = {...sites};
    appliances.forEach(appliance=>{
        delete o[appliance.siteId];
    });

    return o;
}


const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions,dispatch)
});

const mapStateToProps = state =>({
    view: state.view.default,
    dataEntryOpen: state.dataEntry.open,
    dataEntryActiveForm: state.dataEntry.activeForm,
    initialFormData: state.dataEntry.initialFormData,
    columns: state.appliances.columns,
    data: state.appliances.data,
    sites: state.appliances.sites,
    tasks: state.appliances.tasks,
    packages: state.appliances.packages,
    firmwareVersions: state.appliances.firmwareVersions,
    selectedAppliances: state.appliances.selected,
    canEdit: state.user.permissions['edit-infrastructure-appliances'],
    isMobile: state.resize.isMobile,
});

export default connect(mapStateToProps,mapDispatchToProps)(Appliances);
