import React, {useState, useEffect} from 'react';
import {useDispatch} from "react-redux";
import {
    changeMapView,
    selectDevicesSite,
    changeStateShowMaps,
} from "../../../actions/device-manager";
import {LOCAL_MAP_VIEW, WORLD_MAP_VIEW, ALL_MAPS} from "../../../constants/DeviceManager";
import ReactToolTip from 'react-tooltip';
const MapInformation = ({site, map, selectMap}) => {

    const [siteName, setSiteName] = useState(" ");
    const [mapName, setMapName] = useState(" ");
    const [lastEdited, setLastEdited] = useState(" ");
    const [countDevices, setCountDevices] = useState(" ");
    const [src, setSrc] = useState("");
    const [allMaps, setAllMaps] = useState(" ");
    const [showSiteName, setShowSiteName] = useState(" ");
    const [selected, setSelected] = useState(false);
    const [isConfiguredCoordinates, setIsConfiguredCoordinates] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
       setSiteName(map.siteName);
       setMapName(map.name);
       setLastEdited(map.lastEdited);
       setCountDevices(map.countDevices);
       setAllMaps(map.allMaps);
       setShowSiteName(map.showSiteName);
       if(map.allMaps){
           setSrc("assets/images/map-dev.png")
       } else {
           setSrc(map.src);
           setIsConfiguredCoordinates(map.isConfiguredCoordinates);
       }
       setSelected(map.selected);
    }, [map.siteName, map.name, map.lastEdited, map.countDevices, map.allMaps, map.src, map.showSiteName, map.selected,map.isConfiguredCoordinates]);
    let className = selected ? "map-information-container map-information-container-selected": "map-information-container";
    className = isConfiguredCoordinates ? className : className+" map-information-disable-component";

    const handleSelectMap = () => {
        if(!selected) {
            if (!map.allMaps) {
                //if a map is selected
                let applianceId = site ? site.applianceId : map.applianceId;

                let mapObject = {...map, showSiteName: true, selected: true, isMap: true, applianceId: applianceId};
                dispatch(changeMapView(LOCAL_MAP_VIEW));
                selectMap(mapObject);
            } else if(site){
                //if a site is selected
                //dispatch(changeMapView(WORLD_MAP_VIEW));
                let mapObject = {
                    id: null,
                    name: null,
                    selected: true,
                    showSiteName: true,
                    countDevices: map.countDevices,
                    siteId: site.id,
                    siteName: site.name,
                    isMap: false,
                    applianceId: site.applianceId
                };
                dispatch(changeMapView(LOCAL_MAP_VIEW));
                dispatch(changeStateShowMaps(false));
                dispatch(selectDevicesSite(mapObject));
            }else {
                //if all sites and maps is selected
                dispatch(changeMapView(WORLD_MAP_VIEW));
                selectMap(null);
            }

        }
    };

    let information =
        <>
            <div className={"map-information"}>
                <div className={"site-name"}>{showSiteName ? siteName : ""}</div>
                <div className={"map-name"}>{mapName}</div>
                <div className={"map-info-edited"}>{(!allMaps&&lastEdited!=null) ? "Last edited "+ lastEdited : ''}</div>
            </div>
            <div className={"map-image"}>
                <img alt={mapName} src={allMaps ? ALL_MAPS : src}/>
                <div className={"map-devices-count"}>
                    {countDevices}
                </div>
            </div>
        </>
    return (
        <>
            {
                isConfiguredCoordinates ?
                    <div className={className} onClick={handleSelectMap}>
                        {information}
                    </div>:
                    <div className={className} data-tip={"Coordinates are not set on this map. Please configure the map on SiteManager."} data-for={'mapInformation'} >
                        {information}
                        <ReactToolTip className={"tooltip-map-class"} place={"top"} id={"mapInformation"} effect={"solid"} />
                    </div>
            }
        </>
    )

};

export default MapInformation;