import React, {useEffect, useState, Fragment} from 'react';
import CustomCell from "./CustomCell";
import AppliedFiltersLanding from "./AppliedFiltersLanding";
import CrossBrowserTable from "../common/tableComponent/CrossBrowserTable";
import MapMainContainer from "../maps/MapMainContainer";
import DeviceDetails from "./DeviceDetails";
import {useSelector, useDispatch} from 'react-redux';
import {
    loadDeviceList,
    unloadDeviceList,
    selectAll,
    selectRow,
    unSelectRow,
    loadDeviceManagerScreen,
    changeStateShowMaps,
    changeStatusModalDetails,
    changeStatusDetails,
    changeOperationCheck,
    setOpenAlert, reloadSitesMaps,
    // unloadSiteMaps,
    startPollingOperationStatus, stopPollingOperationStatus, updateConfigurationOption,
    setErrorMessage,
    setDevicesIds
} from "../../../actions";
import SplitPane from "../../../../../common/app/views/table/splitpane/SplitPane";
import {
    MAP_REPORT_HORIZONTAL_REVERSE_SPLIT,
    MAP_REPORT_HORIZONTAL_SPLIT,
    MAP_REPORT_VERTICAL_REVERSE_SPLIT,
    MAP_REPORT_VERTICAL_SPLIT,
    REPORT_VIEW, MAP_VIEW
} from "../../../constants/ViewTypes";
import LandingMapContainer from "../common/LandingMapContainer";
import {
    LANDING_PAGE_SCREEN,
    ALERT_MESSAGE,
    LANDING_DEVICE_TABLE
} from "../../../constants/DeviceManager";
import DeviceManagerToolbar from '../toolbars/DeviceManagerToolbar';
import FetchScreen from '../common/FetchScreen';
import ModalDeviceDetails from '../common/dialogs/ModalDeviceDetails';
import {getSidebarSizes} from '../../../constants/Responsive';
import FilterTree from "../landing/FilterTree";
import EditMenuHeader from "../landing/EditMenuHeader";
import AlertMessage from '../common/AlertMessage';
import DialogStatusHistory from "../common/dialogs/DialogStatusHistory";
import DialogListStatusHistory from "../common/dialogs/DialogListStatusHistory";
import ErrorAlert from '../common/dialogs/logsComponents/ErrorAlert';

const LandingDeviceManager = () => {
    const dispatch = useDispatch();
    const MAX_RIGHT_OFFSET = 200;
    const columnOrder = useSelector(state => state.deviceManager.columnOrder.devices);
    const showMaps = useSelector(state => state.deviceManager.showMaps);
    const modernBrowser = useSelector(state => state.user.modernBrowser);
    const openAlert = useSelector(state => state.deviceManager.openAlert);
    const [header, setHeader] = useState([]);
    const [headerHeight, setHeaderHeight] = React.useState(0);
    const [orientation, setOrientation] = React.useState('vertical');
    const [reverseView, setReverseView] = React.useState(false);
    const [resizeTrigger, setResizeTrigger] = useState((new Date()).getTime());
    const selectedRows = useSelector(state => state.deviceManager.selectedRows);
    const deviceList = useSelector(state => state.deviceManager.deviceList);
    const showDetails = useSelector(state => state.deviceManager.showDetails);
    const showModalDetails = useSelector(state => state.deviceManager.showModalDetails);
    const view = useSelector(state => state.view['DEVICE_MANAGER']||REPORT_VIEW);
    const deviceManagerScreen = useSelector(state => state.deviceManager.deviceManagerScreen);
    const isFetchingDevice = useSelector(state => state.deviceManager.isFetchingDevice);
    const isFetching = useSelector(state => state.deviceManager.isFetching);
    const sortIndex = useSelector(state => state.deviceManager.sortIndex);
    const deviceColumnConfig = useSelector(state => state.deviceManager.deviceColumnConfig);
    const [selectedRowsTable, setSelectedRowsTable] = useState({});
    const isOnCloud = useSelector(state => state.deviceManager.isOnCloud);
    const mapSelected = useSelector(state => state.deviceManager.mapSelected);
    const {error, message} = useSelector(state => state.deviceManager.errorLoadDevicesList);

    // sidebar constants
    const [sidebarWidth, setSidebarWidth] = useState(300);
    const [sidebarResizing, setSidebarResizing] = useState(false);
    const [animationEnabled, setAnimationEnabled] = useState(false);
    const [openHistory, setOpenHistory] = useState(false);
    const [openStatusDialog, setOpenStatusDialog] = useState(false);
    const [statusToShow, setStatusToShow] = useState(false);
    const [deviceId, setDeviceId] = useState(null);

    const handleLoadPooling =() => {
        if(deviceList.length > 0) {
            const devicesId = deviceList.map(item => item._id);
            dispatch(setDevicesIds(devicesId));
        }
    };
    useEffect(handleLoadPooling, [deviceList]);

    const handleResize = e => {
        if(e.event === 'mousedown'){
            setAnimationEnabled(false);
            setSidebarResizing(true);
            setSidebarWidth(oldState => e.width || oldState);
        }else if(sidebarResizing){
            setAnimationEnabled(false);
            setSidebarResizing(e.resizing);
            setSidebarWidth(oldState => e.width || oldState);
        }
    }
    useEffect(() => {
        const headerRowOrdered = columnOrder.filter(item => item.enabled);
        setHeader(headerRowOrdered.length === 1 ? []: headerRowOrdered.map(item => deviceColumnConfig[item.name]));
        },[columnOrder,deviceColumnConfig]
    );

    const handleClickRow = (rowIndex) => {
        const deviceId = deviceList[rowIndex]._id;
        // const newState = Object.assign({}, selectedRows);

        if (selectedRows.hasOwnProperty(deviceId)) {
            dispatch(unSelectRow(rowIndex))
        } else {
            dispatch(selectRow(rowIndex))
        }
    };

    const handleSelectAll = () => {
        if(Object.keys(selectedRows).length === 0){
            let newState = {};
            deviceList.forEach(item => newState[item._id] = item);
            dispatch(selectAll(newState));
        }else{
            dispatch(selectAll({}))
        }
    };

    const handleCloseAlert = () => {
        dispatch(setOpenAlert(false));
    };

    const requestLoadDevices=() => {
        dispatch(loadDeviceList());
        // dispatch(setPaginationInfo(true))
    };

    const loadDevices=() => {
        // Load first time on mount component.
        dispatch(startPollingOperationStatus());
        requestLoadDevices();
        dispatch(reloadSitesMaps(null, null,true));
        const unmount = () => {
            dispatch(stopPollingOperationStatus());
            dispatch(changeStatusModalDetails(false));
            dispatch(changeStatusDetails(false));
            dispatch(unloadDeviceList());
            dispatch(changeOperationCheck(LANDING_PAGE_SCREEN, false));
        }
        return unmount;
    }

    // Only call once when mount.
    useEffect(loadDevices, []);

    // View orientation has changed.
    useEffect(() => {

        let orientation;
        let reverse;

        switch (view) {
            case MAP_REPORT_VERTICAL_SPLIT:
                orientation = 'vertical';
                break;
            case MAP_REPORT_HORIZONTAL_SPLIT:
                orientation = 'horizontal';
                break;
            case MAP_REPORT_VERTICAL_REVERSE_SPLIT:
                reverse = true;
                break;
            case MAP_REPORT_HORIZONTAL_REVERSE_SPLIT:
                orientation = 'horizontal';
                reverse = true;
                break;
            default:
                orientation = 'vertical';
                reverse=false;
        }
        setReverseView(reverse);
        setOrientation(orientation);
    }, [view]);

    const handleDrag = size =>{
        setResizeTrigger((new Date()).getTime());
    };

    useEffect(() => {
        if(sidebarWidth === 0 && showDetails){
            const defaultWidth = getSidebarSizes().defaultWidth;
            if(defaultWidth===210){
                setSidebarWidth(300);
            }else{
                setSidebarWidth(defaultWidth);
            }
        }
        setResizeTrigger((new Date()).getTime());
    },[sidebarWidth, showDetails])

    const handleLoadScreen = () => {
        dispatch(loadDeviceManagerScreen(LANDING_PAGE_SCREEN));
    };

    useEffect(handleLoadScreen, []);

    useEffect(()=>{
        const devicesChecked = {};
        deviceList.forEach((element, index)=> {
            if(Object.keys(selectedRows).indexOf(element._id) > -1){
                devicesChecked[index] = element._id
            }
        });
        setSelectedRowsTable(devicesChecked)
    },[selectedRows, deviceList]);

   /* const handleLoadColumnsConfig = () => {
        dispatch(loadTableColumns(DEVICE_MANAGER_COLUMNS));

        return () => dispatch(loadTableColumns({}));
    };*/

    // useEffect(handleLoadColumnsConfig, []);
    const getConfigurationOptions = () =>{
        if(isOnCloud === undefined)
            dispatch(updateConfigurationOption());
    }
    useEffect(getConfigurationOptions, [isOnCloud]);

    const handleCloseErrorDialog = () => {
        dispatch(setErrorMessage(false, null))
    };

    const customHeader = ({text, tableWidth, tableHeight, itemKey}) => {
        const headerObjectKeys = {"alert":"health"};
        const customItemKey = Object.keys(headerObjectKeys).indexOf(itemKey) > -1?headerObjectKeys[itemKey]:itemKey;
        const items = deviceList.map(item => item[customItemKey]).filter((value, index, self) => self.indexOf(value) === index);
        let headerDescription = header.filter( headerDescription =>{ return headerDescription.key === customItemKey; });
        let columnConfig=deviceColumnConfig[customItemKey];
        let statusSortable = true;
        let search = false;
        if( headerDescription && headerDescription.length > 0){
            statusSortable = headerDescription[0].sortable;
            search = headerDescription[0].search;
        }

        return (
            <Fragment>
                {customItemKey === 'actions' ? <EditMenuHeader /> :
                    <FilterTree sortIndex={sortIndex}
                                items={items}
                                text={text}
                                tableWidth={tableWidth}
                                tableHeight={tableHeight}
                                tree={false}
                                itemKey={customItemKey}
                                type={'devices'}
                                statusSortable={statusSortable}
                                columnConfig={columnConfig}
                                tableScreen={LANDING_DEVICE_TABLE}
                                search={search}
                    />}
            </Fragment>
        );
    }
    const TableDeviceManager=<CrossBrowserTable
            data={deviceList}
            rowCount={deviceList.length}
            showCheckColumn={true}
            headerRow={header}
            headerRender={(...rest)=>customHeader(...rest)}
            cellRender={(...rest) => CustomCell(...rest)}
            selectedRows={selectedRowsTable}
            onClickRow={handleClickRow}
            onSelectAll={handleSelectAll}
            modernBrowser={modernBrowser}
            appliedFilters={(...rest) => AppliedFiltersLanding({...rest, setHeaderHeight, type:'devices'})}
            appliedFiltersHeight={headerHeight}
            setHeaderHeight={setHeaderHeight}
        />;

    const renderBody = () => {
        switch(view){
            case REPORT_VIEW:
                return TableDeviceManager;
            case MAP_VIEW:
                return (
                    <div className={'devices-map-container'}>
                        <div className={'table-react-window-container'}>
                            <AppliedFiltersLanding setHeaderHeight={setHeaderHeight} type={'devices'}/>
                        </div>
                        {deviceManagerScreen === LANDING_PAGE_SCREEN &&
                        <LandingMapContainer
                            devicesBatch={deviceList}
                            deviceManagerScreen={deviceManagerScreen}
                            resizeTrigger={resizeTrigger}
                            orientation={orientation} />}
                    </div>
                );
            default:
                return (
                    <SplitPane
                        defaultSize='50%'
                        onChange={handleDrag}
                        split={orientation}
                        maxSize={-MAX_RIGHT_OFFSET}
                        reverse={reverseView}>
                        <div className={'devices-table-container'} style={{width: '100%', height: '100%'}}>
                            {TableDeviceManager}
                        </div>
                        <div className={'devices-map-container'}>
                        {deviceManagerScreen === LANDING_PAGE_SCREEN &&
                        <LandingMapContainer
                            devicesBatch={deviceList}
                            deviceManagerScreen={deviceManagerScreen}
                            resizeTrigger={resizeTrigger}
                            orientation={orientation} />}
                        </div>
                    </SplitPane>
                );
        }
    }

    return (
        <div className={"device-manager-container"} style={isFetchingDevice?{pointerEvents:'none',opacity:'0.5'}:{}}>
            <div className={"device-manager-header"}>
                <DeviceManagerToolbar mapSelected={mapSelected}/>
            </div>
            <div className={"device-manager-content"}>
                <div className={'device-manager-landing-container'}>
                {showMaps ? <MapMainContainer setToggleMap={()=>dispatch(changeStateShowMaps(!showMaps))}/> : 
                    <React.Fragment>
                        <div className={'table-map-container'} style={{flexBasis: `calc(100% - ${showDetails?sidebarWidth:0}px)`}}>
                            {renderBody()}
                        </div>

                        {showDetails && 
                            <DeviceDetails                                       
                                identifier="rightSidebar"
                                resizing={sidebarResizing}
                                handleResize={handleResize}
                                width={sidebarWidth}
                                animationEnabled={animationEnabled}
                                onTransitionEnd={e => setAnimationEnabled(false)}
                                onClose={e => setSidebarWidth(0)}
                                setOpenHistory={setOpenHistory}
                                setDeviceId={setDeviceId}
                            />
                        }

                    </React.Fragment>}

                    {showModalDetails ? <ModalDeviceDetails open={showModalDetails} closeDialog={()=>dispatch(changeStatusModalDetails(false))}/>: null}
                    {openHistory ? <DialogListStatusHistory open={openHistory} setOpenHistory={setOpenHistory} setStatusToShow={setStatusToShow} setOpenStatusDialog={setOpenStatusDialog} deviceId={deviceId}/> : null}
                    {openStatusDialog ? <DialogStatusHistory statusHistory={statusToShow} open={openStatusDialog} setOpenHistory={setOpenStatusDialog} />: null}
                </div>
                {isFetching ? <FetchScreen />: null}
                <AlertMessage
                    openModal={openAlert}
                    onClose={handleCloseAlert}
                    message={ALERT_MESSAGE}
                />
                <ErrorAlert open={error} message={message} closeAlert={handleCloseErrorDialog} width={345}/>
            </div>
        </div>
    )
}

export default LandingDeviceManager;