import React, { useState, useEffect } from "react";
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
// import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {useDispatch, useSelector} from "react-redux";
import {
    selectFilter,
    getColumnListValues,
    setOpenAlert,
    sortingByColumns,
    updateColumnWidth, resetPaginationInfo
} from "../../../actions";
import {IE_MAX_CHARACTERS} from '../../../constants/DeviceManager';
import SortDirection from "@material-ui/icons/TrendingFlat";
import ColumnResizer from "./ColumnResizer";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 330,
        backgroundColor: theme.palette.background.paper,
        placement:'bottom-start',
        overflow:'auto',
    },
    button: {
        textTransform:'none',
        padding:'8px 10px',
        width:'100px',
        height:'42px'
    },
    list:{
        fontSize:'13px',
    },
}));

const FilterTree=({items, text, tableWidth, tableHeight, tree, itemKey, type, sortIndex,statusSortable,columnConfig,tableScreen,search})=> {
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();
    const [allChecked, setAllchecked] = useState(false);
    const [positionFilter, setPositionFilter] = useState('left');
    const [checked, setChecked] = useState([]);
    const [checkedObject, setCheckedObject] = useState([]);
    let [localChecked, setLocalChecked] = useState([]);
    let [queryParameters, setqueryParameters] = useState("");
    let [closeFilter, setCloseFilter] = useState(true);
    let [sortDirection, setSortDirection] = useState(-1);
    let [searchFilters, setSearchFilters] = useState([]);
    // const [listItems, setListItems] = useState([]);
    const dispatch = useDispatch();

    const selectedFilters = useSelector(state => state.deviceManager.selectedFilters[type]);
    const filters = useSelector(state => state.deviceManager.filters[itemKey]);
    const paginationInfo = useSelector(state => state.deviceManager.paginationInfo);

    const handleSetSearchFilters = () => {
        setSearchFilters(filters);
    };

    useEffect(handleSetSearchFilters, [filters]);

    const handleSetSortDirection = () => {
        setSortDirection(paginationInfo.sort?.direction || -1)
    };

    useEffect(handleSetSortDirection, []);

    useEffect(() => {
        if(anchorEl){
            dispatch(getColumnListValues(type, itemKey));  
        }
    },[dispatch, anchorEl, type, itemKey, items]);

    useEffect(()=>{
        let listCheckedItems = selectedFilters && selectedFilters.map(item => item[itemKey]);
        setChecked(listCheckedItems);
        setCheckedObject(selectedFilters);
    },[selectedFilters, itemKey]);

    /*const handleLoadLocalCheckedItems = () => {
        let checkedItems = selectedFilters.map(item => item[itemKey]);
        let localCheckedItems = filters && checkedItems.filter(item=> filters.indexOf(item) !== -1);
        setLocalChecked(localCheckedItems || []);
    };

    useEffect(handleLoadLocalCheckedItems, [filters]);*/

    const handleClickAway= () => {
        if(closeFilter) {
            setAnchorEl(null);
        }
        setCloseFilter(true);
    };

    //header button
    const handleOpenFilter = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        const elementPosition = event.currentTarget.getBoundingClientRect().x;
        setPositionFilter(elementPosition+340>tableWidth ? 'right': 'left');
    };

    //Link A to Z
    /*const handleAsc = () => {
        // setListItems([...listItems].sort());
    };
    const handleDesc = () => {
        // setListItems([...listItems].sort().reverse());        
    };*/

    const handleGetQueryParams = () => {
        let parameters ='';
        //create a string with the parameters that will be send
        if (checkedObject.length > 0) {
            Object.keys(checkedObject).forEach(function(key) {
                const currentFilter = checkedObject[key];
                Object.keys(currentFilter).forEach(function (subKey) {
                    if (parameters === ''){
                        parameters = subKey + "=" + currentFilter[subKey];
                    }
                    else {
                        parameters = parameters + "&" + subKey + "=" + currentFilter[subKey];
                    }
                })
            });
        }
        return parameters;
    };


    /// handle checkbox
    const handleClickItem = (value) => () => {
        const newState = {};
        const currentIndex = checked.indexOf(value.key);
        const newChecked = [...checked];
        const newCheckedObject =[...checkedObject];
        const auxLocalChecked = [...localChecked];
        if (currentIndex === -1) {

            // Add current value to the actual parameters
            let queryParam = handleGetQueryParams();
            queryParam += '&' + itemKey + '=' + value.key;
            if(queryParam.length > IE_MAX_CHARACTERS){
                dispatch(setOpenAlert(true));
                setCloseFilter(false);
            } else {
                newState[itemKey]= value.key;
                newChecked.push(value.key);
                newCheckedObject.push(newState);
                auxLocalChecked.push(value.key);
            }
        } else {
            newChecked.splice(currentIndex,1);

            // Remove query parameters
            const removeString = '&' + itemKey + '=' + value.key;
            let queryAux = queryParameters;
            queryAux = queryAux.replace(removeString,'');
            setqueryParameters(queryAux);

            let removeIndex = newCheckedObject.map((item) => item[itemKey]).indexOf(value.key);
            newCheckedObject.splice(removeIndex,1)
            auxLocalChecked.splice(currentIndex,1);
        }

        setChecked(newChecked);
        setCheckedObject(newCheckedObject);
        setLocalChecked(auxLocalChecked);
        if(allChecked) {
            setAllchecked(false);
        }
        /*if(filters && (filters.length === newChecked.length)){
            setAllchecked(true);
        }else{
            setAllchecked(false);
        }*/
    };

    // handle checkbox All
    const handleSelectAll = event => {
        /*const newChecked = [...checked];
        const newCheckedObject = [...checkedObject];*/

        setAllchecked(event.target.checked);
        if(filters && checked.length > 0 && checkedObject.length > 0)
        {
            setChecked([]);
            setCheckedObject([]);
            setLocalChecked([]);
        }
        /*else {
            filters.map((item) => {
                const isSelected = checked.indexOf(item);
                if (isSelected === -1){
                    let newData = {};
                    newData[itemKey] = item;
                    newCheckedObject.push(newData);
                    newChecked.push(item);
                }return newCheckedObject
            })
        }*/
        // setChecked(newChecked);
        // setCheckedObject(newCheckedObject);
    };

    const handleClickApply = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        dispatch(selectFilter(type, checkedObject));
    };

    const handleSortColumn = () => {
        if(statusSortable){
            const same = (sortIndex === itemKey);
            const directionAux = (same && (sortDirection === -1) && 1) || -1;
            setSortDirection(directionAux);
            dispatch(resetPaginationInfo());
            dispatch(sortingByColumns(itemKey, directionAux));
        }
    };
    let checkFilter = true;
    if(selectedFilters){
        let selectFilter = selectedFilters.filter(item => item.hasOwnProperty(itemKey));
        checkFilter = (selectFilter && selectFilter.length>0)?true:false;
    }
    const onResizeColumn = (newWidth) =>{
        dispatch(updateColumnWidth(itemKey,newWidth,tableScreen));
    }

    const handleSearchCriteria = (e) => {
        if(e.target.value.trim() === ''){
            setSearchFilters(filters);
        } else {
            const filterCriteria = filters ? filters.filter(filter => filter.value.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) : [];
            setSearchFilters(filterCriteria);
        }


    };

    return (text !== '' &&
        <ClickAwayListener onClickAway={handleClickAway}>
            <div style={{background: !anchorEl? 'transparent':'white', color:   'white' }} >
                <div className="buttonContainer">
                    <div className="buttonText">
                        <button className={"button-style"} onClick={handleSortColumn}>
                            <span className={checkFilter ? 'highlight' : ''}>
                                {text}
                                {(sortIndex === itemKey && statusSortable) ? (<SortDirection className={(sortDirection  < 0) ? "sort-asc" : "sort-desc"} />) : null}
                            </span>
                        </button>

                    </div>
                    {columnConfig?.filterable && <div className="buttonIcon" onClick={handleOpenFilter} style={checkFilter?{color: '#5296b2'}:{}} >
                        <ClearAllIcon/>
                    </div>}
                </div>
                {anchorEl && columnConfig?.filterable && <div className="filter-main-container" style={{maxHeight:tableHeight, float: positionFilter}}>
                    <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'}}>
                        <div style={{flex: 1}}>
                            <div className="filter-left-container">
                                <span className="filter-title">Filter</span>
                                {search && <input style={{
                                                        position:'absolute',
                                                        border: '1px solid #cecece',
                                                        right: '6px',
                                                        top: '8px',
                                                        fontSize:'13px'
                                                        }}
                                                  placeholder={"Type a criteria to filter"}
                                                  onChange={handleSearchCriteria}/>}
                            </div>
                            <div style={{paddingLeft:'7px'}}>
                                <FormGroup style={{height: '30px'}}>
                                    <FormControlLabel style={{height: '30px', fontSize: '14px'}} label='All'
                                        control={<Checkbox checked={allChecked} onChange={handleSelectAll}  style={{color: '#00779f'}} value="All" inputProps={{'fontSize': '10px'}}/>}                                  
                                    />
                                </FormGroup>
                            </div>
                            <Divider className="filter-divider"/>
                            <div>
                                <List className="filter-list" style={{maxHeight:(tableHeight - 200)}}>
                                    {searchFilters && searchFilters.slice(0, 500).map((listItem, index) => {
                                        // let disableItem = (localChecked.length >= MAX_ITEMS_SELECTED) && localChecked.indexOf(listItem) === -1;
                                        return (<ListItem disabled={false} className="filter-item-CheckBoxList" key={index}
                                                  role={undefined} dense button onClick={handleClickItem(listItem)}>
                                            <ListItemIcon style={{minWidth: '30px'}}>
                                                <Checkbox
                                                    edge="start"
                                                    checked={checked.indexOf(listItem.key) !== -1}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    className="filter-CheckBoxList"
                                                />
                                            </ListItemIcon>
                                            <ListItemText id={listItem.key} primary={listItem.value}
                                                          classes={{primary: classes.list}}/>
                                        </ListItem>)
                                    })}
                                    {searchFilters && searchFilters.length > 500 ?
                                        <ListItem style={{minWidth: '30px'}}>
                                            <span style={{color:'#449ccc', fontStyle:'italic'}}>Limit reached, type a criteria to filter</span>
                                        </ListItem>
                                        :null
                                    }
                                </List>
                            </div>
                        </div>
                        {/*<Divider orientation="vertical" flexItem className="filter-divider"/>
                        <div className="filter-right-container">
                            <div className="filter-right-title">
                                <span className="filter-title">Sort</span>
                            </div>
                            <div className="filter-left">
                                <div className="filter-left-sort" onClick={handleAsc}>
                                    <ArrowUpwardIcon className="filter-icon" />
                                    <span>A to Z</span>
                                </div>
                                <div className="filter-left-sort" onClick={handleDesc}>
                                    <ArrowDownwardIcon className="filter-icon" />
                                    <span>Z to A</span>
                                </div>
                            </div>
                        </div>*/}
                    </div>
                    <div className="filter-container-button">
                        <Button disabled={(queryParameters.length > IE_MAX_CHARACTERS)}
                                variant="contained"  onClick={handleClickApply} size="small"
                        className={(filters && filters.length>0)?"filter-button-apply": "filter-button-apply-disabled"}>Apply</Button>
                    </div>
                </div>}
                <ColumnResizer
                    onResize={onResizeColumn}
                    width={columnConfig && columnConfig.width}
                    minValue={50}
                />
            </div>
        </ClickAwayListener>
    );
};

export default FilterTree;