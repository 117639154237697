import React from 'react';
import PropTypes from 'prop-types';

import ConfirmDialog from '../../../appliances/dataentry/ConfirmDialog'; // really should move this...

class DeleteMapDialog extends React.Component{

    static get propTypes(){
        return {
            map: PropTypes.object,
            onConfirm: PropTypes.func
        };
    }

    render(){
        const {map,onConfirm} = this.props;

        const content = (map) ? (
            [
                <span key="span-1">Delete map {map.description}?</span>,
                <br key="br-1"/>,
                <br key="br-2"/>,
                <span style={{fontStyle:'italic',fontSize:'0.9em'}} key="span-2">This action will likely invalidate your site.json configuration.</span>
            ]
        ) : "";

        return (
            <ConfirmDialog
                subtitle={"Delete Site Map"}
                confirmText={"Delete"}
                confirmColor={"red"}
                content={content}
                onSubmit={onConfirm}
                {...this.props}
            />
        );
    }
}

export default DeleteMapDialog;
