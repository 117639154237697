/**
 * Nexus Application Routes constants goes here.
 * @type {{DASHBOARD: string, HOME: string, ALERT: string}}
 */
export const ROUTES = {
    DASHBOARD   :  "dashboard",
    REPORTS        :  "reports",
    SAVED       :  "saved",
    ALERTS         :  "alerts",
    CONFIG         :  "config",
    USERS         :  "users",
    HELP         :  "help",
    SITES : "sites",
    APPLIANCES: "appliances",
    LANDING: "landing",
    IMPORT: "import",
    ADD_DEVICES: "add-devices",
    ADD_DEVICES_BATCH: "add-devices-batch",
    EDIT_DEVICE: "edit-device",
    DEVICE_MANAGER: "device-manager",
    DEVICES: "devices",
    TEMPLATES: "templates"
};
