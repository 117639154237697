import React from 'react';
import PropTypes from 'prop-types';

import UpArrowIcon from '@material-ui/icons/ArrowUpward';
import DownArrowIcon from '@material-ui/icons/ArrowDownward';

import IconButton from '@material-ui/core/IconButton';

export default class ListManager extends React.Component{

    static get propTypes(){
        return {
            initialItems: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string.isRequired,
                    label:PropTypes.string.isRequired
                })
            ),
            onSelectItem: PropTypes.func,
            onChangeOrder: PropTypes.func
        };
    }

    static get defaultProps(){

        const nop = ()=>{};

        return {
            initialItems: [
                {id:'one',label:'one'},
                {id:'two',label:'two',disabled: true},
                {id:'three',label:'three'}
            ],
            onSelectItem: nop,
            onChangeOrder: nop
        };
    }

    constructor(props){
        super(props);

        this.state = {
            selectedIndex: 0,
            items: props.initialItems
        };

        this.handleSelect = this.handleSelect.bind(this);
        this.handleUp = this.handleUp.bind(this);
        this.handleDown = this.handleDown.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(nextProps.initialItems !== this.props.initialItems){
            this.setState({
                items: nextProps.initialItems
            });
        }
    }



    handleSelect(index){
        this.setState({
            selectedIndex: index
        });

        this.props.onSelectItem(this.state.items[index],index);
    }

    moveItemInArray(originalArray,fromIndex,toIndex){
        let array = [].concat(originalArray);

        let movedItem = array.splice(fromIndex,1)[0];

        array.splice(toIndex,0,movedItem);

        return array;
    }

    handleDown(){
        const {selectedIndex,items} = this.state;
        if(selectedIndex >= items.length - 1) return; // don't move

        const newIndex = selectedIndex + 1;
        const _items = this.moveItemInArray(items,selectedIndex,newIndex);

        this.setState({
            items : _items,
            selectedIndex: newIndex
        });

        this.props.onChangeOrder(_items);
    }

    handleUp(){
        const {selectedIndex,items} = this.state;
        if(selectedIndex <= 0) return; // don't move

        const newIndex = selectedIndex - 1;
        const _items = this.moveItemInArray(items,selectedIndex,newIndex);

        this.setState({
            items : _items,
            selectedIndex: newIndex
        });

        this.props.onChangeOrder(_items);
    }


    render(){

        const {items, selectedIndex} = this.state;

        let sharedStyles = {
            cursor:'pointer',
            padding:'0 0.5em',
            outline:'none'
        };

        let _items  = items.map((it,index)=>{

            let _style = (index === selectedIndex) ? {
                ...sharedStyles,
                background:"#00779f"
            } : {
                ...sharedStyles
            };

            let labelStyle = {};
            if(index === selectedIndex){
                labelStyle = {
                    ...labelStyle,
                    color:'#fff'
                };
            }

            if(it.disabled){
                labelStyle = {
                    ...labelStyle,
                    opacity:'0.5'
                };
            }

            return (
                <div
                    key={it.id}
                    onClick={this.handleSelect.bind(this,index)}
                    style={_style}
                    >
                    <span style={labelStyle}> {it.label} </span>
                </div>
            );
        });

        const itemContainerStyles = {
            border:'solid 1px',
            position:'absolute',
            top:0,
            bottom:0,
            left:0,
            right:'2em',
            boxSizing:'border-box',
            overflow:'auto'
        };

        const iconButtonStyles = {
            height:'auto',
            width:'auto',
            padding:'2px',
            border:'solid 1px',
            marginBottom:'16px',
            color: '#000',
            borderRadius: 0
        };

        return (
            <div>
                <div style={itemContainerStyles}>
                    {_items}
                </div>
                <div style={{position:'absolute',top:0,bottom:0,right:'-0.25em',width:'2em'}}>
                    <div style={{position:'absolute',top:'20%',transform:'translateY(-50%)'}}>
                        <IconButton onClick={this.handleUp} style={iconButtonStyles} disableTouchRipple>
                            <UpArrowIcon />
                        </IconButton>
                        <br />
                        <IconButton onClick={this.handleDown} style={iconButtonStyles} disableTouchRipple>
                            <DownArrowIcon />
                        </IconButton>
                    </div>
                </div>
            </div>
        );

        /*

        <button onClick={this.handleUp}>up</button>
        <button onClick={this.handleDown}>down</button>
         */
    }
}
