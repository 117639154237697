import React from 'react';
import PropTypes from 'prop-types';
import {Popover} from '@material-ui/core';
import MenuItem from '@material-ui/core/es/MenuItem/MenuItem';

import RemoveRedEye from '@material-ui/icons/RemoveRedEye';
import MapViewIcon from '../../icons/MapViewIcon';
import MapIcon from '../../icons/ViewsIcon';
import MapsMap from '@material-ui/icons/Map';


import * as views from '../../../constants/ViewTypes';
import ClassedIconButton from './ClassedIconButton';
import {generateComponentId} from "../../../utils/ComponentUtils";
import MenuList from "@material-ui/core/es/MenuList/MenuList";
import ListItemIcon from "@material-ui/core/es/ListItemIcon/ListItemIcon";

class ViewsDropDown extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            open: false,
        };

        this.handleTouchTap = this.handleTouchTap.bind(this);
        this.handleRequestClose = this.handleRequestClose.bind(this);
    }

    static get propTypes(){
        return {
            actions: PropTypes.object,
            mapEnabled: PropTypes.bool,
            isReportCategory: PropTypes.bool
        };
    }

    handleTouchTap(event){
        // This prevents ghost click.
        event.preventDefault();

        this.setState({
            open: true,
            anchorEl: event.currentTarget,
        });
    }

    handleRequestClose(){
        this.setState({
            open: false,
        });
    }
    render(){
        const {mapEnabled,reportId, isReportCategory} = this.props;
        let arr = [
            {
                text:"Report View",
                icon:<RemoveRedEye  />,
                action:() => {this.props.actions.changeView(views.REPORT_VIEW,reportId);}
            }
        ];

        if(mapEnabled){
            arr.push({
                text:"Map",
                icon:<MapsMap />,
                action:() => {this.props.actions.changeView(views.MAP_FULL_SCREEN,reportId);}
            });
            arr.push({
                text:"Report & Map Vertical",
                icon:<MapIcon />,
                action:() => {this.props.actions.changeView(views.MAP_REPORT_VERTICAL_SPLIT,reportId);}
            });
            arr.push({
                text:"Report & Map Horizontal",
                icon:<MapIcon />,
                action:() => {this.props.actions.changeView(views.MAP_REPORT_HORIZONTAL_SPLIT,reportId);}
            });
            arr.push({
                text:"Map & Report Horizontal",
                icon:<MapIcon/>,
                action:() => {this.props.actions.changeView(views.MAP_ABOVE_REPORT_HORIZONTAL, reportId);}
            });
            if(isReportCategory) {
                arr.push({
                    text: "PlayBack",
                    icon: <MapIcon/>,
                    action: () => {
                        this.props.actions.changeView(views.PLAYBACK_VIEW, reportId);
                        this.props.actions.saveCurrentView(views.PLAYBACK_VIEW);
                    }
                });
            }
        }

        let items = arr.map((v,i)=>{
            let icon = React.cloneElement(v.icon,{
                style:{width:'48px',margin:'6px 12px'}
            });

            return (

                    <MenuItem
                        id={generateComponentId(v.text,null,"button")}
                        key={i}
                        style={{paddingLeft:'0px', height:'36px',minHeight:'30px',fontSize:'0.9em'}}
                        onClick={()=>{v.action();this.handleRequestClose();}}
                    >
                        <ListItemIcon>{icon}</ListItemIcon>
                        {v.text}
                    </MenuItem>
            );
        });

        return(
            <div>
                <ClassedIconButton
                    id={"view_btn"}
                    onClick={this.handleTouchTap}
                >
                    <MapViewIcon />
                </ClassedIconButton>
                <Popover open={this.state.open}
                         anchorEl={this.state.anchorEl}
                         anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                         transformOrigin={{horizontal: 'right', vertical: 'top'}}
                         onClose={this.handleRequestClose}
                >
                    <MenuList style={{padding:'8px 0px'}}>
                        {items}
                    </MenuList>
                </Popover>
            </div>


        );
    }
}

export default ViewsDropDown;
