import React, {useState, useEffect} from 'react';

import MapsBySite from './MapsBySite';

const MapsBySiteContainer = ({sitesProps, mapsProps, selectMap}) => {
    let [sites, setSites] = useState([]);
    let [maps, setMaps] = useState([]);

    useEffect(() =>{
        setSites(sitesProps);
        setMaps(mapsProps)
    }, [sitesProps,mapsProps]);

    const renderSites = () => {
        return sites ? sites.map((site, i) => maps[site.id] ?
            <MapsBySite key={i} siteProps={site} mapsProps={maps[site.id]} selectMap={selectMap} /> : null
        ) : null
    };

    return (
        <div>
            {/*<MapsBySite/>*/}
            {renderSites()}
        </div>
    )
};

export default MapsBySiteContainer;