import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import LocalMapContainer from "./localMapComponent/LocalMapContainer";
import WorldMapContainer from "./WorldMapContainer";
import {unloadSiteAnMap, getZones, loadSiteAndMap} from "../../../actions";
import {LOCAL_MAP_VIEW, WORLD_MAP_VIEW, LOCAL_MAP_VIEW_DEVICES} from "../../../constants/DeviceManager";

const LandingMapContainer = ({devicesBatch, deviceManagerScreen, resizeTrigger, orientation}) => {
    const [showDeviceLabels, setShowDeviceLabels] = useState(false);
    const siteId = useSelector(state => state.deviceManager.siteId);
    const mapId = useSelector(state => state.deviceManager.mapId);
    const mapDevices = useSelector(state => state.deviceManager.mapDevices);
    const mapBounds = useSelector(state => state.deviceManager.mapBounds);
    const mapZoom = useSelector(state => state.deviceManager.mapZoom);
    const zones = useSelector(state => state.deviceManager.zones[siteId] || []);
    const mapView = useSelector(state => state.deviceManager.mapView);
    const dispatch = useDispatch();

    const deviceLabelsCallback = (val) => {
        setShowDeviceLabels(val);
    };

    const getSiteAndMap = () => {
        dispatch(loadSiteAndMap(siteId, mapId));

        const unmount = () => {
            dispatch(unloadSiteAnMap());
        };
        return unmount;
    };

    useEffect(getSiteAndMap, [siteId, mapId]);

    const loadZones = () => {
        if(siteId){
            dispatch(getZones(siteId));
        }
    }

    useEffect(loadZones, [siteId]);
    
    const getMapView = () => {
        switch (mapView) {
            case LOCAL_MAP_VIEW_DEVICES:
            case LOCAL_MAP_VIEW:
                return (
                    <LocalMapContainer
                        devicesBatch={devicesBatch.filter(item =>  item.siteId && item.location.mapID && item.siteId === siteId && Number(item.location.mapID) === mapId)}
                        siteId={siteId}
                        mapId={mapId}
                        mapDevices={mapDevices}
                        mapBounds={mapBounds}
                        mapZoom={mapZoom}
                        zonesOnMap={zones}
                        showDeviceLabels={showDeviceLabels}
                        deviceLabelsCallback={deviceLabelsCallback}
                        //parentCallback={showThumbnailsCallback}
                        deviceManagerScreen={deviceManagerScreen}
                        resizeTrigger={resizeTrigger}
                        orientation={orientation}
                    />
                );
            case WORLD_MAP_VIEW:
            default:
                return (
                    <WorldMapContainer resizeTrigger={resizeTrigger} orientation={orientation}/>
                    )
        }
    };

    return (
        <div className={'landing-map-container'}>
            {getMapView()}
        </div>
    );
};

export default LandingMapContainer;