//import leftPad from 'left-pad';
//import assign from 'object-assign';

function Randomizer(){

    this._alpha = {};
    this._alpha.upper = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split("");
    this._alpha.lower = 'abcdefghijklmnopqrstuvwxyz'.split("");

    this._hex = '0123456789ABCDEF'.split("");
}

Randomizer.prototype.number = function number(n){
    return Math.floor(n*Math.random());
};

Randomizer.prototype.numberBetween = function numberBetween(min,max){
    if(min >= max) return min;
    let range = 1+max-min;
    return min + this.number(range);
};

Randomizer.prototype.bool = function bool(){
    return !!this.number(2);
};

Randomizer.prototype.alpha = function alpha({uppercase=true,startAt='A',endAt='Z'}={}){
    let arr = this._alpha[(uppercase)?"upper":"lower"];

    let offset = arr.indexOf(startAt);
    offset = (offset >= 0) ? offset : 0;

    let end = arr.indexOf(endAt);
    end = (end >= 0 && end >= offset) ? end : 26;

    return arr[this.number(end-offset)+offset];
};

Randomizer.prototype.hex = function hex(){
    return this._hex[this.number(16)];
};

Randomizer.prototype.choose = function choose(arr){
    const l = arr.length;
    return arr[this.number(l)];
};

let rand = new Randomizer();


function DataGenerator(){}

/*
██    ██ ████████ ██ ██
██    ██    ██    ██ ██
██    ██    ██    ██ ██
██    ██    ██    ██ ██
 ██████     ██    ██ ███████
*/


DataGenerator.prototype.generateArray = function generate(fn,n){
    let arr = [],i;
    for(i = 0; i < n; i++){
        arr.push(fn(i));
    }
    return arr;
};

/*
 ██████ ██   ██  █████  ██████  ████████     ██████   █████  ████████  █████
██      ██   ██ ██   ██ ██   ██    ██        ██   ██ ██   ██    ██    ██   ██
██      ███████ ███████ ██████     ██        ██   ██ ███████    ██    ███████
██      ██   ██ ██   ██ ██   ██    ██        ██   ██ ██   ██    ██    ██   ██
 ██████ ██   ██ ██   ██ ██   ██    ██        ██████  ██   ██    ██    ██   ██
*/

DataGenerator.prototype.generateDiscreteDistribution = function generateDiscreteDistribution({count=6,total=100,max=total,min=0}={}){

    let arr = [];

    let _count = 0,d,j;
    for(j = 0; j < count-1; j++){

        let _max = Math.min(
            max,
            1+total - (min*(count-1-j)) - _count
        );

        let _min = Math.max(
            min,
            1+total - (max*(count-1-j)) - _count
        );

        d = rand.numberBetween(_min, _max);
        arr.push(d);
        _count += d;
    }
    arr.push(total-_count);

    return arr;
};

DataGenerator.prototype.generateStackedChartData = function generateStackedChartData({nSets=6,countPerSet=12,startTotal=100,endTotal=250}={}){

    // make object.
    let arr = [],i;
    for(i = 0; i < nSets; i++){
        arr[i] = [];
    }

    let _diffInTotals = endTotal - startTotal;

    let totals = this.generateDiscreteDistribution({
        count:countPerSet-1,
        total:_diffInTotals,
        min:-10,
        max:30
    });

    totals.splice(0,0,100);
    for(i = 1; i < countPerSet; i++){
        totals[i] += totals[i-1];
    }



    for(i = 0; i < countPerSet; i++){

        let total = totals[i];

        let d = this.generateDiscreteDistribution({
            count:nSets,
            total,
            max:Math.floor(total * 0.20),
            min:Math.floor(total * 0.12)
        });

        let j;
        for(j = 0; j < nSets; j++){
            arr[j].push(d[j]);
        }
    }

    return arr;
};

/*
███████ ██   ██ ██████   ██████  ██████  ████████
██       ██ ██  ██   ██ ██    ██ ██   ██    ██
█████     ███   ██████  ██    ██ ██████     ██
██       ██ ██  ██      ██    ██ ██   ██    ██
███████ ██   ██ ██       ██████  ██   ██    ██
*/



let _generator = new DataGenerator();

module.exports = _generator;
