import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Divider from "@material-ui/core/Divider/Divider";
import {loadDeviceDetailsData, changeStatusModalDetails} from "../../../../actions";
import {localeTimestamp} from '../../../../utils/TimestampFormats';
import DetailsSidebar from '../../landing/DetailsSideBar';
import CardsAntennas from '../../landing/CardsAntennas';
import ConfigurationDetails from '../../landing/ConfigurationDetails';
import Identity from '../../landing/Identity';

import LinearProgress from '@material-ui/core/LinearProgress';
import ThemeProvider from "@material-ui/styles/ThemeProvider/ThemeProvider";
import createMuiTheme from "@material-ui/core/es/styles/createMuiTheme";
import {withStyles} from "@material-ui/core";

const useStyles = makeStyles({
    paperDialog: {
        overflowY: "visible",
    }
});

function ModalDeviceDetails({open, closeDialog}){
    const dispatch = useDispatch();
    const isFetchingDetails = useSelector(state => state.deviceManager.isFetchingDetails);
    const detailsData = useSelector(state => state.deviceManager.detailsDevice);
    const selectedRows = useSelector(state => state.deviceManager.selectedRows);
    const idArray = Object.keys(selectedRows);
    const [index, setIndex] = useState(0);
    const numberOfDevices = idArray.length;

    const loadData =()=>{
        if (open){
            let deviceID = idArray[index];
            if(deviceID){
                dispatch(loadDeviceDetailsData(idArray[index]))
            }
        }
    };
    useEffect(loadData,[open, index]);


    useEffect(() => {
        if (numberOfDevices === 0){
            dispatch(changeStatusModalDetails(false));
        }
    });

    const scrollView = id => {
        var elmntToView = document.getElementById(id);
        if (elmntToView)
            elmntToView.scrollIntoView({behavior: 'smooth'});
    }

    function renderCloseButton(){
        return (
            <div style={{position:'absolute',top:'-0.5em',right:'-0.5em'}}>
                <IconButton aria-label="close"
                    size="small"
                    onClick={closeDialog}
                    style={{cursor:'pointer',position:'relative',zIndex:'100',backgroundColor:'#000'}}
                    >
                    <CloseIcon style={{color: '#fff'}} />
                </IconButton>
            </div>
        );
    }

    const CreateBy=({createBy, lastUpdate})=>(
        <div className="ddmodal-createBy">
            <span>Create by: {createBy}</span>
            <span style={{marginLeft:'50px'}}>Last Update: {lastUpdate ? localeTimestamp(new Date(lastUpdate)): ''}</span>
        </div>
    );

    const Triggers=({triggers, reporting})=>{

        const getStartLabel = (start) => {
            let label = '';
            if(Object.keys(start).length === 0){
                label = 'When START is pressed'
            }else{
                label = `When START is pressed and GPI ${start.trigger} goes ${start.transition}`
            }
            return label;
        }

        const getStopLabel = (stop) => {
            let label = '';
            if(Object.keys(stop).length === 0){
                label = 'When STOP is pressed'
            }else if(stop.trigger && stop.transition){
                label = `When GPI ${stop.trigger} goes ${stop.transition}`
            }else if(stop.numReads){
                label = `After ${stop.numReads} tag read(s)`
            }else if(stop.milliseconds){
                label = `After ${stop.milliseconds} ms`
            }else if(stop.rounds){
                label = `After ${stop.rounds} inventory rounds`
            }
            return label;
        }

        
        return (
            <div id={"Triggers"} className="dd-identity-main-container">
                <div className="dd-identity-container-title">
                    <span className="dd-identity-title">
                    Triggers
                    </span>
                </div>
                <>
                    <div className="dd-identity-items">
                        <span className="dd-identity-items-title">Start Reading: </span>
                        <span>{getStartLabel(triggers.start)}</span>
                    </div>
                    <div>
                        <span style={{paddingLeft:'5px', fontWeight: 'bold'}}>Stop Reading: </span>
                        <span>{getStopLabel(triggers.stop)}</span>
                    </div>
                    <Divider className="dd-divider" />
                    <div  className="dd-identity-items">
                        <span className="dd-identity-items-title">Report Tag: </span>
                        <span>After {reporting.numTags} tags and after each being read for {reporting.seconds} seconds</span>

                    </div>
                    <div  className="dd-identity-items">
                        <span className="dd-identity-items-title">Autonomous Mode: {reporting.autoMode}</span>
                        <span>New Tag Event {reporting.newTagEvent}</span>

                    </div>
                </>
            </div>
        );
    };

    const GPO=()=>{
        return (
            <div id={"GPO"} className="dd-identity-main-container">
                <div className="dd-identity-container-title">
                    <span className="dd-identity-title">
                    GPO (General Porpose Output)
                    </span>
                </div>
                <>
                    <div className="dd-identity-items">
                        <span className="dd-identity-items-title">Out Events: </span>
                        <span>When occurs trigger GPO port </span>
                    </div>
                </>
            </div>
        );
    };

    const classes = useStyles();
    const item = detailsData;
    return(
        <React.Fragment>
            <Dialog
            open={open}
            fullWidth
            onClose={closeDialog}
            classes={{paper:classes.paperDialog}}
            >
                {renderCloseButton()}
                <DialogTitle style={{paddingBottom:0}}>
                    <div className="ddmodal-main-container-title">
                        <div className="dd-main-title">
                            <div  className="dd-title">
                                {item?item.title:''} &nbsp;
                            </div>
                            <div style={{fontSize:'12px'}}>
                                ({index + 1} of {numberOfDevices})
                            </div>
                        </div>
                        {numberOfDevices>1?
                            <div style={{display:'flex'}}>
                                <div className={index ===0 ?"dd-arrow-back-disabled":"dd-arrow-back"} onClick={()=>setIndex(prevIndex => prevIndex - 1)}>
                                    <ArrowBackIosIcon />
                                    <span>Previous</span>
                                </div>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <div className={index === (numberOfDevices - 1) ?"dd-arrow-back-disabled":"dd-arrow-back"}
                                    onClick={()=>setIndex(prevIndex => prevIndex + 1)}>
                                    <span>Next</span>
                                    <ArrowForwardIosIcon />
                                </div>
                            </div>: null}
                    </div>
                </DialogTitle>
                <DialogContent className="ddmodal-container">
                    <div className="ddSideBar">
                        <DetailsSidebar scrollView={(e) => scrollView(e)} />
                    </div>
                    {isFetchingDetails?<ModalProgressDetails />: null}
                    {item && <div className="ddmodal-container-right">
                        <ConfigurationDetails configuration={item?item.configuration_state:''} template={item?item.templateTitle:''}
                                            version={item?item.version:''} operationalStatus={item?item.status.title:''}/>
                        <CreateBy createBy={item?item.templateCreateBy:''} lastUpdate={item?item.templateCreateDate:''}/>
                        <Identity deviceType={item?item.deviceType:''} description={item?item.description:''}
                                address={item?item.ipAddress:''} location={item?item.location:''}/>
                        {item?<CardsAntennas antennas={item.rfidAntennas}/>:null}
                        {item && item.triggerSettings && item.reporting && <Triggers triggers={item.triggerSettings} reporting={item.reporting}/>}
                        {item && item.GPORules && <GPO />}
                    </div>}
                </DialogContent>
            </Dialog>    
        </React.Fragment>
    );
}

export default ModalDeviceDetails;

const ColorLinearProgress = withStyles({
    barColorPrimary: {backgroundColor: '#00779f'}
})(LinearProgress);
    
const outerTheme = createMuiTheme({
    palette: {
        primary: {main: '#00779F'}
    },
    shadows: Array(25).fill('none')
});

function ModalProgressDetails() {
    const styleContent = {width:'100%', height: '100%', backgroundColor:'rgba(0,0,0,0.1)',
                        display:'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center'}
    return (
        <div style={styleContent}>
            <div style={{backgroundColor:'#fff', padding: '0.5em'}}>
                <span style={{fontSize: '12px'}}>Getting data from server</span>
                <ThemeProvider theme={outerTheme}>
                    <ColorLinearProgress mode="indeterminate" style={{background:'rgba(0,0,0,0.25)',marginTop:'1em'}}/>
                </ThemeProvider>
            </div>
        </div>
    );
}