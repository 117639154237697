import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {updateMultipleDevices, changeDeviceOptions, updateAntennaAutozone, detectSaveChanges, loadSiteAndMap,
        updateZoneName, deleteEmptyNewZones, getZonesWithAntennas} from '../../../actions';
import { useSelector, useDispatch } from 'react-redux';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {IconButton, Button, Divider, Dialog, DialogContent, DialogActions, Tooltip} from '@material-ui/core';
import Checkbox from "@material-ui/core/Checkbox";
import DialogAutoZones from './dialogs/DialogAutozones';
import {v4 as uuidv4} from 'uuid';
import WarningIcon from "@material-ui/icons/Warning";
import DialogConfirm from '../common/dialogs/DialogConfirm';

const NO_ZONE = 'No Zone';
const UNPLACED = 'UNPLACED', PLACED = 'PLACED', NOSITE = 'NOSITE';
const DevicesListContainer = (props) => {
    const dispatch = useDispatch();
    const {idSelected, devicesBatch, addDeviceFeature, changeCoordinatesFeature, selectItemPanel, zones} = props;
    const zonesWithAntennas = useSelector(state => state.deviceManager.zonesWithAntennas);
    const siteId = useSelector(state => state.deviceManager.siteId);
    const mapId = useSelector(state => state.deviceManager.mapId);
    const history = useHistory();
    const deviceOptions = useSelector(state => state.deviceManager.deviceOptions);
    const isDeviceChanging = useSelector(state => state.deviceManager.isDeviceChanging);
    const [openAll, setOpenAll] = useState({PLACED:true, UNPLACED:false, NOSITE: false});
    const styleLabel = {padding: '0px 3px'};
    const [placedDevices, setPlacedDevices] = useState([]);
    const [unplacedDevices, setUnplacedDevices] = useState([]);
    const [noSiteDevices, setNoSiteDevices] = useState([]);
    const sites = useSelector(state => state.deviceManager.sites || []);

    const distributeDevices = () => {
        let initPlacedDevices = [];
        let initUnplacedDevices = [];
        let initNoSiteDevices = [];
        
        for(var i = 0; i < devicesBatch.length; i++){
            const device = devicesBatch[i];
            if(device.siteId === ""){
                initNoSiteDevices.push(device);
            }else if(siteId === device.siteId  && device.location.mapID === ""){
                initUnplacedDevices.push(device);
            }else if(siteId === device.siteId && device.location.mapID === mapId.toString()){
                initPlacedDevices.push(device);
            }
        }

        setOpenAll(oldState => ({...oldState, [NOSITE]: initNoSiteDevices.length===0?false:oldState[NOSITE], [UNPLACED]: initUnplacedDevices.length===0?false:oldState[UNPLACED]}))
        setNoSiteDevices(initNoSiteDevices);
        setUnplacedDevices(initUnplacedDevices);
        setPlacedDevices(initPlacedDevices);
    }

    useEffect(distributeDevices, [devicesBatch, siteId, mapId]);

    const handleUpdateOnlyDevices = () => {
        dispatch(updateMultipleDevices(history));
    }

    const handleSelectFeature = id => {
        selectItemPanel(id);
    }
    let styleDoneButton = {background: '#007CB0', color: '#fff', width:'100%', textTransform: 'none', padding: '6px 0', fontweight:'bolder'};

    const changeSite = (e) => {
        const valueSite = e.target.value;
        dispatch(loadSiteAndMap(valueSite, null));
    }

    const openPlacedContainer = (openValue) => {
        setOpenAll(oldState => ({...oldState, [openValue]: !oldState[openValue]}));
    }

    const renderPlacedDevices = (listDevices, titlePanel, typeContent) => {
        let styleContent = {};
        if(typeContent !== PLACED){
            styleContent.maxHeight = listDevices.length > 1? '124px': `${listDevices.length * 60 + 1}px`
        }
        return (
            <React.Fragment>
                <div className="devices-container-header">
                    <span style={{flex: 1}}>{titlePanel}</span>
                    <IconButton size="small" style={{padding: 0}} onClick={()=>openPlacedContainer(typeContent)}>
                        {openAll[typeContent]? <ExpandLessIcon />:<ExpandMoreIcon />}
                    </IconButton>
                </div>
                {openAll[typeContent] && listDevices.length>0 && <div className="devices-container-body" style={styleContent}>
                    {listDevices.map((device, index)=> 
                        <DeviceComponent 
                            key={index} item={device} indexDevice={device._id} idSelected={idSelected}
                            addDeviceFeature={addDeviceFeature} 
                            selectFeature={handleSelectFeature} 
                            changeCoordinatesFeature={changeCoordinatesFeature}
                            deviceOptions={deviceOptions}
                            zonesWithAntennas={{...getZonesWithAntennas(devicesBatch, [device._id]), ...zonesWithAntennas}}
                            zones={zones}
                            haveCoordinates={(device.location.x && device.location.y)}
                        />
                    )}
                </div>}
            </React.Fragment>
        )
    }
    return (
        <div className="devices-container">
            {renderPlacedDevices(noSiteDevices, `Devices with no site assigned (${noSiteDevices.length})`, NOSITE, false)}
            <div style={{display: 'flex', flexDirection: 'row',padding: '0.5em'}}>
                <label style={styleLabel}>Site</label>
                <select style={{flexGrow: 1}} value={siteId || ''} onChange={changeSite}>
                    {sites && sites.map((item, index) => <option value={item.id} key={index}>{item.name}</option>)}
                </select>
            </div>
            {renderPlacedDevices(unplacedDevices, `Devices with no map assigned (${unplacedDevices.length})`, UNPLACED, false)}
            {renderPlacedDevices(placedDevices, `Devices assigned to map (${placedDevices.length})`, PLACED, true)}
            <div className="devices-container-footer" style={(!openAll[PLACED] || placedDevices.length===0)?{flexGrow:1}:{}}>
                {!isDeviceChanging?<Button style={styleDoneButton} onClick={() => history.replace('/device-manager/devices')} component="span">Return to Device List</Button>
                :<Button style={styleDoneButton} onClick={handleUpdateOnlyDevices} component="span">Save All</Button>}
            </div>
        </div>
    );
};

export default DevicesListContainer;


const DeviceComponent = (props) => {
    const {item, indexDevice, idSelected, addDeviceFeature, selectFeature, changeCoordinatesFeature, deviceOptions, zones, zonesWithAntennas, haveCoordinates} = props;
    const isDeviceChanging = useSelector(state => state.deviceManager.isDeviceChanging);
    const [showAntennas, setShowAntennas] = useState(false);
    const [checkedAll, setCheckedAll] = useState(false);
    const [coordinates, setCoordinates] = useState({x: 0, y: 0});
    const [openDialog, setOpenDialog] = useState(false);
    const [openPrompt, setOpenPrompt] = useState(false);
    const [isHover, setIsHover] = useState(false);
    const dispatch = useDispatch();

    const sites = useSelector(state => state.deviceManager.sites);
    const maps = useSelector(state => state.deviceManager.maps);
    const siteId = useSelector(state => state.deviceManager.siteId);

    // const conditionAddFeature = !(item.location.x && item.location.y);
    const conditionAddFeature = !((item.location.x && item.location.y) || !siteId);

    const getTooltipTitle = () => {
        const emptyValue = 'n/a';
        let titleObject = {site: emptyValue, map: emptyValue};
        if(sites && item.siteId && item.siteId !== ""){
            const siteObject = sites.find(s => s.id === item.siteId);
            const mapObject = maps[item.siteId].find(m => m.id.toString() === item.location.mapID);
            titleObject.site = siteObject?siteObject.name:emptyValue;
            titleObject.map = mapObject?mapObject.name:emptyValue;
        }
        let titleTooltip = `Site: ${titleObject.site}, map: ${titleObject.map}`
        return titleTooltip;
    }

    useEffect(() => {
        setCoordinates({x:item.location.x, y:item.location.y});
    }, [item.location]);

    const changeAntennaZone = (indexAntenna, zone) => {
        dispatch(updateAntennaAutozone(indexDevice, indexAntenna, zone));
        if(!isDeviceChanging){
            dispatch(detectSaveChanges(true));
        }
        dispatch(deleteEmptyNewZones());
    }

    const addDeviceOnMap = event => {
        addDeviceFeature(event, indexDevice);
    }

    const changeCoordinates = event => {
        let currentCoordinates = {x: coordinates.x, y: coordinates.y};
        currentCoordinates[event.target.name] = event.target.value;

        if(!conditionAddFeature){
            changeCoordinatesFeature(event, indexDevice, currentCoordinates);
        }
    }

    const changeAutoZoneAll = e => {
        if(e.target.checked){
            createAutoZonePerAntenna();
            setCheckedAll(true);
        }else{
            setOpenPrompt(true);
        }

        if(!isDeviceChanging){
            dispatch(detectSaveChanges(true));
        }
    };

    const deleteAutoZones = () => {
        setCheckedAll(false);
        item.rfidAntennas.forEach((antenna, indexAntenna) => {
            dispatch(updateAntennaAutozone(item._id, indexAntenna, null));
        });
        dispatch(deleteEmptyNewZones());
    }

    const createAutoZonePerAntenna = () => {
        item.rfidAntennas.forEach((antenna, indexAntenna) => {
            if(!Boolean(antenna.autoZone) || antenna.autoZone === NO_ZONE){
                //creating a new zone
                const titleAutozone = generateAutoZoneName(antenna.title);
                const existingZone = zones.find(z => z.name === titleAutozone);
                let locationGuid;
                if(existingZone){
                    // existing locationGuid
                    locationGuid = existingZone.locationGuid;
                }else{
                    // new locationGuid
                    locationGuid = '{' + uuidv4() + '}';
                    dispatch(updateZoneName(locationGuid, titleAutozone));
                }
                dispatch(updateAntennaAutozone(item._id, indexAntenna, locationGuid));
            }
        });
        dispatch(deleteEmptyNewZones());
    }

    const onKeyDown = event => {
        if(event.key === 'Enter'){
            changeCoordinates(event);
        }
    }

    const handleAllowMoveAntennas = (event, deviceId) => {
        dispatch(changeDeviceOptions(event.target.checked, deviceId));
        if(!isDeviceChanging){
            dispatch(detectSaveChanges(true));
        }
    };

    const configureZones = (zonesObject) => {
        for(const zone in zonesObject){
            if(zone !== NO_ZONE && zonesObject[zone].antennas.length > 0){
                // UPDATING THE ZONE LIST
                dispatch(updateZoneName(zone, zonesObject[zone].name));
            }
            for(var i = 0; i < zonesObject[zone].antennas.length; i++){
                const antenna = zonesObject[zone].antennas[i];
                dispatch(updateAntennaAutozone(indexDevice, getPositionAntenna(antenna), zone === NO_ZONE?null:zone));

            }
        }
        setCheckedAll(false);

        if(!isDeviceChanging){
            dispatch(detectSaveChanges(true));
        }
        dispatch(deleteEmptyNewZones());
    }

    const generateObjectZones = () => {
        let objectZones = {};
        for(var i = 0; i < item.rfidAntennas.length; i++){
            const antenna = item.rfidAntennas[i];
            if(antenna.autoZone){
                if(objectZones[antenna.autoZone]){
                    objectZones[antenna.autoZone].antennas.push(antenna.title);
                }else{
                    let objectZone = {};
                    objectZone.name = getAutoZoneName(antenna.autoZone);
                    objectZone.originalName = getAutoZoneName(antenna.autoZone);
                    objectZone.editable = true;
                    objectZone.disabled = true;
                    objectZone.antennas = [antenna.title];
                    objectZones[antenna.autoZone] = objectZone;
                }
            }
        }
        return objectZones;
    }

    const getAutoZoneName = (locationGuid) => {
        const zoneSelected = zones.find(z => z.locationGuid === locationGuid);
        return zoneSelected && zoneSelected.name;
    }

    const getPositionAntenna = (antennaLabel) => {
        return item.rfidAntennas.map(antenna => antenna.title).indexOf(antennaLabel);
    }

    const generateAutoZoneName = (antennaLabel) => {
        return `${item.ipAddress.length <= 85 ? item.ipAddress : item.ipAddress.substr(0, 35)}-${getPositionAntenna(antennaLabel) +1}-az`;
    }

    const isSelected = idSelected === indexDevice;
    const styleDeviceTitle = {paddingLeft:'8px',display:'block',width:'200px',userSelect:'none',cursor:'pointer', pointerEvents: conditionAddFeature ? 'auto': 'none',textOverflow: 'ellipsis', overflow:'hidden',whiteSpace:'nowrap'};
    const styleLabel = {padding: '0px 3px'};

    let deviceImage = isSelected ? 'assets/images/devices/04-1.svg' :'assets/images/devices/04.svg';
    const styleImage = {width:'24px',height:'24px', pointerEvents: conditionAddFeature ? 'auto': 'none'};
    let styleDoneButton = {background: '#007CB0', color: '#fff', width:'96%', textTransform: 'none', padding: '6px 0'};
    const zonesOnMap = zones.filter(z => z.siteId===item.siteId && z.map === parseInt(item.location.mapID) && z.layerPriority === -1);
    const styleContainerDevice = {display:'flex',flexDirection:'row',alignItems:'center', backgroundColor: isSelected ? '#d8d8d8': 'inherit'};
    const styleMessage={fontSize:'11.5px',textAlign:'center',padding:'2px 0px',backgroundColor:'#eee'}
    return(
        <React.Fragment>
            <div className="device-item">
                <Tooltip title={getTooltipTitle()} placement="right" open={isHover} arrow>
                    <div id={indexDevice} style={styleContainerDevice} onClick={() => selectFeature(indexDevice)} onMouseOver={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>                        
                        <img style={styleImage} src={deviceImage} alt={indexDevice+1} onDoubleClick={conditionAddFeature ? addDeviceOnMap: ()=>{}}/>
                        <div style={styleDeviceTitle} onDoubleClick={conditionAddFeature ? addDeviceOnMap: ()=>{}}>{item.title}</div>
                        <IconButton size="small" onClick={()=>{setShowAntennas(!showAntennas); setIsHover(false);}}>
                            {showAntennas? <ExpandLessIcon />:<ExpandMoreIcon />}
                        </IconButton>
                    </div>
                </Tooltip>
                {showAntennas && 
                <React.Fragment>
                {!haveCoordinates? <span style={styleMessage}>Double-click to position device on map</span>:                
                    <div className="antennas-content">
                        <div style={{display: 'flex', justifyContent: 'flex-end', paddingRight: '1.2em'}}>
                            <div>
                                <label style={styleLabel}>X</label>
                                <input type="number" style={{maxWidth: '70px'}} name={'x'} value={coordinates.x || ''}
                                    onChange={e => setCoordinates({...coordinates, x: e.target.value})} onKeyDown={onKeyDown} onBlur={changeCoordinates} />
                            </div>
                            &nbsp;
                            <div>
                                <label style={styleLabel}>Y</label>
                                <input type="number" style={{maxWidth: '70px'}} name={'y'} value={coordinates.y || ''}
                                        onChange={e => setCoordinates({...coordinates, y: e.target.value})} onKeyDown={onKeyDown} onBlur={changeCoordinates} />
                            </div>
                        </div>
                        <Divider style={{backgroundColor:'#455a64',margin: '0.25em 0'}} />
                        {item.rfidAntennas.map((antenna, index) =>
                            <AntennaComponent 
                                key={index}
                                indexDevice={indexDevice} 
                                indexAntenna={index}
                                idFeatureAntenna={`${indexDevice}_${index + 1}`} 
                                idSelected={idSelected}
                                antenna={antenna} 
                                selectFeature={selectFeature}
                                changeCoordinatesFeature={changeCoordinatesFeature}
                                enableDrag={deviceOptions[indexDevice] && deviceOptions[indexDevice].enableDrag}
                                changeAntennaZone={changeAntennaZone}
                                zoneSelected={antenna.autoZone}
                                objectZones={zonesOnMap.reduce((acc, z) => {acc[z.locationGuid] = z.name; return acc},{})}
                            />
                        )}
                        <div className={'antennas-same-as-reader'}>
                                <Checkbox
                                    className={'antennas-same-as-reader-checkbox'}
                                    checked={checkedAll}
                                    onChange={changeAutoZoneAll}
                                    size="small"
                                    disableTouchRipple={true}
                                />
                            Create one auto zone per antenna
                        </div>
                        <div className={'antennas-same-as-reader'}><Checkbox checked={deviceOptions[indexDevice] && deviceOptions[indexDevice].enableDrag} 
                            onClick={(event) => handleAllowMoveAntennas(event, indexDevice)} className="antennas-same-as-reader-checkbox" 
                            size="small" disableTouchRipple={true} disabled={item.rfidAntennas.length === 0} /> All antennas use reader position</div>
                        <div className={'antennas-same-as-reader'}>
                            <Button style={styleDoneButton} onClick={()=>setOpenDialog(true)} component="span">Zone and Antenna Mapping</Button>
                        </div>
                    </div>}
                </React.Fragment>}
            </div>
            {showDialogAutozones()}
            {openPrompt && <DialogConfirm
                open={Boolean(openPrompt)}
                closeDialog={() => setOpenPrompt(false)}
                actionSuccess={deleteAutoZones}
                actionCancel={() => setCheckedAll(true)}
                message={'There are already auto zone(s) created for all these antennas. \n Do you want to delete existing auto zones and create new auto zone per antenna?'}
            />}
        </React.Fragment>
    )

    function showDialogAutozones() {
        if (openDialog) {
            if(item.rfidAntennas && item.rfidAntennas.length>0) {
                return <DialogAutoZones
                    open={openDialog}
                    device={item}
                    zonesByDefault={Boolean(checkedAll)}
                    closeDialog={() => setOpenDialog(false)}
                    configureZones={configureZones}
                    objectZones={generateObjectZones()}
                    antennasWithoutZone={item.rfidAntennas.filter(antenna => (!Boolean(antenna.autoZone) || antenna.autoZone === NO_ZONE)).map(a => a.title)}
                    zones={zonesOnMap}
                    zonesWithAntennas={zonesWithAntennas}
                />
            }
            else{
                return <Dialog
                    open={openDialog}
                    fullWidth
                    onClose={() => setOpenDialog(false)}
                >
                    <DialogContent >
                        <div style={{padding: '0.5em', textAlign: 'center', fontSize: '1.1em',lineHeight:'37px'}}><WarningIcon style={{fill:'#ff8000',fontSize:'1em',paddingRight:'5px'}}/>This device has no antenna</div>
                        <div style={{padding: '0.5em', textAlign: 'center', fontSize: '1em'}}>You need to add at least one antenna to use this function</div>
                    </DialogContent>
                    <DialogActions>
                        <Button style={{background: '#007CB0',color: '#fff', padding: '0.5em 0.8em'}} onClick={() => setOpenDialog(false)} component="span">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            }
        }
    }
}

const AntennaComponent = (props) => {
    const {indexAntenna, idFeatureAntenna, idSelected, antenna, selectFeature, changeCoordinatesFeature, enableDrag, changeAntennaZone, zoneSelected, objectZones} = props;
    const [coordinates, setCoordinates] = useState({x: antenna.location.x, y: antenna.location.y});
    
    let antennaImage = 'assets/images/devices/09.svg';
    let antennaActiveImage = 'assets/images/devices/09-active.svg';
    useEffect(()=>{
        setCoordinates({x:antenna.location.x, y:antenna.location.y});
    },[antenna.location.x,antenna.location.y])

    const changeAntennaCoordinates = event => {
        let currentCoordinates = {x: coordinates.x, y: coordinates.y};
        currentCoordinates[event.target.name] = event.target.value;
        changeCoordinatesFeature(event, idFeatureAntenna, currentCoordinates);
    }

    const onKeyDown = event => {
        if(event.key === 'Enter'){
            changeAntennaCoordinates(event);
        }
    }

    const isSelected = idSelected === idFeatureAntenna;
    const styleContainer = {backgroundColor: isSelected? '#d8d8d8': 'inherit'};
    const styleLabel = {padding: '0px 3px'};
    const styleImage = {width:'12px',height:'12px',padding:'0 0.2em'};
    const conditionImage = coordinates.x !== null && coordinates.y !== null;

    return(
        <div id={idFeatureAntenna} onClick={() => {return !enableDrag ? selectFeature(idFeatureAntenna) : null}} className="antenna-item" style={styleContainer}>
            <div style={{display:'flex',alignItems:'center',paddingBottom:'0.2em'}}>
                <div style={{display:'flex', alignItems:'center', justifyContent:'flex-start',flex: 1}}>
                    <span>{`${indexAntenna + 1}.`}</span>
                    <img style={styleImage} src={conditionImage?antennaActiveImage:antennaImage} alt={antenna.title} />
                    <span>{antenna.title}</span>
                </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'flex-end', paddingRight: '1.2em'}}>
                <div>
                    <label style={styleLabel}>X</label>
                    <input disabled={enableDrag} type="number" style={{maxWidth:'70px'}} name={'x'} value={coordinates.x || ''}
                                    onChange={e => setCoordinates({...coordinates, x: e.target.value})} 
                                    onKeyDown={onKeyDown} onBlur={changeAntennaCoordinates}/>
                </div>
                &nbsp;
                <div>
                    <label style={styleLabel}>Y</label>
                    <input disabled={enableDrag} type="number" style={{maxWidth:'70px'}} name={'y'} value={coordinates.y || ''}
                                    onChange={e => setCoordinates({...coordinates, y: e.target.value})} 
                                    onKeyDown={onKeyDown} onBlur={changeAntennaCoordinates}/>
                </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '0.5em', paddingRight: '1.2em'}}>
                <label style={styleLabel}>Zone</label>
                <select style={{width:'174px'}} value={zoneSelected || ''} onChange={e => changeAntennaZone(indexAntenna, e.target.value===NO_ZONE ? null: e.target.value)}>
                    <option value={null}>{NO_ZONE}</option>
                    {Object.keys(objectZones).map((zone, i) => <option key={i} value={zone}>{objectZones[zone]}</option>)}
                </select>
            </div>
        </div>
    )
}