import React, {useState, useRef, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getDeviceStatus} from "../../../actions";

const ReaderStatusPanel = ({deviceId}) => {
    const [showMore, setShowMore] = useState(false);
    const [showCopied, setShowCopied] = useState(false);

    const jsonRef = useRef();

    const deviceStatus = useSelector(state => state.deviceManager.deviceStatus);
    const fetchDeviceStatus = useSelector(state => state.deviceManager.fetchDeviceStatus);

    const dispatch = useDispatch();

    const handleCopyJson = (e) => {
        e.preventDefault();
        window.getSelection().removeAllRanges();
        var range = document.createRange();
        range.selectNode(jsonRef.current);
        window.getSelection().addRange(range);
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
        setShowCopied(true);
    };

    const handleShow = (e) => {
        e.preventDefault();
        setShowMore(!showMore)
    };

    // const handleOpenHistory = (e) => {
    //     e.preventDefault();
    //     setOpenHistory(true);
    //     setDeviceId(deviceId);
    // };

    const handleLoadStatus = () => {
        dispatch(getDeviceStatus(deviceId));
    };

    useEffect(handleLoadStatus, [deviceId]);

    return (
        <div id={"ReaderStatus"} className={"dd-status-main-container"}>
            <div className="dd-status-container-title">
                <span className="dd-status-title">Reader Status Detail</span>
            </div>
            <div className="dd-status-items">
                <div style={{display:'flex', justifyContent:'flex-end', marginTop: '2px'}}><span style={{color: '#8b9298', fontStyle: 'italic'}}>{showCopied && 'Copied!'}</span></div>
                <div className="dd-status-items-jsonbox" >
                    <pre ref={jsonRef} className="dd-status-json-content" style={{WebkitLineClamp: !showMore? 8 : 'unset'}}>{fetchDeviceStatus ? "Getting Status Data..." : (deviceStatus ? JSON.stringify(deviceStatus,null,4) : "{ No status data }")}</pre>
                    <div className="dd-status-items-links-container">
                        <a href="/#" className={deviceStatus ? "dd-status-show-link" : "dd-status-disabled-link"} onClick={handleShow}>Show {!showMore ? 'More': 'Less'}</a>
                        <a href="/#" className={deviceStatus ? "dd-status-show-link" : "dd-status-disabled-link"} onClick={handleCopyJson}>Copy</a>
                        {/*<a href="/#" className={deviceStatus ? "dd-status-show-link" : "dd-status-disabled-link"} onClick={handleOpenHistory}>History</a>*/}
                    </div>
                </div>
            </div>
        </div>
    )

};

export default React.memo(ReaderStatusPanel, ((prevProps, nextProps) => {
    return prevProps.deviceId === nextProps.deviceId;
}));