import React from 'react';
import PropTypes from 'prop-types';

import StyledDialog, {CancelButton, SaveButton} from '../../../util/Dialog';

import PermissionsTable from './PermissionsTable';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../../../../actions';

import {USERMGMT_ADD_GROUP} from '../../../../constants/DataEntryForms';

class UpdateUserGroupDialog extends React.Component{

    constructor(){
        super();
        this.handleSave = this.handleSave.bind(this);
        this.formatPermissions = this.formatPermissions.bind(this);
    }

    static get propTypes(){
        return {
            sites: PropTypes.array,
            resourceTypes: PropTypes.array,
            onRequestClose: PropTypes.func.isRequired,
            requestSitesForUserMgmt: PropTypes.func.isRequired,
            requestResourceTypes: PropTypes.func.isRequired,
            addUserGroup: PropTypes.func.isRequired
        };
    }

    static get defaultProps(){
        return {
            group:{}
        };
    }

    componentDidMount(){
        this.props.requestSitesForUserMgmt();
        this.props.requestResourceTypes();
    }

    handleSave(){
        const {addUserGroup,sites,resourceTypes,onRequestClose} = this.props;
        var o = {
            groupName: this.refs.groupName.value,
            description: this.refs.description.value,
            permission: this.refs.permissions.getPermissions()
        };

        function checkAndReplace(obj,perm,key,all){
            if(obj.permission[perm] && obj.permission[perm][key] && obj.permission[perm][key].length === all.length) obj.permission[perm][key] = ["ALL"];
        }

        checkAndReplace(o,"view","sites",sites);
        checkAndReplace(o,"edit","sites",sites);
        checkAndReplace(o,"view","resourceTypes",resourceTypes);
        checkAndReplace(o,"edit","resourceTypes",resourceTypes);

        addUserGroup(o)
        .then(onRequestClose);
    }

    formatPermissions(permissions){
        const {sites,resourceTypes} = this.props;
        return permissions && {
            view:{
                ...permissions.view,
                sites:permissions.view.sites && ((permissions.view.sites[0] === "ALL") ? sites : permissions.view.sites),
                resourceTypes:permissions.view.resourceTypes && ((permissions.view.resourceTypes[0] === "ALL") ? resourceTypes : permissions.view.resourceTypes)
            },
            edit: permissions.edit && {
                ...permissions.edit,
                sites: permissions.edit.sites && ((permissions.edit.sites[0] === "ALL") ? sites : permissions.edit.sites),
                resourceTypes:permissions.edit.resourceTypes && ((permissions.edit.resourceTypes[0] === "ALL") ? resourceTypes : permissions.edit.resourceTypes)
            }
        };
    }

    render(){

        const {sites,resourceTypes} = this.props;

        return (
            <StyledDialog
                {...this.props}
                onClose={this.props.onRequestClose}
                title={"Add Group"}
                maxWidth={false}
                contentStyle={{width: '86vw'}}
                className="update-user-group-dialog"
                actions={
                    <>
                        <SaveButton onClick={this.handleSave} id={"add_save_btn"}/>
                        <CancelButton style={{position: 'absolute', left: 8, bottom: 8}} onClick={this.props.onRequestClose} id={"add_cancel_btn"}/>
                    </>
                }
                >
            <table><tbody><tr>
                    <td>
                        <div style={{paddingRight:'1em'}}>
                            <div className="label">Group</div>
                            <div style={{paddingBottom: '1em'}}>
                                <input type={"text"} placeholder={"Name"} ref="groupName" id={"add_groupname_txt"}/>
                                <br/>
                                <span>Please provide group name characters exact as saved in active directory.</span>
                            </div>
                            <div className="label">Description</div>
                            <textarea
                                defaultValue={""}
                                style={{minHeight:'150px',width:'100%',boxSizing:'border-box'}}
                                ref={"description"}
                                id={"add_groupdescription_txt"}
                            />
                        </div>
                    </td>
                    <td>
                        <PermissionsTable sites={sites} resourceTypes={resourceTypes} ref={"permissions"}/>
                    </td>
                </tr></tbody></table>
            </StyledDialog>
        );
    }
}


/*
 ██████  ██████  ███    ██ ███    ██ ███████  ██████ ████████     ████████  ██████
██      ██    ██ ████   ██ ████   ██ ██      ██         ██           ██    ██    ██
██      ██    ██ ██ ██  ██ ██ ██  ██ █████   ██         ██           ██    ██    ██
██      ██    ██ ██  ██ ██ ██  ██ ██ ██      ██         ██           ██    ██    ██
 ██████  ██████  ██   ████ ██   ████ ███████  ██████    ██           ██     ██████

███████ ████████  █████  ████████ ███████
██         ██    ██   ██    ██    ██
███████    ██    ███████    ██    █████
     ██    ██    ██   ██    ██    ██
███████    ██    ██   ██    ██    ███████
*/






const mapStateToProps = state => ({
    open: state.dataEntry.open && state.dataEntry.activeForm === USERMGMT_ADD_GROUP,
    sites: state.userMgmt.sites,
    resourceTypes: state.userMgmt.resourceTypes // possible to change.
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        requestSitesForUserMgmt:actions.requestSitesForUserMgmt,
        requestResourceTypes: actions.requestResourceTypes,
        onRequestClose:actions.closeDataEntry,
        addUserGroup: actions.addUserGroup
    },dispatch)
});

export default connect(mapStateToProps,mapDispatchToProps)(UpdateUserGroupDialog);
