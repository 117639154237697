import {MOBILE_DEVICE} from '../constants/Mobile';
import * as types from '../constants/ActionTypes';
import * as forms from '../constants/DataEntryForms';
import Promise from 'promise';
import {displayErrorDialog, expireSession, openDataEntry} from './general';

import {displayErrorFromAxios, ReportService, sessionHasExpired} from './util';

import {subscribeToWS, unsubscribeToWS, subscribeToCloseWindow,unsubscribeToCloseWindow} from './util/websocket/api';
import axios from 'axios';

import {getTimes, PERIOD} from '../views/util/report/filter/util.js';
import {FILTER_TYPE,enableNextPrevButtons} from "../views/util/report/filter/util";
import {historyReports} from "../constants/History";
import {detectBrowser} from '../utils/DetectBrowser';
/*
 ██████  ███████ ███    ██ ███████ ██████   █████  ██
██       ██      ████   ██ ██      ██   ██ ██   ██ ██
██   ███ █████   ██ ██  ██ █████   ██████  ███████ ██
██    ██ ██      ██  ██ ██ ██      ██   ██ ██   ██ ██
 ██████  ███████ ██   ████ ███████ ██   ██ ██   ██ ███████
*/

const RESOURCE_REPORT = 'C0FB7CAD-8C25-11D5-9066-00105A6AE011';
const TAG_REPORT = '2D50E6C5-F1C7-4544-A91F-B1DA38045411';

// -----------------------------------------------------------------------------
// views
export const changeView = (view,reportId) => ({type: types.CHANGE_VIEW, view, reportId});


export const openDynamicReportDataEntry = (form,baseData) => {
    return (dispatch,getState) =>{
        const state = getState();
        const sel = state.report.selected;
        dispatch(openDataEntry(form,{
            ...baseData,
            reportRows: Object.keys(sel).map(i=>sel[i])
        }));
    };
};

export const openSaveReportForm = () => {
    return (dispatch,getState) => {
        dispatch(openDataEntry(forms.SAVE_REPORT,getState()));
    };
};

// -----------------------------------------------------------------------------

const CancelToken = axios.CancelToken;
let cancelRequest = null;
const CANCELLATION_MESSAGE = "Cancelled";

export const changeLocation = (location,action)=>{
    return dispatch => { // eslint-disable-line
        if(cancelRequest && action !== "REPLACE"){ // if "REPLACE", we're pushing the same path / same report (and don't really want to cancel)
            cancelRequest(CANCELLATION_MESSAGE);
        }
    };
};


// -----------------------------------------------------------------------------
// report definition
export const loadReportContent = (reportContent) => ({type:types.LOAD_REPORT_CONTENT, reportContent});
export const stopFetchingData = () => ({type:types.STOP_FETCHING_DATA});
export const requestReportContent = (filterEnable,path,filters,columns,sort,silent) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({type:types.REQUEST_REPORT_CONTENT, silent});
        const siteName = filters.siteName;
        dispatch({type:types.SITE_SELECTED, siteName});
        const reportId = state.report.reportId;
        const ishistoryReport = historyReports.indexOf(reportId);
        if (ishistoryReport >=0 && filters.objectId && filterEnable === 'objectId'){
            filters.tagId='%';
        }
        if (ishistoryReport >=0 && filters.resourceId && filterEnable === 'resourceId'){
            filters.tagId=(filters.resourceId ==='%')?'': '%';
        }
        if (ishistoryReport >=0 && filterEnable === 'tagId') {
            filters.objectId = '%';
            filters.resourceId ='%';
        }
        let data = {
            ...filters,
            fields: (columns ? columns : ""),
            sort: (sort && sort.column ? (((sort.direction === -1) ? "-" : "") + sort.column) : ""),
            limit: (MOBILE_DEVICE ? 100: '')
        };

        return ReportService.instance().get(path,{
            params:data,
            cancelToken: new CancelToken(c=>{cancelRequest = c;})
        }).then( response => {

            dispatch(loadReportContent(response.data.results));
            dispatch(loadPlaybackView());
        }).catch((e) => {
            if(e.message === CANCELLATION_MESSAGE) return; // don't want to cancel a new fetch or display an error.
            dispatch(stopFetchingData());

            if(sessionHasExpired(e)){
                dispatch(expireSession());
            }else{
                console.error(e); // eslint-disable-line
                let message = "Failed to retrieve report.";
                if(e.code === "ECONNABORTED"){
                    let msg = e.message.match(/\d/g).join("");
                    msg = msg / 60000;
                    message = "Timeout of " + msg + "min exceeded. Please logout and log back in";
                }
                const details = ((e||{}).response||{}).data || 'Error unexpected';
                dispatch( displayErrorDialog({
                    message: message,
                    details: details,
                }));
            }
        });
    };
};

function getDefaultFilters(filters){
    var setFilters = {};

    var today = getTimes(PERIOD.TODAY);

    filters.forEach(filter=>{
        if(filter.type === FILTER_TYPE.DATETIME){
            if(filter.name.match(/^from/)){
                setFilters[filter.name] = today.from.toJSON();
            }
            if(filter.name.match(/^to/)){
                setFilters[filter.name] = today.to.toJSON();
            }
        }
    });
    return setFilters;
}


export const loadReport = (reportDefinition, runReport=true) => {
    return (dispatch,getState) => {
        const sessionActive = getState().report.isSessionActive;
        dispatch({type:types.LOAD_REPORT, reportDefinition});

        let default_filter_values = getDefaultFilters(reportDefinition.filters);
        let filterEnable ='';
        const state = getState();


        // Apply the defined filter values for one time use.
        let one_time_default_filter_values={};
        let oneTimeDefaultFilterValue = Object.assign({}, state.filters.oneTimeDefaultFilterValue);

        if (Object.keys(oneTimeDefaultFilterValue).length > 0 && oneTimeDefaultFilterValue.hasOwnProperty(state.report.reportId)) {
            const onetimeFilterValues = oneTimeDefaultFilterValue[state.report.reportId];
            //
            // if(reportDefinition.filters!=null&&reportDefinition.filters.length>0) {
            //     reportDefinition.filters.forEach((filter,index) => {
            //
            //         if (onetimeFilterValues.hasOwnProperty(filter.name)) {
            //             reportDefinition.filters[index].value = onetimeFilterValues[filter.name].value;
            //         }
            //     });
            // }


            Object.keys(onetimeFilterValues).forEach(filterName => {
                one_time_default_filter_values[filterName] = onetimeFilterValues[filterName].value;
                default_filter_values[filterName] = onetimeFilterValues[filterName].value;
            });
            delete oneTimeDefaultFilterValue[state.report.reportId];
            dispatch({type: types.UPDATE_ONE_TIME_DEFAULT_FILTER_VALUE, oneTimeDefaultFilterValue});
        }

        updateDatetimeFilters(dispatch,state);

        reportDefinition.filters.forEach(filter=>{
            let currentValue = state.filters[filter.id].value;



            if(currentValue){
                if(one_time_default_filter_values[filter.name]){
                    currentValue=one_time_default_filter_values[filter.name];
                    dispatch(changeFilterValue(filter.id,one_time_default_filter_values[filter.name]));
                }

                if (state.filters[filter.id].isEnabled === false){
                    filterEnable = state.filters[filter.id].name;
                }
                default_filter_values[filter.name] = currentValue;
                return; // retain current value.
            }

            if(default_filter_values[filter.name]){
                dispatch(changeFilterValue(filter.id,default_filter_values[filter.name]));
            }


        });

        if(runReport && sessionActive){
            dispatch(
                requestReportContent(
                    filterEnable,
                    reportDefinition.url,
                    default_filter_values
                )
            );
        }
    };
};

function fetchReport(dispatch,reportId,runReport){

    if(!reportId) return Promise.reject();

    return ReportService.instance().get("/reports/"+reportId)
    .then(response => {
        dispatch( loadReport(response.data.results,runReport) );
    })
    .catch(err=>{
        console.error(err); // eslint-disable-line
        return Promise.reject(err);
    })
    .catch(err => {
        const details = ((err||{}).response||{}).data || 'Error unexpected';
        dispatch( displayErrorDialog({
            message: "Failed to retrieve report definition.",
            details: details
        }));
    });
}

export const requestReport = (reportId,runReport) => {
    return dispatch => {
        const realTimeIsDisabled = checkIfHistoryReport(reportId);
        dispatch({type:types.REQUEST_REPORT, reportId, realTimeIsDisabled});
        return fetchReport(dispatch,reportId,runReport);
    };
};

const checkIfHistoryReport = reportId => {
    if(reportId !== RESOURCE_REPORT && reportId !== TAG_REPORT) return true;
    else return false;
}

const GETTING_DATA = "Getting the data.";
const ERROR_RETRIEVING = "Error when retrieving data.";
const ERROR_UNEXPECTED = "Error unexpected.";

export const requestTagsHistory = (itemsInfo, idAttribute,indexQuery, startDate, endDate) => {
    return dispatch => {

        // let limitClustering=1750;
        let limitClustering=100000;

        // if(elementIds.length>0)
        //     limitClustering=Math.round(1000/elementIds.length);


        let itemsIds=[];
        let itemsEvent={};

        Object.keys(itemsInfo).forEach(itemId=>{
            //const currentItemInfo=itemsInfo[itemId];
            itemsIds.push(itemId);
            // itemsEvent[itemId]=currentItemInfo.event;
            itemsEvent[itemId]=indexQuery;
        });

        let data = {
            itemsId: itemsIds,
            itemsEvent: itemsEvent,
            idColumn: idAttribute,
            startDate: startDate,
            endDate: endDate,
            limitClustering:limitClustering,
            numberOfSlots:60
        };
        dispatch({type: types.REQUEST_PLAYBACK, requested: GETTING_DATA});
        return ReportService.instance().post("/playback/", data)
            .then( response => {
                    dispatch({type: types.REQUEST_PLAYBACK, requested: null});
                    dispatch({type: types.LOAD_PLAYBACK_HISTORY, tagsData: response.data, length: response.data.length});
                }
            )
            .catch(err=>{
                console.error(err); // eslint-disable-line
                // dispatch({type: types.REQUEST_PLAYBACK, requested: ERROR_RETRIEVING});
                return Promise.reject(err);
            })
            .catch(err => {
                let details = (((err||{}).response||{}).data||{}).message || ERROR_UNEXPECTED;
                if(details===ERROR_UNEXPECTED){
                    details = (err||{}).message || ERROR_UNEXPECTED;
                }
                dispatch( displayErrorDialog({
                    message: ERROR_RETRIEVING,
                    details: details
                }));
                dispatch({type: types.REQUEST_PLAYBACK, requested: ERROR_RETRIEVING});
            });
    }
};
export const clearTagsHistory = () => {
    return (dispatch)=> {
        dispatch({type: types.LOAD_PLAYBACK_HISTORY, tagsData: null, length: null});
    }

};


export const loadPlaybackView = (tags, currentMap) => ({type: types.LOAD_PLAYBACK_VIEW, currentMap});
export const addItemsPlaybackView = (selectedTags) => {
    return (dispatch,getState)=>{

        if(selectedTags!=null&& Object.keys(selectedTags).length>0) {

            const state = getState();
            const reportId=state.report.reportId;

            const currentItemsPlayback = (state.reportMap.playback&&state.reportMap.playback[reportId])?state.reportMap.playback[reportId].items:{};
            const currentEventQueryPlayback = (state.reportMap.playback&&state.reportMap.playback[reportId])?state.reportMap.playback[reportId].eventQuery:null;
            let eventQuery=null;
            let items=Object.assign({},currentItemsPlayback);
            const idAttribute= state.reportMap.mapFormat.idAttribute;
            // Find the max order for elements.
            // const orderArray = Object.keys(items).map(key =>items[key].order);
            // const maxOrderPlayback=(orderArray.length>0)?Math.max(...orderArray):0;

            // Sort to add to the end
            const sortedItems = Object.keys(currentItemsPlayback).sort((a,b) => (currentItemsPlayback[a].order > currentItemsPlayback[b].order) ? 1 : -1);
            const playbackItemsIndex = sortedItems.length;
            const playbackLastItemKey = sortedItems[playbackItemsIndex-1];
            const playbackLastItemOrder = currentItemsPlayback[playbackLastItemKey].order + 1;

            Object.keys(selectedTags).forEach(key=>{

                const item=selectedTags[key];
                const itemId=item[idAttribute];
                const itemEvent=item.event;

                let duplicate = false;
                sortedItems.map(v => {
                    let playbackItem = currentItemsPlayback[v];
                    if(playbackItem[idAttribute] === itemId) duplicate = true;
                    return duplicate;
                });

                if(duplicate) return;

                if(!items.hasOwnProperty(itemId)){
                    items[itemId]={};
                    items[itemId].event=[];
                    items[itemId].order = playbackLastItemOrder;
                }

                if(itemEvent!=null&&items[itemId].event.indexOf(itemEvent.toLowerCase())<0){
                    items[itemId].event.push(itemEvent.toLowerCase());
                }
            });


            // Find the event for the ES query.
            Object.keys(items).forEach(key=>{

                const item=items[key];
                // const itemId=item[idAttribute];
                const itemEvent=item.event;

                if(eventQuery==null&&itemEvent!=null&&itemEvent.indexOf("zone change")>=0){
                    eventQuery="zone change"
                }
            });

            if(eventQuery==null&&currentEventQueryPlayback!=null){
                eventQuery=currentEventQueryPlayback;
            }
            else if(eventQuery==null&&currentEventQueryPlayback==null){
                eventQuery="locate";
            }

            dispatch({type: types.MODIFY_PLAYBACK_ITEMS, items: items,eventQuery:eventQuery,reportId:reportId});
        }
    }

};
export const removeItemsPlaybackView = (removeTags) => {
    return (dispatch,getState)=>{

        if(removeTags!=null&& removeTags.length>0) {

            const state = getState();

            const reportId=state.report.reportId;

            const currentItemsPlayback = (state.reportMap.playback&&state.reportMap.playback[reportId])?state.reportMap.playback[reportId].items:{};
            let itemsPlayback=Object.assign({},currentItemsPlayback);

            removeTags.forEach(itemId=>{

                if(itemsPlayback.hasOwnProperty(itemId)){
                    delete itemsPlayback[itemId];
                }
            });

            dispatch({type: types.MODIFY_PLAYBACK_ITEMS, items: itemsPlayback,reportId:reportId});
        }
    }

};
function findKey(obj, searchKey) {
    return Object.keys(obj)
        .find((key) => {
            return key.toLowerCase() === searchKey.toLowerCase();
        });
}

export const updateItemsPlaybackView = selectedTags => {

    return (dispatch, getState) => {
        if(selectedTags!=null&& Object.keys(selectedTags).length>0) {
            const state = getState();
            const reportId=state.report.reportId;
            let eventQuery=null;
            const items = {};
            const currentEventQueryPlayback = (state.reportMap.playback&&state.reportMap.playback[reportId])?state.reportMap.playback[reportId].eventQuery:null;
            const idAttribute= state.reportMap.mapFormat.idAttribute;

            let count = 0;
            Object.keys(selectedTags).forEach(key => {
                const item = selectedTags[key];
                let itemId = item[idAttribute];
                const itemEvent = item.event;

                // Finds the key (case insensitive) in the current object.
                const foundKey=findKey(items,itemId);

                if(foundKey==null){
                    count++;
                    items[itemId]={};
                    items[itemId].order=count;
                    items[itemId].event=[];
                }else{
                    itemId=foundKey;
                }

                if(itemEvent!=null&&items[itemId].event.indexOf(itemEvent.toLowerCase())<0){
                    items[itemId].event.push(itemEvent.toLowerCase());
                }
            })

            // Find the event for the ES query.
            Object.keys(items).forEach(key=>{
                const item=items[key];
                const itemEvent=item.event;
                if(eventQuery==null&&itemEvent!=null&&itemEvent.indexOf("zone change")>=0){
                    eventQuery="zone change"
                }
            });

            if(eventQuery==null&&currentEventQueryPlayback!=null) eventQuery=currentEventQueryPlayback;
            else if(eventQuery==null&&currentEventQueryPlayback==null) eventQuery="locate";
            dispatch({type: types.MODIFY_PLAYBACK_ITEMS, items: items,eventQuery:eventQuery,reportId:reportId});
        }
    }
};

export const saveCurrentView = (currentView) => ({type: types.SAVE_CURRENT_VIEW, currentView})
export const setPlaybackMapId = (playbackMapId) =>({type: types.SET_DEFAULT_PLAYBACK_MAP_ID, playbackMapId})
export const setPlaybackInfo = (playbackId,playbackSiteName) =>({type: types.SET_CURRENT_PLAYBACK_SITE_NAME,playbackId,playbackSiteName})
export const setPlaybackDateRange = (reportId, fromDateRange, toDateRange) => ({type: types.SET_PLAYBACK_DATE, reportId: reportId, fromDateRange: fromDateRange, toDateRange: toDateRange});

export const requestRefreshRate = ()=>{
    return (dispatch)=>{
        const rr = parseInt( localStorage.getItem('mwe-report-refresh-rate') );

        dispatch(setRefreshRate(rr));
    };
};

export const getRefreshRate = ()=>{
    return ()=>{
        const rr = localStorage.getItem('mwe-report-refresh-rate');
        if(rr){
            return Promise.resolve(rr);
        }else{
            return Promise.reject();
        }
    };
};

export const setRefreshRate = (rr)=>{
    return (dispatch)=>{
        if(rr && !isNaN(rr)){
            dispatch(({type:types.SET_REFRESH_RATE, refreshRate: rr}));
            return Promise.resolve(
                localStorage.setItem('mwe-report-refresh-rate',rr)
            );
        }else{
            dispatch(({type:types.SET_REFRESH_RATE, refreshRate: null}));
            return Promise.resolve(
                localStorage.removeItem('mwe-report-refresh-rate')
            );
        }

    };
};


// -----------------------------------------------------------------------------
// parameter values

export const loadFilterValues = (filterId,values) => ({type:types.LOAD_FILTER_VALUES, filterId, values});
export const requestFilterValues = (filterId, overrideValue) => {
    return (dispatch,getState) => {
        if(!filterId) return; // don't send [dumb] requests...

        dispatch({type:types.START_LOADING});
        dispatch({type:types.REQUEST_FILTER_VALUES, filterId});

        var state = getState();

        var report = state.report.meta;
        var constraints = report.filters.reduce( (appliedFilters, __filterId) => {
            var filter = state.filters[__filterId];
            if(filter.value != null || overrideValue) {
                return {
                    ...appliedFilters,
                    [filter.name]: (overrideValue && filter.id === filterId) ? overrideValue : filter.value,
                };
            }
            return appliedFilters;
        },{});


        return ReportService.instance().get("/parameters/"+filterId+"/values",{
            params:{...constraints}
        })
        .then(response => {
            const results = response.data.results;
            dispatch(loadFilterValues(filterId,response.data.results));
            return [results,response.data.status.complete];
        });
    };
};


export function updateDatetimeFilters(dispatch, state, nextPrevButton=false) {

    let updatedFilters = {};
    if(!nextPrevButton){
        nextPrevButton = enableNextPrevButtons(state.report.reportId);
    }
    let datetimeFilters = state.filters.datetimeFilters[state.report.reportId];

    // If datetime filters exist for this report recalculate the current range.
    if (datetimeFilters != null) {
        if(!nextPrevButton){
        for (const filterKey in datetimeFilters) {

            const filter = datetimeFilters[filterKey];
            let filterIdFrom = null;
            let filterIdTo = null;
            if (state.report.meta.filters){
                state.report.meta.filters.forEach(filterId => {
                    const currentFilter=state.filters[filterId] || null;
                    if (currentFilter !== null && currentFilter.name.indexOf("from") === 0) {
                        filterIdFrom = filterId;
                    } else if (currentFilter !== null && currentFilter.name.indexOf("to") === 0) {
                        filterIdTo = filterId;
                    }
                });
            }

            if (filter.value != null && filter.from != null && filter.to != null && filter.value !== PERIOD.CUSTOM) {
                const times = getTimes(filter.value);
                if (state.report.meta.filters && filterIdFrom && filterIdTo){
                    filter.from = filterIdFrom;
                    filter.to = filterIdTo;
                }
                // If change in filters exists.
                if (state.filters.hasOwnProperty(filter.from) && state.filters[filter.from].value !== times.from.toJSON()) {
                    updatedFilters[filter.from] = state.filters[filter.from];
                    updatedFilters[filter.from].value = times.from.toJSON();
                }
                if (state.filters.hasOwnProperty(filter.to) && state.filters[filter.to].value !== times.to.toJSON()) {
                    updatedFilters[filter.to] = state.filters[filter.to];
                    updatedFilters[filter.to].value = times.to.toJSON();
                }
            }
        }
        }
    }
    // If datetime filters does not exist for this report put by default the value of TODAY range.
    else if (state.report != null && state.report.meta != null && state.report.meta.filters != null) {

        const todayTimes = getTimes(PERIOD.TODAY);

        state.report.meta.filters.forEach(filterId => {
            if (state.filters.hasOwnProperty(filterId) && state.filters[filterId].type === "datetime") {

                const currentFilter=state.filters[filterId];

                if(currentFilter.name.indexOf("from")===0&&currentFilter.value!==todayTimes.from.toJSON()){
                    updatedFilters[filterId]=currentFilter;
                    updatedFilters[filterId].value=todayTimes.from.toJSON();
                }
                else if(currentFilter.name.indexOf("to")===0&&currentFilter.value!==todayTimes.to.toJSON()){
                    updatedFilters[filterId]=currentFilter;
                    updatedFilters[filterId].value=todayTimes.to.toJSON();
                }
            }
        });
    }

    // Update store for datetime filters.
    if (Object.keys(updatedFilters).length > 0) {
        dispatch({type: types.CHANGE_BATCH_FILTER_VALUE, updatedFilters});
    }

    return updatedFilters;
}

export const reloadReportWithChanges = ({filters,sort,silent,nextPrevButton}={}) => {
    return (dispatch,getState) => {

        dispatch({type:types.RELOAD_REPORT});

        // Get State
        var state = getState();
        updateDatetimeFilters(dispatch,state,nextPrevButton);

        // Get Filters from State.
        var report = state.report.meta;
        var filterEnable = '';
        var _filters = report.filters.reduce( (appliedFilters, filterId) => {
            var filter = state.filters[filterId];
            if(filter.value !== undefined && filter.value !== null){
                if(filter.isEnabled !== undefined && !filter.isEnabled){
                    filterEnable = filter.name;
                }
                return {
                    ...appliedFilters,
                    [filter.name]:filter.value,
                };
            }
            return appliedFilters;
        },{});


        var _sort = sort ? sort : {
            column: state.report.sortColumn,
            direction: state.report.sortDirection
        };

        // Make adjustments per arguments.
        _filters = {..._filters,...filters};

        // request report
        return dispatch(requestReportContent(filterEnable,report.url,_filters,null,_sort, silent));
    };
};

export const reloadReport = reloadReportWithChanges; // this will probably be called with no arguments.

export const changeFilterValue = (filterId,value) => {
    return (dispatch, getState) => {
        const state = getState();
        // Dispatch to reducers
        dispatch({type:types.CHANGE_FILTER_VALUE, filterId, value});
        if(state.filters[filterId] && state.filters[filterId].value !== value){
            dispatch(reloadReport({}))
        }
    };
};

export const changeFilterValueRange = (fromId, newDateTimeFrom,toId, newDateTimeTo) => {
    return (dispatch, getState) => {
        const state = getState();
        // Dispatch to reducers (to manage internal state)
        dispatch({type:types.CHANGE_FILTER_VALUE, filterId:fromId, value:newDateTimeFrom});
        dispatch({type:types.CHANGE_FILTER_VALUE, filterId:toId, value:newDateTimeTo});
        if(state.filters[fromId] && state.filters[fromId].value !== newDateTimeFrom
            && state.filters[toId] && state.filters[toId].value !== newDateTimeTo ){
            dispatch(reloadReport({nextPrevButton:true}))
        }
    };
};
export const changeFilterValueHistory = (filterId,value, isEnabled) => {
    return dispatch=> {
        // Dispatch to reducers (to manage internal state)
        dispatch({type:types.CHANGE_FILTER_STATE, filterId, value, isEnabled});
        if (!isEnabled)
        {
            dispatch(reloadReport({}));
        }

    };
};

export const changeSiteName = (siteId, filterName) => {
    return (dispatch, getState) => {
        dispatch(setPlaybackInfo(siteId, filterName));
        const state = getState();
        let reportId = state.report.reportId;
        if(state.report.sidebarColumnMeta[reportId].siteName && state.report.sidebarColumnMeta[reportId].siteName.filter){
            let filterId = state.report.sidebarColumnMeta[reportId].siteName.filter;
            dispatch(changeFilterValue(filterId, filterName));
        }
    };
}

export const cleanFilters = () => {
    return (dispatch, getState) => {
        //const state = getState();
        dispatch({type:types.CLEAN_FILTERS});
    };
};

export const cleanPlaybackDate = () => ({type:types.CLEAN_PLAYBACK_DATE});

export const cleanLocalMap = () => ({type: types.CLEAN_LOCAL_MAP});

export const connectWS = () => {
    return (dispatch, getState) => {
        subscribeToCloseWindow();
        dispatch({type: types.CONNECT_WS});
        subscribeToWS(
            (err, data) => {
              if( Object.keys(data.resourceMap).length > 0 || Object.keys(data.tagMap).length > 0 ) {
                dispatch({type: types.UPDATE_WEBSOCKET, data});
              }
            },
            (status) => {
                dispatch({type: types.WEBSOCKET_STATUS, status: status});
            }
        );
    };
};

export const disconnectWS = () => {
    return (dispatch, getState) => {
        let state = getState();
        let { report } = state;
        let { reportId, realTime,
            //connectedRealTime
        } = report;
        realTime[reportId] = false;
        let result = false;
        if(realTime[TAG_REPORT] || realTime[RESOURCE_REPORT]) {
            result = true;
        }
        if(!result) {
            unsubscribeToWS();
            unsubscribeToCloseWindow();
        }
        dispatch({type: types.DISCONNECT_WS, data: {}});
    };
};

export const changeStatusWS = () => ({type: types.WEBSOCKET_STOP_BLINK});

// Change all filter values in a array.
export const changeBatchFilterValue = (filterArray) => {
    return (dispatch, getState) => {
        const state = getState();
        // Build the filters object with new values.
        let filters = {};
        let updateReport=false;
        for (let key in filterArray) {
            const filterValue = filterArray[key];
            if (state.filters.hasOwnProperty(key))
            {
                filters[key] = {...state.filters[key], ...filterValue};
                // If change in filters exists.
                if(!updateReport&&state.filters[key].value!==filterValue.value) {
                    updateReport = true;
                }
            }
            else if(state.filters.datetimeFilters.hasOwnProperty(key)){
                filters[key]={...state.filters.datetimeFilters[key],...filterArray[key]};
            }
        }
        dispatch({type: types.CHANGE_BATCH_FILTER_VALUE, filters});
        if(updateReport)
            dispatch(reloadReport({}))

    };
};

export const changeFilterFromToValue = (fromId, fromValue, toId, toValue) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({type:types.CHANGE_FILTER_VALUE, filterId: fromId, value: fromValue});
        dispatch({type:types.CHANGE_FILTER_VALUE, filterId: toId, value: toValue});
        if(state.filters[fromId] && state.filters[fromId].value !== fromValue && state.filters[toId] && state.filters[toId].value !== toValue){
            dispatch(reloadReport({}))
        }
    };
};

export const changeSpecialFilterValue = (filterKey,filterValue,filterFrom,filterTo) => {
    return (dispatch, getState) => {

        const state=getState();

        let datetimeFilters={};
        if(state.filters[state.report.reportId]==null){
            datetimeFilters[state.report.reportId]={};

        }
        else if(state.filters[state.report.reportId]!=null){
            datetimeFilters[state.report.reportId]=state.filters[state.report.reportId];
        }

        datetimeFilters[state.report.reportId][filterKey]={value:filterValue,from:filterFrom,to:filterTo};

        dispatch({type:types.CHANGE_SPECIAL_FILTER_VALUE, datetimeFilters:datetimeFilters});
    };
};

export const changeDateRange = (qty) => ({type: types.CHANGE_DATE_RANGE, daysQty: qty})


export const requestZones = (site) =>{
    return dispatch => {

        if(!site){
            return Promise.resolve([]);
        }

        return ReportService.instance().get(`/zone-data/zones?site=${site}`)
        .then(res=>{
            return res.data;
        })
        .catch(displayErrorFromAxios.bind(this,dispatch));
    };
};

// Show/Hide details rigth sidebar.
export const toggleRightSidebar = () => ({type:types.TOGGLE_RIGHT_SIDEBAR});
export const openRightSidebar = (itemId,highlightedItemMap) => ({type:types.OPEN_RIGHT_SIDEBAR,itemId:itemId,highlightedItemMap:highlightedItemMap});
export const highlightItemMap = (itemId) => ({type:types.HIGHLIGHT_ITEM_MAP,itemId:itemId});

// -----------------------------------------------------------------------------
// report

export const hoverRow = (index,datum) => ({type:types.REPORT_HOVER_OVER_ROW, index, datum:[datum]});
export const clickRow = (index,datum, reportId,site,map) => {
    return dispatch => {
        dispatch({type:types.REPORT_CLICK_ON_ROW, index, datum});
        if(!datum.___selected) {
            dispatch({type: types.CHANGE_LOCAL_MAP, reportId, map, site});
            dispatch(setPlaybackInfo(datum.siteId, datum.siteName));
        }
        dispatch(setPlaybackMapId(datum.mapId));
    };
};

//
export const selectManyRows = (idArray,toogle) => {
    return (dispatch,getState) => {
        let newData=[];
        let select={};
        let showRightSidebar=false;

        const state = getState();
        const selectedState = {...state.report.selected};
        let changed=false;
        state.report.data.forEach(row=>{
            if(idArray.indexOf(row.___uid)>=0){
                if(toogle === true){
                    let selected = row.___selected;
                    row.___selected= ! selected;
                }else{
                    if(row.___selected===false)
                    {
                        changed=true;
                    }
                    row.___selected=true;
                }
                select[row.___uid]=row;
            }
            newData.push(row);
        });

        if(state.report.showRightSidebar!==true&&newData.length>0&&newData.length===state.report.data){
            showRightSidebar=true;
        }
        else{
            showRightSidebar=state.report.showRightSidebar;
        }

        if(toogle===false&&changed===false){
            newData=state.report.data;
        }

        select = {...select, ...selectedState};

        dispatch({type:types.REPORT_SELECT_MANY, data:newData,selected:select,showRightSidebar:showRightSidebar});

    };
};

export const selectCluster = (idArray,lastObjectSelected) => {
    return (dispatch,getState) => {
        let newData=[];
        let select={};
        let showRightSidebar=false;

        const state = getState();
        //finding items of idArray in state.report.selected
        const selectedState = {...state.report.selected};

        const findSelected = Object.keys(selectedState).findIndex(row =>idArray.indexOf(row)>=0);

        let selected=false;
        if(findSelected === -1){
            selected=true;
        }

        //assign selected property according the number of items no selected
        state.report.data.forEach(row=>{
            if(idArray.indexOf(row.___uid)>=0){
                row.___selected=selected;
                if(selected===true) {
                    select[row.___uid] = row;
                }
                else if(selected===false&&selectedState.hasOwnProperty(row.___uid)) {
                    delete selectedState[row.___uid];
                }
            }
            newData.push(row);
        });


        // Keept previus selected objects.
        select={...select,...selectedState};

        if(state.report.showRightSidebar!==true&&newData.length>0&&newData.length===state.report.data){
            showRightSidebar=true;
        }
        dispatch({type:types.REPORT_SELECT_MANY, data:newData,selected:select,showRightSidebar:showRightSidebar,lastObjectSelected:lastObjectSelected});
    };
};


export const selectAllRows = (selected) => ({type:types.REPORT_SELECT_ALL, selected});

export const hoverFeatures = (datum) => ({type:types.MAP_HOVER_FEATURE, datum});
export const selectFeature = (datum) => ({type:types.MAP_SELECT_FEATURE, datum});

// users page
export const clickUsersTab = (report) => ({type:types.USERS_CLICK_TAB, report});


/*
██ ███    ██ ██ ████████
██ ████   ██ ██    ██
██ ██ ██  ██ ██    ██
██ ██  ██ ██ ██    ██
██ ██   ████ ██    ██
*/

function loadJSONFromStorage(key){
    var val = window.localStorage.getItem(key);
    try{
        val = JSON.parse(val);
        return val;
    }catch(e){
        return null;
    }
}

export const loadColumnOrder = (columnOrder) => ({type:types.LOAD_COLUMN_ORDER, columnOrder});
export const loadSidebarColumOrder = (sidebarColumnOrder) => ({type: types.LOAD_SIDEBAR_COLUMN_ORDER, sidebarColumnOrder});
export const loadFilters = (filters) => ({type:types.LOAD_FILTERS, filters});
export const loadPlaybackDate = (dateRange) => ({type:types.LOAD_PLAYBACK_DATE, dateRange});
export const loadLocalMapData = (currentLocalMap) => ({type:types.LOAD_LOCAL_MAP, currentLocalMap});

//load filter from device manager
export const loadDMSFilters = (selectedFilters) => ({type:types.LOAD_SELECTED_FILTERS, selectedFilters: selectedFilters});
// Load column order for device manager
export const loadDeviceManagerColumnOrder = (deviceManagerColumnOrder) => ({type:types.LOAD_DEVICE_COLUMN_ORDER, columnOrder: deviceManagerColumnOrder});
export const initializeUserState = (username) => {
    return dispatch => {
        var columnOrder = loadJSONFromStorage(`mwe-persisted-state/report/column-order/${username}`);
        if(columnOrder) dispatch(loadColumnOrder(columnOrder));

        var sidebarColumnOrder = loadJSONFromStorage(`mwe-persisted-state/report/sidebar-column-order/${username}`)
        if (sidebarColumnOrder) {
            dispatch(loadSidebarColumOrder(sidebarColumnOrder));
        }

        // Recover filters from "browser local storage".
        var filters = loadJSONFromStorage(`mwe-persisted-state/filters/${username}`);
        if(filters) dispatch(loadFilters(filters));

        var selectedFilters = loadJSONFromStorage(`mwe-persisted-state/deviceManager/selectedFilters/${username}`);
        if(selectedFilters) dispatch(loadDMSFilters(selectedFilters));

        var columnOrderDeviceManager = loadJSONFromStorage(`mwe-persisted-state/deviceManager/columnOrder/${username}`);
        if(columnOrderDeviceManager) dispatch(loadDeviceManagerColumnOrder(columnOrderDeviceManager));

        var dateRange = loadJSONFromStorage(`mwe-persisted-state/playback/date-range/${username}`);
        if(dateRange) dispatch(loadPlaybackDate(dateRange));

        var currentLocalMap = loadJSONFromStorage(`mwe-persisted-state/reportMap/current-local-map/${username}`);
        if(currentLocalMap) dispatch(loadLocalMapData(currentLocalMap));

        dispatch({type: types.CHECK_BROWSER, modernBrowser: detectBrowser()})
    };
};
