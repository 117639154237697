import React, {useState, useEffect} from 'react';

import MapInformation from './MapInformation';

const MapsBySite = ({siteProps, mapsProps, selectMap}) => {

    let [site, setSite] = useState({});
    let [maps, setMaps] = useState([]);

    useEffect(()=>{

        setSite(siteProps);
        setMaps(mapsProps);

    }, [siteProps, mapsProps]);

    const renderMaps = () => {
        return maps ? maps.map((map, i) => {
            return <MapInformation key={i} site={site} map={map} selectMap={selectMap}/>
        }) : null
    };

    const allMaps = {
        name: site.name + " all maps",
        countDevices: mapsProps.reduce((acc, item) => {return acc + item.countDevices}, 0),
        allMaps: true
    };

    const handleSelectMap = () => {
        selectMap(null)
    };

    return (
        <div>
            <div className={"header-site-map"}><h3>{site.name}</h3></div>
            <div className={"content-site-map"}>
                <MapInformation map={allMaps} site={site} selectMap={handleSelectMap}/>
                {renderMaps()}
            </div>
        </div>
    )
};

export default MapsBySite;