import React, {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {unSelectFilter, resetAllFilters} from "../../../actions";


const FilterItem = ({type, keyText, text}) => {
    const dispatch = useDispatch();
    return (
        <div className={"applied-filter-item"}>
            <span className={"applied-filter-item-text"}>{text}</span>
            <span className={"applied-filter-item-close"} onClick={()=>dispatch(unSelectFilter(type,keyText,text))}>X</span>
        </div>
    );
};

const AppliedFiltersLanding = ({setHeaderHeight, type}) => {
    const selectedFilters = useSelector(state => state.deviceManager.selectedFilters[type]);
    const ref = useRef(null);
    const dispatch = useDispatch();
    useEffect(() => {
        setHeaderHeight(ref.current ? ref.current.clientHeight : 0);
    })

    // const loadFilters=() => {
    //     // Load first time on mount component.
    //     const unmount = () => {
    //         dispatch(resetAllFilters());
    //     }
    //     return unmount;
    // }

    // // Only call once when mount.
    // useEffect(loadFilters, []);

    const NUMBER_ROWS = 2;
    
    return (
        <>
            {selectedFilters.length > 0 &&
                <div ref={ref} className={"applied-filters-container"}>
                    <div className={"applied-filters"} style={{maxHeight: `${NUMBER_ROWS * 42}px`}}>
                        {selectedFilters.map((item, index) => (
                            <FilterItem type={type} key={index} keyText={Object.keys(item)} text={Object.values(item)}/>
                        ))}    
                    </div>
                    <div className={"applied-filters-clear"} onClick={() => dispatch(resetAllFilters(type))}>
                        Reset all filters
                    </div>    
                </div>
            }
        </>
    );
};
export default AppliedFiltersLanding;