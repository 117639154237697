import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles({
    paperDialog: {
        overflowY: "visible",
        marginTop: "4em"
    },
    scrollPaper:{
        alignItems: "flex-start"
    }
});

const StopDeviceDialog = ({ open, type, closeDialog, actionSuccess}) => {
    const classes = useStyles();
    const styleButton = {backgroundColor:'#4c4c4c',color:'#fff',border:'none',fontSize:'12px',padding:'0.75em',width:'150px',textTransform:'none'};

    const renderCloseButton = () => {
        return (
            <div style={{position:'absolute',top:'-0.5em',right:'-0.5em'}}>
                <IconButton aria-label="close"
                    size="small"
                    onClick={closeDialog}
                    style={{cursor:'pointer',position:'relative',zIndex:'100',backgroundColor:'#000'}}
                    >
                    <CloseIcon style={{color: '#fff'}} />
                </IconButton>
            </div>
        );
    }
    
    const handleSuccessButton = () => {
        actionSuccess();
        closeDialog();
    }

    return (
        <Dialog
            fullWidth
            maxWidth={'sm'}
            open={open}
            onClose={closeDialog}
            classes={{paper:classes.paperDialog, scrollPaper: classes.scrollPaper}}>
            <DialogTitle style={{paddingBottom:0, fontSize:'0.8em'}}>
                <div style={{borderBottom:'1px solid #000'}}>
                    <span style={{fontWeight:'bold'}}>{`Are you sure you want to ${type} this device?`}</span>
                </div>
            </DialogTitle>
            {renderCloseButton()}
            <DialogActions style={{padding:'16px 24px'}}>
                <button style={{...styleButton, backgroundColor:'#fff',color:'#007CB0'}} 
                        onClick={closeDialog}>
                        {`No, do not ${type}`}
                </button>
                <button style={{...styleButton, background: '#007CB0',color: '#fff'}} 
                        onClick={handleSuccessButton}>
                        {`Yes, ${type} device`}
                </button>
            </DialogActions>           
        </Dialog>
    );
}

export default StopDeviceDialog;