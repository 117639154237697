import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Button, IconButton, Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import {Close as CloseIcon} from '@material-ui/icons';
import LinkedDevicesPanel from '../accordionItems/LinkedDevicesPanel';

const useStyles = makeStyles({
    paperDialog: {
        overflowY: "visible"
    },
    tooltip:{
        color:'#000',
        backgroundColor:'#e6ee31',
        fontSize:'14px'
    },
    arrow:{
        color: '#e6ee31'
    },
    popper:{
        zIndex: 0
    }
});

const DialogSaveChanges = ({openDialog, closeDialog, actionSuccess}) => {
    const classes = useStyles();
    const renderCloseButton = () => {
        return (
            <div style={{position:'absolute',top:'-0.5em',right:'-0.5em'}}>
                <IconButton aria-label="close"
                    size="small"
                    onClick={closeDialog}
                    style={{cursor:'pointer',position:'relative',zIndex:'100',backgroundColor:'#000'}}>
                    <CloseIcon style={{color: '#fff'}} />
                </IconButton>
            </div>
        );
    }

    return (
        <Dialog
            fullWidth
            maxWidth={'md'}
            open={openDialog}
            onClose={closeDialog}
            classes={{paper:classes.paperDialog}}>
            <DialogTitle style={{paddingBottom:0}}><span style={{fontSize:'0.8em',fontWeight:'bold'}}>All linked devices will be updated</span></DialogTitle>
            {renderCloseButton()}
            <DialogContent style={{fontSize:'0.9em',display:'flex',flexDirection:'column',maxHeight:'100%'}}>
                <div style={{flex:0, marginBottom:'5px'}}>
                    Are you sure?
                </div>
                <div style={{width:'100%',maxHeight:'200px',backgroundColor:'#fff'}}>
                    <LinkedDevicesPanel showWarning={false} checkColumn={true}/>
                </div>
            </DialogContent> 
            <DialogActions>
                <Button style={{background: '#fff', color: '#007CB0', padding: '0.5em', textTransform: 'none'}} onClick={closeDialog} component="span">
                    Cancel
                </Button>
                <Button style={{background: '#007CB0',color: '#fff', padding: '0.5em', width: '200px', textTransform: 'none'}} 
                        onClick={actionSuccess} component="span">
                    Yes, I´m sure
                </Button>
            </DialogActions>               
        </Dialog>
    )
}

export default DialogSaveChanges;