import React from 'react';
import {List,
    ListItem,
    ListItemText,
    ListItemIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import items from './DeviceType';
const useStyles = makeStyles({
    itemIcon: {
        minWidth: '40px'
    }
});

const DetailsSidebar = React.memo(props => {
    const {scrollView} = props;
    const classes = useStyles();
    const includeFields = ['Identity','Antennas'];
    const getList = () => {
        return items.filter(object => includeFields.indexOf(object.name) > -1);

    };

    return (
        <div className={"add-template-sidebar"}>
            <List>
                {getList().map(v => (
                    <ListItem button
                              key={v.name}
                              className={"add-template-sidebar-item"}
                              onClick={() => scrollView(v.name)}
                    >
                        <ListItemIcon classes={{root:classes.itemIcon}} >
                            {v.icon}
                        </ListItemIcon>

                        <ListItemText
                            className={"add-template-sidebar-text"}
                            primary={v.name}
                            style={{fontWeight: 'bold'}} />

                    </ListItem>
                ))}
            </List>
        </div>
    );
},(prevProps, nextProps) =>{
    return (
        prevProps.deviceId === nextProps.deviceId &&
        prevProps.windowWidth === nextProps.windowWidth
    );
})

export default DetailsSidebar;