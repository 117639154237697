import React from 'react';
import PropTypes from 'prop-types';

const absoluteFit = {
    position:'absolute',
    top:0,
    bottom:0,
    left:0,
    right:0
};

export default class CustomTable extends React.Component{

    static get propTypes(){
         return {
             data: PropTypes.any,
             columns:PropTypes.arrayOf(
                 PropTypes.shape({
                     caption:PropTypes.string,
                     key:PropTypes.string,
                     align:PropTypes.string
                 })
             ),
             style:PropTypes.object,
             lastLineTotal: PropTypes.bool,
             rowProperties: PropTypes.object
         };
    }

    static get defaultProps(){
        return {
            color: '#00779F',
            style:{},
            lastLineTotal: false
        };
    }

    render(){

        const {data,columns,style} = this.props;

        const _style = {
            ...absoluteFit,
            padding:'0.5em 1em',
            boxSizing:'border-box',
            overflowY:"auto",
            ...style
        };

        // I'm assuming that if there's only one column, the title
        // will suffice as the header.
        const header = (columns && columns.length > 1) ? (
            <thead>
                {this.renderHeader(columns)}
            </thead>
        ) : null;

        return (
            <div className="zebra-quick-stat" style={_style}>
                <table style={{width:'100%'}}>
                    {header}
                    <tbody>
                        {this.renderBody(columns,data)}
                    </tbody>
                </table>
            </div>
        );
    }

    renderHeader(columns){

        const headerStyle = {
            color:'#00779F',
            fontWeight:'normal',
            fontStyle:'italic',
            fontSize:'0.8em',
            letterSpacing:'0.5px',
            paddingBottom:'0.5em'
        };

        return (
            <tr>
                {columns.map((col,i)=>(
                    <th key={i} style={{...headerStyle,textAlign:col.align||"left"}}>{col.caption}</th>
                ))}
            </tr>
        );
    }

    renderBody(columns,data){



        const cellStyle={
            padding:'0.25em 0',
        };



        return data.map((datum,i)=>(
            <tr key={i} style={this.getRowStyle(datum,i,i===data.length-1)}>
                {columns.map((col,j)=>(
                    <td key={j} style={{...cellStyle,textAlign:col.align||"left"}}>
                        {datum[col.key]}
                    </td>
                ))}
            </tr>
        ));
    }

    getRowStyle(datum,i,isLast){
        const rowStyle={
            fontSize:'0.9em'
        };

        const {lastLineTotal,rowProperties} = this.props;

        const textColorObj = rowProperties && rowProperties.textColor;

        return {
            ...rowStyle,
            color: (textColorObj && textColorObj.attribute && (datum[textColorObj.attribute] || textColorObj.default)) || "inherit",
            fontWeight: (lastLineTotal && isLast ? 'bold' : 'normal'),
            backgroundColor: (i % 2 === 0) ? 'transparent' : '#eee'
        };
    }
}
