import React from 'react';
import {HashRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import ReportsPage from './views/home/ReportsPage';
import SavedReportsPage from './views/home/SavedReportsPage';
import UsersPage from './views/users/Users';
import MainPage from './views/MainApp';
import AppliancesPage from './views/appliances/Appliances';
import DashboardPage from './views/dashboard/Dashboard';
import SitesPage from './views/sites/Sites';
import LoginPage from './views/login/Login';
import LandingPage from './views/landing/Landing';
import ImportPage from './views/import/Import';
import * as NConstants from "./utils/NConstant";
import DragDevices from "./views/deviceManager/maps/DragDevices";
import LandingDeviceManager from './views/deviceManager/landing/LandingDeviceManager';
import AddDevices from './views/deviceManager/addDevicesManually/AddManually';
import EditDeviceMap from './views/deviceManager/templates/EditDeviceMap';
import EditDevice from './views/deviceManager/templates/EditDevice';


import FormTemplate from "./views/deviceManager/templates/FormTemplate";
import LandingTemplateManager from "./views/deviceManager/templates/LandingTemplateManager";

import {
    PATH_ADD_DEVICES_MANUALLY, PATH_ADD_BATCH_DEVICES, PATH_EDIT_DEVICE, PATH_EDIT_DEV,
    PATH_ADD_TEMPLATE, PATH_EDIT_TEMPLATE, PATH_ADD_SYNC_VIA_ZEBRA_DATA_SERVICE
} from '../app/constants/DeviceManager';
import AddViaZebraDataService from "./views/deviceManager/addDevicesManually/AddViaZebraDataService";
class WrappedMainPage extends React.Component{
    render(){
        return (
            <MainPage {...this.props}>
                <Switch>
                    <Route path={"/"+NConstants.ROUTES.LANDING} component={LandingPage} />
                    <Route exact path={"/"+NConstants.ROUTES.DASHBOARD+"/:dashboardId"} component={DashboardPage}/>
                    <Route path={"/"+NConstants.ROUTES.DASHBOARD} component={DashboardPage}/>
                    <Route path={"/"+NConstants.ROUTES.IMPORT} component={ImportPage}/>
                    <Route exact path={"/"+NConstants.ROUTES.REPORTS+"/:reportId"} component={ReportsPage}/>
                    <Route exact path={"/"+NConstants.ROUTES.SAVED+"/:reportId"} component={SavedReportsPage} />
                    <Route path={"/"+NConstants.ROUTES.USERS} component={UsersPage}/>
                    <Route path={"/"+NConstants.ROUTES.SITES} component={SitesPage}/>
                    <Route path={"/"+NConstants.ROUTES.APPLIANCES} component={AppliancesPage} />

                    <Route path={"/"+NConstants.ROUTES.DEVICE_MANAGER + "/" + NConstants.ROUTES.DEVICES + "/" + PATH_EDIT_DEVICE + "/:pathParam"} component={EditDeviceMap} />
                    <Route path={"/"+NConstants.ROUTES.DEVICE_MANAGER + "/" + NConstants.ROUTES.DEVICES + "/" + PATH_EDIT_DEV + "/:pathParam"} component={EditDevice} />

                    <Route path={"/"+NConstants.ROUTES.DEVICE_MANAGER + "/" + NConstants.ROUTES.DEVICES + "/" + PATH_ADD_DEVICES_MANUALLY} component={() => <AddDevices pathParam={PATH_ADD_DEVICES_MANUALLY}/>} />
                    <Route path={"/"+NConstants.ROUTES.DEVICE_MANAGER + "/" + NConstants.ROUTES.DEVICES + "/" + PATH_ADD_BATCH_DEVICES} component={() => <AddDevices pathParam={PATH_ADD_BATCH_DEVICES}/>} />
                    <Route path={"/"+NConstants.ROUTES.DEVICE_MANAGER + "/" + NConstants.ROUTES.DEVICES + "/" + PATH_ADD_SYNC_VIA_ZEBRA_DATA_SERVICE} component={() => <AddViaZebraDataService pathParam={PATH_ADD_SYNC_VIA_ZEBRA_DATA_SERVICE}/>} />
                    <Route path={"/"+NConstants.ROUTES.DEVICE_MANAGER + "/" + NConstants.ROUTES.DEVICES} component={LandingDeviceManager} />

                    <Route path={"/"+NConstants.ROUTES.DEVICE_MANAGER + "/" + NConstants.ROUTES.TEMPLATES + "/" + PATH_EDIT_TEMPLATE + "/:pathParam"} component={FormTemplate} />
                    <Route path={"/"+NConstants.ROUTES.DEVICE_MANAGER + "/" + NConstants.ROUTES.TEMPLATES + "/" + PATH_ADD_TEMPLATE} component={FormTemplate} />
                    <Route path={"/"+NConstants.ROUTES.DEVICE_MANAGER + "/" + NConstants.ROUTES.TEMPLATES} component={LandingTemplateManager} />
                    <Route path={"/device-maps"} component={DragDevices} />

                    <Route exact path="/">
                        <Redirect to="/login" />
                    </Route>
                    <Route exact path={"/"+NConstants.ROUTES.DEVICE_MANAGER}>
                        <Redirect to={"/"+NConstants.ROUTES.DEVICE_MANAGER + "/" + NConstants.ROUTES.DEVICES} />
                    </Route>
                </Switch>
            </MainPage>
        );
    }
}

const Routes = (
    <Router>
        <Switch>
            <Route exact path="/login" component={LoginPage}/>
            <Route path="/" component={WrappedMainPage} />
        </Switch>
    </Router>
);
export {Routes};
