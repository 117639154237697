import React, {useRef} from "react";
import MapXYZ from "./MapXYZ";
import {useDispatch, useSelector} from "react-redux";
import {changeRefMap} from "../../actions";

const LocalMapContainer = () => {

    const dispatch = useDispatch();
    const mapRefXYZ = useRef();
    const mapId = useSelector(state => state.reportMap.mapId);
    const bounds = useSelector(state => state.reportMap.bounds);
    const maxZoom = useSelector(state => state.reportMap.maxZoom);
    const validConfiguration = useSelector(state => state.reportMap.validConfiguration);
    const updateMapSize = useSelector(state => state.reportMap.updateMapSize);

    function handleFinishLoadMapXYZ() {
        dispatch(changeRefMap(mapRefXYZ.current));
    }

    // Render self hosted (previous tiled) local map.
    return <MapXYZ ref={mapRefXYZ}
                   mapDeviceId={mapId}
                   bounds={bounds}
                   maxZoom={maxZoom}
                   onFinishLoadMap={handleFinishLoadMapXYZ}
                   showMouseCoordinates={validConfiguration}
                   coordinateMessage={(validConfiguration)?'':'Map coordinate system is not configured.'}
                   updateMapSize={updateMapSize}
                   timestamp={new Date().getTime()}
    />;
}

export default React.memo(LocalMapContainer, ((prevProps, nextProps) => {
    return (prevProps.coordinates === nextProps.coordinates);
}));

// export default LocalMapContainer;