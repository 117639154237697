import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

import StyledDialog from '../../../util/Dialog';
import {DialogActions, DialogTitle} from '@material-ui/core';
import {DialogContent} from '@material-ui/core/es';

import {DataEntryTabularField, DataEntryTabularForm} from '../../../util/DataEntryTabularForm';


/*
██████  ██  █████  ██       ██████   ██████
██   ██ ██ ██   ██ ██      ██    ██ ██
██   ██ ██ ███████ ██      ██    ██ ██   ███
██   ██ ██ ██   ██ ██      ██    ██ ██    ██
██████  ██ ██   ██ ███████  ██████   ██████
*/


class EditMapDialog extends React.Component {

    constructor(){
        super();

        this.handleSave = this.handleSave.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleTapUploadButton = this.handleTapUploadButton.bind(this);

        this.state = {
            updating:false
        };

    }

    static get propTypes(){
        return {
            open: PropTypes.bool,
            onClose: PropTypes.func,
            onError: PropTypes.func,
            onSave: PropTypes.func,
            map: PropTypes.any
        };
    }

    static get defaultProps(){
        return {
            open: false,
            onClose:function(){},
            onSave:function(){},
            onError:function(){}
        };
    }

    handleClose ()  {
        this.props.onClose();
    }

    handleSave(){
        const name = this.refs.name.value;
        const file = this.state.file;
        const zoom = this.refs.zoom.value || 4;

        if(!name){
            this.props.onError({
                message:"Name may not be empty."
            });
            return;
        }

        this.props.onSave(name,file,zoom);
    }


    handleFileSelected(e){
        const files = e.currentTarget.files;

        this.setState({
            updating:false,
            file: files[0]
        });
    }


    handleTapUploadButton(){
        this.refs.file.click();
    }

    render() {

        const {
            actions, // eslint-disable-line
            map,
            ...other
        } = this.props;

        const initialName = map && map.description;
        const initialZoom = map && map.maxZoom;

        const mainButtons = (
            <div>
                <Button key={"save-button"} label={"Save"} style={{color:'#00779f'}} onClick={this.handleSave}>Save</Button>
                <Button key={"cancel-button"} label="Cancel" style={{color:'#777',float:'left'}} onClick={this.handleClose}>Cancel</Button>
            </div>
        );




        const fileInput = (this.state.file) ? (
            <div>
                <span onClick={this.handleTapUploadButton} style={{fontWeight:'bold',color:'#00779f',fontSize:'0.9em',cursor:'pointer'}}>
                    {this.state.file.name}
                </span>
            </div>
        ) : (
            <Button
                label={"Select Site Map"}
                variant="contained"
                className="raised-button"
                style={{textTransform:'none'}}
                onClick={this.handleTapUploadButton.bind(this)}
            >Select Site Map</Button>
        );



        return (
            <StyledDialog
                open={this.props.open}
                onRequestClose={this.handleClose}
                onClose={this.handleClose}
                bodyStyle={{
                    overflowY:'visible'
                }}
                className={"site-edit-dialog"}
                {...other}
                >
                <DialogTitle>Edit Site Map</DialogTitle>
                <DialogContent style={{display:'inline-block',textAlign:'left',minWidth:'360px'}}>
                    <DataEntryTabularForm fullWidth>
                        <DataEntryTabularField label="Name">
                            <input type="text" ref="name" defaultValue={initialName}/>
                        </DataEntryTabularField>
                        <DataEntryTabularField label="File">
                            <input
                                ref={"file"}
                                type="file"
                                className="inputfile"
                                onChange={this.handleFileSelected.bind(this)}
                                accept={".wmf"}
                            />
                            {fileInput}
                        </DataEntryTabularField>
                        <DataEntryTabularField label="Max Zoom Level">
                            <select ref={"zoom"} defaultValue={initialZoom}>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                                <option>6</option>
                                <option>7</option>
                                <option>8</option>
                            </select>
                        </DataEntryTabularField>
                    </DataEntryTabularForm>
                </DialogContent>
                <DialogActions>
                    {mainButtons}
                </DialogActions>
            </StyledDialog>
        );
    }
}

export default EditMapDialog;
