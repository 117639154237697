import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Dialog, DialogTitle, DialogContent, DialogActions, IconButton} from '@material-ui/core';
import {Close as CloseIcon} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import {EDIT_DEVICE_CONFIG, EDIT_DEVICE_MANUALLY_ADDED, PUBLISH, START, STOP, REBOOT, ENABLE, DISABLE} from '../../../../constants/DeviceManager';
import {CONF_R2C} from '../../../../constants/DeviceManager';
import {
    initValidatedFields,
    deviceActionForm,
    updateOnlyDevice,
    updateDeviceAndTemplate,
    cleanFormTemplate,
    saveDeviceBatch,
    cleanDialogNotification,
    addTemplateSaveDevice,
    // saveNewDevice,
    // updateTemplate,
    startPollingOperationStatus,
    detectSaveChanges,
    verifyUpdateTemplate,
    validateFieldsDevice, disableNotification, cleanMessagesDevicesBatch
} from '../../../../actions';
// import {validateFields} from '../../../../actions';

// blocks of form
import TemplateSidebar from '../../templates/TemplateSidebar';
import Accordion from '../Accordion';
import EditDeviceHeader from '../accordionItems/EditDeviceHeader';
import ConfigurationTemplate from '../accordionItems/ConfigurationTemplate';
import IdentityPanel from '../accordionItems/IdentityPanel';
import OperationPanel from '../accordionItems/OperationPanel';
import AntennasPanel from '../accordionItems/AntennasPanel';
import TriggersPanel from '../accordionItems/TriggersPanel';
import GPOPanel from '../accordionItems/GPOPanel';
// import ErrorValidation from '../accordionItems/ErrorValidation';
import OperationNotification from '../accordionItems/OperationNotification';
// dialogs
import DialogSaveChanges from './DialogSaveChanges';
import DialogAddTemplate from './DialogAddTemplate';
import DialogSaveDevices from './DialogSaveDevices';
import TestingProcessDialog from './TestingProcessDialog';
import StopDeviceDialog from './StopDeviceDialog';
import DialogConfirm from './DialogConfirm';

import {ContainerButtonsDialog} from '../../templates/EditDevice';
import ProtocolPanel from "../accordionItems/ProtocolPanel";

const useStyles = makeStyles({
    paperDialog: {
        overflowY: "visible"
    }
});

const DialogEditDevice = ({indexDevice, open, closeDialog}) => {
    const title = useSelector(state => (state.deviceManager.templateForm.template) ? state.deviceManager.templateForm.template.title: null);
    const templateFormLoaded = useSelector(state => state.deviceManager.templateFormLoaded); //only CONFIG or SAVED
    const rfidConfigurationType = useSelector(state => state.deviceManager.rfidConfigurationType);
    const successful = useSelector(state => state.deviceManager.successfulValidatedFields);
    const dialogNotification = useSelector(state => state.deviceManager.dialogNotification);
    const isDeviceChanging = useSelector(state => state.deviceManager.isDeviceChanging);
    const focusedSection = useSelector(state => state.deviceManager.focusedSection);
    const deviceId = useSelector(state => (state.deviceManager.templateForm.template) ? state.deviceManager.templateForm.template._id: null);
    const classes = useStyles();
    const dispatch = useDispatch();
    const [operation, setOperation] = useState(null);
    const [save, setSave] = useState(null);
    const [showMessage, setShowMessage] = useState(false);

    useEffect(()=>{
        if(dialogNotification){
            scrollView('operationNotification');
        }
    },[dialogNotification]);

    useEffect(() => {
        if(focusedSection){
            scrollView(focusedSection);
        }
    }, [focusedSection]);

    function renderCloseButton(){
        return (
            <div style={{position:'absolute',top:'-0.5em',right:'-0.5em'}}>
                <IconButton aria-label="close"
                            size="small"
                            onClick={handleBackButton}
                            style={{cursor:'pointer',position:'relative',zIndex:'100',backgroundColor:'#000'}}
                >
                    <CloseIcon style={{color: '#fff'}} />
                </IconButton>
            </div>
        );
    }

    const scrollView = id => {
        var elmntToView = document.getElementById(id);
        if (elmntToView)
            elmntToView.scrollIntoView({behavior: 'smooth'});
    }

    const loadDeviceDialog = () => {
        //mounting
        if(!isNaN(deviceId)) {
            dispatch(startPollingOperationStatus(deviceId));
        }
        //unmounting
        const unmount = () => {
            dispatch(startPollingOperationStatus());
            dispatch(cleanDialogNotification());
            dispatch(initValidatedFields());
            dispatch(cleanFormTemplate());
            dispatch(cleanMessagesDevicesBatch());
        }
        return unmount;
    }
    useEffect(loadDeviceDialog, []);

    // operate device
    const operateDevice = () => {
        switch(operation){
            case 'publish-device':
                dispatch(deviceActionForm(PUBLISH));
            break;
            case 'start-device':
                dispatch(deviceActionForm(START));
            break;
            case 'enable-device':
                dispatch(deviceActionForm(ENABLE));
            break;
            default:
            break;
        }

        // scrollView('editDeviceHeader');
    }

    useEffect(operateDevice, [operation, dispatch]);

    // save device
    const saveDevice = () => {
        if(successful && save){
            switch(save){
                case 'save-configuration':
                    handleUpdateDeviceAndTemplate();
                break;
                default:
                break;
            }
        }

        if(!successful && save){
            // scrollView('errorValidation');
            setSave(null);
        }
    }

    useEffect(saveDevice, [successful, save]);

    const handleReturnBack = () => {
        closeDialog();
        dispatch(detectSaveChanges(false));
    }

    const handleBackButton = () => {
        if(isDeviceChanging){
            setShowMessage(true);
        }else{
            handleReturnBack();
        }
    }

    // validating the form, in case to save device or template

    const handleSave = (type) => {
        dispatch(verifyUpdateTemplate());
        dispatch(validateFieldsDevice());
        setSave(type);
        // setWasChecked(true);
    }

    const handleUpdateOnlyDevice = () => {
        //closing save dialog
        setSave(null);
        //closing edit dialog
        closeDialog();
        //save the changes in backend
        dispatch(updateOnlyDevice());
        //save configuration in device to render in map
        dispatch(saveDeviceBatch(indexDevice));
        //init detect changes in device
        dispatch(detectSaveChanges(false));
    }

    const handleUpdateDeviceAndTemplate = () => {
        //closing save dialog
        setSave(null);
        //closing edit dialog
        closeDialog();
        //save the changes in backend
        dispatch(updateDeviceAndTemplate());
        //save configuration in device to render in map
        dispatch(saveDeviceBatch(indexDevice));
        //init detect changes in device
        dispatch(detectSaveChanges(false));
    }

    const handleAddNewTemplate = () => {
        dispatch(disableNotification());
        dispatch(addTemplateSaveDevice(indexDevice));
        setSave(null);
        // dispatch(addTemplate());
    }
    const styleTitleDialog = {paddingBottom:0, fontSize:'1.2em', fontWeight: 'bold', whiteSpace:'nowrap', overflow:'hidden', textOverflow: 'ellipsis'};

    return (
        <React.Fragment>
            <Dialog fullWidth={true} maxWidth={'lg'} open={open} onClose={closeDialog} disableBackdropClick={true} classes={{paper:classes.paperDialog}}>
                <DialogTitle style={styleTitleDialog} disableTypography>{title}</DialogTitle>
                {renderCloseButton()}
                <DialogContent className="ddmodal-container">
                    <div className="ddSideBar">
                        <TemplateSidebar scrollView={(e) => scrollView(e)} deviceId={'EditDevice'}/>
                    </div>
                    <div className="ddmodal-container-right">
                        {/*{(!successful && wasChecked) && <ErrorValidation type={'device'} />}*/}
                        {dialogNotification && <OperationNotification duration={6000} />}
                        {templateFormLoaded === EDIT_DEVICE_CONFIG || templateFormLoaded === EDIT_DEVICE_MANUALLY_ADDED ? <ConfigurationTemplate />:<EditDeviceHeader />}
                        <Accordion displayName={"Identity"} id={"identity"}>
                            <IdentityPanel />
                        </Accordion>
                        <Accordion displayName={"Reader Management Protocol"} id={"protocol"}>
                            <ProtocolPanel />
                        </Accordion>

                        <Accordion displayName={"Operation"} id={"operation"}> 
                            <OperationPanel />
                        </Accordion>

                        <Accordion displayName={"Antennas"} id={"rfidAntennas"}> 
                            <AntennasPanel isTemplate={false}/>
                        </Accordion>

                        {rfidConfigurationType !== CONF_R2C && <React.Fragment>
                            <Accordion displayName={"Trigger settings"} id={"triggers"}> 
                                <TriggersPanel />
                            </Accordion>

                            <Accordion displayName={"GPO (General Purpose Output) Programming"} id={"gpo"}> 
                                <GPOPanel />
                            </Accordion>
                        </React.Fragment>}
                    </div>
                </DialogContent>
                <DialogActions>
                    <ContainerButtonsDialog templateFormLoaded={templateFormLoaded} setOperation={setOperation} handleSave={handleSave} handleReturnBack={closeDialog} />
                </DialogActions>
            </Dialog>
            {(successful && save==='save-device') &&
                <DialogSaveDevices 
                    openDialog={Boolean(save)}
                    actionLabel={'save'}
                    closeDialog={() => setSave(null)}
                    updateOnlyDevice={handleUpdateOnlyDevice}
                    updateDeviceAndTemplate={handleUpdateDeviceAndTemplate} />}

            {(successful && save==='save-new-device') &&
                <DialogSaveDevices 
                    openDialog={Boolean(save)}
                    actionLabel={'save'}
                    closeDialog={() => setSave(null)}
                    updateOnlyDevice={handleUpdateOnlyDevice}
                    updateDeviceAndTemplate={handleUpdateDeviceAndTemplate} />}

            {(successful && save==='new-template') &&
                <DialogAddTemplate
                    openDialog={Boolean(save)}
                    closeDialog={() => setSave(null)}
                    actionSuccess={handleAddNewTemplate} />}

            {(successful && save==='edit-template') &&
                <DialogSaveChanges
                    openDialog={Boolean(save)}
                    closeDialog={() => setSave(null)}
                    actionSuccess={handleUpdateDeviceAndTemplate} />}
                    
            {operation==='test-device' &&
                <TestingProcessDialog
                        open={Boolean(operation)} 
                        closeDialog={() => setOperation(null)}/>}

            {operation==='stop-device' &&
                <StopDeviceDialog
                        open={Boolean(operation)}
                        type={'stop'}
                        closeDialog={() => setOperation(null)}
                        actionSuccess={() => dispatch(deviceActionForm(STOP))} />}

            {operation==='reboot-device' &&
                <StopDeviceDialog
                        open={Boolean(operation)}
                        type={'reboot'}
                        closeDialog={() => setOperation(null)}
                        actionSuccess={() => dispatch(deviceActionForm(REBOOT))} />}

            {operation==='disable-device' &&
                <StopDeviceDialog
                        open={Boolean(operation)}
                        type={'disable'}
                        closeDialog={() => setOperation(null)}
                        actionSuccess={() => dispatch(deviceActionForm(DISABLE))} />}

            {showMessage && <DialogConfirm 
                open={showMessage}
                closeDialog={() => setShowMessage(false)}
                actionSuccess={handleReturnBack}
                actionCancel={()=>{}}
                message={'There are changes not saved. Are you sure to leave this page?'}
            />}
        </React.Fragment>
    )
}

export default DialogEditDevice;