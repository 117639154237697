export const FULL_MENU = [
    {
        "id": "dashboard_btn",
        "name": "Dashboard",
        "routes":"dashboard",
        "placement":"left",
        "apiCategory":"Dashboard"
    },
    {
        "name": "Reports",
        "routes": "reports",
        "placement":"left",
        "apiCategory":"Reports"
    },
    {
        "name": "History",
        "routes":"history",
        "placement":"left",
        "apiCategory":"History"
    },
    {
        "name": "Alerts",
        "routes":"alerts",
        "placement":"left",
        "apiCategory":"Alerts"
    },
    {
        "name": "Saved",
        "routes": "saved",
        "placement":"left",
        "apiCategory":"Saved"
    },
    {
        "name":"Infrastructure",
        "placement":"right",
        "routes":"infra",
        "disabled":true,
        "apiCategory":"Infrastructure",
    },
    {
        "name": "Configuration",
        "routes":"config",
        "placement":"right",
        "apiCategory":"Configuration"
    },
    {
        "name": "Users",
        "routes":"users",
        "placement":"right",
        "apiCategory":"Users"
    },
    {
        "name": "Help",
        "routes":"help",
        "placement":"right"
    }
];


export const CUSTOM_PAGES ={
    "Infrastructure":{
        "Site Manager":{
            routes:"/sites"
        },
        "Appliances":{
            routes:"/appliances"
        }
    }
};
