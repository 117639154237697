import React from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import {Breadcrumbs} from '../../home/toolbars/ReportsToolbar';
//import IconButton from 'material-ui/IconButton';

/*
import SaveIcon from 'material-ui/svg-icons/content/save';
import EmailIcon from 'material-ui/svg-icons/communication/email';
import ClockIcon from 'material-ui/svg-icons/action/query-builder';
import PrintIcon from 'material-ui/svg-icons/action/print';

import WidgetConfigMenu from './WidgetConfigMenu';

const ClassedIconButton = (props) =>{
    return (
        <IconButton className="icon-button" {...props} />
    );
};*/


class SaveAndConfigToolbar extends React.Component{

    constructor(props){
        super(props);

        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.handleEmailClick = this.handleEmailClick.bind(this);
        this.handleScheduledEmailClick = this.handleScheduledEmailClick.bind(this);
        this.handlePrintClick = this.handlePrintClick.bind(this);
    }

    static get propTypes(){
        return {
            actions:PropTypes.object,
            columns:PropTypes.array,
            name: PropTypes.string
        };
    }

    shouldComponentUpdate(np,ns){
        return(np !== this.props) || (ns !== this.state);
    }

    handleSaveClick(){ this.props.actions.displayMessage("Save Button clicked."); }
    handleEmailClick(){ this.props.actions.displayMessage("Email Button clicked.");}
    handleScheduledEmailClick(){ this.props.actions.displayMessage("Scheduled Email Button clicked."); }
    handlePrintClick(){ this.props.actions.displayMessage("Print Button clicked."); }


     render(){
         const {name} = this.props;

         return (
             <Toolbar className="save-share-toolbar">
                 <div className={"toolbargroup"}>
                     <Breadcrumbs category="Dashboards" name={name} />
                 </div>
             </Toolbar>
         );

         /*

         <ToolbarGroup>
             <ClassedIconButton onClick={this.handleSaveClick}><SaveIcon /></ClassedIconButton>
             <ClassedIconButton onClick={this.handleEmailClick}><EmailIcon /></ClassedIconButton>
             <ClassedIconButton onClick={this.handleScheduledEmailClick}><ClockIcon /></ClassedIconButton>
             <ClassedIconButton onClick={this.handlePrintClick}><PrintIcon /></ClassedIconButton>
             <WidgetConfigMenu actions={actions} />
         </ToolbarGroup>

          */
     }
 }

 export default SaveAndConfigToolbar;
