import React from 'react';
import PropTypes from 'prop-types';
import Promise from 'promise';

import SiteDialog from './BasicSitesDialog';
import AddSiteForm from './AddSiteForm';
import SiteGroupDialog from './SiteGroupDialog';
import DeleteConfirmDialog from './DeleteConfirmDialog';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../../actions';

class UpdateSiteForm extends React.Component{
    render(){
        return(
            <AddSiteForm
                {...this.props}
                title={"Update Site"}
                update={true}
            />
        );
    }
}



const SITE_FORMS = {
    ADD_SITE: 0,
    ADD_GROUP: 1,
    UPDATE_SITE:2,
    DELETE_SITE:3,
    UPDATE_GROUP:4,
    DELETE_GROUP:5
};

export {SITE_FORMS};



class SiteFormSwitch extends React.Component{
    constructor(){
        super();
        this.handleClose = this.handleClose.bind(this);
    }

    static get propTypes(){
        return {
            form: PropTypes.number,
            initialFormData: PropTypes.object,
            open: PropTypes.bool,
            actions: PropTypes.object.isRequired,
            maps: PropTypes.array // ... I need a better design.
        };
    }

    shouldComponentUpdate(nextProps){
        return (nextProps.open || this.props.open);
    }

    renderForm(){
        const {initialFormData, actions, ...other} = this.props;

        switch(this.props.form){
            case SITE_FORMS.ADD_SITE:
                return (
                    <AddSiteForm
                        {...other}
                        onSave={({name,location,lat,long,siteGroupId})=>{
                            actions.addSite({name,location,lat,long,siteGroupId})
                            .then(actions.closeSiteForm);
                        }}
                        maps={undefined}
                        actions={actions}
                    />
                );
            case SITE_FORMS.ADD_GROUP:
                return (
                    <SiteGroupDialog
                        {...other}
                        onSave={({name})=>{
                            actions.addSiteGroup(name)
                            .then(actions.closeSiteForm);
                        }}
                    />
                );
            case SITE_FORMS.UPDATE_SITE:
                return (
                    <UpdateSiteForm
                        {...other}
                        name={initialFormData.name}
                        latLong={(initialFormData.lat && initialFormData.long) && [initialFormData.lat,initialFormData.long]}
                        location={initialFormData.location}
                        siteGroupId={initialFormData.groupId}
                        onSave={({name,location,lat,long,siteGroupId})=>{

                            let pr = Promise.resolve();

                            if(name !== initialFormData.name || location !== initialFormData.location
                                    || lat !== initialFormData.lat || long !== initialFormData.long){
                                pr = actions.updateSite({
                                    id: initialFormData.id,
                                    name,
                                    location,
                                    lat,
                                    long
                                });
                            }

                            if(siteGroupId !== initialFormData.groupMembership[0]){
                                pr = pr.then(()=>{
                                    actions.moveSiteToSiteGroup(initialFormData.id,siteGroupId);
                                });
                            }


                            pr.then(actions.closeSiteForm);
                        }}
                        id={initialFormData.id}
                        maps={this.props.maps.filter(m=>m.siteId === initialFormData.id)}
                        actions={actions}
                    />
                );
            case SITE_FORMS.DELETE_SITE:
                return (
                    <DeleteConfirmDialog
                        {...other}
                        name={initialFormData.name}
                        title={"Confirm Delete Site"}
                        pretext={"Delete Site"}
                        posttext={"?"}
                        onSave={()=>{
                            actions.deleteSite(initialFormData.id);
                            actions.closeSiteForm();
                        }}
                    />
                );

            case SITE_FORMS.UPDATE_GROUP:
                return (
                    <SiteGroupDialog
                        {...other}
                        title={"Rename Site Group"}
                        name={initialFormData.name}
                        onSave={({name})=>{
                            if(initialFormData.name !== name) actions.updateSiteGroup(initialFormData.id,name);
                            actions.closeSiteForm();
                        }}
                    />
                );

            case SITE_FORMS.DELETE_GROUP:
                return (
                        <DeleteConfirmDialog
                            {...other}
                            name={initialFormData.name}
                            title={"Confirm Delete Site Group"}
                            pretext={"Delete Site Group"}
                            posttext={"?"}
                            onSave={()=>{
                                actions.deleteSiteGroup(initialFormData.id);
                                actions.closeSiteForm();
                            }}
                        />
                );

            default:
                return <SiteDialog {...this.props} />;
        }

    }

    handleClose(){
        this.props.actions.closeSiteForm();
    }

    render(){

        // get the form.
        const form = this.renderForm();

        // make sure that it has a function to handle closing the form.
        return React.cloneElement(form,{
            onRequestClose:this.handleClose
        });
    }
}


const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions,dispatch)
});

const mapStateToProps = state => ({
    siteGroups: state.sites.siteGroups,
    maps: state.sites.maps,
    open: state.sites.activeForm !== -1,
    form: state.sites.activeForm,
    initialFormData: state.sites.initialFormData
});

export default connect(mapStateToProps,mapDispatchToProps)(SiteFormSwitch);
