import React from 'react';
import PropTypes from 'prop-types';

import StyledDialog from '../../util/Dialog';
import Button from '@material-ui/core/Button';

class DeleteConfirmDialog extends React.Component{

    static get propTypes(){
        return {
            open: PropTypes.bool,
            onSave: PropTypes.func,
            name: PropTypes.string,
            pretext: PropTypes.string,
            posttext: PropTypes.string,
            onRequestClose: PropTypes.func
        };
    }

    static get defaultProps(){
        const nop = function(){};
        return {
            open: false,
            pretext:"Delete",
            posttext:"?",
            onRequestClose: nop
        };
    }

    render() {

        const {
            onSave,
            name,
            pretext,
            posttext,
            onRequestClose
        } = this.props;

        const mainButtons = [
            <div className="dialog-buttons" key={"main-buttons-delete-confirm-dialog"}>
                <Button onClick={onRequestClose} style={{position: 'absolute', left: 8, backgroundColor: 'white'}}>Cancel</Button>
                <Button style={{color: 'white', backgroundColor: '#e8483a'}} onClick={onSave || onRequestClose}>Delete</Button>
            </div>
        ];

        return (
            <StyledDialog
                title="Confirm Delete Site"
                modal={false}
                onRequestClose={onRequestClose}
                onClose={onRequestClose}
                {...this.props}
                actions={mainButtons}
                maxWidth={false}
            >
                <div style={{width: 360}}>
                    {pretext+" "}
                        <span style={{fontStyle:'italic',fontWeight:'bold'}}>{name}</span>
                    {posttext+" "}
                </div>
            </StyledDialog>
        );
    }
}

export default DeleteConfirmDialog;
