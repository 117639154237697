import React from 'react';
import PropTypes from 'prop-types';
//import AddIcon from 'material-ui/svg-icons/content/add';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';

import WrappedTable from '../../util/report/WrappedTable';

import AddUserDialog from './forms/AddUserDialog';
import DeleteUserDialog from './forms/DeleteUserDialog';
import UpdateUserDialog from './forms/UpdateUserDialog';
import ResetPasswordDialog from './forms/ResetPasswordDialog';

import ProppedButton from '../../util/button/ProppedButton';


import SelectWrapper from './SelectWrapper';

import {
    USERMGMT_ADD_USER,
    USERMGMT_DELETE_USER,
    USERMGMT_RESET_PASSWORD,
    USERMGMT_UPDATE_USER,
} from '../../../constants/DataEntryForms';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../../../actions';
import {generateComponentId} from "../../../utils/ComponentUtils";

class UsersView extends React.Component{

    constructor(){
        super();
        this.state = {};

        this.renderBodyCellContent = this.renderBodyCellContent.bind(this);
        this.getRowHeight = this.getRowHeight.bind(this);
    }

    static get propTypes(){
        return {
            actions: PropTypes.object.isRequired,
            data: PropTypes.array,
            columns: PropTypes.array,
            dataEntryOpen: PropTypes.bool,
            users: PropTypes.array,
            userColumns: PropTypes.array,
            canEdit: PropTypes.bool
        };
    }

    componentDidMount(){
        this.refreshUsersAndGroups();
    }

    refreshUsersAndGroups(){
        this.props.actions.requestUsers();
        this.props.actions.requestUserGroups(); // will need...
    }

    renderBodyCellContent({columnIndex,datum}){
        const {userColumns} = this.props;
        if(columnIndex<0) return "";
        const col = userColumns[columnIndex];
        const d = datum[col.name];

        switch(col.name){
            case "groups":
                return d.map(v=>v.name).join(", ");
            default:
                return d;
        }
    }

    getRowHeight(){
        return 40;
    }

    handleOpenForm(form,needsSelected){
        var selected = this.refs.table.getSelected();

        if(needsSelected){
            if(selected.length === 0) {
                this.props.actions.displayErrorDialog({
                    message:"Please select a user first."
                });
                return;
            } else if(selected.length > 1) {
                this.props.actions.displayErrorDialog({
                    message:"Please select a single user."
                });
                return;
            }
        }

        this.props.actions.openDataEntry(form,{
            user:selected[0]
        });
    }

    render(){
        const {actions,users,userColumns,canEdit,isMobile} = this.props;
        const refreshLabel = "Refresh";
        const addLabel = "Add User";
        const editLabel = "Edit User";
        const resetPasswordLabel = "Reset password";
        const deleteLabel = "Delete User";

        let refreshButton = (
            <ProppedButton
                id={generateComponentId(refreshLabel, "usr", "button")}
                label={!isMobile ? refreshLabel : null}
                icon={<RefreshIcon />}
                onClick={this.refreshUsersAndGroups.bind(this)}
                className={"button-crud-style material-button"}
            />
        );

        let editActions = canEdit ? (
            <div className={"user-groups-button-container"}>
                {refreshButton}
                <ProppedButton
                    id={generateComponentId(addLabel, "usr", "button")}
                    label={!isMobile ? addLabel : null}
                    icon={<AddIcon />}
                    onClick={this.handleOpenForm.bind(this,USERMGMT_ADD_USER,false)}
                    className={"button-crud-style material-button"}
                />
                <ProppedButton
                    id={generateComponentId(editLabel, "usr", "button")}
                    label={!isMobile ? editLabel : null}
                    icon={<EditIcon />}
                    onClick={this.handleOpenForm.bind(this,USERMGMT_UPDATE_USER,true)}
                    className={"button-crud-style material-button"}
                />
                <ProppedButton
                    id={generateComponentId(resetPasswordLabel, "usr", "button")}
                    label={!isMobile ? resetPasswordLabel : null}
                    icon={<LockIcon />}
                    onClick={this.handleOpenForm.bind(this,USERMGMT_RESET_PASSWORD,true)}
                    className={"button-crud-style material-button"}
                />
                <ProppedButton
                    id={generateComponentId(deleteLabel, "usr", "button")}
                    label={!isMobile ? deleteLabel : null}
                    icon={<DeleteIcon />}
                    onClick={this.handleOpenForm.bind(this,USERMGMT_DELETE_USER,true)}
                    className={"button-crud-style material-button"}
                />
            </div>
        ) : (
            <div className={"user-groups-button-container"}>
                {refreshButton}
            </div>
        );

        return (
            <div className="filled-up users-report">
                <AddUserDialog />
                <UpdateUserDialog />
                <ResetPasswordDialog />
                <DeleteUserDialog />

                <div className="user-data-entry">
                    {editActions}
                </div>
                <div className="filled-up" style={{overflow:'hidden',position:'relative',boxSizing:'border-box'}}>
                    <SelectWrapper uidAttribute={"username"} data={users} ref="table">
                        <WrappedTable
                            actions={actions}
                            data={users}
                            columns={userColumns}
                            bodyCellContentRenderer={this.renderBodyCellContent}
                            getRowHeight={this.getRowHeight}
                            isSelected={null}
                            maxInitialColumnSize={500}
                            initiaColumnSizeOffset={60}
                            adjustColumns
                            onSelectAll={null}
                            disableFillTable
                            disableFilters
                            disableSort
                        />
                    </SelectWrapper>
                </div>
            </div>
        );
    }
}

/*
 ██████  ██████  ███    ██ ███    ██ ███████  ██████ ████████     ████████  ██████
██      ██    ██ ████   ██ ████   ██ ██      ██         ██           ██    ██    ██
██      ██    ██ ██ ██  ██ ██ ██  ██ █████   ██         ██           ██    ██    ██
██      ██    ██ ██  ██ ██ ██  ██ ██ ██      ██         ██           ██    ██    ██
 ██████  ██████  ██   ████ ██   ████ ███████  ██████    ██           ██     ██████

███████ ████████  █████  ████████ ███████
██         ██    ██   ██    ██    ██
███████    ██    ███████    ██    █████
     ██    ██    ██   ██    ██    ██
███████    ██    ██   ██    ██    ███████
*/

const mapStateToProps = state => ({
    users: state.userMgmt.users,
    userColumns: state.userMgmt.userColumns,
    canEdit: state.user.permissions["edit-users"]
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions,dispatch)
});

export default connect(mapStateToProps,mapDispatchToProps)(UsersView);
