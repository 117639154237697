import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Paper from "@material-ui/core/Paper";

import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';


import MapIcon from '@material-ui/icons/Layers';

import assign from 'object-assign';
import Promise from 'promise';


import IconButton from '@material-ui/core/IconButton';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import UploadMapDialog from './UploadMapDialog';
import DeleteMapDialog from './DeleteMapDialog';
import EditMapDialog from './EditMapDialog';
import WaitDialog from './WaitDialog';
import {ListItemIcon, ListItemText} from '@material-ui/core';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import WarningIcon from '@material-ui/icons/Warning';

function makePinIcon(WrappedComponent){
    return class extends React.Component{
        render(){
            const {style, ...other} = this.props; // eslint-disable-line
            let styles = null;
            if(style) {
                styles = assign(style,{
                    left:'20px',
                    top:'2px',
                    margin:'2px',
                    position:'absolute'
                });
            }

            return (
                <WrappedComponent
                    style={styles}
                    className="icon-pin"
                    {...other}
                />
            );
        }
    };
}
const DefaultMapIcon = makePinIcon(MapIcon);

/*
██       ██████   ██████  █████  ██          ███    ███  █████  ██████
██      ██    ██ ██      ██   ██ ██          ████  ████ ██   ██ ██   ██
██      ██    ██ ██      ███████ ██          ██ ████ ██ ███████ ██████
██      ██    ██ ██      ██   ██ ██          ██  ██  ██ ██   ██ ██
███████  ██████   ██████ ██   ██ ███████     ██      ██ ██   ██ ██

███    ███  █████  ███    ██  █████   ██████  ███████ ██████
████  ████ ██   ██ ████   ██ ██   ██ ██       ██      ██   ██
██ ████ ██ ███████ ██ ██  ██ ███████ ██   ███ █████   ██████
██  ██  ██ ██   ██ ██  ██ ██ ██   ██ ██    ██ ██      ██   ██
██      ██ ██   ██ ██   ████ ██   ██  ██████  ███████ ██   ██
*/



class MultiMapManager extends React.Component{
    constructor(){
        super();

        this.handleUpload = this.handleUpload.bind(this);
        this.closeDialogMap = this.closeDialogMap.bind(this);
        this.state={
            uploadDialogOpen: false,
            deleteDialogOpen: false,
            editDialogOpen: false,
            waitDialogOpen: false,
            selectedMapId: null,
            siteUploaded: null,

            dialogMapOpen:false
        };
    }

    static get propTypes(){
        return {
            siteId: PropTypes.any,
            maps: PropTypes.array,
            actions: PropTypes.shape({
                uploadSiteMap: PropTypes.func.isRequired,
                displayErrorDialog: PropTypes.func.isRequired,
                requestMaps: PropTypes.func.isRequired,
                deleteSiteMap: PropTypes.func.isRequired,
                updateSiteMapInfo: PropTypes.func.isRequired,
                replaceSiteMap: PropTypes.func.isRequired
            }).isRequired
        };
    }

    handleEditButtonClick(mapId){
        this.setState({
            editDialogOpen: true,
            selectedMapId: mapId
        });
    }

    handleDeleteButtonClick(mapId){
        this.setState({
            deleteDialogOpen: true,
            selectedMapId: mapId
        });
    }

    renderItem(m){

        const onEdit = this.handleEditButtonClick.bind(this,m._id);
        const onDelete = this.handleDeleteButtonClick.bind(this,m._id);
        //const ICON_COLOR = "#777";
        const buttonStyles = {height:'32px',width:'32px',padding:'4px',backgroundColor:'transparent'};
        const { upload_status } = this.props;
        const rightIcons = (
            <div style={{position:'absolute',top:0,right:'4px'}}>
                <IconButton disabled={upload_status.progress} onClick={onEdit} style={buttonStyles}
                            tooltip={(this.state.siteUploaded === m.description)?upload_status.message:""} >
                    <EditIcon  />
                </IconButton>
                <IconButton disabled={upload_status.progress} onClick={onDelete} style={buttonStyles}>
                    <DeleteIcon />
                </IconButton>
            </div>
        );

        return (
            <ListItem
                key={m._id}
                // innerDivStyle={{paddingTop:'8px',paddingBottom:'8px'}}
                style={{height: 32}}
                className={"pin-item list-item"}
            >
                <ListItemIcon><DefaultMapIcon /></ListItemIcon>
                <ListItemText>{m.description}</ListItemText>
                {rightIcons}
            </ListItem>
        );
    }

    handleUpload(name,file,zoom){

        if(this.mapNameIsDuplicated(this.props.maps,name)){
            this.props.actions.displayErrorDialog({
                message:"Map name must not be duplicated."
            });
            return;
        }


        this.setState({
            waitDialogOpen: true
        });

        this.props.actions.uploadSiteMap(this.props.siteId,name,file,zoom)
        .then(()=>{
            this.setState({
                uploadDialogOpen: false,
                waitDialogOpen: false,
                siteUploaded: name
            });
            this.props.actions.requestMaps();
        })
        .catch(()=>{
            this.setState({
                waitDialogOpen: false
            });
        });

    }

    mapNameIsDuplicated(maps,name){
        return maps.some(m=>m.description===name);
    }

    handleDeleteMap(mapId){

        this.props.actions.deleteSiteMap(mapId,()=>{
            this.setState({
                deleteDialogOpen: false,
                selectedMapId: null
            });
            this.props.actions.requestMaps();
        }, () =>{
            this.setState({
                dialogMapOpen: true,
            });
        });

    }

    handleEditMap(mapId,name,file,zoom){

        if(this.mapNameIsDuplicated(this.props.maps.filter(m => m._id !== mapId ),name)){
            this.props.actions.displayErrorDialog({
                message:"Map name must not be duplicated."
            });
            return;
        }

        const closeAndRefresh = ()=>{
            this.setState({
                editDialogOpen: false,
                selectedMapId: null,
                waitDialogOpen: false,
                dialogMapOpen: false

            });
            this.props.actions.requestMaps();
        };

        let promises = [];

        if(name !== this.props.maps.find(m=>m._id===mapId).description){
            promises.push(this.props.actions.updateSiteMapInfo(mapId,name,closeAndRefresh));
        }
        if(file){
            this.setState({
                waitDialogOpen: true
            });
            promises.push( this.props.actions.replaceSiteMap(mapId,file,zoom) );
        }

        Promise.all(promises)
        .then(closeAndRefresh)
        .catch(()=>{
            this.setState({
                waitDialogOpen: false
            });
        });
    }
    closeDialogMap(){
        this.setState({
            dialogMapOpen: false,
        });
    }
    render(){
        let items = this.props.maps.map(this.renderItem.bind(this));
        const { upload_status } = this.props;
        // const uploadButtonStyles = {
        //     float:'right',
        //     fontSize:'0.9em',
        //     height:'auto',
        //     lineHeight:'32px',
        //     padding:'0.1em',
        //     color:'white',
        //     margin:'-8px'
        // };
        const uploadButton = (
            <Button disabled={upload_status.progress}
                style={{color: upload_status.progress ? 'gray' : 'white', textTransform: 'none'}}
                onClick={()=>{this.setState({uploadDialogOpen:true});}}>
                + Upload
            </Button>
        );

        // const circularBar = ( <IconButton tooltipPosition="bottom-left" tooltip={upload_status.message}> <CircularProgress style={{ marginTop:-12 }} thickness={2} size={15}  /> </IconButton>);

        items = [(
            <ListItem
                key={"button"}
                className="map-items-list-title"
                style={{height: 32}}
            >
                <ListItemText>Site Maps</ListItemText>
                <ListItemIcon>{uploadButton}</ListItemIcon>
            </ListItem>
        )].concat(items);
        const list = (
            <List style={{padding:0,marginTop:'1em'}}>
                {items}
            </List>
        );

        return (
            <div>
                <Paper elevation={2} style={{margin:'2px',color:'inherit'}}>
                    {list}
                </Paper>
                <div style={{textAlign:'center',marginTop:'0.5em'}}>
                    <span style={{fontSize:'0.9em',fontStyle:'italic',letterSpacing:'-1px'}}>Note: Site map actions are automatically saved.</span>
                </div>
                <UploadMapDialog
                    open={this.state.uploadDialogOpen}
                    onClose={()=>{this.setState({uploadDialogOpen:false});}}
                    onSave={this.handleUpload}
                    onError={this.props.actions.displayErrorDialog}
                />
                <DeleteMapDialog
                    open={this.state.deleteDialogOpen}
                    map={this.props.maps.find(m=>m._id===this.state.selectedMapId)}
                    onClose={()=>{this.setState({deleteDialogOpen:false});}}
                    onConfirm={this.handleDeleteMap.bind(this,this.state.selectedMapId)}
                    onError={this.props.actions.displayErrorDialog}
                />
                <EditMapDialog
                    open={this.state.editDialogOpen}
                    map={this.props.maps.find(m=>m._id===this.state.selectedMapId)}
                    onClose={()=>{this.setState({editDialogOpen:false});}}
                    onSave={this.handleEditMap.bind(this,this.state.selectedMapId)}
                    onError={this.props.actions.displayErrorDialog}
                />
                <WaitDialog
                    open={this.state.waitDialogOpen}
                    text={"Uploading map..."}
                />


                {this.state.dialogMapOpen&&<Dialog
                    open={this.state.dialogMapOpen}
                    fullWidth
                    onClose={this.closeDialogMap}
                >
                    <DialogContent >
                        <div style={{padding: '0.5em', textAlign: 'center', fontSize: '1.1em',lineHeight:'37px'}}><WarningIcon style={{fill:'#cc0000',fontSize:'1em',paddingRight:'5px'}}/>There are devices associated with this map.</div>
                        <div style={{padding: '0.5em', textAlign: 'left', fontSize: '1em'}}>You must remove all devices associated with this map before deleting the map.</div>
                    </DialogContent>
                    <DialogActions>
                        <Button style={{background: '#007CB0',color: '#fff', padding: '0.5em 0.8em'}} onClick={this.closeDialogMap} component="span">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>}

            </div>
        );
    }
}

export default MultiMapManager;
