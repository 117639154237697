import React, {createContext, forwardRef, memo, useEffect, useRef} from "react";
// import {FixedSizeGrid} from "react-window";
import {AutoSizer} from "react-virtualized";
import CheckboxCell from "./Checkboxcell";
import CustomVariableSizeGrid from "./CustomVariableSizeGrid";
import {
    defaultCellWidth,
    defaultCellHeight,
    checkboxWidth,
    calculateAccumulatedWidth, calculateWidthOfExtraColumn,
} from "./Util";
import Cell from "./Cell";
import CheckAll from "./CheckAll";
// import AppliedFilters from "./AppliedFilters";

const StickyGridContext = createContext();
StickyGridContext.displayName = "StickyGridContext";


const Table=(props)=>{
    // const [computedHeaderInfo,setComputedHeaderInfo] = React.useState(calculateAccumulatedWidth(props.headerRow));
    const computedHeaderInfo = calculateAccumulatedWidth(props.headerRow);
    const checkBoxWidth = (props.showCheckColumn===true)?checkboxWidth:0;

    const gridRef = useRef();

    useEffect(() => {
        // Calculate the data of width of each column.
        // setComputedHeaderInfo(calculateAccumulatedWidth(props.headerRow));
        // Reset cache of widths in react-window component.
        gridRef.current.resetAfterColumnIndex(0);

    }, [props.headerRow]);

    const columnWidthByIndex=(index)=>{
        return computedHeaderInfo.headerRow[index].width||defaultCellWidth;
    }

    return (

        <AutoSizer>
            {({height, width}) =>{
                let addedCellWidth=0;
                let allColumnWidth=(props.headerRow[Object.keys(props.headerRow).length-1].accumulatedWidth)+props.headerRow[Object.keys(props.headerRow).length-1].width+checkBoxWidth;
                if(props.fillWidth===true){
                    if(width>allColumnWidth){
                        addedCellWidth=Math.floor((width-allColumnWidth-10)/(Object.keys(props.headerRow).length));// 10 is the scrollbar
                    }
                }else{
                    calculateWidthOfExtraColumn(props.headerRow,width,allColumnWidth);
                }

                return (
                <StickyGrid
                    ref={gridRef}
                    innerElementType={innerElementType}
                    columnCount={props.headerRow.length}
                    rowCount={props.rowCount}
                    columnWidth={columnWidthByIndex}
                    rowHeight={index=>defaultCellHeight}
                    width={width}
                    height={height}
                    dataArray={props.data}
                    showCheckColumn={props.showCheckColumn}
                    headerRow={computedHeaderInfo.headerRow}
                    stickyColumn={props.stickyColumn||[]}
                    checkBoxWidth={checkBoxWidth}
                    cellRender={props.cellRender}
                    headerRender={props.headerRender}
                    selectedRows={props.selectedRows}
                    onClick={props.onClickRow}
                    onSelectAll={props.onSelectAll}
                    appliedFiltersHeight={props.appliedFiltersHeight}
                    appliedFilters={props.appliedFilters}
                    estimatedColumnWidth={computedHeaderInfo.averageWidth}
                    estimatedRowHeight={defaultCellHeight}
                    addedCellWidth={addedCellWidth}
                    rowsValidationError={props.rowsValidationError}
                >
                    {Cell}
                </StickyGrid>
            )}}
        </AutoSizer>
    )
}


const StickyGrid = React.forwardRef(({ children,
                        dataArray,
                        headerRow,
                        stickyColumn,
                        showCheckColumn,
                        checkBoxRender,
                        checkBoxWidth,
                        columnIndex,
                        cellRender,
                        headerRender,
                        selectedRows,
                        onClick,
                        onSelectAll,
                        appliedFiltersHeight,
                        appliedFilters,
                        estimatedColumnWidth,
                        estimatedRowHeight,
                        width,
                        height,
                        addedCellWidth,
                        rowsValidationError,
                        ...rest },ref) => (
    <StickyGridContext.Provider value={{ ItemRenderer: children,
                                         dataArray,
                                         headerRow,
                                         stickyColumn,
                                         showCheckColumn,
                                         checkBoxRender,
                                         checkBoxWidth,
                                         columnIndex,
                                         cellRender,
                                         headerRender,
                                         selectedRows,
                                         onClick,
                                         onSelectAll,
                                         appliedFiltersHeight,
                                         appliedFilters,
                                         estimatedColumnWidth,
                                         estimatedRowHeight,
                                         width,
                                         height,
                                         addedCellWidth,
                                         rowsValidationError,
                                         ...rest }}>
        <div className={"table-react-window-container table-modern-browser-container"} style={{width:width,height:height}}>
            <CustomVariableSizeGrid
                ref={ref}
                estimatedColumnWidth={estimatedColumnWidth}
                estimatedRowHeight={estimatedRowHeight}
                width={width}
                height={height}
                style={{overflowY:'scroll'}}
                itemData={{ ItemRenderer: children,
                                             dataArray,
                                             headerRow,
                                             stickyColumn,
                                             showCheckColumn,
                                             checkBoxRender,
                                             checkBoxWidth,
                                             columnIndex,
                                             cellRender,
                                             headerRender,
                                             selectedRows,
                                             onClick,
                                             onSelectAll,
                                             appliedFiltersHeight,
                                             addedCellWidth,
                                             rowsValidationError,
                                             height
                                            }} {...rest}
            >
                {ItemWrapper}
            </CustomVariableSizeGrid>
        </div>
    </StickyGridContext.Provider>
));


const ItemWrapper = (allProps) => {

    const { data, columnIndex,rowIndex, style}=allProps;
    const { ItemRenderer,
            headerRow,
            dataArray,
            checkBoxWidth,
            cellRender,
            onClick,
            selectedRows,
            onSelectAll,
            appliedFiltersHeight,
            addedCellWidth,
            rowsValidationError, 
            height
    } = data;

    const modifiedStyle={...style};
    modifiedStyle.top=modifiedStyle.top+defaultCellHeight+appliedFiltersHeight;
    modifiedStyle.left=(headerRow[columnIndex].accumulatedWidth)+checkBoxWidth+(addedCellWidth*columnIndex);
    modifiedStyle.width=headerRow[columnIndex].width+addedCellWidth;

    return <ItemRenderer
                         columnIndex={columnIndex}
                         rowIndex={rowIndex}
                         style={modifiedStyle}
                         headerRow={headerRow}
                         dataArray={dataArray}
                         cellRender={cellRender}
                         selectedRows={selectedRows}
                         onClick={onClick}
                         onSelectAll={onSelectAll}
                         rowDisabled={rowsValidationError && rowsValidationError[rowIndex] && rowsValidationError[rowIndex].disabled}
                         tableHeight={height}
    />;
};




const innerElementType = forwardRef((props, ref) => {
    const { children,rowStartIndex,rowStopIndex,columnStartIndex,columnStopIndex, ...rest }=props;
    const containerEmpty = <div className="table-sticky-column-container" style={{paddingTop:defaultCellHeight+'px', width:'80px'}}>No results</div>;
    return (
        <StickyGridContext.Consumer>
            {({ headerRow,
                  stickyColumn,
                  showCheckColumn,
                  width,
                  height,
                  columnIndex,
                  rowIndex,
                  ItemRenderer,
                  dataArray,
                  checkBoxWidth,
                  headerRender,
                  selectedRows,
                  onClick,
                  onSelectAll,
                  rowCount,
                  appliedFiltersHeight,
                  appliedFilters,
                  addedCellWidth,
                  rowsValidationError
            }) => (
                <div ref={ref} {...rest}>
                    {/*<AppliedFilters
                        appliedFilters={appliedFilters}
                        appliedFiltersHeight={appliedFiltersHeight}
                        width={width}
                    />*/}
                    {showCheckColumn&&
                    <CheckAll
                        onSelectAll={onSelectAll}
                        checked={(rowCount===Object.keys(selectedRows).length)?true:false}
                        width={width}
                        appliedFiltersHeight={appliedFiltersHeight}
                        appliedFilters={appliedFilters}
                    />}
                    <StickyRow
                        headerRow={headerRow}
                        showCheckColumn={showCheckColumn}
                        ItemRenderer={ItemRenderer}
                        checkBoxWidth={checkBoxWidth}
                        rowStartIndex={rowStartIndex}
                        rowStopIndex={rowStopIndex}
                        columnStartIndex={columnStartIndex}
                        columnStopIndex={columnStopIndex}
                        headerRender={headerRender}
                        tableWidth={width}
                        tableHeight={height}
                        addedCellWidth={addedCellWidth}
                    />
                    {rowCount > 0 ? <StickyColumn
                        stickyColumn={stickyColumn}
                        showCheckColumn={showCheckColumn}
                        height={height}
                        rowIndex={rowIndex}
                        columnIndex={columnIndex}
                        dataArray={dataArray}
                        checkBoxWidth={checkBoxWidth}
                        rowStartIndex={rowStartIndex}
                        rowStopIndex={rowStopIndex}
                        columnStartIndex={columnStartIndex}
                        columnStopIndex={columnStopIndex}
                        selectedRows={selectedRows}
                        onClick={onClick}
                        appliedFiltersHeight={appliedFiltersHeight}
                        rowsValidationError={rowsValidationError}
                    />:containerEmpty}
                    {children}
                </div>
            )}
        </StickyGridContext.Consumer>
    )});


const StickyColumn = memo(props=>{

    const {showCheckColumn,
        stickyColumn,
        dataArray,
        rowStartIndex,
        rowStopIndex,
        selectedRows,
        onClick,
        appliedFiltersHeight,
        rowsValidationError
        }=props;


    const startRowIndex=rowStartIndex;
    const stopRowIndex=rowStopIndex;
    const startColumnIndex=0;
    const stopColumnIndex=stickyColumn.length-1;

    const columns=[];

    for(let indexRow=startRowIndex; indexRow<=stopRowIndex; indexRow++){

        const rowDisabled = rowsValidationError && rowsValidationError[indexRow] && rowsValidationError[indexRow].disabled;
        const styleRow=rowDisabled ? ((indexRow%2===0)?" cell-error-even":" cell-error-odd") : ((indexRow%2===0)?" cell-even":" cell-odd");

        if(showCheckColumn===true) {
            const rowSelected=(selectedRows[indexRow]!=null)?true:false;
            const selectedClassName=(rowSelected)?" table-cell-selected":"";

            columns.push(<CheckboxCell key={"stickyCheck"+indexRow}
                                       style={{top:indexRow*defaultCellHeight+defaultCellHeight+appliedFiltersHeight,left:0,width:checkboxWidth,height:defaultCellHeight}}
                                       checked={rowSelected}
                                       className={selectedClassName+styleRow}
                                       onClick={onClick}
                                       columnIndex={0}
                                       rowIndex={indexRow}

            />);
        }
        for(let indexColumn=startColumnIndex; indexColumn<=stopColumnIndex; indexColumn++){
            columns.push(<div key={"stickyCheck"+indexRow+indexColumn}
                              className={"table-sticky-column-cell"}
                              style={{top:indexRow*defaultCellHeight,left:defaultCellWidth*indexColumn,width:defaultCellWidth,height:defaultCellHeight}}

                        >
                {dataArray[indexRow][stickyColumn[indexColumn]]}
            </div>);
        }
    }
    
    return (
        <div className="table-sticky-column-container" style={{paddingTop:defaultCellHeight+'px'}}>
            {columns}
        </div>
    )});

const StickyRow = memo(props=>{
    const {
    //style,
    headerRow,
    checkBoxWidth,
    //rowStartIndex,
    //rowStopIndex,
    columnStartIndex,
    columnStopIndex,
    headerRender,
    tableWidth,
    tableHeight,
    addedCellWidth,
}=props;

    const startColumnIndex = columnStartIndex;
    const stopColumnIndex = columnStopIndex;
    const rows = [];

    for (let indexColumn = startColumnIndex; indexColumn <= stopColumnIndex; indexColumn++) {
        const text=headerRow[indexColumn]["value"];
        const width=headerRow[indexColumn].width+addedCellWidth;
        const left=headerRow[indexColumn].accumulatedWidth+(addedCellWidth*indexColumn);
        const itemKey=headerRow[indexColumn]["key"];
        rows.push(<div key={"headerRow" + indexColumn} className={"table-sticky-row-cell"} style={{
            top: 0,
            left: left + checkBoxWidth,
            width: width,
            height: defaultCellHeight
        }}>
            {(headerRender!=null)?headerRender({text,rowIndex:0,columnIndex:indexColumn,tableWidth,tableHeight, itemKey}):text}
        </div>);
    }

    return (
        <div className="table-sticky-row-container">
            {rows}
        </div>
    );
});



export default Table;