import React, {useState} from "react";
import InfoIcon from '@material-ui/icons/Info';
const DeviceStatus = ({status}) => {

    let styleIndicator={width:'100%',height:'100%',color:'#fff'};
    let label = status?status.title:"n/a";
    let statusMessage = status?status.status:null;
    const [showMassageTooltip,setShowMassageTooltip]=useState(false);

    switch(label){
        case 'Running':
            styleIndicator.backgroundColor='#009c34';
            break;
        case 'Failure':
            styleIndicator.backgroundColor='#f02618';
            break;
        case 'Rebooting':
            styleIndicator.backgroundColor='#faa63a';
            break;
        case 'Stopped':
            styleIndicator.backgroundColor='#9b9b9b';
            break;
        case 'Disabled':
            styleIndicator.backgroundColor='#a9a9a9';
            styleIndicator.color='#eeeeee';
            break;
        case 'Initialized':
            styleIndicator.backgroundColor='#f3d745';
            styleIndicator.color='#000';
            break;
        case 'Publishing':
            styleIndicator.backgroundColor='#ff7c00';
            break;

        case '':
            break;
        default:
            styleIndicator={};
    }

    const showTooltip=()=>{
        setShowMassageTooltip(true);
    }
    const hiddenTooltip=()=>{
        setShowMassageTooltip(false);
    }


    return <div className={'device-status-container'} style={styleIndicator}>
        <span className={'device-status-label'}>{label}</span>
        {(statusMessage != null&&statusMessage!=='') ?
        <div className={'device-status-tooltip-container'} onMouseOver={showTooltip} onMouseLeave={hiddenTooltip}>
            <InfoIcon style={{width:20, height:20}}/>
            {(showMassageTooltip)?<div className='device-status-tooltip' style={{width: 'max-content'}}><span>{statusMessage}</span></div>:null}
        </div> : null}

    </div>;
}

export default DeviceStatus;
