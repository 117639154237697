import React from 'react';
import PropTypes from 'prop-types';
import RaisedButton from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

class TextFileInput extends React.Component{

    constructor(){
        super();

        this.state = {
            fileName:"",
            fileContent: ""
        };

        this.handleTouchTap = this.handleTouchTap.bind(this);
        this.handleFileLoad = this.handleFileLoad.bind(this);
        this.handleWindowFocus = this.handleWindowFocus.bind(this);

    }

    static get propTypes(){
        return {
            label: PropTypes.string,
            accept: PropTypes.string,
            onError: PropTypes.func
        };
    }

    static get defaultProps(){
        return {
            onError: function(){}
        };
    }

    getContent(){
        return this.state.fileContent;
    }

    handleTouchTap(){
        this.refs.input.click();
        window.addEventListener('focus',this.handleWindowFocus);
    }

    handleWindowFocus(){
        window.removeEventListener('focus',this.handleWindowFocus);
        this.setState({
            updating:true
        });
    }

    handleFileLoad(e){
        var files = e.target.files;

        let reader = new FileReader();

        reader.addEventListener('loadend',(e)=>{
            var txt = e.target.result;
            this.setState({
                fileContent: txt
            });
        });

        if(files[0]){

            if(files[0].name.match(this.props.accept || "")){
                this.setState({
                    fileName: files[0].name,
                    updating: false
                });
                reader.readAsText(files[0]);
            }else{
                this.props.onError("Invalid file.  Must be "+this.props.accept);
                this.setState({
                    updating:false
                });
            }


        }else{
            this.setState({
                updating:false
            });
        }
    }

    render(){
        const {
            label,
            ...other
        } = this.props;

        const {
            fileName,
            updating
        } = this.state;

        let button = null;
        if(fileName){
            button = (
                <div>
                    <span>{fileName}</span>
                    <div style={{float:'right',color:'#777'}}>
                        <IconButton
                                style={{padding:'0px', backgroundColor:'transparent'}}
                                size="small"
                                onClick={this.handleTouchTap}
                            >
                            <EditIcon fontSize="inherit" />
                        </IconButton>
                    </div>
                </div>
            );
        }else if(updating){
            button = (
                <RaisedButton
                    label={"loading file..."}
                    style={{width:'100%', height: 36, backgroundColor: '#aaa', color: 'inherit'}}
                    onClick={this.handleTouchTap}
                    variant="contained"
                >loading file...</RaisedButton>
            );
        }else{
            button = (
                <RaisedButton
                    label={label}
                    style={{width:'100%', height: 36, color: 'inherit'}}
                    onClick={this.handleTouchTap}
                    variant="contained"
                >{label}</RaisedButton>
            );
        }


        return(
            <div>
                <input
                    ref="input"
                    type="file"
                    className="hidden-file-input"
                    style={{width: 'inherit'}}
                    onChange={this.handleFileLoad}
                    {...other}
                />
                {button}
            </div>
        );
    }
}

export default TextFileInput;
