
function assertInRange(value,{min,max,label,minLabel,maxLabel}){
    if((min || min === 0) && value < min){
        // eslint-disable-next-line
        throw `${label} must be greater than or equal to ${minLabel?minLabel:min}`;
    }

    if((max || max === 0) && value > max){
        // eslint-disable-next-line
        throw `${label} must be less than or equal to ${maxLabel?maxLabel:max}`;
    }
    if(label === "Median Count" ||label ==="Median Count Z" ||label ==="Move Median"){
        if(value%2===0){
            // eslint-disable-next-line
            throw `${label} must be an odd number`;
        }
    }
}

const rangeCheck = (args) => ( ({value,label}) => assertInRange(value,{label,...args}) );

/*
███    ███ ███████ ██████  ██  █████  ███    ██
████  ████ ██      ██   ██ ██ ██   ██ ████   ██
██ ████ ██ █████   ██   ██ ██ ███████ ██ ██  ██
██  ██  ██ ██      ██   ██ ██ ██   ██ ██  ██ ██
██      ██ ███████ ██████  ██ ██   ██ ██   ████
*/

export const MEDIAN_FILTER_SCHEMA = [
    {
        key:"detectMoving",
        type:"checkbox",
        name:"Detect moving"
    },
    {
        key:"medianCount",
        type:"number",
        label:"Median Count",
        validate:rangeCheck({min:1,max:21})
    },
    {
        key:"medianZCount",
        type:"number",
        label:"Median Count Z",
        validate:rangeCheck({min:1,max:21})
    },
    {
        key:"jumpMoveRadius",
        type:"number",
        label:"Move Radius (ft)",
        validate:rangeCheck({min:1})
    },
    {
        key: "jumpMoveCount",
        type:"number",
        label:"Move Count",
        validate:rangeCheck({min:1,max:21})
    },
    {
        key:"moveMedian",
        type:"number",
        label:"Move Median",
        validate:({label,value,values})=>{
            assertInRange(value,{
                label,
                min:1,
                max:values.medianCount,
                maxLabel:"Median Count"
            });
        }
    }
];

/*
██████   █████  ████████ ███████
██   ██ ██   ██    ██    ██
██████  ███████    ██    █████
██   ██ ██   ██    ██    ██
██   ██ ██   ██    ██    ███████
*/

export const RATE_FILTER_SCHEMA = [
    {
        key:"minMoveDistance",
        type:"number",
        label:'Report blink move by more than __ feet since last report.',
        validate:rangeCheck({min:0}),
        defaultValue:10,
        disabledValue:0
    },{
        key:"maxWaitTime",
        type:"number",
        label:"Report blink if it has been more than __ seconds since last report.",
        validate:rangeCheck({min:0}),
        defaultValue:30,
        disabledValue:0
    },{
        key:"minWaitTime",
        type:"number",
        label:"[Override] Do not report if less than __ seconds since last report.",
        validate:rangeCheck({min:0}),
        defaultValue:30,
        disabledValue:0
    }

];

/*
 ██████  █████  ██      ██
██      ██   ██ ██      ██
██      ███████ ██      ██
██      ██   ██ ██      ██
 ██████ ██   ██ ███████ ███████
*/

export const CALL_FILTER_SCHEMA = [
    {
        key:"suppressionWindow",
        type:"number",
        label:"Suppression Window (sec)",
        validate:rangeCheck({min:1})
    }
];
