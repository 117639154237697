import React, {useState, useEffect} from 'react';
import AlertMessage from '../../deviceManager/common/AlertMessage';

import {validateLatsAndLons, calculateLatLongDistance} from './common/utils';
// import {MAX_DISTANCE_BETWEEN_POINTS, MIN_DISTANCE_BETWEEN_POINTS} from "./common/constants";


const SetLatLongComponent = ({configLonLat, saveLonLat, mapsConfiguration, closeLonLat}) => {

    const [startX, setStartX] = useState(null);
    const [startY, setStartY] = useState(null);
    const [startLat, setStartLat] = useState(null);
    const [startLon, setStartLon] = useState(null);
    const [endX, setEndX] = useState(null);
    const [endY, setEndY] = useState(null);
    const [endLat, setEndLat] = useState(null);
    const [endLon, setEndLon] = useState(null);
    const [openAlertValue, setOpenAlertValue] = useState(false);
    const [messageAlertValue, setMessageAlertValue] = useState("");
    const [text2, setText2] = useState(null);
    const [text3, setText3] = useState(null);
    const [isError, setIsError] = useState(false);
    const [isWarning, setIsWarning] = useState(false);

    const handleSetLonLatValues = () => {
        if(mapsConfiguration != null){
            setStartX(mapsConfiguration.geoRefX1?.toString() || "");
            setStartY(mapsConfiguration.geoRefY1?.toString() || "");
            setStartLat(mapsConfiguration.geoRefLat1?.toString() || "");
            setStartLon(mapsConfiguration.geoRefLong1?.toString() || "");
            setEndX(mapsConfiguration.geoRefX2?.toString() || "");
            setEndY(mapsConfiguration.geoRefY2?.toString() || "");
            setEndLat(mapsConfiguration.geoRefLat2?.toString() || "");
            setEndLon(mapsConfiguration.geoRefLong2?.toString() || "");
        }
    };

    useEffect(handleSetLonLatValues, [mapsConfiguration]);

    const handleSaveLonLat = () => {
        // const latLonDistance = getLatLonDistance(startLat,startLon,endLat,endLon);
        const latLongDistance = calculateLatLongDistance(startLat, startLon, endLat, endLon);

        const coordinatesDistance = Math.sqrt(Math.pow((endX-startX),2)+Math.pow((endY-startY),2));
        const distanceDifference = Math.abs(latLongDistance-coordinatesDistance);
        // if(distanceDifference <= MIN_DISTANCE_BETWEEN_POINTS || distanceDifference > MAX_DISTANCE_BETWEEN_POINTS){

        if(!isFloat(isNaN(startLat) ? startLat.trim() : startLat)||
            !isFloat(isNaN(startLon) ? startLon.trim() : startLon) ||
            !isFloat(isNaN(startX) ? startX.trim() : startX) ||
            !isFloat(isNaN(startY) ? startY.trim() : startY) ||
            !isFloat(isNaN(endLat) ? endLat.trim() : endLat) ||
            !isFloat(isNaN(endLon) ? endLon.trim() : endLon) ||
            !isFloat(isNaN(endX) ? endX.trim() : endX) ||
            !isFloat(isNaN(endY) ? endY.trim() : endY)){
            setOpenAlertValue(!openAlertValue);
            setMessageAlertValue("All the fields must have a valid value");
        } else {
            // validate if lat and long are valid values
            const areLatsLotsValid = validateLatsAndLons([startLat, startLon], [endLat, endLon]);
            if (!areLatsLotsValid) {
                setOpenAlertValue(!openAlertValue);
                setMessageAlertValue("Latitude and longitude must have the next range of valid values:");
                setText2("- Latitude: -90.00000 - 90.00000");
                setText3("- Longitude: -180.00000 - 180.00000");
            }


            if (Math.abs(coordinatesDistance / latLongDistance - 1.0) > 0.01) {
                setOpenAlertValue(!openAlertValue);
                setMessageAlertValue("The difference in distance between site coords and lat/long is " + Math.trunc(distanceDifference) + " feet.");
                setIsError(true);
            }else if (distanceDifference > 10) {
                setOpenAlertValue(!openAlertValue);
                setMessageAlertValue("The distance between the latitude/longitude points and the site coordinate points is " + distanceDifference + " feet. Try again.");
                setIsWarning(true);
            } else {
                const lanLotCoord = {
                    ...mapsConfiguration.coordinates,
                    geoRefLat1: parseFloat(startLat),
                    geoRefLong1: parseFloat(startLon),
                    geoRefX1: parseFloat(startX),
                    geoRefY1: parseFloat(startY),
                    geoRefLat2: parseFloat(endLat),
                    geoRefLong2: parseFloat(endLon),
                    geoRefX2: parseFloat(endX),
                    geoRefY2: parseFloat(endY)
                };
                saveLonLat(lanLotCoord);

            }
        }
    };

    const isFloat = (val) => {
        let floatRegex = /^-?\d+(?:[.,]\d*?)?$/;
        if (!floatRegex.test(val))
            return false;

        val = parseFloat(val);
        if (isNaN(val))
            return false;
        return true;
    };

    const validateSave = () => {
        return true
    };

    const handleCloseAlertValueModal = () => {
        setOpenAlertValue(!openAlertValue);
        setIsError(false);
        setIsWarning(false);
    };

    let suggestForUserStyle = {
        background: 'rgba(0, 0, 0, 0.6)',
        padding:'0.7em 1em 0.7em 5em',
        color: 'white',
        zIndex: '1',
        position: 'absolute',
        top:'483px',
        width:'268px',
        fontSize:'12px',
        textAlign:'justify',
    };

    return (
        <div>
            <div className={'message-for-user'}>
                <b>Configure Latitude and Longitude</b>
                <div className={'cancel-map-coordinates'} style={{right:'110px'}}>
                    <button onClick={closeLonLat}>Cancel</button>
                </div>
                <div className={'save-map-coordinates'}>
                    {(configLonLat) && <button onClick={handleSaveLonLat} disabled={!validateSave()} >Save</button>}
                </div>
            </div>
            <div className={'config-map-lat-lon'}>
                <div className={'form-config-lat-lon'}>
                    <div className={'label-defined-size'}>
                        <label>Reference Point 1:</label>
                    </div>
                    <div className={'config-defined-size'}>
                        <label>X :</label><input size={8} value={startX  == null ? "" : startX} onChange={(e) => setStartX( e.target.value)}/>
                    </div>
                    <div className={'config-defined-size'}>
                        <label>Y :</label><input size={8} value={startY  == null ? "" : startY} onChange={(e) => setStartY( e.target.value)}/>
                    </div>
                    <div className={'config-defined-size'}>
                        <label>Latitude:</label><input size={8} value={startLat  == null ? "" : startLat} onChange={(e) => setStartLat( e.target.value)}/>
                    </div>
                    <div className={'config-defined-size'}>
                        <label>Longitude:</label><input size={8} value={startLon  == null ? "" : startLon} onChange={(e) => setStartLon( e.target.value)}/>
                    </div>
                    <div className={'label-defined-size'}>
                        <label>Reference Point 2</label>
                    </div>
                    <div className={'config-defined-size'}>
                        <label>X :</label><input size={8} value={endX == null ? "" : endX} onChange={(e) => setEndX(e.target.value)}/>
                    </div>
                    <div className={'config-defined-size'}>
                        <label>Y :</label><input size={8} value={endY == null ? "" : endY} onChange={(e) => setEndY(e.target.value)}/>
                    </div>
                    <div className={'config-defined-size'}>
                        <label>Latitude:</label><input size={8} value={endLat == null ? "" : endLat} onChange={(e) => setEndLat(e.target.value)}/>
                    </div>
                    <div className={'config-defined-size'}>
                        <label>Longitude:</label><input size={8} value={endLon == null ? "" : endLon} onChange={(e) => setEndLon(e.target.value)}/>
                    </div>
                    {/*<div className={'save-map-coordinates'}>
                        {(configWH) && <button onClick={handleSaveWidthOrHeight} >Save</button>}
                    </div>*/}
                </div>
            </div>
            <div style={suggestForUserStyle}>
                This feature is only supported with ZLA. After saving reference points, please perform a Download and Publish operation in System Builder to push the configuration to the ZLA.
            </div>
            <AlertMessage isError={isError} isWarning={isWarning} openModal={openAlertValue} onClose={handleCloseAlertValueModal} message={messageAlertValue} text2={text2} text3={text3}/>
        </div>
    )
};

export default SetLatLongComponent;