import React from 'react';
import PropTypes from 'prop-types';

import {BoundsCheckingLocalMap as LocalMap, zoomMapByDeltaWithDuration} from '../../../util/map/LocalMapView';
import {GreyFilter} from '../../../util/map/PlaybackMap';

import {overlayWidth, SmartSiteMapThumbnailDrawer} from '../../../util/SiteMapThumbnailOverlay2';

import Paper from "@material-ui/core/Paper";
import IconButton from '@material-ui/core/IconButton';

import ZoomInIcon from '@material-ui/icons/AddBox';
import ZoomOutIcon from '@material-ui/icons/IndeterminateCheckBox';

import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../../../../actions';

import {interpolateRgb} from 'd3-interpolate';
import CustomTable from '../CustomTable';
// import {SmarterZoneLayer} from '../../../util/map/LocalMap';
// import {LayerGroup} from '../../../../../../common/app/views/map/ZMap';
//import knowZones from '../../../util/hoc/knowZones';
// import ZoneClickLabelLayer from '../../../util/map/layers/ZoneTooltipLabelLayer';
// import ZoneLabelLayer from '../../../util/map/layers/ZoneLabelLayer';
import Feature from "ol/Feature";
import VectorLayer from "ol/layer/Vector";
import Polygon from "ol/geom/Polygon";
import VectorSource from "ol/source/Vector";
import Fill from "ol/style/Fill";
import Text from "ol/style/Text";
import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";

const SelfManagedLocalMap = connect(
    (state,props)=>{

        const map = state.sites.maps.find(m=>m._id===props.mapId);

        return {
            maxZoom: (map ? map.maxZoom - 1 : 1)
        };
    },

    dispatch => ({
        ...bindActionCreators({
            requestMapBounds:actions.requestMapBounds,
        },dispatch)
    })
)(LocalMap);

/*
███████ ██    ██ ███    ███ ███    ███  █████  ██████  ██    ██
██      ██    ██ ████  ████ ████  ████ ██   ██ ██   ██  ██  ██
███████ ██    ██ ██ ████ ██ ██ ████ ██ ███████ ██████    ████
     ██ ██    ██ ██  ██  ██ ██  ██  ██ ██   ██ ██   ██    ██
███████  ██████  ██      ██ ██      ██ ██   ██ ██   ██    ██

████████  █████  ██████  ██      ███████
   ██    ██   ██ ██   ██ ██      ██
   ██    ███████ ██████  ██      █████
   ██    ██   ██ ██   ██ ██      ██
   ██    ██   ██ ██████  ███████ ███████
*/


const SUMMARY_TABLE_HEIGHT = '200px';

class SummaryTable extends React.Component{

    static get propTypes(){
        return {
            data: PropTypes.array
        };
    }

    static get defaultProps(){
        return {
            data:[]
        };
    }

    render(){
        const {data} = this.props;
        return (
            <CustomTable
                style={{position:'relative',height:SUMMARY_TABLE_HEIGHT}}
                data={data}
                columns={[
                    {caption:"Zone",key:"location",align:'left'},
                    {caption:"Resource Type",key:"ResourceType",align:"left"},
                    {caption:"Count",key:"cnt",align:'center'}
                ]}
            />
        );
    }
}

/*
███████  ██████  ███    ██ ███████     ██       █████  ██    ██ ███████ ██████
   ███  ██    ██ ████   ██ ██          ██      ██   ██  ██  ██  ██      ██   ██
  ███   ██    ██ ██ ██  ██ █████       ██      ███████   ████   █████   ██████
 ███    ██    ██ ██  ██ ██ ██          ██      ██   ██    ██    ██      ██   ██
███████  ██████  ██   ████ ███████     ███████ ██   ██    ██    ███████ ██   ██
*/



// class SpecialZoneLayer extends React.Component{
//     static get propTypes(){
//         return {
//             zones: PropTypes.array,
//             mapId: PropTypes.any,
//             data: PropTypes.array,
//             zoneGroup: PropTypes.string
//         };
//     }
//
//     static get defaultProps(){
//         return {
//             zoneGroup: null
//         };
//     }
//
//     constructor(){
//         super();
//
//         this.colorInterpolator = interpolateRgb('white','#00779f');
//     }
//
//
//     render(){
//         const {zones,mapId,data,zoneGroup} = this.props;
//
//
//         const allCountByZoneName = data.reduce((lookup,datum)=>{
//             return {
//                 ...lookup,
//                 [datum.location]:(lookup[datum.location] || 0) + datum.cnt
//             };
//         },{});
//
//         const maxCountInZones = Object.keys(allCountByZoneName).map(k=>allCountByZoneName[k]).reduce((max,datum)=>{
//             return (max > datum) ? max : datum;
//         },0);
//
//         //console.log(allCountByZoneName,maxCountInZones);
//
//
//         const zonesOnMap = zones.filter(z=>z.map===mapId && this.inZoneGroup(z,zoneGroup)).map(zone=>{
//
//             let relativeShade = maxCountInZones ? (
//                 (allCountByZoneName[zone.name] || 0) / maxCountInZones
//             ) : 0;
//
//
//             let rgbString = this.colorInterpolator(relativeShade);
//             let isolatedColors = rgbString.match(/[0-9]{1,3}\s*,\s*[0-9]{1,3}\s*,\s*[0-9]{1,3}/);
//             let color = isolatedColors && isolatedColors[0].split(',').map(v=>parseInt(v)) || [255,255,255];
//
//             return {
//                 ...zone,
//                 color,
//                 label: (allCountByZoneName[zone.name] || 0)
//             };
//         });
//
//         return(
//             <LayerGroup {...this.props}>
//                 <SmarterZoneLayer
//                     {...this.props}
//                     zones={zonesOnMap}
//                     zoneVisibility={1}
//                 />
//                 <SmarterZoneLabelLayer
//                     {...this.props}
//                     zones={zonesOnMap}
//                     zoneVisibility={2}
//                     zIndex={1}
//                 />
//             </LayerGroup>
//
//         );
//     }
//
//     inZoneGroup(zone,zoneGroup){
//         //console.log(zone,zoneGroup);
//
//         if(zoneGroup===null || zoneGroup==="%") return true;
//         return (zone.raw.locationGroupName === zoneGroup);
//     }
// }
//const WidgetZoneLayer = knowZones(SpecialZoneLayer);

/*
███████  ██████  ███    ██ ███████     ██       █████  ██████  ███████ ██
   ███  ██    ██ ████   ██ ██          ██      ██   ██ ██   ██ ██      ██
  ███   ██    ██ ██ ██  ██ █████       ██      ███████ ██████  █████   ██
 ███    ██    ██ ██  ██ ██ ██          ██      ██   ██ ██   ██ ██      ██
███████  ██████  ██   ████ ███████     ███████ ██   ██ ██████  ███████ ███████

 ██████ ██      ██  ██████ ██   ██
██      ██      ██ ██      ██  ██
██      ██      ██ ██      █████
██      ██      ██ ██      ██  ██
 ██████ ███████ ██  ██████ ██   ██

██       █████  ██    ██ ███████ ██████
██      ██   ██  ██  ██  ██      ██   ██
██      ███████   ████   █████   ██████
██      ██   ██    ██    ██      ██   ██
███████ ██   ██    ██    ███████ ██   ██
*/

// class SmarterZoneClickLabelLayer extends React.Component{
//     static get propTypes(){
//         return {
//             zones: PropTypes.array
//         };
//     }
//
//     shouldComponentUpdate(nextProps){
//         return this.props !== nextProps;
//     }
//
//     render(){
//         return (
//             <ZoneClickLabelLayer
//                 {...this.props}
//                 zones={this.props.zones}
//                 active={true}
//             />
//         );
//     }
// }

// class SpecialZoneClickLabelLayer extends React.Component{
//     static get propTypes(){
//         return {
//             zones: PropTypes.array,
//             mapId: PropTypes.any,
//             data: PropTypes.array,
//             selectedZone: PropTypes.oneOfType([
//                 PropTypes.string,
//                 PropTypes.number
//             ])
//         };
//     }
//
//     constructor(){
//         super();
//
//         this.colorInterpolator = interpolateRgb('white','#00779f');
//     }
//
//
//     render(){
//         console.log("Render clickeable label");
//         const {zones,mapId,data,selectedZone} = this.props;
//
//         const dataByZoneName = data.reduce((lookup,datum)=>{
//             return {
//                 ...lookup,
//                 [datum.location]:(lookup[datum.location] || []).concat([datum])
//             };
//         },{});
//
//         function tweakZoneLabel(zone){
//             const counts = (dataByZoneName[zone.name]) ? (
//                 dataByZoneName[zone.name].map(datum=>(
//                     <tr key={datum.ResourceType}>
//                         <td style={{paddingRight:'0.5em'}}>{`${datum.ResourceType}`}</td>
//                         <td>{` ${datum.cnt}`}</td>
//                     </tr>
//                 ))
//             ) : null;
//
//             return (
//                 <div key={zone.name}>
//                     <span style={{fontWeight:'bold',letterSpacing:'0.5px'}}>{zone.name}</span>
//                     <table>
//                         <tbody>
//                             {counts}
//                         </tbody>
//                     </table>
//                 </div>
//             );
//         }
//
//         const zoneLabelsOnMap = zones.filter(z=>z.map===mapId && z.name===selectedZone).map(zone=>{
//             return {
//                 ...zone,
//                 name: tweakZoneLabel(zone)
//             };
//         });
//
//         return(
//             <SmarterZoneClickLabelLayer
//                 {...this.props}
//                 zones={zoneLabelsOnMap}
//             />
//         );
//     }
// }
//const WidgetZoneClickLabelLayer = knowZones(SpecialZoneClickLabelLayer);


/*
███████  ██████  ███    ██ ███████     ██       █████  ██████  ███████ ██
   ███  ██    ██ ████   ██ ██          ██      ██   ██ ██   ██ ██      ██
  ███   ██    ██ ██ ██  ██ █████       ██      ███████ ██████  █████   ██
 ███    ██    ██ ██  ██ ██ ██          ██      ██   ██ ██   ██ ██      ██
███████  ██████  ██   ████ ███████     ███████ ██   ██ ██████  ███████ ███████

██       █████  ██    ██ ███████ ██████
██      ██   ██  ██  ██  ██      ██   ██
██      ███████   ████   █████   ██████
██      ██   ██    ██    ██      ██   ██
███████ ██   ██    ██    ███████ ██   ██
*/


// class SmarterZoneLabelLayer extends React.Component{
//     static get propTypes(){
//         return {
//             zones: PropTypes.array
//         };
//     }
//
//     shouldComponentUpdate(nextProps){
//         return this.props !== nextProps;
//     }
//
//     render(){
//         console.log("Render label");
//         return (
//             <ZoneLabelLayer
//                 {...this.props}
//                 zones={this.props.zones}
//                 active={true}
//             />
//         );
//     }
// }

/*
███████ ██       ██████   ██████  ██████      ███    ███  █████  ██████
██      ██      ██    ██ ██    ██ ██   ██     ████  ████ ██   ██ ██   ██
█████   ██      ██    ██ ██    ██ ██████      ██ ████ ██ ███████ ██████
██      ██      ██    ██ ██    ██ ██   ██     ██  ██  ██ ██   ██ ██
██      ███████  ██████   ██████  ██   ██     ██      ██ ██   ██ ██

██     ██ ██ ██████   ██████  ███████ ████████
██     ██ ██ ██   ██ ██       ██         ██
██  █  ██ ██ ██   ██ ██   ███ █████      ██
██ ███ ██ ██ ██   ██ ██    ██ ██         ██
 ███ ███  ██ ██████   ██████  ███████    ██
*/


class FloorMapWidget extends React.Component{
    static get propTypes(){
        return {
            data: PropTypes.array,
            children: PropTypes.any,
            requestSites: PropTypes.func.isRequired,
            requestMaps: PropTypes.func.isRequired,
            site: PropTypes.shape({
                _id:PropTypes.any
            }),
            initialMapId: PropTypes.any,
            onMapChange: PropTypes.func,
            zoneGroup: PropTypes.string
        };
    }

    static get defaultProps(){
        return {
            onMapChange:()=>{}
        };
    }

    constructor(props){
        super(props);

        this.state={
            mapId: props.initialMapId,
            selectedZone: "",
            zones:[]
        };
        this.layers = {};

        this.zoomIn = this.zoomIn.bind(this);
        this.zoomOut = this.zoomOut.bind(this);
        this.handleSelectMap = this.handleSelectMap.bind(this);
        this.colorInterpolator = interpolateRgb('white','#00779f');
        this.drawWidgetZones = this.drawWidgetZones.bind(this);
        this.drawWidgetClickLabel = this.drawWidgetClickLabel.bind(this);
        this.removeLayers = this.removeLayers.bind(this);
    }

    componentDidMount(){
        this.map = window.MY_MAP;
        this.refreshZones(this.props.site && this.props.site._id);
        this.props.requestSites()
        .then(this.props.requestMaps)
        .then(()=>{
            this.props.onMapChange({"mapId":this.state.mapId}); // covering bases...
        });
    }

    getMapFromRefs(){
        return window.MY_MAP;//this.refs.map.getWrappedInstance().getMap(); // (consequence of using the connect() function -- can't use child methods particularly easily)
    }

    componentDidUpdate(){
        this.map = window.MY_MAP;
        if(this.map) {
            this.removeLayers();
            this.drawWidgetClickLabel(this.state.zones);
            this.drawWidgetZones(this.state.zones);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(typeof nextProps.initialMapId !== "undefined" && nextProps.initialMapId !== this.props.initialMapId){
            this.setState({
                mapId: nextProps.initialMapId
            });
            this.props.onMapChange({"mapId":nextProps.initialMapId});

            if(this.props.site !== nextProps.site){
                this.refreshZones(nextProps.site._id);
            }
        }
    }

    zoomIn(){
        zoomMapByDeltaWithDuration(this.map,1,250);
    }

    zoomOut(){
        zoomMapByDeltaWithDuration(this.map,-1,250);
    }

    refreshZones(siteId){
        if(siteId === undefined) return;

        this.props.requestZones(siteId)
            .then((zones)=>{
                if(this.unmounted) return; // workaround
                this.setState({
                    zones: this.interpretZonesFromDefinition(zones)
                });
            });
    }

    interpretZonesFromDefinition(zones){
        return zones.map(zone=>({
            name: zone.name,
            color: zone.color && this.hexColorToRGBArray(zone.color),
            shape: zone.shapeTrue && this.interpretShapeString(zone.shapeTrue),
            map: zone.mapId,
            raw: zone
        }));
    }

    hexColorToRGBArray(hex){
        return [0,2,4].map(i=>parseInt(hex.slice(i,i+2),16));
    }

    interpretShapeString(shapeString){
        let delimited = shapeString.split(';');
        let n = parseInt(delimited[0]);
        let shape = [];
        for(let i = 1; i <= 2*n; i+=2){
            shape.push([ parseFloat(delimited[i]), parseFloat(delimited[i+1]) ]);
        }
        shape.push([].concat(shape[0]));
        return shape;
    }

    removeLayers() {
        for(const key in this.layers)
        {
            const layer=this.layers[key];
            this.map.removeLayer(layer);
        }
    }

    render(){
        const {
            children,
            site
        } = this.props;

        const ZOOM_TOOLBAR_WIDTH = '3em';

        const zoomToolbar = (
            <Paper style={{position:'absolute',top:0,bottom:0,left:0,width:ZOOM_TOOLBAR_WIDTH,zIndex:1}} >
                <IconButton style={{display:'block',height:'32px',padding:'8px 12px 0px'}} onClick={this.zoomIn} disableTouchRipple>
                    <ZoomInIcon />
                </IconButton>

                <IconButton style={{display:'block',height:'32px',padding:'0px 12px 8px'}} onClick={this.zoomOut} disableTouchRipple>
                    <ZoomOutIcon />
                </IconButton>
            </Paper>
        );

        return (
            <div className="vss-map">
                {zoomToolbar}
                <div style={{position:'absolute',top:0,bottom:0,left:ZOOM_TOOLBAR_WIDTH,right:0}}>
                    <div style={{position:'absolute',top:0,bottom:0,left:0,right:`${overlayWidth}px`}}>
                        <div style={{position:'absolute',left:0,right:0,top:0,bottom:SUMMARY_TABLE_HEIGHT}}>
                            {this.renderMap()}
                        </div>

                        <Paper style={{position:'absolute',bottom:0,left:0,right:0}} >
                            <SummaryTable data={this.props.data}/>
                        </Paper>
                    </div>
                    <SmartSiteMapThumbnailDrawer
                        siteId={site && site._id}
                        onSelectMap={this.handleSelectMap}
                    />
                </div>
                {children}
            </div>
        );
    }

    drawWidgetZones(zones){

        const allCountByZoneName = this.props.data.reduce((lookup,datum)=>{
            return {
                ...lookup,
                [datum.location]:(lookup[datum.location] || 0) + datum.cnt
            };
        },{});

        const maxCountInZones = Object.keys(allCountByZoneName).map(k=>allCountByZoneName[k]).reduce((max,datum)=>{
            return (max > datum) ? max : datum;
        },0);

        const elementId = this.state.mapId;
        if (this.layers.hasOwnProperty(elementId)) {
            this.map.removeLayer(this.layers[elementId+"zone"]);
        }
        let _zones = zones.filter(z=>z.map===this.state.mapId && this.inZoneGroup(z,this.props.zoneGroup)).map((v, i) => {
            const label = v.label || v.name;
            let relativeShade = maxCountInZones ? (
                (allCountByZoneName[label] || 0) / maxCountInZones
            ) : 0;


            let rgbString = this.colorInterpolator(relativeShade);
            let isolatedColors = rgbString.match(/[0-9]{1,3}\s*,\s*[0-9]{1,3}\s*,\s*[0-9]{1,3}/);
            let color = (isolatedColors && isolatedColors[0].split(',').map(v=>parseInt(v))) || [255,255,255];
            let zoneFeature = new Feature({
                geometry: new Polygon([v.shape]),
                data: {index: i, name: v.name, color: v.color, zone: v.name}
            });
            zoneFeature.setStyle(new Style({
                fill: new Fill({
                    color: [color[0], color[1], color[2], color[3] || 0.46]
                }),
                stroke: new Stroke({color: '#000000'})
            }));
            return zoneFeature;
        });

        const zoneLayer = new VectorLayer ({
            renderMode: 'image',
            source: new VectorSource ({
                features: [..._zones]
            })
        });


        this.layers[elementId+"zone"] = null;
        this.layers[elementId+"zone"] = zoneLayer;

        this.map.addLayer(zoneLayer);
    }

    drawWidgetClickLabel(zones){

        const elementId = this.state.mapId;
        if (this.layers.hasOwnProperty(elementId)) {
            this.map.removeLayer(this.layers[elementId+"label"]);
        }
        const allCountByZoneName = this.props.data.reduce((lookup,datum)=>{
            return {
                ...lookup,
                [datum.location]:(lookup[datum.location] || 0) + datum.cnt
            };
        },{});

        let zonesLabel = zones.filter(z=>z.map===this.state.mapId && this.inZoneGroup(z,this.props.zoneGroup)).map((v, i) => {

            const label = allCountByZoneName[v.label || v.name] || 0;

            const labelFeature = new Feature({
                geometry: new Polygon([v.shape])
            });
            labelFeature.set("label", label+"");
            return labelFeature;
        });

        const labelStyle = this.getLabelStyle();

        const labelLayer = new VectorLayer ({
            zIndex: 20,
            renderMode: 'image',
            source: new VectorSource ({
                features: [...zonesLabel]
            }),
            style: function (feature) {
                labelStyle.getText().setText(feature.get('label'));
                return labelStyle;
            }
        });


        this.layers[elementId+"label"] = null;
        this.layers[elementId+"label"] = labelLayer;
        this.map.addLayer(labelLayer);
    }

    getLabelStyle(){
        if(this.labelStyle == null) {
            let zoneLabel = {
                text: new Text({
                    font: '16px Calibri,sans-serif',
                    fontWeight: '600',
                    fill: new Fill({color: '#000'}),
                    backgroundFill: new Fill({color: "#fff"}),
                    backgroundStroke: new Stroke({color: "#000", width: 1}),
                    offsetY: 0,
                    overflow: true,
                    padding: [1.5, 5, 1.5, 5]
                })
            };

            this.labelStyle = new Style({
                ...zoneLabel
            });
        }
        return this.labelStyle;
    }

    inZoneGroup(zone,zoneGroup){
        if(zoneGroup===null || zoneGroup==="%") return true;
        return (zone.raw.locationGroupName === zoneGroup);
    }

    renderMap(){
        const {mapId} = this.state;

        return (
            <SelfManagedLocalMap
                disableOLControls
                mapId={mapId}
                onSelectFeature={(data)=>{this.setState({selectedZone:data.name});}}
            >
                <GreyFilter />
            </SelfManagedLocalMap>
        );
    }

    handleSelectMap(map){
        this.setState({
            mapId: map._id
        });
        this.props.onMapChange({"mapId":map._id});
        if(this.map) {
            this.removeLayers();
            this.drawWidgetClickLabel(this.state.zones);
            this.drawWidgetZones(this.state.zones);
        }
    }
}

export default connect(

    (state,props) => {
        let siteName = props.parameterValues["site"];
        let zoneGroup = props.parameterValues["zonegroup"];
        const site = state.sites.sites.find(s=>s.name===siteName);
        const siteMaps = state.sites.maps.filter(m=>m.siteId && m.siteId===(site && site._id));
        const initialMapId = siteMaps && siteMaps[0] && siteMaps[0]._id;

        return {
            site,
            initialMapId,
            zoneGroup
        };
    },

    dispatch => ({
        ...bindActionCreators({
            requestSites: actions.requestSites,
            requestMaps: actions.requestMaps,
            requestZones: actions.requestZones
        },dispatch)
    })

)(FloorMapWidget);
