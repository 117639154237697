import React from 'react';
import PropTypes from 'prop-types';

import {Field, Form} from '../../../../util/DataEntryTabularForm';

import Checkbox from '@material-ui/core/Checkbox';
import {FormControlLabel} from '@material-ui/core/es';

export default class BaseFilterForm extends React.Component{

    static get propTypes(){
        return {
            technology: PropTypes.string,
            children: PropTypes.any,
            enabled: PropTypes.bool,
            onChange: PropTypes.func
        };
    }

    static get defaultProps(){
        return {
            technology: "Unspecified",
            enabled: false,
            onChange:()=>{}
        };
    }

    constructor(){
        super();
        this.handleCheck = this.handleCheck.bind(this);
    }

    handleCheck(event,isChecked){
        const {onChange} = this.props;

        onChange({
            enabled: isChecked
        });
    }

    render(){
        const {technology, enabled, children} = this.props;

        return (
            <Form fullWidth>
                <Field>
                    <FormControlLabel control = {
                        <Checkbox
                            onChange={this.handleCheck}
                            checked={enabled}
                            style={{backgroundColor:'transparent', color: enabled?'#43a047':'inherit', maxHeight: 10}}
                            disableTouchRipple/>
                        }
                        label={"Enable this filter"}
                    />
                <hr />
                </Field>

                <Field label="Technology">
                    {technology}
                </Field>

                {children}
            </Form>
        );
    }
}
