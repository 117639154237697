import React from 'react';
import WrappedTable from './WrappedTable';
import MapView from './MapView';
import * as views from '../../../constants/ViewTypes';
import {MAP_ABOVE_REPORT_HORIZONTAL, MAP_FULL_SCREEN, MAP_REPORT_DEFAULT} from '../../../constants/ViewTypes';
import SplitPane from '../../../../../common/app/views/table/splitpane/SplitPane';
import HistoryPlaybackMap from "../map/playback/HistoryPlaybackMap";
import FlatButton from '@material-ui/core/Button';
import MapsMap from '@material-ui/icons/Map';
import ImageGridOn from '@material-ui/icons/GridOn';


const MAX_RIGHT_OFFSET = 200;

class ReportView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            resizeTrigger: (new Date()).getTime(),
            showTable: true,
            showMap: false,
        };
        this.tableShowMobile = this.tableShowMobile.bind(this);
        this.mapShowMobile = this.mapShowMobile.bind(this);
        this.handleSplitResize = this.handleSplitResize.bind(this);
        this.handleHoverIndexUpdate = this.handleHoverIndexUpdate.bind(this);
        this.onItemSelected = this.onItemSelected.bind(this);
    }

    handleSplitResize() {
        this.setState({
            resizeTrigger: (new Date()).getTime()
        });
    }
    handleHoverIndexUpdate(hoverIndex) {
        const {view} = this.props;
         if(view !== views.MAP_FULL_SCREEN){
             this.refs.table.jumpToRow(hoverIndex);
         }
        //this.refs.table.jumpToRow(hoverIndex);
    }
    onItemSelected(item){
        this.handleHoverIndexUpdate(item.___index);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.sidebarFilterStatus.leftSidebarCollapsed !== this.props.sidebarFilterStatus.leftSidebarCollapsed
            || nextProps.sidebarFilterStatus.rightSidebarCollapsed !== this.props.sidebarFilterStatus.rightSidebarCollapsed
            || nextProps.sidebarFilterStatus.leftSidebarEnabled !== this.props.sidebarFilterStatus.leftSidebarEnabled
            || nextProps.sidebarFilterStatus.rightSidebarEnabled !== this.props.sidebarFilterStatus.rightSidebarEnabled
            || nextProps.animationEnabled !== this.props.animationEnabled
            || nextProps.resizing !== this.props.resizing
            || nextProps.view !== this.props.view // When change orientation of map.
            || nextProps.isPortrait !== this.props.isPortrait // When change orientation of screen.
            || nextProps.view !== this.props.view // When change orientation of split view.
        ) {
            this.setState({
                resizeTrigger: (new Date()).getTime()
            });
            if(nextProps.view === views.REPORT_VIEW){
                this.setState({
                    showTable: true,
                    showMap: false,
                });
            }
            if(nextProps.view === views.MAP_FULL_SCREEN){
                this.setState({
                    showTable: false,
                    showMap: true,
                });
            }
        }
    }

    componentDidUpdate(prevProps){
        const {reportId, currentLocalMap} = this.props;
        if(currentLocalMap && currentLocalMap[reportId] && currentLocalMap[reportId].site && prevProps.maps !== this.props.maps) {
            let site = {...currentLocalMap[reportId].site};
            const oldSiteId = site._id;
            const sitesArray = [...this.props.sites];

            for(let i = 0; i < sitesArray.length; i++){
                if(sitesArray[i].name.trim() === site.name.trim() && sitesArray[i]._id !== site._id){
                    site = {...sitesArray[i]};
                    break;
                }
            }

            if(site._id !== oldSiteId) {

                const mapsId = this.props.maps.filter(m => {
                    if (m.siteId === site._id) {
                        return m._id
                    }
                    return false;
                });

                let localMapId = this.props.currentLocalMap[this.props.reportId].localMapId;

                if (mapsId.indexOf(localMapId) === -1) {
                    localMapId = mapsId[0]._id;
                    let currentLocalObj = {...currentLocalMap};
                    currentLocalObj[reportId] = {
                        localMapId: localMapId,
                        site: site
                    };

                    this.props.actions.loadLocalMapData(currentLocalObj);
                }

            }

        }

    }


    tableShowMobile() {
        this.setState({
            showTable:true,
            showMap:false
        });
    }

    mapShowMobile() {
        this.setState({
            showTable:false,
            showMap:true
        });
    }

    getWrappedTable(){
        const {
            columns,
            data,
            onTouchTapCheckbox,
            onTouchTapRow,
            actions,
            filters,
            animationEnabled,
            columnMeta,
            columnOrder,
            detailsItem,
            disableFillTable,
            disableFilters,
            isMobile,
            maps,
            onHoverRow,
            reportId,
            reportMeta,
            requestZones,
            resizing,
            selected,
            showRightSidebar,
            sidebarFilterStatus,
            sites,
            sortDirection,
            sortIndex,
        } = this.props;
        const isHovered = (index,datum) =>{
            // const sameUID = !!(this.props.hoverUIDs.find(h=> datum && h===datum.___uid));
            // return sameUID || (index === this.props.hoverIndex);
            return (index === this.props.hoverIndex);
        };
        return (
            <WrappedTable
                ref="table"
                columns={columns}
                data={data}
                isHovered={isHovered}
                // isHovered={isHovered}
                onTouchTapCheckbox={onTouchTapCheckbox}
                onTouchTapRow={onTouchTapRow}
                actions={actions}
                filters={filters}
                animationEnabled={animationEnabled}
                columnMeta={columnMeta}
                columnOrder={columnOrder}
                detailsItem={detailsItem}
                disableFillTable={disableFillTable}
                disableFilters={disableFilters}
                isMobile={isMobile}
                onHoverRow={onHoverRow}
                requestZones={requestZones}
                resizing={resizing}
                selected={selected}
                showRightSidebar={showRightSidebar}
                sidebarFilterStatus={sidebarFilterStatus}
                sortDirection={sortDirection}
                sortIndex={sortIndex}
                reportId={reportId}
                reportName={reportMeta.name}
                maps={maps}
                sites={sites}
            />
        );
    }

    getMapView(){
        const {
            data,
            sidebarFilteredColumns,
            actions,
            mapFormat,
            hoverUIDs,
            feature,
            reportMeta,
            maps,
            sites,
            filters,
            hoverIndex,
            clickedItem,
            selectedTag,
            isMobile,
            reportId,
            localMapInfo,
            //showRecent,
            view,
            realTime,
            connectedRealTime,
            currentLocalMap
        } = this.props;

        return (
            <MapView
                data={data}
                columns={sidebarFilteredColumns}
                view={views.MAP_VIEW}
                resizeTrigger={this.state.resizeTrigger}
                onHoverIndexUpdate={this.handleHoverIndexUpdate}
                actions={actions}
                mapFormat={mapFormat}
                hoverUIDs={hoverUIDs}
                feature={feature}
                reportMeta={reportMeta}
                maps={maps}
                sites={sites}
                filters={filters}
                hoverIndex={hoverIndex}
                clickedItem={clickedItem}
                selectedTag={selectedTag}
                onItemSelected={this.onItemSelected}
                isMobile={isMobile}
                reportId={reportId}
                localMapInfo={localMapInfo}
                realTime={realTime}
                connectedRealTime={connectedRealTime}
                currentView={view}
                currentLocalMap={currentLocalMap}
            />
        );
    }

    render() {
        const {
            view,
            theme,
            isPortrait,
            //siteId,
            maps,
            reportId,
            sites,
            data,
            mapFormat,
            isMobile,
            isMobileDevice,
            mapEnabled,
        } = this.props;


        if (view === views.REPORT_VIEW) {
            if(!isMobileDevice){
                return this.getWrappedTable();
            }

            if(!mapEnabled){
                return this.getWrappedTable();
            }
        }

        if(view === MAP_FULL_SCREEN){
            if(!isMobileDevice){
                return this.getMapView();
            }
        }

        if(view === views.PLAYBACK_VIEW){
            return(<HistoryPlaybackMap
                resizeTrigger={this.state.resizeTrigger}
                maps={maps}
                reportId={reportId}
                sites={sites}
                //siteId={siteId}
                data={data}
                mapFormat={mapFormat}
                isMobile={isMobile}
            />)
        }

        let orientation = view === views.MAP_REPORT_HORIZONTAL_SPLIT ? 'horizontal' : 'vertical';
        if(view===MAP_REPORT_DEFAULT){
            if(isPortrait)
                orientation='horizontal';
            else
                orientation='vertical';
        }
        let firstChild = this.getWrappedTable();
        let secondChild = this.getMapView();
        if(view === MAP_ABOVE_REPORT_HORIZONTAL){
            orientation = 'horizontal';
            firstChild = this.getMapView();
            secondChild = this.getWrappedTable();
        }

        if(view === MAP_FULL_SCREEN){
            orientation = 'vertical';
            firstChild = this.getMapView();
            secondChild = this.getWrappedTable();

            //return this.getMapView();
        }

        const resizerHandleStyle = {
            color: (theme && theme.table && theme.table.resizerHandle && theme.table.resizerHandle.color) || "inherit"
        };


        const styles_mobile = {
            tab: {
                width:'50%',
                color:'white',
            },
            parent:{
                display: 'flex',
                width: '100%',
                left:'0em',
                right:'0em',
                top:'0em',
                height: 35,
            },
            container:{
                position:'absolute',
                bottom:'0em',
                left:'0em',
                right:'0em',
                top:'2.2em',
            },
            container_header:{
                position:'absolute',
                width:'100%'
            }
          };
        if (isMobileDevice && isMobile && (view === views.MAP_FULL_SCREEN || view === views.REPORT_VIEW)){        return  (
            <div>
                    <div style={styles_mobile.container_header}>
                        <div style={styles_mobile.parent}>
                            <div style={styles_mobile.tab}>
                                <FlatButton 
                                    startIcon={<ImageGridOn />}
                                    style={{backgroundColor: this.state.showTable?"#ffffff":"#455a64", color: this.state.showTable?"#000":"#fff", height: '100%'}}
                                    fullWidth={true}
                                    onClick={this.tableShowMobile}
                                    variant="text"
                                >Table</FlatButton>
                            </div>
                            <div style={styles_mobile.tab}>
                                <FlatButton 
                                    startIcon={<MapsMap />}
                                    style={{backgroundColor: this.state.showMap?"#ffffff":"#455a64", color: this.state.showMap?'#000':'#fff', height: '100%'}}
                                    fullWidth={true}
                                    onClick={this.mapShowMobile}
                                    variant="text"
                                >Map</FlatButton>
                            </div> 
                        </div>
                    </div>

                    <div style={styles_mobile.container}>
                        {this.state.showMap === true ? this.getMapView() : this.getWrappedTable()}
                    </div>
                </div>
            )
        }

        return  (
            <SplitPane
                defaultSize='50%'
                onChange={this.handleSplitResize}
                resizerHandleStyle={resizerHandleStyle}
                split={orientation}
                maxSize={-MAX_RIGHT_OFFSET}
            >
                {firstChild}
                {secondChild}
            </SplitPane>
        )
    }

}

export default ReportView;
