import React from 'react';
import {useSelector}  from 'react-redux';

const CustomCellLogsHistory = ({itemKey, rowIndex}) => {
    const selectedStatusHistory = useSelector( state => state.deviceManager.logsHistoryData[rowIndex]);

    const getValue = () => {
        let value = selectedStatusHistory;
        const arrayKey = itemKey.split('.');
        for(let index = 0; index < arrayKey.length; index++){
            value = value[arrayKey[index]];
        }

        return value;
    };

    return (
        <div>
            {getValue()}
        </div>
    )
};

export default CustomCellLogsHistory;