import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const StatusDeadIcon = (props) => {

    return (
        <SvgIcon {...props} >
            <path d="M2 12h2.98l1.212-4 1.717 4H22"/>
        </SvgIcon>
    );

};



export default StatusDeadIcon;
