import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {recentMapsAction, selectDevicesMap} from "../../../actions";
import RecentMaps from './RecentMaps';

const RecentMapsContainer = ({setToggleMap}) => {

    const recentMaps = useSelector(state => state.deviceManager.recentMaps);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(recentMapsAction());
    },[dispatch]);

    const selectMap = (mapObj) => {
        dispatch(selectDevicesMap(mapObj));
        setToggleMap()
    };

    return (
        <div className={"recent-maps-container"}>
            <div className={"header-container-title"}><h2>Most recent Maps</h2></div>
            <RecentMaps maps={recentMaps} selectMap={selectMap}/>
        </div>
    )
};

export default RecentMapsContainer;