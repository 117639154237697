import React from 'react';
import PropTypes from 'prop-types';

import equal from 'deep-equal';

import StyledDialog, {CancelButton, SaveButton} from '../../../util/Dialog';
import ProfileManagerForm from './ProfileManagerForm';
import {CALL_FILTER_SCHEMA, MEDIAN_FILTER_SCHEMA, RATE_FILTER_SCHEMA} from './filter-forms/schemas';
import FILTERS from './__tests__/MockProfile';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../../../../actions';
import {ZLA_FILTER_PROFILE_MANAGER} from '../../../../constants/DataEntryForms';

const SCHEMAS = {
    "median":MEDIAN_FILTER_SCHEMA,
    "rate":RATE_FILTER_SCHEMA,
    "call":CALL_FILTER_SCHEMA
};


function validateAgainstSchema(filter,schema){

    if(!filter.enabled) return;

    try{
        schema.forEach(field=>{
            if(field.validate){
                field.validate({
                    values:filter,
                    field:field,
                    value:filter[field.key],
                    label:field.label
                });
            }
        });
    }catch(e){
        // eslint-disable-next-line
        throw `${filter.name} - ${e}`;
    }

}



const MOCK_PROFILES = [
    {
        profileName:"Profile A",
        filters:FILTERS
    },{
        profileName:"Profile B",
        filters:[].concat(FILTERS).reverse()
    },{
        profileName:"Profile C",
        filters:[].concat(FILTERS)
    }
];

class ProfileManagerDialog extends React.Component {

    static get propTypes(){
        return {
            open: PropTypes.bool,
            appliances:PropTypes.array,

            onRequestClose: PropTypes.func.isRequired,
            getFilterProfiles: PropTypes.func.isRequired,
            updateMultipleApplianceFilterProfiles: PropTypes.func.isRequired,
            displayErrorDialog: PropTypes.func.isRequired
        };
    }

    static get defaultProps(){
        return {
            open: false,
            data:{}
        };
    }

    constructor(){
        super();

        this.state = {
            isFetching:true,
            profiles: MOCK_PROFILES,
        };

        this.handleSave = this.handleSave.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount(){
        this.refreshProfiles();
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        this.setState({isFetching:true});
        if(!this.props.open && nextProps.open){
            this.refreshProfiles();
        }
    }

    refreshProfiles(){
        this.props.getFilterProfiles()
            .then(profiles=>{
                this.setState({profiles:profiles,isFetching:false});
            });
    }


    handleClose ()  {
        this.props.onRequestClose();
    }

    handleSave(){
        const data = this.refs.profileForm.getData();
        if(this.validate(data)){
            const {appliances} = this.props;
            let _data = {
                profileName: data.name || data.baseProfile,
                filters: data.filters
            };
            this.props.updateMultipleApplianceFilterProfiles(appliances.map(app=>app._id),_data)
                .then(() =>{
                    this.props.onRequestClose();
                });
        }
    }

    validate(data){
        const {name,baseProfile,filters} = data;
        try{

            if(!name.trim()){

                if(baseProfile==="Default"){
                    // eslint-disable-next-line
                    throw "Please provide a name for the profile.";
                }

                if(this.profileConflictsWithExistingProfiles(baseProfile,filters)){
                    // eslint-disable-next-line
                    throw "Please provide a different name for the profile.";
                }

            }else if(this.profileConflictsWithExistingProfiles(name,filters)){
                // eslint-disable-next-line
                throw "Different profiles cannot share the same name.";
            }

            this.validateFilters(filters);

        }catch(e){
            this.props.displayErrorDialog({
                message: e
            });
            return false;
        }

        return true;
    }

    profileConflictsWithExistingProfiles(name,filters){
        const {profiles} = this.state;
        let profile = profiles.find(p=>p.profileName===name);
        return profile && !equal(profile.filters,filters);
    }

    validateFilters(filters){
        filters.forEach(filter=>{
            if(SCHEMAS[filter.type]){
                validateAgainstSchema(filter,SCHEMAS[filter.type]);
            }
        });
    }

    render() {

        const {profiles,isFetching} = this.state;
        return (
            <StyledDialog
                title="Manage Filter Profiles"
                modal={false}
                open={this.props.open}
                onClose={this.handleClose}
                contentStyle={{maxWidth: 760, minWidth: 600, height: 440}}
                actions={
                    <>
                        <SaveButton onClick={this.handleSave} label="Apply" disabled={isFetching} />
                        <CancelButton style={{position: 'absolute', left: 8, bottom: 8}} onClick={this.handleClose} />
                    </>
                }
                {...this.props}
            >

                <ProfileManagerForm profiles={profiles}  isFetching={isFetching} ref="profileForm" defaultProfile={this.getDefaultProfile()}/>

            </StyledDialog>

        );
    }

    getDefaultProfile(){
        const {profiles} = this.state;
        const {appliances} = this.props;

        if(!profiles.length || !appliances.length) return null;

        let profileName = appliances[0].messageFiltersProfileName, i, l = appliances.length;
        for(i=1;i<l;i++){
            if(appliances[i].messageFiltersProfileName !== profileName) return null;
        }
        return profiles.find(p=>p.profileName===profileName);
    }
}


/*
 ██████  ██████  ███    ██ ███    ██ ███████  ██████ ████████     ████████  ██████
██      ██    ██ ████   ██ ████   ██ ██      ██         ██           ██    ██    ██
██      ██    ██ ██ ██  ██ ██ ██  ██ █████   ██         ██           ██    ██    ██
██      ██    ██ ██  ██ ██ ██  ██ ██ ██      ██         ██           ██    ██    ██
 ██████  ██████  ██   ████ ██   ████ ███████  ██████    ██           ██     ██████

███████ ████████  █████  ████████ ███████
██         ██    ██   ██    ██    ██
███████    ██    ███████    ██    █████
     ██    ██    ██   ██    ██    ██
███████    ██    ██   ██    ██    ███████
*/


const mapStateToProps = state => {
    let formData = state.dataEntry.initialFormData;

    return {
        open: state.dataEntry.open && state.dataEntry.activeForm === ZLA_FILTER_PROFILE_MANAGER,
        appliances: (formData && formData.appliances) || []
    };
};

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        onRequestClose: actions.closeDataEntry,
        displayErrorDialog: actions.displayErrorDialog,
        updateMultipleApplianceFilterProfiles: actions.updateMultipleApplianceFilterProfiles,
        getFilterProfiles: actions.getFilterProfiles
    },dispatch)
});

export default connect(mapStateToProps,mapDispatchToProps)(ProfileManagerDialog);
