import React from 'react'
import {useSelector, useDispatch} from 'react-redux';
import {RadioGroup, FormControlLabel, Radio, } from '@material-ui/core';
import {useStyles} from './utils/utils';
import {updateStateFormTemplate} from "../../../../actions";
import Tooltip from "@material-ui/core/Tooltip";

const ProtocolPanel = () => {

    const protocol = useSelector(state => state.deviceManager.templateForm.template.protocol);
    const protocolValidations = useSelector(state => state.deviceManager.needsValidationFields.protocol);
    const dispatch = useDispatch();

    const classes = useStyles();
    const handleChange = (event) => {
        const key = event.target.name;
        dispatch(updateStateFormTemplate(key, null, event.target.value))
    };

    return (
        <div style={{padding:'0 0.3em'}}>
            <div className="device-items" style={{width: '100%'}}>
                <Tooltip open={protocolValidations['protocol'].error }
                         arrow={true} title={
                    protocolValidations['protocol'].error ? protocolValidations['protocol'].message : ''
                } placement={"right"}
                         PopperProps={{disablePortal: true}}
                         classes={{tooltip: classes.tooltip, arrow: classes.arrow, popper: classes.popper}}>
                <div className="basic-input" style={{margin:'0 -0.5em'}}>
                    <div className="field">
                        <span className="items-header" style={{paddingBottom: 0}}>Reader Management Communication</span>
                        <RadioGroup value={protocol || ''} onChange={handleChange} style={{display: 'flex', flexDirection:'row', padding:'0 0.1em'}} name="protocol">
                            <FormControlLabel
                                value="mqtt" control={<Radio classes={{checked: classes.checked}} style={{color: '#000', backgroundColor:'transparent'}} size={"small"}/>}
                                label={"Direct with Readers"} />
                            <FormControlLabel
                                value="https" control={<Radio classes={{checked: classes.checked}} style={{color: '#000', backgroundColor:'transparent'}} size={"small"}/>}
                                label={"via Zebra Data Service"} />
                        </RadioGroup>
                    </div>
                </div>
                </Tooltip>
                <div className="basic-input" style={{margin:'0 -0.5em'}}>
                    <div className="field" style={{width:'410px'}}>
                        <span className="items-header" style={{paddingBottom:'13px'}}>Tag Data URL</span>
                        <input type={"text"}
                               placeholder={"N/A"}
                               name={'url'}
                               value={''}
                               disabled/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProtocolPanel;