import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from 'react-redux';
import {
    updateStepAddDevices,
    cleanDevicesBatch,
    addNewDevices
} from "../../../actions";
import {useHistory} from 'react-router-dom';
import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import CrossBrowserTable from '../common/tableComponent/CrossBrowserTable';
import r2cdevicePayload from '../../../constants/r2cdevicePayload';
import rfidAntenna from '../../../constants/rfidAntenna';
import SelectTemplateDialog from '../common/dialogs/SelectTemplateDialog';
import {NUMBER_ANTENNAS, MAX_LENGTH_DEVICE_TITLE} from '../../../constants/DeviceManager';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from "moment";
import DialogConfirm from '../common/dialogs/DialogConfirm';

function ProvideDeviceDetails(props){
    const dispatch = useDispatch();
    const history = useHistory();
    const deviceTypes = useSelector(state => state.deviceManager.deviceTypes);
    const actualStep = useSelector(state => state.deviceManager.actualStep);
    const devicesBatch = useSelector(state => state.deviceManager.devicesBatch);
    const responseMessagesBatch = useSelector(state => state.deviceManager.messagesBatch);
    const mapSelected = useSelector(state => state.deviceManager.mapSelected);
    // const rfidConfigurationType = useSelector(state => state.deviceManager.rfidConfigurationType);
    const modernBrowser = useSelector(state => state.user.modernBrowser);
    const userName = useSelector(state => state.user.username);
    const [listDevices, setListDevices] = useState(devicesBatch.length>0? devicesBatch:getListDevices);
    const [numberInput, setNumberInput] = useState(null);
    const [itemSelected, setItemSelected] = useState(null);
    const [isSaved, setIsSaved] = useState(false);
    const [openCancelDialog, setOpenCancelDialog] = useState(false);
    const [rowsValidation, setRowsValidation] = useState([]);
    const [messagesBatch, setMessagesBatch] = useState({});
    useEffect(updateRowsValidation,[responseMessagesBatch]);

    useEffect(() => {
        if (numberInput){
            const [number, key] = numberInput.split('-');
            document.getElementById(`input-${key}-${number}`).value = listDevices[parseInt(number)][key];
            document.getElementById(`input-${key}-${number}`).focus();
            setNumberInput(null);
        }
    },[listDevices, numberInput]);

    function updateRowsValidation(){
        let disabledRows = listDevices.reduce((acc, item, index) => ({...acc, [index]:{disabled: false}}), {});
        Object.keys(responseMessagesBatch).forEach(position => {
            if(responseMessagesBatch[position].status === 'SUCCESS'){
                disabledRows[position].disabled = true;
            }
        });
        setRowsValidation(Object.keys(disabledRows).map(key => disabledRows[key]));
        setMessagesBatch(m => ({...m, ...responseMessagesBatch}));
    }
    
    function getListDevices(){
        let listItems = [];
        let siteName = (mapSelected && mapSelected.siteId) ? `${mapSelected.siteName}-`:'';
        let mapID = (mapSelected && mapSelected.id) ? `${mapSelected.id}`:'';
        // let counter = numberDevices + 1;
        for(let device in deviceTypes){
          for (let model in deviceTypes[device].models){
            if(deviceTypes[device].models[model].number>0){
              let modelItem = deviceTypes[device].models[model];
              let x=1;
              while(x<=modelItem.number){
                // const currentDate=moment().format('YYMMDDHHmmss');
                const currentDate=moment().format('YY-DD-HHmmss');

                let item = {...r2cdevicePayload};
                // item.deviceId = counter;
                item.deviceType = deviceTypes[device].title;
                item.rfidType = modelItem.label;
                // item.dateInstalled = currentDate;
                item.title = siteName + modelItem.label + '-' + currentDate+x;
                //extra field
                item.template = "";
                item.configuration_state = "";
                item.status = "";
                item.protocol = "mqtt";
                if(mapSelected && mapSelected.siteId){
                    item.siteId = mapSelected.siteId;
                    item.location = {...item.location, mapID: mapID}
                }
                item.numberAntennaPorts = 0;
                listItems.push(item);
                x++;
                // counter++;
              }
            }
          }
        }
        return listItems;
    }

    const templateStyle =  {textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'no-wrap'};

    const headerRow = [{key:'deviceNumber',value:'#',width: 40,clickable:true},
        {key:'deviceModel',value:'Device type and model',width: 170,clickable:true},
        {key:'deviceName',value:'Device name',width: 260,clickable:false},
        {key:'deviceHostname',value:'Hostname *',width: 160,clickable:false},
        {key:'deviceTemplate',value:'Template',width: 200,clickable:false},
        {key:'deviceAntennaPorts',value:'# Antenna Ports',width: 120,clickable:false},
        {key:'deviceEnabledPorts',value:'Enabled ports for data collection',width: 220,clickable:false},
        {key:"deviceStatus",value:"Import Status",clickable:false,width:100},
        {key:"deviceMessage",value:"Message",clickable:false, width:560}];
        
    function nextButton(){
        dispatch(updateStepAddDevices(actualStep + 1));
    }

    function saveButton(){
        let devices = [];
        listDevices.forEach((device, index) => {
            if(!rowsValidation[index].disabled){
                devices.push({...device, _id: index, createBy: userName, title: device.title.trim(), ipAddress: device.ipAddress.trim()});
            }
        })

        dispatch(addNewDevices(devices));
        setIsSaved(true);
    }

    function confirmCancel(){
        setOpenCancelDialog(false);
        dispatch(updateStepAddDevices(actualStep - 1));
        dispatch(cleanDevicesBatch());
        if(isSaved){
            history.replace('/device-manager/devices');
        }
    }

    function updateItem(index, key, value){
        let listDevicesUpdated = [...listDevices];
        listDevicesUpdated[index][key] = value; 
        setListDevices(listDevicesUpdated);
    }

    function handleChangeHostname(index, key, value){
        let listDevicesUpdated = [...listDevices];
        listDevicesUpdated[index][key] = value; 
        setListDevices(listDevicesUpdated);

        //checking duplicated host names
        let updateMessagesBatch = {...responseMessagesBatch};
        let listIPAdress = listDevicesUpdated.map(item => item.ipAddress);
        let listAllowedIPAddress = listIPAdress.filter(x => listIPAdress.indexOf(x) === listIPAdress.lastIndexOf(x));

        listDevicesUpdated.forEach((device, index) => {
            if(!rowsValidation[index].disabled && device.ipAddress!=="" && listAllowedIPAddress.indexOf(device.ipAddress) === -1){
                updateMessagesBatch[index] = {status: 'FAILED', text: 'Duplicated Hostname'};
            }
        });
        
        setMessagesBatch(updateMessagesBatch);
    }

    function changeAntennasNumber(rowIndex, value){
        updateItem(rowIndex, "numberAntennaPorts", Number(value));

        // declarating antennas
        let arrayAntennas = [];
        let i = 0;
        while(i < Number(value)){
            i++;
            let antenna = {...rfidAntenna};
            antenna.id = Number(i);
            antenna.title = `Antenna ${i}`;
            if(mapSelected && mapSelected.id){
                antenna.location = {...antenna.location, 
                    mapID: `${mapSelected.id}`
                };
            }
            arrayAntennas.push(antenna);
        }
        updateItem(rowIndex, "rfidAntennas", arrayAntennas);
    }

    const clearInputTable = (rowIndex, key) => {
        updateItem(rowIndex, key,"");
        setNumberInput(`${rowIndex}-${key}`);
    }

    const styleInputText = {paddingTop: '1px',paddingBottom:'1px',border:'0.5px solid #aaaaaa'};
    function customCellDevice ({rowIndex, headerKey}) {        
        let content = null;
        switch(headerKey){
            case 'deviceNumber':
                content = rowIndex + 1;
            break;
            case 'deviceModel':
                content = listDevices[rowIndex].deviceType + ' - ' +listDevices[rowIndex].rfidType;
            break;
            case 'deviceName':
                {
                    const value = listDevices[rowIndex].title;
                    content = (
                        <InputBase id={`input-title-${rowIndex}`} style={styleInputText} fullWidth defaultValue={value} 
                            inputProps={{maxLength: MAX_LENGTH_DEVICE_TITLE}}
                            onBlur={e=>updateItem(rowIndex, "title", getTextLabel(e.target.value, MAX_LENGTH_DEVICE_TITLE))} 
                            endAdornment = {modernBrowser ?                                       
                                <InputAdornment style={{cursor:'pointer'}} onClick={()=>clearInputTable(rowIndex, 'title')}>
                                    <ClearIcon />
                                </InputAdornment> : null
                            }
                        />
                    );
                }
            break;
            case 'deviceHostname':
                {
                    const value = listDevices[rowIndex].ipAddress;
                    content = (
                        <InputBase id={`input-ipAddress-${rowIndex}`} style={styleInputText} fullWidth defaultValue={value} 
                            onChange={e=>handleChangeHostname(rowIndex, "ipAddress", e.target.value)} 
                            endAdornment = {modernBrowser ?                                       
                                <InputAdornment style={{cursor:'pointer'}} onClick={()=>clearInputTable(rowIndex, 'ipAddress')}>
                                    <ClearIcon />
                                </InputAdornment> : null
                            }
                        />
                    );
                }
            break;
            case 'deviceTemplate':
                {
                    const value = listDevices[rowIndex].template;
                    const styleDelete = value === "" ? {pointerEvents:'none', opacity:'0.75'}:{cursor:'pointer', pointerEvents:'auto', opacity:'1'}
                    content = (
                        <div style={{display:'flex'}}>
                            <div style={{...templateStyle, flex: 1}}>
                                <span>{value && value.length>0 ? value : 'No Template'}</span> &nbsp;
                            </div>
                            <div style={{flex: '0 0 50px', display:'flex', alignItems: 'center'}}>
                                <EditIcon style={{cursor:'pointer'}} onClick={()=>setItemSelected(rowIndex)} />
                                <DeleteIcon style={styleDelete} onClick={()=>deleteTemplate(rowIndex)}/>
                            </div>
                        </div>
                    );
                }
            break;
            case 'deviceAntennaPorts':
                {
                    const value = listDevices[rowIndex].numberAntennaPorts;
                    const rfidTypeDevice = listDevices[rowIndex].rfidType;
                    const styleDiv = listDevices[rowIndex].template.length>0?{pointerEvents:'none',opacity:'0.75'}:{pointerEvents:'auto',opacity:'1'};
                    content = <div style={styleDiv}>
                        <select disabled={listDevices[rowIndex].rfidType.indexOf('ATR') > -1} value={value} onChange={e => changeAntennasNumber(rowIndex, e.target.value)} style={{width:'112px'}}>
                            <option value={0} style={{display:'none'}}></option>
                            {NUMBER_ANTENNAS[rfidTypeDevice].map((v,i) => <option key={i} value={v}>{v}</option>)}
                        </select>
                    </div>
                }
            break;
            case 'deviceEnabledPorts':
                {
                    const rfidAntennas = listDevices[rowIndex].rfidAntennas;
                    content = <EnabledPorts rfidAntennas={rfidAntennas} rowIndex={rowIndex} updateItem={updateItem} disabled={listDevices[rowIndex].rfidType.indexOf('ATR') > -1}/>;
                }
            break;
            case "deviceStatus":
                content = messagesBatch[rowIndex] ? renderResponse(messagesBatch[rowIndex].status) : '';
            break;
            case "deviceMessage":
                content = messagesBatch[rowIndex] ? messagesBatch[rowIndex].text : '';
            break;
            default:
                content = '';
            break
        }
        return (
            <React.Fragment>    
                {content}      
            </React.Fragment>
        );
    }

    const getTextLabel = (textValue, maxLength) => {
        return textValue.substr(0, maxLength);
    }

    const renderResponse = (status) => {
        let styleResponse = {backgroundColor: status === 'SUCCESS' ? '#009c34': '#f02618', color: '#fff', textAlign: 'center'};
        return <div style={styleResponse}>{status}</div>
    }

    function deleteTemplate(rowIndex){
        let objectDevice = listDevices[rowIndex];
        let {deviceType, rfidType, title, siteId, location} = objectDevice;
        let deviceUpdated = {...r2cdevicePayload, deviceType, rfidType, title, siteId, location, template: ""};
        setListDevices(listDevices.map((item, index)=> index === rowIndex ? {...deviceUpdated} :item));
    }

    function handleSelectTemplate(objectTemplate){
        if(objectTemplate){
            let objectDevice = objectTemplate.template;
            objectDevice.template = objectTemplate.title;
            objectDevice.templateId = objectTemplate._id;
            //ignoring some fields at the moment to apply a template in a device
            const {_id, rfidType, title, description, deviceType, orderNumber, siteId, location, ipAddress, macAddress, ...rest} = objectDevice;
            const rfidAntennasUpdate = rest.rfidAntennas.map((antenna, index) => {
                return {...rfidAntenna, id:antenna.id, title: antenna.title ,disabled: antenna.disabled}
            })
            setListDevices(listDevices.map((item, index)=>index===itemSelected?{...item, ...rest, rfidAntennas: rfidAntennasUpdate} :item));
        }else{
            const currentDevice = listDevices[itemSelected];
            const currentItem = {deviceType: currentDevice.deviceType,
                    rfidType: currentDevice.rfidType,
                    title: currentDevice.title,
                    template: "",
                    configuration_state: "",
                    status: "",
                    siteId: currentDevice.siteId,
                    location: currentDevice.location,
                    rfidAntennas: [...currentDevice.rfidAntennas]
                }
            setListDevices(listDevices.map((item, index)=>index===itemSelected?{...r2cdevicePayload, ...currentItem}:item));
        }
        closeDialog();
    }
    
    function closeDialog(){
        setItemSelected(null);
    }

    function showSaveButton(){
        if(!isSaved){
            return true;
        }else{
            let value = false;
            rowsValidation.forEach(rows => {
                if(!rows.disabled){
                    value = true;
                }
            })
            return value;
        }
    }

    function showContinueButton(){
        return rowsValidation.filter(row => row.disabled === true).length > 0;
    }

    function showContinueLabel(){
        return rowsValidation.filter(row => row.disabled === true).length === listDevices.length;
    }

    // const disabledSave = listDevices.filter(item => item.ipAddress === "" || item.rfidAntennas.length === 0).length>0;
    const disabledSave = listDevices.filter(item => item.ipAddress === "").length>0;
    const cancelMessage = `Do you want to cancel the operation? \n There are ${rowsValidation.filter(row => row.disabled === false).length} devices not saved yet`;
    return (
        <React.Fragment>
            <div className={"container-body"}>
                <CrossBrowserTable
                    rowsValidationError={rowsValidation}
                    rowCount={listDevices.length}
                    showCheckColumn={false}
                    headerRow={headerRow}
                    cellRender={customCellDevice}
                    selectedRows={{}}
                    modernBrowser={true}
                    appliedFilters={()=>null}
                />
            </div>                   

            <div className={"container-step-content-buttons"}>
                {showContinueLabel()?<Button className={'cancel-button'} style={{marginRight:'8px', padding: '10px'}} onClick={() => history.replace('/device-manager/devices')} component="span">Back to Landing Page</Button>:
                <Button className={'cancel-button'} style={{marginRight:'8px'}} onClick={() => setOpenCancelDialog(true)} component="span">Cancel</Button>}
                
                <div style={{display:'flex',justifyContent:'space-between',maxWidth:'270px'}}>
                    {showSaveButton() && 
                    <Button disabled={disabledSave} className={disabledSave ? 'next-step-button next-step-button-disabled':'next-step-button'} 
                        style={{padding:'10px 1em'}} onClick={saveButton} component="span">Save</Button>}
                    {showContinueButton() && 
                    <Button className={'next-step-button'} style={{padding:'10px 1em', marginLeft:'6px'}} onClick={nextButton} component="span">
                        {showContinueLabel() ? 'Continue': 'Skip failed and continue'}
                    </Button>}
                </div>
            </div>

            {itemSelected!==null && <SelectTemplateDialog 
                nameTemplate = {listDevices[itemSelected].template}
                selectTemplate={handleSelectTemplate} 
                open={itemSelected!==null} 
                closeDialog={closeDialog} />}

            {openCancelDialog && <DialogConfirm 
                open={openCancelDialog}
                closeDialog={() => setOpenCancelDialog(false)}
                actionSuccess={confirmCancel}
                actionCancel={()=>{}}
                message={cancelMessage}
            />}
        </React.Fragment>
    )
}

export default ProvideDeviceDetails;

export const EnabledPorts = (props) => {
    const {updateItem, rowIndex, rfidAntennas} = props;
    const styleContainer = {display:'flex', flexDirection: 'row', flexWrap: 'wrap', height:'100%', alignItems: 'center'};
    const styleLabel = {lineHeight: '13px', display: 'inline-block', paddingRight: '2px'};

    const updateAntennaState = (indexAntenna, value) => {
        let updatedRfidAntennas = [...rfidAntennas];
        updatedRfidAntennas[indexAntenna].disabled = !value;
        updateItem(rowIndex, 'rfidAntennas', updatedRfidAntennas);
    }
    return <div style={styleContainer}>
        {rfidAntennas.map((antenna, index) => 
            <label key={index} style={styleLabel}>
                <span style={{verticalAlign: 'middle', fontSize: '11px'}}>{antenna.id}</span>
                <input disabled={props.disabled} style={{verticalAlign: 'middle'}} type="checkbox" checked={!Boolean(antenna.disabled)} onChange={e => updateAntennaState(index, e.target.checked)} />
            </label>)}
    </div>
}