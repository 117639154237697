import React, {useState, useEffect, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import {Button, DialogActions, DialogTitle} from '@material-ui/core';
import {DialogContent} from '@material-ui/core/es';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import {
    setConfigurationDevices,
    setDownloadError,
    setLogConfigurationError,
    clearDownloadedLogs
} from "../../../../actions";
import LogDownloadComponent from "./logsComponents/LogDownloadComponent";
import LogHistoryComponent from "./logsComponents/LogHistoryComponent";
import LogSettingsComponent from "./logsComponents/LogSettingsComponent";
import ErrorAlert from "./logsComponents/ErrorAlert";
import {OPE_STATUS_FAILURE, OPE_STATUS_NA} from "../../../../constants/DeviceManager";
import WarningAlert from "./logsComponents/WarningAlert";

const CONFIGURATION_LOGS_TITLE = "Device Logs";



const DialogLogSettings = ({open, handleClose, devicesArray, value, isMultiselect, logOptions}) => {

    const [devices, setDevices] = useState([]);
    const [devicesToSend, setDevicesToSend] = useState([]);
    const [tabValue, setTabValue] = useState(value);
    const [warningOpen, setWarningOpen] = useState(false);
    const [warningMessage, setWarningMessage] = useState("");

    const {error, message} = useSelector(state => state.deviceManager.errorLogConfiguration);
    const logsSavedSuccessfully = useSelector(state => state.deviceManager.logsSavedSuccessfully);
    const fetchingLogsSave = useSelector(state => state.deviceManager.fetchingLogsSave);
    const errorDownload = useSelector(state => state.deviceManager.errorDownload);

    const dispatch = useDispatch();

    const isAvailableToSend = (device) => {
            return !(device.status?.title===OPE_STATUS_NA||
                device.status?.title===''||
                device.status?.title==null||
                device.status?.title===OPE_STATUS_FAILURE);
    }

    const handleLoadDevices = () => {
        const devicesToAdd = devicesArray && devicesArray.map((item) => item._id);
        setDevices(devicesToAdd);
        let devicesToSendArr = [];
        if(isMultiselect) {
            devicesToSendArr = devicesArray && devicesArray.filter(item => isAvailableToSend(item)).map(item => item._id);
        } else {
            devicesToSendArr = devicesArray && devicesArray.map(item => item._id);
        }
        setDevicesToSend(devicesToSendArr)

        return () => {
            dispatch(clearDownloadedLogs())
        }
    };

    useEffect(handleLoadDevices, []);

    const handleCloseDialog = () => {
        if(logsSavedSuccessfully){
            handleClose();
        }
    };

    useEffect(handleCloseDialog, [logsSavedSuccessfully]);


    const handleSaveLogConfiguration = () => {
        if(devicesArray.length === 1 && (devicesArray[0].status?.title===OPE_STATUS_NA||
            devicesArray[0].status?.title===''||
            devicesArray[0].status?.title==null||
            devicesArray[0].status?.title===OPE_STATUS_FAILURE)){
            setWarningMessage(`The actual status of device is ${devicesArray[0].status?.title}. Are you sure to continue?`);
            setWarningOpen(true);
        } else {
            dispatch(setConfigurationDevices(devicesToSend));
        }
    };

    const handleCloseErrorDialog = () => {
        dispatch(setLogConfigurationError(false, null));
    };

    const handleCloseDownloadErrorDialog = () => {
        dispatch(setDownloadError(false, null));
    };

    const handleCloseWarningDialog = () => {
        setWarningOpen(false)
    };

    const handleContinueAction = () => {
        dispatch(setConfigurationDevices(devicesToSend));
        setWarningOpen(false);
    };

    // const optionsDisable = getSubMenuOptions(devicesArray[0]);
    const optionsDisable = logOptions;

    const a11yProps = (index) => {
        return {
            // id: `nav-tab-${index}`,
            // 'aria-controls': `nav-tabpanel-${index}`,
        };
    };

    const LinkTab = (props) => {
        return (
            <Tab
                component="span"
                {...props}
            />
        )
    };

    const TabPanel = useCallback(({children, value, index, ...other}) => {
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`nav-tabpanel-${index}`}
                aria-labelledby={`nav-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box style={{padding: '10px 0px'}} p={3}>
                        <Typography style={{display:'flex', flexDirection:'column'}} component='div'>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }, []);

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    const tabStyles = {

        active_tabStyle:{
            color: 'white',
            backgroundColor: '#007cb0',
        },

        default_tabStyle:{
            color: 'black',
            backgroundColor: 'white',
        }
    };

    const theme = createMuiTheme({
        overrides: {
            MuiAppBar: {
                colorPrimary: {
                    backgroundColor: 'white'
                }
            }
        }
    });

    const getStyle = (isActive) => {
        return isActive ? tabStyles.active_tabStyle : tabStyles.default_tabStyle
    };

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
            >
                <DialogTitle style={{paddingRight: 50}}>
                    <div style={{fontWeight:'300',fontSize:'0.9em'}}>
                        {CONFIGURATION_LOGS_TITLE}
                    </div>
                </DialogTitle>
                <DialogContent style={{maxHeight: '475px', overflow:'hidden'}}>
                    <MuiThemeProvider theme={theme}>
                    <div style={{width: 910,display:'inline-block',textAlign:'left', height: 'auto'}}>
                        <AppBar position="static">
                            <Tabs
                                variant="fullWidth"
                                value={tabValue}
                                // aria-label="nav tabs example"
                                onChange={handleChangeTab}
                                TabIndicatorProps={{style:{backgroundColor: '#007cb0'}}}
                            >
                                <LinkTab style={getStyle(tabValue === 0)} label="Settings" {...a11yProps(0)}/>
                                <LinkTab style={getStyle(tabValue === 1)} label="Download" {...a11yProps(1)}/>
                                {!isMultiselect && <LinkTab disabled={!optionsDisable.historical} style={getStyle(tabValue === 2)} label="History" {...a11yProps(2)}/>}
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <LogSettingsComponent
                                devicesArray={devicesArray}
                                isMultiselect={isMultiselect}
                                disabled={!optionsDisable.configureLogs}
                            />
                        </TabPanel>

                        <TabPanel value={tabValue} index={1}>
                            <LogDownloadComponent devicesArray={devicesArray} disabled={!optionsDisable.downloadLogs}/>
                        </TabPanel>

                        {!isMultiselect &&
                            <TabPanel value={tabValue} index={2}>
                                <LogHistoryComponent deviceId={devicesArray[0]._id}/>
                            </TabPanel>
                        }
                    </div>
                    </MuiThemeProvider>
                </DialogContent>
                <DialogActions style={{padding: '10px 30px'}}>
                    {tabValue === 0 && isMultiselect && (devicesToSend.length < devices.length) ? <span style={{
                        left: '30px',
                        position: 'absolute',
                        fontSize: '0.8em',
                        fontStyle: 'italic'}}>{devicesToSend.length === 0 ? "*Cannot configure any device. Please select available devices." : "*Devices on grey color are not available to configure."}</span> : null}
                    {tabValue === 0 && <Button disabled={(devicesArray.length === 1 && fetchingLogsSave[devicesArray[0]._id]) || devicesToSend.length === 0}
                                               style={{
                                                   background: (devicesArray.length === 1 && fetchingLogsSave[devicesArray[0]._id]) || devicesToSend.length === 0 ? '#81b2c7' : '#007CB0',
                                                   color: '#fff', padding: '0.5em',
                                                   textTransform: 'none'
                                               }} onClick={handleSaveLogConfiguration} component="span">
                        Publish Log Setting
                    </Button>}
                    <Button style={{background: '#fff', color: '#007CB0', padding: '0.5em', textTransform: 'none'}} onClick={handleClose} component="span">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            {error && <ErrorAlert open={error} message={message} closeAlert={handleCloseErrorDialog} width={360}/>}
            {warningOpen && <WarningAlert open={warningOpen} message={warningMessage} closeAlert={handleCloseWarningDialog} continueAction={handleContinueAction}/>}
            {errorDownload.error && <ErrorAlert open={errorDownload.error} message={errorDownload.message} closeAlert={handleCloseDownloadErrorDialog}/>}
        </React.Fragment>
    )
};

export default DialogLogSettings;