/* jshint esversion: 6 */

import React from 'react';
import PropTypes from 'prop-types';

import * as ZMap from './../../../../../common/app/views/map/ZMap';
//import ZoneLayer from './layers/ZoneLayer';
import TagPathLayer from './layers/TagPathLayer';
import Label from './misc/Label';
//openlayers
import {getCenter} from 'ol/extent';
import Projection from 'ol/proj/Projection';
import XYZ from 'ol/source/XYZ';
//import TagClusterLayer from './layers/TagClusterLayer';
//import TagLabelLayer from './layers/TagLabelLayer';
//import ZoneLabelLayer from './layers/ZoneLabelLayer';

const {
    Map,
    TileLayer,
    Overlay,
    OverlayGroup
} = ZMap;



//----------------------

//----------------------
// State (flux-y)
let activeLayers = {
    localMap: true,
    zones: true,
    zoneLabels: false,
    tags:true,
    tagLabels: false,
    tagClusters:false
};


let ttx = 0, tty = 0, tttext = "", ttactive = false, hoveredResource = null;

function getStateFromStores(){
    return {
        activeLayers: activeLayers,
        tooltip:{
            ttx:ttx,
            tty:tty,
            tttext:tttext,
            ttactive: ttactive
        },
        hoveredResource:hoveredResource
    };
}

const CANVAS_BRIGHTEN = (img) => {
    const MINIMUM_BRIGHTNESS = 150;

    const convert = (value) => Math.floor(((255-MINIMUM_BRIGHTNESS)*value)/255)+MINIMUM_BRIGHTNESS;

    let i,l=img.data.length;
    for(i=0;i<l;i+=4){
        img.data[i] = convert(img.data[i]);
        img.data[i+1] = convert(img.data[i+1]);
        img.data[i+2] = convert(img.data[i+2]);
    }

    return img;
};

const CANVAS_COPY = (img,original)=>{
    let i,l=img.data.length;
    for(i=0;i<l;i++){
        img.data[i] = original.data[i];
    }

    return img;
};

const CANVAS_GRAY = (img) => {
    let i,l=img.data.length;

    for(i=0;i<l;i+=4){
        let r = img.data[i],
            g = img.data[i+1],
            b = img.data[i+2];

        let grey = Math.round(0.2126*r + 0.7152*g + 0.0722*b);//Math.round((r+g+b)/3);

        img.data[i] = grey;
        img.data[i+1] = grey;
        img.data[i+2] = grey;
    }
    return img;
};


// ---------------------
class GreyFilter extends React.Component{

    static get propTypes(){
        return {
            map: PropTypes.any,
            fadeAfterLayer: PropTypes.number
        };
    }

    static get defaultProps(){
        return {
            fadeAfterLayer:0
        };
    }

    constructor(props){
        super(props);

        this.map = props.map;

        this.handleAfterRender = this.handleAfterRender.bind(this);

    }


    componentDidMount(){
        try{
            this.map.getLayers()
                .getArray()[this.props.fadeAfterLayer]
                .on('postcompose',this.handleAfterRender);

        }catch(e){
            console.log("Failed to attach postcompose listener:",e); // eslint-disable-line
        }
    }

    componentWillUnmount(){
        if(this.props.fadeAfterLayer)
            this.map.getLayers()
                .getArray()[this.props.fadeAfterLayer]
                .un('postcompose',this.handleAfterRender);
    }

    handleAfterRender({context}){

        const cxt = context;

        const canvas = cxt.canvas;

        const height = canvas.height;
        const width = canvas.width;

        try{
            let img_data = cxt.getImageData(0,0,width,height);
            let copy = cxt.createImageData(width,height);

            copy = CANVAS_COPY(copy,img_data);
            img_data = CANVAS_BRIGHTEN(CANVAS_GRAY(copy));
            cxt.putImageData(copy,0,0);

        }catch(e){
            console.warn("grey filter failed"); // eslint-disable-line
        }
    }

    render(){
        return (<div/>);
    }
}

export {GreyFilter};


//----------------------

export default class PlaybackMap extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            url:"",
            wrapX:false,
            maxZoom:7,
            projection: new Projection({
                code:'local-map',
                units:'pixels',
                extent:this.props.bounds
            }),
            activeLayers: getStateFromStores().activeLayers,
            animations: getStateFromStores().animations,
            tooltip:{
                ttx:ttx,
                tty:tty,
                tttext:tttext,
                ttactive: ttactive
            },
            hoveredResource:null
        };


        this._onChange = this._onChange.bind(this);

        this.handlePointerMove = this.handlePointerMove.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    _onChange(){
        this.setState(getStateFromStores());
    }

    static get propTypes(){
        return {
            style: PropTypes.object,
            children: PropTypes.any,
            bounds: PropTypes.array,
            tilePrefix: PropTypes.string,
            zones: PropTypes.array,
            tags: PropTypes.array,
            onHoverFeature: PropTypes.func,
            onSelectFeature: PropTypes.func,
            startIndex: PropTypes.number,
            //endIndex:PropTypes.number,
            showPath:PropTypes.bool,
            currentTime:PropTypes.number
        };
    }

    static get defaultProps(){
        const nop = function(){};
        return {
            onHoverFeature: nop,
            onSelectFeature: nop,
            startIndex:0,
            endIndex:0
        };
    }

    handlePointerMove(event,hoveredFeatures){

        // tooltip coordinates
        ttx = event.coordinate[0];
        tty = event.coordinate[1];

        if(hoveredFeatures.length > 0){
            if(hoveredFeatures.length > 1){
                // I'll figure out the logic going forward...
                // eslint-disable-next-line
                console.log("I dunno what to do with multiple..."); // eslint-disable-line
            }

            let data = hoveredFeatures[0].get('data');
            this.props.onHoverFeature(data);

            hoveredResource = data.raw.___uid;
            tttext = data.name;
        }else{
            ttactive = false;
            hoveredResource = null;
            this.props.onHoverFeature(null);
        }

        // trigger change.
        this._onChange();
    }

    handleClick(event,clickedFeatures){
        if(clickedFeatures.length > 0){
            let i,l= clickedFeatures.length;
            for(i=0;i<l;i++){
                this.props.onSelectFeature(clickedFeatures[i].get('data'));
            }
        }
    }

    renderLocalMap(){

        const tileUrlFunction = (coord)=>{
            let _z = coord[0]+2;
            let _x = coord[1];
            // let _y = -coord[2] -1;
            let _y = coord[2];

            var url = this.props.tilePrefix;
            url += (_z) + "_";
            url += (_y) + "_";
            url += (_x) + ".png";
            return url;
        };

        const tileSource = new XYZ({
            tileUrlFunction: tileUrlFunction,
            tileSize:[1024,1024],
            wrapX:false,
            maxZoom:1,
            projection: this.state.projection
        });

        return (this.state.activeLayers.localMap) ? (
            <TileLayer
                source={tileSource}
                projection={this.state.projection}
            />
        ) : null;
    }

    render(){

        const {startIndex,showPath,...other} = this.props;

        const styles = {
            map:{
                height:'100%',
                width:'100%'
            }
        };

        let tt = this.state.tooltip;


        var toolTip = (
            <Overlay
                key={"tooltip"}
                position={[tt.ttx,tt.tty]}
                positioning={"bottom-center"}
            >
                <Label style={{
                    backgroundColor:'white'
                }}>{tt.tttext}</Label>
            </Overlay>
        );



        let localMapLayer = this.renderLocalMap();

        /*

            <ZoneLayer zones={this.props.zones} active={this.state.activeLayers.zones}/>
            <ZoneLabelLayer zones={this.props.zones} active={this.state.activeLayers.zoneLabels}/>
            <TagClusterLayer tags={this.props.tags} active={this.state.activeLayers.tagClusters}/>
            <TagLabelLayer tags={this.props.tags} active={this.state.activeLayers.tagLabels}/>
         */

        const tags =  this.props.tags.map(v=>({
            ...v,
            selected:v.raw.___uid === this.state.hoveredResource
        }));

        return (
            <Map
                {...other}
                style={styles.map}
                center={getCenter(this.props.bounds)}
                zoom={1}
                projection={this.state.projection}
                onPointerMove={this.handlePointerMove}
                onClick={this.handleClick}
            >

                {localMapLayer}


                <TagPathLayer tags={tags} active={this.state.activeLayers.tags} startIndex={startIndex} endTime={this.props.currentTime} showPath={showPath}/>
                <GreyFilter fadeAfterLayer={0}/>

                <OverlayGroup>
                    {tt.ttactive ? toolTip : null}
                </OverlayGroup>



            </Map>
        );
    }
}


const ProppedPlaybackMap = (props) => (
    <div className="vss-map">
        <PlaybackMap
            tilePrefix={"data/tiles/mapHS_"}
            bounds={[-5.293979190815696,-184.14001007967576,187.18711327525688,8.341082386396836]}
            zones={[]}
            tags={[]}
            {...props} />
    </div>
);

export {ProppedPlaybackMap};
