import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import {ListItemIcon, Popover} from '@material-ui/core';
import SortedChecklist from '../../util/SortedChecklist';

import ViewColumnIcon from '@material-ui/icons/ViewColumn';

import AddIcon from '@material-ui/icons/Add';
import UpdateIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsIcon from '@material-ui/icons/Settings';
import ServicesIcon from '@material-ui/icons/NetworkCheck';
//import DownloadIcon from 'material-ui/svg-icons/file/file-download';
import DownloadIcon from '@material-ui/icons/GetApp';
import RefreshIcon from '@material-ui/icons/Refresh';
import LogIcon from '@material-ui/icons/Build';

import * as FORMS from '../../../constants/DataEntryForms';

import {ListItemText, MenuItem} from '@material-ui/core/es/index';
import IconFlatButton from './IconFlatButton';
import {generateComponentId} from "../../../utils/ComponentUtils";


const ClassedIconButton = (props) =>{
    return (
        <IconButton className="icon-button" {...props} />
    );
};

class MoreActionsMenuItem extends React.Component{
    static get propTypes(){
        return {
            icon: PropTypes.any,
            text: PropTypes.string,
            action: PropTypes.func,
            closeMenu: PropTypes.func
        };
    }

    static get defaultProps(){
        return {
            icon: ServicesIcon,
            text: "Action",
            action: ()=>{},
            closeMenu: ()=>{}
        };
    }

    render(){
        const {icon,text,action,closeMenu,...other} = this.props;


        let _icon = React.cloneElement(icon,{
            style:{width:'48px',margin:'6px 0'}
        });

        return (
            <MenuItem
                style={{height: 36, paddingLeft: 4, paddingRight: 40}}
                onClick={()=>{
                    action();
                    closeMenu();
                }}
                {...other}
            >
                <ListItemIcon>{_icon}</ListItemIcon>
                <ListItemText style={{fontSize: '0.8em'}} disableTypography>{text}</ListItemText>
            </MenuItem>
        );
    }
}

class MoreActionsDropDown extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            open: false,
        };

        this.handleTouchTap = this.handleTouchTap.bind(this);
        this.handleRequestClose = this.handleRequestClose.bind(this);
    }

    static get propTypes(){
        return {
            children: PropTypes.any
        };
    }

    handleTouchTap(event){
        // This prevents ghost click.
        event.preventDefault();

        this.setState({
            open: true,
            anchorEl: event.currentTarget,
        });
    }

    handleRequestClose(){
        this.setState({
            open: false,
        });
    }
    render(){

        const {children, isMobile} = this.props;

        return <div style={{display: 'inline-block'}}>
            <IconFlatButton
                    isMobile={isMobile}
                    id={"more_btn"}
                    label={"More"}
                    icon={SettingsIcon}
                    onClick={this.handleTouchTap}
                    className={"applicances-button-style"}
                />

            <Popover open={this.state.open}
                     anchorEl={this.state.anchorEl}
                     anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                     transformOrigin={{horizontal: 'right', vertical: 'top'}}
                     onClose={this.handleRequestClose}
            >
                {/* <Menu
                    style={{fontSize: '0.9em'}}
                    open={this.state.open}
                    anchorEl={this.state.anchorEl}
                    keepMounted   
                > */}
                    {React.Children.map(children, child => React.cloneElement(child, {
                        closeMenu: this.handleRequestClose
                    }))}
                {/* </Menu> */}
            </Popover>
        </div>;
    }
}



// eslint-disable-next-line
class ColumnsDropDown extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            anchorEl:null,
            columns:props.columns
        };

        this.handleTouchTap = this.handleTouchTap.bind(this);
        this.handleRequestClose = this.handleRequestClose.bind(this);
        this.handleMoveColumn = this.handleMoveColumn.bind(this);
        this.handleToggleColumn = this.handleToggleColumn.bind(this);
        this.handleToggleAllColumns = this.handleToggleAllColumns.bind(this);
    }

    static get propTypes(){
        return {
            columns: PropTypes.array,
            actions: PropTypes.object.isRequired
        };
    }

    handleTouchTap(event){
        // This prevents ghost click.
        event.preventDefault();

        this.setState({
            open: true,
            anchorEl: event.currentTarget,
        });
    }

    handleMoveColumn(dragIndex,hoverIndex){
        this.props.actions.moveColumn(dragIndex,hoverIndex);
    }

    handleToggleColumn(index,checked){
        this.props.actions.toggleColumn(index,checked);
    }

    handleToggleAllColumns(event,checked){
        this.props.actions.toggleAllColumns(checked);
    }

    handleRequestClose(){
        this.setState({
            open: false,
        });
    }
    render(){
        const {columns} = this.props;


        return(
            <div>
                <ClassedIconButton
                    onClick={this.handleTouchTap}>
                    <ViewColumnIcon />
                </ClassedIconButton>

                <Popover open={this.state.open}
                     anchorEl={this.state.anchorEl}
                     anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                     transformOrigin={{horizontal: 'right', vertical: 'top'}}
                     onRequestClose={this.handleRequestClose}
                     transitionDuration={100}
                 >
                     <SortedChecklist
                        selectAllText={"Columns"}
                        className={"columns-drop-down"}
                        data={columns}
                        onMoveItem={this.handleMoveColumn}
                        onCheckItem={this.handleToggleColumn}
                        onCheckSelectAll={this.handleToggleAllColumns}
                        keyAttribute={"name"}
                        labelAttribute={"caption"}
                        checkedAttribute={"enabled"}/>
                 </Popover>
            </div>


        );
    }
}

class DataEntryButtons extends React.Component{
    constructor(){
        super();
        this.renderButton = this.renderButton.bind(this);
        this.refreshModule = this.refreshModule.bind(this);
    }

    static get propTypes(){
        return {
            actions: PropTypes.object.isRequired,
            rowsSelected: PropTypes.array,
            canEdit: PropTypes.bool
        };
    }

    openForm(form,uuids){
        this.props.actions.openApplianceDataEntry(form,uuids);
    }

    renderButton(props){
        const {label,form,icon,disabled,rowsSelected, isMobile} = props;
        return (
            <IconFlatButton
                label={label}
                icon={icon}
                onClick={this.openForm.bind(this,form,rowsSelected)}
                disabled={disabled}
                isMobile={isMobile}
                className={"applicances-button-style" + (disabled ? ' disabled' : '')}
            />
        );
    }

    refreshModule(){
        console.log("clicked");
        this.props.actions.requestAppliances.bind(this);
    }

    render(){

        const {rowsSelected,canEdit, isMobile} = this.props;

        const DEButton = this.renderButton;

        let refreshButton = (
            <IconFlatButton
                isMobile={isMobile}
                id={"refresh_btn"}
                label={"Refresh"}
                icon={RefreshIcon}
                onClick={this.props.actions.requestAppliances.bind(this)}
                className={"applicances-button-style"}
            />
        );

        if(!canEdit){
            return (
                <div className={"appliances-button-container"}>
                    {refreshButton}
                </div>
            );
        }
        const addApplianceLabel = "Add Appliance";
        const editApplianceLabel = "Edit Appliance";
        const deleteApplianceLabel = "Delete Appliance";
        const rebootZlaLabel = "Reboot ZLA";
        const upgradeFirmwareLabel = "Upgrade Firmware";
        const manageFilterProfilesLabel = "Manage Filter Profiles";

        return (
            <div className={"appliances-button-container"}>

                {refreshButton}

                <DEButton
                    isMobile={isMobile}
                    id={"startStopServices_btn"}
                    label={"Start / Stop Services"}
                    icon={ServicesIcon}
                    form={FORMS.APPLIANCES_SERVICES}
                    rowsSelected={rowsSelected}
                    disabled={rowsSelected.length !== 1}
                />

                <DEButton
                    isMobile={isMobile}
                    id={"logs_btn"}
                    label={"ZLA Logs"}
                    icon={LogIcon}
                    form={FORMS.APPLIANCES_DEBUG_PACKAGE}
                    rowsSelected={rowsSelected}
                    disabled={rowsSelected.length !== 1}
                />

                <DEButton
                    isMobile={isMobile}
                    id={"logs_server_btn"}
                    label={"Linux Services Logs"}
                    icon={DownloadIcon}
                    form={FORMS.APPLIANCES_DOWNLOAD_SERVICES_LOGS}
                    rowsSelected={[]}
                    disabled={false}
                />

                <MoreActionsDropDown isMobile={isMobile}>
                    <MoreActionsMenuItem
                        id={generateComponentId(addApplianceLabel,null,"button")}
                        text={addApplianceLabel}
                        icon={<AddIcon />}
                        action={this.openForm.bind(this,FORMS.APPLIANCES_ADD_APPLIANCE,rowsSelected)}
                    />
                    <MoreActionsMenuItem
                        id={generateComponentId(editApplianceLabel,null,"button")}
                        text={editApplianceLabel}
                        icon={<UpdateIcon />}
                        action={this.openForm.bind(this,FORMS.APPLIANCES_UPDATE_APPLIANCE,rowsSelected)}
                        disabled={rowsSelected.length !== 1}
                    />
                    <MoreActionsMenuItem
                        id={generateComponentId(deleteApplianceLabel,null,"button")}
                        text={deleteApplianceLabel}
                        icon={<DeleteIcon />}
                        action={this.openForm.bind(this,FORMS.APPLIANCES_DELETE_APPLIANCE,rowsSelected)}
                        disabled={rowsSelected.length !== 1}
                    />
                    <MoreActionsMenuItem
                        id={generateComponentId(rebootZlaLabel,null,"button")}
                        text={rebootZlaLabel}
                        icon={<SettingsIcon />}
                        action={this.openForm.bind(this,FORMS.APPLIANCES_REBOOT,rowsSelected)}
                        disabled={rowsSelected.length !== 1}
                    />
                    <MoreActionsMenuItem
                        id={generateComponentId(upgradeFirmwareLabel,null,"button")}
                        text={upgradeFirmwareLabel}
                        icon={<SettingsIcon />}
                        action={this.openForm.bind(this,FORMS.APPLIANCES_UPDATE_FIRMWARE,rowsSelected)}
                        disabled={rowsSelected.length === 0}
                    />

                    <MoreActionsMenuItem
                        id={generateComponentId(manageFilterProfilesLabel,null,"button")}
                        text={manageFilterProfilesLabel}
                        icon={<SettingsIcon />}
                        action={this.openForm.bind(this,FORMS.ZLA_FILTER_PROFILE_MANAGER, rowsSelected)}
                        disabled={rowsSelected.length === 0}
                    />

                </MoreActionsDropDown>

            </div>
        );
    }
}


class Breadcrumbs extends React.Component{
    render(){
        return (
            <div className={"tb-appliances-breadcrumbs-container"}>
                <span>Infrastructure</span>
                <span> / </span>
                <span>Appliances</span>
            </div>
        );
    }
}


class AppliancesToolbar extends React.Component{

    constructor(props){
        super(props);

        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.handleEmailClick = this.handleEmailClick.bind(this);
        this.handleScheduledEmailClick = this.handleScheduledEmailClick.bind(this);
        this.handlePrintClick = this.handlePrintClick.bind(this);
        this.handleStarClick = this.handleStarClick.bind(this);
        this.handleFullScreenClick = this.handleFullScreenClick.bind(this);
    }

    static get propTypes(){
        return {
            actions:PropTypes.object,
            columns:PropTypes.array,
            rowsSelected: PropTypes.array,
            nrows: PropTypes.number,
            canEdit: PropTypes.bool
        };
    }

    shouldComponentUpdate(np,ns){
        return(np !== this.props) || (ns !== this.state);
    }

    handleSaveClick(){ this.props.actions.displayMessage("Save Button clicked."); }
    handleEmailClick(){ this.props.actions.displayMessage("Email Button clicked.");}
    handleScheduledEmailClick(){ this.props.actions.displayMessage("Scheduled Email Button clicked."); }
    handlePrintClick(){ this.props.actions.displayMessage("Print Button clicked."); }
    handleStarClick(){ this.props.actions.displayMessage("Star Button clicked"); }
    handleFullScreenClick(){ this.props.actions.displayMessage("Full Screen clicked"); }

    /*

    <ColumnsDropDown columns={columns} actions={actions}/>
     */

     render(){
         const {
             //columns,
             actions,
             rowsSelected,
             nrows,
             canEdit,
             isMobile
         } = this.props;

         /*

         <ToolbarSeparator className="separator"/>
         <ClassedIconButton onClick={this.handleSaveClick}><SaveIcon /></ClassedIconButton>
         <ClassedIconButton onClick={this.handleEmailClick}><EmailIcon /></ClassedIconButton>
         <ClassedIconButton onClick={this.handleScheduledEmailClick}><ClockIcon /></ClassedIconButton>
         <ClassedIconButton onClick={this.handlePrintClick}><PrintIcon /></ClassedIconButton>
         <ClassedIconButton onClick={this.handleStarClick}><StarIcon /></ClassedIconButton>
         <ClassedIconButton onClick={this.handleFullScreenClick}><FullScreenIcon /></ClassedIconButton>

          */

         return (
             <div className="toolbar-main-container save-share-toolbar">
                 <div className={"tb-appliances-left-container"}>
                     <Breadcrumbs  />
                 </div>
                 <div className={"tb-appliances-buttons-container"}>
                     <DataEntryButtons actions={actions} rowsSelected={rowsSelected} canEdit={canEdit} isMobile={isMobile}/>
                 </div>
                 <div className={"tb-appliances-right-container"}>
                     <div style={{flexShrink:0}}>{nrows} Results</div>
                 </div>
             </div>
         );
     }
 }

 export default AppliancesToolbar;
