import React from 'react';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import LinearProgress from '@material-ui/core/LinearProgress';
import {withStyles} from '@material-ui/core/styles';
import createMuiTheme from "@material-ui/core/es/styles/createMuiTheme";
import Paper from "@material-ui/core/Paper";

const ColorLinearProgress = withStyles({
    barColorPrimary: {
        backgroundColor: '#00779f'
    }
})(LinearProgress);

const outerTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#00779F',
        },
    },
    shadows: Array(25).fill('none')
});

const FetchScreen = () => {
    return (
        <div style={{position:'absolute',top:0,right:0,left:0,bottom:0,background:'rgba(0,0,0,0.7)',textAlign:'center', zIndex: '100000'}}>
            <div style={{position:'absolute',top:'50%',transform:'translateY(-50%)',left:0,right:0}}>
                <Paper style={{display:'inline-block',padding:'2em'}}>
                    Getting Data From the Server...
                    <ThemeProvider theme={outerTheme}>
                    <ColorLinearProgress style={{background:'rgba(0,0,0,0.25)',marginTop:'1em'}}/>
                    </ThemeProvider>
                </Paper>
            </div>

        </div>
    );
}

export default FetchScreen;