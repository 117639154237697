const getLocalTimestamp= (date) => {
    return date.toLocaleDateString(window.navigator.language,{
        year:"2-digit",
        month:"numeric",
        day:"numeric",
        hour:"numeric",
        minute:"numeric",
        second:"numeric"
    });
};

const getShortDateFromTimestamp= (timestamp) => {
    return new Date(timestamp).toLocaleDateString(window.navigator.language,{
        year:"2-digit",
        month:"numeric",
        day:"numeric",
        hour:"numeric",
        minute:"numeric",
    });
};

const getDateFromTimestamp= (timestamp) => {
    return new Date(timestamp).toLocaleDateString(window.navigator.language,{
        year:"2-digit",
        month:"numeric",
        day:"numeric",
        hour:"numeric",
        minute:"numeric",
        second:"numeric"
    });
};

export {getLocalTimestamp,getShortDateFromTimestamp,getDateFromTimestamp};
