import React, {useState, useEffect} from 'react';
import {Button} from '@material-ui/core';

import { useSelector, useDispatch } from 'react-redux';
import {
    updateStepAddDevices,
    cleanDevicesBatch,
    cleanMessagesDevicesBatch,
    unloadTemplateList,
    addNewDevices,
    cleanImportedFiles
} from "../../../actions";
import FileSaver from "file-saver";
import CrossBrowserTable from '../common/tableComponent/CrossBrowserTable';
import r2cdevicePayload from '../../../constants/r2cdevicePayload';
import rfidAntenna from '../../../constants/rfidAntenna';
import {NUMBER_ANTENNAS, MAX_LENGTH_DEVICE_TITLE} from '../../../constants/DeviceManager';

function ProvideDeviceDetails(){
    const dispatch = useDispatch();
    const batchImportFilesState = useSelector(state => state.deviceManager.batchImportFiles);
    const actualStep = useSelector(state => state.deviceManager.actualStep);
    const responseMessagesBatch = useSelector(state => state.deviceManager.messagesBatch);
    const templates = useSelector(state => state.deviceManager.templates);
    const mapSelected = useSelector(state => state.deviceManager.mapSelected);
    const userName = useSelector(state => state.user.username);
    const [listDevices, setListDevices] = useState([]);
    const [isSaved, setIsSaved] = useState(false);
    const [messagesBatch, setMessagesBatch] = useState({});
    const [rowsValidation, setRowsValidation] = useState([]);
    const [disabledSave, setDisabledSave] = useState(false);
    const headerContent = loadHeaderContent();

    const headerRow = [{key:"deviceType",value:"Device Type",clickable:true,width:100},
    {key:"deviceModel",value:"Device Model",clickable:true,width:100},
    {key:"deviceName",value:"Name",clickable:true,width:250},
    {key:"deviceIP",value:"Hostname",clickable:true,width:120},
    {key:"deviceTemplate",value:"Template Used",clickable:true,width:120},
    {key:'deviceAntennaPorts',value:'# Antenna Ports',width: 160,clickable:true},
    {key:'deviceEnabledPorts',value:'Enabled ports for data collection',width: 220,clickable:true},
    {key:"deviceX",value:"X",clickable:true,width:80},
    {key:"deviceY",value:"Y",clickable:true,width:80},
    {key:"deviceZ",value:"Z",clickable:true,width:80},
    {key:"deviceAutozone",value:"Auto Zone",clickable:true,width:80},
    {key:"deviceStatus",value:"Import Status",clickable:true,width:100},
    {key:"deviceMessage",value:"Message",clickable:true, width:400}];

    useEffect(() => {
        setMessagesBatch(m => ({...m, ...responseMessagesBatch}));
    }, [responseMessagesBatch]);

    useEffect(initSecondStep, [batchImportFilesState, templates]);

    function initSecondStep(){
        const listItems = getListDevices(batchImportFilesState);
        const objectErrors = initCheckRows(listItems);
        setMessagesBatch(objectErrors);
        setListDevices(listItems);
    }

    function initCheckRows(listRows){
        let ipAddressObject = {};
        
        listRows.forEach((device, index) => {
            if(ipAddressObject.hasOwnProperty(device.ipAddress)){
                let previousValue = ipAddressObject[device.ipAddress];
                ipAddressObject[device.ipAddress] = previousValue + 1;
            }else{
                ipAddressObject[device.ipAddress] = 1;
            }
        });

        let ipAddressAllowed = Object.keys(ipAddressObject).filter(key => ipAddressObject[key] === 1);
        let objectErrors = {};
        let listValidation = [];
        listRows.forEach((device, index)=> {
            let failedMessage = [];
            if((ipAddressAllowed.indexOf(device.ipAddress) === -1)){
                failedMessage.push('IP address duplicated');
            }

            if(Object.keys(NUMBER_ANTENNAS).indexOf(device.rfidType) === -1){
                failedMessage.push('RFID type not identified');
            }else if(device.numberAntennaPorts >0 && NUMBER_ANTENNAS[device.rfidType].indexOf(device.numberAntennaPorts) === -1){
                failedMessage.push(`Number of antennas port for ${device.rfidType} not allowed`);
            }

            if(device.rfidType.indexOf('ATR') > -1 && device.numberAntennaPorts > 0){
                failedMessage.push(`Number of antennas for ${device.rfidType} not allowed`);
            }

            if(failedMessage.length>0){
                objectErrors[index] = {status: 'FAILED', text: failedMessage.join('. ')};
                listValidation.push({disabled: true});
            }else{
                listValidation.push({disabled: false});
            }
        })
        setRowsValidation(listValidation);
        setDisabledSave(Object.keys(objectErrors).length === listRows.length);
        return objectErrors;
    }

    function getEnabledAntennas(listAntennas){
        try {
            let antenaIds = listAntennas.replace(/['"]+/g, '').split(',');
            return antenaIds;
        }catch(error){
            return [];
        }
    }

    function getListDevices(objectBatch){
        let listItems = [];
        Object.keys(objectBatch).forEach(file => {
            objectBatch[file].forEach(device => {
                const templateApplied = templates.find(item => item.title === device.deviceTemplate);
                let item = null;
                let protocol = 'mqtt';

                if(templateApplied){
                    const objectDevice = templateApplied.template;
                    const {_id, rfidType, title, description, deviceType, orderNumber, siteId, location, ipAddress, macAddress, ...rest} = objectDevice;
                    const rfidAntennasUpdate = rest.rfidAntennas.map((antenna) => {
                        let updateAntenna = {...antenna};
                        if(mapSelected && mapSelected.id){
                            updateAntenna.location = {...updateAntenna.location, 
                                mapID: `${mapSelected.id}`
                            };
                            if(!isNaN(device.deviceX)){updateAntenna.location.x = Number(device.deviceX);}
                            if(!isNaN(device.deviceY)){updateAntenna.location.y = Number(device.deviceY);}
                            if(!isNaN(device.deviceZ)){updateAntenna.location.z = Number(device.deviceZ);}
                        }
                        return updateAntenna;
                    });
                    item = {...r2cdevicePayload, ...rest, rfidAntennas: rfidAntennasUpdate, templateId: templateApplied._id};
                    protocol = rest.protocol;
                }else{
                    item = {...r2cdevicePayload}
                }
                
                item.deviceType = device.deviceType;
                item.rfidType = device.deviceModel;
                item.title = getTextLabel(device.deviceName, MAX_LENGTH_DEVICE_TITLE);
                item.ipAddress = device.deviceIP;
                item.protocol = protocol;

                // location and siteId

                if(mapSelected && mapSelected.siteId){
                    item.siteId = mapSelected.siteId;
                }

                if(mapSelected && mapSelected.id){
                    item.location = {...item.location, 
                        mapID: `${mapSelected.id}`
                    };

                    if(!isNaN(device.deviceX)){item.location.x = Number(device.deviceX);}
                    if(!isNaN(device.deviceY)){item.location.y = Number(device.deviceY);}
                    if(!isNaN(device.deviceZ)){item.location.z = Number(device.deviceZ);}
                }

                if(!templateApplied){
                    // antenna ports
                    item.numberAntennaPorts = !isNaN(device.deviceAntennaPorts)?Number(device.deviceAntennaPorts):0;
                    // declarating antennas
                    let arrayAntennas = [];
                    let antennaIds = device.deviceEnabledAntennas === ""?[]:getEnabledAntennas(device.deviceEnabledAntennas);
                    let i = 0;
                    while(i < Number(item.numberAntennaPorts)){
                        i++;
                        let antenna = {...rfidAntenna};
                        antenna.id = Number(i);
                        antenna.title = `Antenna ${i}`;
                        // enabling antennas
                        if(antennaIds.length > 0){
                            antenna.disabled = !Boolean(antennaIds.indexOf(antenna.id.toString()) > -1);
                        }
                        if(mapSelected && mapSelected.id){
                            antenna.location = {...antenna.location, 
                                mapID: `${mapSelected.id}`
                            };
                            if(!isNaN(device.deviceX)){antenna.location.x = Number(device.deviceX);}
                            if(!isNaN(device.deviceY)){antenna.location.y = Number(device.deviceY);}
                            if(!isNaN(device.deviceZ)){antenna.location.z = Number(device.deviceZ);}
                        }
                        arrayAntennas.push(antenna);
                    }

                    item.rfidAntennas = arrayAntennas;
                }

                //extra fields
                item.template = device.deviceTemplate;
                item.configuration_state = "";
                item.status = "";
                item.autoZone = device.deviceAutozone !== ''? device.deviceAutozone: 'yes';

                listItems.push(item);
            })
        })

        return listItems;
    }

    const getTextLabel = (textValue, maxLength) => {
        return textValue.substr(0, maxLength);
    }

    function loadHeaderContent(){
        let objectHeader = {files: [], deviceTypes: {}, totalAntennas: 0};
        Object.keys(batchImportFilesState).forEach(file => {
            //saving name file
            objectHeader.files.push(file);
            batchImportFilesState[file].forEach(device => {
                //saving device type
                const deviceType = device.deviceType
                if(deviceType in objectHeader.deviceTypes){
                    objectHeader.deviceTypes[deviceType] = objectHeader.deviceTypes[deviceType] + 1;
                }else{
                    objectHeader.deviceTypes[deviceType] = 1
                }
                //incrementing antennas
                objectHeader.totalAntennas = objectHeader.totalAntennas + Number(device.numberAntennaPorts);
            })
        })
        return objectHeader;
    }

    const downloadFile = () => {
        let wrongDevices = [];
        Object.keys(messagesBatch).forEach(key => {
            if(messagesBatch[key].status === 'FAILED'){
                wrongDevices.push(listDevices[key]);
            }
        });
        
        let headerCSV = ['Device Type','Device Model','Name','Hostname','Template Used','# Antenna Ports','Connected Antenna Ports','x','y','z','Auto Zone'].join(',');
        let bodyCSV = wrongDevices.map(item => {
            let rowCSV = [item.deviceType, item.rfidType, item.title, item.ipAddress, item.template, item.rfidAntennas.length, 
                item.location.x, item.location.y, item.location.z, item.autoZone];
            return rowCSV.join(',');
        });
        const dataString = [headerCSV, ...bodyCSV].join('\n');
        const type = 'csv';
        const fileName = 'Wrong-devices.csv';
        var blob = new Blob([dataString], {type: `text/${type};charset='utf-8'`});
        FileSaver.saveAs(blob, fileName);
    }

    const customCellDevice = (cellProps) => {
        const {rowIndex, content, headerKey} = cellProps;
        switch(headerKey){
            case "deviceType":
                return listDevices[rowIndex].deviceType;
            case "deviceModel":
                return listDevices[rowIndex].rfidType;
            case "deviceName":
                return listDevices[rowIndex].title;
            case "deviceIP":
                return listDevices[rowIndex].ipAddress;
            case "deviceTemplate":
                return listDevices[rowIndex].template ? listDevices[rowIndex].template : 'No Template';
            case "deviceAntennaPorts":
                return listDevices[rowIndex].numberAntennaPorts;
            case "deviceEnabledPorts":
                const rfidAntennasIds = listDevices[rowIndex].rfidAntennas.filter(item => !item.disabled).map(item => item.id);
                return rfidAntennasIds.join(', ');
            case "deviceX":
                return listDevices[rowIndex].location.x;
            case "deviceY":
                return listDevices[rowIndex].location.y;
            case "deviceZ":
                return listDevices[rowIndex].location.z;
            case "deviceAutozone":
                return listDevices[rowIndex].autoZone ? listDevices[rowIndex].autoZone : '';
            case "deviceStatus":
                return messagesBatch[rowIndex] ? renderResponse(messagesBatch[rowIndex].status) : '';
            case "deviceMessage":
                return messagesBatch[rowIndex] ? messagesBatch[rowIndex].text : '';
            default:
                return content;
        }
    };

    const renderResponse = (status) => {
        let styleResponse = {backgroundColor: status === 'SUCCESS' ? '#009c34': '#f02618', color: '#fff', textAlign: 'center'};
        return <div style={styleResponse}>{status}</div>
    }

    function saveButton(){
        let devices = [];
        listDevices.forEach((device, index) => {
            if(!rowsValidation[index].disabled){
                devices.push({...device, _id: index, createBy: userName});
            }
        })
        dispatch(addNewDevices(devices));
        setIsSaved(true);
    }
    
    function nextButton(){
        dispatch(updateStepAddDevices(actualStep + 1));
    }

    function prevButton(){
        dispatch(updateStepAddDevices(actualStep - 1));
        dispatch(cleanImportedFiles());
        dispatch(cleanMessagesDevicesBatch());
        dispatch(cleanDevicesBatch());
        dispatch(unloadTemplateList());
    }

    const renderHeaderContent = (title, list) =>{
        return (
            <div style={{display:'flex',flexDirection:'row',flexWrap:'no-wrap', alignItems:'flex-start',justifyContent:'flex-start', paddingRight:'1em'}}>
                <div>
                    <b>{`${title}:`}</b>
                </div>
                <div>
                    <ul style={{listStyleType:'none', marginBlockStart:0, marginBlockEnd:0, paddingInlineStart: '10px'}}>
                        {list.map((item, index)=> <li key={index}>{item}</li>)}
                    </ul>
                </div>
            </div>
        )
    };

    const styleDownload = {color: '#007cb0', cursor: 'pointer'};
    
    return (
        <React.Fragment>
            <div className="imported-devices-container">
                {renderHeaderContent('Files uploaded', headerContent.files)}
                {renderHeaderContent('Devices detected', Object.keys(headerContent.deviceTypes).map(type => `${type} (${headerContent.deviceTypes[type]})`))}
                {renderHeaderContent('Devices with errors', [Object.keys(messagesBatch).map(key => messagesBatch[key]).filter(item => item.status === 'FAILED').length])}
                {/* {renderHeaderContent('Antennas Detected', [headerContent.totalAntennas])} */}
                <div style={{ flexGrow: 1, textAlign: 'right' }}>
                    <span onClick={e => downloadFile()} style={styleDownload}>Download devices with errors</span>
                </div>
            </div>
            <div className={"container-body"}>
                <CrossBrowserTable
                    //data={listDevices}
                    rowsValidationError={rowsValidation}
                    rowCount={listDevices.length}
                    showCheckColumn={false}
                    headerRow={headerRow}
                    cellRender={customCellDevice}
                    // onClickRow={clickRow}
                    selectedRows={{}}
                    // onSelectAll={handleSelectAll}
                    modernBrowser={true}
                    appliedFilters={() => null}
                />
            </div>

            <div className={"container-step-content-buttons"}>
                <Button className={'cancel-button'} style={{ marginRight: '8px' }} onClick={prevButton} component="span">Cancel</Button>
                {!isSaved ? <Button disabled={disabledSave} className={disabledSave ? 'next-step-button next-step-button-disabled' : 'next-step-button'} onClick={saveButton} component="span">Save</Button> :
                    <Button className={'next-step-button'} onClick={nextButton} component="span">Place Devices on Map</Button>}
            </div>
        </React.Fragment>
    )
}

export default ProvideDeviceDetails;