import React from 'react';
import PropTypes from 'prop-types';


import Paper from "@material-ui/core/Paper";

import Popover from '@material-ui/core/Popover';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import CarotIcon from '../icons/CarotIcon';

import assign from 'object-assign';

import {StatefulTreeList, TreeList} from './TreeList';

export {TreeList};


const ContainedTreeList = (props)=>(
    <Paper style={{display:'inline-block', width: '100%'}} elevation={2}>
        <StatefulTreeList {...props} />
    </Paper>
);

class PseudoSelect extends React.Component{

    static get propTypes(){
        return {
            label:PropTypes.string,
            style:PropTypes.object
        };
    }

    render(){

        const {
            label,
            style,
            ...other
        } = this.props;

        const baseStyles = {
            display:'inline-block',
            border:'solid 1px #aaa',
            borderRadius:'2px',
            padding:'0.5em 1em',
            fontSize:'0.9em',
            paddingRight:'2em',
            width:'100px',
            whiteSpace:'nowrap',
            textOverflow:'ellipsis',
            overflowX:'hidden',
            position:'relative',
            background:'white'
        };

        const styles = assign({},baseStyles,style);


        return (
            <div style={styles} {...other}>
                {label}
                <CarotIcon
                    style={{
                        position:'absolute',
                        top:0,
                        bottom:0,
                        right:'0.25em',
                        margin:'auto',
                        color:'inherit'
                    }}/>
            </div>
        );
    }
}

class TreeSelect extends React.Component{
    constructor(props){
        super();
        this.state = {
            open:false,
            label: this.deriveLabelFromProps(props.options,props.defaultValue),
            values: props.defaultValue
        };
        this.handleTouchTap = this.handleTouchTap.bind(this);
        this.handleRequestClose = this.handleRequestClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    static get propTypes(){
        return {
            options: PropTypes.array,
            defaultValue: PropTypes.array,
            onChange: PropTypes.func,
            style: PropTypes.object
        };
    }

    static get defaultProps(){
        return {
            onChange: ()=>{},
            options:[],
            defaultValue:[]
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(this.props.defaultValue !== nextProps.defaultValue){
            this.setState({
                values: nextProps.defaultValue
            });
        }
    }

    deriveLabelFromProps(options,values){
        if(this.isAllSelected(options,values)) return "All";

        return values.join(", ") || "None";
    }

    handleTouchTap(event){

        event.preventDefault();

        this.setState({
            open:true,
            anchorEl: event.currentTarget
        });
    }

    handleRequestClose(){
        this.setState({open:false, setAnchorEl:null});
    }

    isAllSelected(options,values){
        // silly assumption, but works.
        return options.length === values.length;
    }

    handleChange(values){
        const {options} = this.props;

        let label = this.deriveLabelFromProps(options,values);

        this.setState({
            label,
            values
        });

        this.props.onChange(values);
    }

    render(){
        const {options,style} = this.props;
        return (
            <div>
                <PseudoSelect
                    label={this.state.label}
                    onClick={this.handleTouchTap}
                    style={style}
                />

                <Popover
                    className={"list-group-class"}
                    open={this.state.open}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
                    transformOrigin={{horizontal: 'left', vertical: 'top'}}
                    onClose={this.handleRequestClose}
                    //canAutoPosition={false}
                >
                    <ClickAwayListener onClickAway={this.handleRequestClose}>
                        <div>
                            <ContainedTreeList defaultValue={this.state.values} options={options}
                                               onChange={this.handleChange}/>
                        </div>
                    </ClickAwayListener>
                </Popover>

            </div>
        );
    }
}

export default TreeSelect;
