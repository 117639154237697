import React,{useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { updateStateKeyFormTemplate } from "../../../../actions";
import { makeStyles } from '@material-ui/core/styles';
import {Tooltip} from '@material-ui/core';
import {
    ADD_TEMPLATE,
    HIGHLIGHT_STYLE,
    TEMPLATE_MAX_FIELD_DESCRIPTION,
    TEMPLATE_MAX_FIELD_NAME
} from '../../../../constants/DeviceManager';

const useStyles = makeStyles({
    checked: {
        color: "#007CB0 !important",
    },
    tooltip:{
        color:'#000',
        backgroundColor:'#e6ee31',
        fontSize:'14px'
    },
    arrow:{
        color: '#e6ee31'
    },popper:{
        zIndex: 0
    }
});

const TemplateGeneralPanel = () => {
    const dispatch = useDispatch();

    // const requiredFields = useSelector(state => state.deviceManager.requiredFields.fields);

    const requiredFields = false;
    const title = useSelector(state => state.deviceManager.templateForm.title);
    const description = useSelector(state => state.deviceManager.templateForm.description);

    //device type fields
    const templateFormLoaded = useSelector(state => state.deviceManager.templateFormLoaded);

    useEffect(()=>{}, [requiredFields]);

    const classes = useStyles();

    //flag to enable highlights in input fields
    const isHighlight = templateFormLoaded !== ADD_TEMPLATE;

    const handleChange = event => {
        const key = event.target.name;
        const value = event.target.value;

        //highlighting
        if(isHighlight && key){
            document.getElementById(`identity-${key}`).style["box-shadow"] = HIGHLIGHT_STYLE;
        }

        dispatch(updateStateKeyFormTemplate(key, value));
    };
        
    return (<div className={"template-content"}>
        <div className="device-items">
            <div className="basic-input" style={{margin:'0 -0.5em'}}>
                <div className="field">
                    <span className="items-header">Template Name*</span>
                    <Tooltip open={requiredFields} arrow={true} title={'Mandatory Field'} placement={"right"} PopperProps={{ disablePortal: true }}
                            classes={{tooltip:classes.tooltip, arrow:classes.arrow, popper: classes.popper }} >
                        <input id='identity-title' type={"text"} maxLength={TEMPLATE_MAX_FIELD_NAME}
                            style={{boxShadow: requiredFields?'0px 0px 4px #e4ed15':''}}
                            placeholder={"Full Name"}
                            name={'title'}
                            value={title}
                            onChange={handleChange}/>
                    </Tooltip>
                    <span className="identity-char-indicator">{TEMPLATE_MAX_FIELD_NAME-title?.length} characters left.</span>
                </div>
                <div className="field">
                    <span className="items-header">Template Description*</span>
                    <Tooltip open={requiredFields} arrow={true} title={'Mandatory Field'} placement={"right"} PopperProps={{ disablePortal: true }}
                            classes={{tooltip:classes.tooltip, arrow:classes.arrow, popper: classes.popper }} >
                        <textarea id='identity-description' type={"text"} maxLength={TEMPLATE_MAX_FIELD_DESCRIPTION}
                            placeholder={"Enter Description"}
                            style={{resize: 'none', height: '6em', boxShadow: requiredFields?'0px 0px 4px #e4ed15':''}}
                            name={'description'}
                            value={description}
                            onChange={handleChange} />
                    </Tooltip>
                    <span className="identity-char-indicator">{TEMPLATE_MAX_FIELD_DESCRIPTION-description?.length} characters left.</span>
                </div>
            </div>
        </div>
    </div>);
}

export default TemplateGeneralPanel;