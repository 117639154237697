import React from 'react';
import PropTypes from 'prop-types';

import {Feature, VectorLayer} from '../../../../../../common/app/views/map/ZMap';
//openlayers
import Style from 'ol/style/Style';
import CircleStyle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Point from 'ol/geom/Point';

function solidDotWithColor(color){
    return new Style({
        image: new CircleStyle({
          radius: 8,
          fill: new Fill({color: color}),
          stroke: new Stroke({color: 'white', width: 2})
        })
    });
}

const RED_DOT = solidDotWithColor('#f00');
const ORANGE_DOT = solidDotWithColor("#f60");
const YELLOW_DOT = solidDotWithColor("#ff0");
const GREEN_DOT = solidDotWithColor("#2a3");


class PinLayer extends React.Component{

    constructor(props){
        super(props);
        this.props = props;
    }

    static get propTypes(){
        return {
            pins: PropTypes.array.isRequired
        };
    }

    styleFunction(feature,number){ // eslint-disable-line

        const data = feature.get('data');

        const {severity} = data;

        switch(severity){
            case "High":
                return [RED_DOT];
            case "Medium":
                return [ORANGE_DOT];
            case "Low":
                return [YELLOW_DOT];
            case "NA":
            default:
                return [GREEN_DOT];
        }
    }

    render(){


        let _pins =  this.props.pins.map((v,i)=>{
            return (
                <Feature
                    key={"tag"+i}
                    geometry={ new Point([v.x,v.y]) }
                    data={v}
                    style={this.styleFunction}
                />
            );
        });


        return (
            <VectorLayer {...this.props}>
                {_pins}
            </VectorLayer>
        );
    }
}

export default PinLayer;
