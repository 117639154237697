import * as types from '../constants/ActionTypes';

import {displayErrorFromAxios, ICService, SiteViewService} from './util';
import {MAP_VIEW_URL} from '../constants/Misc';
import {TOGGLE_MY_SITES, UPDATE_MAP_SIZE} from "../constants/ActionTypes";
import {SITE_MANAGER_SITE_HOVER} from "../constants/ActionTypes";
// pure ui actions -- sites page
export const openSiteForm = (form,data) => ({type: types.OPEN_SITE_FORM, form, data});
export const closeSiteForm = () => ({type: types.CLOSE_SITE_FORM});


export const selectSite = (id,checked) => ({type:types.TOGGLE_SITE_SELECTION, id, checked});
export const selectSiteGroup = (id,checked) => ({type:types.TOGGLE_SITEGROUP_SELECTION, id, checked});
export const selectAllSites = (checked) =>({type:types.TOGGLE_ALLSITES, checked});

export const selectFloorMap = (map)=>({type:types.SELECT_FLOOR_MAP, map});
export const updateMapSize = ()=>({type:UPDATE_MAP_SIZE});

export const toggleMySites = (map)=>{
    return (dispatch) => {
        dispatch({type: TOGGLE_MY_SITES});
        dispatch(updateMapSize());
    }
}


/*
███    ███  ██████  ██    ██ ███████
████  ████ ██    ██ ██    ██ ██
██ ████ ██ ██    ██ ██    ██ █████
██  ██  ██ ██    ██  ██  ██  ██
██      ██  ██████    ████   ███████
*/


export const moveSiteToSiteGroup = (id,groupId) => {
    return (dispatch) => {
        dispatch({type: types.MOVE_SITE_TO_SITEGROUP, id, groupId:(groupId === "null" ? null : groupId)});

        let data = {
            groupMembership:(!groupId || groupId === "null") ? [] : [groupId]
        };

        ICService.instance().put("/sites/" + id,data)
        .then( () => {
            dispatch(requestSites());
        })
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};

/*
██████  ███████  ██████  ██    ██ ███████ ███████ ████████         ██     ██       ██████   █████  ██████
██   ██ ██      ██    ██ ██    ██ ██      ██         ██           ██      ██      ██    ██ ██   ██ ██   ██
██████  █████   ██    ██ ██    ██ █████   ███████    ██          ██       ██      ██    ██ ███████ ██   ██
██   ██ ██      ██ ▄▄ ██ ██    ██ ██           ██    ██         ██        ██      ██    ██ ██   ██ ██   ██
██   ██ ███████  ██████   ██████  ███████ ███████    ██        ██         ███████  ██████  ██   ██ ██████
                    ▀▀
*/

export const loadSites = (data) => ({type:types.LOAD_SITES, data:data});

export const requestSites = () => {
    return (dispatch) => {
        //upgradeReact
        // Actually not convinced we need to dispatch an event... UI doesn't really do much with it.
        //dispatch({type:types.REQUEST_SITES});

        return SiteViewService.instance().get(MAP_VIEW_URL+"/sites/",{
            params:{

                },
                headers:{
                    'Content-Type':'application/json'
                }
            })
            .then(response => {
                dispatch(loadSites(response.data));
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch({type:types.SET_SESSION, isSessionActive: false});
                }
                displayErrorFromAxios.bind(null, dispatch);
            });
    };
};

// request + load site groups
export const loadSiteGroups = (data) => ({type:types.LOAD_SITE_GROUPS, data: data});

export const requestSiteGroups = () => {
    return (dispatch) => {
        ICService.instance().get("/sitegroups/",{
            params:{

            },
            headers:{
                'Content-Type':'application/json',
                'Pragma': 'no-cache'
            }
        })
        .then(response => {
            dispatch(loadSiteGroups(response.data));
        })
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};

/*
 █████  ██████  ██████
██   ██ ██   ██ ██   ██
███████ ██   ██ ██   ██
██   ██ ██   ██ ██   ██
██   ██ ██████  ██████
*/

// site (create,update,delete)
export const addSite = ({name, location, lat, long, siteGroupId }) => {
    return (dispatch) => {
        //dispatch(({type: types.ADD_SITE, name, location, lat, long, siteGroupId}));

        let data = {
            name,
            location,
            lat,
            long,
            groupMembership:(siteGroupId ? [siteGroupId] : [])
        };

        return ICService.instance().post("/sites",data)
        .then( () => {
            dispatch(requestSites());
        })
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};
export const addSiteGroup = (name) => {
    return (dispatch) => {
        //dispatch({type: types.ADD_SITE_GROUP, name});

        let data = {name};

        return ICService.instance().post("/sitegroups",data)
        .catch(displayErrorFromAxios.bind(null,dispatch))
        .then( () => {
            dispatch(requestSiteGroups());
        });
    };
};


/*
██    ██ ██████  ██████   █████  ████████ ███████
██    ██ ██   ██ ██   ██ ██   ██    ██    ██
██    ██ ██████  ██   ██ ███████    ██    █████
██    ██ ██      ██   ██ ██   ██    ██    ██
 ██████  ██      ██████  ██   ██    ██    ███████
*/

export const updateSite = ({id, name, location, lat, long }) => {
    return (dispatch) => {
        //dispatch(({type: types.UPDATE_SITE, id, name, location, lat, long}));

        let data = {
            name,
            location,
            lat,
            long
        };

        return ICService.instance().put("/sites/"+id,data)
        .catch(displayErrorFromAxios.bind(null,dispatch))
        .then( () => {
            dispatch(requestSites());
        });
    };
};
export const updateSiteGroup = (id, name) => {
    return (dispatch) => {
        //dispatch({type:types.UPDATE_SITE_GROUP, id, name});

        let data = {name};

        return ICService.instance().put("/sitegroups/"+id,data)
        .catch(displayErrorFromAxios.bind(null,dispatch))
        .then( () => {
            dispatch(requestSiteGroups());
        });
    };
};

/*
██████  ███████ ██      ███████ ████████ ███████
██   ██ ██      ██      ██         ██    ██
██   ██ █████   ██      █████      ██    █████
██   ██ ██      ██      ██         ██    ██
██████  ███████ ███████ ███████    ██    ███████
*/

export const deleteSite = (id) => {
    return (dispatch) => {
        //dispatch({type: types.DELETE_SITE, id});

        ICService.instance().delete("/sites/"+ id)
        .then( () => {
            dispatch(requestSites());
        })
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};
export const deleteSiteGroup = (id) => {
    return (dispatch) => {

        ICService.instance().delete("/sitegroups/" + id)
        .then( () => {
            dispatch({type: types.DELETE_SITE_GROUP, id});
            dispatch(requestSiteGroups());
        })
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};

///
export const changeSiteManagerSiteHover = (siteUID,hovered) =>({type:SITE_MANAGER_SITE_HOVER,hoverUID: hovered?siteUID:null});
