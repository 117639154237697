import React from 'react';

class InputFileComponent extends React.Component {

    render() {
        return (
            <div style={{ display: "flex", fontWeight: "normal" }}>
                <input className={"upload-input-wrapper"} type={"text"} disabled={true} value={this.props.nameFile ? this.props.nameFile.name : ''} style={{ background: this.props.disabledOption ? "#D9DEE0" : "" }} />
                <div className={"upload-wrapper"} style={{ paddingLeft: "0.2em" }}>
                    <div className={"upload-btn-wrapper"}>
                        <input className={"upload-btn"} type={"button"} value={"+"} style={{ background: this.props.disabledOption ? "#D9DEE0" : "" }} />
                        <input type={"file"} className={'input-file'} name={"myfile"} accept={".csv"} onChange={e => this.props.handleFileChosen(e.target.files[0])} disabled={this.props.disabledOption} />

                    </div>
                </div>
            </div>
        );
    }
}

export default InputFileComponent;