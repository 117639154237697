import {
    DEVICE_ALERT_CHART,
    TAG_HEALTH,
    ZLA_STATUS_ACTION,
} from "../constants/ActionTypes";

const initialState = {
    tagData: [],
    zlaStatus:[],
    devicesData: [],
};

export function dashboards(state=initialState, action) {
    switch (action.type) {
        case TAG_HEALTH:
            return {
                ...state,
                tagData: action.data
            };
        case ZLA_STATUS_ACTION:
            return {
                ...state,
                zlaStatus: action.data
            };
        case DEVICE_ALERT_CHART:
            return {
                ...state,
                devicesData: action.data
            };
        default:
            return state;
    }
}