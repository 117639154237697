import React from 'react';
import SiteMapDevices from './SiteMapDevices';

const DragDevices = () => {
    return (
        <div style={{display:"flex", justifyContent:"space-between", height:"100%"}}>
            <SiteMapDevices />
        </div>
    )
};

export default DragDevices;