import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { updateStateFormTemplate } from "../../../../actions";
import { makeStyles } from '@material-ui/core/styles';
import {RadioGroup, FormControlLabel, Radio, Divider} from '@material-ui/core';
import {ADD_TEMPLATE, CONSTANT_OF_FORMS, HIGHLIGHT_STYLE} from '../../../../constants/DeviceManager';
import Tooltip from "@material-ui/core/Tooltip";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from "@material-ui/icons/InfoOutlined";
import ReactToolTip from "react-tooltip";

const useStyles = makeStyles({
    checked: {
        color: "#007CB0 !important",
    },
    rootChecked: {
        color: '#000', paddingTop: 3, paddingBottom: 3
    },
    tooltip:{
        color:'#000',
        backgroundColor:'#e6ee31',
        fontSize:'14px'
    },
    arrow:{
        color: '#e6ee31'
    },popper:{
        zIndex: 0
    }
});


const OperationPanel = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const templateFormLoaded = useSelector(state => state.deviceManager.templateFormLoaded);
    const operationMode = useSelector(state => state.deviceManager.templateForm.template.operationMode);
    const tagFilter = useSelector(state => state.deviceManager.templateForm.template.tagFilter);
    const inventoryInterval = useSelector(state => state.deviceManager.templateForm.template.inventoryInterval);
    const portalSettings = useSelector(state => state.deviceManager.templateForm.template.portalSettings);
    const minRssi = useSelector(state => state.deviceManager.templateForm.template.minRssi);
    const operationValidations = useSelector(state => state.deviceManager.needsValidationFields.operation);
    //flag to enable highlights in input fields
    const isHighlight = templateFormLoaded !== ADD_TEMPLATE;

    const handleChange = event => {
        const key = event.target.name;

        const [nameParentKey, nameChildKey, nameGrandChild] = key.split('-');
        //highlighting
        if(isHighlight && key){
            document.getElementById(`operation-${key}`).style["box-shadow"] = HIGHLIGHT_STYLE;
        }
        

        if(nameParentKey === 'portalSettings'){
            let portalValueUpdate;
            let portalValue = event.target.value;
            if(nameGrandChild){
                portalValueUpdate = {...portalSettings.startTrigger, [nameGrandChild]: nameGrandChild === 'port'? Number(portalValue):portalValue}
            }else{
                portalValueUpdate = Number(portalValue);
            }
            dispatch(updateStateFormTemplate(nameParentKey, nameChildKey, portalValueUpdate));
        }

        if(nameParentKey === 'inventoryInterval'){
            if(key !== 'inventoryInterval-value'){
                dispatch(updateStateFormTemplate(nameParentKey, nameChildKey, event.target.value));
            } else if (/^([0-9])*$/.test(event.target.value)){
                dispatch(updateStateFormTemplate(nameParentKey, nameChildKey, event.target.value));
            }
        }

        if(nameParentKey === 'tagFilter'){
            const elementValue = event.target.value.trim();
            dispatch(updateStateFormTemplate(nameParentKey, nameChildKey, elementValue));
        }

        if(nameParentKey === 'operationMode'){
            dispatch(updateStateFormTemplate(nameParentKey, null, event.target.value));

        }

        if(nameParentKey === 'minRssi'){
            if(event.target.value!=null&&event.target.value!==''&&!isNaN(event.target.value)) {
                dispatch(updateStateFormTemplate(nameParentKey, null, parseInt(event.target.value)));
            }else{
                dispatch(updateStateFormTemplate(nameParentKey, null, event.target.value));

            }
        }
    };

    useEffect(() => {}, [operationMode]);

    const getTagFilterContent = () => {
        return(
            <Tooltip open={operationValidations['tagFilter.value'].error || operationValidations['tagFilter.match'].error || operationValidations['tagFilter.operation'].error || operationValidations['tagFilter.length'].error}
                     arrow={true} title={
                        operationValidations['tagFilter.value'].error ? operationValidations['tagFilter.value'].message : (
                            operationValidations['tagFilter.match'].error ? operationValidations['tagFilter.match'].message :
                                operationValidations['tagFilter.operation'].error ? operationValidations['tagFilter.operation'].message :
                                    operationValidations['tagFilter.length'].error ? operationValidations['tagFilter.length'].message : ''
                            )
                        } placement={"right"}
                     PopperProps={{disablePortal: true}}
                     classes={{tooltip: classes.tooltip, arrow: classes.arrow, popper: classes.popper}}>
            <div className="basic-input" style={{display:'flex',flexDirection:'row',margin:'0 -0.4em'}} >
                <div className="field" style={{boxShadow: operationValidations['tagFilter.value'].error || operationValidations['tagFilter.length'].error?'0px 0px 4px #e4ed15':''}}>

                    <div style={{display: 'flex'}}>
                        <div className="items-header">Tag ID Filter</div>
                        <div style={{cursor: 'pointer', lineHeight:'22px', margin:'0 10px'}} data-for={'tagInfo'}
                             data-tip="TagId field number of digits must be even"
                        >
                            <InfoIcon fontSize={"small"} htmlColor={"#007CB0"}/>
                        </div>
                        <ReactToolTip className={"tooltip-class-transmit-power"} place={"top"} id={"tagInfo"} effect={"solid"} />
                    </div>
                        <input id="operation-tagFilter-value" type="text" className="operation-items-small-input" value={tagFilter.value || ''}
                               name={'tagFilter-value'} onChange={handleChange} />

                </div>
                <div className="field" style={{boxShadow: operationValidations['tagFilter.match'].error?'0px 0px 4px #e4ed15':''}}>
                    <span className="items-header">Match</span>
                    <select id="operation-tagFilter-match" name={'tagFilter-match'} value={tagFilter.match || ''} onChange={handleChange}>
                        <option value="">-</option>
                        <option value={CONSTANT_OF_FORMS.prefix.value}>{CONSTANT_OF_FORMS.prefix.label}</option>
                        <option value={CONSTANT_OF_FORMS.suffix.value}>{CONSTANT_OF_FORMS.suffix.label}</option>
                        <option value={CONSTANT_OF_FORMS.regex.value}>{CONSTANT_OF_FORMS.regex.label}</option>
                    </select>
                </div>
                <div className="field" style={{boxShadow: operationValidations['tagFilter.operation'].error?'0px 0px 4px #e4ed15':''}}>
                    <span className="items-header">Operation</span>
                    <select id="operation-tagFilter-operation" name={'tagFilter-operation'} value={tagFilter.operation || ''} onChange={handleChange}>
                        <option value="">-</option>
                        <option value={CONSTANT_OF_FORMS.include.value}>{CONSTANT_OF_FORMS.include.label}</option>
                        <option value={CONSTANT_OF_FORMS.exclude.value}>{CONSTANT_OF_FORMS.exclude.label}</option>
                    </select>
                </div>

            </div>

            </Tooltip>
        )
    }

    return (<>
        <div style={{width: '100%',padding:'0 0.3em'}}>
            <div className="operation-items">
                <span className="items-header">Operation Mode</span>
                <RadioGroup id='operation-operationMode' style={{display: 'flex', flexDirection:'row',justifyContent:'space-between', padding:'0 0.1em'}}
                    value={operationMode || ''} name={'operationMode'} onChange={handleChange}>
                    <FormControlLabel value={"simple"} control={<Radio classes={{checked:classes.checked}} 
                        style={{color: '#000', backgroundColor:'transparent'}} size={"small"} />} label="Simple" />
                    <FormControlLabel value={"conveyor"} control={<Radio classes={{checked:classes.checked}} 
                        style={{color: '#000', backgroundColor:'transparent'}} size={"small"} />} label="Conveyor" />
                    <FormControlLabel value={"inventory"} control={<Radio classes={{checked:classes.checked}} 
                        style={{color: '#000', backgroundColor:'transparent'}} size={"small"} />} label="Inventory" />
                    <FormControlLabel value={"portal"} control={<Radio classes={{checked:classes.checked}} 
                        style={{color: '#000', backgroundColor:'transparent'}} size={"small"} />} label="Portal" />
                    <FormControlLabel value={"advanced"} control={<Radio classes={{checked:classes.checked}} 
                        style={{color: '#000', backgroundColor:'transparent'}} size={"small"} />} label="User Defined" />
                </RadioGroup>

                <Divider style={{marginTop: 15, marginBottom: 15}} />
                {(operationMode === 'simple' || operationMode === 'conveyor') && getTagFilterContent()}
                {operationMode === 'inventory' && <React.Fragment>
                {getTagFilterContent()}
                    <Tooltip open={operationValidations['inventoryInterval.value'].error || operationValidations['inventoryInterval.unit'].error }
                             arrow={true} title={
                                operationValidations['inventoryInterval.value'].error ? operationValidations['inventoryInterval.value'].message : (
                                    operationValidations['inventoryInterval.unit'].error ? operationValidations['inventoryInterval.unit'].message : ''
                                )
                            } placement={"right"}
                             PopperProps={{disablePortal: true}}
                             classes={{tooltip: classes.tooltip, arrow: classes.arrow, popper: classes.popper}}>
                        <div className="basic-input" style={{display:'flex',flexDirection:'row', margin:'0 -0.3em'}} >
                            <div className="field" style={{boxShadow: operationValidations['inventoryInterval.value'].error ?'0px 0px 4px #e4ed15':''}}>
                                <span className="items-header">Reporting Interval</span>
                                <input id="operation-inventoryInterval-value" type="number" className="operation-items-small-input" min={0}
                                        value={inventoryInterval.value || ''} name={'inventoryInterval-value'} onChange={handleChange} />
                            </div>
                            <div className="field" style={{boxShadow: operationValidations['inventoryInterval.unit'].error ?'0px 0px 4px #e4ed15':''}}>
                                <span className="items-header">Units</span>
                                <select id="operation-inventoryInterval-unit" value={inventoryInterval.unit || ''}
                                        name={'inventoryInterval-unit'} onChange={handleChange}>
                                    <option value="">-</option>
                                    <option value="seconds">Seconds</option>
                                    <option value="minutes">Minutes</option>
                                    <option value="hours">Hours</option>
                                    <option value="days">Days</option>
                                </select>
                            </div>
                        </div>
                    </Tooltip>
                    <div className="basic-input" style={{display:'flex',flexDirection:'row'}}>
                        <div className="field" style={{boxShadow: operationValidations.minRssi.error?'0px 0px 4px #e4ed15':'',margin:'0 -0.3em'}}>
                            <span className="items-header">Minimum RSSI </span>
                            <Tooltip open={operationValidations.minRssi.error}
                                     arrow={true} title={operationValidations.minRssi.message} placement={"right"}
                                     PopperProps={{disablePortal: true}}
                                     classes={{tooltip: classes.tooltip, arrow: classes.arrow, popper: classes.popper}}>
                                <input id="operation-minRssi" type="number" className="operation-items-small-input"
                                       value={minRssi || ''} name={'minRssi'} onChange={handleChange}/>
                            </Tooltip>
                        </div>

                        <div className="field">
                        </div>
                    </div>
                </React.Fragment>}
                {operationMode === 'portal' &&
                <React.Fragment>
                    {getTagFilterContent()}
                    <Tooltip open={operationValidations['portalSettings.startTrigger.port'].error || operationValidations['portalSettings.startTrigger.signal'].error }
                             arrow={true} title={
                        operationValidations['portalSettings.startTrigger.port'].error ? operationValidations['portalSettings.startTrigger.port'].message : (
                            operationValidations['portalSettings.startTrigger.signal'].error ? operationValidations['portalSettings.startTrigger.signal'].message : ''
                        )
                    } placement={"right"}
                             PopperProps={{disablePortal: true}}
                             classes={{tooltip: classes.tooltip, arrow: classes.arrow, popper: classes.popper}}>
                        <fieldset>
                            <legend>Start Trigger</legend>
                            <div className="basic-input" style={{display:'flex',flexDirection:'row',margin:'0 -0.3em'}} >
                                <div className="field" style={{boxShadow: operationValidations['portalSettings.startTrigger.port'].error?'0px 0px 4px #e4ed15':''}}>
                                    <span className="items-header">GPI Port</span>
                                    <input id="operation-portalSettings-startTrigger-port" type="number" className="operation-items-small-input"
                                            value={portalSettings.startTrigger.port || ''} name={'portalSettings-startTrigger-port'} onChange={handleChange} />
                                </div>
                                <div className="field" style={{boxShadow: operationValidations['portalSettings.startTrigger.signal'].error?'0px 0px 4px #e4ed15':''}}>
                                    <span className="items-header">Signal</span>
                                    <select id="operation-portalSettings-startTrigger-signal" name={'portalSettings-startTrigger-signal'}
                                            value={portalSettings.startTrigger.signal || ''} onChange={handleChange}>
                                        <option value="">-</option>
                                        <option value={CONSTANT_OF_FORMS.high.value}>{CONSTANT_OF_FORMS.high.label}</option>
                                        <option value={CONSTANT_OF_FORMS.low.value}>{CONSTANT_OF_FORMS.low.label}</option>
                                    </select>
                                </div>
                            </div>
                        </fieldset>
                    </Tooltip>
                    <fieldset>
                        <legend>Stop Trigger</legend>
                        <div className="basic-input" style={{display:'flex',flexDirection:'row',margin:'0 -0.3em'}} >
                            <div className="field">
                                <span className="items-header">Interval</span>
                                <input id="operation-portalSettings-stopInterval" type="number" className="operation-items-small-input"
                                        value={portalSettings.stopInterval || ''} name={'portalSettings-stopInterval'} onChange={handleChange} />
                            </div>
                        </div>
                    </fieldset>
                </React.Fragment>}
                {operationMode === 'advanced' &&
                <React.Fragment>
                    <ReadJSONFileComponent />
                </React.Fragment>}
            </div>
        </div>
    </>);
}

export default OperationPanel;


const isJsonString = str => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}


const ReadJSONFileComponent = () => {
    const classes = useStyles();
    const advancedJson = useSelector(state => state.deviceManager.templateForm.template.advancedJson);
    const validationAdvancedJson = useSelector(state => state.deviceManager.needsValidationFields.operation.advancedJson);
    const styleErrorContainer = {display:'flex',alignItems:'center', backgroundColor: '#fdecea', color: 'red', padding:'0.25em', whiteSpace: 'pre-line'};
    const styleErrorLabel = {marginLeft: '0.5em', flex: 1, fontSize: '0.9em', textAlign: 'justify'};

    const dispatch = useDispatch();
    const [wrongFile, setWrongFile] = useState(null);
    const [wrongJSON, setWrongJSON] = useState(null);
    const [okFile, setOkFile] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [date, setDate] = useState(Date.now());
    const fileMaxSize = 4194304; //4MB

    const handleFileSelect = event => {
        handleReadingFiles(event.target.files);
        //init values
        setDate(Date.now());
    };

    const initReadJSON = () => {
        if(typeof(advancedJson) === 'object'){
            dispatch(updateStateFormTemplate('advancedJson', null, JSON.stringify(advancedJson, null, 2), true));
        }else if(advancedJson){
            setWrongJSON(isJsonString(advancedJson)?null:'JSON malformed');
        }
    }
    useEffect(initReadJSON, [advancedJson]);

    const handleReadingFiles = files => {
        // init states
        for (let i = 0, f; !!(f = files[i]); i++) {
            if (!f.name.endsWith('.json')){                 //validating extension file
                setWrongFile(`${f.name} dont have JSON extension.`);
                setOkFile(null);
            }else if(f.size > fileMaxSize){                 //validating the size (max 4MB)
                setWrongFile(`Maximum upload file size: 4MB. \n ${f.name} exceeds the maximum upload size.`);
                setOkFile(null);
            }else{
                let reader = new FileReader();
                // Detect the method readAsBinaryString for modernBrowsers
                let hasBinaryString = typeof reader.readAsBinaryString === 'function';
    
                if(hasBinaryString){
                    reader.readAsBinaryString(f);
                }else{
                    reader.readAsText(f);
                }
    
                reader.onload = (file) => {
                    const result = file.target.result;                    
                    if(isJsonString(result)){
                        dispatch(updateStateFormTemplate('advancedJson', null, result));
                        setWrongFile(null);
                        setOkFile(f.name);
                    }else{
                        setWrongFile(`${f.name} has JSON malformed.`);
                        setOkFile(null);
                    }
                }
            }
        }
    }

    const handleChangeText = e => {
        let jsonString = e.target.value;
        dispatch(updateStateFormTemplate('advancedJson', null, jsonString));
    }

    return (
        <div className="basic-input" style={{display:'flex',flexDirection:'row',margin:'0 -0.4em'}} >
            <div className="field">
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '0.5em'}}>
                    <span className="items-header" style={{paddingBottom: 0}}>JSON File</span>&nbsp;&nbsp;&nbsp;
                    <Button
                        component="label"
                        style={{backgroundColor:'#007cb0', color:'#fff',textTransform:'none'}}>
                        Browse
                        <input style={{display: 'none'}} key={date} type="file" accept={'.json'} multiple={false} onChange={handleFileSelect}/>
                    </Button>
                    {okFile && <span style={{fontSize: '0.875rem', paddingLeft:'0.5em'}}>{okFile}</span>}
                    {wrongFile && <div style={styleErrorContainer}>
                        <ErrorOutlineIcon />
                        <span style={styleErrorLabel}>{wrongFile}</span>
                    </div>}
                </div>
                <Tooltip open={validationAdvancedJson.error}
                     arrow={true} title={validationAdvancedJson.message} placement={"right"} PopperProps={{disablePortal: true}}
                     classes={{tooltip: classes.tooltip, arrow: classes.arrow, popper: classes.popper}}>
                    <textarea rows={25} cols={50} style={{background:'rgba(0,0,0,0.1)', minHeight:'160px',lineHeight:'1.25em'}} value={advancedJson||''} onChange={handleChangeText} onDoubleClick={()=>setOpenDialog(true)} />
                </Tooltip>
                {wrongJSON && <div style={styleErrorContainer}>
                    <ErrorOutlineIcon />
                    <span style={styleErrorLabel}>{wrongJSON}</span>
                </div>}
            </div>
            {openDialog && <DialogJSONFullScreen open={openDialog} jsonContent={advancedJson} closeDialog={() => setOpenDialog(false)}/>}
        </div>
    )
}


const DialogJSONFullScreen = ({ open, closeDialog}) => {
    const advancedJson = useSelector(state => state.deviceManager.templateForm.template.advancedJson);
    const [isJsonMalformed, setIsJsonMalformed] = useState(false);
    const dispatch = useDispatch();
    const initValues = () => {
        if(isJsonString(advancedJson)){
            setIsJsonMalformed(false);
        }else{
            setIsJsonMalformed(true);
        }
    }

    useEffect(initValues, [advancedJson]);

    const renderCloseButton = () => {
        return (
            <div style={{position:'absolute',top:'-1.1em',right:'-1.1em'}}>
                <IconButton aria-label="close"
                    size="small"
                    onClick={closeDialog}
                    style={{cursor:'pointer',position:'relative',zIndex:'100',backgroundColor:'#000'}}
                    >
                    <CloseIcon style={{color: '#fff'}} />
                </IconButton>
            </div>
        );
    }

    const handleChangeText = e => {
        let jsonString = e.target.value;
        dispatch(updateStateFormTemplate('advancedJson', null, jsonString));
    }

    const styleContainerError = {display:'flex',alignItems:'center', backgroundColor: '#fdecea', color: 'red', padding:'0.25em'};
    const styleLabelError = {marginLeft: '0.5em', flex: 1, fontSize: '0.9em', textAlign: 'justify'};
    return (
        <Dialog
            maxWidth={'md'}
            open={open}
            onClose={closeDialog}
            PaperProps={{style: {overflowY: 'visible'}}}>
            <DialogContent style={{overflow: 'hidden', padding: '8px'}}>
                <textarea rows={27} cols={50} style={{background:'rgba(0,0,0,0.1)'}} value={advancedJson ||''} onChange={handleChangeText} />
                {isJsonMalformed && <div style={styleContainerError}>
                    <ErrorOutlineIcon />
                    <span style={styleLabelError}>JSON malformed</span>
                </div>}
                {renderCloseButton()}
            </DialogContent>          
        </Dialog>
    );
}   