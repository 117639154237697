import React from 'react';
import PropTypes from 'prop-types';

import dateFormat from 'dateformat';

import StyledDialog from '../../../util/Dialog';

import ConfirmDialog from '../ConfirmDialog';
import SchedulerDialog from '../SchedulerDialog';
import ViewConfigDialog from './ViewConfigDialog';
import RescheduleIcon from '@material-ui/icons/Update';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/NotInterested';
import RollbackIcon from '@material-ui/icons/Restore';
import InfoIcon from '@material-ui/icons/InfoOutlined';


import IconFlatButton from '../../toolbars/IconFlatButton';
import Task from './TaskCell';
import HistoryTable from './HistoryTable';


// Task Name --> Xfer to 'tasks' constant file?
const SET_CONFIG = "SetConfig";



/*
████████  █████  ███████ ██   ██
   ██    ██   ██ ██      ██  ██
   ██    ███████ ███████ █████
   ██    ██   ██      ██ ██  ██
   ██    ██   ██ ███████ ██   ██
*/

class ConfigTask extends React.Component{
    static get propTypes(){
        return {
            data: PropTypes.shape({
                timeCreated: PropTypes.number,
                timeToExecute: PropTypes.number,
                status: PropTypes.string
            }),
            onRollbackTask: PropTypes.func,
            onRescheduleTask: PropTypes.func,
            onCancelTask: PropTypes.func,
            onDeleteTask: PropTypes.func,
            onViewConfig: PropTypes.func
        };
    }

    static get defaultProps(){
        const nop = function(){};
        return {
            onViewConfig: nop,
            onRollbackTask: nop,
            onRescheduleTask: nop,
            onCancelTask: nop,
            onDeleteTask: nop
        };
    }


    render(){

        const {data} = this.props;

        let createdTime = new Date(data.timeCreated);

        let executionTime = new Date(data.timeToExecute || data.timeCreated);

        const status = data.status;
        const header = dateFormat(executionTime,'mmmm dd, yyyy');
        const timestamp = dateFormat(executionTime,'h:MM:ss TT');
        const consoleText = (data.status.slice(0,1).toUpperCase() + data.status.slice(1)) + (data.log ? ": "+data.log : "");

        const {onRollbackTask, onRescheduleTask, onCancelTask, onDeleteTask, onViewConfig} = this.props;

        const actions = [
                <IconFlatButton icon={InfoIcon} label="View Config" onClick={onViewConfig.bind(null,data)} />,
                (status === "completed") ? <IconFlatButton icon={RollbackIcon} label="Rollback" onClick={onRollbackTask.bind(null,data)}/> : null,
                (status === "pending") ? <IconFlatButton icon={RescheduleIcon} label="Reschedule" onClick={onRescheduleTask.bind(null,data)}/> : null,
                (status === "pending") ? <IconFlatButton icon={CancelIcon} label="Cancel" onClick={onCancelTask.bind(null,data)}/> : null,
                (status ==="completed" || status === "error" || status === "cancelled") ? <IconFlatButton icon={DeleteIcon} label="Delete" onClick={onDeleteTask.bind(null,data)}/> : null
        ];

        return (
            <Task
                status={status}
                header={header}
                timestamp={timestamp}
                consoleText={consoleText}
                actions={actions}

                createdTime={createdTime}
            />
        );
    }
}

/*
██████  ██  █████  ██       ██████   ██████
██   ██ ██ ██   ██ ██      ██    ██ ██
██   ██ ██ ███████ ██      ██    ██ ██   ███
██   ██ ██ ██   ██ ██      ██    ██ ██    ██
██████  ██ ██   ██ ███████  ██████   ██████
*/

class ConfigHistoryDialog extends React.Component {

    constructor(){
        super();

        this.handleClose = this.handleClose.bind(this);

        this.handleRollbackTask = this.handleRollbackTask.bind(this);
        this.handleRescheduleTask = this.handleRescheduleTask.bind(this);
        this.handleCancelTask = this.handleCancelTask.bind(this);
        this.handleDeleteTask = this.handleDeleteTask.bind(this);
        this.handleViewConfig = this.handleViewConfig.bind(this);

        this.handleCloseConfirm = this.handleCloseConfirm.bind(this);
        this.handleCloseScheduler = this.handleCloseScheduler.bind(this);
        this.refreshTasks = this.refreshTasks.bind(this);
        this.handleCloseConfigText = this.handleCloseConfigText.bind(this);


        this.state={
            confirmProps:{
                open:false
            },
            schedulerProps:{
                open:false
            }
        };
    }

    static get propTypes(){
        return {
            open: PropTypes.bool,
            actions: PropTypes.object.isRequired,
            data: PropTypes.object
        };
    }

    static get defaultProps(){
        return {
            open: false,
            data:{
                appliances:[]
            }
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(!nextProps.data.appliances || !nextProps.data.appliances[0]) return;
        if(!this.props.data.appliances || this.props.data.appliances[0]._id !== nextProps.data.appliances[0]._id){
            this.props.actions.requestTasks(nextProps.data.appliances[0]._id,SET_CONFIG);
            this.props.actions.requestAppliances(); // keep them in sync
        }
    }

    handleClose ()  {
        this.props.actions.closeDataEntry();
    }


    refreshTasks(){
        const {data} = this.props;
        this.props.actions.requestTasks(data.appliances[0]._id,SET_CONFIG);
    }

    handleConfirmRollback(task,time){
        this.props.actions.createTask(task.applianceId,task.command.name,task.command.args,
        (!time) ? undefined : time,()=>{
            this.handleCloseScheduler();
            this.refreshTasks();
        });
    }

    handleRollbackTask(task){
        this.setState({
            schedulerProps:{
                open:true,
                subtitle:"Rollback Configuration",
                labelText:"Rollback",
                confirmText:"Schedule",
                confirmColor:undefined,
                content:undefined,
                onSubmit: this.handleConfirmRollback.bind(this,task)
            }
        });
    }

    handleConfirmRescheduleTask(task,time){
        this.props.actions.cancelTask(task._id);
        this.props.actions.createTask(
            task.applianceId,
            task.command.name,
            task.command.args,
            (!time) ? undefined : time,
            ()=>{
                this.handleCloseScheduler();
                this.refreshTasks();
            }
        );
    }

    handleRescheduleTask(task){
        this.setState({
            schedulerProps:{
                open:true,
                subtitle:"Reschedule Configuration Update",
                labelText:"Reschedule to",
                confirmText:"Schedule",
                confirmColor:undefined,
                content:undefined,
                onSubmit: this.handleConfirmRescheduleTask.bind(this,task)
            }
        });
    }

    handleConfirmCancelTask(task){
        this.props.actions.cancelTask(task._id,this.refreshTasks);
        this.handleCloseConfirm();
    }

    handleCancelTask(task){
        this.setState({
            confirmProps:{
                open: true,
                subtitle:"Cancel Configuration Update",
                confirmText:undefined,
                confirmColor:undefined,
                content:"Do you wish to cancel this task?",
                onSubmit:this.handleConfirmCancelTask.bind(this,task)
            }
        });
    }

    handleConfirmDeleteTask(task){
        this.props.actions.deleteTask(task._id,this.refreshTasks);
        this.handleCloseConfirm();
    }

    handleDeleteTask(task){
        this.setState({
            confirmProps:{
                open: true,
                subtitle:"Delete Configuration Update Record",
                confirmText:"Delete",
                confirmColor: "red",
                content:"Do you wish to delete this task from history?",
                onSubmit:this.handleConfirmDeleteTask.bind(this,task)
            }
        });
    }

    handleViewConfig(task){
        const config = task.command.args.configuration;
        let stamp = task.timeCreated;
        this.setState({
            configTextOpen: true,
            configTextObject: config,
            timeStamp:stamp
        });

    }

    handleCloseConfirm(){
        this.setState({
            confirmProps:{
                open: false
            }
        });
    }
    handleCloseScheduler(){
        this.setState({
            schedulerProps:{
                open: false
            }
        });
    }

    handleCloseConfigText(){
        this.setState({
            configTextOpen: false
        });
    }

    render() {

        const {
            data,
            actions, // eslint-disable-line no-unused-vars
            ...other
        } = this.props;


        if(!data.appliances || !data.appliances[0]) return null; // obviously not visible.

        let appTasks = data.tasks[data.appliances[0]._id];
        const history = ( appTasks && appTasks[SET_CONFIG] ) || [];

        const WrappedConfigTask = (props) => (
            <ConfigTask
                key={props.data._id}
                onRollbackTask={this.handleRollbackTask}
                onRescheduleTask={this.handleRescheduleTask}
                onCancelTask={this.handleCancelTask}
                onDeleteTask={this.handleDeleteTask}
                onViewConfig={this.handleViewConfig}
                {...props}
            />
        );

        let content = null;
        if(data.appliances[0]){
            content = (
                    <div style={{marginBottom:'1em'}} key={1}>
                        <span style={{fontWeight:'bold'}}>{"Appliance: "}</span>
                        <span>{data.appliances[0].name}</span>
                    </div>
            );
        }

        return (
            <StyledDialog
                title="Configuration Updates"
                //modal={false}
                open={this.props.open}
                onRequestClose={this.handleClose}
                onClose={this.handleClose}
                actions={[]}
                maxWidth={false} 
                {...other}
                // fullHeight
                >
                <div style={{width: 720,display:'inline-block',textAlign:'left', height: 480}}>
                    {content}
                    <div style={{position:'absolute',top:'7em',bottom:'24px',left:0,right:0,overflowY:'auto'}}>
                        <div style={{padding:'0 24px', width: 700}}>
                            <HistoryTable taskRenderer={WrappedConfigTask} history={history} key={2}/>
                        </div>
                    </div>

                    <ConfirmDialog {...this.state.confirmProps} onClose={this.handleCloseConfirm} />
                    <SchedulerDialog {...this.state.schedulerProps} onClose={this.handleCloseScheduler} onError={this.props.actions.displayErrorDialog}/>
                    <ViewConfigDialog open={this.state.configTextOpen} timestamp={this.state.timeStamp} configuration={this.state.configTextObject} onRequestClose={this.handleCloseConfigText}/>

                </div>
                </StyledDialog>
            );
        }
    }

export default ConfigHistoryDialog;
