import React from 'react';
import Filter from '../../../util/report/filter/Filter';
import SortedChecklist from '../../../util/SortedChecklist';
import DatetimeFilter from '../../../util/report/filter/DatetimeFilter';
//upgradeReact
/*
import RestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import ListIcon from '@material-ui/icons/ViewList';
import {black} from '@material-ui/core/colors';
*/
import RestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import ListIcon from '@material-ui/icons/ViewList';
import {getSidebarSizes} from '../../../../../app/constants/Responsive';
import Resizer from '../../../home/sidebar/Resizer';
import * as actions from '../../../../actions';
import {connect} from "react-redux";
import {bindActionCreators} from 'redux';
import moment from 'moment/moment.js';
import Popover from "@material-ui/core/Popover";

//let today = () => moment().startOf('day');
//let tomorrow = () => today().add(1, 'days');

class ImportFilter extends React.Component {
    
    constructor(props) {
        super(props);
        const defaultWidths = this.__getDefaultWidths(props);
        
        this.state = {
            openList: false,//show the popover to select the visible filters or filters order
            animationEnabled: false,
            leftSidebarWidth: defaultWidths.leftSidebarWidth,
            leftSidebarResizing: false,
            anchorEl: null,
            filtersOrdered: null
        };

        this.handleTransitionEnd = this.handleTransitionEnd.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.scrollHandler = this.scrollHandler.bind(this);
        this.handleRequestClosePopover = this.handleRequestClosePopover.bind(this);
        this.handleRequestOpenPopover = this.handleRequestOpenPopover.bind(this);

        this.handleRequestFilterValues = this.handleRequestFilterValues.bind(this);
        this.handleChangeFilterValue = this.handleChangeFilterValue.bind(this);
        this.getNonDefaultFiltersCount = this.getNonDefaultFiltersCount.bind(this);
        this.generateFilterOrder = this.generateFilterOrder.bind(this);
        this.isTimeDefaultValue = this.isTimeDefaultValue.bind(this);

    }

    __getDefaultWidths(props) {
        const widths = getSidebarSizes();
        return {
            leftSidebarWidth: widths.defaultWidth,
        };
    }

    handleTransitionEnd() {
        this.setState({
            animationEnabled: false
        });
    }

    handleResize(e) {
        const sidebarWidth = `${e.identifier}Width`;
        const sidebarResizing = `${e.identifier}Resizing`;
        if (e.event === 'mousedown') {
            this.setState({
                animationEnabled: false,
                [sidebarResizing]: true,
                [sidebarWidth]: e.width || this.state[sidebarWidth],
            });
        } else if (this.state[sidebarResizing]) {
            this.setState({
                animationEnabled: false,
                [sidebarResizing]: e.resizing,
                [sidebarWidth]: e.width || this.state[sidebarWidth],
            });
        }
    }

    scrollHandler() {
        if (document.activeElement.tagName !== 'BODY' && !this.props.isMobile) {
            document.activeElement.blur();
        }
    }

    handleRequestClosePopover() {
        this.setState({
            openList: false,
            anchorEl: null
        });
    }

    handleRequestOpenPopover(event) {
        this.setState({
            openList: true,
            anchorEl: event.currentTarget
        });
    }

    handleRequestFilterValues(name) {
        const filters = this.state.filtersOrdered.filter(item => item.type === 'normal');
        return this.props.actions.getFilterOptions(name, filters);
    }

    handleChangeFilterValue(name, value) {
        this.props.actions.changeNormalFilterValue(name, value);
    }

    renderFilterItems() {
        const {isMobile, isMobileDevice, filters} = this.props;
        const {filtersOrdered} = this.state;    
        if(filtersOrdered){
            const enabledFilters = filtersOrdered.filter(filter => filter.enabled===true);
            const filterValues = enabledFilters.map(item => filters[item.filter]);
            if(filterValues.length > 0){
                return(
                    <div>            
                        {filterValues.map((filterItem, index) => {
                            const filter = {...filterItem} ;            
                            filter.id = filter.name;
                            switch (filter.type) {
                                case 'normal': {
                                    const actions = {
                                        requestFilterValues: this.handleRequestFilterValues,
                                        changeFilterValue: this.handleChangeFilterValue
                                    };
                                    return (
                                        <div className={"filter"} key={index}>
                                            <span style={{paddingLeft:'2px', display:'inline-Block'}} className={filter.value !== filter.defaultValue ? 'highlight' : ''}>{filter.displayName}</span>
                                                <Filter filter = {filter} actions={actions} isMobile={isMobile} isMobileDevice={isMobileDevice}/>
                                        </div>
                                    );
                                }                    
                                default:{
                                    const actions = {
                                        changeFilterValue: this.props.actions.changeFilterValueImport,
                                        changeFilterFromToValue: this.props.actions.changeFilterFromToValueImport,
                                        changeSpecialFilterValue: this.props.actions.changeSpecialFilterValue
                                    };
                                    return(
                                        <div className={"filter"} key={index}>
                                        <span style={{paddingLeft:'2px', display:'inline-Block'}} className={this.isTimeDefaultValue() ? 'highlight' : ''}>
                                            {'Imported Date'}
                                        </span>
                                        <DatetimeFilter
                                            reportId={"import"}
                                            name={"importDate"}
                                            filters={this.props.filters}
                                            actions={actions}
                                            isMobile={isMobile}
                                            isMobileDevice={isMobileDevice}
                                        /> 
                                        </div>                                
                                    );
                                }
                            }            
                        })}
                    </div>
                ); 
            }else{
                return(
                    <span>No Filters</span>
                );
            }              
        }else{
            return null;
        }

    }

    isTimeDefaultValue(){
        const {filters} = this.props;
        const {filtersOrdered} = this.state;
        //getting the names of the datetime filters
        let nameFilterFrom = filtersOrdered.find(item => item.type === "datetime").filter;
        let nameFilterTo = nameFilterFrom.replace("From","To");
        const from = filters[nameFilterFrom];
        const to = filters[nameFilterTo];
        if( (moment(from.value).format('LL') !== moment(from.defaultValue).format('LL')) ||
            (moment(to.value).format('LL') !== moment(to.defaultValue).format('LL'))){
            return true;
        }else{return false;}
    }
    
    getNonDefaultFiltersCount(){
        const {filtersOrdered} = this.state;
        const {filters} = this.props;
        if(filtersOrdered){
            const importFilters = filtersOrdered.map(item => filters[item.filter]).filter(object => object.type!=='datetime');
            let count = 0;
            importFilters.forEach(filter =>{
                    if(filter.value !== filter.defaultValue){
                        count++;
                    }
            })
            return this.isTimeDefaultValue()? count+1: count;
        }else{return 0;}        
    }

    componentDidMount(){
        const {reportId, columnMeta, sidebarColumnOrder, sidebarColumnMeta} = this.props;
        this.generateFilterOrder(reportId, columnMeta, sidebarColumnOrder, sidebarColumnMeta);
    }
    UNSAFE_componentWillReceiveProps(nextProps){
        
        if(this.props.columnMeta !== nextProps.columnMeta || this.props.reportId !== nextProps.reportId
           || this.props.sidebarColumnOrder !== nextProps.sidebarColumnOrder || this.props.sidebarColumnMeta !== nextProps.sidebarColumnMeta){
            this.generateFilterOrder(nextProps.reportId, nextProps.columnMeta, nextProps.sidebarColumnOrder, nextProps.sidebarColumnMeta);
        }
        
    }

    generateFilterOrder(reportId, columnMeta, sidebarColumnOrder, sidebarColumnMeta){
        const importFiltersOrder = sidebarColumnOrder[reportId];
        const importFiltersMeta = columnMeta[reportId];         
        const importColumnMeta = sidebarColumnMeta[reportId];
        
        if(importFiltersOrder && importFiltersMeta && importColumnMeta){
            const sidebarColumns = importFiltersOrder.map(col => ({...col, ...importColumnMeta[col.name]}));          
            this.setState({filtersOrdered: sidebarColumns});
        }
    }


    render() {
        const { openList } = this.state;
        let filtersClassName = 'sidebar-filters ' + (this.props.isMobile ? 'expanded' : 'collapsed');
        if (this.props.animationEnabled) {
            filtersClassName += ' animation';
        }
        return (
            <Resizer
                className={filtersClassName}
                width={this.state.leftSidebarWidth}
                resizing={this.state.leftSidebarResizing}
                handleResize={this.handleResize}
                collapsed={this.props.sidebarCollapsed}
                separatorPosition="right"
                identifier="leftSidebar"
                onTransitionEnd={()=>{this.handleTransitionEnd()}}
                isMobile={this.props.isMobile}
            >
                <div className={"sidebar-header"}>
                    <ul>
                        <li>
                            <div>
                                <button onClick={this.handleRequestOpenPopover} className="material-button" title="Show Filters" style={{backgroundColor:'#fff',marginLeft:'0.3em',paddingTop:'10px'}} >
                                <ListIcon style={{color:"#000"}}/>
                            </button>
                            </div>
                        </li>
                        <li>
                            <button className="material-button" title="Reset Filters" style={{backgroundColor:'#fff',paddingTop:'10px'}} onClick={this.props.actions.resetImportFilters}>
                                <RestoreIcon style={{color: "#000"}} />
                            </button>
                        </li>
                        <li>
                            {this.getNonDefaultFiltersCount() > 0 && <div className="filter-label">
                                <span> {this.getNonDefaultFiltersCount()} filters</span>
                            </div>}
                        </li>
                    </ul>    
                    <Popover open={openList}
                             anchorEl={this.state.anchorEl}
                             anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                             transformOrigin={{horizontal: 'right', vertical: 'top'}}
                             onClose={this.handleRequestClosePopover}
                             transitionDuration={100}
                    >
                        {this.state.filtersOrdered &&
                            <SortedChecklist
                            selectAllText="Filters"
                            className="columns-drop-down"
                            data={this.state.filtersOrdered}
                            onMoveItem={this.props.actions.moveImportFilterItem}
                            onCheckItem={this.props.actions.toggleImportFilter}
                            onCheckSelectAll={this.props.actions.toggleAllImportFilters}
                            keyAttribute={"name"}
                            labelAttribute={"displayName"}
                            checkedAttribute={"enabled"}/>
                        }                        
                    </Popover>                          

                </div>
                <div className="filter-container" onScroll={this.scrollHandler}>
                    {this.state.filtersOrdered && this.renderFilterItems()}
                </div>
            </Resizer>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = state => ({
    isMobile: state.resize.isMobile,
    isMobileDevice: state.resize.isMobileDevice,
    importHistoryList: state.importReport.importHistoryList,
    reportId: state.report.reportId,
    columnMeta: state.report.columnMeta,
    sidebarColumnOrder: state.report.sidebarColumnOrder,
    sidebarColumnMeta: state.report.sidebarColumnMeta,
    nonDefaultFiltersCounter: state.importReport.nonDefaultFiltersCounter,
    filters: state.filters     
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportFilter);