import React from 'react';
import {Button, DialogActions, DialogTitle} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog/Dialog";
import {DialogContent} from '@material-ui/core/es';

const ErrorAlert = ({open, message, closeAlert, width, icon}) => {

    return (
        <Dialog
            open={open}
            onClose={closeAlert}
            maxWidth="md"
        >
            <DialogTitle style={{paddingRight: 50}}>
                <div style={{fontWeight:'300',fontSize:'0.9em'}}>
                    Error:
                </div>
            </DialogTitle>
            <DialogContent style={{maxHeight: '415px', overflow:'hidden'}}>
                <div style={{width: width || 'auto', display:'flex',textAlign:'left', height: 'auto'}}>
                    {icon}<pre style={{fontFamily: 'inherit', fontSize:'inherit'}}>{message}</pre>
                </div>
            </DialogContent>
            <DialogActions>
                <Button style={{background: '#fff', color: '#007CB0', padding: '0.5em', textTransform: 'none'}} onClick={closeAlert} component="span">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ErrorAlert;
