import React from 'react';
import PropTypes from 'prop-types';

import {Field} from '../../../../util/DataEntryTabularForm';
import Checkbox from '@material-ui/core/Checkbox';

import BaseFilterForm from './BaseFilterForm';

export default class CheckboxControlledFilterForm extends React.Component{
    static get propTypes(){
        return {
            schema: PropTypes.arrayOf(
                PropTypes.shape({
                    key: PropTypes.string.isRequired,
                    type: PropTypes.string.isRequired,
                    label: PropTypes.string.isRequired
                })
            ),
            onChange: PropTypes.func,
            enabled: PropTypes.bool
        };
    }

    static get defaultProps(){
        return {
            schema: [],
            onChange: ()=>{}
        };
    }

    constructor(){
        super();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.renderField = this.renderField.bind(this);
    }

    handleInputChange(field, value){

        const {key,type} = field;

        if(type==="number"){
            value = parseInt(value);
        }

        this.props.onChange({
            [key]: value
        });
    }

    componentDidMount(){
        if(!this.props.enabled){
            this.resetFieldValues();
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.enabled && !this.props.enabled){
            this.resetFieldValues();
        }
    }

    resetFieldValues(){

        let change = this.props.schema.reduce((changeset,field)=>{
            const {key,type} = field;
            let value = field.disabledValue;
            if(type === "number") value = parseInt(value);
            return {
                ...changeset,
                [key]:value
            };
        },{});

        this.props.onChange(change);
    }

    render(){

        const {schema} = this.props;

        return (
            <BaseFilterForm {...this.props}>
                <Field>
                    <table>
                        <tbody>
                            {schema.map(this.renderField)}
                        </tbody>
                    </table>
                </Field>
            </BaseFilterForm>
        );
    }

    renderField(field){

        const {enabled} = this.props;
        const currentValue = this.props[field.key];
        const defaultEnabled = enabled && currentValue !== field.disabledValue;

        return (
            <CheckboxControlledField
                label={field.label}
                defaultEnabled={defaultEnabled}
                onChange={this.handleInputChange.bind(null,field)}
                defaultValue={defaultEnabled ? currentValue : field.defaultValue}
                type={field.type}
                key={field.key}
                disabledValue={field.disabledValue}
            />
        );
    }
}

class CheckboxControlledField extends React.Component{

    static get propTypes(){
        return {
            label: PropTypes.string,
            defaultEnabled: PropTypes.bool,
            onChange: PropTypes.func,
            defaultValue: PropTypes.any,
            type: PropTypes.any,
            disabledValue: PropTypes.any
        };
    }

    static get defaultProps(){
        return {
            onChange: ()=>{},
            defaultEnabled: false
        };
    }

    constructor(props){
        super(props);

        this.state = {
            enabled: props.defaultEnabled
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(this.props.defaultEnabled !== nextProps.defaultEnabled){
            this.setState({
                enabled: nextProps.defaultEnabled
            });
        }
    }

    handleInputChange(event){
        if(!this.state.enabled) return;
        this.props.onChange(event.currentTarget.value);
    }

    handleCheckChange(event,checked){
        this.setState({
            enabled: checked
        });

        if(checked){
            this.props.onChange(this.refs.input.value);
        }else{
            this.props.onChange(this.props.disabledValue);
        }
    }

    render(){
        const {label,defaultValue,type} = this.props;
        const {enabled} = this.state;

        return (
            <tr style={{padding:'0.5em'}}>
                <td style={{padding:'1.5em 0'}}>
                    <Checkbox
                        // labelStyle={{color:'inherit'}}
                        onChange={this.handleCheckChange}
                        checked={enabled}
                        style={{backgroundColor:'transparent', color: enabled?'#43a047':'inherit', maxHeight: 10}}
                        disableTouchRipple/>
                </td>
                <td style={{padding:'0 0.5em'}}>{label}</td>
                <td>
                    <input
                        type={type}
                        defaultValue={defaultValue}
                        onChange={this.handleInputChange}
                        disabled={!enabled}
                        ref="input"/>
                </td>
            </tr>
        );
    }
}
