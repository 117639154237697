import React from 'react';
import PropTypes from 'prop-types';


import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import RefreshIcon from '@material-ui/icons/Refresh';

import WrappedTable from '../../util/report/WrappedTable';

import UpdateUserGroupDialog from './forms/UpdateUserGroupDialog';
import DeleteUserGroupDialog from './forms/DeleteUserGroupDialog';
import AddUserGroupDialog from './forms/AddUserGroupDialog';

import ProppedButton from '../../util/button/ProppedButton';

import {USERMGMT_ADD_GROUP, USERMGMT_DELETE_GROUP, USERMGMT_UPDATE_GROUP} from '../../../constants/DataEntryForms';


import SelectWrapper from './SelectWrapper';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../../../actions';
import {generateComponentId} from "../../../utils/ComponentUtils";

class UserGroupsView extends React.Component{

    constructor(){
        super();

        this.renderBodyCellContent = this.renderBodyCellContent.bind(this);
        this.getRowHeight = this.getRowHeight.bind(this);
    }

    componentDidMount(){
        this.refreshGroups();
    }

    refreshGroups(){
        this.props.actions.requestUserGroups();
    }

    static get propTypes(){
        return {
            actions: PropTypes.object.isRequired,
            data: PropTypes.array,
            columns: PropTypes.array,
            dataEntryOpen: PropTypes.bool,
            groups: PropTypes.array,
            groupColumns: PropTypes.array,
            canEdit: PropTypes.bool
        };
    }

    renderBodyCellContent({columnIndex,datum}){
        const {groupColumns} = this.props;
        if(columnIndex<0) return "";
        const col = groupColumns[columnIndex];
        const d = datum[col.name];

        // eslint-disable-next-line
        switch(columnIndex){
            case 0:
            case 1:
                return d;
        }

        let contentA, contentB;
        if(col.name === "role"){
            contentA = d;
            contentB = (d === "View") ? "Edit" : "";
        }else{
            contentA = datum.permission.view[col.name];
            contentB = datum.permission.edit && datum.permission.edit[col.name];
        }

        var boolFlag = false;

        function convertToString(c){
            // BOOLEAN
            if(c === true){
                boolFlag = true;
                return (<CheckIcon style={{verticalAlign:'middle',color:'#777'}}/>);//"Yes";
            }
            if(c === false){
                boolFlag = true;
                return String.fromCharCode(183);
            }

            // ARRAY
            if(c && c.join){
                return c.join(", ");
            }

            if(!c){
                return String.fromCharCode(183);
            }

            return c;
        }

        contentA = convertToString(contentA);
        contentB = convertToString(contentB);

        var cellStyle = {
            borderBottom:'1px solid #eee',
            padding:'0 5%',
            textAlign:(boolFlag) ? "center":"left"
        };

        return (
            <div style={{lineHeight:'40px'}}>
                <table style={{width:'100%'}}>
                    <tbody>
                        <tr><td style={cellStyle}>{contentA}</td></tr>
                        <tr><td style={cellStyle}>{contentB}</td></tr>
                    </tbody>
                </table>
            </div>
        );
    }

    getRowHeight(){
        return 80;
    }

    handleOpenForm(form,needsSelected){
        var selected = this.refs.table.getSelected();

        if(needsSelected){
            if(selected.length === 0) {
                this.props.actions.displayErrorDialog({
                    message:"Please select a group first."
                });
                return;
            } else if(selected.length > 1) {
                this.props.actions.displayErrorDialog({
                    message:"Please select a single group."
                });
                return;
            }
        }

        this.props.actions.openDataEntry(form,{
            group:selected[0]
        });
    }

    render(){
        const {
            actions,
            groups,
            groupColumns,
            canEdit,
            isMobile
        } = this.props;

        const refreshLabel = "Refresh";
        const addLabel = "Add Group";
        const editLabel = "Edit Group";
        const deleteLabel = "Delete Group";

        let refreshButton = (
            <ProppedButton
                id={generateComponentId(refreshLabel, "usrGroup", "button")}
                label={!isMobile ? refreshLabel : null}
                icon={<RefreshIcon />}
                onClick={this.refreshGroups.bind(this)}
                className={"button-crud-style material-button"}/>
        );

        let editActions = canEdit ? (
            <div className={"user-groups-button-container"}>
                {refreshButton}
                <ProppedButton
                    id={generateComponentId(addLabel, "usrGroup", "button")}
                    label={!isMobile ? addLabel : null}
                    icon={<AddIcon />}
                    onClick={this.handleOpenForm.bind(this,USERMGMT_ADD_GROUP,false)}
                    className={"button-crud-style material-button"}/>
                <ProppedButton
                    id={generateComponentId(editLabel, "usrGroup", "button")}
                    label={!isMobile ? editLabel : null}
                    icon={<EditIcon />}
                    onClick={this.handleOpenForm.bind(this,USERMGMT_UPDATE_GROUP,true)}
                    className={"button-crud-style material-button"}/>
                <ProppedButton
                    id={generateComponentId(deleteLabel, "usrGroup", "button")}
                    label={!isMobile ? deleteLabel : null}
                    icon={<DeleteIcon />}
                    onClick={this.handleOpenForm.bind(this,USERMGMT_DELETE_GROUP,true)}
                    className={"button-crud-style material-button"}/>
            </div>
        ) : (
            <div className={"user-groups-button-container"}>
                {refreshButton}
            </div>
        );

        return (
            <div className="filled-up user-group-report">
                <AddUserGroupDialog />
                <UpdateUserGroupDialog />
                <DeleteUserGroupDialog />
                <div className="user-data-entry">
                    {editActions}
                </div>
                <div className="filled-up" style={{overflow:'hidden',position:'relative',boxSizing:'border-box'}}>
                    <SelectWrapper uidAttribute={"id"} data={groups} ref="table">
                        <WrappedTable
                            actions={actions}
                            data={groups}
                            columns={groupColumns}
                            bodyCellContentRenderer={this.renderBodyCellContent}
                            getRowHeight={this.getRowHeight}
                            isSelected={null}
                            onSelectAll={null}
                            initiaColumnSizeOffset={60}
                            adjustColumns
                            disableFillTable
                            disableSort
                            disableFilters
                        />
                    </SelectWrapper>
                </div>
            </div>
        );
    }
}
/*
 ██████  ██████  ███    ██ ███    ██ ███████  ██████ ████████     ████████  ██████
██      ██    ██ ████   ██ ████   ██ ██      ██         ██           ██    ██    ██
██      ██    ██ ██ ██  ██ ██ ██  ██ █████   ██         ██           ██    ██    ██
██      ██    ██ ██  ██ ██ ██  ██ ██ ██      ██         ██           ██    ██    ██
 ██████  ██████  ██   ████ ██   ████ ███████  ██████    ██           ██     ██████

███████ ████████  █████  ████████ ███████
██         ██    ██   ██    ██    ██
███████    ██    ███████    ██    █████
     ██    ██    ██   ██    ██    ██
███████    ██    ██   ██    ██    ███████
*/


const mapStateToProps = state => ({
    groups: state.userMgmt.groups,
    groupColumns: state.userMgmt.groupColumns,
    canEdit: state.user.permissions['edit-users']
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        actions
    ,dispatch)
});

export default connect(mapStateToProps,mapDispatchToProps)(UserGroupsView);
