import React, {useEffect, useRef, useState} from "react";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {useDispatch, useSelector} from "react-redux";
import {deviceAction, getOperationOptions} from '../../../actions';
import TestingDialog from '../common/dialogs/TestingProcessDialog';
import {useHistory} from 'react-router-dom';
import {
    PATH_EDIT_DEVICE,
    PATH_EDIT_DEV,
    EDIT,
    EDIT_DEV,
    TEST,
    UPGRADE_FIRMWARE,
    DELETE,
    LOGS,
    OPE_STATUS_PENDING,
    DOWNLOAD_LOGS, HISTORICAL, CONFIGURE_LOGS, REBOOT
} from '../../../constants/DeviceManager';
import {
    MAP_REPORT_HORIZONTAL_REVERSE_SPLIT,
    MAP_REPORT_HORIZONTAL_SPLIT,
    REPORT_VIEW
} from "../../../constants/ViewTypes";

import DialogUpdateFirmware from "../common/dialogs/DialogUpdateFirmware";
import DialogConfirm from "../common/dialogs/DialogConfirm";
import CustomAction from "./CustomAction";
import DialogLogSettings from '../common/dialogs/DialogLogSettings';
// import DialogLogDownload from '../common/dialogs/DialogLogDownload';
// import DialogLogsHistory from '../common/dialogs/DialogLogsHistory';
import DeviceActionSubMenu from './DeviceActionSubMenu';
import AlertMessage from '../common/AlertMessage';

function EditMenu(props) {
    const {rowIndex, tableHeight} = props;
    const [openTest, setOpenTest] = useState(false);
    const [openFirmware, setOpenFirmware] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openConfigLogs, setOpenConfigLogs] = useState(false);
    /*const [openDownloadLogs, setOpenDownloadLogs] = useState(false);
    const [openHistoricalLogs, setOpenHistoricalLogs] = useState(false);*/
    const [openSubMenu, setOpenSubMenu] = useState(false);
    const [subMenuKey, setSubMenuKey] = useState(null);
    const [openDownloadAlert, setOpenDownloadAlert] = useState(false);
    const [messageAlert, setMessageAlert] = useState("");
    const [value, setValue] = useState(0);
    const [positionY, setPositionY] = useState(0);
    const [actionToExecute, setActionToExecute] = useState({});
    const [openRebootDialog, setOpenRebootDialog] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();
    const windowHeight = useSelector(state => state.resize.windowHeight);
    const device = useSelector(state => state.deviceManager.deviceList[rowIndex]);
    const canEdit = useSelector(state => state.user.permissions['edit-infrastructure-devices']);
    const isOnCloud = useSelector(state => state.deviceManager.isOnCloud);
    const fetchingDownloadLogs = useSelector(state => state.deviceManager.fetchingDownloadLogs);
    const menuDropDown = useRef();
    const view = useSelector(state => state.view['DEVICE_MANAGER']||REPORT_VIEW);

     useEffect(() => {
        if(anchorEl!==null &&  menuDropDown.current){
            let menuProperties = menuDropDown.current.getBoundingClientRect();
            setPositionY(menuProperties.bottom);
        }
    }, [anchorEl,windowHeight]);

        
    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClickAway= () => {
        setAnchorEl(null);
        setOpenSubMenu(false);
    };

    const closeDialog = () => {
        setOpenTest(false);
    };

    const handleCloseConfigLogs = () => {
        setOpenConfigLogs(false)
    };

    /*const handleCloseDownloadLogs = () => {
        setOpenDownloadLogs(false)
    };

    const handleCloseHistoricalLogs = () => {
        setOpenHistoricalLogs(false);
    };*/

    const handleCloseDownloadAlert = () => {
        setOpenDownloadAlert(false);
    };

    const handleActionOption = (e, action, index) => {
        if (action !== LOGS) {
            setAnchorEl(null);
        }
        
        if(action === EDIT){
            const pathEditDevice = `/device-manager/devices/${PATH_EDIT_DEVICE}/${device._id}`;
            history.push(pathEditDevice);
        }else if(action === EDIT_DEV){
            const pathEditDevDevice = `/device-manager/devices/${PATH_EDIT_DEV}/${device._id}`;
            history.push(pathEditDevDevice);
        }else if(action === TEST){
            setOpenTest(true);
        }else if(action === UPGRADE_FIRMWARE){
            setOpenFirmware(true);
        }else if(action === DELETE){
            setOpenDelete(true);
        }else if (action === LOGS) {
            setSubMenuKey(action);
            setOpenSubMenu(true);
        }else if(action === REBOOT){
            setActionToExecute({action: action, index: index});
            setOpenRebootDialog(true);
        }else{
            dispatch(deviceAction(action, index));
        }
    }

    const handleActionLogs = (action) => {
        handleCloseLogsSubMenu();
        if (action === CONFIGURE_LOGS) {
            setOpenConfigLogs(true);
            setValue(0)
        }else if (action === DOWNLOAD_LOGS) {
            if(Object.keys(fetchingDownloadLogs).length === 0) {
                setOpenConfigLogs(true);
                setValue(1)
            } else {
                const key = Object.keys(fetchingDownloadLogs)[0];
                setMessageAlert(`Device "${fetchingDownloadLogs[key].name}" has a download in progress.`)
                setOpenDownloadAlert(true);
            }
        }else if (action === HISTORICAL) {
            setOpenConfigLogs(true);
            setValue(2)
        }
    };

    const handleCloseLogsSubMenu = () => {
        setAnchorEl(null);
        setOpenSubMenu(false);
    }

    const handleRebootAction = () => {
        const {action, index} = actionToExecute;
        dispatch(deviceAction(action, index));
        setActionToExecute(null);
        setOpenRebootDialog(false)
    };


    const getOptionsToShow = (optionsObject) => {
        if (!canEdit) {
            return Object.keys(optionsObject).reduce((acc, keyOption) =>  ({...acc, [keyOption]: keyOption === 'viewDetails'}) ,{})
        }else{
            return optionsObject;
        }
    }

    // calculating top position
    const getHeightMenu = (opts) => {
        const heightMoreButton = 26;
        const heightMenuItem = 20;
        const totalPadding = 4;
        const lines = 2;
        const numberLogsItems = 3;
        const numberItems = Object.keys(opts).length - numberLogsItems;
        const topPosition = numberItems * heightMenuItem + totalPadding + lines + heightMoreButton;
        return  topPosition;
    }

    const options = getOperationOptions(device,isOnCloud);
    const optionsToShow = getOptionsToShow(options);
    const heightMenu = getHeightMenu(optionsToShow);

    let posY = 0;
    if(view === MAP_REPORT_HORIZONTAL_SPLIT){
        posY = positionY - 108;         //position inside the table
    }else if(view === MAP_REPORT_HORIZONTAL_REVERSE_SPLIT){
        posY = positionY - (windowHeight - tableHeight);    //position inside the table
    }else{
        posY = positionY - 108;         //position inside the table
    }

    // height of menu actions
    const showUp = posY>(tableHeight/2);
    let heightRelMenu = 0;
    if(showUp){
        heightRelMenu = posY>heightMenu?heightMenu:posY-40;
    }else{
        heightRelMenu = tableHeight-posY>heightMenu?heightMenu:tableHeight-posY-60;
    }

    return (
        <React.Fragment>
            <ClickAwayListener onClickAway={handleClickAway}>
                <div className="actionContainer" ref={menuDropDown}>
                    {canEdit && <div onClick={(e)=>handleActionOption(e, EDIT_DEV, rowIndex)} className="actionLinkContainer">
                            <span className="actionLink">Edit</span>
                    </div>}
                    <div onClick={handleClick} className="editMainContainer">
                        <MoreVertIcon className="editIcon"/>
                    </div>
                    {anchorEl? <DeviceActionList rowIndex={rowIndex} device={device} showUp={showUp} posY={posY} heightMenu={heightMenu} heightRelMenu={heightRelMenu}
                                    options={optionsToShow} handleActionOption={handleActionOption} />:null}
                    {anchorEl && openSubMenu ?
                        <DeviceActionSubMenu
                            rowIndex={rowIndex}
                            showUp={showUp}
                            itemKey={subMenuKey}
                            handleActionLogs={handleActionLogs}
                            handleCloseLogsSubMenu={handleCloseLogsSubMenu}
                            heightMenu={heightRelMenu}
                            hasScrolling={heightRelMenu<heightMenu} 
                            options={optionsToShow}
                        />
                        : null}
                </div>
            </ClickAwayListener>
            {openTest && <TestingDialog open={openTest} closeDialog={closeDialog} deviceName={device.title} deviceId={device._id}/>}
            {openFirmware && <DialogUpdateFirmware open={openFirmware} closeDialog={()=>setOpenFirmware(false)} rowIndex={rowIndex} />}
            {openDelete && <DialogConfirm
                open={openDelete}
                closeDialog={() => setOpenDelete(false)}
                actionSuccess={() => dispatch(deviceAction(DELETE, rowIndex))}
                actionCancel={()=>{}}
                message={`${device.title} will be removed. Are you sure to remove this device?`}
            />}
            {openConfigLogs && <DialogLogSettings open={openConfigLogs} handleClose={handleCloseConfigLogs} 
                devicesArray={[{...device}]} value={value} isMultiselect={false} logOptions={optionsToShow}/>}
            {/*{openDownloadLogs && <DialogLogDownload open={openDownloadLogs} handleClose={handleCloseDownloadLogs} devicesArray={[device]}/>}*/}
            {/*{openHistoricalLogs && <DialogLogsHistory open={openHistoricalLogs} handleClose={handleCloseHistoricalLogs} deviceId={device._id}/>}*/}
            {openDownloadAlert && <AlertMessage openModal={openDownloadAlert} message={messageAlert} onClose={handleCloseDownloadAlert} />}
            {openRebootDialog &&
                <DialogConfirm
                    open={openRebootDialog}
                    closeDialog={() => setOpenRebootDialog(false)}
                    actionSuccess={handleRebootAction}
                    actionCancel={()=>{}}
                    message={`${device.title} will be rebooted. Are you sure to reboot it?`}
                />
            }
        </React.Fragment>
    );
}

export default EditMenu;

const DeviceActionList = props => {
    const {rowIndex, device, showUp, handleActionOption, options, heightMenu, heightRelMenu, posY} = props;
    // const windowHeight = useSelector(state => state.resize.windowHeight);
    const isOnCloud = useSelector(state => state.deviceManager.isOnCloud);
    let operationPending = null;
    const operationStatus = device.operation_status?.current_status;
    if(operationStatus && operationStatus === OPE_STATUS_PENDING){
        operationPending = device.operation_status.operation;
    }

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    
    const labelOptions = {
        update_firmware: 'Upgrade Firmware',
        initialize: 'Initialize',
        // reset: 'Reset Configuration',
        resync: 'Resync Reader Status',
        viewDetails: 'View Details',
        edit: 'Edit on Map',
        logs: 'Logs',
        publish: 'Publish',
        //test: 'Test', //line commented by ticket MWE-4463
        start: 'Start',
        stop: 'Stop',
        reboot: 'Reboot',
        enable: 'Enable',
        disable: 'Disable',
        delete: 'Delete',
        cancel: operationPending?`Cancel ${capitalizeFirstLetter(operationPending)}`: 'Cancel'
    };

      
    const getEnabledAction = (key) => {
        const specialOperations = ['initialize', 'reset'];
        if(device.protocol === 'https' && specialOperations.indexOf(key) > -1){
            return false;
        }else if(isOnCloud && specialOperations.indexOf(key) > -1){
            return false;
        }else{
            return options[key];
        }
    }

    // position of menu upwards
    const getPositionBottom = () => {
        if(heightRelMenu<heightMenu){
            return posY - 10;
        }else{
            return heightRelMenu;
        }
    }

    return(
        <div className="ListMenu" style={{bottom: showUp?`${getPositionBottom()}px`:'inherit'}}>
            <div className="liMenu" style={{maxHeight: heightRelMenu}}>
                {Object.keys(options).map((key, index) =>
                    <CustomAction key={index}
                        enabled={getEnabledAction(key)} value={labelOptions[key]} 
                        protocol={device.protocol} actionKey={key} 
                        handleAction={(e)=>handleActionOption(e, key,rowIndex)} />
                )}
            </div>
        </div>
    );
};