import React from 'react';
import Divider from "@material-ui/core/Divider/Divider";

const OperationPanel = (props) => {

    return (
        <div id={"Operation"} className="dd-operation-main-container">
            <div className="dd-operation-container-title">
                <span className="dd-operation-title">Operations</span>
            </div>
            <div className="dd-operation-items">
                <span className="dd-operation-items-title">Operation Mode: </span>
                <span style={{textTransform: "capitalize"}}>{props.operationMode}</span>
            </div>
            <Divider className="dd-content-divider" />
            <div className="dd-operation-items">
                <span className="dd-operation-items-title">TagID Filter: </span>
                <span>{props.tagFilter && props.tagFilter.value}</span>
                &nbsp;&nbsp;
                <span className="dd-operation-items-title">Match: </span>
                <span style={{textTransform: "capitalize"}}>{props.tagFilter && props.tagFilter.match}</span>
                &nbsp;&nbsp;
                <span className="dd-operation-items-title">Operation: </span>
                <span style={{textTransform: "capitalize"}}>{props.tagFilter && props.tagFilter.operation}</span>
            </div>
            {props.operationMode === 'inventory' &&
                <React.Fragment>
                    <Divider className="dd-content-divider" />
                    <div className="dd-operation-items">
                        <span className="dd-operation-items-title">Reporting Interval: </span>
                        <span>{props.inventoryInterval && props.inventoryInterval.value}</span>
                        &nbsp;&nbsp;
                        <span className="dd-operation-items-title">Units: </span>
                        <span style={{textTransform: "capitalize"}}>{props.inventoryInterval && props.inventoryInterval.unit}</span>
                    </div>
                    <Divider className="dd-content-divider" />
                    <div className="dd-operation-items">
                        <span className="dd-operation-items-title">Minimum RSSI: </span>
                        <span>{props.minRssi || ''}</span>
                    </div>
                </React.Fragment>
            }
            {props.operationMode === 'portal' &&
                <React.Fragment>
                    <Divider className="dd-content-divider" />
                    <div className="dd-operation-items">
                        <span className="dd-operation-items-title">GPI Port: </span>
                        <span>{props.portalSettings && props.portalSettings.startTrigger?.port}</span>
                        &nbsp;&nbsp;
                        <span className="dd-operation-items-title">Signal: </span>
                        <span style={{textTransform: "capitalize"}}>{props.portalSettings && props.portalSettings.startTrigger?.signal?.toLowerCase()}</span>
                    </div>
                </React.Fragment>
            }

        </div>
    );
};

export default OperationPanel;
