import React from 'react';
import PropTypes from 'prop-types';

import StyledDialog, {CancelButton, SaveButton} from '../../util/Dialog';

import {DataEntryTabularField, DataEntryTabularForm} from '../../util/DataEntryTabularForm';

//import Checkbox from 'material-ui/Checkbox'; // when we add back the other log files we'll need this.

export const TASK_GET_LOGS = "GetLogs";


class CollectLogsDialog extends React.Component {

    constructor(){
        super();

        this.handleSave = this.handleSave.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleDurationChange = this.handleDurationChange.bind(this);

        this.state = {
            standardLogs: true,
            DETs: false,
            BARs: false,
            WTTs: false,
            duration: 0
        };
    }

    static get propTypes(){
        return {
            open: PropTypes.bool,
            data: PropTypes.object,
            onClose: PropTypes.func,
            onError: PropTypes.func,
            onSubmit: PropTypes.func
        };
    }

    static get defaultProps(){
        return {
            open: false,
            data:{ // please remove this...
                appliances:[{
                    uuid:"94ec08bf-2f83-4181-8c91-119dbf53bec8"
                }]
            },
            onClose:function(){},
            onError:function(){},
            onSubmit:function(){}
        };
    }

    handleCheck(key,event,checked){
        this.setState({
            [key]:checked
        });
    }

    handleDurationChange(e){
        this.setState({
            duration: e.target.value
        });
    }

    handleClose ()  {
        this.props.onClose();
    }

    handleSave(){

        const {duration,DETs,BARs,WTTs,standardLogs} = this.state;

        // validation
        if(parseInt(duration) === 0 && (DETs || BARs || WTTs)){
            this.props.onError({message:"Duration must be greater than 0 for .DETs, .BARs, and .WTTs."});
            return;
        }

        this.props.onSubmit(
            this.props.data.appliances[0]._id,
            TASK_GET_LOGS,{
                standardLogs,
                DETs,
                BARs,
                WTTs,
                duration
            },
            undefined,
            this.handleClose
        );
    }

    renderForm(){

        const _data = this.props.data.appliances;
        if(!_data || !_data[0]) return null;
        const data = _data[0];

        /*
        // In "Log Files"
        <DataEntryTabularField label={"Log Files"}>
            <Checkbox label="Standard Log Files" defaultChecked onCheck={this.handleCheck.bind(this,"standardLogs")}/>
            <Checkbox label=".DET Detects" onCheck={this.handleCheck.bind(this,"DETs")}/>
            <Checkbox label=".BAR Brief Association Reports" onCheck={this.handleCheck.bind(this,"BARs")}/>
            <Checkbox label=".WTT Wireless Time Traces" onCheck={this.handleCheck.bind(this,"WTTs")}/>
        </DataEntryTabularField>
        <DataEntryTabularField label="Duration">
            <input type="number" style={{width:'4em',marginRight:'0.5em'}} defaultValue={0} onChange={this.handleDurationChange}/> minutes
        </DataEntryTabularField>
         */

        return (
            <DataEntryTabularForm fullWidth>
                <DataEntryTabularField label={"Appliance"}>
                    {data.name}
                </DataEntryTabularField>
            </DataEntryTabularForm>
        );
    }

    render() {

        const {
            actions, // eslint-disable-line
            ...other
        } = this.props;

        const {
            standardLogs,
            DETs,
            WTTs,
            BARs
        } = this.state;

        return (
            <StyledDialog
                title={(
                    <div>
                        <span>Logs</span>
                        <span style={{fontWeight:'300'}}> | </span>
                        <span style={{fontWeight:'300',fontSize:'0.9em'}}>Create new log zip file</span>
                    </div>
                )}
                modal={false}
                open={this.props.open}
                onClose={this.handleClose}
                contentStyle={{display:'inline-block',textAlign:'left', maxWidth: 360}}
                actions={
                    <>
                        <SaveButton label="Submit" onClick={this.handleSave} disabled={!(standardLogs || WTTs || BARs || DETs)} />
                        <CancelButton style={{position: 'absolute', left: 8, bottom: 8}} onClick={this.handleClose} />
                    </>
                }
                {...other}
                >
                {this.renderForm()}
            </StyledDialog>
        );
    }
}

export default CollectLogsDialog;
