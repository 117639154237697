import React from 'react';
import {
    Assignment as Identity, Autorenew as GPO, Cast as Antennas,
    DeveloperMode as Operation, DeviceHub as DeviceHubIcon, Style as Type
} from '@material-ui/icons';
const items = [
    {
        name: "Type",
        displayName: "Device Type",
        icon: <Type />
    },
    {
        name: "Identity",
        displayName: "Identity",
        icon: <Identity />
    },
    {
        name: "Antennas",
        displayName: "Antennas",
        icon: <Antennas />
    },
    {
        name: "Triggers",
        displayName: "Trigger settings",
        icon: <Operation />
    },
    {
        name: "GPO",
        displayName: "GPO (General Purpose Output) Programming",
        icon: <GPO />
    },
    {
        name: "Linked Devices",
        displayName: "Linked Devices",
        icon: <DeviceHubIcon />
    }
];
export default items;