import React from 'react';
import FilterDropDown from './filter/FilterDropdown';
import InputFileComponent from './filter/InputFileComponent';
import FontIcon from '@material-ui/core/Icon';
import {ReportService} from '../../../../app/actions/util';
// import dateFormat from 'dateformat';
import FileSaver from "file-saver";
import WrappedTable from "../../util/report/WrappedTable";
import SampleFields from "./SampleFields";
import {connect} from "react-redux";
import {bindActionCreators} from 'redux';
import {
    getImportById,
    getImportFields,
    getImportStatus,
    importFromFile,
    loadResourceCustomAttibuteSettings
} from '../../../actions';
import {Grid} from '@material-ui/core';

class ModalImport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            valueObject: 'Please select an option',
            valueObjectChildren: 'Please select an option',
            menu: [],
            menuChildren: [],
            dataQuery: [],
            percentage: 0,
            viewProcessBar: 'block',
            waitDialogOpen: false,
            disableComponent: false,
            settingIcon: {
                view: false,
                name: '',
                color: '',
                label: ''
            },
            importStatusData: {},
            showAnswer: false,
            showErrorTable: false,
            errorTable: [],
            errorColumns : [
                {
                    caption: "Row",
                    displayName: "Row",
                    name: "row",
                    enabled: true,
                    filter: 125,
                    sort: 0
                },
                {
                   caption: "Code",
                   displayName: "Code",
                   name: "code",
                   enabled: true,
                   filter: 123,
                   sort: 0
               }, {
                   caption: "Message",
                   displayName: "Message",
                   name: "msg", 
                   enabled: true,
                   filter: 124,
                   sort: 0
               },{
                    caption: "Data",
                    displayName: "Data",
                    name: "data", 
                    enabled: true,
                    filter: 126,
                    sort: 0
               }               
            ],
            columnWidths:{
                code:100,
                row:100,
                msg:300,
                data:300
            } 
        };
        //functions
        const {reportId} = this.props;
        this.loadFormsForReport(reportId);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeChildren = this.handleChangeChildren.bind(this);
        this.handleFileChosen = this.handleFileChosen.bind(this);
        this.handleGetHeaders = this.handleGetHeaders.bind(this);

        //width of a column
        this.getBaseColumnWidth = this.getBaseColumnWidth.bind(this);
        this.handleResizeColumn = this.handleResizeColumn.bind(this);
    };



    static get propTypes() {
        return {
        };
    };

    //width of a column
    getBaseColumnWidth({index}){
        const {errorColumns,columnWidths} = this.state;
        return columnWidths[errorColumns[index].name] || 200;
    }

    handleResizeColumn(index,width){
        const {errorColumns,columnWidths} = this.state;
        if (errorColumns[index] && errorColumns[index].name) {
            this.setState({
                columnWidths:{
                    ...columnWidths,
                    [errorColumns[index].name] : width
                }
            });
        }
    }

    changeValueState(stateValues) {
        this.setState({
            ...stateValues,
            percentage: 0,
            viewProcessBar: 'none',
            settingIcon: {
                view: false,
                name: '',
                color: '',
                label: ''
            }
        });
    };

    handleRequestMenu(){}

    handleRequestChildrenMenu() {}

    handleChange(){}

    handleGetHeaders() {
        const {valueObjectChildren, valueObjectChildrenId, menuChildren} = this.state;
        this.props.getImportFields(valueObjectChildrenId)
        .then(res => {
            if (res.data && res.data.results !== "Error getting form fields") {
                const lines = [];
                const headers = [];
                const samples = [];
                let maxLength = 0;
                for (const key in res.data.results) {
                    const value = res.data.results[key];
                    if (typeof key === 'string') {
                        headers.push('"' + key + '"');
                        if (SampleFields.hasOwnProperty(value)) {
                            maxLength = Math.max(maxLength, SampleFields[value].length);
                        }
                    }
                }

                for (const key in res.data.results) {
                    const value = res.data.results[key];
                    if (typeof key === 'string') {
                        let arr = [""];
                        if (key === "Mode") {
                            if (valueObjectChildren.indexOf("Add") === 0) {
                                arr = ["A"];
                            } else if (valueObjectChildren.indexOf("Update") === 0) {
                                arr = ["U"];
                            } else {
                                //There are not considered cases.
                                //It's left in 'U' by the moment.
                                arr = ["U"];
                            }
                        } else if (SampleFields.hasOwnProperty(value)) {
                            if (value === 'pAttributeName'){
                                arr = this.props.tokenReplacements;
                            }else{
                                arr = SampleFields[value];
                            }                               
                        }
                        samples[key] = this.extendArray(arr, maxLength);
                    }
                }

                lines.push(headers.join(','));
                for (let rowIndex = 0; rowIndex < maxLength; rowIndex++) {
                    const line = [];
                    for (const key in res.data.results) {
                        if (typeof key === 'string') {
                            line.push('"' + samples[key][rowIndex] + '"');
                        }
                    }
                    lines.push(line);
                }

                const lineString = lines.join('\n');
                let name = 'sample';
                menuChildren.forEach(child => {
                    if (child.id === valueObjectChildrenId) {
                        name = child.name;
                        name = name.split(' ').join('_') + '_sample';
                    }
                });
                this.downloadFile(`${name}.csv`, lineString, 'csv');
            } else {
                console.error("Error getting form fields");
            }   
        });
    }
    downloadFile(fileName, dataString, type){
        var blob = new Blob([dataString], {type: `text/${type};charset='utf-8'`});
        FileSaver.saveAs(blob, fileName);
    }
    /**
     * Copy the elements of array in a new array until this last have 'newlength' elements. 
     * @param array
     * @param newlength
     * @returns {array}
     */
     extendArray(array, newlength) {
        let answer = [], index = 0;
        while (newlength-- > 0) {
            answer.push(array[index]);
            index = (index + 1) % array.length;
        }
        return answer;
    }
    


    handleFileChosen(nameFile) {
        this.setState({
            nameFile: nameFile
        });
        this.changeValueState();
    };
    handleChangeChildren(value){
        let childObj = this.state.menuChildren.filter(m => m.name === value);
        if (value && childObj.length > 0) {
            this.setState({
                valueObjectChildren: value,
                valueObjectChildrenId: childObj[0].id,
            });
            this.changeValueState();
        }
    }

    loadFormsForReport(reportId){
        const { permissions } = this.props;
        if (reportId!=null) {
            ReportService.instance().get('/reports/' + reportId)
                .then(response => {

                    const menuChildren = [];
                    if (permissions[`edit-report-${reportId}`]) {
                        response.data.results.dataEntryActions.filter(element => element.name.indexOf('RFID Label Printing')===-1).forEach(action => menuChildren.push(action));
                    }
                    this.setState({ menuChildren: menuChildren || [] });
                })
                .catch(err => {
                    console.error(err);
                })
                .catch(err => {
                    console.error(err);
                });
        }
    }
    handleButtonClick() {
        this.changeValueState();
        this.setState({ waitDialogOpen: true, disableComponent: true, showAnswer: true });

        const header = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        let formData = new FormData();
        formData.append('inputFile', this.state.nameFile);

        this.props.actions.importFromFile(this.props.reportId,this.state.valueObjectChildrenId,formData,header)
            .then(res => {
                this.setState({ waitDialogOpen: false, viewProcessBar: 'block' });
                if (res.data.correct) {
                    let statusBarUpdate = () => {
                        this.props.getImportStatus(this.props.reportId,this.state.valueObjectChildrenId,1)
                            .then(res => {
                                if (res.data.importing || res.data.elapsedTime){
                                    let bar = res.data.rowsProcessed / res.data.rows * 100;
                                    this.setState({ percentage: Number(bar.toFixed(2)) || 0, importStatusData: res.data });
                                    if (res.data.rowsProcessed === res.data.rows) {
                                        this.setState({ showErrorTable:true, disableComponent: false, settingIcon: { view: true, name: res.data.rowsError ? 'error' : 'check_circle_outline', color: res.data.rowsError ? 'orange' : 'green', label: '' } });
                                    } else {
                                        setTimeout(statusBarUpdate, 200);
                                    } 
                                }                               
                            })
                            .catch((e) =>{
                                this.setState({showErrorTable:true});
                            });
                    };
                    setTimeout(statusBarUpdate, 200);
                } else {
                    this.setState({ disableComponent: false, settingIcon: { view: true, name: 'block', color: 'red', label: res.data.msg } });
                }
            });
    }

    componentDidMount() {
        this.props.loadResourceCustomAttibuteSettings();
    };

    ProgressBar(props) {
        return (
            <div className={"progress-bar-import"}>
                <div className={"container-progress-bar"}>
                    <div className={"progress"}>
                        <div className={"progress-bar"} style={{ width: `${props.percentage}%`, display: `${props.viewProcessBar}` }}>
                            <span>{props.percentage} %</span>
                        </div>
                    </div>
                    {props.settingIcon.view && <div className={"icon-progress-bar"}>
                        <FontIcon className="material-icons" style={{ color: `${props.settingIcon.color}`, width: "24px" }}>{props.settingIcon.name}</FontIcon>
                    </div>
                    }
                </div>
                {props.settingIcon.label && <div className={"message-error"}>{props.settingIcon.label}</div>
                }
            </div>
        )
    };

    render() {
        const _progressBar = <this.ProgressBar percentage={this.state.percentage} viewProcessBar={this.state.viewProcessBar} settingIcon={this.state.settingIcon} />;
        //const heightDiv = this.props.windowWidth < 700 ? 310 : this.props.windowWidth < 1100 ? 200 : 140;
        let disableButtonImport = (this.state.valueObjectChildren==='Please select an option' || this.state.nameFile === undefined) ? true :false;
        let disableButtonFileSample = (this.state.valueObjectChildren==='Please select an option') ? true :false;
        const {errorColumns,showErrorTable, importStatusData } = this.state;
        let tableData;
        if(showErrorTable && importStatusData.resultRow){
            tableData = importStatusData.resultRow.map(item => {
                const row = {};
                errorColumns.forEach(column => {
                    if (column.name === 'row') {
                        row[column.name] = item[column.name] + 1;
                    } else {
                        row[column.name] = item[column.name];
                    }
                });
                return row;
            });
        }

        return ( 
            <div className={"import-container"} style={{minHeight: 156}}>
                <div className={"import-filter-containter"} style={{padding:"0px 0px 0px 0px"}}>
                    <div className={"filters-container"}>
                        <div className={"container-resizer-import"} >
                            <div className={"object-container"}>
                                <span className={"import-title"}>
                                    Report
                                </span>
                                <div style={{display:'block',marginTop:'10px'}}>
                                    <input type='text' className={"input-modal-import"} value={this.props.reportName} disabled/>                                
                                </div>
                            </div>
                        </div>
                        <div className={"container-resizer-import"}>
                            <div className={"action-container"}>
                                <span className={"import-title"}>
                                    Action
                                </span>
                                <div style={{display:'flex',width:'100%'}}>
                                    <div style={{display:'block',width:'75%'}}>
                                        <FilterDropDown
                                            values={this.state.menuChildren}
                                            value={this.state.valueObjectChildren}
                                            onChangeValue={this.handleChangeChildren}
                                            onFocus={() => { this.handleRequestChildrenMenu() }}
                                            isMobile={this.props.isMobile}
                                        />
                                    </div>
                                    <div style={{display:'block',width:'25%',marginLeft:'2px',marginRight:'0px'}}>
                                        <button className={"button-modal-import"} style={{backgroundColor: disableButtonFileSample ? '#d9dee0':'#198cb8'}} 
                                            onClick={this.handleGetHeaders} disabled={disableButtonFileSample}>
                                            <span>Sample File</span>
                                        </button>
                                    </div>                                       
                                </div>                                   
                            </div>
                        </div>
                        <div className={"container-resizer-import-last-element"} >
                            <div className={"input-file-container"}>
                                <span className={"import-title"}>
                                    Select File
                                </span>
                                <InputFileComponent
                                handleFileChosen={this.handleFileChosen}
                                nameFile={this.state.nameFile}/>                                                          
                            </div>
                        </div>
                        <div className={"resource-by-progress-bar-class"}>
                            <span className={"import-title"}>
                                Progress
                            </span>
                            <div className={"import-progress"}>
                                <Grid container style={{paddingBottom: 10}}>
                                    <Grid item xs={8}>
                                        {_progressBar}
                                    </Grid>
                                    <Grid item xs={4}>
                                        <button style={{color:'#fff',marginTop:'7px',fontSize:'14px',verticalAlign:'text-bottom',border:'none',backgroundColor: disableButtonImport ? '#d9dee0':'#198cb8'}} 
                                                onClick={() => {this.handleButtonClick()}} disabled={disableButtonImport} >
                                            IMPORT
                                        </button>
                                    </Grid>
                                </Grid>
                            </div>
                            <hr style={{marginBottom:'5px'}}/>             
                        </div>
                    </div>
                </div>   
                <hr style={{marginBottom:'5px'}}/>             
                {this.state.showAnswer && 
                    <div style={{ fontSize: "14px", backgroundColor: "#f5f5f5",paddingBottom:'0px',marginBottom:'5px'}}>
                        <label htmlFor={"results"} className="label" style={{ paddingLeft: "1em"}}>
                            <b>Import Results</b>
                        </label>
                        <div>
                            <div className={"container-results-import"}>
                                <div className={"container-block-results"}>
                                    <div style={{paddingBottom:'0.2em',paddingTop:'0.2em'}}><span>File Name: {importStatusData.fileName}</span></div>
                                    <div style={{paddingBottom:'0.2em',paddingTop:'0.2em'}}><span>Processed Records: {importStatusData.rowsProcessed}</span></div>                                    
                                </div>
                                <div className={"container-block-results"}>
                                    <div style={{paddingBottom:'0.2em',paddingTop:'0.2em'}}><span>Correct Records: {importStatusData.rowsCorrect}</span></div>
                                    <div style={{paddingBottom:'0.2em',paddingTop:'0.2em'}}><span>Wrong Records: {importStatusData.rowsError}</span></div>
                                </div>                                                            
                            </div>                                                    
                        </div>                    
                    </div>}
                    {showErrorTable && tableData && tableData.length > 0 && 
                        <div style={{overflow:'hidden',position:'relative',minHeight:'190px'}}>
                            <WrappedTable 
                                actions={this.props.actions}
                                data = {tableData} 
                                columns={errorColumns}
                                getRowHeight={this.getRowHeight}
                                isSelected={null}
                                maxInitialColumnSize={500}
                                initiaColumnSizeOffset={60}
                                adjustColumns
                                onSelectAll={null}
                                disableFillTable
                                disableFilters
                                disableSort
                                getBaseColumnWidth={this.getBaseColumnWidth}
                                onResizeColumn={this.handleResizeColumn}
                                backgroundRow={new Array(tableData.length)}
                                onTouchTapRow={null}

                                sites={[]}//The component require this prop
                                maps={[]}//The component require this prop
                                />
                        </div>                 
                    }
            </div >
        );
    };

}

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        loadResourceCustomAttibuteSettings: loadResourceCustomAttibuteSettings,
        getImportFields:getImportFields,
        getImportById:getImportById,
        importFromFile:importFromFile,
        getImportStatus:getImportStatus,
    },dispatch)
});

const mapStateToProps = state => ({
    tokenReplacements: state.importReport.tokenReplacements,
    permissions: state.user.permissions
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalImport);