import React from 'react';
import PropTypes from 'prop-types';

class SummaryTable extends React.Component{
    static get propTypes(){
        return {
            columns: PropTypes.array.isRequired,
            data: PropTypes.array.isRequired
        };
    }

    render(){

        const {columns,data,...other} = this.props;

        const headers = columns.map((v,i)=>(
            <th key={i}>{v.caption}</th>
        ));

        const body = data.map((v,i)=>{
            const cells = columns.map((w,j)=>(
                <td key={j}>{data[i][w.name]}</td>
            ));

            return (
                <tr key={i} className={i%2 ? 'odd' : 'even'}>{cells}</tr>
            );
        });

        return (
            <table {...other} className={"summary-table"}>
                <thead><tr>{headers}</tr></thead>
                <tbody>{body}</tbody>
            </table>
        );
    }
}

export default SummaryTable;
