import * as types from '../constants/ActionTypes';

import {displayErrorFromAxios, ReportService} from './util';
import {changeFilterValue, reloadReportWithChanges, requestReport} from './main';
import {requestMenu} from './menu';

import {OPTION_ALL_WILDCARD} from '../constants/Misc';

import downloadReport from "./util/downloadReport";


/*
 ██████  ██████  ██      ██    ██ ███    ███ ███    ██ ███████
██      ██    ██ ██      ██    ██ ████  ████ ████   ██ ██
██      ██    ██ ██      ██    ██ ██ ████ ██ ██ ██  ██ ███████
██      ██    ██ ██      ██    ██ ██  ██  ██ ██  ██ ██      ██
 ██████  ██████  ███████  ██████  ██      ██ ██   ████ ███████
*/


export const moveColumn = (dragIndex,hoverIndex) => ({type:types.REPORT_MOVE_COLUMN, dragIndex, hoverIndex});

// the column should be toggled based on something a bit more direct than
// the index.
export const toggleColumn = (index,enabled) => {
    return (dispatch) => {
        dispatch({type:types.REPORT_TOGGLE_COLUMN, index, enabled});
    };
};

export const toggleAllColumns = (enabled) => ({type:types.REPORT_TOGGLE_ALL_COLUMNS, enabled});

/*
█████████ ████████ ██      █████████ ███████ ██████  ███████
██           ██    ██         ██     ██      ██   ██ ██
█████        ██    ██         ██     ████    ██████  ███████
██           ██    ██         ██     ██      ██   ██      ██
██        ████████ ███████    ██     ███████ ██   ██ ███████
*/

export const moveFilter = (dragIndex, hoverIndex) => ({type: types.REPORT_MOVE_FILTER, dragIndex, hoverIndex});

export const toggleFilter = (index, enabled) => {
    return (dispatch) => {
        dispatch({type: types.REPORT_TOGGLE_FILTER, index, enabled});
    };
}

export const toggleAllFilters = (enabled) => ({type: types.REPORT_TOGGLE_ALL_FILTERS, enabled});

/*
███████  ██████  ██████  ████████
██      ██    ██ ██   ██    ██
███████ ██    ██ ██████     ██
     ██ ██    ██ ██   ██    ██
███████  ██████  ██   ██    ██
*/


// column should be more direct than index.
export const changeSort = (index,direction,sortDisable) => {
    return (dispatch,getState) => {

        const state = getState();

        // Determine sort based on columnorder when columns are visible

        let visibleColumnOrder = state.report.columnOrder[state.report.savedReportId || state.report.reportId];
        visibleColumnOrder = visibleColumnOrder.filter(col=>col.enabled);
        if (visibleColumnOrder.length === 0) {
            return;
        }

        if(visibleColumnOrder[index] !== undefined){
            const sortColumn = visibleColumnOrder[index].name;
            dispatch({type:types.REPORT_CHANGE_SORT, index, direction,sortColumn});
            const sort = {
                column: sortColumn,
                direction
            };

            if(sortDisable!==true)
                dispatch(reloadReportWithChanges({sort}));
        }
    };
};

/*
███████  █████  ██    ██ ███████ ██████
██      ██   ██ ██    ██ ██      ██   ██
███████ ███████ ██    ██ █████   ██   ██
     ██ ██   ██  ██  ██  ██      ██   ██
███████ ██   ██   ████   ███████ ██████
*/

export const requestSavedReportDefinition = (reportId) => {
    return dispatch => {
        return ReportService.instance().get('/saved-reports/'+reportId)
        .then(res=>{
            var report = res.data;
            return report;
        })
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};

export const requestSavedReport = (report) => {
    return dispatch => {
        return dispatch(requestReport(report.baseReportId,false))
        .then(()=>{
            dispatch({type:types.REQUEST_SAVED_REPORT, report});
            return dispatch(loadSavedReport(report));
        });
    };
};

export const loadResourceCustomAttibuteSettings = () => {
    return dispatch => {
        return ReportService.instance().get('/token-replacement/sample')
        .then(res=>{
            dispatch({type:types.LOAD_TOKEN_REPLACEMENTS, tokenReplacements: res.data.slice(0,12)});
        })
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};


export const loadSavedReport = (report) => {
    return (dispatch,getState) => {

        // Dispatch it. (to update store)
        dispatch({type:types.LOAD_SAVED_REPORT, report});

        const filterValues = report.filterValues || {};

        // Construct filter mapping (name=>value)
        const state = getState();
        let _filters = state.report.meta.filters.reduce((filters,filterId)=>{
            var filter = state.filters[filterId];
            filters[filter.name] = filterValues[filterId] || OPTION_ALL_WILDCARD;
            return filters;
        },{});

        // Reload report.
        dispatch( reloadReportWithChanges({
            filters: _filters
        }));

    };
};

export const deleteSavedReport = () => {
    return ( dispatch,getState )=>{
        const state = getState();
        const id = state.report.savedReportId;

        return ReportService.instance().delete("/saved-reports/"+id)
        .then(res=>{
            dispatch(requestMenu(state.user.username,state.user.jwtToken));
            return res.data;
        })
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};

export const saveReport = (name) => {
    return (dispatch,getState)=>{
        const state = getState();

        // Construct Definition.

        const id = state.report.savedReportId ;

        const baseReportId = state.report.reportId;

        const colOrder = state.report.columnOrder[id] || state.report.columnOrder[baseReportId];
        let filterOrder = null;
        if (id != null && state.report.sidebarColumnOrder[id])
            filterOrder = state.report.sidebarColumnOrder[id];
        else if (baseReportId != null && state.report.sidebarColumnOrder[baseReportId])
            filterOrder = state.report.sidebarColumnOrder[baseReportId];

        var o = {
            id,
            baseReportId,
            name,
            columnOrder:colOrder,
            filterOrder:filterOrder,
            filterValues:state.report.meta.filters.reduce((filterValues,filterId)=>{
                if(filterId){
                    filterValues[ filterId ] = state.filters[filterId].value || OPTION_ALL_WILDCARD;
                }
                return filterValues;
            },{}),
            sortColumn: state.report.sortColumn,
            sortDirection: state.report.sortDirection
        };

        // Save Definition to Persisted Storage.

        var ajax;
        if(id){
            ajax = ReportService.instance().put("/saved-reports/"+id,o);
        }else{
            delete o.id;
            ajax = ReportService.instance().post("/saved-reports/",o);
        }

        return ajax
        .then(res=>{
            dispatch({type:types.LOAD_SAVED_REPORT, report:{...o, id:res.data}});
            dispatch(requestMenu(state.user.username,state.user.jwtToken));

            return res.data;
        })
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};

// Change the selected item in grid.
export const changeSelectedItem = (newItemSelected) => ({type:types.CHANGE_DETAILS_ITEM,newItemSelected:newItemSelected});

//region Export Report (CSV,HTML)

/**
 * Main action for export a report.
 * @param reportId Report Id
 * @param format csv or html
 * @returns {Function}
 */
export const exportReport = (reportId,format) => {

    return (dispatch,getState)=>{

        setTimeout(
            function () {

                // Get data of report from Redux Store.
                const {report, filters} = getState();

                let reportId = report.reportId;

                // For saved reports.
                if (report.savedReportId != null)
                    reportId = report.savedReportId;

                // Download report in browser.
                downloadReport(report,filters,reportId,format);


            }, 1);
    };
};

export const playbackReportLoad = (tagsIdArray, dateInit, dateEnd) => {

};

export const getPrinterDevices = (siteName) => {
    return dispatch => {
        ReportService.instance().get("/printers", {
            params: { siteName: siteName }
        })
            .then(res => {
                dispatch({type: types.LOAD_PRINTER_DEVICES, data: res.data.results})
            })
            .catch(displayErrorFromAxios.bind(null,dispatch));
    }
};

export const sendDataToPrint = (data) => {
    return async dispatch => {

        let result = data.reduce((prom, dat) => {
            return prom.then(() => {
                let promise = [];
                promise.push(
                    ReportService.instance().post("printers/printjobs", dat)
                    .then(res => {
                        return res.data.results;
                    })
                    .catch(displayErrorFromAxios.bind(null,dispatch)));

                return new Promise((resolve, reject) => {
                    resolve(Promise.all(promise)).catch(displayErrorFromAxios.bind(null,dispatch))
                });
            })
        }, Promise.resolve());

        result.then(e => {
            console.log("Result: ", e);
        });
    }
};


export const toggleMapDrawer = (showMapDrawer) => ({type:types.TOOGLE_MAP_DRAWER, showMapDrawer: showMapDrawer});

// Define value of one filter before load the report.
export const setValueFilter = (filterName, value, reportId) => {
    return async (dispatch, getState) => {
        const {report} = getState();
        const columnMeta = report.columnMeta;

        if (columnMeta != null && columnMeta.hasOwnProperty(reportId) && columnMeta[reportId].hasOwnProperty(filterName)) {
            dispatch(changeFilterValue(columnMeta[reportId][filterName].filter, value));
        }else{
            const oneTimeDefaultFilterValue={};
            oneTimeDefaultFilterValue[reportId]= {};
            oneTimeDefaultFilterValue[reportId][filterName]={value:value};
            dispatch({type:types.UPDATE_ONE_TIME_DEFAULT_FILTER_VALUE,oneTimeDefaultFilterValue:oneTimeDefaultFilterValue});
        }

    }
};



//endregion


