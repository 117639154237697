import {
    CHANGE_STEP,
    CHANGE_STEP_BATCH,
    UPDATE_LIST_TYPE_DEVICES,
    FILTER_RESULTS,
    LIST_SITES,
    RECENT_MAPS,
    LOAD_SUGGESTIONS,
    MAP_SELECTED,
    SHOW_DETAILS,
    SHOW_MAPS,
    LOAD_TEMPLATE_LIST,
    APPLY_TEMPLATE,
    SELECT_TEMPLATE,
    LOAD_FORM_TEMPLATE,
    CLEAN_FORM_TEMPLATE,
    UPDATE_FORM_TEMPLATE,
    UPDATE_FORM_TEMPLATE_MAIN,
    UPDATE_FORM_TEMPLATE_ANTENNA,
    ADD_FORM_TEMPLATE_ANTENNA,
    DELETE_FORM_TEMPLATE_ANTENNA,
    ADD_FORM_TEMPLATE_ANTENNA_BY_PORT,
    CREATE_BATCH_IMPORT_FILES,
    LOAD_MESSAGES_DEVICES_BATCH,
    CLEAN_MESSAGES_DEVICES_BATCH,
    CLEAN_IMPORTED_FILES,
    CREATE_DEVICES_BATCH,
    CLEAN_DEVICES_BATCH,
    INIT_ADD_DEVICES,
    LOAD_DEVICE_LIST,
    LOAD_FORM_DEVICE,
    LIST_SITES_GROUPS,
    SET_SELECTED_GROUP,
    LOAD_SITE_MAP,
    ACTION_DEVICE,
    DEVICE_MANAGER_UPDATE_SELECTED,
    LOAD_FILTERS,
    LOAD_SELECTED_FILTERS,
    UPDATE_SELECTED_FILTERS,
    LOAD_SITE_ZONES,
    UPDATE_SITE_ZONES,
    // ADD_SITE_ZONES,
    LOAD_DEVICES_SITES,
    LOAD_SCREEN,
    ADD_ANTENNA_BATCH,
    DELETE_ANTENNA_BATCH,
    LOAD_EDITED_DEVICE,
    LOAD_DEVICE_DETAILS_DATA,
    LOAD_TEMPLATE_DETAILS_DATA,
    LOAD_LINKED_DEVICES,
    CLEAN_LINKED_DEVICES,
    UPDATE_LINKED_SELECTED_ROWS,
    SET_FETCHING,
    SET_FETCHING_DEVICE,
    CHANGE_MAP_VIEW,
    LOAD_DEVICE_MANAGER_SCREEN,
    SET_FETCHING_DETAILS,
    SHOW_MODAL_DETAILS,
    SHOW_MODAL_EDIT_DEVICE,
    UPDATE_DEVICE_OPERATIONS,
    CHECK_DEVICE_OPERATION,
    DEVICE_MOVE_COLUMN,
    DEVICE_TOGGLE_COLUMN,
    DEVICE_TOGGLE_ALL_COLUMNS,
    CHANGE_CURRENT_MAP,
    OPEN_ALERT,
    SORT_COLUMN,
    LOAD_DIALOG_NOTIFICATION,
    LOAD_FIRMWARE_VERSIONS_DEVICE,
    UPDATE_COLUMN_WIDTH,
    UPDATE_COLUMN_WIDTH_AUTO_SIZE,
    LOAD_DEVICE_OPTIONS,
    CHANGE_DEVICE_OPTIONS,
    UNLOAD_DEVICE_OPTIONS,
    UPDATE_DEVICE_OPTIONS,
    CONFIGURATION_VERSION_HISTORY,
    UPDATE_DEVICE_OPERATIONS_IN_PROGRESS,
    UPDATE_TEMPLATE_COLUMN_WIDTH,
    DETECT_SAVE_CHANGES,
    SET_FIRMWARE_ERROR,
    UPDATE_VALIDATED_FIELDS,
    ALLOW_UPDATE_TEMPLATE,
    SET_TEMPLATE_ERROR,
    SET_ADDED_TEMPLATE,
    CONFIGURATION_CLOUD,
    LOAD_SITE_SNAPSHOT_INFORMATION,
    GETTING_SITE_INFO,
    UPDATE_MAP_SELECTED,
    LOAD_ZONES_ANTENNAS,
    LOAD_STATUS_HISTORY,
    LOAD_DEVICE_STATUS,
    CHANGE_HISTORY_DATE_FILTER,
    LOAD_FETCH_HISTORY_STATUS,
    LOAD_FETCH_DEVICE_STATUS,
    DATA_TO_DOWNLOAD,
    CHANGE_LOG_HISTORY_DATE_FILTER,
    LOAD_LOG_HISTORY_DATA,
    SET_LOG_HISTORY_TYPES, SET_LOG_TO_DOWNLOAD, SET_LOG_DOWNLOAD_TYPES,
    SET_DEVICES_SELECTED,
    SET_DOWNLOAD_ERROR,
    SET_LOG_CONFIGURATION_ERROR,
    SET_LOGS_CONFIGURATION_SUCCESSFULLY, SET_FETCHING_LOGS_SAVE,
    SET_FETCHING_DOWNLOAD_LOGS,
    SET_FETCHING_LOGS,
    CHANGE_LOG_LEVEL,
    SET_DOWNLOADED_HISTORY_LOGS,
    CLEAR_DOWNLOADED_LOGS,
    SET_DEVICE_LOG_CONFIG,
    SET_ERROR_DEVICES_LIST,
    LOADING_DEVICES_LIST,
    SET_PAGINATION_INFO,
    SET_RECORDS_PER_PAGE,
    SET_DEVICES_ID, LOAD_DEVICE_COLUMN_ORDER,
    CLEAN_DEVICE_MANAGER
} from '../constants/ActionTypes';
import {
    RFID_MODELS,
    EDIT_TEMPLATE,
    CONF_LLRP,
    CONF_R2C,
    DEVICE_MANAGER_COLUMNS,
    TEMPLATE_MANAGER_COLUMNS,
    VALIDATION_MESSAGE_NEGATIVE_VALUE, VALIDATION_MESSAGE_REQUIRED, VALIDATION_TYPE_REQUIRED, VALIDATION_TYPE_CUSTOM,
    VALIDATION_TAGFILTER_VALUE, VALIDATION_TAGFILTER_MATCH, VALIDATION_TAGFILTER_OPERATION, VALIDATION_TAGFILTER_LENGTH, VALIDATION_ANTENNAS_REQUIRED,
    VALIDATION_INVENTORY_VALUE, VALIDATION_INVENTORY_UNIT,
    VALIDATION_PORTAL_PORT, VALIDATION_PORTAL_SIGNAL, EDIT_DEVICE_SAVED, ADD_TEMPLATE, EDIT_DEVICE_MANUALLY_ADDED,
    VALIDATION_JSON_FORMAT, VALIDATION_JSON_MESSAGE, VALIDATION_ANTENNAS_MESSAGE,
    LOG_DIALOG_COLUMNS,
    LOG_HISTORY_COLUMNS, LOG_MULTISELECT_DOWNLOAD_COLUMNS,
    PAGINATION_LIMIT,
} from "../constants/DeviceManager";
let antennaItem = {
    "id": 0,
    "title": "",
    "description": "",
    "disabled": false,
    "orderNumber": 0,
    "itemNumber": 0,
    "typedInSerialNumber": "",
    "dateInstalled": "",
    "location": {
        "locationType": "",
        "surveyPoint": "",
        "mapID": 0,
        "x": null,
        "y": null,
        "z": 0
    },
    "color": "",
    "power": 0
};

const emptyRFIDForm = {
    "deviceType": "",
    "siteId": "",
    "title": "",
    "ipAddress": "",
    "isIpV6": false,
    "serialNumber": "",
    "description": "",
    "disabled": false,
    "version": 0,
    "orderNumber": "",
    "itemNumber": 0,
    "typedInSerialNumber": "",
    "dateInstalled": "",
    "product": "",
    "location": {
        "locationType": "",
        "surveyPoint": "",
        "mapID": 0,
        "x": 0,
        "y": 0,
        "z": 0
    },
    "macAddress": "",
    "rfidType": "",
    "rfidAntennas": [],
    "operationMode": "",
    "minRssi": null,
    "tagFilter": {
        "value": "",
        "match": "",
        "operation": ""
    },
    "inventoryInterval": {
        "unit": "",
        "value": 0,
    },
    "portalSettings": {
        "startTrigger": {
            "port": 0,
            "signal": "HIGH"
        },
        "stopInterval": 0    
    }, 
	"triggerSettings": {
		"start": {},
		"stop": {}
	},
	"reporting": {
		"numTags": 0,
		"seconds": 0,
		"autoMode": true,
		"newTagEvent": ""
	},
	"GPORules": [],
    "protocol": "mqtt",
    "logLevel": {}
};
const emptyTemplateForm = {
    "title": "",
    "deviceType": "",
    "description": "",
    "createBy": "",
    "createDate": "",
    "template": null
};

const initialRequiredFields = {successful: false, fields:{title:false, description: false, ipAddress: false,macAddress:false}};

const getInitialDeviceTypes = () => ({
    rfidReader:{
        id: "rfidReader",
        title: "RFID Reader",
        image: "assets/images/devices/04.svg",
        models: {
            FX7500:{id:"FX7500",label:"FX7500",number:0},
            FX9600:{id:"FX9600",label:"FX9600",number:0},
            ATR7000:{id:"ATR7000",label:"ATR7000",number:0}
        }
    }
})

const getDefaultFromDate = () => {
    const date = new Date();
    date.setHours(0,0,0.0);
    return date.toISOString();
};

const getDefaultToDate = () => {
    let date = new Date();
    date.setHours(0,0,0,0)
    date.setDate(date.getDate() + 1);
    return date.toISOString();
}

const dynamicSort = (property) => {
    let sortOrder = 1;
    return (a,b) => {
        let result = (parseInt(a[property]) < parseInt(b[property])) ? -1 : (parseInt(a[property]) > parseInt(b[property])) ? 1 : 0;
        return result * sortOrder;
    }
};

const initialState = {
    rfidConfigurationType: CONF_R2C,
    batchImportFiles:{},
    devicesBatch: [],
    isFetching: false,
    isFetchingDevice: false,
    isFetchingDetails:false,
    deviceList: [],
    deviceListPerPage:[],
    actualStep:0,
    actualStepBatch:0,
    deviceTypes: {
        // bleBeacon:{
        //     id: "bleBeacon",
        //     title: "BLE Beacon",
        //     image: "assets/images/devices/01.svg",
        //     models:
        //     {
        //         BLEBE5500:{id:"BLEBE5500",label:"BLEBE5500",number:0},
        //         BLEBE7500:{id:"BLEBE7500",label:"BLEBE7500",number:0},
        //         BLEBE9500:{id:"BLEBE9500",label:"BLEBE9500",number:0},
        //         BLEBE9600:{id:"BLEBE9600",label:"BLEBE9600",number:0}
        //     }
        // },
        // bleReceiver:{
        //     id: "bleReceiver",
        //     title: "BLE Receiver",
        //     image: "assets/images/devices/02.svg",
        //     models:
        //     {
        //         BLE5500:{id:"BLE5500",label:"BLE5500",number:0},
        //         BLE7500:{id:"BLE7500",label:"BLE7500",number:0},
        //         BLE9500:{id:"BLE9500",label:"BLE9500",number:0},
        //         BLE9600:{id:"BLE9600",label:"BLE9600",number:0}
        //     }
        // },
        // dartHub:{
        //     id: "dartHub",
        //     title: "Dart Hub",
        //     image: "assets/images/devices/03.svg",
        //     models:
        //     {
        //         DART5500:{id:"DART5500",label:"DART5500",number:0},
        //         DART7500:{id:"DART7500",label:"DART7500",number:0},
        //         DART9500:{id:"DART9500",label:"DART9500",number:0},
        //         DART9600:{id:"DART9600",label:"DART9600",number:0}
        //     }
        // },
        rfidReader:{
            id: "rfidReader",
            title: "RFID Reader",
            image: "assets/images/devices/04.svg",
            models: {...RFID_MODELS}
        },
        // gemeniDevice:{
        //     id: "gemeniDevice",
        //     title: "Gemeni Device",
        //     image: "assets/images/devices/05.svg",
        //     models:
        //     {
        //         GEM5500:{id:"GEM5500",label:"GEM5500",number:0},
        //         GEM7500:{id:"GEM7500",label:"GEM7500",number:0},
        //         GEM9500:{id:"GEM9500",label:"GEM9500",number:0},
        //         GEM9600:{id:"GEM9600",label:"GEM9600",number:0}
        //     }
        // },
        // claServer:{
        //     id: "claServer",
        //     title: "CLA Server",
        //     image: "assets/images/devices/06.svg",
        //     models:
        //     {
        //         CLA5500:{id:"CLA5500",label:"CLA5500",number:0},
        //         CLA7500:{id:"CLA7500",label:"CLA7500",number:0},
        //         CLA9500:{id:"CLA9500",label:"CLA9500",number:0},
        //         CLA9600:{id:"CLA9600",label:"CLA9600",number:0}
        //     }
        // },
        // ciscoMSE:{
        //     id: "ciscoMSE",
        //     title: "CISCO MSE",
        //     image: "assets/images/devices/07.svg",
        //     models:
        //     {
        //         CIS5500:{id:"CIS5500",label:"CIS5500",number:0},
        //         CIS7500:{id:"CIS7500",label:"CIS7500",number:0},
        //         CIS9500:{id:"CIS9500",label:"CIS9500",number:0},
        //         CIS9600:{id:"CIS9600",label:"CIS9600",number:0}
        //     }
        // },
        // wheSensor:{
        //     id: "wheSensor",
        //     title: "Wherelan III Sensor",
        //     image: "assets/images/devices/08.svg",
        //     models:
        //     {
        //         WHE5500:{id:"WHE5500",label:"WHE5500",number:0},
        //         WHE7500:{id:"WHE7500",label:"WHE7500",number:0},
        //         WHE9500:{id:"WHE9500",label:"WHE9500",number:0},
        //         WHE9600:{id:"WHE9600",label:"WHE9600",number:0}
        //     }
        // }
    },
    deviceType: 'RFID Reader',
    devices: [],
    results: [],
    recentMaps: [],
    requiredFields: {...initialRequiredFields},
    successfulValidatedFields: true,
    focusedSection: null,
    needsValidationFields: {
        identity: {
            'title': {
                error: false,
                type: VALIDATION_TYPE_REQUIRED,
                message: VALIDATION_MESSAGE_REQUIRED,
                label: 'Title'
            },
            'description': {
                error: false,
                type: VALIDATION_TYPE_REQUIRED,
                message: VALIDATION_MESSAGE_REQUIRED,
                label: 'Description'
            },
            'ipAddress': {
                error: false,
                type: VALIDATION_TYPE_REQUIRED,
                message: VALIDATION_MESSAGE_REQUIRED,
                validateForms:[EDIT_DEVICE_SAVED,EDIT_DEVICE_MANUALLY_ADDED],
                label: 'Hostname'
            },
            'macAddress': {
                error: false,
                type: VALIDATION_TYPE_REQUIRED,
                message: VALIDATION_MESSAGE_REQUIRED,
                label: 'MAC Address'
            },
        },
        protocol: {
            'protocol' : {
                error: false,
                type: VALIDATION_TYPE_REQUIRED,
                message: VALIDATION_MESSAGE_REQUIRED,
                validateForms:[EDIT_DEVICE_SAVED,EDIT_DEVICE_MANUALLY_ADDED,ADD_TEMPLATE,EDIT_TEMPLATE],
                label: 'Reader Management Communication'
            }
        },
        operation: {                                        // section for watch values
            'minRssi': {                                    // path to value in form template
                error: false,                               // by default no error
                type: VALIDATION_TYPE_CUSTOM,
                regexp: '',
                message: VALIDATION_MESSAGE_NEGATIVE_VALUE,
                validateForms:[EDIT_DEVICE_SAVED,EDIT_DEVICE_MANUALLY_ADDED,ADD_TEMPLATE,EDIT_TEMPLATE],
                label: 'Minimum RSSI'
            },
            'tagFilter.value': {
                error: false,
                type: VALIDATION_TYPE_CUSTOM,
                message: VALIDATION_TAGFILTER_VALUE,
                validateForms:[EDIT_DEVICE_SAVED,EDIT_DEVICE_MANUALLY_ADDED,ADD_TEMPLATE,EDIT_TEMPLATE],
                label: 'Tag ID Filter'
            },
            'tagFilter.match': {
                error: false,
                type: VALIDATION_TYPE_CUSTOM,
                regexp: '',
                message: VALIDATION_TAGFILTER_MATCH,
                validateForms:[EDIT_DEVICE_SAVED,EDIT_DEVICE_MANUALLY_ADDED,ADD_TEMPLATE,EDIT_TEMPLATE],
                label: 'Match'
            },
            'tagFilter.operation': {
                error: false,
                type: VALIDATION_TYPE_CUSTOM,
                regexp: '',
                message: VALIDATION_TAGFILTER_OPERATION,
                validateForms:[EDIT_DEVICE_SAVED,EDIT_DEVICE_MANUALLY_ADDED,ADD_TEMPLATE,EDIT_TEMPLATE],
                label: 'Operation'
            },
            'tagFilter.length': {
                error: false,
                type: VALIDATION_TYPE_CUSTOM,
                regexp: '',
                message: VALIDATION_TAGFILTER_LENGTH,
                validateForms:[EDIT_DEVICE_SAVED,EDIT_DEVICE_MANUALLY_ADDED,ADD_TEMPLATE,EDIT_TEMPLATE],
                label: 'Tag ID Filter'
            },
            'inventoryInterval.value': {
                error: false,
                type: VALIDATION_TYPE_CUSTOM,
                message: VALIDATION_INVENTORY_VALUE,
                validateForms:[EDIT_DEVICE_SAVED,EDIT_DEVICE_MANUALLY_ADDED,ADD_TEMPLATE,EDIT_TEMPLATE],
                label: 'Reporting Interval'
            },
            'inventoryInterval.unit': {
                error: false,
                type: VALIDATION_TYPE_CUSTOM,
                regexp: '',
                message: VALIDATION_INVENTORY_UNIT,
                validateForms:[EDIT_DEVICE_SAVED,EDIT_DEVICE_MANUALLY_ADDED,ADD_TEMPLATE,EDIT_TEMPLATE],
                label: 'Units'
            },
            'portalSettings.startTrigger.port': {
                error: false,
                type: VALIDATION_TYPE_CUSTOM,
                message: VALIDATION_PORTAL_PORT,
                validateForms:[EDIT_DEVICE_SAVED,EDIT_DEVICE_MANUALLY_ADDED,ADD_TEMPLATE,EDIT_TEMPLATE],
                label: 'GPI Port'
            },
            'portalSettings.startTrigger.signal': {
                error: false,
                type: VALIDATION_TYPE_CUSTOM,
                regexp: '',
                message: VALIDATION_PORTAL_SIGNAL,
                validateForms:[EDIT_DEVICE_SAVED,EDIT_DEVICE_MANUALLY_ADDED,ADD_TEMPLATE,EDIT_TEMPLATE],
                label: 'Signal'
            },
            'advancedJson':{
                error: false,
                type: VALIDATION_JSON_FORMAT,
                message: VALIDATION_JSON_MESSAGE,
                validateForms:[EDIT_DEVICE_SAVED,EDIT_DEVICE_MANUALLY_ADDED,ADD_TEMPLATE,EDIT_TEMPLATE],
                label: 'Custom Operation'
            }
        },
        rfidAntennas: {
            'rfidAntennas': {
                error: false,
                type: VALIDATION_ANTENNAS_REQUIRED,
                message: VALIDATION_ANTENNAS_MESSAGE,
                validateForms: [EDIT_DEVICE_SAVED,EDIT_DEVICE_MANUALLY_ADDED],
                label: 'Antennas'
            }
        }
    },
    showDetails:false,
    showModalDetails: false,
    showModalEditDevice: false,
    showMaps:false,
    templateForm: {},
    templateFormLoaded: null,
    editDevice:null,
    templates:[],
    templateSelected: null,
    groupSelected: null,
    siteDevice: {},
    mapDevice: {},
    linkedSelectedRows: {},
    selectedRows:{},
    selectedFilters: {
        devices: [],
        templates: []
    },
    linkedDevices: [],
    zones: {},
    zonesWithAntennas: {},
    filters:{},
    detailsDevice: null,
    detailsTemplate: null,
    deviceOperationStatuses: {},
    deviceOperationUpdateInProgress:false,
    isDeviceChanging: false,
    operationCheck: {"LANDING_PAGE_SCREEN": false, "EDIT_DEVICES_SCREEN": false},
    columnOrder: {
        devices: [
            {name: "deviceType", label:"Device Type", enabled: false},
            {name: "rfidType", label: "Device Model", enabled: true},
            {name: "title", label: "Name", enabled: false},
            {name: "firmwareVersion", label:"Firmware Version", enabled: true},
            // {name: "logLevel", label:"Log Level", enabled: false},
            {name: "ipAddress", label: "Hostname", enabled: true},
            {name: "status", label: "Status", enabled: true},
            {name: "alert", label: "Hardware Health", enabled: true},
            {name: "rfidAntennas", label: "Antenna Ports", enabled: true},
            {name: "configuration_state", label: "Config. State", enabled: true},
            {name: "siteId", label: "Site Name", enabled: true},
            {name: "location", label: "Map Name", enabled: true},
            {name: "macAddress", label: "Mac Address", enabled: false},

            {name: "actions", label: "Actions", enabled: true},
        ],
        templates: [
            {name: "title", label: "Name", enabled: true},
            {name: "deviceType", label: "Device Type", enabled: true},
            {name: "createBy", label: "Create by", enabled: true},
            {name: "createDate", label: "Last Updated",enabled: true},
            {name: "devices", label: "Devices", enabled: true},
            {name: "actions", label: "",enabled: true},
        ]
    },

    firmwareList: {
        // 'deviceId123': [
        //     {version: '4.3.3.1', status: null, releaseDate: '7/20/20, 5:39:39 PM', action: 'update'},
        //     {version: '4.3.3.0', status: 'ok', releaseDate: '7/20/20, 5:39:39 PM', action: 'rollback'},
        //     {version: '4.3.2.0', status: 'ok', releaseDate: '7/20/20, 5:39:39 PM', action: 'rollback'},
        //     {version: '4.3.1.9', status: 'fail', releaseDate: '7/20/20, 5:39:39 PM', action: 'rollback'},
        //     {version: '2.8.3.2', status: 'fail', releaseDate: '7/20/20, 5:39:39 PM', action: 'rollback'},
        //     {version: '1.7.5.9', status: 'ok', releaseDate: '7/20/20, 5:39:39 PM', action: 'rollback'},
        // ]
    },
    countDevices: 0,
    openAlert: false,
    sortIndex: "",
    dialogNotification: null,
    deviceColumnConfig:DEVICE_MANAGER_COLUMNS,
    templateColumnConfig:TEMPLATE_MANAGER_COLUMNS,
    deviceOptions: {},
    messagesBatch: {},
    errorObj: {
        error: false,
        message: null
    },
    updateTemplate: false,
    templateMsgError: null,
    addedTemplate: false,
    siteInformation: {},
    siteSnapshotError: null,

    filtersHistory: {1461: {
            name: 'fromTimestamp',
            value: getDefaultFromDate(),
            defaultValue: getDefaultFromDate(),
            displayName: "From",
            id: 1461,
            isAllAllowed: false,
            type: "datetime"
        },
        1462:{
            name: 'toTimestamp',
            value: getDefaultToDate(),
            defaultValue: getDefaultToDate(),
            displayName: "To",
            id: 1462,
            isAllAllowed: false,
            type: "datetime"
        },
        datetimeFilters: {
            statusHistory : {
                timestamp: {
                    from: 1461,
                    to: 1462,
                    value: "TODAY"
                }
            }
        }
    },
    fetchingHistory: false,
    fetchDeviceStatus: false,
    logDialogColumns: LOG_DIALOG_COLUMNS,
    logMultiselectDialogColumns: LOG_MULTISELECT_DOWNLOAD_COLUMNS,
    downloadLogs: undefined,

    filtersLogsHistory: {
        1461: {
            name: 'fromTimestamp',
            value: getDefaultFromDate(),
            defaultValue: getDefaultFromDate(),
            displayName: "From",
            id: 1461,
            isAllAllowed: false,
            type: "datetime"
        },
        1462:{
            name: 'toTimestamp',
            value: getDefaultToDate(),
            defaultValue: getDefaultToDate(),
            displayName: "To",
            id: 1462,
            isAllAllowed: false,
            type: "datetime"
        },
        datetimeFilters: {
            statusHistory : {
                timestamp: {
                    from: 1461,
                    to: 1462,
                    value: "TODAY"
                }
            }
        }
    },
    logsHistoryData: [],
    logsHistoryColumns: LOG_HISTORY_COLUMNS,
    logsHistoryTypes: ['syslog'],
    logsDownloadTypes: [],
    logToDownload: null,
    devicesSelected: [],
    errorDownload: {error: false, message: null},
    errorLogConfiguration : {error: false, message: null},
    fetchingLogsSave: {},
    fetchingDownloadLogs: {},
    fetchingLogs: false,
    logLevel: {},
    deviceLogConfig: {},
    downloadedLogs: {},
    errorLoadDevicesList: {error: false, message: null},
    detailsDataUpdateInProgress: false,
    loadingDevices: false,
    paginationInfo: {
        nextPageAllow: false,
        prevPageAllow: false,
        offset: 0,
        limit: PAGINATION_LIMIT,
        page:1,
        totalPages:1,
        totalRecords: 0,
        totalPerPage: 0,
        quantityRanges: [],
        sort: null
    },
    devicesId:[],
};

function specificColumnOrder(state,action){
    let cols;
    switch(action.type){
        case DEVICE_TOGGLE_COLUMN:
            cols = [].concat(state);
            cols.splice(action.index,1, toggleColumn(cols[action.index],action.enabled));
            return cols;
        case DEVICE_MOVE_COLUMN:
            cols = [].concat(state);
            cols.splice(action.dragIndex,1);
            cols.splice(action.hoverIndex,0,state[action.dragIndex]);
            return cols;
        case DEVICE_TOGGLE_ALL_COLUMNS:
            cols = state.map((col)=> col.name==='actions'? toggleColumn(col, true) : toggleColumn(col,action.enabled));
            return cols;
        default:
            return state;
    }
}

function toggleColumn(col,enabled){
    return {...col,enabled};
}

function compareAntennaId(a,b){
    return (a.id < b.id)?-1:(a.id>b.id)?1:0;
}

export function deviceManager(state = initialState, action){
    let antennas, deviceModified, newAntenna;
    switch(action.type){
        case DETECT_SAVE_CHANGES:
            return {
                ...state,
                isDeviceChanging: action.isDeviceChanging
            };
        case LOAD_DIALOG_NOTIFICATION:
            return {
                ...state,
                dialogNotification: action.dialogNotification
            };
        case SET_FETCHING:
            return {
                ...state,
                isFetching: action.isFetching
            };
        case SET_FETCHING_DEVICE:
            return {
                ...state,
                isFetchingDevice: action.isFetchingDevice
            };
        case CHANGE_STEP:
            return {
                ...state,
                actualStep: action.step
            };
        case CHANGE_STEP_BATCH:
            return{
                ...state,
                actualStepBatch: action.step
            };
        case CREATE_BATCH_IMPORT_FILES:
            return{
            ...state,
                batchImportFiles: action.batchImportFiles
        };
        case LOAD_MESSAGES_DEVICES_BATCH:
            return{
                ...state,
                messagesBatch: {...state.messagesBatch, [action.position]: action.message}
            };
        case CLEAN_MESSAGES_DEVICES_BATCH:
            return{
                ...state,
                messagesBatch: {}
            };
        case UPDATE_LIST_TYPE_DEVICES:
            return {
                ...state,
                deviceTypes: action.listDeviceTypes
            };
        case CLEAN_IMPORTED_FILES:
            return{
                ...state,
                batchImportFiles:{}
            };
        case CREATE_DEVICES_BATCH:
            return{
                ...state,
                devicesBatch: [...action.listDevices]
            };
        case CLEAN_DEVICES_BATCH:
            return{
                ...state,
                devicesBatch: []
            };
        case INIT_ADD_DEVICES:
            return {
                ...state,
                devicesBatch:[],
                deviceTypes:getInitialDeviceTypes()
            };
        case FILTER_RESULTS:
            return {
                ...state,
                results: action.results
            };
        case LIST_SITES:
            return {
                ...state,
                sites: action.sites,
                maps: action.maps,
                countDevices:action.countDevices,
            };
        case MAP_SELECTED:
            return {
                ...state,
                mapSelected: action.mapSelected,
                siteId: action.mapSelected && action.mapSelected.siteId,
                mapId: action.mapSelected && action.mapSelected.id,
                applianceId: action.mapSelected && action.mapSelected.applianceId
            };
        case UPDATE_MAP_SELECTED:
            return {
                ...state,
                mapSelected: action.mapSelected
            };
        case CHANGE_CURRENT_MAP:
            return {
                ...state,
                siteId: action.siteId,
                mapId: action.mapId
            }
        case RECENT_MAPS:
            let recentMaps = [...state.recentMaps];
            if(action.mapSelected){
                recentMaps = recentMaps.map(recentMap => {
                    return {...recentMap, selected: false}
                });
                if(!action.mapSelected.allMaps) {
                    let matchRecentMap = false;
                    let index = -1;
                    for (let i = 0; i < recentMaps.length; i++) {
                        if (action.mapSelected.id === recentMaps[i].id && action.mapSelected.siteId === recentMaps[i].siteId) {
                            matchRecentMap = true;
                            index = i;
                        }
                    }

                    if (matchRecentMap) {
                        recentMaps.splice(index, 1);
                        recentMaps.push(action.mapSelected);
                    } else {
                        recentMaps.push(action.mapSelected);
                    }

                    if (recentMaps.length > 4) {
                        recentMaps.shift();
                    }
                }
                return {
                    ...state,
                    recentMaps: recentMaps
                };
            }
            if(action.recentMapUpdated){
                return {
                    ...state,
                    recentMaps: action.recentMapUpdated
                };
            }

            return {
                 ...state,
                 recentMaps: recentMaps
            };

        case LOAD_SUGGESTIONS:
            return {
                ...state,
                suggestions: action.suggestions
            };
        case SHOW_DETAILS:
            return{
                ...state,
                showDetails: action.showDetails
            };
        case SHOW_MAPS:
            return{
                ...state,
                showMaps: action.showMaps                
            };
        case LOAD_TEMPLATE_LIST:
            return {
                ...state,
                templates: action.templates
            };
        case LOAD_FORM_TEMPLATE:
            if(action.nameForm === EDIT_TEMPLATE){
                let conditionFormTemplate = action.template.template.triggerSettings && action.template.template.GPORules
                return{
                    ...state,
                    rfidConfigurationType: conditionFormTemplate ? CONF_LLRP : CONF_R2C,
                    templateForm: {...action.template},
                    templateFormLoaded: action.nameForm
                };
            }else{
                let {triggerSettings, reporting, GPORules, ...r2cFormat} = emptyRFIDForm;
                return{
                    ...state,
                    templateForm: {...emptyTemplateForm, template:  (state.rfidConfigurationType === CONF_R2C) ? r2cFormat: emptyRFIDForm},
                    templateFormLoaded: action.nameForm
                };
            }
        case LOAD_FORM_DEVICE:
            // const form_device = {...emptyTemplateForm, ...action.templateHeader, template: {...action.device}};
            const form_device = {...action.templateHeader, template: {...action.device}};

            let conditionForm = action.device.triggerSettings && action.device.GPORules
            return{
                ...state,
                rfidConfigurationType: conditionForm ? CONF_LLRP : CONF_R2C,
                templateForm: {...form_device},
                templateFormLoaded: action.nameForm
            };
        case CLEAN_FORM_TEMPLATE:
            return{
                ...state,
                templateForm: {},
                templateFormLoaded: null,
                templateSelected: null
            };
        case UPDATE_FORM_TEMPLATE:
            let sectionUpdated = {...state.templateForm.template[action.sectionKey]};
            if(action.key) {sectionUpdated[action.key] = action.value;}
            else{sectionUpdated=action.value;}
            return{
                ...state,
                templateForm: {...state.templateForm,
                    template:{...state.templateForm.template, [action.sectionKey]:sectionUpdated}}
            };
        case UPDATE_FORM_TEMPLATE_MAIN:
            return{
                ...state, templateForm: {...state.templateForm, [action.key]: action.value}
            };
        case UPDATE_FORM_TEMPLATE_ANTENNA:
            return {
                ...state,
                templateForm: {...state.templateForm,
                    template:{...state.templateForm.template, rfidAntennas: action.rfidAntennas}}
            };
        case ADD_FORM_TEMPLATE_ANTENNA:
            antennas = [...state.templateForm.template.rfidAntennas];
            antennas.push({...antennaItem, id: antennas.length+1, title: `Antenna ${antennas.length + 1}`, location: {...state.templateForm.template.location}});
            return {
                ...state,
                templateForm: {...state.templateForm,
                    template:{...state.templateForm.template, rfidAntennas:antennas}}
            };
        case DELETE_FORM_TEMPLATE_ANTENNA:
            antennas = [...state.templateForm.template.rfidAntennas.slice(0, action.index), ...state.templateForm.template.rfidAntennas.slice(action.index + 1)];
            return {
                ...state,
                templateForm: {...state.templateForm,
                    template:{...state.templateForm.template, rfidAntennas:antennas}}
            }
        case ADD_FORM_TEMPLATE_ANTENNA_BY_PORT:
            newAntenna = {...antennaItem, id: action.port + "", title: `Antenna ${action.port}`, location: {...state.templateForm.template.location}};
            antennas = [...state.templateForm.template.rfidAntennas, newAntenna].sort(compareAntennaId);
            antennas.sort(dynamicSort("id"));
            return {
                ...state,
                templateForm: {...state.templateForm,
                    template:{...state.templateForm.template, rfidAntennas: antennas}}
            };
        case UPDATE_VALIDATED_FIELDS:
            return {
                ...state,
                successfulValidatedFields:action.successfulValidatedFields,
                needsValidationFields: action.needsValidationFields,
                focusedSection: action.focusedSection
            };
        case APPLY_TEMPLATE:
            let deviceObject = {}
            deviceObject['rfidAntennas'] = [...action.rfidAntennas];
            deviceObject['inventoryInterval'] = action.template.template.inventoryInterval;
            deviceObject['operationMode'] = action.template.template.operationMode;
            deviceObject['portalSettings'] = action.template.template.portalSettings;
            deviceObject['tagFilter'] = action.template.template.tagFilter;
            deviceObject['numberAntennaPorts'] = action.template.template.numberAntennaPorts;
            deviceObject['protocol'] = action.template.template.protocol;

            if(action.template.template.advancedJson){
                deviceObject['advancedJson'] = JSON.stringify(action.template.template.advancedJson, null, 2)
            }

            // additional fields
            deviceObject['templateId'] = action.template._id;
            deviceObject['template'] = action.template.title;
            return{
                ...state,
                templateForm: {...action.template,
                template: {...state.templateForm.template, ...deviceObject}}
            };
        case SELECT_TEMPLATE:
            return {
                ...state,
                templateSelected: action.position
            };
        case LOAD_DEVICE_LIST:
            let deviceList = action.deviceList.map(item => {
                const {dateInstalled,
                    description,
                    deviceIdStr,
                    hostname,
                    inventoryInterval,
                    itemNumber,
                    needsDevicePublish,
                    operationMode,
                    operationStatus,
                    orderNumber,
                    portalSettings,
                    product,
                    serialNumber,
                    tagFilter,
                    typeIdInSerialNumber,
                    ...deviceParams
                } = item;

                return {...deviceParams}
            });
            const deviceListAndObject=buildDeviceListAndObject(deviceList);
            return {
                ...state,
                ...deviceListAndObject,
            };
        case LIST_SITES_GROUPS:
            return {
                ...state,
                siteGroups: action.data
            };
        case SET_SELECTED_GROUP:
            return {
                ...state,
                groupSelected: action.groupId
            };
        case ACTION_DEVICE:
            return{
                ...state,
                actionDevice: action.actionDevice,
                actionRow: action.actionRow
            };
        case LOAD_SITE_MAP:
            return {
                ...state,
                siteDevice: action.siteDevice,
                mapDevices: action.mapDevices,
                mapBounds: action.mapBounds,
                mapZoom: action.mapZoom,
            };
        case DEVICE_MANAGER_UPDATE_SELECTED:
            return{
                ...state,
                selectedRows: action.selectedRows,
            };
        case LOAD_SELECTED_FILTERS:
            return {
                ...state,
                selectedFilters: action.selectedFilters
            };
        case LOAD_FILTERS:
            return {
                ...state,
                filters: {...state.filters, [action.column]: action.values}
            }
        case UPDATE_SELECTED_FILTERS:
            return{
                ...state,
                selectedFilters: {...state.selectedFilters, [action.table]: action.filters}
            };
        case LOAD_SITE_ZONES:
            return{
                ...state,
                zones: {...state.zones, [action.siteId]: action.zones}
            };
        // case ADD_SITE_ZONES:
        //     return {
        //         ...state,
        //         zones: [...state.zones, action.newZone]
        //     };
        case UPDATE_SITE_ZONES:
            return {
                ...state,
                zones: {...state.zones, [action.siteId]: action.updatedZones}
                // zones: action.updatedZones
            };
        case LOAD_ZONES_ANTENNAS:
            return {
                ...state,
                zonesWithAntennas: action.zonesWithAntennas
            }
        case LOAD_DEVICES_SITES:
            return{
                ...state,
                sitesForDevices: action.data.map(v => ({
                    ...v,
                    id: v._id,
                    checked:false,
                    groupId: (v.groupMembership && v.groupMembership[0]) || null,
                    name: v.name.trim()
                }))
            };
        case LOAD_SCREEN:
            return {
                ...state,
                editScreen: action.editScreen,
                indexDevice: action.indexDevice
            };
        case LOAD_EDITED_DEVICE:
            return {
                ...state,
                devicesBatch: state.devicesBatch.map((device, index) => {
                    if (index === action.indexDevice) {
                        return action.deviceModified;
                    } else {
                        return device
                    }
                })
            };
        case ADD_ANTENNA_BATCH:
            antennas = [...state.devicesBatch[action.indexDevice].rfidAntennas];
            antennas.push({...antennaItem, id: antennas.length, title: `Antenna ${antennas.length + 1}`});
            deviceModified = {...state.devicesBatch[action.indexDevice], rfidAntennas: antennas};
            return {
                ...state,
                devicesBatch: state.devicesBatch.map((device, index) => {
                    if (index === action.indexDevice) {
                        return deviceModified
                    } else {
                        return device
                    }
                })
            };
        case DELETE_ANTENNA_BATCH:
            antennas = [...state.devicesBatch[action.indexDevice].rfidAntennas.slice(0, action.indexAntenna), ...state.devicesBatch[action.indexDevice].rfidAntennas.slice(action.indexAntenna + 1)];
            deviceModified = {...state.devicesBatch[action.indexDevice], rfidAntennas: antennas};
            return {
                ...state,
                devicesBatch: state.devicesBatch.map((device, index) => {
                    if (index === action.indexDevice) {
                        return deviceModified
                    } else {
                        return device
                    }
                })
            };
        case LOAD_DEVICE_DETAILS_DATA:
            return{
                ...state,
                detailsDevice: action.detailsDevice
            };
        case LOAD_TEMPLATE_DETAILS_DATA:
            return{
                ...state,
                detailsTemplate: action.detailsTemplate
            };
        case CHANGE_MAP_VIEW:
            return {
                ...state,
                mapView: action.mapView
            };
        case LOAD_DEVICE_MANAGER_SCREEN:
            return {
                ...state,
                deviceManagerScreen: action.deviceManagerScreen
            };
        case SET_FETCHING_DETAILS:
            return {
                ...state,
                isFetchingDetails: action.isFetchingDetails
            };
        case SHOW_MODAL_DETAILS:
            return {
                ...state,
                showModalDetails: action.showModalDetails
            };
        case SHOW_MODAL_EDIT_DEVICE:
            return {
                ...state,
                showModalEditDevice: action.showModalEditDevice
            }
        case UPDATE_DEVICE_OPERATIONS:
            return {
                ...state,
                deviceOperationStatuses: action.deviceOperationStatuses
            };
        case UPDATE_DEVICE_OPERATIONS_IN_PROGRESS:
            return {
                ...state,
                deviceOperationUpdateInProgress: action.inProgress
            };
        case CHECK_DEVICE_OPERATION:
            return {
                ...state,
                operationCheck: {...state.operationCheck, [action.screen] : action.operationCheck}
            };
        case LOAD_LINKED_DEVICES:
            return {
                ...state,
                linkedDevices: action.linkedDevices
            };
        case CLEAN_LINKED_DEVICES:
            return {
                ...state,
                linkedDevices: [],
                linkedSelectedRows: {}
            }
        case UPDATE_LINKED_SELECTED_ROWS:
            return {
                ...state,
                linkedSelectedRows: action.linkedSelectedRows
            };
        case DEVICE_TOGGLE_ALL_COLUMNS:
        case DEVICE_MOVE_COLUMN:
        case DEVICE_TOGGLE_COLUMN:
            return {
                ...state,
                columnOrder : {...state.columnOrder, [action.key]: specificColumnOrder(state.columnOrder[action.key], action)}
            };
        case LOAD_DEVICE_COLUMN_ORDER:
            return {
                ...state,
                columnOrder: action.columnOrder
            }
        case OPEN_ALERT:
            return {
                ...state,
                openAlert: action.openAlert
            };
        case SORT_COLUMN:
            return {
                ...state,
                sortIndex: action.sortIndex
            };
        case LOAD_FIRMWARE_VERSIONS_DEVICE:
            return {
                ...state,
                firmwareList: {...state.firmwareList,[action.deviceId]:action.firmwareList},
                errorObj: {
                    error: false,
                    message: null
                }
            };
        case SET_FIRMWARE_ERROR:
            return {
                ...state,
                errorObj: {
                    error: action.error,
                    message: action.errorMsg
                }
            }
        case UPDATE_COLUMN_WIDTH:
            return {
                ...state,
                deviceColumnConfig: {
                    ...state.deviceColumnConfig,
                    [action.data.itemKey]: {
                        ...state.deviceColumnConfig[action.data.itemKey],
                        width: action.data.newSize
                    }
                }
            };
        case UPDATE_TEMPLATE_COLUMN_WIDTH:
            return {
                ...state,
                templateColumnConfig: {
                    ...state.templateColumnConfig,
                    [action.data.itemKey]: {
                        ...state.templateColumnConfig[action.data.itemKey],
                        width: action.data.newSize
                    }
                }
            };
        case UPDATE_COLUMN_WIDTH_AUTO_SIZE:
            return {
                ...state,
                deviceColumnConfig: {
                    ...state.deviceColumnConfig,
                    [action.data.itemKey]: {
                        ...state.deviceColumnConfig[action.data.itemKey],
                        width: DEVICE_MANAGER_COLUMNS[action.data.itemKey].width
                    }
                }
            };
        case LOAD_DEVICE_OPTIONS:
            return {
                ...state,
                deviceOptions: action.deviceOptions
            };
        case UNLOAD_DEVICE_OPTIONS:
            return{
                ...state,
                deviceOptions: {}
            };
        case CHANGE_DEVICE_OPTIONS:
            return {
                ...state,
                deviceOptions: {...state.deviceOptions, [action.deviceId]: action.deviceOption}
            };
        case UPDATE_DEVICE_OPTIONS:
            return{
                ...state,
                deviceOptions: {...action.deviceOptions}
            };
        case CONFIGURATION_VERSION_HISTORY:
            return {
                ...state,
                versionHistory: {...action.versionHistory}
            };
        case ALLOW_UPDATE_TEMPLATE:
            return {
                ...state,
                updateTemplate: action.updateTemplate
            };
        case SET_ADDED_TEMPLATE:
            return {
                ...state,
                addedTemplate: action.addedTemplate
            }
        case SET_TEMPLATE_ERROR:
            return {
                ...state,
                templateMsgError: action.templateMsgError
            }

        case CONFIGURATION_CLOUD:
            return {
                ...state,
                isOnCloud: action.isOnCloud
            }
        case LOAD_SITE_SNAPSHOT_INFORMATION:
            return {
                ...state,
                siteInformation: action.siteInformation,
                siteSnapshotError: action.errorMsg
            };
        case GETTING_SITE_INFO:
            return {
                ...state,
                loadingSiteInfo: action.loadingSiteInfo
            };
        case LOAD_STATUS_HISTORY:
            return {
                ...state,
                statusHistory: action.statusHistory
            };
        case LOAD_DEVICE_STATUS:
            return {
                ...state,
                deviceStatus: action.deviceStatus
            };
        case CHANGE_HISTORY_DATE_FILTER:
            return {
                ...state,
                filtersHistory: action.filtersHistory
            };
        case LOAD_FETCH_HISTORY_STATUS:
            return {
                ...state,
                fetchingHistory: action.fetchingHistory
            };
        case LOAD_FETCH_DEVICE_STATUS:
            return {
                ...state,
                fetchDeviceStatus: action.fetchDeviceStatus
            };
        case DATA_TO_DOWNLOAD:
            return {
                ...state,
                downloadLogs: action.downloadLogs
            };
        case LOAD_LOG_HISTORY_DATA:
            return {
                ...state,
                logsHistoryData: action.logsHistoryData
            };
        case CHANGE_LOG_HISTORY_DATE_FILTER:
            return {
                ...state,
                filtersLogsHistory: action.filtersLogsHistory
            };
        case SET_LOG_HISTORY_TYPES:
            return {
                ...state,
                logsHistoryTypes: action.logsHistoryTypes
            };
        case SET_LOG_TO_DOWNLOAD:
            return {
                ...state,
                logToDownload: action.logToDownload
            };
        case SET_LOG_DOWNLOAD_TYPES:
            return {
                ...state,
                logsDownloadTypes: action.logsDownloadTypes
            };
        case SET_DEVICES_SELECTED:
            return {
                ...state,
                devicesSelected: action.devicesSelected
            };
        case SET_DOWNLOAD_ERROR:
            return {
                ...state,
                errorDownload : action.errorDownload
            };
        case SET_LOG_CONFIGURATION_ERROR:
            return {
                ...state,
                errorLogConfiguration: action.errorLogConfiguration
            };
        case SET_LOGS_CONFIGURATION_SUCCESSFULLY:
            return {
                ...state,
                logsSavedSuccessfully: action.logsSavedSuccessfully
            };
        case SET_FETCHING_LOGS_SAVE:
            return {
                ...state,
                fetchingLogsSave: action.fetchingLogsSave
            };
        case SET_FETCHING_DOWNLOAD_LOGS:
            return {
                ...state,
                fetchingDownloadLogs: action.fetchingDownloadLogs
            };
        case SET_FETCHING_LOGS:
            return {
                ...state,
                fetchingLogs: action.fetchingLogs
            };
        case CHANGE_LOG_LEVEL:
            return {
                ...state,
                logLevel: action.logLevel
            };
        case SET_DEVICE_LOG_CONFIG:
            return {
                ...state,
                deviceLogConfig: action.deviceLogConfig
            }
        case SET_DOWNLOADED_HISTORY_LOGS:
            return {
                ...state,
                downloadedLogs: {...state.downloadedLogs, ...action.downloadedLogs}
            };
        case CLEAR_DOWNLOADED_LOGS:
            return {
                ...state,
                downloadedLogs: {}
            };
        case SET_ERROR_DEVICES_LIST:
            return {
                ...state,
                errorLoadDevicesList: action.errorLoadDevicesList
            };
        case LOADING_DEVICES_LIST:
            return {
                ...state,
                loadingDevices: action.loadingDevices
            };

        case SET_PAGINATION_INFO:
            return {
                ...state,
                paginationInfo: action.paginationInfo
            };
        case SET_RECORDS_PER_PAGE:
            return {
                ...state,
                deviceListPerPage: action.deviceListPerPage
            };
        case SET_DEVICES_ID:
            return {
                ...state,
                devicesId: action.devicesId
            };
        case CLEAN_DEVICE_MANAGER:
            return {
                ...initialState
            };
        default:
            return state;

    }
}

function buildDeviceListAndObject(deviceList){
    let deviceListObject={};
    if(deviceList!=null&&deviceList.length>0){
        deviceList.forEach((device,index)=>{
            deviceListObject[device._id]=index;
        });
    }
    return {deviceList,deviceListObject};
}