import {EXTRA_COLUMN} from "../../../../constants/DeviceManager";

export const defaultCellWidth=100;
export const defaultCellHeight=40;
export const checkboxWidth=50;

export const calculateAccumulatedWidth=(headerRow)=>{
    let currentLeft=0;
    const headerInfo=Object.assign({},headerRow);
    Object.keys(headerInfo).forEach((key,index)=>{
        const header=headerInfo[key];
        header.accumulatedWidth=currentLeft;
        currentLeft+=((header.width!=null)?header.width:defaultCellWidth);
    });
    const averageWidth=currentLeft/Object.keys(headerInfo).length;
    return {headerRow:headerInfo,averageWidth:averageWidth}
}
export const calculateWidthOfExtraColumn=(headerRowTable,width,allColumnWidth,)=>{
    let oldExtraColumnWidth = 0;
    let extraColumn = headerRowTable.filter( column => column.key === EXTRA_COLUMN);
    if(extraColumn && extraColumn.length === 1){
        oldExtraColumnWidth = extraColumn[0].width;
    }
    allColumnWidth -= oldExtraColumnWidth;
    let newColumnWidth  = width - allColumnWidth;
    if( allColumnWidth >= 0){
        headerRowTable.forEach( item =>{
            if(item.key === EXTRA_COLUMN){
                item.width = newColumnWidth;
            }
        })
    }
    return headerRowTable;
}

export const getColor = (status, disabled) => {
    if(!disabled){
        if(!status){
            return '#7e868c';
        }
        switch(status.toLowerCase()){
            case 'connected':
                return '#009c34';
            case 'disconnected':
                return '#faa63a';
            case 'unknown':
                return '#7e868c';
            default:
                return '#7e868c';
        }
    }else{
        return '#bbb3b3';
    }

    // if(!status){
    //     return '#7e868c';
    // }

    // if(!disabled){
    //     switch(status.toLowerCase()){
    //         case 'connected':
    //             return '#009c34';
    //         case 'disconnected':
    //             return '#faa63a';
    //         case 'unknown':
    //             return '#7e868c';
    //         default:
    //             return '#7e868c';
    //     }
    // }else{
    //     return '#eeeeee';
    // }
}

export const getAntennaStatusLabel = (status, disabled) => {
    if(!disabled){
        if(!status){
            return 'Unknown';
        }
        switch(status.toLowerCase()){
            case 'connected':
                return 'Connected';
            case 'disconnected':
                return 'Disconnected';
            case 'unknown':
                return 'Unknown';
            default:
                return 'Unknown';
        }
    }else{
        return 'Disabled';
    }

    // if(!status){
    //     return 'Unknown';
    // }
    // if(!disabled){
    //     switch(status.toLowerCase()){
    //         case 'connected':
    //             return 'Connected';
    //         case 'disconnected':
    //             return 'Disconnected';
    //         case 'unknown':
    //             return 'Unknown';
    //         default:
    //             return 'Unknown';
    //     }
    // }else{
    //     return 'Disabled';
    // }
}