import * as types from '../constants/ActionTypes';

import {closeDataEntry, displayErrorDialog} from './general';
import {reloadReportWithChanges} from './main';
import {requestMenu} from './menu';
import {displayErrorFromAxios, ReportService} from './util';


export const loadFormFields = (data,formId) => {
    return (dispatch,getState) =>{
        if(formId==='c7654608-af21-4a9c-96d0-3b646dd1b00a'){
            data=getDataZoneUpdate();
        }
        dispatch(({type: types.LOAD_FORM_FIELDS, data, formId}));

        const state = getState();

        if(!state.dataEntry.open) return; // don't bother if it's closed.

        const reportRows = state.dataEntry.initialFormData.reportRows;

        // check fields for "REPORT_ROW" default source. (Close / Error Message if no rows selected.)
        const fields = data;
        let need_rows = fields.some(f => (f.defaultSource === "REPORT_ROW"));
        if(need_rows && !reportRows.length){
            dispatch(displayErrorDialog({
                message:"Please select a row first."
            }));
            dispatch(closeDataEntry());
        }
    };
};
function getDataZoneUpdate(){
    let valueId='c7654608-af21-4a9c-96d0-3b646dd1b00a';
    return [
        {"raw":{"id":2411,"name":":pObjectID","formid":valueId,"caption":"~Object~ ID","x":100,"y":50,"fieldsize":200,"displaytype":1,"filter":"A,s,1,100","defaultsource":1,"defaultvalue":"~Object~ ID","valuesourcetype":0,"txpar_index":1,"valuesource":"","allowchangeinbatch":false},
            "id":2411,"name":"pObjectID","caption":"Resource ID","displayType":"DISPLAY","defaultSource":"REPORT_ROW","defaultValue":"objectId","valueSourceType":"NONE","valueSource":""},
        {"raw":{"id":2413,"name":":pExtTagID","formid":valueId,"caption":"Tag ID","x":100,"y":100,"fieldsize":90,"displaytype":2,"filter":"","defaultsource":1,"defaultvalue":"Tag ID","valuesourcetype":0,"txpar_index":3,"valuesource":"","allowchangeinbatch":false},
            "id":2413,"name":"pExtTagID","caption":"Tag ID","displayType":"DISPLAY","defaultSource":"REPORT_ROW","defaultValue":"tagId","valueSourceType":"NONE","valueSource":""},
        {"raw":{"id":2817,"name":":pSite","formid":valueId,"caption":"Site Name","x":100,"y":75,"fieldsize":200,"displaytype":4,"filter":"","defaultsource":1,"defaultvalue":"0","valuesourcetype":2,"txpar_index":4,"valuesource":"exec PrBR_CBGetSiteNameList @pXMLOption=^:pXMLOption^","allowchangeinbatch":true},
            "id":2817,"name":"pSite","caption":"Site Name","displayType":"COMBOBOX_NOEDIT","defaultSource":"REPORT_ROW","defaultValue":"siteName","valueSourceType":"SELECT","valueSource":"exec PrBR_CBGetSiteNameList @pXMLOption=^:pXMLOption^"},
        /*
        {"raw":{"id":2817,"name":":pSite","formid":valueId,"caption":"Map","x":100,"y":75,"fieldsize":200,"displaytype":4,"filter":"","defaultsource":1,"defaultvalue":"0","valuesourcetype":2,"txpar_index":4,"valuesource":"exec PrBR_CBGetSiteNameList @pXMLOption=^:pXMLOption^","allowchangeinbatch":true},
            "id":2817,"name":"pSite","caption":"Map","displayType":"COMBOBOX_NOEDIT","defaultSource":"REPORT_ROW","defaultValue":"siteName","valueSourceType":"SELECT","valueSource":"exec PrBR_CBGetSiteNameList @pXMLOption=^:pXMLOption^"},
        */
        {"raw":{"id":2891,"name":":pGroup","formid":valueId,"caption":"Zone Group","x":100,"y":75,"fieldsize":200,"displaytype":4,"filter":"","defaultsource":1,"defaultvalue":"0","valuesourcetype":2,"txpar_index":4,"valuesource":"EXEC PrBR_CBLocGrpName @pViewName = ^VBR_CBLocGrpNameSysFirst^, @pLanguageID = ^:pLanguageID^, @pSiteName = ^:pSite^, @pXMLOption=^:pXMLOption^","allowchangeinbatch":true},
            "id":2891,"name":"pGroup","caption":"Zone Group","displayType":"COMBOBOX_NOEDIT","defaultSource":"REPORT_ROW","defaultValue":"locationGroup","valueSourceType":"SELECT","valueSource":"EXEC PrBR_CBLocGrpName @pViewName = ^VBR_CBLocGrpNameSysFirst^, @pLanguageID = ^:pLanguageID^, @pSiteName = ^:pSite^, @pXMLOption=^:pXMLOption^"},

        {"raw":{"id":2892,"name":":pLocation","formid":valueId,"caption":"Zone","x":100,"y":75,"fieldsize":200,"displaytype":4,"filter":"","defaultsource":1,"defaultvalue":"0","valuesourcetype":2,"txpar_index":4,"valuesource":"EXEC PrBR_CBLocName @pViewName = ^VBR_CBLocNameStandardSysFirst^, @pLanguageID = ^:pLanguageID^, @pLocGrpName = ^:pGroup^, @pSiteName = ^:pSite^, @pXMLOption=^:pXMLOption^","allowchangeinbatch":true},
            "id":2892,"name":"pLocation","caption":"Zone","displayType":"COMBOBOX_NOEDIT","defaultSource":"REPORT_ROW","defaultValue":"location","valueSourceType":"SELECT","valueSource":"EXEC PrBR_CBLocName @pViewName = ^VBR_CBLocNameStandardSysFirst^, @pLanguageID = ^:pLanguageID^, @pLocGrpName = ^:pGroup^, @pSiteName = ^:pSite^, @pXMLOption=^:pXMLOption^"},
    ];
}
export const requestFormFields = (formId) => {
    return dispatch =>{

        //dispatch({type:types.REQUEST_MENU, file});

        ReportService.instance().get(`/data-entry/forms/${formId}/fields`)
         .then(response => {
            dispatch(loadFormFields(response.data.results,formId));
         })
         .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};

export const requestFieldValues = (fieldId,params) => {
    return dispatch => {
        return ReportService.instance().get(`/data-entry/fields/${fieldId}/values`,{
            params
        })
        .then(res => res.data)
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};

export const runDataEntry = (formId,args) => {
    return (dispatch, getState) => {
        const state = getState();
        if(formId!=='c7654608-af21-4a9c-96d0-3b646dd1b00a'){
            return ReportService.instance().post(`/data-entry/forms/${formId}/run`,{
                ...args
            })
                .then(() => {
                    dispatch(reloadReportWithChanges());
                    dispatch(requestMenu(state.user.username, state.user.jwtToken));
                })
                .catch(displayErrorFromAxios.bind(null,dispatch));
        }else{
            return runPatchResource(formId,args,dispatch,state);
        }
    };
};

export const runPatchResource = (formId, args, dispatch, state) => {
    let resourceId = args.pObjectID;
    let zoneName=args.pLocation;
    let zoneGroupName=args.pGroup;
    let siteName=args.pSite;
    let zoneId = null;
    return ReportService.instance().get(`/sites?siteName=${siteName}`)
        .then(response => {
            if(response.data.results && response.data.results.length>0)
                return response.data.results[0].site_id;
        }).then(siteId=>{
            return ReportService.instance().get(`/zone-data/zones?site=${siteId}`)
                .then(res=>{
                    return res.data;
                }).then(requestZonesList=>{
                    if(requestZonesList){
                        requestZonesList.forEach(item => {
                            if(item.name===zoneName&&item.locationGroupName===zoneGroupName){
                                zoneId=item.locationGuid;
                            }
                        });
                        if(zoneId){
                            let data = {
                                zones: [{"id": zoneId}]
                            };
                            return ReportService.instance().patch(`v1/resources/${resourceId}?updateFields=zoneId`, data,
                                {
                                    params: {},
                                    headers: {
                                        'Content-Type': 'application/json'
                                    }
                                }
                            ).then(() => {
                                dispatch(reloadReportWithChanges());
                                dispatch(requestMenu(state.user.username, state.user.jwtToken));
                            })
                                .catch(displayErrorFromAxios.bind(null, dispatch));
                        }else{
                            return displayErrorFromAxios.bind(null, dispatch)
                        }
                    }else return displayErrorFromAxios.bind(null, dispatch);
                })
                .catch(displayErrorFromAxios.bind(this,dispatch));
        })
        .catch(displayErrorFromAxios.bind(null,dispatch));
};
export const deleteArrayDataEntry = (formId,values) => {
    return dispatch => {
        //chunking values array
        let resChunk = []
        const chunkSize = 1;    //length of the chunk
        while(values.length > 0){resChunk.push(values.splice(0, chunkSize))}

        let result = resChunk.reduce((accumulatorPromise, chunk) => {
            return accumulatorPromise.then(() => {
              return deleteChunk(formId, chunk, dispatch);
            });
        }, Promise.resolve());
          
        result.then(e => {
            dispatch(reloadReportWithChanges());
        });
    };
}          


function deleteChunk(formId, chunk, dispatch) {
    let promises = [];
    chunk.forEach((args) => {
        let data = null;
        promises.push(
            ReportService.instance().post(`/data-entry/forms/${formId}/run`,{...args})
            .then((res)=>{
                data = {'status':res.status, 'message': res.data};
                dispatch(({type: types.DELETE_MULTIPLE_RECORDS, data, formId}));
            })
            .catch((err) => {
                data ={'status':err.response.status, 'message': err.response.data};
                dispatch(({type: types.DELETE_MULTIPLE_RECORDS, data, formId}));
            })
        )
    })

    return new Promise((resolve, reject) => {
      resolve(
        Promise.all(promises)
        .catch(reason => { 
            console.log('Failed: ',reason);
        })
      )
    });
}
