import PropTypes from "prop-types";
import React from "react";

class ColumnResizer extends React.Component {

    static get propTypes() {
        return {
            onResize: PropTypes.func,
        };
    }

    constructor(props) {
        super(props);

        this.position = 0;
        this.active = false;
        this.width = props.width;
        this.minValue = props.minValue;

        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
    }

    onMouseDown(event) {
        event.preventDefault();
        document.addEventListener('mouseup', this.onMouseUp);
        document.addEventListener('mousemove', this.onMouseMove);

        this.active = true;
        this.mouseInitialPosition = event.clientX;

    }

    onMouseMove(event) {
        event.preventDefault();
        if (this.active === true) {
            this.mouseMoveDistance = event.clientX - this.mouseInitialPosition;
            const newWidth=this.mouseMoveDistance + this.width;
            if(this.props.onResize!=null&&this.minValue<newWidth) {
                this.props.onResize(this.mouseMoveDistance + this.width);
            }
        }
    }

    onMouseUp(event) {
        event.preventDefault();
        document.removeEventListener('mouseup', this.onMouseUp);
        document.removeEventListener('mousemove', this.onMouseMove);
        this.active = false;


    }

    render() {


        return (
            <div
                style={{
                    background: 'transparent',
                    width: '10px',
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: '-5px',
                    textAlign: 'center',
                    zIndex: '1',
                    cursor: 'col-resize'
                }}
                onMouseDown={this.onMouseDown}
            >
                <div style={{
                    width: '1px',
                    height: '100%',
                    margin: 'auto'
                }}
                     className={"resizer-line"}
                ></div>
            </div>
        );
    }
}

export default ColumnResizer;
