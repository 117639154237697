import React, {useEffect, useState} from "react";
// import {useSelector} from 'react-redux';
import CustomAction from "./CustomAction";
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener";

const DeviceHeaderSubMenu = props => {
    const {itemKey, handleCloseLogsSubMenu, handleActionLogs, options} = props;
    const [subMenuOptions, setSubMenuOptions] = useState({});
    const logOptions = options || {};

    // const windowHeight = useSelector(state => state.resize.windowHeight);

    const labelOptions = {
        logs: {
            configureLogs: 'Configure Logs',
            downloadLogs: 'Download Logs',
            // historical: "Logs History"
        }
    };

    const handleSetSubMenuOptions = () => {
        setSubMenuOptions(labelOptions[itemKey]);
    };

    useEffect(handleSetSubMenuOptions, [itemKey]);

    return (
        <ClickAwayListener onClickAway={handleCloseLogsSubMenu}>
            <div className="ListMenu" style={{top: '-170px',left:'-145px'}}>
                <div className="liMenu" style={{minWidth:'115px'}}>
                    {subMenuOptions && Object.keys(subMenuOptions).map((key, index) =>
                        <CustomAction value={subMenuOptions[key]} handleAction={()=>handleActionLogs(key)} actionKey={key} enabled={logOptions[key]} />
                    )}
                </div>
            </div>
        </ClickAwayListener>
    )
};

export default DeviceHeaderSubMenu;