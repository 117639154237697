import React from 'react';
import PropTypes from 'prop-types';

import StyledDialog, {CancelButton, SaveButton} from '../../util/Dialog';

import {DataEntryTabularField, DataEntryTabularForm} from '../../util/DataEntryTabularForm';

import ConfirmDialog from './ConfirmDialog';


class DeleteApplianceDialog extends React.Component {

    constructor(){
        super();

        this.state = {
            openConfirm:false
        };

        this.handleSave = this.handleSave.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    static get propTypes(){
        return {
            open: PropTypes.bool,
            actions: PropTypes.object.isRequired,
            data: PropTypes.object
        };
    }

    static get defaultProps(){
        return {
            open: false,
            data:{ // please remove this...
                appliances:[{
                    uuid:"94ec08bf-2f83-4181-8c91-119dbf53bec8"
                }]
            }
        };
    }

    handleClose ()  {
        this.props.actions.closeDataEntry();
    }

    handleSave(){
        this.setState({openConfirm:!this.state.openConfirm})

    }
    onConfirm (){
        this.actions.deleteAppliance({
            uuid: this.data.appliances[0].uuid
        });
    }
    onClose (){
        this.setState({openConfirm:!this.state.openConfirm})
    }
    confirmDeleteAppliances(){
        return (
            <ConfirmDialog
                subtitle={"Delete Appliance"}
                confirmText={"Delete"}
                confirmColor={"red"}
                content={"Will remove the appliance from the system. No blinks will be processed.  Are you sure you want to proceed?"}
                onSubmit={this.onConfirm}
                onClose={this.onClose}
                styleWidth={480}
                {...this.props}
            />
        )
    }

    renderForm(){

        const _data = this.props.data.appliances;
        if(!_data || !_data[0]) return null;
        const data = _data[0];

        const site = this.props.data.sites[data.siteId];

        return (
            <DataEntryTabularForm fullWidth>

                <DataEntryTabularField label={"Site"}>
                    {site && site.name}
                </DataEntryTabularField>

                <DataEntryTabularField label={"Appliance"}>
                    {data.name}
                </DataEntryTabularField>

                <DataEntryTabularField label={"UUID"}>
                    {data.uuid}
                </DataEntryTabularField>

            </DataEntryTabularForm>
        );
    }

    render() {

        const {
            actions, // eslint-disable-line
            ...other
        } = this.props;

        return (
            <>
            <StyledDialog
                title="Delete Appliance"
                modal={false}
                open={this.props.open}
                onClose={this.handleClose}
                contentStyle={{maxWidth: 360}}
                actions={
                    <>
                        <SaveButton label="Delete" onClick={this.handleSave} />
                        <CancelButton style={{position: 'absolute', left: 8, bottom: 8}} onClick={this.handleClose} />
                    </>
                }
                {...other}
                >
                {this.renderForm()}
            </StyledDialog>
                {this.state.openConfirm?this.confirmDeleteAppliances():null}
               </>
        );
    }
}

export default DeleteApplianceDialog;
