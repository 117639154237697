import React, {useState, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {setValueFilter} from "../../../actions";

const AlertStatus = (props) => {
    const {rowIndex} = props;
    const device = useSelector(state => state.deviceManager.deviceList[rowIndex]);
    const [showMassageTooltip, setShowMassageTooltip] = useState(false);
    const [absoluteStyle, setAbsoluteStyle] = useState(null);
    const tooltipRef = useRef();
    const dispatch=useDispatch();

    const health = device.health;
    const GOOD = 'GOOD', WARNING = 'WARNING', ERROR = 'ERROR';

    let icon;


    const showTooltip=()=>{

        let absoluteStyle= {};
        if(device.alert!=null && device.alert.length>0){
            let tooltipProperties = tooltipRef.current.getBoundingClientRect();
            const heightMenu=60;
            const heightToolbar=48;
            const heightMargin=7;
            const lineHeight=17;
            const freeHeightTop=tooltipProperties.y - (heightMenu+heightToolbar+heightMargin);
            const lineHeightAddition = rowIndex <= 1 ? 4 : 3;
            const estimatedHeightTooltip=(lineHeight*(device.alert.length+lineHeightAddition));
            if(freeHeightTop<estimatedHeightTooltip){
                const bottom=(estimatedHeightTooltip-freeHeightTop);
                absoluteStyle={top:'unset',bottom:-bottom};
            }
        }
        setAbsoluteStyle(absoluteStyle);
        setShowMassageTooltip(true);
    }
    const hiddenTooltip=()=>{
        setShowMassageTooltip(false);
    }

    const renderAlertObject = () => {

        const htmlTooltip=[];
        if(health === GOOD||health ==null){
            const noDetailsStatus=
                <span key={'health'}>{(health==null)?'UNKNOWN':health}</span>
                ;
            htmlTooltip.push(noDetailsStatus);
        }
        else if(device.alert!=null && device.alert.length>0){
            const warnings=[];
            const errors=[];
            device.alert.forEach((alert, index) => {

                const htmlAlert=<div className={'alert-row'} key={index}>
                    {/*<div className={'alert-column-key'}>{alert.statusKey}:</div>*/}
                    {/*<div className={'alert-column-value'}>{alert.value}</div>*/}
                    <div className={'alert-column-description'}>{alert.description}</div>
                </div>

                if(alert.alertType==="ERROR"){
                    errors.push(htmlAlert);
                }
                else{
                    warnings.push(htmlAlert);
                }
            });

            if(errors.length>0) {
                htmlTooltip.push(<div className={'alerts-type-error'} key={'error'}>
                    <div className={'alert-row'}>
                        <b className={'alert-type-title'}>ERRORS</b>
                    </div>
                    {errors}
                    <a className={'alerts-show-more'} href={'#/reports/CF2768DA-D459-11D3-9019-00105A6AE011'} onClick={goToOpenAlerts} key={'more-system-alert'}>Show Open Alert Details</a>
                </div>);
            }
            if(warnings.length>0) {
                htmlTooltip.push(<div className={'alerts-type-warning'} key={'warning'}>
                    <div className={'alert-row'}>
                        <b className={'alert-type-title'}>WARNINGS</b>
                    </div>
                    {warnings}</div>);
            }
        }
        htmlTooltip.push(<a className={'alerts-show-more'} href={'#/reports/C94812E1-C727-4A71-B32E-8D5986E972EE'} onClick={goToHistory} key={'more'}>Show Closed Alert History</a>);

        return htmlTooltip;

    }

    let styleContainer = {color: '#fff', height: '100%'};

    switch(health){
        case GOOD:
            icon = <CheckCircleIcon style={{color: 'green'}} />;
        break;
        case WARNING:
            icon = <WarningIcon style={{color: '#faa63a'}} />;
        break;
        case ERROR:
            icon = <ErrorIcon style={{color: 'red'}} />;
        break;
        default:
            icon = <WarningIcon style={{color: '#9c9c9c'}} />;
        break;
    }
    return <div className={'device-status-container'} style={styleContainer}>
        <div className={'device-status-tooltip-container'}  ref={tooltipRef} onMouseOver={showTooltip} onMouseLeave={hiddenTooltip}>
            {icon}
            {showMassageTooltip ?<div className={'device-status-tooltip'} style={absoluteStyle}>{renderAlertObject()}</div>:null}
        </div>
    </div>;

    function goToHistory(e){
        const value=device.ipAddress;
        // TODO put in const the values of name and report id
        dispatch(setValueFilter("equipmentId",value,'C94812E1-C727-4A71-B32E-8D5986E972EE'));
    }

    function goToOpenAlerts(e){
        const value=device.ipAddress;
        // TODO put in const the values of name and report id
        dispatch(setValueFilter("equipmentId",value,'CF2768DA-D459-11D3-9019-00105A6AE011'));
    }
}

export default AlertStatus;