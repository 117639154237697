import React from 'react';
import PropTypes from 'prop-types';
import assign from 'object-assign';
//openlayers
import {defaults} from 'ol/control';
//import Control from 'ol/control/Control';
//import {Coordinate} from 'ol/coordinate';
//import {Extent} from 'ol/extent';
//import Projection from 'ol/proj/Projection';
import OLMap from 'ol/Map';
import View from 'ol/View';

/**
 * The parent ReactElement that holds all of its pieces together.
 *
 *
 * Children of the <Map /> component will be passed in the following properties:
 * - `map`: The underlying OpenLayers component with which to interact.
 * - `zIndex`: An effective layer / order id of the layer.
 *
 */
class Map extends React.Component{

    /**
     * @private
     */
    constructor(props,context){
        super(props,context);

        /**
         * @type {Object}
         * @property {ReactElement[]} children - Sub-elements of the map (Layers, Overlays, etc.)
         * @property {Control} options - An OpenLayers Control object that handles basic controls.
         * @property {Coordinate} center - An XY coordinate representing the center of the map.
         * @property {int} zoom - Zoom level.
         * @property {double} resolution - Current resolution of the map.
         * @property {double} minResolution - Minimum resolution of the map.
         * @property {double} maxResolution - Maximum resolution of the map.
         * @property {Extent} extent - An array representing the box which is allowed to be the center point.
         * @property {Projection} projection - An OpenLayers Projection for the map.
         * @property {boolean} sphericalMercator - Whether or not the spherical mercator is used for the View.  Ignored if projection is set.
         *
         * @property {function} onPointerMove - Fires when a mouse moves on the map.
         */
         this.props = props;
          // this is actually quite redundant.

        let options = assign({
            controls: props.disableOLControls ? [] : defaults({
                    attributionOptions: ({
                        collapsible: false,
                    })
                })
        },this.props.options);


        /**
         * @private
         *
         * We just want to save the underlying OpenLayers object here.
         */
        this.map = new OLMap(options);
        window.MY_MAP = this.map;

        this.updateView(this.props);

        // Attach listeners.

        this.view.on('propertychange',(e)=>{
            if(e.key==="zoom"){
                //props.onZoom(e.target.get(e.key));
            }
            if(e.key==="resolution"){
                let z = e.target.getZoom();
                let fz = Math.floor(z);
                if(z - fz < 0.001) this.props.onZoom(fz,this.map);
            }
            if(e.key==="center"){
                this.props.onPan();
            }
        });


        if(this.props.disableMoveEvent!==true&&this.props.onPointerMove) this.map.on('pointermove',(e)=>{

            if (e.dragging) {
                return;
            }

            var features = [];
            /*this.map.forEachFeatureAtPixel(e.pixel,(f)=>{
                features.push(f);
            });*/
            return this.props.onPointerMove(e,features);
        });

        if(this.props.onMoveEnd) this.map.on('moveend', (e) =>{
            return this.props.onMoveEnd(e);
        });


        if(this.props.disableDragEvent!==true&&this.props.onPointerDrag) this.map.on('pointerdrag',(e)=>{

            var features = [];
            /*this.map.forEachFeatureAtPixel(e.pixel,(f)=>{
                features.push(f);
            });*/
            return this.props.onPointerDrag(e,features);
        });

        if(this.props.onClick) this.map.on('singleclick',(e)=>{
            var features = [];
            this.map.forEachFeatureAtPixel(e.pixel,(f)=>{
                const keys = f.get("data") && Object.keys(f.get("data"));
                if(!keys || keys.indexOf("zone") === -1) {
                    features.push(f);
                }
            });
            return this.props.onClick(e,features);
        });

        // if(this.props.onPress) this.map.on('singleclick',(e)=>{
        //     var features = [];
        //     /*this.map.forEachFeatureAtPixel(e.pixel,(f)=>{
        //         features.push(f);
        //     });*/
        //     return this.props.onPress(e,features);
        // });


        this.getLayerZIndex = this.getLayerZIndex.bind(this);
        this.zIndices = {};
        this.nextZIndex = 0;
    }

    getMap(){
        return this.map;
    }

    getLayerZIndex(key){
        if(!this.zIndices[key] || this.zIndices[key] !== 0){
            this.zIndices[key] = this.nextZIndex++;
        }
        return this.zIndices[key];
    }

    updateView(props){

        this.view = new View({
            center: props.center || undefined,
            zoom: props.zoom || undefined,
            resolution: props.resolution || undefined,
            maxResolution: props.maxResolution || undefined,
            minResolution: props.minResolution || undefined,
            extent: props.extent || undefined,
            projection: props.projection || (!this.props.sphericalMercator ? "EPSG:900913" : "EPSG:3857")
        });

        /* view parameters */
        this.map.setView(
            this.view
        );
    }

    /**
     * @private
     * Runs after mounting.
     */
    componentDidMount(){
        this.map.setTarget(this.refs.container);
    }

    /**
     * @private
     * Runs before unmounting.
     */
    componentWillUnmount(){

    }

    /**
     * @private
     * Runs after React's render() function.
     */
    componentDidUpdate(pp){

        if(pp.resizeTrigger !== this.props.resizeTrigger){
           this.map.updateSize();
        }

        if(pp.zoom !== this.props.zoom){
            this.map.getView().setZoom(this.props.zoom);
        }

        if(pp.resolution !== this.props.resolution){
            this.map.getView().setResolution(this.props.resolution);
            this.map.getView().setCenter(this.props.center);
        }

        if(pp.center !== this.props.center){

            // if the center changes by more than a unit...
            if(pp.center.some(
                (v,i)=>(
                    (Math.round(v)-Math.round(this.props.center[i]) !== 0)
                )
            )){
                this.map.getView().setCenter(this.props.center);
            }
        }

        if(pp.projection !== this.props.projection){
            this.updateView(this.props);
        }

        this.map.render();
    }

    getPixelSize(){
        return {width: this.refs.container.offsetWidth, height: this.refs.container.offsetHeight};
    }

    /**
     * @private
     * Renders the UI to the Virtual DOM (which updates the actual DOM)
     */
    render(){

        // Standardize the children.
        const children = (this.props.children.length) ? this.props.children.filter((v)=>!!v) : [this.props.children];

        // Give each child a 'map' and a 'zIndex' prop.
        const childrenWithProps = React.Children.map( React.Children.toArray(children),
            (child,i) => React.cloneElement(child, {
                map: this.map,
                zIndexKey:i,
                zIndexFn:this.getLayerZIndex
            })
        );

        // the map will use this div, but its children probably won't.
        // (we just run this to "mount" them in our map)
        return (
            <div ref={'container'} style={this.props.style}>
            {childrenWithProps}
            </div>
        );
    }

    /**
     * @private
     * @return {[type]} [description]
     */
    static get propTypes(){
        return {
            children: PropTypes.any,
            style: PropTypes.object,
            options: PropTypes.any,
            center: PropTypes.array,
            zoom: PropTypes.number,
            onZoom: PropTypes.func,
            onPan: PropTypes.func,
            resolution: PropTypes.any,
            maxResolution:PropTypes.any,
            minResolution: PropTypes.any,
            extent: PropTypes.array,
            projection: PropTypes.any,
            sphericalMercator: PropTypes.any,
            onPointerMove: PropTypes.func,
            onPointerDrag: PropTypes.func,
            onClick: PropTypes.func,
            resizeTrigger: PropTypes.any,      // manual trigger to resize the map.
            disableOLControls: PropTypes.bool,
            onMoveEnd: PropTypes.func,
            onPress: PropTypes.func,
            disableDragEvent: PropTypes.bool,
            disableMoveEvent: PropTypes.bool,
        };
    }

    static get defaultProps(){
        const nop = function(){};
        return {
            onZoom:nop,
            onPan:nop,
            zoom:1
        };
    }
}

export default Map;
