import React, {useState}  from "react";
import {ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails} from '@material-ui/core';
import { AddBox, IndeterminateCheckBox} from '@material-ui/icons';

const Accordion = props => {
    const [openAcc, setOpenAcc] = useState(props.open !== undefined ? props.open : true);
    const {displayName, id, children} = props;
    return(
        <div className={"template-content"}>
            <ExpansionPanel expanded={openAcc} id={id} >
                <ExpansionPanelSummary 
                    className={"template-item-header"}
                    onClick={() => setOpenAcc(!openAcc)}
                    IconButtonProps={{style: {marginRight: 6}}}
                    style={{minHeight: '32px', height: '40px'}}
                    expandIcon={!openAcc ? 
                        <AddBox style={{color: '#000'}}/> : 
                        <IndeterminateCheckBox style={{color: '#000'}}/>} >
                    <span style={{paddingLeft: 12}}>{displayName}</span>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={"template-item-body"} >
                    {children}
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    );
}

export default Accordion;