import React, {useEffect} from 'react';
import {Redirect} from 'react-router-dom';
import {useSelector, useDispatch} from "react-redux";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from '@material-ui/core/StepConnector';
import { makeStyles } from '@material-ui/core/styles';
import {ADD_DEVICES_SCREEN, PATH_ADD_DEVICES_MANUALLY, STEPS_ADD_DEVICE} from '../../../constants/DeviceManager';
import DeviceManagerToolbar from '../toolbars/DeviceManagerToolbar'
//Steps Contents
import FirstStepContent from './FirstStepContent';
import SecondStepContent from './SecondStepContent';
import ThirdStepContent from './ThirdStepContent';
import FirstStepContentBatch from  './FirstStepContentBatch';
import SecondStepContentBatch from './SecondStepContentBatch';
import EditDevice from '../templates/EditDevice';
import {updateStepAddDevices, initAddDevices, loadDeviceManagerScreen, cleanMessagesDevicesBatch, unloadTemplateList, cleanDevicesBatch, detectSaveChanges} from '../../../actions';
const useStyles = makeStyles({
    stepActive: {
        color: "#455a64 !important"
    }
});

function AddManuallyPage(props){  
    const actualStep = useSelector(state => state.deviceManager.actualStep);
    const editScreen = useSelector(state => state.deviceManager.editScreen);
    const canEdit = useSelector(state => state.user.permissions['edit-infrastructure-devices']);

    const {pathParam} = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const loadAddDevices = () => {
        //mounting

        dispatch(updateStepAddDevices(0));
        dispatch(initAddDevices());
        dispatch(loadDeviceManagerScreen(ADD_DEVICES_SCREEN));
        
        const unmount = () => {
            //unmounting
            dispatch(updateStepAddDevices(0));
            dispatch(initAddDevices());
            dispatch(cleanDevicesBatch());
            dispatch(cleanMessagesDevicesBatch());
            dispatch(unloadTemplateList());
            dispatch(detectSaveChanges(false));
        };
        return unmount;
    }

    useEffect(loadAddDevices, [pathParam]);
         
    function getStepContent(stepIndex){
        switch(stepIndex){
            case 0:
                return pathParam === PATH_ADD_DEVICES_MANUALLY? <FirstStepContent/>:<FirstStepContentBatch/>;
            case 1:
                return pathParam === PATH_ADD_DEVICES_MANUALLY?  <SecondStepContent pathParam={pathParam}/>:<SecondStepContentBatch pathParam={pathParam}/>
            case 2:
                return <ThirdStepContent />
            default:
                return 'No content'
        }
    }

    return(
        <div className={"device-manager-container"}>
            <div className={"device-manager-header"}>
                <DeviceManagerToolbar location={pathParam}/>
            </div>
            { !canEdit ? <Redirect to={'/'} /> : 
                !editScreen ? <div className={"device-manager-content"}>
                <div className={"add-devices-page"}>
                    <Stepper className={"container-steppers-add-device"}
                        nonLinear activeStep={actualStep}
                        connector={<StepConnector style={{maxWidth:'100px',minWidth:'20px'}} />} >
                        {STEPS_ADD_DEVICE[pathParam].map((item, index) =>(
                            <Step key={index}>
                                <StepLabel StepIconProps={{
                                    classes: {active: classes.stepActive}
                                }}>
                                    <span style={{fontWeight: (actualStep === index)?'bold':'normal'}}>{item}</span>                                        
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    {getStepContent(actualStep)}
                </div>
            </div>:<EditDevice />}
        </div>        
    )
}

export default AddManuallyPage;