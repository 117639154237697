import React from 'react';
import {useSelector} from "react-redux";
import FlatButton from '@material-ui/core/Button';
import IconButton from "@material-ui/core/IconButton";
import FontIcon from '@material-ui/core/Icon';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import {DialogContent} from '@material-ui/core/es';
import {DialogActions, DialogTitle, withStyles} from "@material-ui/core";
import dateFormat from "dateformat";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";

const outerTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#00779F',
        },
    },
    shadows: Array(25).fill('none')
});

const ColorLinearProgress = withStyles({
    barColorPrimary: {
        backgroundColor: '#00779f'
    }
})(LinearProgress);

const ViewConfigDialog = ({onRequestClose, openBox, title}) => {
    const siteInformation = useSelector(state => state.deviceManager.siteInformation);
    const siteSnapshotError = useSelector(state => state.deviceManager.siteSnapshotError);
    const loadingSiteInfo = useSelector(state => state.deviceManager.loadingSiteInfo);

    const handleRequestDownload = (event, siteInformation) => {
        if(!siteInformation.dateCreated){
            event.stopPropagation();
            return null;
        }
        let stamp = new Date(siteInformation.dateCreated);
        stamp = dateFormat(stamp, "mm_dd_yy_HH_MM_s_Z");
        let element = document.createElement("a");
        let content = JSON.stringify(siteInformation,null,'    ')
        let file = new Blob([content], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = "config_"+stamp+".json";
        element.click();
    };

    const handleFetchScreen = () => {
        return (
            <div style={{background:'inherit',textAlign:'center', zIndex: '100000', padding:'0 9em'}}>
                <div>
                    Getting Data From the Server...
                    <ThemeProvider theme={outerTheme}>
                        <ColorLinearProgress style={{background:'rgba(0,0,0,0.25)',marginTop:'1em'}}/>
                    </ThemeProvider>
                </div>
            </div>
        );
    };

    const handleMainButtons = () => (
        <>
            <IconButton style={{backgroundColor: 'transparent', color: siteInformation.dateCreated ?'inherit':'#b7b2b2', height: 40, width: 40}} onClick={(event) => {handleRequestDownload(event, siteInformation)}} >
                <FontIcon className="material-icons">
                    get_app
                </FontIcon>
            </IconButton>
            <FlatButton label="OK" onClick={onRequestClose} variant="text">OK</FlatButton>
        </>
    );

    const renderCloseButton = () => {
        return (
            <div style={{position:'absolute',top:'1em',right:'1em',color:'#777'}}>
                <IconButton aria-label="close"
                            onClick={onRequestClose}
                            style={{cursor:'pointer',position:'relative',zIndex:'100'}}
                >
                    <CloseIcon />
                </IconButton>
            </div>
        );
    };

    return (
        <Dialog
            open={openBox}
            onClose={onRequestClose}
            maxWidth={"md"}
            PaperProps={{style: {overflowY: 'visible'}}}
            className={""}
        >
            {renderCloseButton()}
            <DialogTitle style={{paddingRight: 50}}>
                <div style={{fontWeight:'300',fontSize:'0.9em'}}>
                    {title}
                </div>
            </DialogTitle>
            <DialogContent>
                <div style={{color: '#0009', minHeight: 40}}>
                    {/*{content}*/}
                    <div style={{width: 800}}>
                        {loadingSiteInfo ? handleFetchScreen() :
                            <div style={{background:'rgba(0,0,0,0.1)',border:'solid 1px',overflow:'auto',maxHeight:window.innerHeight*0.7}}>
                                {siteSnapshotError == null ? (siteInformation && Object.entries(siteInformation).length !== 0?<pre style={{margin:'0',overflow:'visible'}}>
                                    {JSON.stringify(siteInformation,null,'    ')}
                                </pre>:<div>No snapshot information found</div>) : <div style={{color:'red'}}>{siteSnapshotError}</div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </DialogContent>
            <DialogActions className='dialog-buttons'>
                {handleMainButtons()}
            </DialogActions>
        </Dialog>
    );
};


export default ViewConfigDialog;