import React from 'react';
import PropTypes from 'prop-types';


export default class SelectWrapper extends React.Component{

    constructor(){
        super();
        this.state={
            selected:{}
        };
        this.handleTouchTapRow = this.handleTouchTapRow.bind(this);
        this.handleTouchTapCheckbox = this.handleTouchTapCheckbox.bind(this);
        this.handleSelectAll = this.handleSelectAll.bind(this);
        this.isSelected = this.isSelected.bind(this);
    }

    static get propTypes(){
        return {
            data: PropTypes.array,
            uidAttribute: PropTypes.any.isRequired,
            children: PropTypes.element.isRequired
        };
    }

    handleTouchTapRow(index,datum){
        const {uidAttribute} = this.props;
        const key = datum[uidAttribute];
        this.setState({
            selected:{
                ...this.state.selected,
                [key]:!this.state.selected[ key ]
            }
        });
    }

    handleTouchTapCheckbox(index,datum){
        const {uidAttribute} = this.props;
        const key = datum[uidAttribute];
        this.setState({
            selected:{
                ...this.state.selected,
                [key]:!this.state.selected[ key ]
            }
        });
    }

    handleSelectAll(){
        const {data,uidAttribute} = this.props;
        const {selected} = this.state;

        var _sel = {};

        // all selected.
        if(data.length === Object.keys(selected).length){
            this.setState({
                selected:_sel
            });
        // not all selected.
        }else{
            data.forEach(d=>{
                _sel[ d[uidAttribute] ] = true;
            });
            this.setState({
                selected:_sel
            });
        }
    }

    isSelected(index,datum){
        const {uidAttribute} = this.props;
        const key = datum[uidAttribute];
        return !!this.state.selected[ key ];
    }

    getSelected(){
        const {data,uidAttribute} = this.props;
        const {selected} = this.state;
        return data.filter(d=>selected[ d[uidAttribute] ]); // may just want to return the ids... i dunno.
    }

    render(){
        const {children} = this.props;

        return React.cloneElement(
            React.Children.only(children),
            {
                onTouchTapRow:this.handleTouchTapRow,
                onTouchTapCheckbox:this.handleTouchTapCheckbox,
                onSelectAll:this.handleSelectAll,
                isSelected: this.isSelected
            }
        );
    }
}
