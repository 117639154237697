import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const DialogConfirm = (props) => {
    const { open, message, closeDialog, actionSuccess, actionCancel, labelSuccess, labelCancel} = props;
    const lblSuccess = labelSuccess?labelSuccess: 'OK';
    const lblCancel = labelCancel?labelCancel: 'Cancel';
    const styleButton = {backgroundColor:'#4c4c4c',color:'#fff',border:'none',fontSize:'12px',padding:'0.75em',minWidth:'70px',textTransform: 'none'};

    const renderCloseButton = () => {
        return (
            <div style={{position:'absolute',top:'-0.5em',right:'-0.5em'}}>
                <IconButton aria-label="close"
                    size="small"
                    onClick={closeDialog}
                    style={{cursor:'pointer',position:'relative',zIndex:'100',backgroundColor:'#000'}}
                    >
                    <CloseIcon style={{color: '#fff'}} />
                </IconButton>
            </div>
        );
    }
    
    const handleSuccessButton = () => {
        actionSuccess();
        closeDialog();
    }

    const handleCancelButton = () => {
        actionCancel();
        closeDialog();
    }

    return (
        <Dialog
            fullWidth
            maxWidth={'sm'}
            open={open}
            onClose={closeDialog}
            PaperProps={{style: {overflowY: 'visible'}}}>
            <DialogContent style={{paddingBottom:0, fontSize:'1em', whiteSpace: 'pre-line'}}>
            {message}
            </DialogContent>
            {renderCloseButton()}
            <DialogActions style={{padding:'16px 24px'}}>
                <button style={{...styleButton, backgroundColor:'#fff',color:'#007CB0'}} onClick={handleCancelButton}>
                    {lblCancel}
                </button>
                <button style={{...styleButton, background: '#007CB0',color: '#fff'}} onClick={handleSuccessButton}>
                    {lblSuccess}
                </button>
            </DialogActions>           
        </Dialog>
    );
}

export default DialogConfirm;