import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CrossBrowserTable from '../common/tableComponent/CrossBrowserTable';
import FilterTree from "../landing/FilterTree";
import TemplateDetails from "./TemplateDetails";
import TemplateManagerToolbar from "../toolbars/TemplateManagerToolbar";
import FetchScreen from '../common/FetchScreen';
import AppliedFiltersLanding from '../landing/AppliedFiltersLanding';
import {deleteTemplate, loadTemplateList, unloadTemplateList, changeStatusDetails, loadLinkedDevices} from "../../../actions";
import {localeTimestamp} from "../../../utils/TimestampFormats";
import {Link} from "react-router-dom";
import {LANDING_TEMPLATE_TABLE, PATH_EDIT_TEMPLATE} from '../../../constants/DeviceManager';
import {getSidebarSizes} from '../../../constants/Responsive';
import DialogTemplateDelete from "../common/dialogs/DialogTemplateDelete";

const LandingTemplateManager = () =>{
    //from store
    const columnOrder = useSelector(state => state.deviceManager.columnOrder.templates);
    const templateColumnConfig = useSelector(state => state.deviceManager.templateColumnConfig);
    const modernBrowser = useSelector(state => state.user.modernBrowser);
    const templates = useSelector(state => state.deviceManager.templates);
    const showDetails = useSelector(state => state.deviceManager.showDetails);
    const isFetching = useSelector(state => state.deviceManager.isFetching);
    const canEdit = useSelector(state => state.user.permissions['edit-infrastructure-devices']);

    // sidebar constants
    const [sidebarWidth, setSidebarWidth] = useState(300);
    const [sidebarResizing, setSidebarResizing] = useState(false);
    const [animationEnabled, setAnimationEnabled] = useState(false);
    const [header, setHeader] = useState([]);
    const [openAlert, setOpenAlert] = useState(false);
    const [templateId, setTemplateId] = useState(null);
    const handleResize = e => {
        if (e.event === 'mousedown') {
            setAnimationEnabled(false);
            setSidebarResizing(true);
            setSidebarWidth(oldState => e.width || oldState);
        } else if (sidebarResizing) {
            setAnimationEnabled(false);
            setSidebarResizing(e.resizing);
            setSidebarWidth(oldState => e.width || oldState);
        }
    }

    useEffect(() => {
        const headerRowOrdered = columnOrder.filter(item => item.enabled);
        setHeader(headerRowOrdered.length === 1 ? []: headerRowOrdered.map(item => templateColumnConfig[item.name]));
    },[columnOrder,templateColumnConfig]);

    const dispatch = useDispatch();

    const createObject = numberItems => {
        let x=0;
        let parentObject = {};
        while(x<numberItems){
            parentObject[x]=templates[x]['_id'];
            x++;
        }
        return parentObject;
    }
   
    const [selectedRows, setSelectedRows] = useState({});
    const [headerHeight, setHeaderHeight] = useState(0);

    const loadTemplates=() => {
        // Load first time on mount component.
        dispatch(loadTemplateList());
        const unmount = () => {
            dispatch(changeStatusDetails(false));
            dispatch(unloadTemplateList());
        }
        return unmount;
    }

    // Only call once when mount.
    useEffect(loadTemplates, []);

    useEffect(() =>{
        if(Object.keys(selectedRows).length === 0){
            dispatch(changeStatusDetails(false));
        }
    },[selectedRows, dispatch]);

    useEffect(() => {
        if(sidebarWidth === 0 && showDetails){
            const defaultWidth = getSidebarSizes().defaultWidth;
            if(defaultWidth===210){
                setSidebarWidth(300);
            }else{
                setSidebarWidth(defaultWidth);
            }
        }
    },[sidebarWidth, showDetails])

    const handleDeleteTemplate = (templateId) => {
        dispatch(deleteTemplate(templateId));
        setOpenAlert(false);
    };

    const handleConfirmDialog = (templateId) => {
        setOpenAlert(true);
        setTemplateId(templateId);
        dispatch(loadLinkedDevices(templateId))
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const customCell = ({columnIndex, rowIndex, headerKey}) => {
        switch(headerKey){
            case 'title':
                return templates[rowIndex].title;
            case 'deviceType':
                return templates[rowIndex].deviceType;
            case 'createBy':
                return templates[rowIndex].createBy;
            case 'createDate':
                return localeTimestamp(new Date(templates[rowIndex].createDate));
            case 'devices':
                return templates[rowIndex].devices;
            case 'actions':
                if (!canEdit) {
                    return null;
                }
                return (
                        <div style={{display:'flex',justifyContent:'center'}} >
                            <Link style={{color:'#3f51b5'}} to={`/device-manager/templates/${PATH_EDIT_TEMPLATE}/${templates[rowIndex]._id}`}> Edit</Link>
                            <IconButton size="small" onClick={() => handleConfirmDialog(templates[rowIndex]._id)} ><DeleteIcon /></IconButton>
                        </div>
                    );
            default:
                return '';
        }
    }
    const handleClickRow = (rowIndex) => {
        const selectedRowsUpdated=Object.assign({},selectedRows);
        if(selectedRowsUpdated.hasOwnProperty(rowIndex)){
            delete selectedRowsUpdated[rowIndex];
        }
        else {
            selectedRowsUpdated[rowIndex] = templates[rowIndex]['_id'];
        }
        setSelectedRows(selectedRowsUpdated); 
    }

    const handleSelectAll = () => {
        if(Object.keys(selectedRows).length === 0){
            setSelectedRows(createObject(templates.length));
        }else{
            setSelectedRows({});
        }
    }

    const customHeader = ({text, tableWidth, tableHeight, itemKey}) => {
        const items = templates.map(item => item[itemKey]).filter((value, index, self) => self.indexOf(value) === index);
        let columnConfig=templateColumnConfig[itemKey];
        return (
            (itemKey !== 'actions') &&
                <FilterTree
                    items={items}
                    text={text}
                    tableWidth={tableWidth}
                    tableHeight={tableHeight}
                    itemKey={itemKey}
                    type={'templates'}
                    columnConfig={columnConfig}
                    tableScreen={LANDING_TEMPLATE_TABLE}
                />
        );
    }

    function transformObjectToArray(objectParent){
        return Object.keys(objectParent).map(key => objectParent[key]);
    }

    return (
        <div className={"device-manager-container"}>
            <div className={"device-manager-header"}>
                <TemplateManagerToolbar selectedRows={selectedRows} />
            </div>
            <div className={"device-manager-content"}>
                <div className={'device-manager-landing-container'}>
                    <div className={'table-map-container'} style={{flexBasis: `calc(100% - ${showDetails?sidebarWidth:0}px)`}}>
                        {isFetching ?
                            <div style={{width:0}}>
                                Loading...
                            </div>:

                            templates.length > 0 &&
                            <CrossBrowserTable
                            showCheckColumn={true}
                            headerRow={header}
                            headerRender={(...rest)=>customHeader(...rest)}
                            rowCount={templates.length}
                            cellRender={customCell}
                            selectedRows={selectedRows}
                            onClickRow={handleClickRow}
                            onSelectAll={handleSelectAll}
                            modernBrowser={modernBrowser}
                            appliedFilters={(...rest) => AppliedFiltersLanding({...rest, setHeaderHeight, type:'templates'})}
                            appliedFiltersHeight={headerHeight}
                            setHeaderHeight={setHeaderHeight}
                        />}
                    </div>

                    {(showDetails && Object.keys(selectedRows).length> 0) &&
                        <TemplateDetails 
                            selectedRows={transformObjectToArray(selectedRows)}
                            identifier="rightSidebar"
                            resizing={sidebarResizing}
                            handleResize={handleResize}
                            width={sidebarWidth}
                            animationEnabled={animationEnabled}
                            onTransitionEnd={e => setAnimationEnabled(false)}
                            onClose={e => setSidebarWidth(0)}
                        />
                    }
                </div>
                {isFetching? <FetchScreen />: null}

                <DialogTemplateDelete
                    templateId={templateId}
                    openModal={openAlert}
                    onClose={handleCloseAlert}
                    onDelete={handleDeleteTemplate}
                />
            </div>
        </div>
    )
}

export default LandingTemplateManager;