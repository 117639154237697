import * as types from '../constants/ActionTypes';

import Promise from 'promise';

import {AuthService, getErrorMessageFromAxios} from './util';
import qs from 'qs';
import {initializeUserState} from './main';

const LOGIN_MESSAGE_USER_ERROR = "The username or password is wrong.";

/*
████████  ██████  ██   ██ ███████ ███    ██
   ██    ██    ██ ██  ██  ██      ████   ██
   ██    ██    ██ █████   █████   ██ ██  ██
   ██    ██    ██ ██  ██  ██      ██  ██ ██
   ██     ██████  ██   ██ ███████ ██   ████
*/

export const saveJWTToken = (token) =>{
    return (dispatch) => {
        dispatch({type:types.SAVE_JWT_TOKEN, token});
        window.localStorage.setItem("jwtToken",token);

    };
};

/*
██    ██ ███████ ███████ ██████  ███    ██  █████  ███    ███ ███████
██    ██ ██      ██      ██   ██ ████   ██ ██   ██ ████  ████ ██
██    ██ ███████ █████   ██████  ██ ██  ██ ███████ ██ ████ ██ █████
██    ██      ██ ██      ██   ██ ██  ██ ██ ██   ██ ██  ██  ██ ██
 ██████  ███████ ███████ ██   ██ ██   ████ ██   ██ ██      ██ ███████
*/

export const saveUsername = (username) => {
    return dispatch => {
        dispatch({type:types.SAVE_USERNAME, username});
        window.localStorage.setItem("username",username);
    };
};

export const errorDialog=(title, message)=>{
    return (dispatch)=> {
        dispatch({type: types.ERROR_DIALOG, title, message});
    }
}
/*
██       ██████   ██████  ██ ███    ██
██      ██    ██ ██       ██ ████   ██
██      ██    ██ ██   ███ ██ ██ ██  ██
██      ██    ██ ██    ██ ██ ██  ██ ██
███████  ██████   ██████  ██ ██   ████
*/


export const adfsLogin = (token, username, localUser) => {
    return (dispatch) => {
        return new Promise((resolve) => {
            dispatch(saveJWTToken(token));
            dispatch(saveUsername(username));
            saveAccountTypeToStorage(localUser);
            dispatch({type: types.SET_ACCOUNT_TYPE, isLocal:localUser});
            dispatch({type: types.SET_LOGIN_USER, userLogin: false});
            resolve(username);
        });
    }
}

export const setDisableAutoRedirect = (disableAutoRedirect) => {
    return (dispatch) => {
        dispatch({type: types.SET_DISABLE_AUTO_REDIRECT, disableAutoRedirect});
    }
}

export const checkLoginType = () => {
    return (dispatch) => {
        const authService = AuthService.instance();
        authService.get('/authType').then((res) => {
            const authSettings = res.data;
            dispatch({type: types.SET_AUTH_SETTINGS, authSettings});
        });
    };
}

export const login = (username,password)=>{
    return (dispatch) => {

        let _authService = AuthService.instance();
        dispatch({type: types.SET_LOGIN_USER, userLogin: true});

        return _authService.post("/login",qs.stringify({un:username,passwd:password}),{
            headers:{
                'Content-Type':'application/x-www-form-urlencoded'
            }
        })
        .then(function(response){
            dispatch(saveJWTToken(response.data.access_token));
            dispatch(saveUsername(username));

            saveAccountTypeToStorage(response.data.local_user);
            dispatch({type: types.SET_ACCOUNT_TYPE, isLocal:response.data.local_user});
            dispatch({type: types.SET_LOGIN_USER, userLogin: false});
            dispatch({type: types.SET_SESSION, isSessionActive: true});
        })
        .catch(function(err){
            if(err.response){

                /*if(err.response.status>400 && err.response.status<500)
                {
                    dispatch(errorDialog('Error', 'Login Failed'));
                }*/
                if (err.response.status===401) {
                    dispatch(errorDialog('Login Error', LOGIN_MESSAGE_USER_ERROR));
                }
                if(err.response.status===502)
                {
                    dispatch(errorDialog('Error', 'Service Unavailable'));
                }
            }
            dispatch({type: types.SET_LOGIN_USER, userLogin: false});
            return Promise.reject(err);
        });
    };
};

function saveAccountTypeToStorage(type){
    window.localStorage.setItem("accountType",type);
    ACCOUNT_TYPE = type;
}

// Validate if a string is a valid JSON.
function IsJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

let ACCOUNT_TYPE = window.localStorage.getItem("accountType");
ACCOUNT_TYPE=(IsJsonString(ACCOUNT_TYPE))?ACCOUNT_TYPE:null;
ACCOUNT_TYPE = ACCOUNT_TYPE && JSON.parse(ACCOUNT_TYPE);

export const checkLoginStatus = () => {
    return (dispatch,getState) => {

        let state = getState();

        const _AuthService = AuthService.instance();

        return _AuthService.get("/menu")
        .then(()=>{
            var un = state.user.username;
            dispatch( initializeUserState(un) );
            dispatch( saveUsername(un) );

            dispatch({type: types.SET_ACCOUNT_TYPE, isLocal:ACCOUNT_TYPE});

            return [un,state.user.jwtToken];
        });
    };
};

export const changePassword = (username, password, newPassword) => {
    return () => {
        return AuthService.instance().post("/changepasswd",qs.stringify({un:username,passwdOld:password, passwdNew: newPassword}),{
            headers:{
                'Content-Type':'application/x-www-form-urlencoded'
            }
        })
        .catch(err=>Promise.reject(getErrorMessageFromAxios(err)));
    };
};
