import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { updateStateFormTemplate } from "../../../../actions";
import {Checkbox, Button} from '@material-ui/core';
import {Delete} from '@material-ui/icons';
import {ADD_TEMPLATE, HIGHLIGHT_STYLE} from '../../../../constants/DeviceManager';

const GPOPanel = () => {
    const dispatch = useDispatch();
    const GPORules = useSelector(state => state.deviceManager.templateForm.template.GPORules);
    const templateFormLoaded = useSelector(state => state.deviceManager.templateFormLoaded);

    //flag to enable highlights in input fields
    const isHighlight = templateFormLoaded !== ADD_TEMPLATE;

    const [checkAll, setCheckAll] = useState(false);
    const [checkedRules, setCheckedRules] = useState([]);
    const [trigger, setTrigger] = useState('');
    const [port, setPort] = useState(0);
    const [statePort, setStatePort] = useState(false);

    //actions in the table of GPO Rules
    const CHECK = "check";
    const CHECKALL = "checkAll";
    const DELETE = "delete";
    const ADD = "add";

    useEffect(() => {}, [GPORules]);

    const highlightTable = () => {
        //highlighting
        if(isHighlight){
            document.getElementById('gpo-table').style["box-shadow"] = HIGHLIGHT_STYLE;
        }
    }

    const handleChange = (action, index = 0, value = false) => {
        switch(action){
            case CHECK:
                if(value){
                    setCheckedRules(state => [...state, index]);
                }else{
                    setCheckedRules(checkedRules.filter(item => item !== index));
                }
            break;
            case CHECKALL:
                setCheckAll(value);
                setCheckedRules(value?GPORules.map((v,i) => i):[]);
            break;
            case DELETE:
                {
                    let updateRules = [...GPORules];
                    updateRules.splice(index, 1);
                    setCheckedRules([]);
                    dispatch(updateStateFormTemplate('GPORules',null,updateRules));
                    highlightTable();
                }
            break;
            case ADD:
                {
                    let updateRules = [...GPORules];
                    let rule = {action: trigger, port: port, state: statePort};
                    updateRules.push(rule);
                    dispatch(updateStateFormTemplate('GPORules',null,updateRules));
                    highlightTable();
                }
            break;
            default:
            break;
        }
    }
    return (
        <div style={{width: '100%'}}>
            <div className="gpo-items">
                <span className="items-header">Output Events</span>
                <div className="gpo-options">
                    When occurs, 
                        <select value={trigger} onChange={e=>setTrigger(e.target.value)}
                            style={{width: 'auto', margin: '0 8px', backgroundColor: 'white'}}>
                            <option value="" disabled>Select action</option>
                            <option value="overheat">Overheat</option>
                            <option value="low battery">Low Battery</option>
                        </select>
                    trigger GPO port
                        <select value={port} onChange={e=>setPort(Number(e.target.value))}
                            style={{width: 'auto', margin: '0 8px', backgroundColor: 'white'}}>
                            <option value="" disabled>-</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                        </select>
                    to
                        <input id="toggle" value={statePort} onChange={e=>setStatePort(e.target.checked)} type="checkbox" className="checkbox" />
                        <label htmlFor="toggle" className="switch" />

                    <Button style={{backgroundColor:'#007CB0'}} onClick={()=>handleChange(ADD)} className="gpo-options-button">Add Rule</Button>
                </div>
                <table id="gpo-table" className="gpo-table">
                    <thead className="gpo-table-header">
                        <tr>
                            <th><Checkbox style={{padding: 0, color: 'white'}} checked={checkAll} onChange={e=>handleChange(CHECKALL,0,e.target.checked)} /></th>
                            <th style={{width: '100%', borderRight: 'none'}}>Rules</th>
                        </tr>
                    </thead>
                    <tbody className="gpo-table-body">
                        {GPORules.map((v, i) => {
                            return (
                                <tr key={i}>
                                    <th><Checkbox style={{padding: 0, color: 'grey'}} checked={checkedRules.indexOf(i)>-1} onChange={e=>handleChange(CHECK,i,e.target.checked)} /></th>
                                    <th style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                                        <div>{`When occurs, ${v.action} trigger GPO port ${v.port} to ${v.state?'ON':'OFF'}`}</div>
                                        <div>
                                            <Delete fontSize={"small"} style={{cursor: 'pointer'}} onClick={() => handleChange(DELETE,i)} />
                                        </div>
                                    </th>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default GPOPanel;