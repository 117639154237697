import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import ReactToolTip from 'react-tooltip';

import {MAP_VIEW_URL, SITE_VIEW_HOST} from '../../../constants/Misc';

import {overlayHeight, SmartSiteMapThumbnailDrawer} from '../SiteMapThumbnailOverlay';
//openlayers
import {easeOut} from 'ol/easing';
import {getCenter} from 'ol/extent';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Text from 'ol/style/Text';
import ZoneToggleButton from './misc/ZoneToggleButton';
import CordialErrorScreen from './misc/CordialErrorScreen';

import ZoomInIcon from '@material-ui/icons/AddBox';
import CenterIcon from '@material-ui/icons/CenterFocusStrong';
import ZoomOutIcon from '@material-ui/icons/IndeterminateCheckBox';
import DeviceGpsFixed from '@material-ui/icons/GpsFixed';
import DeviceGpsOff from '@material-ui/icons/GpsOff';
import DeviceGpsNotFixed from '@material-ui/icons/GpsNotFixed';
// import PlayIcon from '@material-ui/icons/av/play-arrow';
import PlayIcon from '@material-ui/icons/PlayArrow';


import {BareLocalMap, SmarterTagsLayer, ZoneVisibility} from './LocalMap';

import knowZones from '../hoc/knowZones';

import IconButton from '@material-ui/core/IconButton';
import WorldIcon from '@material-ui/icons/Public';
import MapsIcon from '@material-ui/icons/Collections';
import TagLabelIcon from '@material-ui/icons/Crop75';
// import AddIcon from '@material-ui/icons/Add';
// import ListIcon from '@material-ui/icons/List';

import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../../../actions';
import * as views from "../../../constants/ViewTypes";

// import {setPlaybackMapId} from "../../../actions/main";
// import {getDateFromTimestamp} from "../../../utils/LocaleTimestamp";
// import ClusteredTagLayer from "./layers/ClusteredTagLayer";
import TagListComponent from "./playback/TagListComponent";
// import moment from "moment";
import {getLabelByIdAttribute} from "../../../utils/View";
import {CALL_HISTORY_ID} from "../../../constants/History";
import {ZOOM_INCREMENT} from "../../../constants/SiteManager";

const styles = {
    container:{
        position:'absolute',
        bottom:'0em',
        left:'0em',
        right:'0em',
        top:'0em'
    },
};

/*
██       ██████   ██████  █████  ██
██      ██    ██ ██      ██   ██ ██
██      ██    ██ ██      ███████ ██
██      ██    ██ ██      ██   ██ ██
███████  ██████   ██████ ██   ██ ███████
*/

export function zoomMapByDeltaWithDuration(map,delta,duration) {

    var view = (map)?map.getView():null;
    if (!view) {
        // the map does not have a view, so we can't act
        // upon it
        // eslint-disable-next-line
        throw "Map does not have view.  Cannot zoom.";
    }
    var currentResolution = view.getResolution();

    if(!currentResolution){
        // eslint-disable-next-line
        throw "No current resolution.";
    }

    // var newResolution = view.constrainResolution(currentResolution, delta);
    const newZoom = view.getZoom() + delta;
    if (duration > 0) {
        if (view.getAnimating()) {
            view.cancelAnimations();
        }
        view.animate({
            //resolution: currentResolution - delta,
            zoom:newZoom,
            duration: duration,
            easing: easeOut
        });
    } else {
        //view.setResolution(currentResolution - delta);
        view.setZoom(newZoom);
    }
}

const DEFAULT_BOUNDS = [0,0,100,100];

class BoundsCheckingLocalMap extends React.Component{

    static get propTypes(){
        return {
            mapId: PropTypes.any,
            requestMapBounds: PropTypes.func.isRequired,
            onError: PropTypes.func,
            children:PropTypes.any
        };
    }

    static get defaultProps(){
        return {
            onError:()=>{}
        };
    }

    constructor(props){
        super(props);
        this.state = {
            errorMsg: null,
            bounds: DEFAULT_BOUNDS,
            mapId: props.mapId // caching this so it'll only update once.
        };
    }

    componentDidMount(){
        this.getBounds(this.props.mapId);
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        const {mapId} = nextProps;

        if(mapId !== this.props.mapId){
            this.setState({
                errorMsg: null
            });
            this.getBounds(mapId);
        }
    }

    shouldComponentUpdate(nextProps,nextState){
        return this.props !== nextProps || this.state !== nextState;
    }

    getTagsOnMap(tags,mapId){
        return tags.filter(t=>t.map===mapId);
    }

    componentDidUpdate(prevProps){

        const{tags, mapId, feature, selectedTag} = this.props;

        if(!!tags && !!mapId && feature && feature.zoomObject && selectedTag) {
            const tagsOnMap = this.getTagsOnMap(this.props.tags, this.props.mapId);

            let tagsXY = [];
            let selectedTags = tagsOnMap.filter(t => t.raw.___selected);
            selectedTags.forEach((tagObj) => {
                const x = tagObj["x"];
                const y = tagObj["y"];
                if (x && y && typeof x === "number" && typeof y === "number") {
                    tagsXY.push({x, y});
                }
            });
            let corrds = [];
            if (tagsXY && tagsXY.length) {
                const minX = tagsXY.sort((a, b) => a["x"] - b["x"])[0]["x"];
                const minY = tagsXY.sort((a, b) => a["y"] - b["y"])[0]["y"];
                const maxX = tagsXY.sort((a, b) => b["x"] - a["x"])[0]["x"];
                const maxy = tagsXY.sort((a, b) => b["y"] - a["y"])[0]["y"];
                corrds = [minX, minY, maxX, maxy];
                window.MY_MAP.getView().fit(corrds, {size: window.MY_MAP.getSize(), maxZoom: 5})
            }
        }
    }

    getMap(){
        if(this.refs.localMap)
            return this.refs.localMap.getMap();
        else
            return null;
    }

    // -------------------------------------------------------------------------

    getBounds(mapId){

        if(!mapId){
            this.setState({
                errorMsg: (
                    <div>
                        No map selected.
                    </div>
                ),
                bounds:DEFAULT_BOUNDS
            });
            return;
        }

        this.props.requestMapBounds(mapId)
        .then(bounds=>{
            window._origninalBound = bounds; // quick fix. TODO get it from redux store
            if(bounds[2] <= bounds[0] || bounds[3] <= bounds[1]){
                this.setState({
                    errorMsg: (
                        <div>
                            Invalid map coordinate configuration.
                            <br /><br />
                            Please configure with Site Manager.
                        </div>
                    ),
                    bounds:DEFAULT_BOUNDS
                });
                this.props.onError(true);
            } else {
                this.setState({
                    bounds,
                    mapId,
                    errorMsg: null
                });
                this.props.onError(false);
            }

        })
        .catch(err=>{
            this.setState({
                errorMsg: (
                    <div>
                        Invalid map coordinate configuration.
                        <br /><br />
                        Please configure with Site Manager.
                    </div>
                ),
                bounds:DEFAULT_BOUNDS
            });
            this.props.onError(true);
        });
    }

    // -------------------------------------------------------------------------

    render(){
        const {children, mapId} = this.props;
        const {errorMsg, bounds} = this.state;

        return errorMsg ? (
            <CordialErrorScreen>
                {errorMsg}
            </CordialErrorScreen>
        ) : (
            <BareLocalMap
                tilePrefix={ SITE_VIEW_HOST + MAP_VIEW_URL +"/maps/" + mapId + "/tiles/" }
                bounds={ bounds }
                {...this.props}
                disableOLControls
                ref={"localMap"}
            >
                {children}
            </BareLocalMap>
        );
    }

}


export {BoundsCheckingLocalMap};

class WrappedLocalMap extends React.Component{

    constructor(props){
        super(props);
        this.layers = {};
        let map = props.maps && props.maps[0];
        if(props.maps&&props.currentMapId)
            map=props.maps.filter(item=>item._id===props.currentMapId)[0];

        this.state = this.stateFromMap(props,map);
        this.handleSelectMap = this.handleSelectMap.bind(this);
    }

    static get propTypes(){
        return {
            maps: PropTypes.arrayOf(
                PropTypes.shape({
                    _id: PropTypes.number.isRequired
                })
            ).isRequired,
            tags: PropTypes.array.isRequired,
            zones: PropTypes.array.isRequired,
            requestMapBounds: PropTypes.func.isRequired,
            onError: PropTypes.func.isRequired,
            zoneVisibility: PropTypes.number,
            showMapDrawer: PropTypes.bool,
            site: PropTypes.shape({
                _id:PropTypes.any
            }),
            currentMapId: PropTypes.number,
            mapConfiguration:PropTypes.any,
            data:PropTypes.array,
            changeMap:PropTypes.func,
        };
    }

    stateFromMap(props,map){
        return {
            mapId: map._id,
            maxZoom: (map.maxZoom - 1) || 0,
            ...this.getTagZoneStateOnMap(props,map._id)
        };
    }

    shouldComponentUpdate(nextProps,nextState){
        return (this.props !== nextProps || nextState !== this.state);
    }


    UNSAFE_componentWillReceiveProps(nextProps, nextState){
        if(this.props.maps !== nextProps.maps||(this.props.currentMapId!==nextProps.currentMapId && !!nextProps.currentMapId)){
            let map = nextProps.maps && nextProps.maps[0];
            if(this.props.currentMapId!==nextProps.currentMapId )
            {
                map=nextProps.maps.filter(item=>item._id===nextProps.currentMapId)[0];
            }

            if(map&&map._id !== this.state.mapId){
                this.setState({
                    ...this.stateFromMap(nextProps,map)
                });
                return;
            }
        }

        if(this.props.tags !== nextProps.tags || this.props.zones !== nextProps.zones){
            this.setState({
                ...this.getTagZoneStateOnMap(nextProps,this.state.mapId)
            });
        }

        if(this.props.zoneVisibility !== nextProps.zoneVisibility || this.state.mapId !== nextState.mapId) {
            const active = nextProps.zoneVisibility >= ZoneVisibility.ZONES_ONLY;
            if(this.state.mapId !== nextState.mapId){
                this.removeLayers();
            }
            if (active) {
                const labelActive = nextProps.zoneVisibility === ZoneVisibility.ZONES_AND_LABELS;
                this.drawZones(this.state.zonesOnMap, active);
                this.drawLabels(this.state.zonesOnMap, labelActive);
            } else {
                this.removeLayers();
            }
        }
    }

    getMap(){
        return this.refs.localMap.getMap();
    }

    // -------------------------------------------------------------------------

    handleSelectMap(map){
        this.setState({
            ...this.stateFromMap(this.props,map)
        });
        if(map) {
            this.props.changeMap(map);
            this.props.setPlaybackMapId(map._id);
        }
    }

    // -------------------------------------------------------------------------

    getTagZoneStateOnMap(props,mapId){
        return {
            tagsOnMap:this.getTagsOnMap(props.tags,mapId),
            zonesOnMap:this.getZonesOnMap(props.zones,mapId)
        };
    }

    getTagsOnMap(tags,mapId){
        return tags.filter(t=> (t.map===mapId));
    }

    getZonesOnMap(zones,mapId){
        return zones.filter(z=>z.map===mapId);
    }

    // -------------------------------------------------------------------------

    drawZones(zones, active){
        this.map = window.MY_MAP;
        if(active) {
            const elementId = this.state.mapId;
            if (this.layers.hasOwnProperty(elementId)) {
                this.map.removeLayer(this.layers[elementId]);
            }
            const zonesOnMap = zones.filter(zone => zone.abbr !== "2" && zone.locationTypeId === 0);
            let _zones = zonesOnMap.map((v, i) => {

                const zoneFeature = new Feature({
                    geometry: new Polygon([v.shape]),
                    data: {index: i, name: v.name, color: v.color, zone: v.name}
                });
                return zoneFeature;
            });


            const zoneLayer = new VectorLayer ({
                renderMode: 'image',
                source: new VectorSource ({
                    features: [..._zones]
                }),
                style: function(feature){
                    return new Style({
                        fill: new Fill({
                            color: [feature.get('data').color[0], feature.get('data').color[1], feature.get('data').color[2], feature.get('data').color[3] || 0.5]
                        }),
                        stroke: new Stroke({color: '#000000'})
                    });
                }
            });


            this.layers[elementId+"zone"] = null;
            this.layers[elementId+"zone"] = zoneLayer;

            this.map.addLayer(zoneLayer);
        }
    }

    getLabelStyle(){
        if(this.labelStyle == null) {
            let zoneLabel = {
                text: new Text({
                    font: '16px Calibri,sans-serif',
                    fontWeight: '600',
                    fill: new Fill({color: '#000'}),
                    backgroundFill: new Fill({color: "#fff"}),
                    backgroundStroke: new Stroke({color: "#000", width: 1}),
                    offsetY: 0,
                    overflow: true,
                    padding: [1.5, 5, 1.5, 5]
                })
            };

            this.labelStyle = new Style({
                ...zoneLabel
            });
        }
        return this.labelStyle;
    }

    drawLabels(zones, labelActive){
        this.map = window.MY_MAP;
        if(labelActive){
            const elementId = this.state.mapId;
            if (this.layers.hasOwnProperty(elementId)) {
                this.map.removeLayer(this.layers[elementId+"label"]);
            }
            const zonesOnMap = zones.filter(zone => zone.abbr !== "2" && zone.locationTypeId === 0);
            let zonesLabel = zonesOnMap.map((v, i) => {
                const label = v.label || v.name;

                const labelFeature = new Feature({
                    geometry: new Polygon([v.shape])
                });
                labelFeature.set("label", label);
                return labelFeature;
            });
            const labelStyle = this.getLabelStyle();

            const labelLayer = new VectorLayer ({
                zIndex: 20,
                renderMode: 'image',
                source: new VectorSource ({
                    features: [...zonesLabel]
                }),
                style: function (feature) {
                    labelStyle.getText().setText(feature.get('label'));
                    return labelStyle;
                }
            });


            this.layers[elementId+"label"] = null;
            this.layers[elementId+"label"] = labelLayer;
            this.map.addLayer(labelLayer);
        }
    }

    removeLayers() {
        this.map = window.MY_MAP;
        for(const key in this.layers)
        {
            const layer=this.layers[key];
            this.map.removeLayer(layer);
        }

    }

    render(){
        const {
            zoneVisibility,
            showMapDrawer,
            site,
            mapConfiguration,
            data,
            updateClusterIndexAction,
            highlightedItemMap,
            highlightItemMap,
            siteId,
            selectManyRows,
            openRightSidebar,
            displayErrorDialog,
            setPlaybackMapId,
            setPlaybackInfo,
            selected,
            onItemSelected,
            selectCluster,
            clickedItem,
            ...other} = this.props;

        const {tagsOnMap, mapId, maxZoom} = this.state;

        let drawer = null;
        if(showMapDrawer){
            drawer = (
                <SmartSiteMapThumbnailDrawer
                    mapId={mapId}
                    siteId={site._id}
                    onSelectMap={this.handleSelectMap}
                    verticalPosition={false}
                 />
            );
        }

        return (
            <div className="vss-map">
                <BoundsCheckingLocalMap onMoveEnd={this.handleMoveEnd} {...this.props} mapId={mapId} maxZoom={maxZoom} ref="localMap">
                    <SmarterTagsLayer
                        highlightedItemMap={highlightedItemMap}
                        highlightItemMap={highlightItemMap}
                        updateClusterIndexAction={updateClusterIndexAction}
                        mapConfiguration={mapConfiguration}
                        data={data} {...other}
                        tags={tagsOnMap}
                        siteId={siteId}
                        mapId={mapId}
                        onItemSelected={onItemSelected}
                        selectCluster={selectCluster}
                        setPlaybackMapId={setPlaybackMapId}
                        setPlaybackInfo={setPlaybackInfo}
                        selectManyRows={selectManyRows}
                        openRightSidebar={openRightSidebar}
                        displayErrorDialog={displayErrorDialog}
                        selected={selected}
                        clickedItem={clickedItem}
                        changeStatusWS={this.props.changeStatusWS}
                    />
                </BoundsCheckingLocalMap>
                {drawer}
            </div>
        );
    }
}



const KnowledgableWrappedLocalMap = knowZones(WrappedLocalMap);

// reportId to block playback on Call History
const callHistoryId = CALL_HISTORY_ID;

class LocalMapView extends React.Component{
    constructor(props){
        super(props);
        this.leftControllBar = null;
        const toolbarItems = this.getRenderToolbarItems(props);

        this.state={
            x:0,
            y:0,
            errored: false,
            zoneVisibility: ZoneVisibility.NO_ZONES,
            showTagLabels: false,
            showMapDrawer: false,
            showTagInfo: false,
            showTagList:false,
            ...toolbarItems
        };

        this.currentZoomPopup=null;
        this.currentMapPopup=null;

        this.handleBackToWorld = this.handleBackToWorld.bind(this);
        this.handlePointerMove = this.handlePointerMove.bind(this);
        this.centerMap = this.centerMap.bind(this);
        this.zoomIn = this.zoomIn.bind(this);
        this.zoomOut = this.zoomOut.bind(this);
        this.toggleZoneVisibility = this.toggleZoneVisibility.bind(this);
        this.toggleShowTagLabels = this.toggleShowTagLabels.bind(this);
        this.toggleMapDrawer = this.toggleMapDrawer.bind(this);
        this.toggleTagInfo = this.toggleTagInfo.bind(this);
        this.toggleRealTime = this.toggleRealTime.bind(this);

        this.handleError = this.handleError.bind(this);
        this.handleChangeMap = this.handleChangeMap.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.handleChangeStatusWS = this.handleChangeStatusWS.bind(this);

        this.getSiteName = this.getSiteName.bind(this);
        this.getSiteId = this.getSiteId.bind(this);
        this.countUniqueElementsByIdAttribute = this.countUniqueElementsByIdAttribute.bind(this);

        this.handleRemoveItemsPlayback = this.handleRemoveItemsPlayback.bind(this);
    }

    static get propTypes(){
        return {
            actions:PropTypes.object,
            infoBoxContent: PropTypes.any,
            onSelectSite: PropTypes.func,
            site: PropTypes.shape({
                _id: PropTypes.string.isRequired
            }),
            maps: PropTypes.array,
            currentMapId: PropTypes.number,
            realTime: PropTypes.object,
            connectedRealTime: PropTypes.object,
            showMapDrawer: PropTypes.bool
        };
    }

    static get defaultProps(){
        return {
            onSelectSite: ()=>{}
        };
    }

    componentDidUpdate(prevProps,prevState){
        if(prevState.showTagInfo !== this.state.showTagInfo){
            let m = this.getMap();
            m.updateSize();
        }

        this.map = window.MY_MAP;
        // const overlays = this.map.getOverlayById("popupItemSelector");
        // if(overlays==null)
        // {
            // this.addPopupOverlay();
            // // this.addDragEvent();
            // this.map.getTargetElement().addEventListener("mousedown",this.handleMouseDown);
        // }

        if(this.props.clusterIndex === null || this.props.data.length !== prevProps.data.length){
            //this.overlay.setPosition(undefined);

            if(this.layerSeletedItems!=null){
                this.map.removeLayer(this.layerSeletedItems);
            }
        }

        if (this.props.showMapDrawer !== prevProps.showMapDrawer){
            this.setState({
                showMapDrawer:this.props.showMapDrawer
            })
        }
    }

    handleMouseUp(event){

        const coordinates=this.map.getCoordinateFromPixel(event.pixel);
        this.overlay.setPositioning('center-right');
        this.overlay.setPosition(coordinates);

    }

    getMap(){
        return this.map;
    }

    handleBackToWorld(){
        this.props.onSelectSite(null);
        this.props.changeLocalMap(this.props.reportId,null,null);
    }

    handlePointerMove(x,y){

        this.setState({
            x: x.toFixed(2),
            y: y.toFixed(2)
        });
    }


    shouldComponentUpdate(nextProps,nextState){
        return (this.props !== nextProps || this.state !== nextState);
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        let objectState = {};
        if(this.props.selected !== nextProps.selected
            ||this.props.playback !== nextProps.playback
        ){
            const toolbarItems = this.getRenderToolbarItems(nextProps);
            objectState = {...objectState, ...toolbarItems};
            this.setState(objectState);
        }

        if(nextProps.trackedBlink && this.props.data !== nextProps.data){
            if(this.timeout) window.clearTimeout(this.timeout);
            this.handleChangeStatusWS();
        }
    }

    componentDidMount(){
        this.setState({
            showMapDrawer:this.props.showMapDrawer
        })
    }

    getRenderToolbarItems(props) {
        const {idAttribute,reportId,playback,selected,currentPlaybackSiteName} = props;

        const itemsPlayback = (playback!=null&&playback.items!=null)?playback.items:{};
        const countElementsPlayback = Object.keys(itemsPlayback).length;

        // Find unique events of selected elements.
        const eventsSelected=Object.keys(selected).map(key=>selected[key].event).filter((event,index,self)=>self.indexOf(event)===index);
        const countEventsSelected=eventsSelected.length;

        // Find unique elements selected.
        const itemsSelected = Object.keys(selected).map(key=>selected[key][idAttribute]).filter((itemId,index,self)=>self.indexOf(itemId)===index);
        const countElementsByIdAttribute=itemsSelected.length;

        let addItemsPlaybackEnabled=false;
        let goPlaybackEnabled=false;
        let listItemsPlaybackEnabled=true;

        const siteName = this.getSiteName(selected,currentPlaybackSiteName);
        const siteId = this.getSiteId(siteName);

        const defaultMessage="Playback is Disabled in Current Report";
        let goPlaybackMessage= (callHistoryId === reportId)?defaultMessage:"Go to Playback";
        let addItemsPlaybackMessage= (callHistoryId === reportId)?defaultMessage:"Add items for Playback";
        let showListPlaybackMessage= (callHistoryId === reportId)?defaultMessage:"Show list of Playback items";

        if(siteName
           && callHistoryId !== reportId
           && countElementsByIdAttribute>0&&countElementsByIdAttribute<=10
        ){
            goPlaybackEnabled=true;
        }

        if(countElementsByIdAttribute>0 && !siteName) goPlaybackMessage = "Only items from same Site Name allowed";

        if(countElementsByIdAttribute>0&&countElementsByIdAttribute<=10
            &&countElementsPlayback<10&&countEventsSelected===1
        ){
            addItemsPlaybackEnabled=true;
        }
        else if(countElementsByIdAttribute>10){
            goPlaybackMessage="Too many items selected";
        }
        else if(countEventsSelected>1){
            goPlaybackMessage="Different events selected";
        }

        return {
            siteName,
            siteId,
            addItemsPlaybackEnabled,
            goPlaybackEnabled,
            listItemsPlaybackEnabled,
            goPlaybackMessage,
            addItemsPlaybackMessage,
            showListPlaybackMessage,
        };
    }

    render(){
        const {showTagInfo} = this.state;
        const {playback,idAttribute} = this.props;
        let itemsPlayback={};
        const elementIdLabel=getLabelByIdAttribute(idAttribute);
        if(playback!=null&&playback.items!=null){
            itemsPlayback=playback.items;
        }

        return (
            <div className="vss-map">
                <div style={styles.container}>
                    <div className="vss-map" style={{marginLeft: 38, marginRight:'-10px',marginTop:'-10px',marginBottom:'0px', right:(showTagInfo?'300px':0)}}>
                        {this.renderContent()}
                    </div>
                </div>
                <Paper className="vss-map" style={{transition:0,left:'auto',width:(showTagInfo? '300px':0)}} elevation={2}>
                    <div style={{fontSize:'1.25em',padding:'0.5em',borderBottom:'solid 1px rgba(0,0,0,0.7)'}}>Information</div>
                    {this.props.infoBoxContent}
                </Paper>
                {this.renderToolbar()}
                <TagListComponent
                    showTagList={this.state.showTagList}
                    tags={itemsPlayback}
                    handleRemoveTag={this.handleRemoveItemsPlayback}
                    showCheckBox={false}
                    elementIdLabel={elementIdLabel}
                    handleSelectTag={()=>{}}
                />
            </div>
        );
    }


    renderContent(){
        const {site,maps,mapConfiguration,data,currentLocalMap,siteId,highlightedItemMap,highlightItemMap,selectManyRows,onItemSelected,selectCluster,clickedItem} = this.props;
        const currentMapId =  currentLocalMap[this.props.reportId] && currentLocalMap[this.props.reportId].localMapId;
        const mapsForSite = this.getMapsForSite(maps,site._id);

        const {zoneVisibility,showMapDrawer,showTagLabels} = this.state;

        if(!mapsForSite.length){
            return (
                <CordialErrorScreen>
                    No maps have been uploaded for this site.
                </CordialErrorScreen>
            );
        }


        // Check if the currentMapId is in the list of maps of site.
        const currentMapIdForSite=this.validateCurrentMapId(currentMapId,mapsForSite);

        return (
            <div className="vss-map">
                <KnowledgableWrappedLocalMap
                    {...this.props}
                    siteId={siteId || site._id}
                    maps={mapsForSite}
                    onPointerMove={this.handlePointerMove}
                    onError={this.handleError}
                    zoneVisibility={zoneVisibility}
                    showTagLabels={showTagLabels}
                    showMapDrawer={showMapDrawer}
                    mapConfiguration={mapConfiguration}
                    data={data}
                    changeMap={this.handleChangeMap}
                    currentMapId={currentMapIdForSite}
                    setPlaybackMapId={this.props.setPlaybackMapId}
                    updateClusterIndexAction={this.props.updateClusterIndex}
                    highlightedItemMap={highlightedItemMap}
                    highlightItemMap={highlightItemMap}
                    selectManyRows={selectManyRows}
                    onItemSelected={onItemSelected}
                    selectCluster={selectCluster}
                    clickedItem={clickedItem}
                />
                {this.renderCoordinates()}
            </div>
        );
    }

    handleError(errored){
        this.setState({
            errored: errored
        });
    }
    handleChangeMap(map){
        this.props.changeLocalMap(this.props.reportId,map);
    }

    getMapsForSite(maps,siteId){
        if(siteId !== this.lastSiteID || !this.lastSiteMaps.length){
            this.lastSiteID = siteId;
            this.lastSiteMaps = maps.filter(m=>m.siteId === siteId);
        }
        return this.lastSiteMaps;
    }

    renderCoordinates(){

        const {x,y,errored,showMapDrawer} = this.state;

        if(errored) return null;

        return (
            <div style={{
                position:'absolute',
                bottom:(showMapDrawer ? overlayHeight+'px' : 0),
                right:0,
                padding:'1.0em',
                fontSize:'0.9em'
            }}>
                <div style={{textAlign:'center'}}>{`(${x}, ${y})`}</div>
            </div>
        );
    }

    countUniqueElementsByIdAttribute(selected,itemsPlayback, idAttribute) {

        const ids = {};

        for (const key in selected) {
            const element = selected[key];
            const id = element[idAttribute];
            const event = element.event;
            if (!ids.hasOwnProperty(id)) {
                ids[id]=event;
            }
            else{
                if(ids[id]!==event) {
                    return -1;
                }
            }
        }

        return Object.keys(ids).length;
    }

    /**
    * @param {Object} selected - gets all the selected rows
    * @param {String} currentPlaybackSiteName - gets the current state playbackSiteName
    *
    * returns the current siteName and if there are different
    * siteNames in the selected Object.array it returns null to disable the playback button
    */

    getSiteName(selected, currentPlaybackSiteName) {
        let unCheck = false;
        let value = null;
        Object.keys(selected).forEach(s=> {
            if(selected[s].siteName === currentPlaybackSiteName){
                value = currentPlaybackSiteName;
                unCheck = true;
            } else {
                value = selected[s].siteName;
            }
        });
        if(unCheck){
            Object.keys(selected).forEach(s=> {
                if(selected[s].siteName !== currentPlaybackSiteName) value = null;
            });
        }
        return value;
    }

    /**
     * @param {String} currentPlaybackSiteName - gets the current state playbackSiteName
     *
     * returns the current playbackSiteId filtering from the state sites array list
     */

    getSiteId(currentPlaybackSiteName){
        let site = this.props.sites.filter(s => s.name === currentPlaybackSiteName);
        return !!site && site[0] !== undefined ? site[0]._id : 'none';
    }

    renderToolbar(){
        const {zoneVisibility,showMapDrawer,showTagLabels
                ,goPlaybackEnabled,goPlaybackMessage,/* addItemsPlaybackMessage,showListPlaybackMessage,addItemsPlaybackEnabled, */} = this.state;
        const {realTime, connectedRealTime, reportId, realTimeIsDisabled,currentView,selected, currentPlaybackSiteName} = this.props;

        const siteName = this.getSiteName(selected,currentPlaybackSiteName);
        const siteId = this.getSiteId(siteName);
        //const DISABLED_COLOR = "#aaa";

        const styles = LocalMapView.styles;

        return  (
            <Paper className="paper-lmvt" ref={"leftToolBar"} style={{position:'absolute',top:'0',bottom: 0,left:0, transition:0}} elevation={2}>

                <IconButton onClick={this.handleBackToWorld} style={{backgroundColor: 'transparent', display:'block', color: '#000'}} data-tip={"Show WorldMap"} data-for={'worldMap'}>
                    <WorldIcon />
                </IconButton>
                <ReactToolTip className={"tooltip-class"} place={"right"} id={"worldMap"} effect={"solid"} />

                <IconButton style={styles.zoomInButton} disableRipple onClick={this.zoomIn} data-tip={"Zoom In"} data-for={"zoomIn"} >
                    <ZoomInIcon />
                </IconButton>
                <ReactToolTip className={"tooltip-class"} place={"right"} id={"zoomIn"} effect={"solid"} />

                <IconButton style={styles.zoomOutButton} onClick={this.zoomOut} disableRipple data-tip={"Zoom Out"} data-for={"zoomOut"} >
                    <ZoomOutIcon />
                </IconButton>
                <ReactToolTip className={"tooltip-class"} place={"right"} id={"zoomOut"} effect={"solid"} />

                <IconButton onClick={this.centerMap} style={{backgroundColor: 'transparent', color: '#000'}} disableRipple data-tip={"Center Map"} data-for={"centerMap"} >
                    <CenterIcon />
                </IconButton>
                <ReactToolTip className={"tooltip-class"} place={"right"} id={"centerMap"} effect={"solid"} />

                <ZoneToggleButton style={styles.standardButton} onClick={this.toggleZoneVisibility} mode={zoneVisibility} data-tip={"Show/Hide Zones"} data-for={"zones"}/>
                <ReactToolTip className={"tooltip-class"} place={"right"} id={"zones"} effect={"solid"} />

                <IconButton style={showTagLabels ? styles.enabledButton : styles.standardButton} disableRipple onClick={this.toggleShowTagLabels}
                    data-tip={"Show/Hide Tag Labels"} data-for={"showTagLabel"}
                >
                    <TagLabelIcon />
                </IconButton>
                <ReactToolTip className={"tooltip-class"} place={"right"} id={"showTagLabel"} effect={"solid"} />

                <div style={styles.spacer}></div>

                {/* <IconButton style={styles.standardButton} disableRipple onClick={this.toggleTagInfo} iconStyle={{fill:(showTagInfo?'inherit':DISABLED_COLOR)}}>
                    <InfoIcon />
                </IconButton> */}

                <IconButton style={showMapDrawer? styles.enabledButton : styles.standardButton} onClick={this.toggleMapDrawer} disableRipple
                    data-tip={"Show/Hide Map Thumbnails"} data-for={"showMapThumbnails"}
                >
                    <MapsIcon />
                </IconButton>
                <ReactToolTip className={"tooltip-class"} place={"right"} id={"showMapThumbnails"} effect={"solid"} />

                <div style={styles.spacer} />


                <IconButton style={goPlaybackEnabled ? styles.enabledButton : styles.standardButton} onClick={() => this.toggleToPlayback(goPlaybackEnabled, selected, currentView, siteName, siteId)}
                            disableTouchRipple data-tip={goPlaybackMessage} data-for={"playback"}
                >
                    <PlayIcon />
                </IconButton>
                <ReactToolTip className={"tooltip-class"} place={"right"} id={"playback"} effect={"solid"} />

                {/* Waiting for approvement */}
                {/* <IconButton style={addItemsPlaybackEnabled ? styles.enabledButton : styles.standardButton} onClick={() => this.handleAddItemsPlayback(addItemsPlaybackEnabled, selected, currentView, siteName, siteId)}
                            disableTouchRipple data-tip={addItemsPlaybackMessage} data-for={"playback"}
                >
                    <AddIcon />
                </IconButton>
                <ReactToolTip className={"tooltip-class"} place={"right"} id={"playbackAdd"} effect={"solid"} />


                <IconButton style={styles.enabledButton} onClick={() => this.setState({showTagList:!this.state.showTagList})}
                            disableTouchRipple data-tip={showListPlaybackMessage} data-for={"playback"}
                >
                    <ListIcon />
                </IconButton>
                <ReactToolTip className={"tooltip-class"} place={"right"} id={"playbackList"} effect={"solid"} /> */}

                <div style={styles.spacer}></div>
                <IconButton style={realTime && realTime[reportId] ? styles.enabledButton : styles.standardButton} onClick={this.toggleRealTime}
                            disableRipple data-tip={realTimeIsDisabled?"Real Time is Disabled in Current Report":"Toggle Real Time"} data-for={"realTime"}
                >
                    { realTime && realTime[reportId] ? (connectedRealTime && connectedRealTime[reportId] ? <DeviceGpsFixed /> : <DeviceGpsOff />) : <DeviceGpsNotFixed /> }
                </IconButton>
                <ReactToolTip className={"tooltip-class"} place={"right"} id={"realTime"} effect={"solid"} />
            </Paper>
        );
    }


    zoomIn(){
        let map = this.getMap();
        zoomMapByDeltaWithDuration(map,ZOOM_INCREMENT,250);
    }

    zoomOut(){
        let map = this.getMap();
        zoomMapByDeltaWithDuration(map,-ZOOM_INCREMENT,250);
    }

    centerMap(){
        let map = this.getMap();
        map.getView().setCenter(getCenter(window._origninalBound));
        map.getView().setZoom(1);
    }

    toggleZoneVisibility(){

        const {NO_ZONES,ZONES_ONLY,ZONES_AND_LABELS} = ZoneVisibility;

        let vis = NO_ZONES;
        switch(this.state.zoneVisibility){
            case NO_ZONES:
                vis = ZONES_ONLY;
                break;
            case ZONES_ONLY:
                vis = ZONES_AND_LABELS;
                break;
            case ZONES_AND_LABELS:
            default:
                vis = NO_ZONES;
                break;
        }

        this.setState({
            zoneVisibility:vis
        });
    }

    toggleShowTagLabels(){
        this.setState({
            showTagLabels: !this.state.showTagLabels
        });
    }

    toggleMapDrawer(){
        this.props.toggleMapDrawer(!this.state.showMapDrawer)
    }

    toggleRealTime() {
        const { realTime, reportId, realTimeIsDisabled } = this.props;
        if(realTimeIsDisabled) return;
        else {
            if(realTime && realTime[reportId]) {
                this.props.actions.disconnectWS();
                this.handleChangeStatusWS();
            } else {
                this.props.actions.connectWS();
            }
        }
    }

    handleChangeStatusWS(){
        this.timeout = window.setTimeout(this.props.changeStatusWS, 5000);
    }

    toggleTagInfo(){
        this.setState({
            showTagInfo: !this.state.showTagInfo
        });
    }

    toggleToPlayback(showPlayback, tags, currentView, getSiteName, getSiteId){

        if(!showPlayback) return;
        let currentMap = this.props.maps.filter(m => m._id === this.props.currentPlaybackMapId);
        // let tagsForPlayback = tags.map(t => t.raw);
        this.props.actions.loadPlaybackView(currentMap[0]);
        this.props.actions.saveCurrentView(currentView);
        this.props.changeView(views.PLAYBACK_VIEW, this.props.reportId);
        this.props.changeSiteName(getSiteId, getSiteName);
        this.props.actions.updateItemsPlaybackView(tags);
    }

    /* handleAddItemsPlayback(showPlayback, tags, currentView, getSiteName, getSiteId){
        if(!showPlayback) return;
        this.props.actions.addItemsPlaybackView(tags);
        let tagsForPlayback = tags.map(t => t.raw);
        this.props.actions.loadPlaybackView(currentMap[0]);
        this.props.actions.saveCurrentView(currentView);
        this.props.changeView(views.PLAYBACK_VIEW, this.props.reportId);
        this.props.changeSiteName(getSiteId, getSiteName);
    } */

    handleRemoveItemsPlayback(value){
        this.props.removeItemsPlaybackView([value]);
    }

    /**
     * Check if the currentMapId is in the list of maps of site.
     * @param currentMapId Current map selected.
     * @param mapsForSite Filtered list with maps for current site.
     */
    validateCurrentMapId(currentMapId,mapsForSite){

        let mapId=null;
        if(currentMapId&&mapsForSite&&mapsForSite.length>0){

            // Find the currentMapId in the list of maps of site.
            let mapArray=mapsForSite.filter(site=>site._id===currentMapId);

            // If the currentMapId is not in the list of maps of site, select the first map of site.
            if(mapArray.length===0)
                mapId=mapsForSite[0]._id;
            // The currentMapId is in the list of maps of site.
            else
                mapId=currentMapId;

        }
        return mapId;

    }
}

LocalMapView.styles = {
    zoomInButton:{
        display:'block',
        height:'32px',
        padding:'8px 12px 0px',
        backgroundColor: 'transparent',
        color: '#000'
    },
    zoomOutButton:{
        display:'block',
        height:'32px',
        padding:'0px 12px 8px',
        backgroundColor: 'transparent',
        color: '#000'
    },
    standardButton:{
        display:'block',
        height:'35px',
        padding:'10px 12px',
        backgroundColor: 'transparent',
        color: '#aaa'
    },
    enabledButton: {
        display:'block',
        height:'35px',
        padding:'10px 12px',
        backgroundColor: 'transparent',
        color: '#000'
    },
    spacer:{
        height:'1em',
        width:'48px'
    },
};

// eslint-disable-next-line
const mapStateToProps = state =>({
    mapConfiguration:state.reportMap.mapConfiguration,
    currentLocalMap:state.reportMap.currentLocalMap,
    currentPlaybackMapId: state.reportMap.currentPlaybackMapId,
    selected:state.report.selected,
    idAttribute: state.reportMap.mapFormat.idAttribute,
    clusterIndex: state.reportMap.clusterInfo.clusterIndex,
    showRightSidebar:state.report.showRightSidebar,
    highlightedItemMap:state.report.highlightedItemMap,
    clickedItem: state.report.clickedItem,
    currentPlaybackSiteName: state.reportMap.currentPlaybackInfo.playbackSiteName,
    realTimeIsDisabled: state.report.realTimeIsDisabled,
    trackedBlink: state.report.trackedBlink,
    reportId:state.report.reportId,
    playback: state.reportMap.playback[state.report.reportId],
    showMapDrawer: state.report.showMapDrawer
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        requestMapBounds: actions.requestMapBounds,
        getAdjustedBounds: actions.getAdjustedBounds,
        changeLocalMap:actions.changeLocalMap,
        changeView:actions.changeView,
        setPlaybackMapId:actions.setPlaybackMapId,
        setPlaybackInfo:actions.setPlaybackInfo,
        selectManyRows:actions.selectManyRows,
        selectCluster:actions.selectCluster,
        updateClusterIndex:actions.updateClusterIndex,
        openRightSidebar:actions.openRightSidebar,
        highlightItemMap:actions.highlightItemMap,
        changeSiteName:actions.changeSiteName,
        displayErrorDialog:actions.displayErrorDialog,
        //onItemSelected:actions.onItemSelected,
        changeStatusWS:actions.changeStatusWS,
        updateItemsPlaybackView:actions.updateItemsPlaybackView,
        removeItemsPlaybackView:actions.removeItemsPlaybackView,
        toggleMapDrawer: actions.toggleMapDrawer,
    },dispatch)
});

export default connect(mapStateToProps,mapDispatchToProps)(LocalMapView);
