import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Sync from '@material-ui/icons/Sync'
import {OPE_STATUS_FAILURE, OPE_STATUS_PENDING} from '../../../../constants/DeviceManager'
import {loadDeviceList, loadFirmwareHistory, upgradeFirmware} from "../../../../actions";
const useStyles = makeStyles({
    paperDialog: {
        overflowY: "visible"
    }
});

export const checkPendingDevice = (operation_status) => {
    // TODO:
    if(!operation_status){
        return true;
    }else if(Object.keys(operation_status).length === 0){
        return true;
    }else{
        return operation_status.current_status !== OPE_STATUS_PENDING;
    }
}

const DialogUpdateFirmware = props => {
    const {open, closeDialog, rowIndex} = props;
    const [tableValues, setTableValues] = useState(null);
    const [versions, setVersions] = useState(null);
    const classes = useStyles();
    const dispatch = useDispatch();
    // getting the device or the selected devices
    const selectedDevices = useSelector(state => typeof rowIndex === 'number' ? [state.deviceManager.deviceList[rowIndex]] :
        Object.keys(state.deviceManager.selectedRows).map(key => state.deviceManager.selectedRows[key]));
    
    const initValues = () => {
        let objectDevices = {};
        let objectVersions = {};
        selectedDevices.forEach(device => {
            const keyVersion = `${device.deviceType}-${device.rfidType}`;
            //checking if deviceType exists in the object
            if(!objectDevices.hasOwnProperty(device.deviceType)){
                objectDevices[device.deviceType] = {};
            }
            //checking if rfidType exists in the object (inside deviceType)
            if(!objectDevices[device.deviceType].hasOwnProperty(device.rfidType)){
                objectDevices[device.deviceType][device.rfidType] = [];
                objectVersions[keyVersion] = {version: '', ids: [], updating: false, enable: true};
            }

            //enabling button for "Select Version"

                objectVersions[keyVersion].enable = checkPendingDevice(device.operation_status);

            // objectVersions[keyVersion].enable = true;
            let objectDevice = {id: device._id, title: device.title, firmwareVersion: device.firmwareVersion? device.firmwareVersion: '', 
                status: device.status, operation_status: device.operation_status};
            objectDevices[device.deviceType][device.rfidType].push(objectDevice);
            objectVersions[keyVersion].ids.push(device._id);
        });
        setTableValues(objectDevices);
        setVersions(objectVersions);
    }

    useEffect(initValues, []);

    const renderCloseButton = () => {
        return (
            <div style={{position:'absolute',top:'-0.5em',right:'-0.5em'}}>
                <IconButton aria-label="close"
                    size="small"
                    onClick={closeDialog}
                    style={{cursor:'pointer',position:'relative',zIndex:'100',backgroundColor:'#000'}}
                    >
                    <CloseIcon style={{color: '#fff'}} />
                </IconButton>
            </div>
        );
    }

    const upgradeFirmwareAction = (key) =>{
        dispatch(upgradeFirmware(versions[key]));
        dispatch(loadDeviceList());
        let updatedVersions = {...versions};
        updatedVersions[key].updating = true;
        updatedVersions[key].enable = false;
        setVersions(updatedVersions);
    }

    const onChangeInput = e => {
        let updateVersions = {...versions};
        updateVersions[e.target.name].version = e.target.value;
        setVersions(updateVersions);
    }

    const renderBody = () => {
        const styleButton = {backgroundColor: '#007cb0', color: '#fff', textTransform: 'none'};
        let positionDevice = 0;
        return (
            <tbody>
                {Object.keys(tableValues).map((type, i) => {
                    let sumType = Object.keys(tableValues[type]).reduce((acc, model) => acc + tableValues[type][model].length, 0);
                    return(
                        Object.keys(tableValues[type]).map((model, j) => {
                            let nameInput = `${type}-${model}`;
                            let updating = !versions[`${type}-${model}`].enable;

                            let emptyVersion=false;
                            if(versions[`${type}-${model}`].version===''||versions[`${type}-${model}`].version==null){
                                emptyVersion=true;
                            }

                            let sumModel = tableValues[type][model].length;

                            return (
                                tableValues[type][model].map((item, index) => {
                                    positionDevice ++;
                                    let styleCell = {backgroundColor: positionDevice%2 === 0?'#d8d8d8':'#fff',textAlign:'center'};
                                    return (
                                        <tr key={index} >
                                            {(j===0 && index===0) && <td rowSpan={sumType} style={{textAlign:'center'}} >{type}</td>}
                                            {(index===0) && <td rowSpan={sumModel} style={{textAlign:'center'}} >{model}</td>}
                                            <td style={styleCell}>{item.title}</td>
                                            <td style={styleCell}>{item.firmwareVersion}</td>
                                            {(index === 0) && <td style={{textAlign:'center'}} rowSpan={sumModel}>
                                                <SelectVersion nameInput={nameInput} valueInput={versions[nameInput].version} 
                                                deviceId={tableValues[type][model][0].id} onChangeInput={onChangeInput} 
                                                status={tableValues[type][model][0].status} 
                                                operation_status={tableValues[type][model][0].operation_status}
                                                disabled={updating}
                                                />
                                            </td>}
                                            {(index === 0) && <td rowSpan={sumModel}>
                                                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                                    <Button component="span" style={{...styleButton, opacity: updating||emptyVersion? 0.5: 1}}
                                                        onClick={e => upgradeFirmwareAction(`${type}-${model}`)} 
                                                        disabled={updating||emptyVersion}>{updating?"Upgrading...":"Upgrade"}</Button>
                                                    {updating&&
                                                    <span style={{fontSize:'11px', lineHeight:'1.5'}}>Check landing page for status</span>}
                                                </div>
                                            </td>}
                                        </tr>
                                    )
                                })
                            )
                        })
                    )
                })}
            </tbody>
        )
    }

    const renderTable = () => {
        const styleHeader = {backgroundColor: '#333d47', color: '#fff', textAlign:'center'}        
        return (
            <table className={"summary-table"} border={2}>
                <thead>
                    <tr>
                        <th style={styleHeader}>Device Type</th>
                        <th style={styleHeader}>Device Model</th>
                        <th style={styleHeader}>Device Name</th>
                        <th style={styleHeader}>Current Version</th>
                        <th style={styleHeader}>Version Available</th>
                        <th style={styleHeader}>Actions</th>
                    </tr>
                </thead>
                {tableValues && renderBody()}
            </table>
        )
    }

    return (
        <Dialog
            fullWidth
            maxWidth={'lg'}
            open={open}
            onClose={closeDialog}
            classes={{paper:classes.paperDialog}}
            className='dialog-upgrade-firmware'
        >
            <DialogTitle style={{paddingBottom:0}}>
                Firmware Upgrade
            </DialogTitle>
            {renderCloseButton()}
            <DialogContent style={{display:'flex',flexDirection:'column',maxHeight:'100%'}}>
                {renderTable()}
            </DialogContent> 
            <DialogActions>
                <Button component="span" style={{textTransform: 'none'}} onClick={closeDialog}>
                    Close
                </Button>
            </DialogActions>               
        </Dialog>
    );
}

export default DialogUpdateFirmware;

const SelectVersion = props => {
    const dispatch = useDispatch();
    const {nameInput, valueInput, deviceId, onChangeInput, status, operation_status, disabled} = props;
    // Get the firmware versions form redux store.
    const firmwareVersions = useSelector(state=>state.deviceManager.firmwareList[deviceId] || []);
    const errorObj = useSelector(state => state.deviceManager.errorObj);
    const OPERATION_UPDATE = "update_firmware";
    const requestFirmwareList=()=>{
        dispatch(loadFirmwareHistory(deviceId));
    }
    // First render.
    useEffect(requestFirmwareList,[]);

    const handleLoadFirmwareHistory = () => {
        dispatch(loadFirmwareHistory(deviceId));
    };

    const circularProgress = <CircularProgress style={{ marginTop:-12, color: "#00779f"}} thickness={2} size={16}/>;
    return (
        <> {(!errorObj.error)?
            (firmwareVersions.length === 0) ? circularProgress :
                // <select name={nameInput} value={valueInput} onChange={onChangeInput}>
                //     {firmwareVersions.map((v, i) => <option key={i} value={v}>{v}</option>)}
                // </select>
                <>
                    <input list="firmwareVersions" name={nameInput} id="firmwareVersion" autocomplete={'off'} disabled={disabled}
                        placeholder="Select or type a firmware version" value={valueInput} onChange={onChangeInput} style={{minWidth:'230px'}}/>
                    <datalist id="firmwareVersions">
                        {firmwareVersions.map((v, i) => <option key={i} value={v}>{v}</option>)}
                    </datalist>
                </>
            :
            <div className={"firmware-error-container"}>
                <div className={"info-container"}>{errorObj.message}</div>
                
                <div className={"retry-container"}>
                    <Button onClick={handleLoadFirmwareHistory} style={{width:'24px', minWidth:"24px"}}><Sync /></Button>
                    <div onClick={handleLoadFirmwareHistory} className={"retry-content"}>Refresh</div>
                </div>
            </div>
        }

        {operation_status && operation_status.operation && operation_status.operation === OPERATION_UPDATE  && status.title === OPE_STATUS_FAILURE && 
            <div className={"firmware-error-container"}>
            {/*<div className={"info-container"}>{status.status}</div>*/}
        </div>}    
        </>
    );
}