import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {cleanDialogNotification} from '../../../../actions';
import LeakAddRoundedIcon from '@material-ui/icons/LeakAddRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CancelIcon from '@material-ui/icons/Cancel';
import {ALERT_SUCCESS, ALERT_RECEIVER, ALERT_ERROR} from '../../../../constants/DeviceManager';

const OperationNotification = (props) => {
    const {duration} = props;
    const dialogNotification = useSelector(state => state.deviceManager.dialogNotification);
    const getImage = type => {
        switch(type){
            case ALERT_SUCCESS:
                return <CheckCircleRoundedIcon style={{color:'green', width: '40px', height: '40px'}}/>;
            case ALERT_ERROR:
                return <CancelIcon style={{color:'red', width: '40px', height: '40px'}}/>;
            case ALERT_RECEIVER:
            default:
                return <LeakAddRoundedIcon style={{color:'black', width: '40px', height: '40px'}} />;
        }
    }

    useEffect( () => {
        setTimeout(closeNotification, duration);
    });

    const dispatch = useDispatch();
    const closeNotification = () => {
        dispatch(cleanDialogNotification());
    }

    return (<React.Fragment>
        {dialogNotification && 
        <div id='operationNotification' style={{padding:'1em 16px',background:'#f5ffcf',border:'#e4ed15 1.5px solid',marginBottom: '14px'}}>
            <div style={{display: 'flex', alignItems:'center'}}>
                {getImage(dialogNotification.typeNotification)}
                <div><b>{`${dialogNotification.title}`}</b></div>
            </div>
            <div><span>{`${dialogNotification.message}`}</span></div>
        </div>}
    </React.Fragment>
    )
}

export default OperationNotification;

