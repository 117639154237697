import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import PhoneLinkRing from '@material-ui/icons/PhonelinkRing';
import PhoneLinkErase from '@material-ui/icons/PhonelinkErase';
import BatteryFull from '@material-ui/icons/BatteryFull';
import BatteryAlert from '@material-ui/icons/BatteryAlert';
import {TAG_BLINK_HEALTH} from './util/DashboardConstants';
import {getReportWidgetData} from '../../../actions';
import IconButton from '@material-ui/core/IconButton';
import LookupIcon from '@material-ui/icons/FindInPage';
import {Link} from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";


const TagHealth = () => {
    const tags = useSelector(state => state.dashboards.tagData);
    const dispatch = useDispatch();

    const handleLoadData = () => {
        dispatch(getReportWidgetData(TAG_BLINK_HEALTH, {}));
    };

    useEffect(handleLoadData, []);

    const handleTHeader = () => {
        return tags.map((t, index) => {
            return (<th key={index} className={"header-column"}>{t.technology}</th>)
        })
    };

    const handleBlinking = () => {
        return tags.map((t, index) => {
            return (<td key={index} className={"column-body"}>{t.blinking}</td>)
        })
    };

    const handleNotBlinking = () => {
        return tags.map((t, index) => {
            return (<td key={index} className={"column-body"}>{t.notblinking}</td>)
        })
    };

    const handleLowBattery = () => {
        return tags.map((t, index) => {
            return (<td key={index} className={"column-body"}>{t.lowbattery}</td>)
        })
    };

    const handleGoodBattery = () => {
        return tags.map((t, index) => {
            return (<td key={index} className={"column-body"}>{t.goodbattery}</td>)
        })
    };

    const handleTable = () => {
        return (
            <table className={"tag-health-table"}>
                <thead className={"tag-health-table-header"}>
                    <tr>
                        <th>&nbsp;</th>
                        {handleTHeader()}
                    </tr>
                </thead>
                <tbody className={"tag-health-table-body"}>
                    <tr className={"row-body-transparent"}>
                        <td className={"main-column-body"}>
                            <Tooltip title={"Blink"} arrow={true} placement={"right"}>
                                <PhoneLinkRing className={"blinking-class"}/>
                            </Tooltip>
                        </td>
                        {handleBlinking()}
                    </tr>
                    <tr className={"row-body"}>
                        <td className={"main-column-body"}>
                            <Tooltip title={"Not blink"} arrow={true} placement={"right"}>
                                <PhoneLinkErase className={"not-blinking-class"}/>
                            </Tooltip>
                        </td>
                        {handleNotBlinking()}
                    </tr>
                    <tr className={"row-body-transparent"}>
                        <td className={"main-column-body"}>
                            <Tooltip title={"Battery OK"} arrow={true} placement={"right"}>
                                <BatteryFull className={"full-battery-class"}/>
                            </Tooltip>
                        </td>
                        {handleGoodBattery()}
                    </tr>
                    <tr className={"row-body"}>
                        <td className={"main-column-body"}>
                            <Tooltip title={"Battery low"} arrow={true} placement={"right"}>
                                <BatteryAlert className={"low-battery-class"}/>
                            </Tooltip>
                        </td>
                        {handleLowBattery()}
                    </tr>
                </tbody>
            </table>
        )
    };

    return (
        <div className={"chart-tag-blinkbattery-class"}>
            <div className={"tag-health-main-container"}>
                <div className={"tag-health-container"}>
                    <div className={"tag-health-header"}>
                        <span>Tag Health: </span><span>All Sites</span>
                    </div>
                    <div className={"tag-health-body"}>
                        <div className={"tag-health-body-container"}>
                            <div className={"tag-health-body-content"}>
                                {handleTable()}
                            </div>
                        </div>
                    </div>
                    <Link to={TAG_BLINK_HEALTH.reportLink}>
                        <IconButton
                            className={"edit-button"}
                        >
                            <LookupIcon />
                        </IconButton>
                    </Link>
                </div>
            </div>
        </div>
    )
};

export default TagHealth;