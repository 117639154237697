import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import IconButton from '@material-ui/core/IconButton';

import ListItemIcon from "@material-ui/core/es/ListItemIcon/ListItemIcon";

import assign from 'object-assign';

import { DndProvider, useDrag, useDrop } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PinIcon from '../../icons/PinIcon';
import PinGroupIcon from '../../icons/PinGroupIcon';

import MoveIcon from '@material-ui/icons/Reorder';
// import GlobeIcon from 'material-ui/svg-icons/social/public';
import GlobeIcon from '@material-ui/icons/Public';
import MapIcon from '@material-ui/icons/Layers';

import {SITE_FORMS} from '../dataentry/SitesDataEntryDialog';
import CircularProgress from "@material-ui/core/CircularProgress";
import {withStyles} from '@material-ui/core/styles';

/*
 ██    ██ ████████ ██ ██
 ██    ██    ██    ██ ██
 ██    ██    ██    ██ ██
 ██    ██    ██    ██ ██
 ██████     ██    ██ ███████
 */

function traditionalSort(a,b){
    return ((a === b) ? 0 : (a > b) ? 1 : -1);
}

/*
 ██  ██████  ██████  ███    ██ ███████
 ██ ██      ██    ██ ████   ██ ██
 ██ ██      ██    ██ ██ ██  ██ ███████
 ██ ██      ██    ██ ██  ██ ██      ██
 ██  ██████  ██████  ██   ████ ███████
 */

function makePinIcon(WrappedComponent){
    return class extends React.Component{
        render(){
            const {style, ...other} = this.props; // eslint-disable-line
            let styles = null;
            if(style) {
                styles = assign(style,{
                    left:'20px',
                    top:'2px',
                    margin:0,
                    position:'absolute'
                });
            }

            return (
              <WrappedComponent
                style={styles}
                className="icon-pin"
                {...other}
              />
            );
        }
    };
}

// const DefaultPinIcon = makePinIcon(PinIcon);
// const DefaultPinGroupIcon = makePinIcon(PinGroupIcon);
const DefaultGlobeIcon = makePinIcon(GlobeIcon);
const DefaultMapIcon = makePinIcon(MapIcon);
const itemType = "site";


/*
 ███████ ██ ████████ ███████     ██ ████████ ███████ ███    ███
 ██      ██    ██    ██          ██    ██    ██      ████  ████
 ███████ ██    ██    █████       ██    ██    █████   ██ ████ ██
 ██ ██    ██    ██          ██    ██    ██      ██  ██  ██
 ███████ ██    ██    ███████     ██    ██    ███████ ██      ██
 */

const MovableSiteListItem = ({onMoveSite, ...props}) => {
    const item = { name: props.primaryText, type: itemType, siteId: props.id };
    const [, drag] = useDrag({
        item,
        end(item, monitor) {
            const dropResult = monitor.getDropResult();
            if (item && dropResult) {
                onMoveSite(item.siteId, dropResult.id);
            }
        },
        collect: monitor => ({
            opacity: monitor.isDragging() ? 0.4 : 1,
        })
    });

    const handleMouseEnter = () => {
        props.onHoverSite(props.id,true);
    }

    const handleMouseLeave = () => {
        props.onHoverSite(props.id,false);
    }

    const {
        className,
        connectDragSource,
        connectDragPreview,
        isDragging,
        dragIndex,         // eslint-disable-line no-unused-vars
        groupId,    // eslint-disable-line no-unused-vars
        actions,    // eslint-disable-line no-unused-vars
        onHoverSite, // eslint-disable-line no-unused-vars
        isHovered,
        id, //eslint-disable-line no-unused-vars
        onEdit,
        onDelete,
        nestedItems,
        primaryText,
        canEdit,
        upload_status,
        ...other
    } = props;

    const _styles = {
        opacity: (isDragging) ? 0 : 1
    };

    const ICON_COLOR = "inherit";

    // const cirProgress = ( <IconButton tooltip={ upload_status.message } tooltipPosition="bottom-left">
    //     <CircularProgress style={{ marginTop:-12 }} color={"#00779f"} thickness={2} size={12}/>
    // </IconButton>);

    const rightIcons = canEdit ? (
      <div style={{position:'absolute',top:0,right:'32px'}}>
          <IconButton className="action-button" style={{position: 'absolute', bottom: 8, right: 48, backgroundColor:'transparent'}} onClick={onEdit}>
              <EditIcon color={ICON_COLOR}/>
          </IconButton>
          <IconButton className="action-button" style={{position: 'absolute', bottom: 8, right: 26, backgroundColor:'transparent'}} onClick={onDelete}>
              <DeleteIcon color={ICON_COLOR}/>
          </IconButton>
          <div className={"drag-handle"} style={{display:'inline-block'}}><MoveIcon color={ICON_COLOR}/></div>
      </div>
    ) : null;

    return (
      <div style={_styles} ref={drag} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className={(isHovered ? "hovered" : "")}>
          <ListItem
            {...other}
            className={className+" pin-item"}
            style={{padding:'0px',paddingLeft: 18}}
          >
              <ListItemIcon>
                <PinIcon color="#269bc5" borderColor="#2084a8"/>
              </ListItemIcon>
              {primaryText}
             
              {upload_status.progress && id === upload_status.siteId ? null : 
                <ListItemIcon>{rightIcons}</ListItemIcon>}
          </ListItem>
          {nestedItems &&
          <Collapse in={true} timeout={'auto'} unmountOnExit>
              {nestedItems}
          </Collapse> 
          }
      </div>
    );
}

MovableSiteListItem.propTypes = {
    className: PropTypes.string,
    connectDragSource: PropTypes.func,
    connectDragPreview: PropTypes.func,
    isDragging: PropTypes.bool,
    dragIndex: PropTypes.string.isRequired,
    groupId: PropTypes.string.isRequired,
    actions: PropTypes.object.isRequired,
    upload_status: PropTypes.object,
    onHoverSite: PropTypes.func,
    id: PropTypes.any,
    isHovered: PropTypes.bool,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    primaryText: PropTypes.string,
    canEdit: PropTypes.bool
}

MovableSiteListItem.defaultProps = {
    onHoverSite: function(){}
}

/*
 ███████ ██ ████████ ███████      ██████  ██████   ██████  ██    ██ ██████      ██ ████████ ███████ ███    ███
 ██      ██    ██    ██          ██       ██   ██ ██    ██ ██    ██ ██   ██     ██    ██    ██      ████  ████
 ███████ ██    ██    █████       ██   ███ ██████  ██    ██ ██    ██ ██████      ██    ██    █████   ██ ████ ██
      ██ ██    ██    ██          ██    ██ ██   ██ ██    ██ ██    ██ ██          ██    ██    ██      ██  ██  ██
 ███████ ██    ██    ███████      ██████  ██   ██  ██████   ██████  ██          ██    ██    ███████ ██      ██
 */

const DroppableSiteGroupListItem = props => {
    const [{ isOver }, drop] = useDrop({
        accept: itemType,
        drop: () => ({
            name: props.primaryText,
            id: props.id
        }),
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    const {
        className,
        connectDropTarget,
        onMoveSite, // eslint-disable-line no-unused-vars
        id, //eslint-disable-line no-unused-vars
        onEdit,
        onDelete,
        onHideNShow,
        isOpen,
        nestedItems,
        disableActions,
        primaryText,
        canEdit,
        ...other
    } = props;

    const _styles = {
        background: (isOver) ? "#B5DEFF" : "transparent"
    };

    const ICON_COLOR = "inherit";

    const rightIcons = canEdit ? (
        <div style={{position:'absolute',top:0,right:24}}>
            <IconButton className="action-button" onClick={onEdit} style={{backgroundColor:'transparent'}}>
                <EditIcon color={ICON_COLOR}/>
            </IconButton>
            <IconButton className="action-button" onClick={onDelete} style={{backgroundColor:'transparent'}}>
                <DeleteIcon color={ICON_COLOR}/>
            </IconButton>
            {nestedItems.length ?
            <IconButton style={{position:'absolute',top: 2, left: 90, color: '#2C3335', backgroundColor: 'transparent'}} onClick={onHideNShow}>
                {!isOpen ? <ExpandLess /> : <ExpandMore />}
            </IconButton> : null
            }
        </div>
    ) : null;

    const _actions = (disableActions) ? (
        <>
            {nestedItems.length ?
            <IconButton className="action-button" style={{position:'absolute',top: 4, right: 6, color: '#2C3335', backgroundColor: 'transparent', opacity: 1}} onClick={onHideNShow}>
                {!isOpen ? <ExpandLess /> : <ExpandMore />}
            </IconButton> 
            : null
            }
        </>
    ) : rightIcons;

    return (
        <div style={_styles} ref={drop}>
            <ListItem
            {...other}
            
            className={className+" pin-item"}
            // disableTouchRipple
            style={{padding:'0px'}}
            >
                {_actions}
                <ListItemIcon><PinGroupIcon
                    color="#459845"
                    borderColor="#318431"/></ListItemIcon>
                {primaryText}
            </ListItem>
            {nestedItems &&
            <Collapse in={!isOpen} timeout={'auto'} unmountOnExit>
                {nestedItems}
            </Collapse> 
            }
        </div>
    );
}

DroppableSiteGroupListItem.propTypes = {
    className: PropTypes.string,
    connectDropTarget: PropTypes.func,
    onMoveSite: PropTypes.func.isRequired,
    isOver: PropTypes.bool,
    id: PropTypes.string.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    disableActions: PropTypes.bool,
    primaryText: PropTypes.string,
    canEdit: PropTypes.bool
}


/*
 ██      ██ ███████ ████████
 ██      ██ ██         ██
 ██      ██ ███████    ██
 ██      ██      ██    ██
 ███████ ██ ███████    ██
 */

const ColoredCircularProgress = withStyles({
    root: {
        color: '#00779f'
    }
})(CircularProgress);

class SitesTreeList extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            hoverUID: "CA-01", 
            openAll: true,
            open: [false]
        };

        this.handleSiteMove = this.handleSiteMove.bind(this);
        this.handleHoverSite = this.handleHoverSite.bind(this);
        this.handleListClick = this.handleListClick.bind(this);
    }

    static get propTypes(){
        return {
            searchVal: PropTypes.string,
            actions: PropTypes.object,
            onHoverSite: PropTypes.func,
            hoverUID: PropTypes.any,
            sites: PropTypes.array.isRequired,
            siteGroups: PropTypes.array.isRequired,
            maps: PropTypes.array.isRequired,
            onSelectMap: PropTypes.func,
            canEdit: PropTypes.bool,
            open:PropTypes.bool,
            openAll: PropTypes.bool
        };
    }

    handleSiteMove(siteId, siteGroupId){
        this.props.actions.moveSiteToSiteGroup(siteId,siteGroupId);
        return;
    }

    handleHoverSite(site,hovered){
        this.setState({
            hoverUID:hovered && site
        });
    }

    handleRenderMap(mapId,siteId){
        if (mapId === this.props.upload_status.mapId) {
            return;
        }

        this.props.onSelectMap(mapId);
        this.setState({
            selectedUID:mapId +siteId
        });
    }

    renderMapsInSite(maps){

        const selectedItemUID = this.state.selectedUID;
        const upload_status = this.props.upload_status;
        const _maps = maps.map(m => {

            // Find if is the current selected item.
            let selectedItemClass = "";
            if (selectedItemUID === (m._id + m.siteId)) {
                selectedItemClass = " tree-selected-item";
            }

            const cirProgress = ( 
                <Tooltip title={upload_status.message} placement="bottom-end" >
                    <IconButton style={{backgroundColor: 'transparent'}}>
                        <ColoredCircularProgress style={{ marginTop:0}} thickness={5} size={16}/>
                    </IconButton>
                </Tooltip>
            );

            const isMapUploading = upload_status.progress && m.siteId === upload_status.siteId && upload_status.mapId === m._id;

            return <ListItem
                className={"list-item pin-item" + selectedItemClass}
                // disableTouchRipple
                style={{padding: 0, paddingLeft: 36}}
                key={m._id}
                onClick={this.handleRenderMap.bind(this, m._id, m.siteId)}
            >
                <ListItemIcon><DefaultMapIcon/></ListItemIcon>
                <ListItemText className={'list-text-item'}>{m.description}</ListItemText>
                {isMapUploading && <ListItemIcon className={'list-icon-item'}>{cirProgress}</ListItemIcon>}
            </ListItem>

        });

        return _maps;
    }

    renderSitesInSiteGroup(id,sites){
        const huid = (this.props.onHoverSite) ? this.props.hoverUID : this.state.hoverUID;
        const {actions,canEdit, upload_status, siteManagerSiteSelected } = this.props;
        return sites.sort((a,b)=>traditionalSort(a.name,b.name)).map((v,i)=>(
          <MovableSiteListItem
            primaryText={v.name}
            upload_status={upload_status}
            className={v.id===siteManagerSiteSelected?"list-item-selected":"list-item"}
            key={v.id}
            index={i}
            onMoveSite={this.handleSiteMove}
            dragIndex={v.groupId || "null"}
            groupId={v.groupId || "null"}
            onHoverSite={this.props.onHoverSite || this.handleHoverSite}
            nestedItems={this.renderMapsInSite(this.props.maps.filter(m=>m.siteId === v.id))}
            isHovered={v.id === huid}
            id={v.id}
            actions={actions}
            onEdit={()=>{
                actions.openSiteForm(SITE_FORMS.UPDATE_SITE,{
                    ...v
                });
            }}
            onDelete={()=>{
                actions.openSiteForm(SITE_FORMS.DELETE_SITE,{
                    ...v
                });
            }}
            onClick={
                ()=>this.props.onSelectMap(null)
            }
            canEdit={canEdit}
          />
        ));
    }

    handleListClick(index) {
        if(index || index === 0) {
            const toggleState = [...this.state.open];
            toggleState[index] = toggleState[index] !== null ? !toggleState[index] : false;
            this.setState({open: toggleState});
        } else {
            const toggleState = this.state.openAll;
            this.setState({openAll: !toggleState});
        }
    }

    renderSiteGroups(){
        const {siteGroups,searchVal,actions,canEdit} = this.props;
        const { open } = this.state;
        // eslint-disable-next-line
        return siteGroups.map((v,i)=>{

            // only list sites in the site group
            let sites = this.props.sites.filter(s=>(s.groupId === v.id));
            
            // if the search value exists but doesn't match the site group
            // only show the sites that match.  If none, don't show the site group.
            // (if it matches the site group, we'd want to show all sites in that group)...
            if(searchVal.length > 0 && !v.name.toLowerCase().match(searchVal.toLowerCase())){
                // eslint-disable-next-line
                sites = sites.filter((v)=>(
                  !!v.name.toLowerCase().match(searchVal.toLowerCase())
                ))
                // eslint-disable-next-line
                if(sites.length === 0) return;
            }
            return (
              <DroppableSiteGroupListItem
                key={v.id}
                className="list-item"
                primaryText={v.name}
                nestedItems={
                    this.renderSitesInSiteGroup(v.id,sites)
                }
                onMoveSite={this.handleSiteMove}
                id={v.id || "null"}
                onEdit={()=>{
                    actions.openSiteForm(SITE_FORMS.UPDATE_GROUP,{
                        ...v
                    });
                }}
                onDelete={()=>{
                    actions.openSiteForm(SITE_FORMS.DELETE_GROUP,{
                        ...v
                    });
                }}
                onHideNShow={() => this.handleListClick(i)}
                isOpen={open[i]}
                disableActions={(v.id === null)}

                onClick={
                    ()=>this.props.onSelectMap(null)
                }
                canEdit={canEdit}
              />
            );
        });
    }

    render(){
        const { openAll } = this.state;
        return (
          <List className="tree-list">
              <ListItem button onClick={() => this.handleListClick(false)}>
                  <ListItemIcon>
                      <DefaultGlobeIcon style={{color:'inherit'}}/>
                  </ListItemIcon>
                  <ListItemText primary="All" />
                  <div className='hideAndShow'>
                    {openAll ? <ExpandLess /> : <ExpandMore />}
                  </div>
              </ListItem>
              <Collapse in={openAll} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                      <DndProvider backend={Backend}>
                            {this.renderSiteGroups()}
                      </DndProvider>
                  </List>
              </Collapse>
          </List>
        );
    }
}

export default SitesTreeList;
