import React from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

const NavigateDetails = ({index, setIndex, totalItems, title, handleClose}) => {
    return (
        <div className="dd-main-container-title">
            <div className="dd-arrow-close" onClick={handleClose}>
                <DoubleArrowIcon style={{padding:'3px'}} />
            </div>
            <div className="dd-title-details">
                {title}
            </div>
            <div className="dd-counter-details">
                ({index+1} of {totalItems})
            </div>
            {totalItems > 1?
            <React.Fragment>
                <div className={index ===0 ?"dd-arrow-back-disabled":"dd-arrow-back"} onClick={()=>setIndex(prevIndex => prevIndex - 1)}>
                    <ArrowBackIosIcon />
                </div>
                <div className={index === (totalItems-1) ?"dd-arrow-back-disabled":"dd-arrow-back"} onClick={()=>setIndex(prevIndex => prevIndex + 1)}>
                    <ArrowForwardIosIcon />
                </div>
            </React.Fragment>: null}
        </div>
    );
}

export default NavigateDetails;