import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import { getReportZlaStatusData} from "../../../actions";
import { ZLA_STATUS} from "./util/DashboardConstants";
import IconButton from "@material-ui/core/IconButton";
import LookupIcon from "@material-ui/icons/FindInPage";
import {Link} from "react-router-dom";
const ZLAStatus = () => {
    const zlaStatus = useSelector(state => state.dashboards.zlaStatus);
    const dispatch = useDispatch();
    const name = "All Sites";
    const reportLink = "/appliances";
    const handleLoadData = () => {
        dispatch(getReportZlaStatusData(ZLA_STATUS, {}));
    };

    useEffect(handleLoadData, []);
    //console.log("zlaStatus: ",zlaStatus);
    const getBody = () =>{
        return (
            zlaStatus && zlaStatus.length === 6 &&
            (<>
                <div className={'flex-container'}>
                    <div className={'flex-item'}>
                        <strong>{zlaStatus[0].count} </strong><br/> {zlaStatus[0].status}
                    </div>
                    <div className={'flex-item'}>
                        <strong>{zlaStatus[1].count} </strong><br/> {zlaStatus[1].status}
                    </div>
                    <div className={'flex-item'}>
                        <strong>{zlaStatus[2].count} </strong><br/> {zlaStatus[2].status}
                    </div>
                </div>
                <div className={'flex-container'}>
                    <div style={{color: zlaStatus[3].color}} className={'flex-item'}>
                        <strong>{zlaStatus[3].count}  </strong><br/> {zlaStatus[3].status}
                    </div>
                    <div style={{color: zlaStatus[4].color}} className={'flex-item'}>
                        <strong>{zlaStatus[4].count} </strong><br/> {zlaStatus[4].status}
                    </div>
                    <div className={'flex-item'}>
                        <strong>{zlaStatus[5].count} </strong><br/> {zlaStatus[5].status}
                    </div>
                </div>
            </>)
        )
    }
    return (
        <div className={"chart-zlas-class"}>
            <div className={"flex-zla-container"}>
                <div className={"flex-zla-header"}>
                    <span>ZLA Status:  </span><span>{name}</span>
                </div>
                <div className={"flex-zla-body"}>
                    {getBody()}
                </div>
                <div className={'flex-container-link'}>
                    <Link to={reportLink}>
                        <IconButton className={"edit-button"}>
                            <LookupIcon />
                        </IconButton>
                    </Link>
                </div>
            </div>
        </div>
    )
};

export default ZLAStatus;