import React from 'react';
import PropTypes from 'prop-types';

import WorldMap from '../../../util/map/WorldMap';
import {zoomMapByDeltaWithDuration} from '../../../util/map/LocalMapView';

import SiteAlertLayer from './SiteAlertLayer';
import SiteCountLayer from './SiteCountLayer';

import Paper from "@material-ui/core/Paper";
import IconButton from '@material-ui/core/IconButton';

import ZoomInIcon from '@material-ui/icons/AddBox';
import ZoomOutIcon from '@material-ui/icons/IndeterminateCheckBox';

import {Overlay, OverlayGroup} from '../../../../../../common/app/views/map/ZMap';


import SiteInfoBox from './SiteInfoBox';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../../../../actions';

// this may be silly...
class ColorSquare extends React.Component{
    static get propTypes(){
        return {
            color: PropTypes.any
        };
    }
    render(){
        const {color} = this.props;
        return (
            <div
                style={{
                    height:'0.8em',
                    width:'0.8em',
                    display:'inline-block',
                    backgroundColor:color,
                    margin:'0 0.25em',
                    border:'solid 1px',
                    boxSizing: 'border-box'
                }}
                />
        );
    }
}

class ServerAlertsBox extends React.Component{
    static get propTypes(){
        return {
            label: PropTypes.string,
            counts:PropTypes.shape({
                high: PropTypes.number,
                medium: PropTypes.number,
                low: PropTypes.number
            })
        };
    }

    static get defaultProps(){
        return {
            counts:{
                high:0,
                medium:0,
                low:0
            }
        };
    }

    render(){
        const {label,counts} = this.props;
        return (
            <Paper style={{position:'absolute',top:0,right:0,padding:'0.25em 0.5em'}}>
                <span style={{color:'#00779f',fontSize:'0.9em',marginRight:'0.2em'}}>
                    {label}
                </span>
                <span style={{fontSize:'0.9em'}}>
                    {counts.high}
                    <ColorSquare color="red" />
                    {counts.medium}
                    <ColorSquare color="orange" />
                    {counts.low}
                    <ColorSquare color="yellow" />
                </span>
            </Paper>
        );
    }
}

class BaseMapWidget extends React.Component{
    static get propTypes(){
        return {
            sitesWithData: PropTypes.array,
            requestSites: PropTypes.func.isRequired,
            data: PropTypes.array,
            title: PropTypes.string,
            pinLayerRenderer: PropTypes.func,
            children: PropTypes.any,
            infoBoxAttributes:PropTypes.array
        };
    }

    constructor(){
        super();

        this.state={
            infoboxSite:null
        };

        this.zoomIn = this.zoomIn.bind(this);
        this.zoomOut = this.zoomOut.bind(this);
        this.handleSelectSite = this.handleSelectSite.bind(this);
    }

    componentDidMount(){
        this.props.requestSites();

        this.map =  window.MY_MAP;

    }

    zoomIn(){
        zoomMapByDeltaWithDuration(this.map,1,250);
    }

    zoomOut(){
        zoomMapByDeltaWithDuration(this.map,-1,250);
    }

    handleSelectSite(site/*,feature*/){
        this.setState({
            infoboxSite:site
        });
    }

    render(){
        const {
            sitesWithData,
            pinLayerRenderer,
            children,
            infoBoxAttributes
        } = this.props;


        const zoomToolbar = (
            <Paper style={{position:'absolute',top:0,bottom:0,left:0,width:'3em',zIndex:1}}>
                <IconButton style={{display:'block',height:'32px',padding:'8px 12px 0px'}} onClick={this.zoomIn} disableTouchRipple>
                    <ZoomInIcon />
                </IconButton>

                <IconButton style={{display:'block',height:'32px',padding:'0px 12px 8px'}} onClick={this.zoomOut} disableTouchRipple>
                    <ZoomOutIcon />
                </IconButton>
            </Paper>
        );

        const map = (
            <div style={{position:'absolute',top:0,bottom:0,left:'3em',right:0}}>
                <WorldMap
                    sites={sitesWithData}
                    worldOnly
                    pinLayerRenderer={pinLayerRenderer}
                    disableOLControls
                    onSelectSite={this.handleSelectSite}
                    onDeselectSite={this.handleSelectSite}
                >
                    <OverlayGroup>
                        {this.renderInfoBox(this.state.infoboxSite,infoBoxAttributes)}
                    </OverlayGroup>
                </WorldMap>
            </div>
        );

        return (
            <div className="vss-map">
                {zoomToolbar}
                {map}
                {children}
            </div>
        );
    }

    renderInfoBox(site,attributes){
        if(!site) return null;

        return (
            <Overlay key="hallo" position={[site.x,site.y]} positioning="bottom-center" offset={[0,-16]}>
                <SiteInfoBox site={site} attributes={attributes}/>
            </Overlay>
        );
    }
}

class SiteAlertMapWidget extends React.Component{
    static get propTypes(){
        return {
            sites: PropTypes.any,
            data: PropTypes.any
        };
    }

    render(){
        const {sites,data} = this.props;
        const sitesWithAlertData = this.synthesizeSitesAndData(sites,data);
        const unassociatedAlerts = data.find(site=>site.site_id==="NA");
        const infoBoxAttributes = [
            {
                label:"Site",
                key:"name",
                defaultValue:""
            },{
                label:"High",
                key:"high",
                defaultValue:0
            },{
                label:"Medium",
                key:"medium",
                defaultValue:0
            },{
                label:"Low",
                key:'low',
                defaultValue:0
            }
        ];
        return (
            <BaseMapWidget
                {...this.props}
                pinLayerRenderer={SiteAlertLayer}
                sitesWithData={sitesWithAlertData}
                infoBoxAttributes={infoBoxAttributes}
            >
                <ServerAlertsBox label="Server Alerts" counts={unassociatedAlerts} />
            </BaseMapWidget>
        );
    }

    synthesizeSitesAndData(sites,data){
        let lookup = {};
        data.forEach(site=>{
            lookup[site.site_id] = site;
        });

        return sites.map(site=>{
            return {
                ...site,
                ...lookup[site._id]
            };
        });
    }
}

class SiteCountMapWidget extends React.Component{
    static get propTypes(){
        return {
            sites: PropTypes.any,
            data: PropTypes.any
        };
    }

    render(){
        const {sites,data} = this.props;
        return (
            <BaseMapWidget
                {...this.props}
                pinLayerRenderer={SiteCountLayer}
                sitesWithData={this.synthesizeSitesAndData(sites,data)}
                infoBoxAttributes={[
                    {
                        label:"Site",
                        key:"name"
                    },
                    {
                        label:"Count",
                        key:"count"
                    }
                ]}
            />
        );
    }

    synthesizeSitesAndData(sites,data){
        const maxCount = data.reduce((workingMax,row)=>{
            return (row.count > workingMax) ? row.count : workingMax;
        },0);

        const counts = data.reduce((counts,row)=>{
            return {
                ...counts,
                [row.site_id]: row.count
            };
        },{});

        return sites.map(site=>({
            ...site,
            count: counts[site._id] || 0,
            relativeSize: (counts[site._id] || 0) / maxCount
        }));
    }
}

class MapWidget extends React.Component{
    static get propTypes(){
        return {
            pinType: PropTypes.string
        };
    }

    render(){

        let SiteMapWidget = this.getWidgetComponent(this.props.pinType);

        return (
            <SiteMapWidget {...this.props} />
        );

    }

    getWidgetComponent(type){
        switch(type){
            case "count":
                return SiteCountMapWidget;
            case "alert":
            default:
                return SiteAlertMapWidget;
        }
    }

}


const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        requestSites: actions.requestSites
    },dispatch)
});

const mapStateToProps = state =>({
    sites: state.sites.sites
});

export default connect(mapStateToProps,mapDispatchToProps)(MapWidget);
