import React from 'react';

import SitesMapsContainer from './SitesMapsContainer';
import RecentMapsContainer from "./RecentMapsContainer";

const  MapMainContainer = ({setToggleMap}) => {
    return(
        <div className={"map-main-container"}>
            <RecentMapsContainer setToggleMap={setToggleMap}/>
            <SitesMapsContainer setToggleMap={setToggleMap}/>
        </div>
    )
};

export default MapMainContainer;