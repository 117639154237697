import React from "react";
// import { useSelector } from "react-redux";

const CustomCellSiteMap = ({value}) => {
   /* const sites = useSelector(state => state.deviceManager.sites);
    const maps = useSelector(state => state.deviceManager.maps);
    const selectedDevices = useSelector(state => state.deviceManager.deviceList[rowIndex]);

    let siteSelect;*/
    /*if( selectedDevices ) {
        siteSelect = sites && sites.filter( item =>{
            return item.id === selectedDevices.siteId;
        });
    }

    let valueCustom = null;
    if(typeColum === 'siteId'){
        if(siteSelect && siteSelect.length === 1){
            valueCustom  =  siteSelect[0].name;
        }
    }
    else if (typeColum === 'location'){
        if(siteSelect && siteSelect.length === 1 && maps[selectedDevices.siteId]){
            let selectMap = maps[selectedDevices.siteId].filter( item =>{
                return item.id.toString() === value.mapID;
            });
            if(selectMap && selectMap.length ===1 ){
                valueCustom = selectMap[0].name;
            }
        }
    }*/
    return (
        <div>
            {value?.mapName}
        </div>
    );
}

export default CustomCellSiteMap;