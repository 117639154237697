import React, {Component} from 'react';
import FilterDropDown from './filter/FilterDropdown';
import InputFileComponent from './filter/InputFileComponent';
import FontIcon from "@material-ui/core/Icon";
import ContentArchive from '@material-ui/icons/Archive';
import {ReportService} from '../../../../app/actions/util';
import WaitDialog from '../../../../app/views/sites/dataentry/floormaps/WaitDialog.js';
import ModalImportDetails from './ModalImportDetails';
import {connect} from "react-redux";
import {bindActionCreators} from 'redux';
import {loadResourceCustomAttibuteSettings} from '../../../actions';
//for sample file button
import FileSaver from "file-saver";
import SampleFields from "./SampleFields";

class ImportBox extends Component {
    constructor (props) {
        super(props);
        this.state = {
            nameFile: '',
            valueObject: 'Please select an option',
            valueObjectChildren: 'Please select an option',
            percentage: 0,
            menu: [],
            menuChildren: [],
            valueObjectId: 0,
            valueObjectChildrenId: 0,
            viewProcessBar: 'block',
            waitDialogOpen: false,
            disableComponent: false,
            settingIcon: {
                view: false,
                name: '',
                color: '',
                label: ''
            },
            importStatusData: {},
            showModal: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleFileChosen = this.handleFileChosen.bind(this);
        this.handleChangeChildren = this.handleChangeChildren.bind(this);
        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.handleFinishImport = this.handleFinishImport.bind(this);
        //functions for sample files button
        this.handleGetHeaders = this.handleGetHeaders.bind(this);
        this.handleImportDetailsOpen = this.handleImportDetailsOpen.bind(this);
        this.handleImportDetailsClose = this.handleImportDetailsClose.bind(this);
    }

    componentDidMount() {
        this.props.loadResourceCustomAttibuteSettings();
    };

    getDisableButton() {
        return !(this.state.valueObject !== 'Please select an option' && this.state.valueObjectChildren !== 'Please select an option' && this.state.nameFile);
    }

    handleRequestMenu() {
        this.setState({
            menu: this.getMenu()
        });
    }

    changeValueState(stateValues) {
        this.setState({
            ...stateValues,
            percentage: 0,
            viewProcessBar: 'none',
            settingIcon: {
                view: false,
                name: '',
                color: '',
                label: ''
            }
        });
    }

    handleChange(value) {
        let menu = this.getMenu();
        let listChildren = menu.filter(m => m.name === value);
        if (value && listChildren.length > 0) {
            const stateValues={
                valueObject: value,
                valueObjectId: listChildren[0].id,
                valueObjectChildren: 'Please select an option'
            };

            this.loadFormsForReport(listChildren[0].id);

            this.changeValueState(stateValues);
        }
    }

    handleChangeChildren(value) {
        let childObj = this.state.menuChildren.filter(m => m.name === value);
        if (value && childObj.length > 0) {
            this.setState({
                valueObjectChildren: value,
                valueObjectChildrenId: childObj[0].id,
            });
            this.changeValueState();
        }
    }

    handleFinishImport() {
        //Refresh the report(Import Result Table) if it is connected to this ImportBox
        if (this.props.onFinishImport) {
            this.props.onFinishImport();
        }
    }

    handleButtonClick() {
        this.changeValueState();
        this.setState({ waitDialogOpen: true, disableComponent: true });

        const header = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        let formData = new FormData();
        formData.append('inputFile', this.state.nameFile);

        // axios.post(`http://10.0.0.182/mwe/import/report/upload?reportId=${this.state.valueObjectId}&formId=${this.state.valueObjectChildrenId}&user=admin`, formData, header)
        this.props.actions.importFromFile(this.state.valueObjectId,this.state.valueObjectChildrenId,formData,header)
            .then(res => {
                this.setState({ waitDialogOpen: false, viewProcessBar: 'block' });
                if (res.data.correct) {
                    //let newState = Object.assign({}, this.state)
                    // let copyDataQuery = this.props.importHistoryList; // DANGER!!!!! modifying the props
                    // let backgroundRow = newState.backgroundRow;
                    //copyDataQuery.unshift({});
                    //backgroundRow.unshift(0);
                    // let intervalQuery = () => {
                    //     // axios.get(`http://10.0.0.182/mwe/import/report/status?reportId=${this.state.valueObjectId}&formId=${this.state.valueObjectChildrenId}&resultRowFrom=1`)

                    //     //TODO: Manage catch if ocurrs an error
                    //     this.props.actions.getImportStatus(this.state.valueObjectId,this.state.valueObjectChildrenId,1)
                    //         .then(res => {
                    //             let bar = res.data.rowsProcessed / res.data.rows * 100;
                    //             res.data.startDate = dateFormat(res.data.startDate, 'mm/dd/yyyy h:MM TT');
                    //             //copyDataQuery[0] = res.data;
                    //             //backgroundRow[0] = res.data.rowsError ? 1 : 0;

                    //             this.setState({ /*dataQuery: [...copyDataQuery],*/
                    //                 //backgroundRow: [...backgroundRow],
                    //                 percentage: Number(bar.toFixed(2)) || 0 }, () => {
                    //                 //this.applyFilters();
                    //             });

                    //             if (res.data.rowsProcessed === res.data.rows) {
                    //                 this.setState({
                    //                     disableComponent: false,
                    //                     settingIcon: {
                    //                         view: true,
                    //                         name: res.data.rowsError ? 'error' : 'check_circle_outline',
                    //                         color: res.data.rowsError ? 'orange' : 'green',
                    //                         label: ''
                    //                     }
                    //                 }, this.handleFinishImport);
                    //             } else {
                    //                 setTimeout(intervalQuery, 2000);
                    //             }
                    //         });
                    // };
                    let statusBarUpdate = () => {
                        this.props.actions.getImportStatus(this.state.valueObjectId,this.state.valueObjectChildrenId,1)
                            .then(res => {
                                const {rowsProcessed, rowsCorrect, rowsError, rows, importing, elapsedTime} = res.data;
                                if (importing || elapsedTime){
                                    const bar = rowsProcessed / rows * 100;
                                    this.setState({ 
                                        percentage: Number(bar.toFixed(2)) || 0,
                                        currentProgress: ` Items processed: ${rowsProcessed}, Successful: ${rowsCorrect}, Failed: ${rowsError}`,
                                        importStatusData: res.data
                                    }, () => {    
                                            if (rowsProcessed === rows) {
                                                this.setState({ 
                                                    disableComponent: false, 
                                                    settingIcon: { 
                                                        view: true, 
                                                        name: res.data.rowsError ? 'error' : 'check_circle_outline', 
                                                        color: res.data.rowsError ? 'orange' : 'green', 
                                                        label: '',
                                                    },
                                                }, this.handleFinishImport);
                                            } else {
                                                setTimeout(statusBarUpdate, 200);
                                            }
                                        }
                                    );
                                }
                            });
                    };
                    //setTimeout(intervalQuery, 200);
                    setTimeout(statusBarUpdate, 200);
                } else {
                    this.setState({
                        disableComponent: false,
                        settingIcon: {
                            view: true,
                            name: 'block',
                            color: 'red',
                            label: res.data.msg
                        },
                        currentProgress: undefined
                    }, this.handleFinishImport);
                }
            });
    }

    handleRequestChildrenMenu() {
        // if (this.state.valueObjectId) {
        //     ReportService.instance().get('/reports/' + this.state.valueObjectId)
        //         .then(response => {
        //             this.setState({ menuChildren: response.data.results.dataEntryActions || [] });
        //         })
        //         .catch(err => {
        //             console.error(err);
        //         })
        //         .catch(err => {
        //             console.error(err);
        //         });
        // }
    }

    handleFileChosen(nameFile) {
        this.setState({
            nameFile: nameFile
        });
        this.changeValueState();
    }

    handleGetHeaders() {
        const {valueObjectChildren, valueObjectChildrenId, menuChildren} = this.state;
        this.props.actions.getImportFields(valueObjectChildrenId)
        .then(res => {
            if (res.data && res.data.results !== "Error getting form fields") {
                const lines = [];
                const headers = [];
                const samples = [];
                let maxLength = 0;
                for (const key in res.data.results) {
                    const value = res.data.results[key];
                    if (typeof key === 'string') {
                        headers.push('"' + key + '"');
                        if (SampleFields.hasOwnProperty(value)) {
                            maxLength = Math.max(maxLength, SampleFields[value].length);
                        }
                    }
                }

                for (const key in res.data.results) {
                    const value = res.data.results[key];
                    if (typeof key === 'string') {
                        let arr = [""];
                        if (key === "Mode") {
                            if (valueObjectChildren.indexOf("Add") === 0) {
                                arr = ["A"];
                            } else if (valueObjectChildren.indexOf("Update") === 0) {
                                arr = ["U"];
                            } else {
                                //There are not considered cases.
                                //It's left in 'U' by the moment.
                                arr = ["U"];
                            }
                        } else if (SampleFields.hasOwnProperty(value)) {
                            if (value === 'pAttributeName'){
                                arr = this.props.tokenReplacements;
                            }else{
                                arr = SampleFields[value];
                            }
                        }
                        samples[key] = this.extendArray(arr, maxLength);
                    }
                }
                lines.push(headers.join(','));
                for (let rowIndex = 0; rowIndex < maxLength; rowIndex++) {
                    const line = [];
                    for (const key in res.data.results) {
                        if (typeof key === 'string') {
                            line.push('"' + samples[key][rowIndex] + '"');
                        }
                    }
                    lines.push(line);
                }

                const lineString = lines.join('\n');
                let name = 'sample';
                menuChildren.forEach(child => {
                    if (child.id === valueObjectChildrenId) {
                        name = child.name;
                        name = name.split(' ').join('_') + '_sample';
                    }
                });
                this.downloadFile(`${name}.csv`, lineString, 'csv');
            } else {
                console.error("Error getting form fields");
            }
        });
    }

    handleImportDetailsClose() {
        this.setState({showModal: false});
    }

    handleImportDetailsOpen() {
        this.setState({showModal: true});
    }

    downloadFile(fileName, dataString, type){
        var blob = new Blob([dataString], {type: `text/${type};charset='utf-8'`});
        FileSaver.saveAs(blob, fileName);
    }
    /**
     * Copy the elements of array in a new array until this last have 'newlength' elements.
     * @param array
     * @param newlength
     * @returns {array}
     */
    extendArray(array, newlength) {
        let answer = [], index = 0;
        while (newlength-- > 0) {
            answer.push(array[index]);
            index = (index + 1) % array.length;
        }
        return answer;
    }

    getMenu() {
        const { userMenu, permissions } = this.props;
        let menu = [];
        userMenu.forEach((element) => {
            element.children.forEach((elementChildren) => {
                if (elementChildren.id && elementChildren.type === 'report' && elementChildren.category === 'Reports'
                && elementChildren.name && elementChildren.name !== 'Resource Grouping' && elementChildren.name !== 'Pickup Dropoff' && permissions[`edit-report-${elementChildren.id}`]
                ) {
                    menu.push(elementChildren);
                }
                if (elementChildren.id && elementChildren.type === 'report' && elementChildren.category === 'Configuration'
                && elementChildren.name && elementChildren.name.indexOf('Resource') !== -1
                && elementChildren.name.indexOf('Print Setup') === -1 && permissions[`edit-report-${elementChildren.id}`]) {
                    menu.push(elementChildren);
                }
            });
        });
        menu.sort(this.dynamicSort('label'));
        return menu;
    }

    dynamicSort(property){
        var sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }

        return function (a,b) {
            if(sortOrder === -1){
                return b[property].localeCompare(a[property]);
            }else{
                return a[property].localeCompare(b[property]);
            }
        }
    }

    loadFormsForReport(reportId){
        let actions =[];
        if (reportId!=null) {
            ReportService.instance().get('/reports/' + reportId)
                .then(response => {
                    actions = response.data.results.dataEntryActions;
                    actions.sort(this.dynamicSort('name'));
                    //Filtering values without RFID Label Printing
                    this.setState({ menuChildren: actions.filter(object => object.name.indexOf("RFID Label") === -1 )  || [] });
                })
                .catch(err => {
                    console.error(err);
                });
        }
    }

    ProgressBar(props) {
        return (
            <div className={"progress-bar-import"}>
                <div className={"container-progress-bar"}>
                    <div className={"progress"}>
                        <div className={"progress-bar"} style={{ width: `${props.percentage}%`, display: `${props.viewProcessBar}` }}>
                            <span>
                                {props.percentage} % {props.currentProgress && ` ${props.currentProgress}`}
                            </span>
                        </div>

                    </div>
                    <div className={"icon-progress-bar"} style={{visibility: props.settingIcon.view? 'visible':'hidden'}}>
                        <FontIcon className="material-icons" style={{ color: `${props.settingIcon.color}`, width: "24px"}} onClick={props.handleImportDetailsOpen}>{props.settingIcon.name}</FontIcon>
                    </div>

                </div>
                {props.settingIcon.label && <div className={"message-error"}>{props.settingIcon.label}</div>
                }
            </div>
        )
    }

    render () {
        const disableButton = this.getDisableButton();
        let disableButtonFileSample = (this.state.valueObjectChildren==='Please select an option') ? true :false;
        const _progressBar = <this.ProgressBar
                percentage={this.state.percentage}
                viewProcessBar={this.state.viewProcessBar}
                settingIcon={this.state.settingIcon}
                currentProgress = {this.state.currentProgress}
                handleImportDetailsOpen = {this.handleImportDetailsOpen}
            />;
        const collapsed = this.props.collapsed;
        const isMobile = this.props.isMobile;
        const {importStatusData, showModal} = this.state;
        return (
            <div style={{position: "relative", float: "left"}}>
                <div className={"import-filter-containter"}>
                    <div className={"filters-container"}>
                        <div className={"resource-by-select-class"}>
                            <div className={"object-container"}>
                                <span className={"import-title"}>
                                    Report
                                </span>
                                <FilterDropDown
                                    values={this.state.menu}
                                    actions={this.props.actions}
                                    onChangeValue={this.handleChange}
                                    value={this.state.valueObject}
                                    onFocus={() => { this.handleRequestMenu(); }}
                                    disabledOption={this.state.disableComponent}
                                    isMobile={this.props.isMobile}
                                />
                            </div>
                        </div>
                        <div className={"resource-by-select-class"}>
                            <div className={"action-container"}>
                                <span className={"import-title"}>
                                    Action
                                </span>
                                <div style={{display:'flex',width:'100%'}}>
                                    <div style={{display:'block',width:'80%'}}>
                                        <FilterDropDown
                                            values={this.state.menuChildren}
                                            actions={this.props.actions}
                                            value={this.state.valueObjectChildren}
                                            onChangeValue={this.handleChangeChildren}
                                            onFocus={() => { this.handleRequestChildrenMenu() }}
                                            disabledOption={this.state.disableComponent || (this.state.valueObject === 'Please select an option')}
                                            isMobile={this.props.isMobile}
                                        />
                                    </div>
                                    <div style={{display:'block',width:'20%',marginLeft:'0px',marginRight:'9px'}}>
                                        <button className={"button-modal-import"} style={{backgroundColor: disableButtonFileSample ? '#d9dee0':'#198cb8'}}
                                            onClick={this.handleGetHeaders} disabled={disableButtonFileSample}>
                                            {(collapsed && isMobile)?
                                                <ContentArchive style={{color: "#fff"}}/>:
                                                <span>Sample File</span>
                                            }
                                        </button>
                                    </div>                                       
                                </div>   
                                
                            </div>
                        </div>
                        <div className={"resource-by-select-class"}>
                            <div className={"input-file-container"}>
                                <span className={"import-title"}>
                                    Select File
                                </span>
                                <InputFileComponent
                                    handleFileChosen={this.handleFileChosen}
                                    nameFile={this.state.nameFile}
                                    disabledOption={this.state.disableComponent}
                                />                                   
                            </div>
                        </div>
                        <div className={"resource-by-button-import-class"}>
                            <div className={"import-button-container"}>
                                <div>
                                    <input className="import-button" type={"button"} value="IMPORT" onClick={this.handleButtonClick} disabled={disableButton || this.state.disableComponent} style={{ background: this.state.disableComponent || disableButton ? "#D9DEE0" : "" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"resource-by-progress-bar-class"} >
                        <span className={"import-title"}>
                            Progress
                        </span>
                        {_progressBar}
                    </div>

                </div>

                <WaitDialog
                    open={this.state.waitDialogOpen}
                    text={"Uploading file..."}
                />

                <ModalImportDetails
                    data = {importStatusData}
                    isMobile = {isMobile}
                    open = {showModal}
                    onRequestClose = {this.handleImportDetailsClose}
                />
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        loadResourceCustomAttibuteSettings: loadResourceCustomAttibuteSettings
    },dispatch)
});

const mapStateToProps = state => ({
    tokenReplacements: state.importReport.tokenReplacements,
    permissions: state.user.permissions
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportBox);