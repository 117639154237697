import React from 'react';
import PropTypes from 'prop-types';

import StyledDialog, {CancelButton, SaveButton} from '../../util/Dialog';

import {DataEntryTabularField, DataEntryTabularForm} from '../../util/DataEntryTabularForm';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../../../actions';
import {APPLIANCES_REBOOT} from '../../../constants/DataEntryForms';

class RebootApplianceDialog extends React.Component {

    constructor(){
        super();

        this.handleSave = this.handleSave.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    static get propTypes(){
        return {
            open: PropTypes.bool,
            appliance: PropTypes.object,
            site: PropTypes.object,
            onRequestClose: PropTypes.func.isRequired,
            createTask: PropTypes.func.isRequired
        };
    }

    static get defaultProps(){
        return {
            open: false,
            data:{ // please remove this...
                appliances:[{
                    uuid:"94ec08bf-2f83-4181-8c91-119dbf53bec8"
                }]
            }
        };
    }

    handleClose ()  {
        this.props.onRequestClose();
    }

    handleSave(){
        //this.props.actions.deleteAppliance({
        //    uuid: this.props.data.appliances[0].uuid
        //});
        const {appliance} = this.props;
        this.props.createTask(appliance._id,"RebootSystem",{},0);
    }

    renderForm(){

        const {appliance, site} = this.props;
        const data = appliance;

        return (
            <DataEntryTabularForm fullWidth>

                <DataEntryTabularField label={"Site"}>
                    {site && site.name}
                </DataEntryTabularField>

                <DataEntryTabularField label={"Appliance"}>
                    {data.name}
                </DataEntryTabularField>

                <DataEntryTabularField label={"UUID"}>
                    {data.uuid}
                </DataEntryTabularField>

            </DataEntryTabularForm>
        );
    }

    render() {

        return (
            <StyledDialog
                title="Reboot ZLA"
                modal={false}
                open={this.props.open}
                onClose={this.handleClose}
                contentStyle={{maxWidth: 360}}
                actions={
                    <>
                        <SaveButton onClick={this.handleSave} label="Reboot"/>
                        <CancelButton style={{position: 'absolute', left: 8, bottom: 8}} onClick={this.handleClose} />
                    </>
                }
                {...this.props}
                >
                {this.renderForm()}
            </StyledDialog>
        );
    }
}


/*
 ██████  ██████  ███    ██ ███    ██ ███████  ██████ ████████     ████████  ██████
██      ██    ██ ████   ██ ████   ██ ██      ██         ██           ██    ██    ██
██      ██    ██ ██ ██  ██ ██ ██  ██ █████   ██         ██           ██    ██    ██
██      ██    ██ ██  ██ ██ ██  ██ ██ ██      ██         ██           ██    ██    ██
 ██████  ██████  ██   ████ ██   ████ ███████  ██████    ██           ██     ██████

███████ ████████  █████  ████████ ███████
██         ██    ██   ██    ██    ██
███████    ██    ███████    ██    █████
     ██    ██    ██   ██    ██    ██
███████    ██    ██   ██    ██    ███████
*/





const mapStateToProps = state => {

    let initialFormData = state.dataEntry.initialFormData || {};
    let appliance = (initialFormData && initialFormData.appliances && initialFormData.appliances[0]) || {};
    let sites = state.appliances.sites;

    return ({
        open: state.dataEntry.open && state.dataEntry.activeForm === APPLIANCES_REBOOT,
        appliance,
        site: sites && sites[appliance.siteId]
    });
};

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        onRequestClose: actions.closeDataEntry,
        createTask: actions.createTask
    },dispatch)
});

export default connect(mapStateToProps,mapDispatchToProps)(RebootApplianceDialog);
