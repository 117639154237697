import React, {useState} from 'react';
import SelectTemplateDialog from '../dialogs/SelectTemplateDialog';
import PublishedVersionDialog from '../dialogs/PublishedVersionDialog';
import { applySelectedTemplate } from "../../../../actions";
// import DeviceStatus from '../DeviceStatus';
import EditDeviceStatus from '../EditDeviceStatus';
import { useSelector, useDispatch } from 'react-redux';
import {localeTimestamp} from "../../../../utils/TimestampFormats";
import InfoIcon from '@material-ui/icons/Info';

const EditDeviceHeader = () => {
    const [dialog, setDialog] = useState(null);
    const [showMassageTooltip,setShowMassageTooltip] = useState(false);
    const dispatch = useDispatch();
    const nameTemplate = useSelector(state => state.deviceManager.templateForm.template.template);
    const nameDevice = useSelector(state => state.deviceManager.templateForm.template.title);
    const version = useSelector(state => state.deviceManager.templateForm.template.version);
    const configuration_state = useSelector(state => state.deviceManager.templateForm.template.configuration_state);
    const status = useSelector(state => state.deviceManager.templateForm.template.status);
    const createBy = useSelector(state => state.deviceManager.templateForm.createBy);
    const createDate = useSelector(state => state.deviceManager.templateForm.createDate);
    const successful = useSelector(state => state.deviceManager.successfulValidatedFields);
    const needsValidationFields = useSelector(state => state.deviceManager.needsValidationFields);
    const errorMessages = useSelector(state => state.deviceManager.messagesBatch);

    const handleSelectTemplate = (template) => {
        dispatch(applySelectedTemplate(template));
        closeDialog();
    }

    const closeDialog = () => {
        setDialog(null);
    }
    
    const showTooltip=()=>{
        setShowMassageTooltip(true);
    }
    const hiddenTooltip=()=>{
        setShowMassageTooltip(false);
    }

    const getInvalidFields = () => {
        let invalidFields = [];
        let styleItemList = {width:'100%', display: 'flex', flexDirection:'row'};
        Object.keys(needsValidationFields).forEach(sectionKey=>{
            const section = needsValidationFields[sectionKey];
            Object.keys(section).forEach(field=>{
                if(section[field].error){
                    invalidFields.push(`${section[field].label}. ${section[field].message}`);
                }
            });
        });

        // check server errors
        if(Object.keys(errorMessages).length > 0){
            Object.keys(errorMessages).forEach(key => {
                invalidFields.push(errorMessages[key]);
            });
        }
        return <>
            {invalidFields.map((item, index) => <div key={index} style={styleItemList}>
                <div style={{flex:'0 0 10px'}}>-</div>
                <div style={{fontSize:'11px', textAlign: 'justify',flexGrow: 1}}>{item}</div>
            </div>)}
        </>;
    }

    const renderConfigurationState = () => {
        const styleErrorContent = {backgroundColor: 'red', color: '#fff', width: '100%'};
        const styleIcon = {height: '20px', width: '20px'};
        const customStyleTooltip = {bottom:'auto', top: '24px', width:'230px', fontSize: '12px'};
        if(successful && Object.keys(errorMessages).length === 0){
            return configuration_state;
        }else{
            return <div className={'device-status-container'} style={styleErrorContent}>
                <span className={'device-status-label'}>Error</span>
                <div className={'device-status-tooltip-container'}  onMouseOver={showTooltip} onMouseLeave={hiddenTooltip}>
                    <InfoIcon style={styleIcon}/>
                    {(showMassageTooltip)?<div className={'device-status-tooltip'} style={customStyleTooltip}><span>{getInvalidFields()}</span></div>:null}
                </div>
            </div>
        }
    }

    const styleButton = {backgroundColor:'#007aba',color:'#fff',border:'none',fontSize:'12px'};
    const styleContent = {flex: 1, textOverflow:'ellipsis',whiteSpace:'no-wrap',overflow:'hidden'}
    const styleDeviceName = {display: 'block', whiteSpace:'nowrap', overflow:'hidden', textOverflow: 'ellipsis'};
    return (
        <div id={'editDeviceHeader'}>
            {/* <div style={{marginBottom:'16px'}}>
                <b style={{fontSize:'20px'}}>{nameDevice}</b>
            </div> */}
            <div className={'grid-configuration-devices'}>
                <div className={'grid-child-configuration'}>
                    <div className={'title-configuration'}>Device Name</div>
                    <div className={'content-configuration'} style={styleDeviceName}>{nameDevice}</div>
                </div>
                <div className={'grid-child-configuration'}>
                    <div className={'title-configuration'}>Configuration State</div>
                    <div className={'content-configuration'} style={{width:'100px'}}>{renderConfigurationState()}</div>
                </div>
                <div className={'grid-child-configuration'}>
                    <div className={'title-configuration'}>Template</div>
                    <div className={'content-configuration'} style={{alignItems:'center'}}>
                        <div style={styleContent}>{nameTemplate && nameTemplate !== "" ? nameTemplate:'No Template'}</div>
                        <div style={{flex:'0 0 52px'}}><button style={styleButton} onClick={()=>setDialog('select-dialog')}>Change</button></div>
                    </div>
                </div>
                <div className={'grid-child-configuration'}>
                    <div className={'title-configuration'}>Configuration Version</div>
                    <div className={'content-configuration'} style={{alignItems:'center'}}>
                        <div style={styleContent}>V {version}</div>
                        <div style={{flex:'0 0 56px'}}><button style={styleButton} onClick={()=>setDialog('published-dialog')}>View All</button></div>
                    </div>
                </div>
                <div className={'grid-child-configuration'}>
                    <div className={'title-configuration'}>Operational Status</div>
                    <div className={'content-configuration'} style={{width:'100px'}}>
                        <EditDeviceStatus status={status} />
                    </div>
                </div>
            </div>
            {nameTemplate && <div className={"template-content"}>
                <div style={{width:'100%', paddingLeft: 0,fontWeight:'bold',clear:'both'}}>
                    <span style={{color:'#aab0b3',fontSize:'12px'}}>
                        {`Created by: ${createBy}`}&nbsp;&nbsp;&nbsp;{`Last Updated: ${localeTimestamp(new Date(createDate))}`}
                    </span>
                </div>
            </div>}
            {(dialog && dialog === 'select-dialog') && 
                <SelectTemplateDialog selectTemplate={handleSelectTemplate} nameTemplate={nameTemplate}
                                    open={dialog === 'select-dialog'} closeDialog={closeDialog}/>}
            {(dialog && dialog === 'published-dialog') && 
                <PublishedVersionDialog nameDevice={nameDevice}
                    open={dialog === 'published-dialog'} closeDialog={closeDialog}/>}
        </div>
    )
}

export default EditDeviceHeader;