import React from 'react';
import {useDispatch, useSelector}  from 'react-redux';
import {downloadSpecificLog} from '../../../actions';

const CustomLinkLogsHistory = ({itemKey, rowIndex}) => {
    const selectedStatusHistory = useSelector( state => state.deviceManager.logsHistoryData[rowIndex]);
    const downloadedLogs = useSelector(state => state.deviceManager.downloadedLogs);
    const dispatch = useDispatch();

    const getValue = (e) => {
        if(selectedStatusHistory.logData.code === 1) e.preventDefault();
        dispatch(downloadSpecificLog(selectedStatusHistory.deviceId, selectedStatusHistory.logType, selectedStatusHistory._id));

    };

    return (
        <div style={{textAlign: 'center'}} >
            <span
                style={{
                    cursor: selectedStatusHistory.logData.code === 1 ? 'default':'pointer',
                    fontStyle: selectedStatusHistory.logData.code === 1 ? 'italic':'normal',
                    textDecoration: selectedStatusHistory.logData.code === 1 ? 'none':'underline',
                    color:  selectedStatusHistory.logData.code === 1 ? '#7e7e7e' : (downloadedLogs[selectedStatusHistory._id] ? '#B23A0F': '#5296b2')}} onClick={getValue}>
                { selectedStatusHistory.logData.code === 1 ? selectedStatusHistory.logData.message : 'Download'}
            </span>
        </div>
    )
};

export default  CustomLinkLogsHistory;