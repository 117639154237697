import React, {useState} from 'react';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from 'react-redux';
import {updateStepAddDevices, updateListTypeDevices} from "../../../actions";
import {useHistory} from 'react-router-dom';

function SelectTypes(){
    const history = useHistory();
    const dispatch = useDispatch();
    const deviceTypes = useSelector(state => state.deviceManager.deviceTypes);
    const windowWidth = useSelector(state => state.resize.windowWidth);
    const actualStep = useSelector(state => state.deviceManager.actualStep);
    const [listDevices, setListDevices] = useState(deviceTypes);
    function changeDeviceModelValue(idDevice, idModel, number){
        let listDevicesUpdated = {...listDevices};
        listDevicesUpdated[idDevice].models[idModel].number = number;  
        setListDevices(listDevicesUpdated);
    }

    function distributeItems(arrayItems, numberChunks){
        let arragedItems = [];
        Object.keys(arrayItems).map(key => arrayItems[key]).forEach((value, index) => {
            if((index % numberChunks) + 1 > arragedItems.length){
                arragedItems.push([value]);
            }else{
                arragedItems[index % numberChunks].push(value);
            }
        })
        return arragedItems;
    }

    function nextButton(){

        dispatch(updateListTypeDevices(listDevices));
        dispatch(updateStepAddDevices(actualStep + 1));
    }

    const getTotalDevicesToAdd = () => {
        let total = 0;
        for (const deviceId in listDevices) {
            const device = listDevices[deviceId];
            for (const modelId in device.models) {
                const model = device.models[modelId];
                total = total + model.number;
            }
        }
        return total;
    }

    let numberDivs = 4;
    //calculating number of columns
    if(windowWidth<=992 && windowWidth>768){
        numberDivs = 3;
    }
    if(windowWidth<=768 && windowWidth>500){
        numberDivs = 2;
    }
    if(windowWidth<=500){
        numberDivs = 1;
    }
    let contentDivs = distributeItems(listDevices, numberDivs);  
    const pathDeviceList = '/device-manager/devices';
    const totalDevicesToAdd = getTotalDevicesToAdd();
    return (
        <React.Fragment>
            <div className={"container-body"}>
                {contentDivs.map((content,index)=>(
                    <div className={"accordions"} key={index}>
                        {content.map((item, itemIndex)=>(
                            <div className={"accordion"} key={itemIndex}>
                                <CardDevice device={item} changeDeviceModelValue={changeDeviceModelValue} />
                            </div>
                        ))}
                    </div>
                ))}
            </div>                   
            <div className={"container-step-content-buttons"}>
                <Button className={'cancel-button'} style={{marginRight:'8px'}} onClick={()=>history.push(pathDeviceList)} component="span">Cancel</Button>
                <Button disabled={totalDevicesToAdd === 0} className={`next-step-button${ totalDevicesToAdd === 0 ? '-disabled' : '' }`} onClick={nextButton} component="span">Next Step: Device Details</Button>
            </div>
        </React.Fragment>
    )
}

export default SelectTypes;


function CardDevice({device, changeDeviceModelValue}){
    const [show, setShow] = useState(false);

    function changeValueItem(idModel,number){
        changeDeviceModelValue(device.id, idModel, number);
    }

    let models = Object.keys(device.models).map(key => device.models[key]);
    return(
        <Card className={"card-device"}>
            <div style={{display:'flex', alignItems:'center', cursor:'pointer'}} onClick={() => setShow(!show)}>
                <div style={{flexGrow:1}}>
                    <IconButton size="small">
                        <PlayArrowIcon style={{width:30, height: 30, transform: show ? 'rotate(90deg)': 'rotate(0deg)'}} />
                    </IconButton>
                </div>                
                
                <div style={{flexGrow:1}}>
                    <CardMedia
                        style={{width:40, height: 40}}
                        image={device.image}
                        title={device.title}
                    />
                </div>
                <CardContent style={{flexGrow:6, alignSelf: 'flex-start'}}>
                    <span>{device.title}</span>
                </CardContent>
            </div>
            {show && 
                <div style={{display:'block'}} >
                    {models.map((item,index) => (
                        <ModelItem key={index} label={item.label} number={item.number} changeValueItem={changeValueItem} idModel={item.id} />
                    ))}
                </div>}
        </Card>
    )
}

function ModelItem({idModel,label,number=0,changeValueItem}){

    function decrement(){
        if(number>0)
            changeValueItem(idModel,number - 1);
    }

    function increment(){
        changeValueItem(idModel,number + 1);
    }

    return(
        <div className={'model-item'}>
            <span>{label}</span>
            <NumberInputComponent number={number} increment={increment} decrement={decrement} />
        </div>
    )
}

function NumberInputComponent({number, increment, decrement}){
    return(
        <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between', minWidth:'84px',maxWidth:'100px'}}>
            <IconButton size="small" onClick={decrement} >
                <ArrowBackIosIcon style={{width:20, height: 20}} />
            </IconButton>
            <div style={{background:'#7e868c', width:'36px',textAlign:'center',color:'#fff',fontWeight:'bold', fontSize:'22px',borderRadius:'5px'}}>
                <span>{number}</span>
            </div>
            <IconButton size="small" onClick={increment}>
                <ArrowForwardIosIcon style={{width:20, height: 20}} />
            </IconButton>
        </div>
    )
} 

export {NumberInputComponent};