import Divider from "@material-ui/core/Divider/Divider";
import React from "react";

const Identity=(props)=>{
    return (
        <div id={"Identity"} className="dd-identity-main-container">
            <div className="dd-identity-container-title">
                <span className="dd-identity-title">Identity</span>
            </div>
            <div className="dd-identity-items">
                <span className="dd-identity-items-title">Device Type: </span>
                <span >{props.deviceType}</span>
            </div>
            <div className="dd-identity-items">
                <span className="dd-identity-items-title">Description:</span>
                <span > {props.description}</span>
            </div>
            <Divider className="dd-divider" />
            <div  className="dd-identity-items">
                <span className="dd-identity-items-title">Hostname: </span>
                <span>{props.address}</span>
            </div>
            <Divider className="dd-divider"/>
            <div className="dd-identity-items">
                <span className="dd-identity-items-title">Coordinates: </span>
                <span className="dd-identity-items-title" style={{marginLeft:'15px'}}>X: </span>
                <span>{props.location.x}</span>
                <span className="dd-identity-items-title" style={{marginLeft:'15px'}}>Y: </span>
                <span>{props.location.y}</span>
                <span className="dd-identity-items-title" style={{marginLeft:'15px'}}>Z: </span>
                <span>{props.location.z}</span>
            </div>
        </div>
    );
};
export default Identity;