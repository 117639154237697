import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/Info';
import CachedIcon from '@material-ui/icons/Cached';
import BackArrow from '@material-ui/icons/NavigateBefore';
import LeftArrow from '@material-ui/icons/KeyboardArrowLeft';
import {useHistory, Link} from "react-router-dom";
import {PATH_ADD_TEMPLATE, PATH_EDIT_TEMPLATE, TEMPLATES_DETAILS_ALERT} from '../../../constants/DeviceManager';
import {changeStatusDetails, loadTemplateList, detectSaveChanges} from '../../../actions';
import ColumnsDropDown from './ColumnsDropDown';
import AlertMessage from "../common/AlertMessage";
import DialogConfirm from '../common/dialogs/DialogConfirm';

const Breadcrumbs = () => {
    return (
        <div className={"tb-appliances-breadcrumbs-container"}>
            <span>Infrastructure</span>
            <span> / </span>
            <span>Templates</span>
        </div>
    );
};

const MainToolbar = ({selectedRows}) => {

    const dispatch = useDispatch();
    const showDetails = useSelector(state => state.deviceManager.showDetails);
    const columns = useSelector(state => state.deviceManager.columnOrder.templates);
    const canEdit = useSelector(state => state.user.permissions['edit-infrastructure-devices']);
    const showedColumns = columns.filter(column => column.name !== 'actions');
    const [openAlert, setOpenAlert] = useState(false);
    const [messageAlert, setMessageAlert] = useState("");

    const handleCloseAlert = () => {
        setOpenAlert(false)
    };

    const handleShowDetails = () => {
        if(Object.keys(selectedRows).length > 0 ) {
            dispatch(changeStatusDetails(!showDetails))
        } else {
            setMessageAlert(TEMPLATES_DETAILS_ALERT);
            setOpenAlert(true);
        }
    }

    return (
        <React.Fragment>
            <div className={"tb-device-manager-left-container"}>
                <Link style={{textDecoration:'none', color:'#fff', margin: '0.25em 0 0'}} to={'/device-manager/devices'}><LeftArrow/></Link>
                <Breadcrumbs />
            </div>

            <div className={"tb-device-manager-right-container"}>
                <Button
                    style={{textTransform: 'none', height: '100%'}}
                    onClick={()=>dispatch(loadTemplateList())}
                    variant="text"
                    startIcon={<CachedIcon />}>Refresh</Button>
                { canEdit ?  
                    <Link style={{textDecoration:'none'}} to={`/device-manager/templates/${PATH_ADD_TEMPLATE}`}>
                        <Button
                        style={{textTransform: 'none', height: '100%',color:'white'}}
                        startIcon={<AddIcon />}>Add</Button>
                    </Link> : 
                    null
                }
                <Button
                    style={{textTransform:'none',marginLeft:"10px",marginTop:'1px'}}
                    startIcon={<InfoIcon />}
                    onClick={handleShowDetails}>
                    Details </Button>

                <div className="report-separator">&nbsp;</div>
                <ColumnsDropDown name="templates" columns={showedColumns} />
            </div>
            <AlertMessage
                openModal={openAlert}
                onClose={handleCloseAlert}
                message={messageAlert}
            />
        </React.Fragment>
    );
};

const TemplateManagerToolbar = props => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [openDialog, setOpenDialog] = useState(false);
    const isDeviceChanging = useSelector(state => state.deviceManager.isDeviceChanging);
    
    const handleBack = () => {
        if(isDeviceChanging && (props.location === PATH_EDIT_TEMPLATE || props.location === PATH_ADD_TEMPLATE)){
            setOpenDialog(true);
        }else{
            handleReturnPage();
        }
    }

    const handleReturnPage = () => {
        dispatch(detectSaveChanges(false));
        history.replace('/device-manager/templates');
    }

    const getToolbar = (location) => {
        if(!location){
            return <MainToolbar selectedRows={props.selectedRows}/>
        }else{
            return (
                <div className={"tb-device-manager-left-container"}>
                    <IconButton style={{padding:0, color:'white'}} onClick={handleBack}>
                        <BackArrow />
                    </IconButton>
                    {location === PATH_ADD_TEMPLATE && <h4>Add Template</h4>}
                    {location === PATH_EDIT_TEMPLATE && <h4>Edit Template</h4>} 
                </div>
            )
        }
    }
    return (
        <React.Fragment>
            <div className="toolbar-main-container save-share-toolbar">
                {getToolbar(props.location)}
            </div>
            {openDialog && <DialogConfirm 
                open={openDialog}
                closeDialog={() => setOpenDialog(false)}
                actionSuccess={handleReturnPage}
                actionCancel={()=>{}}
                message={'There are changes not saved. Are you sure to leave this page?'}
            />}
        </React.Fragment>
    )
}
export default TemplateManagerToolbar;
