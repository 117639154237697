import React from 'react';
import {useSelector} from "react-redux";
import {localeTimestamp} from "../../../../utils/TimestampFormats";
import TemplateGeneralPanel from "./TemplateGeneralPanel";

const EditTemplateHeader = () => {
    const createBy = useSelector(state => state.deviceManager.templateForm.createBy);
    const createDate = useSelector(state => state.deviceManager.templateForm.createDate);
    const linkedDevices = useSelector(state => state.deviceManager.linkedDevices);
    const quantityRFIDReaders = linkedDevices.filter(item => item.deviceType === 'RFID Reader').length;

    return (
        <React.Fragment>
            <div>
                <div style={{paddingLeft: 0,fontWeight:'bold'}}>
                    <span style={{color:'#aab0b3',fontSize:'12px'}}>
                        {`Created by: ${createBy}`}&nbsp;&nbsp;&nbsp;{`Last Updated: ${localeTimestamp(new Date(createDate))}`}
                    </span>
                </div>
            </div>
            <TemplateGeneralPanel />
            <div className={"template-content"}>
                <div style={{backgroundColor:'#6f6d71',padding:'12px',fontWeight:'bold'}}>
                    <span style={{color:'#ffd23a'}}>{quantityRFIDReaders}</span> &nbsp;
                    <span style={{color:'#fff'}}>RFID readers are currently using this template</span>
                </div>
            </div>
        </React.Fragment>
    );
}

export default EditTemplateHeader;