import * as types from '../constants/ActionTypes';

import {DeviceManagementService, displayErrorFromAxios, ICService, ICSMapService, SiteViewService} from './util';
import Promise from 'promise';
import {MAP_VIEW_URL} from '../constants/Misc';
import {getMapCoordinates, } from "./util/maps";
import {changeOlMap} from "./report-map";

export const loadMaps = (data) => ({type: types.LOAD_MAPS, data});

export const requestMaps = () => {
    return (dispatch) => {
        dispatch({type: types.REQUEST_MAPS});
        SiteViewService.instance().get(MAP_VIEW_URL+"/maps")
        .then(response => {
            dispatch(loadMaps(response.data));
        })
        .catch(displayErrorFromAxios.bind(null,dispatch));

    };
};


export const uploadSiteMap = (site,name,file,zoom) => {
    return (dispatch) => {

        let data = new FormData();
        data.append('description',name);
        data.append('wmf',file);
        data.append('siteId',site);
        data.append('maxZoom',zoom);

        return ICSMapService.instance().post("/maps/upload",data)
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};

export const getMapUploadStatus = () => {
    return dispatch => {
        return ICSMapService.instance().get("/maps/upload/status")
          .then(res=>{
            dispatch(loadMapStatus(res.data));
            return res.data;
          })
          .catch(err=>{
            var message = (err.response && err.response.data) || err.message;
            return Promise.reject(message);
          });
    }
};

const loadMapStatus = (data) => {
  return ({type: types.UPLOAD_MAP_STATUS, data});
}

export const deleteSiteMap = (mapId,callback,errorDeleteMap) => {
    return async (dispatch) => {
        let devicesOnMap = null;
        try {
            const devicesDMS = await DeviceManagementService.instance().get(`/devices?location.mapID=${mapId}&fields=_id&limit=1`);

            if (devicesDMS != null && devicesDMS.data && Array.isArray(devicesDMS.data)) {
                devicesOnMap = devicesDMS.data.length>0;
            }
            if(!devicesOnMap){
                ICService.instance().delete("/maps/" + mapId)
                    .then(callback)
                    .catch(displayErrorFromAxios.bind(null,dispatch));
            }else{
                callback();
                errorDeleteMap();
            }
        } catch (e) {
            console.log(e);
        }
    };
};

export const updateSiteMapInfo = (mapId,name) => {
    return (dispatch) => {
        return ICService.instance().put("/maps/" + mapId,{
            description:name
        })
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};

export const replaceSiteMap = (mapId,file,zoom) => {
    return (dispatch) => {

        let data = new FormData();
        data.append('wmf',file);
        data.append('maxZoom',zoom);

        return ICSMapService.instance().put("/maps/" + mapId + "/wmf",data)
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};

// For Rendering --

export const loadMapConfiguration = (mapConfiguration,validConfiguration=true) => ({type: types.LOAD_MAP_CONFIG, mapConfiguration,validConfiguration});
export const changeLocalMap = (reportId,map,site) => ({type: types.CHANGE_LOCAL_MAP, reportId,map,site});

// export const requestMapConfiguration = (mapId) => {
//     return (dispatch) => {
//         if(mapId === undefined){
//             return Promise.reject("Invalid map id.");
//         }
//         return SiteViewService.instance().get(MAP_VIEW_URL+"/maps/" + mapId + "/configuration")
//         .then(res=>{
//             dispatch(loadMapConfiguration(res.data));
//             return res.data;
//         })
//         .catch(err=>{
//             var message = (err.response && err.response.data) || err.message;
//             return Promise.reject(message);
//         });
//     };
// };

// export const requestMapCoordinates = (mapId) => {
export const requestMapConfiguration = (mapId) => {
    return async (dispatch) => {

        if (mapId === undefined) {
            return Promise.reject("Invalid map id.");
        }

        const mapConfiguration = await getMapCoordinates(mapId);

        if (mapConfiguration != null && mapConfiguration.extent != null) {
            dispatch(loadMapConfiguration(mapConfiguration));

            return mapConfiguration;
        }
        else{
            return Promise.reject("Error getting map configuration");
        }

        // return SiteViewService.instance().get(MAP_VIEW_URL + "/maps/" + mapId + "/coordinatesConfiguration")
        //     .then(res => {
        //         if (res.data && res.data[0] && res.data[0].coordinates) {
        //             dispatch(loadMapConfiguration(res.data[0].coordinates));
        //             return res.data[0].coordinates;
        //         } else {
        //
        //             const defaultConfiguration = {
        //                 mapCorner1X: 0,
        //                 mapCorner1Y: 256,
        //                 mapCorner2X: 256,
        //                 mapCorner2Y: 0,
        //                 mapPoint1X: 0,
        //                 mapPoint1Y: 0,
        //                 mapPoint2X: 0,
        //                 mapPoint2Y: 0
        //             };
        //
        //             dispatch(loadMapConfiguration(defaultConfiguration, false));
        //             return defaultConfiguration;
        //         }
        //
        //     })
        //     .catch(err => {
        //         var message = (err.response && err.response.data) || err.message;
        //         return Promise.reject(message);
        //     });
    };
};

export const saveMapConfiguration = (mapId, configuration) => {
    return async (dispatch) => {


        if (!Object.keys(configuration).some(key => configuration[key] == null)) {

            let wmfSize = null;
            // let mapInfo = null;
            // let allAppliances = null;

            // let siteId = null;
            // let applianceForSite = null;
            try {
                wmfSize = await getWmfSize(mapId);
                // mapInfo = await SiteViewService.instance().get(MAP_VIEW_URL + "/maps/" + mapId);
                // siteId = mapInfo.data.siteId;
                // allAppliances = await SiteViewService.instance().get(MAP_VIEW_URL + "/appliances");
                //applianceForSite = allAppliances.data.find(appliance => appliance.siteId === siteId);


                const wmfCoordinates = adjustCoordinates(configuration, wmfSize);
                //const mapObject = applianceForSite.configuration.maps.find(map => map.id === mapId);

                // mapObject.mapPoint1X = configuration.mapPoint1X;
                // mapObject.mapPoint1Y = configuration.mapPoint1Y;
                // mapObject.mapPoint2X = configuration.mapPoint2X;
                // mapObject.mapPoint2Y = configuration.mapPoint2Y;
                // mapObject.mapCorner1X = wmfCoordinates.mapCorner1X;
                // mapObject.mapCorner1Y = wmfCoordinates.mapCorner1Y;
                // mapObject.mapCorner2X = wmfCoordinates.mapCorner2X;
                // mapObject.mapCorner2Y = wmfCoordinates.mapCorner2Y;


                //const configurationAppliance={configuration:applianceForSite.configuration};
                // return SiteViewService.instance().put(MAP_VIEW_URL + "/appliances/"+applianceForSite._id,configurationAppliance)
                //     .then(res => {
                //         dispatch(changeOlMap(mapId));
                //     })
                //     .catch(err => {
                //         const message = (err.response && err.response.data) || err.message;
                //         console.log(message);
                //
                //     });


                let mapConfiguration = {
                    coordinates: configuration
                };

                mapConfiguration.coordinates.mapCorner1X = wmfCoordinates.mapCorner1X;
                mapConfiguration.coordinates.mapCorner1Y = wmfCoordinates.mapCorner1Y;
                mapConfiguration.coordinates.mapCorner2X = wmfCoordinates.mapCorner2X;
                mapConfiguration.coordinates.mapCorner2Y = wmfCoordinates.mapCorner2Y;


                return ICService.instance().put(MAP_VIEW_URL + "/maps/" + mapId, mapConfiguration)
                    .then(res => {
                        dispatch(changeOlMap(mapId));
                    })
                    .catch(err => {
                        const message = (err.response && err.response.data) || err.message;
                        console.log(message);

                    });

            } catch (e) {
                console.log(e);
                throw new Error("Cannot get map or appliance information");
            }
        } else {
            console.log('Non valid configuration', configuration);
        }
    };

};

export const saveWidthHeight = (mapId, width, height,coordinates) => {
    return async dispatch => {
        let wmfSize = null;
        let message = null;
        try{
            wmfSize = await getWmfSize(mapId);
            let newWidth = width;
            let newHeight = height;
            let aspectRatio = wmfSize.height / wmfSize.width;

            if (newHeight == null){
                newHeight = newWidth * aspectRatio;
            } else if(newWidth == null){
                newWidth = newHeight / aspectRatio;
            }

            const newRatio = newHeight/newWidth;

            let mapConfiguration = {
                coordinates: coordinates ? {...coordinates} : {
                    mapPoint1X:0,
                    mapPoint1Y:0,
                    mapPoint2X:0,
                    mapPoint2Y:0,
                    mapCorner1X:0,
                    mapCorner1Y:0,
                    mapCorner2X:0,
                    mapCorner2Y:0
                }
            };
            if((aspectRatio / newRatio) === 1) {
                mapConfiguration.coordinates.mapCorner2X = parseFloat(newWidth);
                mapConfiguration.coordinates.mapCorner1Y = parseFloat(newHeight);


                return ICService.instance().put(MAP_VIEW_URL + "/maps/" + mapId, mapConfiguration)
                    .then(res => {
                        dispatch(changeOlMap(mapId));
                        dispatch({type: types.MAP_WIDTH_HEIGHT_ERROR, errorWH: false, errorWHmessage: message, whSuccessful: true})
                    })
                    .catch(err => {
                        const message = (err.response && err.response.data) || err.message;
                        console.log(message);
                        dispatch({type: types.MAP_WIDTH_HEIGHT_ERROR, errorWH: true, errorWHmessage: message, whSuccessful: false})

                    });
            } else {
                message = "The coordinate system is not rectangular.";
                dispatch({type: types.MAP_WIDTH_HEIGHT_ERROR, errorWH: true, errorWHmessage: message, whSuccessful: false})
            }

        } catch (error) {
            console.log(error);
        }
    }
};

export const setErrorWidthHeight = (error, message) => ({type: types.MAP_WIDTH_HEIGHT_ERROR, errorWH: error, errorWHmessage: message});

export const saveLonLatConfiguration = (mapId, coordinates) => {
    return async dispatch => {
        try{
            let mapConfiguration = {
                coordinates: {...coordinates}
            };

            return ICService.instance().put(MAP_VIEW_URL + "/maps/" + mapId, mapConfiguration)
                .then(res => {
                    dispatch(changeOlMap(mapId));
                })
                .catch(err => {
                    const message = (err.response && err.response.data) || err.message;
                    console.log(message);

                });
        } catch (error) {
            console.log(error);
        }
    }
};

function adjustCoordinates(mapConfiguration,wmfSize){

    const wmfWidth=wmfSize.width;
    const wmfHeight=wmfSize.height;

    let diffSize=0;
    let lengthBox=mapConfiguration.extent[2]-mapConfiguration.extent[0];
    let nonAdjustedValue=0;
    if(wmfWidth>wmfHeight){
        diffSize=wmfWidth-wmfHeight;
        lengthBox=mapConfiguration.extent[2]-mapConfiguration.extent[0];
        nonAdjustedValue=wmfWidth;
    }else if(wmfHeight>wmfWidth){
        diffSize=wmfHeight-wmfWidth;
        lengthBox=mapConfiguration.extent[3]-mapConfiguration.extent[1];
        nonAdjustedValue=wmfHeight;
    }
    const diffForSide=diffSize/2;
    const ratio=lengthBox/nonAdjustedValue;
    const leftoverBySide=diffForSide*ratio;

    // console.log("mapConfiguration",mapConfiguration);
    // console.log("diffForSide",diffForSide);
    // console.log("lengthBox",lengthBox);
    // console.log("ratio",ratio);
    // console.log("leftoverBySide",leftoverBySide);

    let mapCorner1X=mapConfiguration.extent[0];
    let mapCorner1Y=mapConfiguration.extent[3];
    let mapCorner2X=mapConfiguration.extent[2];
    let mapCorner2Y=mapConfiguration.extent[1];

    if(wmfWidth>wmfHeight){
        mapCorner1X=mapConfiguration.extent[0];
        mapCorner1Y=mapConfiguration.extent[3]-leftoverBySide;
        mapCorner2X=mapConfiguration.extent[2];
        mapCorner2Y=mapConfiguration.extent[1]+leftoverBySide;
    }else if(wmfHeight>wmfWidth){
        mapCorner1X=mapConfiguration.extent[0]+leftoverBySide;
        mapCorner1Y=mapConfiguration.extent[3];
        mapCorner2X=mapConfiguration.extent[2]-leftoverBySide;
        mapCorner2Y=mapConfiguration.extent[1];
    }

    //console.log("{mapCorner1X,mapCorner1Y,mapCorner2X,mapCorner2Y}",{mapCorner1X,mapCorner1Y,mapCorner2X,mapCorner2Y});
    return {mapCorner1X,mapCorner1Y,mapCorner2X,mapCorner2Y};
}

function getWmfSize(mapId) {

    return SiteViewService.instance().get(MAP_VIEW_URL + "/maps/" + mapId+"/wmfinfo")
    // return ICService.instance().get(MAP_VIEW_URL + "/maps/" + mapId+"/wmfinfo")
        .then(res => {
            if(res.data!=null&&res.data.width&&res.data.height){
                return res.data;
            }
            return null;
        })
        .catch(err => {
            const message = (err.response && err.response.data) || err.message;
            console.log(message);
            return null;

        });
}

// export const requestMapExtent = (mapId) => {
export const requestMapBounds = (mapId) => {
    return (dispatch) => {
        return dispatch(requestMapConfiguration(mapId))
            .then(results=>{

                // let originalBounds;
                if(results!=null){
                    // Original bounds are parsed from the configuration directly.
                    // Assumption: x and y increases right and up, respectively.
                    // originalBounds = [
                    //     MIN(results.mapCorner1X,results.mapCorner2X),
                    //     MIN(results.mapCorner1Y,results.mapCorner2Y),
                    //     MAX(results.mapCorner1X,results.mapCorner2X),
                    //     MAX(results.mapCorner1Y,results.mapCorner2Y)
                    // ];

                    // From Site Manager the extent is saved in a square.
                    return results.extent;

                    // We will need to adjust the bounds since the tiles produce a square image.
                    // (And the maps aren't usually square.)
                    // return getAdjustedBounds(originalBounds);

                }else{
                    // originalBounds = [
                    //     0,0,256,256
                    // ];
                    return null;
                }





            });
    };
};

// export const requestMapBounds = (mapId) => {
//     return (dispatch) => {
//         return dispatch(requestMapConfiguration(mapId))
//         .then(results=>{
//
//             // Original bounds are parsed from the configuration directly.
//             // Assumption: x and y increases right and up, respectively.
//             let originalBounds = [
//                 MIN(results.mapCorner1X,results.mapCorner2X),
//                 MIN(results.mapCorner1Y,results.mapCorner2Y),
//                 MAX(results.mapCorner1X,results.mapCorner2X),
//                 MAX(results.mapCorner1Y,results.mapCorner2Y)
//             ];
//
//             // We will need to adjust the bounds since the tiles produce a square image.
//             // (And the maps aren't usually square.)
//             return getAdjustedBounds(originalBounds);
//
//         });
//     };
// };


// const MIN = (a,b)=>(a>b?b:a);
// const MAX = (a,b)=>(a>b?a:b);

// export function getUnAdjustedBounds(bounds){
//
//     const dY = bounds[3] - bounds[1];
//     const dX = bounds[2] - bounds[0];
//     let d, _bounds = [];
//     // console.log("bounds[3]",bounds[3],"bounds[1]",bounds[1]);
//     // console.log("bounds[2]",bounds[2],"bounds[0]",bounds[0]);
//     // console.log("dY",dY,"dX",dX);
//     if(dY > dX){
//         d = (dY - dX) / 2;
//         // console.log("d",d);
//         _bounds[0] = bounds[0] + d;
//         _bounds[1] = bounds[1];
//         _bounds[2] = bounds[2] - d;
//         _bounds[3] = bounds[3];
//     }else{
//         d = (dX - dY) / 2;
//         // console.log("d",d);
//         _bounds[0] = bounds[0];
//         _bounds[1] = bounds[1] + d;
//         _bounds[2] = bounds[2];
//         _bounds[3] = bounds[3] - d;
//     }
//     return _bounds;
// }

export function getAdjustedBounds(bounds){
    // return bounds;
    const dY = bounds[3] - bounds[1];
    const dX = bounds[2] - bounds[0];
    let d, _bounds = [];
// console.log("bounds[3]",bounds[3],"bounds[1]",bounds[1]);
// console.log("bounds[2]",bounds[2],"bounds[0]",bounds[0]);
// console.log("dY",dY,"dX",dX);
    if(dY > dX){
        d = (dY - dX) / 2;
        // console.log("d",d);
        _bounds[0] = bounds[0] - d;
        _bounds[1] = bounds[1];
        _bounds[2] = bounds[2] + d;
        _bounds[3] = bounds[3];
    }else{
        d = (dX - dY) / 2;
        // console.log("d",d);
        _bounds[0] = bounds[0];
        _bounds[1] = bounds[1] - d;
        _bounds[2] = bounds[2];
        _bounds[3] = bounds[3] + d;
    }
    return _bounds;
}

// export function getAdjustedBounds2(bounds){
// // return bounds;
//     const dY = bounds[3] - bounds[1];
//     const dX = bounds[2] - bounds[0];
//     let d, _bounds = [];
//     // console.log("bounds[3]",bounds[3],"bounds[1]",bounds[1]);
//     // console.log("bounds[2]",bounds[2],"bounds[0]",bounds[0]);
//     // console.log("dY",dY,"dX",dX);
//     if(dY > dX){
//         d = (dY - dX) / 2;
//         // console.log("d",d);
//         _bounds[0] = bounds[0] - d;
//         _bounds[1] = bounds[1];
//         _bounds[2] = bounds[2] + d;
//         _bounds[3] = bounds[3];
//     }else{
//         d = (dX - dY) / 2;
//         // console.log("d",d);
//         _bounds[0] = bounds[0];
//         _bounds[1] = bounds[1] - d;
//         _bounds[2] = bounds[2];
//         _bounds[3] = bounds[3] + d;
//     }
//     return _bounds;
// }


export function updateClusterIndex(clusterIndex,mapConfiguration){
    return (dispatch) => {
        dispatch({type: types.UPDATE_CLUSTER_INDEX,clusterIndex:clusterIndex,mapId:1});
    };
}