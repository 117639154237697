import React from 'react';
import PropTypes from 'prop-types';
//import FlatButton from 'material-ui/FlatButton';
import FlatButton from '@material-ui/core/Button';

import StyledDialog from '../../util/Dialog';

import Scheduler from './scheduler';

class SchedulerDialog extends React.Component {

    constructor(){
        super();

        this.handleSave = this.handleSave.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.handleTimeChange = this.handleTimeChange.bind(this);

        this.state = {time:0};

    }

    static get propTypes(){
        return {
            open: PropTypes.bool,
            onClose: PropTypes.func,
            onError: PropTypes.func,
            onSubmit: PropTypes.func,
            content: PropTypes.any,
            subtitle: PropTypes.string,
            confirmText: PropTypes.string,
            confirmColor: PropTypes.string,
            labelText: PropTypes.string
        };
    }

    static get defaultProps(){
        return {
            open: false,
            onClose:function(){},
            onSubmit:function(){},
            onError:function(){}
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(nextProps.open && !this.props.open){
            this.setState({
                time:0
            });
        }
    }

    handleClose ()  {
        this.props.onClose();
    }

    handleSave(){

        if(this.state.time && this.state.time < new Date().getTime()){
            this.props.onError({
                title:"Invalid Time",
                message:"Please select a time in the future."
            });
            return;
        }

        this.props.onSubmit(this.state.time);
    }

    handleTimeChange(time){
        this.setState({
            time:time
        });
    }

    render() {

        const {
            actions, // eslint-disable-line
            content,
            confirmText,
            confirmColor,
            ...other
        } = this.props;

        const mainButtons = (
            <div>
                <FlatButton label={confirmText || "Confirm"} style={{color:confirmColor || '#00779f'}} onClick={this.handleSave} variant="text">{confirmText || "Confirm"}</FlatButton>
                <FlatButton label="Cancel" onClick={this.handleClose} style={{position: 'absolute', left: 8 ,color:'#777'}} variant="text">Cancel</FlatButton>
            </div>
        );

        const scheduler = (
            <div style={{marginTop:'1em',textAlign:'left',padding:'0.5em',border:'solid #ccc',borderWidth:'1px 0',fontSize:'0.9em'}}>
                <span style={{fontWeight:'bold'}}>{this.props.labelText || "Update"}</span>
                <div style={{marginLeft:'0.5em',padding:'0.5em'}}>
                    <Scheduler onChange={this.handleTimeChange}/>
                </div>
            </div>
        );

        return (
            <StyledDialog
                title={(<div><span>Confirm</span> <span style={{fontWeight:'300',fontSize:'0.9em'}}>{this.props.subtitle}</span></div>)}
                modal={false}
                open={this.props.open}
                onRequestClose={this.handleClose}
                onClose={this.handleClose}
                actions={mainButtons}
                {...other}
                >
                <div style={{width: 360}}>
                    {content}
                    {scheduler}
                </div>
            </StyledDialog>
        );
    }
}

export default SchedulerDialog;
