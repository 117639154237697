import React from 'react';
import PropTypes from 'prop-types';

import {Field, Form} from '../../../util/DataEntryTabularForm';
import ReorderableSelectionList from './ReorderableSelectionList';
import {CallFilterForm, MedianFilterForm, RateFilterForm} from './filter-forms';
import FILTERS from './__tests__/MockProfile';
import {withStyles} from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

const outerTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#00779F',
        },
    },
    shadows: Array(25).fill('none')
});

const ColorLinearProgress = withStyles({
    barColorPrimary: {
        backgroundColor: '#00779f'
    }
})(LinearProgress);

class FetchScreenManagerFilterProfile extends React.Component{
    render(){
        return (
            <div style={{position:'absolute',top:50,right:0,left:0,bottom:50,background:'rgba(0,0,0,0.7)',textAlign:'center', zIndex: '100000'}}>
                <div style={{position:'absolute',top:'50%',transform:'translateY(-50%)',left:0,right:0}}>
                    <Paper style={{display:'inline-block',padding:'2em'}}>
                        Getting Data From the Server...
                        <ThemeProvider theme={outerTheme}>
                            <ColorLinearProgress style={{background:'rgba(0,0,0,0.25)',marginTop:'1em'}}/>
                        </ThemeProvider>
                    </Paper>
                </div>
            </div>
        );
    }
}

// i know I've written this multiple times
function indexByAttribute(array,attribute){
    var o = {};
    array.forEach(item=>{
        o[item[attribute]] = item;
    });

    return o;
}

function shapeProfileWithAcceptedFilters(profile){
    let indexedAcceptedFilters = {};

    DEFAULT_PROFILE.filters.forEach(f=>{
        indexedAcceptedFilters[ f[FILTER_KEY_ATTRIBUTE] ] = {...f};
    });

    let filterList = [];
    let i, l = profile.filters.length;
    for(i=0;i<l;i++){
        let filter = profile.filters[i];
        let key = filter[FILTER_KEY_ATTRIBUTE];
        if(indexedAcceptedFilters[key]){
            filterList.push({...filter});
            delete indexedAcceptedFilters[key];
        }
    }

    filterList = filterList.concat(entriesOf(indexedAcceptedFilters));

    return {
        ...profile,
        filters:filterList
    };

}

function entriesOf(object){
    return Object.keys(object).map(k=>object[k]);
}


/*
███    ███  █████  ██ ███    ██     ███████  ██████  ██████  ███    ███
████  ████ ██   ██ ██ ████   ██     ██      ██    ██ ██   ██ ████  ████
██ ████ ██ ███████ ██ ██ ██  ██     █████   ██    ██ ██████  ██ ████ ██
██  ██  ██ ██   ██ ██ ██  ██ ██     ██      ██    ██ ██   ██ ██  ██  ██
██      ██ ██   ██ ██ ██   ████     ██       ██████  ██   ██ ██      ██
*/


const FILTER_KEY_ATTRIBUTE = "name";

const DEFAULT_PROFILE = {
    profileName:"Default",
    filters: FILTERS
};



export default class ProfileManagerForm extends React.Component{

    static get propTypes(){
        return {
            profiles: PropTypes.arrayOf(
                PropTypes.shape({
                    profileName: PropTypes.string.isRequired,
                    filters: PropTypes.array.isRequired
                })
            ),
            defaultProfile: PropTypes.any
        };
    }

    static get defaultProps(){
        return {
            profiles:[],
        };
    }

    constructor(props){
        super(props);

        this.state = this.getFilterStateFromProfile(props.defaultProfile || DEFAULT_PROFILE);
        this.state.valueSelect=0;
        this.handleSelectFilter = this.handleSelectFilter.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeOrder = this.handleChangeOrder.bind(this);
        this.handleChangeProfile = this.handleChangeProfile.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(this.props.profiles !== nextProps.profiles){
            let profiles=[DEFAULT_PROFILE].concat(nextProps.profiles);
            let indexCurrentProfile = profiles.findIndex(p=>p.profileName===(nextProps.defaultProfile && nextProps.defaultProfile.profileName));
            let stateObject = this.getFilterStateFromProfile(nextProps.defaultProfile || DEFAULT_PROFILE);
            stateObject.valueSelect=indexCurrentProfile;
            this.setState( stateObject);
        }
    }

    getFilterStateFromProfile(profile){
        let _filters = profile.filters;

        return {
            baseProfile: profile.profileName,
            filters: indexByAttribute(_filters,FILTER_KEY_ATTRIBUTE),
            filterOrder: _filters.map(f=>f[FILTER_KEY_ATTRIBUTE]),
            currentFilterKey:_filters[0][FILTER_KEY_ATTRIBUTE],
        };
    }

    getData(){
        const {baseProfile,filterOrder,filters} = this.state;

        return {
            baseProfile: baseProfile,
            name: this.refs.profileName.value,
            filters: filterOrder.map(key=>filters[key])
        };
    }

    handleChangeProfile(event){

        let value = parseInt( event.currentTarget.value );
        let profile = [DEFAULT_PROFILE].concat(this.props.profiles)[value];
        profile = shapeProfileWithAcceptedFilters(profile);
        let stateObject=this.getFilterStateFromProfile(profile);
        stateObject.valueSelect=value;
        this.setState( stateObject);
    }

    handleSelectFilter(filter){
        this.setState({
            currentFilterKey: filter[FILTER_KEY_ATTRIBUTE]
        });
    }

    handleChange(updates){
        const {currentFilterKey,filters} = this.state;

        this.setState({
            filters: {
                ...filters,
                [currentFilterKey]:{
                    ...filters[currentFilterKey],
                    ...updates
                }
            }
        });
    }

    handleChangeOrder(newOrder){
        this.setState({
            filterOrder: newOrder.map(f=>f[FILTER_KEY_ATTRIBUTE])
        });
    }

    renderFilterForm(filter){
        const {baseProfile} = this.state;

        let _key = `${baseProfile}#${filter[FILTER_KEY_ATTRIBUTE]}`;

        switch(filter.type){
            case "median":
                return (<MedianFilterForm {...filter} onChange={this.handleChange} key={_key} />);
            case "rate":
                return (<RateFilterForm {...filter} onChange={this.handleChange} key={_key} />);
            case "call":
                return (<CallFilterForm {...filter} onChange={this.handleChange} key={_key} />);
            default:
                return null;
        }
    }

    render(){
        const {profiles,isFetching} = this.props;
        const {baseProfile,filters,filterOrder,currentFilterKey,valueSelect} = this.state;
        let _filters = filterOrder.map(key=>({
            id: key,
            [FILTER_KEY_ATTRIBUTE]: key,
            label: filters[key].name,
            disabled: !filters[key].enabled
        }));

        let _profiles = [DEFAULT_PROFILE].concat(profiles);
        return (
            <Form fullWidth>
                <Field label={"Filter Profile"}>
                    <select  value={valueSelect}  onChange={this.handleChangeProfile}    >
                        {_profiles.map((p,i)=>(
                            <option
                                key={i}
                                value={i}
                            >
                                {p.profileName}
                            </option>
                        ))}
                    </select>
                    {isFetching?<FetchScreenManagerFilterProfile />:null}
                </Field>

                <Field label={"Save As..."}>
                    <input ref="profileName"/>
                </Field>

                <Field>
                    <div style={{display:'table',minHeight:'5em'}}>
                        <div style={{display:'table-row'}}>
                            <div style={{display:'table-cell', width:'300px', position:'relative',verticalAlign:'top'}}>
                                <div style={{height:'400px'}}>
                                    <div style={{position:'absolute',top:'0.5em',bottom:'0.5em',left:'0.5em',right:'0.5em'}}>
                                        <ReorderableSelectionList
                                            initialItems={_filters}
                                            onSelectItem={this.handleSelectFilter}
                                            onChangeOrder={this.handleChangeOrder}
                                            key={baseProfile}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div style={{display:'table-cell',border:'solid 1px rgba(0,0,0,0.6)',verticalAlign:'top'}}>
                                <div style={{height:'400px',padding:'0 1em 1em', overflow:'auto'}}>
                                    {this.renderFilterForm(filters[currentFilterKey])}
                                </div>
                            </div>
                        </div>

                    </div>

                </Field>

            </Form>
        );
    }
}
