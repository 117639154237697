import Promise from 'promise';

import {displayErrorFromAxios, ICService, ReportService, SiteViewService} from './util';

import WidgetStore from '../utils/widget-definitions/WidgetStore';
import {compileChart} from '../utils/widget-definitions/compile-chart';
import SystemDashboard from '../utils/dashboard-definitions/SystemDashboard';
import TagsDashboard from '../utils/dashboard-definitions/TagsDashboard';

export const requestDashboard = (dashboardId) => {
    return dispatch =>{
        return Promise.resolve(getLocalDashboard(dashboardId))
         .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};
/*
function getServerDashboard(id){
    return ReportService.instance().get(`/dashboards/${id}`)
     .then(response => response.data);
}*/

function getLocalDashboard(id){
    if(parseInt(id) === 1){
        return TagsDashboard;
    }else{
        return SystemDashboard;
    }
}



export const requestDashboardWidget = (widgetId,queryParameters) => {
    return dispatch =>{
        return Promise.resolve( getLocalWidget(widgetId,queryParameters) )
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};

export const requestDashboardWidgetDefinition = (widgetId)=>{
    return (/*dispatch*/) =>{
        return Promise.resolve(WidgetStore.get(widgetId));
    };
};

export const requestDashboardWidgetData = (definition)=>{
    return (/*dispatch*/) => {
        return Promise.resolve(getLocalWidgetFromDefinition(definition,{}));
    };
};

/*
function getServerWidget(id){
    return ReportService.instance().get(`/dashboards/widgets/${id}`)
    .then(response=>response.data);
}*/

function getLocalWidget(id,queryParameters){
    const def = WidgetStore.get(id);

    return getLocalWidgetFromDefinition(def,queryParameters);
}

function getLocalWidgetFromDefinition(def,queryParameters){
    if(!def.dataProvider) return def;

    let requestData = () => requestDashboardData(def.dataProvider,queryParameters);
    if(!queryHasAllRequiredParameters(queryParameters,def.parameters)){
        requestData = ()=>Promise.resolve([]);
    }

    switch(def.type){
        case "GRAPH":
            return requestData()
            .then(data => compileChart(def.data,data))
            .then(x=>({...def, ...x}));
        case "MAP":
        case "TABLE":
        default:
            return requestData()
            .then(data=>({...def, data}));
    }
}

function queryHasAllRequiredParameters(query,parameters){

    if(!parameters) return true;

    let i, l = parameters.length;
    for(i=0;i<l;i++){
        let par = parameters[i];
        if(par.required && (query && query[par.name]) === undefined) return false;
    }
    return true;
}

export const requestDashboardFilterData = (dataProvider,qp={}) => {
    return (/*dispatch*/) =>{
        return requestDashboardData(dataProvider,qp);
    };
};

function requestDashboardData(dataProvider,queryParameters){
    const dataProviderFn = getDataProviderFn(dataProvider);
    return Promise.resolve(dataProviderFn(queryParameters));
}

/*
function logIntercept(){
    let outer = Array.prototype.slice.call(arguments,0);
    return function(x) {

        console.log.apply( // eslint-disable-line
            null,
            outer.concat([x])
        );

        return x;
    };
}*/

function getDataProviderFn({service,url}){
    switch(service){

        case "report":
            return (params)=>{
                return ReportService.instance().get(url,{params})
                .then(response=>response.data);
            };

        case "ics":
            return (params)=>{
                return ICService.instance().get(url,{params})
                .then(response=>response.data);
            };

        case "ics-appliance-status":
            return ()=>{
                return ICService.instance().get(url)
                .then(response=>response.data)
                .then(getTallyByStatus)
                .then(formatStatusTally);
            };

            case "report-appliance-status":
            return ()=>{
                return SiteViewService.instance().get(url)
                .then(response=>response.data)
                .then(getTallyByStatus)
                .then(formatStatusTally);
            };
        default:
            return null;
    }
}

const getTallyByStatus = rawData => rawData.reduce(
    (tally,record)=>{
        if(!(record.serviceStatus in tally)){
            return tally;
        }
        return {
            ...tally,
            [record.serviceStatus]: tally[record.serviceStatus] + 1
        };
    },{
        "offline":0,
        "active":0,
        "inactive":0,
        "failed":0,
        "activating":0
    }
);

const formatStatusTally = tallies => {
    return [
        {
            "status": "Running",
            "count": tallies["active"]
        },{
            "status": "Activating",
            "count": tallies["activating"]
        },{
            "status": "Stopped",
            "count": tallies["inactive"]
        },{
            "status": "Offline",
            "count": tallies["offline"],
            "color": "red"
        },{
            "status": "Failed",
            "count": tallies["failed"],
            "color": "red"
        },{
            "status": "Total",
            "count": Object.keys(tallies).reduce((cnt,tK)=>cnt+tallies[tK],0)
        }
    ];
};

export const getReportWidgetData = (widgetData, params) => {
    return (dispatch) => {
        ReportService.instance().get(widgetData.url, {params})
            .then(res => {
                dispatch({type: widgetData.type, data: res.data})
            })
    }
};
export const getReportZlaStatusData = (widgetData, params) => {
    return (dispatch) => {
         SiteViewService.instance().get(widgetData.url)
            .then(response=>response.data)
            .then(getTallyByStatus)
            .then(formatStatusTally).then(res =>{
             dispatch({type: widgetData.type, data: res})
         });
    }
};