import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import CrossBrowserTable from '../tableComponent/CrossBrowserTable';
import moment from "moment/moment";
import {useSelector, useDispatch} from "react-redux";
import {testDevice} from '../../../../actions';

const useStyles = makeStyles({
    paperDialog: {
        overflowY: "visible",
        marginTop: "4em"
    },
    scrollPaper:{
        alignItems: "flex-start"
    }
});

const TestingProcessDialog = (props) => {
    const {open, closeDialog, deviceName, deviceId} = props;
    const [processTest, setProcessTest] = useState(false);
    const [data, setData] = useState([]);
    const [seconds, setSeconds] = useState(0);
    const [reads, setReads] = useState(0);

    const nameDevice = useSelector(state => (state.deviceManager.templateForm.template) ? state.deviceManager.templateForm.template.title : deviceName);
    const idDevice = useSelector(state => (state.deviceManager.templateForm.template) ? state.deviceManager.templateForm.template._id : deviceId);

    const dispatch = useDispatch();
    const classes = useStyles();
    const styleButton = {backgroundColor:'#4c4c4c',color:'#fff',border:'none',fontSize:'12px',padding:'0.75em',width:'150px',textTransform: 'none'};

    const renderCloseButton = () => {
        return (
            <div style={{position:'absolute',top:'-0.5em',right:'-0.5em'}}>
                <IconButton aria-label="close"
                    size="small"
                    onClick={closeDialog}
                    style={{cursor:'pointer',position:'relative',zIndex:'100',backgroundColor:'#000'}}
                    >
                    <CloseIcon style={{color: '#fff'}} />
                </IconButton>
            </div>
        );
    }

    useEffect(() => {
        let interval = null;
        if (processTest) {
          interval = setInterval(() => {

            if(seconds % 5 === 0){
                const currentTime = moment().format('MMMM Do YYYY, h:mm:ss a');
                const afterTime = moment().add(1, 'seconds').format('MMMM Do YYYY, h:mm:ss a');    
                const itemData = {tagID:'[Tag121827]', count:170, rssi:-80, firstSeen: currentTime, lastSeen: afterTime, antenna:'Antenna 1'};  
                setData(oldData => [...oldData, itemData]);
                setReads(reads + 1);
            }
            setSeconds(seconds => seconds + 1);
          }, 1000);
        } else if (!processTest && seconds !== 0) {
          clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [processTest, seconds, reads]);


    const getNumberFormat = number => {
        return number<=9 ? '0'+number : number.toString()
    }

    const handleStartButton = () => {
        setProcessTest(!processTest);
        const args = {action:'start'};
        dispatch(testDevice(idDevice, nameDevice,'test', args));
    }

    const handleStopButton = () => {
        setProcessTest(!processTest);
        const args = {action:'cancel'};
        dispatch(testDevice(idDevice, nameDevice,'test', args));
    }

    const handleBackButton = () => {
        const args = {action:'cancel'};
        dispatch(testDevice(idDevice, nameDevice,'test', args));
        closeDialog();
    }

    const handleDoneButton = () => {
        const args = {action:'done'};
        dispatch(testDevice(idDevice, nameDevice,'test', args));
        closeDialog();
    }

    const getTime = () => {
        let totalSeconds = seconds;
        let hoursCalc = Math.floor(totalSeconds / 3600);
        totalSeconds %= 3600;
        let minutesCalc = Math.floor(totalSeconds / 60);
        let secondsCalc = totalSeconds % 60;
        return `${getNumberFormat(hoursCalc)}:${getNumberFormat(minutesCalc)}:${getNumberFormat(secondsCalc)}`
    }


    return (
        <Dialog
            fullWidth
            maxWidth={'md'}
            open={open}
            disableBackdropClick={true}
            onClose={closeDialog}
            classes={{paper:classes.paperDialog, scrollPaper: classes.scrollPaper}}>
            <DialogTitle style={{paddingBottom:0, fontSize:'0.8em'}}>
                <div style={{borderBottom:'1px solid #000'}}>
                    <span>{`${nameDevice}: `}</span>
                    <span style={{fontWeight:'bold'}}>Testing process</span>
                </div>
            </DialogTitle>
            {renderCloseButton()}
            <DialogContent style={{fontSize:'0.9em',display:'flex',flexDirection:'column',maxHeight:'100%'}}>
                <div style={{border:'1px solid #000',flexBasis: 'auto',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',padding:'0.25em'}}>
                    <div>
                        <button style={styleButton} onClick={!processTest ? handleStartButton: handleStopButton}>
                            {!processTest?'Start':'Stop'}
                        </button>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',flexWrap: 'no-wrap',alignItems:'center'}}>
                        <span style={{fontSize:'24px',fontWeight:'bold'}}>0</span>&nbsp;&nbsp;
                        <span>Antennas</span>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',flexWrap: 'no-wrap',alignItems:'center'}}>
                        <span style={{fontSize:'24px',fontWeight:'bold'}}>0</span>&nbsp;&nbsp;
                        <span>Tags</span>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',flexWrap: 'no-wrap',alignItems:'center'}}>
                        <span style={{fontSize:'24px',fontWeight:'bold'}}>{reads}</span>&nbsp;&nbsp;
                        <span>Reads</span>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',flexWrap: 'no-wrap',alignItems:'center'}}>
                        <span style={{fontSize:'24px',fontWeight:'bold'}}>{reads > 0?'1':'0'}</span>&nbsp;&nbsp;
                        <span>Reads/sec</span>
                    </div>
                    <div style={{display:'flex',flexDirection:'column'}}>
                        <div><span>Running Time</span></div>
                        <div><span>{getTime()}</span></div>
                    </div>
                </div>
                {data.length > 0 &&
                <div style={{flex:'1 1 200px',marginTop: '10px',width:'100%',backgroundColor:'#fff'}}>
                    <SimpleTableComponent data={data} />
                </div>}
            </DialogContent> 
            <DialogActions>
                <button style={{...styleButton, width:'auto',backgroundColor:'#fff',color:'#007CB0'}} 
                        onClick={handleBackButton} component="span">
                    &#60; &nbsp; Back
                </button>
                <button style={{...styleButton, background: '#007CB0',color: '#fff'}} 
                        onClick={handleDoneButton} component="span">
                        Testing Done
                </button>
            </DialogActions>            
        </Dialog>
    );
}

export default TestingProcessDialog;


const SimpleTableComponent = ({data}) => {

    let headerRow = [{key:"tagID",value:"TagID",clickable:false,width:150},
                    {key:"count",value:"Count",clickable:false,width:80},
                    {key:"rssi",value:"RSSI",clickable:false,width:80},
                    {key:"firstSeen",value:"First seen",clickable:false,width:140},
                    {key:"lastSeen",value:"Last seen",clickable:false,width:140},
                    {key:"antenna",value:"Antenna",clickable:false,width:80}];

    return (
        <CrossBrowserTable
            data={data}
            showCheckColumn={false}
            headerRow={headerRow}
            rowCount={data.length}
            selectedRows={{}}
            onClickRow={()=>{}}
            onSelectAll={()=>{}}
            modernBrowser={true}
            appliedFilters={()=>null}
        />
    );
}