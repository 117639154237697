import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import ThemeProvider from "@material-ui/styles/ThemeProvider/ThemeProvider";
import createMuiTheme from "@material-ui/core/es/styles/createMuiTheme";
import {withStyles} from "@material-ui/core";

const ColorLinearProgress = withStyles({
    barColorPrimary: {
        backgroundColor: '#00779f'
    }
})(LinearProgress);
    
const outerTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#00779F',
        },
    },
    shadows: Array(25).fill('none')
});

export default function ProgressDetails({width, height}) {
    return (
        <div className="modal-progress" style={{width:`${width}px`, height: `${height}px`}}>
            <div style={{backgroundColor:'#fff', padding: '0.5em'}}>
                <span style={{fontSize: '12px'}}>Getting data from server</span>
                <ThemeProvider theme={outerTheme}>
                    <ColorLinearProgress mode="indeterminate" style={{background:'rgba(0,0,0,0.25)',marginTop:'1em'}}/>
                </ThemeProvider>
            </div>
        </div>
    );
}