import React from 'react';
import PropTypes from 'prop-types';
import StyledDialog, {CancelButton, SaveButton} from '../util/Dialog';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../../actions';

import {DELETE_SAVED_REPORT} from '../../constants/DataEntryForms';
import {withRouter} from 'react-router-dom';

class SaveReportDialog extends React.Component{
    constructor(){
        super();
        this.handleClose = this.handleClose.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.redirectToBaseReport = this.redirectToBaseReport.bind(this);
    }
    static get propTypes(){
        return {
            open: PropTypes.bool,
            close: PropTypes.func.isRequired,
            name: PropTypes.string,
            baseReportId: PropTypes.string,
            showError: PropTypes.func.isRequired,
            deleteReport: PropTypes.func.isRequired,
            history: PropTypes.any.isRequired
        };
    }
    shouldComponentUpdate(nextProps){
        return (nextProps.open || this.props.open);
    }

    redirectToBaseReport(){
        const {history,baseReportId} = this.props;
        history.push("/reports/"+baseReportId);
    }

    handleSave(){

        this.props.deleteReport()
        .then(()=>{
            this.handleClose();
            this.redirectToBaseReport();
        });
    }
    handleClose(){
        this.props.close();
    }
    render() {
        const {name} = this.props;

        return (
            <StyledDialog
                title={"Delete Saved Report"}
                onClose={this.handleClose}
                contentStyle={{width: 360}}
                {...this.props}
                actions={
                    <>
                        <SaveButton onClick={this.handleSave} label="Delete"/>
                        <CancelButton style={{position: 'absolute', left: 8, bottom: 8}} onClick={this.handleClose} />
                    </>
                }
                >
                Permanently delete the saved report '{name}'?
            </StyledDialog>
        );
    }
}



const mapStateToProps = state => ({
    name: (state.report.savedReportId && state.user.savedReports[state.report.savedReportId].name) || "",
    baseReportId: state.report.reportId,
    open: state.dataEntry.open && state.dataEntry.activeForm === DELETE_SAVED_REPORT
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        close:actions.closeDataEntry,
        showError: actions.displayErrorDialog,
        deleteReport: actions.deleteSavedReport
    },dispatch)
});


export default withRouter( connect(mapStateToProps,mapDispatchToProps)(SaveReportDialog) );
