import * as types from '../constants/ActionTypes';
import {CLOSE_DATAENTRY_FORM, UPDATE_SERVER_LOGS_DOWNLOAD_STATUS} from '../constants/ActionTypes';
import {serverLogsDownloadStatus as statusValues} from "../constants/Appliances";

import {closeDataEntry,displayErrorDialog, openDataEntry} from './general';

import {displayErrorFromAxios, ICService, SiteViewService} from './util';
import {MAP_VIEW_URL, SITE_VIEW_HOST} from '../constants/Misc';
/*
 ██████  ███████ ███    ██ ███████ ██████   █████  ██
██       ██      ████   ██ ██      ██   ██ ██   ██ ██
██   ███ █████   ██ ██  ██ █████   ██████  ███████ ██
██    ██ ██      ██  ██ ██ ██      ██   ██ ██   ██ ██
 ██████  ███████ ██   ████ ███████ ██   ██ ██   ██ ███████
*/

export const openApplianceDataEntry = (form,uuids)=>{
    return (dispatch,getState) => {

        var appliances = getState().appliances.data;

        var selectedAppliances = [];

        if(uuids){
            appliances.forEach(appliance=>{
                if(uuids.indexOf(appliance._id)>=0){
                    selectedAppliances.push({...appliance,uuid:appliance._id})
                }
            });

            // selectedAppliances = uuids.map( id => appliances.find(app=>app._id === id))
            //                           .map( v=>({...v,uuid:v._id}) );
        }

        dispatch(openDataEntry(form,{appliances:selectedAppliances}));
    };
};

export const toggleAppliance = (uuid) => ({type:types.TOGGLE_SELECT_APPLIANCE, uuid});
export const toggleAllAppliances = () => ({type:types.TOGGLE_SELECT_ALL_APPLIANCES});

/*
 █████       ██  █████  ██   ██
██   ██      ██ ██   ██  ██ ██
███████      ██ ███████   ███
██   ██ ██   ██ ██   ██  ██ ██
██   ██  █████  ██   ██ ██   ██
*/

export const loadAppliances = (data) => ({type: types.LOAD_APPLIANCES, data});

export const requestAppliances = ()=> {
    return (dispatch) => {

        // Actually not convinced we need to dispatch an event... UI doesn't really do much with it.
        dispatch({type:types.REQUEST_APPLIANCES});

        SiteViewService.instance().get(MAP_VIEW_URL+"/appliances/status",{
            params:{

            },
            headers:{
                'Content-Type':'application/json'
            }
        })
        .then(response => {
            dispatch(loadAppliances(response.data));
        })
        // .catch(displayErrorFromAxios.bind(null,dispatch));
        .catch(e => {
                let message = e.message;
                if(e.code === "ECONNABORTED"){
                    let msg = e.message.match(/\d/g).join("");
                    msg = msg / 60000;
                    message = "Timeout of " + msg + "min exceeded. Please logout and log back in";
                }
                dispatch( displayErrorDialog({
                    message: message
                }));
            });
    };
};


export const downloadServerLogsFile = (uuid) => {
    return (dispatch) => {
        let getFile = new Promise((resolve, reject) => {
            setTimeout(() => {
                window.location.href = `${SITE_VIEW_HOST}/server/logs/${uuid}?jwt=${localStorage.getItem('jwtToken')}`;
                resolve();
            }, 100);
        });
        return getFile.then(()=>{
            dispatch({type:CLOSE_DATAENTRY_FORM});
        })
        .catch(displayErrorFromAxios.bind(null, dispatch));
    };
};

let intervalStatusLogs=null;
export const requestServerLogs = () => {
    return (dispatch) => {

        let data = {uuid: null};
        serverLogsStatus(null).then(response => {

                let status = response.data.status;
                //console.log("status reques logs",status)
                dispatch({type:UPDATE_SERVER_LOGS_DOWNLOAD_STATUS,serverLogsDownloadStatus:status});
                if (status === statusValues.FINISHED) {

                    SiteViewService.instance().post('/server/logs', data)
                        .then(responseRequestLogs => {
                            let status = responseRequestLogs.data.status;
                            let uuid = responseRequestLogs.data.uuid;
                            dispatch({type:UPDATE_SERVER_LOGS_DOWNLOAD_STATUS,serverLogsDownloadStatus:status});
                            intervalStatusLogs=setInterval(()=>dispatch(checkStatusLogs(uuid)),3000);

                        })
                        .catch(displayErrorFromAxios.bind(null, dispatch));
                }
                else {
                   // dispatch({type:UPDATE_SERVER_LOGS_DOWNLOAD_STATUS,serverLogsDownloadStatus:status});
                }
            }
        );
    };
};


const checkStatusLogs = (uuid) => {

    return (dispatch) => {

        serverLogsStatus(uuid).then(response => {
            let status = response.data.status;

            if (status !== statusValues.IN_PROGRESS) {

                dispatch({type: UPDATE_SERVER_LOGS_DOWNLOAD_STATUS, serverLogsDownloadStatus: status});
                if (intervalStatusLogs != null) {
                    clearInterval(intervalStatusLogs);
                    intervalStatusLogs = null;
                }
                if (status === statusValues.ZIP_READY) {
                    dispatch(downloadServerLogsFile(uuid));
                }
                else{
                    dispatch({type: UPDATE_SERVER_LOGS_DOWNLOAD_STATUS, serverLogsDownloadStatus: statusValues.ERROR});
                }
            }
        });
    }
};

export const resetServerLogsStatus=()=>({type:UPDATE_SERVER_LOGS_DOWNLOAD_STATUS,serverLogsDownloadStatus:null});

const serverLogsStatus = (uuid) => {
    return SiteViewService.instance().get('/server/logs/status?uuid='+uuid);
};

export const deleteAppliance = ({uuid})=> {
    return (dispatch) => {

        ICService.instance().delete("/appliances/"+uuid)
        .then(() => {
            dispatch(closeDataEntry());
            dispatch(toggleAppliance(uuid));
            dispatch(requestAppliances());
        })
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};

export const updateAppliance = ({name,uuid,publicKey,siteId})=> {
    return (dispatch) => {


        let data = {
            publicKey,
            name,
            siteId
        };
        if(!data.publicKey) delete data.publicKey; // don't include if it shouldn't change.


        ICService.instance().put("/appliances/"+uuid,data)
        .then(() => {
            dispatch(closeDataEntry());
            dispatch(requestAppliances());
        })
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};

export const registerAppliance = ({name,uuid,publicKey,siteId})=> {
    return (dispatch) => {

        dispatch({type:types.REGISTER_APPLIANCE, name, uuid, publicKey, siteId});

        let data = {
            _id:uuid,
            publicKey,
            name
        };

        if(siteId){
            data.siteId = siteId;
        }

        ICService.instance().post("/appliances",data)
        .then(() => {
            dispatch(closeDataEntry());
            dispatch(requestAppliances());
        })
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};
/*
███████ ██ ██████  ███    ███ ██     ██  █████  ██████  ███████
██      ██ ██   ██ ████  ████ ██     ██ ██   ██ ██   ██ ██
█████   ██ ██████  ██ ████ ██ ██  █  ██ ███████ ██████  █████
██      ██ ██   ██ ██  ██  ██ ██ ███ ██ ██   ██ ██   ██ ██
██      ██ ██   ██ ██      ██  ███ ███  ██   ██ ██   ██ ███████

██    ██ ███████ ██████  ███████ ██  ██████  ███    ██ ███████
██    ██ ██      ██   ██ ██      ██ ██    ██ ████   ██ ██
██    ██ █████   ██████  ███████ ██ ██    ██ ██ ██  ██ ███████
 ██  ██  ██      ██   ██      ██ ██ ██    ██ ██  ██ ██      ██
  ████   ███████ ██   ██ ███████ ██  ██████  ██   ████ ███████
*/

export const loadFirmwareVersions = (data) => ({type:types.LOAD_FIRMWWARE_VERSIONS, data});

export const requestFirmwareVersions = () => {
    let endpoint = "/rpms/ZLA/versions";
    if (window.location.href.indexOf("mwe1661=true") !== -1) {
        endpoint = "/rpms/zebramwe/zlscore/versions";
    }

    return (dispatch) => {
        ICService.instance().get(endpoint)
        .then(response => {
            dispatch(loadFirmwareVersions(response.data));
        })
        .catch( () => {
			 //MWE-1197: now this should just return an empty list
			 console.log("Failed to load available firmware versions.");
			 return {type:types.LOAD_FIRMWWARE_VERSIONS, data:[] };
		});
    };
};
