import React from 'react';
import PropTypes from 'prop-types';
import Paper from "@material-ui/core/Paper";

import {Link} from 'react-router-dom';


import IconButton from '@material-ui/core/IconButton';
import LookupIcon from '@material-ui/icons/FindInPage';
import MapWidget from './widgets/MapWidget';
import FloorMapWidget from './widgets/FloorMapWidget';
import QuickStat from './widgets/QuickStat';
import Chart from './widgets/Chart';
import CustomTable from './widgets/CustomTable';

import WidgetTitleBar, {TITLE_BAR_TOP} from './widgets/util/WidgetTitleBar';

import ParameterSection from './widgets/util/ParameterSection';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../../actions';
import ZLAStatus from "./widgets/ZLAStatus";
import {TAG_BLINK_HEALTH, ZLA_STATUS, DEVICE_ALERT} from "./widgets/util/DashboardConstants";
import TagHealth from './widgets/TagHealth';
import DevicesWidget from './widgets/DevicesWidget';

class DrillDownButton extends React.Component{
    static get propTypes(){
        return {
            to: PropTypes.string
        };
    }

    static get defaultProps(){
        return {
            to:"/landing"
        };
    }

    render(){
        return (
            <Link to={this.props.to}>
                <IconButton
                    /*iconStyle={{color:'#777'}}*/
                    className={"edit-button"}
                    >
                    <LookupIcon />
                </IconButton>
            </Link>
        );
    }
}


class Tile extends React.Component{
    static get propTypes(){
        return {
            x: PropTypes.number,
            y: PropTypes.number,
            h: PropTypes.number,
            w: PropTypes.number,
            children: PropTypes.any,
            gridHeight: PropTypes.number.isRequired,
            gridWidth: PropTypes.number.isRequired,
            classDashboard: PropTypes.string
        };
    }

    render(){

        // const {x,y,h,w,gridWidth,gridHeight} = this.props;
        const {classDashboard} = this.props;

        /*const left = (100 * x / gridWidth)+"%";
        const top = (100 * y / gridHeight)+"%";
        const height = (100 * h / gridHeight)+"%";
        const width = (100 * w / gridWidth)+"%";*/

        return (
            <div className={"tile " + classDashboard}>
                <Paper className="filled-up" style={{position:'relative'}}>
                    {this.props.children}
                </Paper>
            </div>
        );
    }
}

class DumbTileComponent extends React.Component{
    static get propTypes(){
        return {
            id: PropTypes.any,
            requestDashboardWidget: PropTypes.func.isRequired
        };
    }

    constructor(){
        super();
        this.state = {
            widget:{}
        };

        this.handleParameterChange = this.handleParameterChange.bind(this);
    }

    componentDidMount(){
        this.reloadComponent(this.props.id);
        this.resizeWidgetArea();
    }

    componentDidUpdate(){
        this.resizeWidgetArea();
    }

    resizeWidgetArea(){
        this.refs.widgetSection.style.top = this.refs.parameterSection.offsetHeight+'px';
    }

    componentWillUnmount(){
        this.unmounted = true;
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(this.props.id !== nextProps.id){
            this.reloadComponent(nextProps.id);
        }
    }

    reloadComponent(id,queryParameters){
        const parameterValues = {
            ...this.state.parameterValues,
            ...queryParameters
        };
        this.props.requestDashboardWidget(id,parameterValues)
        .then(widget=>{
            if(this.unmounted) { // callback will routinely return after unmounting...
                return;
            }
            this.setState({
                widget,
                parameterValues
            });
        });
    }

    renderComponent(widget){

        const {parameterValues} = this.state;

        switch(widget.type){

            case "MAP":
                return (<MapWidget data={widget.data} title={widget.title} pinType={widget.pinType} />);

            case "FLOOR-MAP":
                return (
                    <FloorMapWidget
                        data={widget.data}
                        pinType={widget.pinType}
                        parameterValues={parameterValues}
                        onMapChange={this.handleParameterChange}
                    />
                );

            case "GRAPH":
                return (<Chart chartObject={widget.data} title={widget.title}/>);

            case "INFOBOX":
                return (<QuickStat {...widget.data} />);

            case "TABLE":
                return (
                    <CustomTable
                        data={widget.data}
                        columns={widget.columns}
                        hasParameters={!!widget.parameters}
                        {...widget}
                    />
                );

            case "AS-IS":
                return widget.data;

            default:
                return null;
        }
    }

    render(){
        const {widget} = this.state;
        if(!widget) return null;

        return (
            <div style={{position:'absolute',top:0,right:0,left:0,bottom:0}}>
                <WidgetTitleBar title={widget.title} />
                <div style={{position:'absolute',top:TITLE_BAR_TOP,right:0,left:0,bottom:0}}>

                    <input type="hidden" value={(widget.parameters||[]).length} />
                    <div style={{position:'absolute',zIndex:1,top:0,left:0,right:0}} ref="parameterSection">
                        <ParameterSection parameters={widget.parameters} onChange={this.handleParameterChange}/>
                    </div>
                    <div style={{position:'absolute',bottom:0,left:0,right:0,top:0}} ref="widgetSection">
                        {this.renderComponent(widget)}
                    </div>

                </div>
                {widget.reportLink ? (<DrillDownButton to={widget.reportLink} />) : null}
            </div>
        );
    }

    handleParameterChange(queryParameters){
        this.reloadComponent(this.props.id,queryParameters);
    }
}


const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        requestDashboardWidget:actions.requestDashboardWidget,
    },dispatch)
});

const TileComponent = connect(null,mapDispatchToProps)(DumbTileComponent);


class TileLayout extends React.Component{

    static get propTypes(){
        return {
            layout: PropTypes.array,
            components: PropTypes.object,
            height: PropTypes.number,
            width: PropTypes.number
        };
    }

    static get defaultProps(){
        return {
            layout: [],
            components:{},
            height:3,
            width:4
        };
    }

    render(){

        const {layout,height,width} = this.props;

        const _tiles = layout.map((v) => {

                switch (v.componentId) {
                    case TAG_BLINK_HEALTH.id :
                        return (<TagHealth key={v.componentId} />);
                    case ZLA_STATUS.id:
                        return (<ZLAStatus key={v.componentId}/>);
                    case DEVICE_ALERT.id:
                        return (<DevicesWidget key={v.componentId}/>);
                    default:
                        return (<Tile
                            key={`${height}-${width}-${v.x}-${v.y}-${v.height}-${v.width}`}
                            x={v.x}
                            y={v.y}
                            w={v.width}
                            h={v.height}
                            classDashboard={v.classDashboard}
                            gridHeight={height}
                            gridWidth={width}
                        >
                            <TileComponent id={v.componentId}/>
                        </Tile>);
                }
            }
        );


        return (
            <div className="filled-up tile-layout-class">
                {_tiles}
            </div>
        );
    }
}

export default TileLayout;
