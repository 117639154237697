import React from 'react';
import PropTypes from 'prop-types';

import StyledDialog, {CancelButton, SaveButton} from '../../../util/Dialog';

import {Field, Form} from '../../../util/DataEntryTabularForm';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../../../../actions';
import {USERMGMT_DELETE_GROUP} from '../../../../constants/DataEntryForms';


class DeleteUserGroupDialog extends React.Component {

    constructor(){
        super();

        this.handleSave = this.handleSave.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    static get propTypes(){
        return {
            open: PropTypes.bool,
            closeDataEntry: PropTypes.func.isRequired,
            data: PropTypes.object,
            group: PropTypes.shape({
                groupName: PropTypes.string,
                id: PropTypes.string.isRequired
            }),
            deleteUserGroup: PropTypes.func.isRequired
        };
    }

    static get defaultProps(){
        return {
            open: false,
            data:{
                appliances:[]
            }
        };
    }

    handleClose ()  {
        this.props.closeDataEntry();
    }

    handleSave(){

        this.props.deleteUserGroup(this.props.group.id)
        .then(this.props.closeDataEntry);
    }

    renderForm(){

        const group = this.props.group;

        return (
            <Form fullWidth>
                <Field label={"Group Name"}>
                    {group && group.groupName}
                </Field>
            </Form>
        );
    }

    render() {

        const {
            actions, // eslint-disable-line
            ...other
        } = this.props;

        return (
            <StyledDialog
                title="Delete User Group"
                open={this.props.open}
                onClose={this.handleClose}
                actions={
                    <>
                        <CancelButton style={{position: 'absolute', left: 8, bottom: 8}} onClick={this.handleClose} id={"del_cancel_btn"}/>
                        <SaveButton label={"Delete"} id={"del_save_btn"} onClick={this.handleSave} />   
                    </>
                }
                contentStyle={{display:'inline-block',textAlign:'left',width: 360}}
                {...other}
                >
                    {this.renderForm()}             
            </StyledDialog>
        );
    }
}


/*
 ██████  ██████  ███    ██ ███    ██ ███████  ██████ ████████     ████████  ██████
██      ██    ██ ████   ██ ████   ██ ██      ██         ██           ██    ██    ██
██      ██    ██ ██ ██  ██ ██ ██  ██ █████   ██         ██           ██    ██    ██
██      ██    ██ ██  ██ ██ ██  ██ ██ ██      ██         ██           ██    ██    ██
 ██████  ██████  ██   ████ ██   ████ ███████  ██████    ██           ██     ██████

███████ ████████  █████  ████████ ███████
██         ██    ██   ██    ██    ██
███████    ██    ███████    ██    █████
     ██    ██    ██   ██    ██    ██
███████    ██    ██   ██    ██    ███████
*/


const mapStateToProps = state => ({
    open: state.dataEntry.open && state.dataEntry.activeForm === USERMGMT_DELETE_GROUP,
    group: (state.dataEntry.activeForm === USERMGMT_DELETE_GROUP || undefined) && state.dataEntry.initialFormData.group
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        closeDataEntry: actions.closeDataEntry,
        deleteUserGroup: actions.deleteUserGroup
    },dispatch)
});

export default connect(mapStateToProps,mapDispatchToProps)(DeleteUserGroupDialog);
