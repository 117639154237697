import React from 'react';
import PropTypes from 'prop-types';
import Promise from 'promise';
import equal from 'deep-equal';
import {MultiSelectFilter} from '../../../util/report/filter/MultiSelectFilter';
import {DumbDateTimeFilter} from '../../../util/report/filter/DatetimeFilter';
import {getTimes, PERIOD} from '../../../util/report/filter/util';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../../../../actions';

const NOP = ()=>{};

// WrappedComponent should be able to handle / use the following props:
// - options,
// - name,
// - onChange
const fetchOptionsAsync = (WrappedComponent)=>{
    return class AsyncSelect extends React.Component{
        static get propTypes(){
            return {
                name: PropTypes.string,
                fetchOptions: PropTypes.func.isRequired,
                onChange: PropTypes.func,
                params: PropTypes.object,
                providers: PropTypes.array,
                includeFrontOptions: PropTypes.array,
                xmlMultiSelect: PropTypes.bool
            };
        }

        static get defaultProps(){
            return {
                onChange: ()=>{},
                providers:[],
                includeFrontOptions:[],
                xmlMultiSelect: false
            };
        }

        constructor(){
            super();
            this.state = {
                options:[]
            };
        }

        componentDidMount(){
            const {name,params} = this.props;
            this.refreshOptions(name,params);
        }

        UNSAFE_componentWillReceiveProps(nextProps){
            let dirty = false;

            let i, l = nextProps.providers.length;
            for(i = 0; i < l; i++){
                let pr = nextProps.providers[i];
                if(this.props.params[pr] !== nextProps.params[pr]){
                    dirty = true;
                    break;
                }
            }

            if(dirty){
                this.refreshOptions(nextProps.name,nextProps.params);
            }
        }

        refreshOptions(name,params){
            const {includeFrontOptions} = this.props;
            Promise.resolve(this.props.fetchOptions(params))
            .then(options=>{

                let _options = includeFrontOptions.concat(options);

                this.setState({
                    options:_options
                });
                this.props.onChange({
                    [name]: (this.props.xmlMultiSelect) ? `<value>${_options[0].value}</value>` : _options[0].value
                });
            });
        }



        render(){
            return (
                <WrappedComponent {...this.props} options={this.state.options} />
            );
        }
    };
};

class SingleSelectDropDown extends React.Component{
    static get propTypes(){
        return {
            onChange:PropTypes.func,
            name: PropTypes.string,
            options:PropTypes.array
        };
    }

    static get defaultProps(){
        return {
            onChange:()=>{}
        };
    }

    constructor(){
        super();
        this.handleChange = this.handleChange.bind(this);
    }

    render(){

        const {options} = this.props;

        return (
            <select onChange={this.handleChange}>
                {options.map(this.renderOption)}
            </select>
        );
    }

    handleChange(e){
        const {name} = this.props;
        this.props.onChange({
            [name]: e.currentTarget.value
        });
    }

    renderOption(opt,i){
        let label,value;
        if(typeof opt !== "object"){
            label = value = opt;
        }else{
            label = opt.label;
            value = opt.value;
        }

        return (
            <option key={i} value={value}>{label}</option>
        );
    }
}

const AsyncSelect = fetchOptionsAsync(SingleSelectDropDown);

const MSFilter = (props)=>(
    <div style={{display:'inline-block',maxWidth:'75%'}}>
        <MultiSelectFilter {...props} />
    </div>
);

const MultiAsyncSelect = fetchOptionsAsync(MSFilter);

/*
██████   █████  ████████ ███████ ████████ ██ ███    ███ ███████
██   ██ ██   ██    ██    ██         ██    ██ ████  ████ ██
██   ██ ███████    ██    █████      ██    ██ ██ ████ ██ █████
██   ██ ██   ██    ██    ██         ██    ██ ██  ██  ██ ██
██████  ██   ██    ██    ███████    ██    ██ ██      ██ ███████
*/


class DateTimeParam extends React.Component{
    static get propTypes(){
        return {
            label: PropTypes.string,
            labelStyle: PropTypes.object,
            fromName: PropTypes.string,
            toName: PropTypes.string,
            onChangeSet:PropTypes.func
        };
    }

    static get defaultProps(){
        return {
            onChangeSet: ()=>{}
        };
    }

    constructor(){
        super();
        this.handleChangeSet = this.handleChangeSet.bind(this);
    }

    shouldComponentUpdate(){
        return false; // once created, nothing really affects how this gets rendered.
    }

    handleChangeSet(changeset){

        const {fromName,toName} = this.props;

        let o = {};

        if(changeset["from"]) o[fromName] = changeset["from"];
        if(changeset["to"]) o[toName] = changeset["to"];

        this.props.onChangeSet(o);
    }

    render(){
        const {labelStyle,label} = this.props;

        return (
            <div style={{display:'block',padding:'0.25em',verticalAlign:'middle',whiteSpace:'nowrap',maxWidth:'100%',boxSizing:'border-box',overflow:'hidden'}}>
                <span style={labelStyle}>{label}</span>
                <div style={{display:'inline-block',maxWidth:'75%'}}>
                    <DumbDateTimeFilter
                        fromFilter={{
                            id:"from"
                        }}
                        toFilter={{
                            id:"to"
                        }}
                        onChange={NOP}
                        onChangeSet={this.handleChangeSet}
                        buttonStyle={{
                            width:'100%',
                            height:"1.6666em",
                            borderRadius:0,
                            color:'black',
                            margin:0
                        }}
                        carotStyle={{
                            borderColor:'black transparent transparent transparent',
                            borderWidth:'6px 3px 0'
                        }}
                    />
                </div>
            </div>
        );
    }
}
/*
██████  ██    ██ ███    ███ ██████      ██████   █████  ██████   █████  ███    ███
██   ██ ██    ██ ████  ████ ██   ██     ██   ██ ██   ██ ██   ██ ██   ██ ████  ████
██   ██ ██    ██ ██ ████ ██ ██████      ██████  ███████ ██████  ███████ ██ ████ ██
██   ██ ██    ██ ██  ██  ██ ██   ██     ██      ██   ██ ██   ██ ██   ██ ██  ██  ██
██████   ██████  ██      ██ ██████      ██      ██   ██ ██   ██ ██   ██ ██      ██
*/

class DumbParameterSection extends React.Component{
    static get propTypes(){
        return {
            requestDashboardFilterData: PropTypes.func.isRequired,
            parameters: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string,
                    options: PropTypes.array,
                    async:PropTypes.shape({
                        key:PropTypes.string,
                        labelKey: PropTypes.string,
                        valueKey: PropTypes.string
                    })
                })
            ),
            onChange: PropTypes.func
        };
    }

    static get defaultProps(){
        return {
            parameters: [],
            onChange:()=>{}
        };
    }

    constructor(){
        super();
        this.state = {
            values:{}
        };

        this.renderParameter = this.renderParameter.bind(this);
    }

    shouldComponentUpdate(nextProps,nextState){
        if(nextProps !== this.props) return true;

        return !equal(this.state,nextState);
    }

    componentDidMount(){
        this.initializeParameters();
    }

    componentDidUpdate(prevProps){
        if(this.props !== prevProps) this.initializeParameters();
    }

    initializeParameters(){
        const values = this.getDefaultValues(this.props.parameters);
        this.props.onChange(values);
        this.setState({values});
    }

    getDefaultValues(params){
        //console.log(params);
        return params.reduce((defaultValues,param)=>{
            let dv= {
                ...defaultValues,
                ...this.getDefaultValuesForParameter(param)
            };
            return dv;
        },{});
    }

    getDefaultValuesForParameter(param){
        switch(param.type){
            case "hidden":
                return {
                    [param.name]: param.value
                };
            case "from-to":
                return (()=>{
                    const {fromName,toName} = param;
                    let times = getTimes(PERIOD.TODAY);
                    return {
                        [fromName]:times.from.toJSON(),
                        [toName]:times.to.toJSON()
                    };
                })();
            default:
                return null;
        }
    }

    render(){

        const {parameters} = this.props;

        return (
            <div style={{fontSize:'0.9em',backgroundColor:"#7d868b"}}>
                {parameters.map(this.renderParameter)}
            </div>
        );
    }

    renderParameter(par){
        return this.renderParameterByType(par.type,par);
    }

    renderParameterByType(type,par){

        const labelStyle={
            fontSize:'0.9em',
            padding:'0 0.5em',
            color:'black',
            letterSpacing:'0.2px',
            fontStyle:'italic',
            position:'relative',
            top:'-1px'
        };


        switch(type){
            case "hidden":
                return (
                    <input
                        type="hidden"
                        key={par.name}
                        value={par.value}
                    />
                );
            case "from-to":
                return (
                    <DateTimeParam
                        {...par}
                        key={par.name}
                        labelStyle={labelStyle}
                        onChangeSet={this.handleChangeSet.bind(this)}
                    />
                );

            case "multi-enum":
                return (
                    <div style={{display:'inline-block',padding:'0.25em',verticalAlign:'middle'}} key={par.name}>
                        <span style={labelStyle}>{par.label}</span>
                        <MultiAsyncSelect
                            {...par}
                            fetchOptions={this.blendData(par)}
                            onChange={this.handleChangeSet.bind(this)}
                            params={this.state.values}
                            xmlMultiSelect={true}
                        />
                    </div>
                );

            case "enum":
            default:
                return (
                    <div style={{display:'inline-block',padding:'0.25em',verticalAlign:'middle'}} key={par.name}>
                        <span style={labelStyle}>{par.label}</span>
                        <AsyncSelect
                            {...par}
                            fetchOptions={this.blendData(par)}
                            onChange={this.handleChangeSet.bind(this)}
                            params={this.state.values}
                        />
                    </div>
                );

        }
    }

    blendData(parameter){

        if(!parameter.async){
            return ()=>{
                return parameter.options;
            };
        }

        return (values)=>{
            return this.props.requestDashboardFilterData(parameter.async,values)
            .then(data=>data.map(datum=>({
                label: datum[ parameter.async.labelKey || parameter.async.key ],
                value: datum[ parameter.async.valueKey || parameter.async.key ]
            })));
        };

    }

    handleChangeSet(changeset){
        const values = {
            ...this.state.values,
            ...changeset
        };
        this.props.onChange(values);
        this.setState({values});
    }
}
/*
 ██████  ██████  ███    ██ ███    ██ ███████  ██████ ████████  ██   ██
██      ██    ██ ████   ██ ████   ██ ██      ██         ██    ██     ██
██      ██    ██ ██ ██  ██ ██ ██  ██ █████   ██         ██    ██     ██
██      ██    ██ ██  ██ ██ ██  ██ ██ ██      ██         ██    ██     ██
 ██████  ██████  ██   ████ ██   ████ ███████  ██████    ██     ██   ██
*/

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        requestDashboardFilterData:actions.requestDashboardFilterData,
    },dispatch)
});

export default connect(null,mapDispatchToProps)(DumbParameterSection);
