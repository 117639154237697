const DataGenerator = require('./DataGenerator');

export const MOCK_DATAENTRY_FIELDS = [
    {
        label:"ID",
        type:"preset",
        value:"10017"
    },
    {
        label:"Zone",
        type:"text",
        defaultValue:"1C01-mtg room"
    },
    {
        label:"Abbreviation",
        type:"text",
        defaultValue:"1C01"
    },
    {
        label:"Zone Group",
        type:"lookup",
        defaultValue:"Floor 1 Mtg Rooms"
    },
    {
        label:"Description",
        type:"text",
        defaultValue:"copernicus"
    },
    {
        label:"Zone Type",
        type:"preset",
        value:"Standard Zone"
    },
    {
        label:"Sequence",
        type:"number"
    },
    {
        label:"Capacity",
        type:"number",
        defaultValue:"0"
    }
];

const MOCK_STACKEDCHART_DATA = DataGenerator.generateStackedChartData();

export const MOCK_CHART_DATA =[
    {
        label: "Site 01",
        color: "rgba(153,185,196,1)",
        data: MOCK_STACKEDCHART_DATA[0]
    },{
        label: "Site 02",
        color: "rgba(67,137,162,1)",
        data: MOCK_STACKEDCHART_DATA[1]
    },{
        label: "Site 03",
        color: "rgba(123,200,128,1)",
        data: MOCK_STACKEDCHART_DATA[2]
    },{
        label: "Site 04",
        color: "rgba(189,246,193,1)",
        data: MOCK_STACKEDCHART_DATA[3]
    },{
        label: "Site 05",
        color: "rgba(187,162,78,1)",
        data: MOCK_STACKEDCHART_DATA[4]
    },{
        label: "Site 06",
        color: "rgba(240,214,119,1)",
        data: MOCK_STACKEDCHART_DATA[5]
    }
];
