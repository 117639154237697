import React from 'react';
import {useSelector}  from 'react-redux';

const CustomCellLogLevel = ({itemKey, rowIndex}) => {
    const selectedStatusHistory = useSelector( state => state.deviceManager.deviceList[rowIndex]);

    const getValue = () => {
        return <div className="log-level-components">{selectedStatusHistory[itemKey]?.components && selectedStatusHistory[itemKey]?.components.length > 0 ?
            selectedStatusHistory[itemKey].components.map((item, index) => {
                let title = "";
                if(item.componentName==='cloud_agent'){
                    title = 'R2C';
                } else if(item.componentName === 'radio_control'){
                    title = 'RC'
                }
                return (
                    <span key={item.componentName} className="log-level-components-title">{title}: {item.level.toLowerCase()}
                    {(index < selectedStatusHistory[itemKey].components.length-1 ? ',' : '')}</span>
                )
            }) : ''}</div>;
    };

    return (
        <div className="log-level-main-cell">
            <div className="log-level-container">
                {selectedStatusHistory[itemKey] && <div className="log-level-components-container">
                    {getValue()}
                    <div key={"radioPacketLog"} className="log-level-components">
                        <span className="log-level-components-title">RPL:</span>
                        <span>{selectedStatusHistory[itemKey].radioPacketLog ? 'Enabled' : 'Disabled' }</span>
                    </div>
                </div>}
            </div>
        </div>
    )
};

export default CustomCellLogLevel;