
import React, {useState, useEffect, useRef} from 'react';
import {Button, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Divider} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {makeStyles} from '@material-ui/core/styles';
import {useSelector, useDispatch} from 'react-redux';
import {
    updateStepAddDevices,
    createBatchImportFiles,
    loadTemplatesBatch,
    displayNotification,
    cleanImportedFiles
} from "../../../actions";
import {ALERT_ERROR} from '../../../constants/DeviceManager';
import {useHistory} from 'react-router-dom';
const useStyles = makeStyles({
    paperDialog: {
        overflowY: "visible"
    }
});

const AddFilesImport = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const batchImportFilesState = useSelector(state => state.deviceManager.batchImportFiles);
    const actualStep = useSelector(state => state.deviceManager.actualStepBatch);
    const [dragContainerStyles, setDragContainerStyles] = useState({display:'flex',height:'10em',width:'100%',border:'1px dashed',
                                                        justifyContent:'center',alignItems: 'center',backgroundColor:'#f7f2f2',marginBottom: '1em'});
    const [nameFiles, setNameFiles] = useState([]);
    const [wrongFiles, setWrongFiles] = useState([]);

    const [filesOK, setFilesOK] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const pathDeviceList = '/device-manager/devices';
    const fileRef = useRef();

    useEffect(()=>{
        // showing a notification of wrong files
        if(wrongFiles.length > 0){
            const bodyMessage = `Not possible to upload ${wrongFiles.length} file(s)`;
            const detailsMessage = `${wrongFiles.join('\n')}`;
            dispatch(displayNotification(true, ALERT_ERROR,`Error to upload file(s): `, bodyMessage, detailsMessage, true));
        }
    },[wrongFiles, dispatch]);

    const onDragFile = event => {
        setDragContainerStyles({...dragContainerStyles, opacity:0.5});
        event.preventDefault();
    };

    const fileOut = () => {
        setDragContainerStyles({...dragContainerStyles, opacity: 1})
    };

    const onFileDrop = event => {
        event.preventDefault();
        event.stopPropagation();
        handleReadingFiles(event.dataTransfer.files);
        fileOut();
    };

    const handleValidateFile = (file) => {
        return filesOK.find(e => e.name === file.name && e.size === file.size && e.type === file.type);
    };

    const handleValidateHeaderCSV = (header) => {
        const headerModel = ['Device Type','Device Model','Name','Hostname','Template Used','# Antenna Ports','Enabled Antenna Ports','x','y','z','Auto Zone'];
        const headerArray = header.split(',');
        let validation = true;
        if(headerModel.length === headerArray.length){
            headerModel.forEach((item, index) => {
                if(item !== headerArray[index].trim()){
                    validation = false;
                }
            });
        }else{
            validation = false;
        }

        return validation;
    }

    const handleReadingFiles = files => {
        setWrongFiles([]);
        for (let i = 0, f; !!(f = files[i]); i++) {
            //searching duplicated file
            if(handleValidateFile(f)) {
                setWrongFiles(prevState => [...prevState, `${f.name} has already been uploaded.`]);
                return;
            }

            //validating extension file
            if (!f.name.endsWith('.csv')) {
                setWrongFiles(prevState => [...prevState, `${f.name} dont have CSV extension.`]);
            }else {
                let reader = new FileReader();
                // Detect the method readAsBinaryString for modernBrowsers
                let hasBinaryString = typeof reader.readAsBinaryString === 'function';

                if (hasBinaryString) {
                    reader.readAsBinaryString(f);
                } else {
                    reader.readAsText(f);
                }

                // reading the file
                reader.onload = (file) => {
                    const result = file.target.result;
                    // const result = reader.result;
                    let lines = result.split('\n');
                    // validating the number of columns of CSV file
                    if(handleValidateHeaderCSV(lines[0])){
                        let content=[];
                        for(var i = 0; i < lines.length; i++){
                            const fileLine = lines[i];
                            // let fileColumn=fileLine.split(",");
                            let fileColumn = fileLine.split(/,(?=(?:(?:[^'"]*(?:'|")){2})*[^'"]*$)/);
                            if(fileColumn[0]!=="" && fileColumn[0]!=="Device Type"){
                                let schema = {};
                                schema.deviceType = fileColumn[0].replace(/\b[a-z]/g,c=>c.toUpperCase());
                                schema.deviceModel = fileColumn[1];
                                schema.deviceName = fileColumn[2];
                                schema.deviceIP = fileColumn[3];
                                schema.deviceTemplate = fileColumn[4];
                                schema.deviceAntennaPorts = fileColumn[5];
                                schema.deviceEnabledAntennas = fileColumn[6];
                                if(fileColumn[7] !== ''){schema.deviceX = fileColumn[7];}
                                if(fileColumn[8] !== ''){schema.deviceY = fileColumn[8];}
                                if(fileColumn[9] !== ''){schema.deviceZ = fileColumn[9];}                                
                                schema.deviceAutozone = fileColumn[10];
                                content.push(schema);
                            }
                        }

                        dispatch(createBatchImportFiles({...batchImportFilesState, [f.name]: content}));
                        setNameFiles(prevState => [...prevState, f.name]);
                        setFilesOK([...filesOK, f]);
                    }else{
                        setWrongFiles(prevState => [...prevState, `${f.name} doesn't have required columns.`])
                    }
                };
            }
        }
    }

    const handleNextStep = () => {
        dispatch(loadTemplatesBatch());
        dispatch(updateStepAddDevices(actualStep + 1));
    };

    const handleFileSelect = event => {
        handleReadingFiles(event.target.files);
    };
    
    const deleteFileItem  = index => {
        const updateNameFiles = [...nameFiles];

        //updating redux store
        let batchImportFilesAux = {...batchImportFilesState};
        delete batchImportFilesAux[updateNameFiles[index]];
        dispatch(createBatchImportFiles(batchImportFilesAux));


        // Delete file from state
        updateNameFiles.splice(index, 1);
        setNameFiles(updateNameFiles);

        const updateFilesOK = [...filesOK];
        updateFilesOK.splice(index, 1);
        setFilesOK(updateFilesOK);

    };

    const handleCancelImportFiles = () => {
        history.replace(pathDeviceList)
        dispatch(cleanImportedFiles());
    };

    const downloadTemplate = () => {
        console.log('Downloading Template');
    };

    const getFilesUploadedContent = () =>{
        return(
            <React.Fragment>
                <div style={{marginBottom:'0.5em'}}><b>Uploaded Files</b></div>
                <Divider />
                <div>
                    {nameFiles.map((item, index) =>
                        <React.Fragment key={index}>
                            <div style={{display:'flex', justifyContent: 'space-between',alignItems: 'center'}} >
                                {item}
                                <IconButton aria-label="close"
                                            size="small" onClick={()=>deleteFileItem(index)}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                            <Divider/>
                        </React.Fragment>)}
                </div>
            </React.Fragment>
        )
    };
    return (
        <React.Fragment>
            <div className={"container-body"}>
                <div style={{flex:'1 1 360px',padding: '0px 0.5em',fontSize:'0.875rem', display:'block',overflowY:'auto'}}>
                    <div style={dragContainerStyles}
                         onDragOver={onDragFile} onDragLeave={fileOut}
                         onDropCapture={onFileDrop}>
                        Drag & Drop CSV files here
                    </div>
                    <div>
                        <label ref={fileRef} htmlFor="input-file">
                            <Button
                                variant="contained"
                                onClick={()=>fileRef.current.click()}
                                style={{backgroundColor:'#7e7b7f', color:'#fff',textTransform:'none'}}>
                                Browse
                            </Button>
                        </label>
                        <input
                            id="input-file"
                            type={'file'}
                            style={{ display: "none" }}
                            multiple
                            accept={'*.csv'}
                            onChange={handleFileSelect}
                            onClick={(event)=> {
                                event.target.value = null
                            }}
                        />
                    </div>
                    <Divider style={{margin:'1em 0'}}/>
                    <b>CSV file requirements</b>
                    <ul style={{paddingInlineStart: '20px'}}>
                        <li>The import file must be .csv extension</li>
                        <li>All the data is read by rows, first the headers and then the information of the devices</li>
                        <li>All the information must be clear and separated by a comma.</li>
                    </ul>
                    <div style={{display:'flex'}}>
                        {/*<Button style={{textTransform:'none', padding: '10px 0',color:'#007CB0',marginRight:'10px'}} onClick={()=>setOpenDialog(true)} component="span">View csv file example</Button>*/}
                        <a style={{textTransform:'none', padding: '10px 0',color:'#007CB0',textDecoration:"none"}}  href={"assets/images/BatchImportExample.csv"} download={"BatchImportExample.csv"}>Download CSV File Template</a>
                    </div>
                </div>
                <Divider orientation="vertical" flexItem style={{background:'#000',margin:'0px 1em'}}/>
                <div style={{flex:'1 1 360px',padding: '0px 0.5em',display:'block',overflowY:'auto'}}>
                    <div style={{fontSize:'0.875rem'}}>
                        {(nameFiles.length>0) ? getFilesUploadedContent() : 'No files uploaded yet.'}
                    </div>
                </div>
            </div>
            <div className={"container-step-content-buttons"}>
                <Button className={'cancel-button'} style={{marginRight:'8px'}} onClick={handleCancelImportFiles} component="span">Cancel</Button>
                <Button className={'next-step-button'} onClick={handleNextStep} variant={"contained"} disabled={Object.keys(batchImportFilesState).length===0}>Next Step: Show Devices</Button>
            </div>
            <DialogCSVExample openDialog={openDialog} closeDialog={()=>setOpenDialog(false)} actionSuccess={downloadTemplate}/>
        </React.Fragment>
    );
};
export default AddFilesImport;

const DialogCSVExample = ({openDialog, closeDialog}) => {
    const classes = useStyles();
    const renderCloseButton = () => {
        return (
            <div style={{position:'absolute',top:'-0.5em',right:'-0.5em'}}>
                <IconButton aria-label="close"
                            size="small"
                            onClick={closeDialog}
                            style={{cursor:'pointer',position:'relative',zIndex:'100',backgroundColor:'#000'}}
                >
                    <CloseIcon style={{color: '#fff'}} />
                </IconButton>
            </div>
        );
    };

    return (
        <Dialog
            open={openDialog} fullWidth={true}
            onClose={closeDialog}
            classes={{paper:classes.paperDialog}}>
            <DialogTitle style={{paddingBottom:0}}><span style={{fontSize:'0.8em',fontWeight:'bold'}}>CSV File Example</span></DialogTitle>
            {renderCloseButton()}
            <DialogContent style={{fontSize:'0.9em',display:'flex',flexDirection:'column',maxHeight:'100%'}}>
                <img src="assets/images/BatchImportExampleImage.png" style={{height:'400px',width:'auto'}} alt="CSV template example"/>
            </DialogContent>
            <DialogActions style={{padding: '8px 24px'}}>
                <a style={{background: '#007CB0',color: '#fff', padding: '0.5em', width: '150px',textTransform:'none',textDecoration:"none"}}
                   href={"assets/images/BatchImportExample.csv"} download={"BatchImportExample.csv"}>
                    Download Template
                </a>
            </DialogActions>
        </Dialog>
    )
};
