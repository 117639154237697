import React from 'react';
import PropTypes from 'prop-types';

import StyledDialog, {CancelButton, SaveButton} from '../../../util/Dialog';

import PermissionsTable from './PermissionsTable';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../../../../actions';

import {USERMGMT_UPDATE_GROUP} from '../../../../constants/DataEntryForms';

class UpdateUserGroupDialog extends React.Component{

    constructor(){
        super();
        this.handleSave = this.handleSave.bind(this);
        this.formatPermissions = this.formatPermissions.bind(this);
    }

    static get propTypes(){
        return {
            sites: PropTypes.array,
            resourceTypes: PropTypes.array,
            group: PropTypes.shape({
                groupName: PropTypes.string,
                description: PropTypes.string,
                permission: PropTypes.object
            }),
            onRequestClose: PropTypes.func.isRequired,
            requestSitesForUserMgmt: PropTypes.func.isRequired,
            requestResourceTypes: PropTypes.func.isRequired,
            updateUserGroup: PropTypes.func.isRequired
        };
    }

    static get defaultProps(){
        return {
            group:{}
        };
    }

    componentDidMount(){
        this.props.requestSitesForUserMgmt();
        this.props.requestResourceTypes();
    }

    handleSave(){
        const {group,updateUserGroup,sites,resourceTypes,onRequestClose} = this.props;
        var o = {
            groupName: group.groupName,
            description: this.refs.description.value,
            permission: this.refs.permissions.getPermissions()
        };

        if(o.permission.view.sites && o.permission.view.sites.length === sites.length) o.permission.view.sites = ["ALL"];
        if(o.permission.edit && o.permission.edit.sites && o.permission.edit.sites.length === sites.length) o.permission.edit.sites = ["ALL"];
        if(o.permission.view.resourceTypes && o.permission.view.resourceTypes.length === resourceTypes.length) o.permission.view.resourceTypes = ["ALL"];
        if(o.permission.edit && o.permission.edit.resourceTypes && o.permission.edit.resourceTypes.length === resourceTypes.length) o.permission.edit.resourceTypes = ["ALL"];

        updateUserGroup(group.id,o)
        .then(onRequestClose);
    }

    formatPermissions(permissions){
        const {sites,resourceTypes} = this.props;
        return permissions && {
            view:{
                ...permissions.view,
                sites:permissions.view.sites && ((permissions.view.sites[0] === "ALL") ? sites : permissions.view.sites),
                resourceTypes:permissions.view.resourceTypes && ((permissions.view.resourceTypes[0] === "ALL") ? resourceTypes : permissions.view.resourceTypes)
            },
            edit: permissions.edit && {
                ...permissions.edit,
                sites: permissions.edit.sites && ((permissions.edit.sites[0] === "ALL") ? sites : permissions.edit.sites),
                resourceTypes:permissions.edit.resourceTypes && ((permissions.edit.resourceTypes[0] === "ALL") ? resourceTypes : permissions.edit.resourceTypes)
            }
        };
    }

    render(){

        const {sites,resourceTypes,group} = this.props;

        return (
            <StyledDialog
                {...this.props}
                onClose={this.props.onRequestClose}
                className="update-user-group-dialog"
                title={"Edit Group"}
                actions={
                    <>
                        <SaveButton onClick={this.handleSave} id={"edit_save_btn"}/>
                        <CancelButton style={{position: 'absolute', left: 8, bottom: 8}} onClick={this.props.onRequestClose} id={"edit_cancel_btn"} />
                    </>
                }
                maxWidth={false}
                //contentStyle={{width: '86vw'}}
                // contentStyle={{maxWidth:'100%',width:'90%'}}
                // contentClassName="update-user-group-dialog"                
                >
                <table><tbody><tr>
                    <td>
                        <div style={{paddingRight:'1em'}}>
                            <div className="label">Group</div>
                            <div>{group.groupName}</div>
                            <div className="label">Description</div>
                            <textarea
                                defaultValue={group.description}
                                style={{minHeight:'150px',width:'100%',boxSizing:'border-box'}}
                                ref={"description"}
                                id={"edit_groupdescription_txt"}
                            />
                        </div>
                    </td>
                    <td>
                        <PermissionsTable sites={sites} resourceTypes={resourceTypes} permissions={this.formatPermissions(group.permission)} ref={"permissions"}/>
                    </td>
                </tr></tbody></table>
            </StyledDialog>
        );
    }
}


/*
 ██████  ██████  ███    ██ ███    ██ ███████  ██████ ████████     ████████  ██████
██      ██    ██ ████   ██ ████   ██ ██      ██         ██           ██    ██    ██
██      ██    ██ ██ ██  ██ ██ ██  ██ █████   ██         ██           ██    ██    ██
██      ██    ██ ██  ██ ██ ██  ██ ██ ██      ██         ██           ██    ██    ██
 ██████  ██████  ██   ████ ██   ████ ███████  ██████    ██           ██     ██████

███████ ████████  █████  ████████ ███████
██         ██    ██   ██    ██    ██
███████    ██    ███████    ██    █████
     ██    ██    ██   ██    ██    ██
███████    ██    ██   ██    ██    ███████
*/






const mapStateToProps = state => ({
    open: state.dataEntry.open && state.dataEntry.activeForm === USERMGMT_UPDATE_GROUP,
    group: (state.dataEntry.activeForm === USERMGMT_UPDATE_GROUP || undefined) && state.dataEntry.initialFormData.group,
    sites: state.userMgmt.sites,
    resourceTypes: state.userMgmt.resourceTypes // possible to change.
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        requestSitesForUserMgmt:actions.requestSitesForUserMgmt,
        requestResourceTypes: actions.requestResourceTypes,
        onRequestClose:actions.closeDataEntry,
        updateUserGroup: actions.updateUserGroup
    },dispatch)
});

export default connect(mapStateToProps,mapDispatchToProps)(UpdateUserGroupDialog);
