import CheckboxCell from "./Checkboxcell";
import {defaultCellHeight} from "./Util";
import React from "react";

const CheckAll = ({onSelectAll,checked}) => {

    return(
        <div className={"table-sticky-check-all-container"}>
            <CheckboxCell className=" table-sticky-check-all"
                          style={{top:0,left:0,width:50,height:defaultCellHeight}}
                          onClick={()=>onSelectAll()}
                          checked={checked}
            />
        </div>
    )};

export default CheckAll;