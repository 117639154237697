/* miscellaneous constants */

let configuration = {};

window.CONFIGURATION = window.CONFIGURATION || {};

configuration.ics = window.CONFIGURATION.ics || {};
configuration.reportService = window.CONFIGURATION.reportService || {};
configuration.accessControlService = window.CONFIGURATION.accessControlService || {};
configuration.authService = window.CONFIGURATION.authService || {};
configuration.zoneService = window.CONFIGURATION.zoneService || {};
configuration.zoneBuilder = window.CONFIGURATION.zoneBuilder || {};
configuration.streamService = window.CONFIGURATION.streamService || {};
configuration.importService = window.CONFIGURATION.importService || {};
configuration.siteViewService = window.CONFIGURATION.siteViewService || {};
configuration.deviceManagementService = window.CONFIGURATION.deviceManagementService || {};


function adjustURLForLocalhost(url){
    return url.replace("localhost",window.location.hostname);
}

const ICS_HOST = adjustURLForLocalhost( configuration.ics.baseUrl || "//localhost/mwe/infrastructure" );
const REPORT_SERVICE_HOST = adjustURLForLocalhost( configuration.reportService.baseUrl || "//localhost/mwe" );
const ACL_HOST = adjustURLForLocalhost( configuration.accessControlService.baseUrl || "//localhost/mwe/usermgmt" );
const ACCESS_HOST = adjustURLForLocalhost( configuration.authService.baseUrl || "//localhost/mwe" );
const OSM_SOURCE = adjustURLForLocalhost(window.CONFIGURATION.osmSource || "//{a-c}.tile.openstreetmap.org/");
const ZONE_SERVICE_HOST = adjustURLForLocalhost(configuration.zoneService.baseUrl || "//localhost/mwe/zone-service");
const STREAM_SERVICE_PATH = configuration.streamService.path;
const STREAM_SERVICE_HOST = adjustURLForLocalhost(configuration.streamService.baseUrl || "//localhost/mwe");
const IMPORT_SERVICE_HOST = adjustURLForLocalhost(configuration.importService.baseUrl || "//localhost/mwe/import");
//const MAP_VIEW_URL = "/map-view";  // Change to /infrastructure for getting the old.
const MAP_VIEW_URL = "";

const ZONE_BUILDER_ENABLED = configuration.zoneBuilder.enabled || false;
const SITE_VIEW_HOST = adjustURLForLocalhost(configuration.siteViewService.baseUrl || "//localhost");
const DEVICE_MANAGEMENT_SERVICE_HOST = adjustURLForLocalhost(configuration.deviceManagementService.baseUrl || "//localhost/mwe/dms");


export {
    ICS_HOST,
    REPORT_SERVICE_HOST,
    ACL_HOST,
    ACCESS_HOST,
    OSM_SOURCE,
    ZONE_SERVICE_HOST,
    ZONE_BUILDER_ENABLED,
    MAP_VIEW_URL,
    STREAM_SERVICE_PATH,
    STREAM_SERVICE_HOST,
    IMPORT_SERVICE_HOST,
    SITE_VIEW_HOST,
    DEVICE_MANAGEMENT_SERVICE_HOST,
};

export const FIELD = {
    DISPLAY_TYPES: {
        NO_DISPLAY: 0,
        DISPLAY: 1,
        EDIT: 2,
        COMBOBOX: 3,
        COMBOBOX_NOEDIT: 4,
        COLORBOX: 5,
        DATEBOX: 6,
        MEMOBOX: 7,
        MEMOBOX_NOEDIT: 8,
        PASSWORD: 9,
        EDIT_WITHFOLDER: 10,
        COMBOBOX_WITHFOLDER: 11,
        ICON_WITHFOLDER: 12,
        ICON_WITHREPOSITORY: 13,
        COLORBOX_READONLY: 14
    },
    DEFAULT_SOURCE_TYPES: {
        VALUE: 0,
        REPORT_ROW: 1,
        TOP_OF_LIST: 2,
        LOGIN_NAME: 3,
        COMPUTER_NAME: 4
    },
    VALUE_SOURCE_TYPES:{
        NONE: 0,
        LIST: 1,
        SELECT: 2,
        REPORT_COLUMN: 3,
        REPORT_FILTER: 4
    }
};

export const OPTION_ALL_WILDCARD = "%";
export const OPTION_ALL_WILDCARD_OLD = "*";
export const OPTION_ALL_TEXT = "All";

export const DEFAULT_ID_ATTRIBUTE = 'tagId';

// Name of layer of tags in maps.
export const MAPS_CLUSTERED_TAG_LAYER_NAME = 'clusteredTagLayer';
export const IMPORT_ENABLED_REPORTS = ['Resources','Tags','Resource Custom Attribute Value Settings','Resource Type Settings'];
