import React from 'react';
import PropTypes from 'prop-types';

import {Radio, RadioGroup} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {withStyles} from '@material-ui/core/styles';

import ZToggle from '../../../util/ZToggle';
import Checklist from '../../../util/TreeSelect';

import cn from 'classnames';

const WhiteRadio = withStyles({
    root: {
        color: "#fff",
        '&$checked': {
            color: "#fff",
        },
    },
    checked: {},
})(props => <Radio color="default" style={{color: '#fff'}} {...props} />);

const ProppedRadioGroup = ({children,...other})=>(
    <RadioGroup {...other}>
        {React.Children.map(children,child => (
            React.cloneElement(child,{
                // iconStyle:{color:'inherit',fill:'currentColor',marginLeft:'4px'},
                style:{display:'block',width:'auto',float:'left',paddingLeft:'1em',color:'inherit'},

            })
        ))}
    </RadioGroup>
);

ProppedRadioGroup.propTypes = {
    children: PropTypes.any
};

const VIEW_ONLY = 0;
const VIEW_EDIT_SEPARATE = 1;
const BOTH = 2;

const SmartToggle = (props)=>(
    <ZToggle onText={"Yes"} offText={"No"} on={props.defaultValue} onChange={props.onChange}/>
);

SmartToggle.propTypes = {
    defaultValue:PropTypes.bool,
    onChange:PropTypes.func
};

const SpecialSelect = (props)=>(
    <Checklist defaultValue={props.defaultValue} options={props.options} onChange={props.onChange} /> // silly
);

SpecialSelect.propTypes = {
    defaultValue:PropTypes.any,
    options:PropTypes.array,
    onChange:PropTypes.func
};

class PermissionsTable extends React.Component{
    constructor(props){
        super();
        const { permissions } = props;
        this.state = {
            permissionRoles:this.getPermissionState(permissions),
            permissions:{...permissions}
        };

        this.handlePermissionsRoleChange = this.handlePermissionsRoleChange.bind(this);
    }

    static get propTypes(){
        return {
            sites: PropTypes.arrayOf(
                PropTypes.string
            ),
            resourceTypes: PropTypes.arrayOf(
                PropTypes.string
            ),
            permissions:PropTypes.shape({
                view: PropTypes.shape({
                    tags: PropTypes.bool,
                    alerts: PropTypes.bool,
                    infrastructure: PropTypes.bool,
                    userManagement: PropTypes.bool,
                    resources: PropTypes.bool,
                    configuration: PropTypes.bool,
                    dashboard: PropTypes.bool,
                    sites: PropTypes.array,
                    resourceTypes: PropTypes.array
                }),
                edit: PropTypes.shape({
                    tags: PropTypes.bool,
                    alerts: PropTypes.bool,
                    infrastructure: PropTypes.bool,
                    userManagement: PropTypes.bool,
                    resources: PropTypes.bool,
                    configuration: PropTypes.bool,
                    dashboard: PropTypes.bool,
                    sites: PropTypes.array,
                    resourceTypes: PropTypes.array
                })
            })
        };
    }


    static get defaultProps(){
        return {
            permissions:{
                view: {

                },
                edit: {

                }
            }
        };
    }

    getPermissionState(permissions){

        let edit = permissions.edit;
        let view = permissions.view;

        let editValues = Object.keys(edit).map(e => edit[e] === true);

        if(editValues.findIndex(value => value === true) < 0){
            return VIEW_ONLY;
        }
        else{
            //searching differences between Objects 'view' and 'edit'
            let count = 0;
            Object.keys(view).forEach((key) => {
                if(Array.isArray(view[key])){
                    if(edit[key] && view[key].length === edit[key].length) {
                        view[key].forEach((val) => count = edit[key].indexOf(val) === -1 ? count + 1 : count);
                    } else {
                        count++;
                    }
                } else {
                    count = view[key] !== edit[key] ? count + 1 : count;
                }
            });

            if(count === 0){
                return BOTH;
            }else{
                return VIEW_EDIT_SEPARATE;
            }           
        }
    }

    handlePermissionsRoleChange(event){
        let rel = parseInt(event.target.value);
        this.setState({
            permissionRoles: rel
        });
    }

    handleChange(type,key,value){
        const { permissions } = this.state; 
        this.setState({
            permissions:{
                ...permissions,
                [type]:{
                    ...permissions[type],
                    [key]:value
                }
            }
        });
    }

    // public -- access with refs.
    getPermissions(){
        const { permissionRoles, permissions } = this.state;
        switch(permissionRoles){
            case VIEW_ONLY:
                return {view:permissions.view};
            case BOTH:
                return {
                    view:permissions.view,
                    edit:permissions.view // use the same set.
                };
            case VIEW_EDIT_SEPARATE:
            default:
                return permissions;
        }
    }

    render(){

        const { permissionRoles, permissions } = this.state;
        const firstRole = (
            <div style={{padding:'0 1em'}}>
                {"View "}
                <span style={{visibility:(permissionRoles === BOTH) ? "visible" : "hidden"}}>
                    {"and Edit"}
                </span>
            </div>
        );

        const secondRole = (
            <div style={{padding:'0 1em'}}>
                {"Edit"}
            </div>
        );



        const {sites,resourceTypes} = this.props;

        const headers = [
            {
                label:"ROLE",
                field:firstRole
            },
            {
                label:"Sites",
                defaultValueKey:"sites",
                controlComponent:SpecialSelect,
                options:sites
            },
            {
                label:"Resource Types",
                defaultValueKey:"resourceTypes",
                controlComponent:SpecialSelect,
                options:resourceTypes
            },
            {
                label:"Resources",
                defaultValueKey:"resources",
                controlComponent:SmartToggle
            },
            {
                label:"Tags",
                defaultValueKey:"tags",
                controlComponent:SmartToggle
            },
            {
                label:"Alerts",
                defaultValueKey:"alerts",
                controlComponent:SmartToggle
            },
            {
                label:"Infrastructure",
                defaultValueKey:"infrastructure",
                controlComponent:SmartToggle
            },
            {
                label:"User Mgmt",
                defaultValueKey:"userManagement",
                controlComponent:SmartToggle
            },
            {
                label:"Configuration",
                defaultValueKey:"configuration",
                controlComponent:SmartToggle
            },
            {
                label:"Dashboard",
                defaultValueKey:"dashboard",
                controlComponent:SmartToggle
            }
        ];

        let secondHeaders = [{label:"ROLE",field:secondRole}].concat(headers.slice(1));
        /*
        secondHeaders.splice(1,2,{
            ...headers[1],
            options: this.state.permissions.view.sites
        },{
            ...headers[2],
            options: this.state.permissions.view.resourceTypes
        });*/


        const mapHeader = (v,i) => (<td key={i} className="label" style={{width:v.label==="ROLE"?'94px':v.width,padding:'1em 0 0.5em 0'}}>{v.label}</td>);
        const mapField = (t,d,v,i)=>{

            const Control = v.controlComponent;

            let field = v.field;
            if(v.defaultValueKey){
                field = (
                    <Control
                        defaultValue={d[v.defaultValueKey]}
                        onChange={this.handleChange.bind(this,t,v.defaultValueKey)}
                        id={v.defaultValueKey}
                        {...v}
                    />
                );
            }

            return (
                <td key={i} className="field" style={{width:v.width}}>
                    {field}
                </td>
            );
        };

        let classnames = cn({
            "disable-de-fields":(permissionRoles !== VIEW_EDIT_SEPARATE)
        });
        return (
            <div style={{minWidth:'1100px'}}>
                <div className="user-permissions-verbose-dropdown" style={{overflow:'hidden'}}>
                    <span>Permissions: </span>
                    <ProppedRadioGroup
                        name="first_role"
                        value={permissionRoles}
                        style={{display:'inline-block',verticalAlign:'middle'}}
                        onChange={this.handlePermissionsRoleChange}>

                        <FormControlLabel value={VIEW_ONLY} control={<WhiteRadio />} label="View Only" />
                        <FormControlLabel value={VIEW_EDIT_SEPARATE} control={<WhiteRadio />} label="View and Edit Separately" />
                        <FormControlLabel value={BOTH} control={<WhiteRadio />} label="View and Edit Same" />
                    </ProppedRadioGroup>
                </div>
                <table style={{width:'100%'}}>
                    <tbody>
                        <tr>
                            {headers.map(mapHeader)}
                        </tr>
                        <tr style={{backgroundColor: '#eee', height: 95}}>
                            {headers.map(mapField.bind(null,"view", permissions.view))}
                        </tr>
                        <tr><td style={{height:'2em'}} /></tr>
                        <tr className={classnames} style={{backgroundColor: '#eee', height: 70}}>
                            {secondHeaders.map(mapField.bind(null,"edit", permissions.edit))}
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default PermissionsTable;
