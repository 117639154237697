import React, {useState, useEffect} from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from '@material-ui/core/StepConnector';
import { makeStyles } from '@material-ui/core/styles';
import {useSelector, useDispatch} from "react-redux";
import {STEPS_ADD_DEVICE, ADD_DEVICES_SCREEN} from '../../../constants/DeviceManager';
import {Redirect} from 'react-router-dom';
import FirstStepDataService from './FirstStepDataService';
import DeviceManagerToolbar from '../toolbars/DeviceManagerToolbar';
import ThirdStepContent from './ThirdStepContent';
import {loadDeviceManagerScreen, cleanDevicesBatch, detectSaveChanges} from '../../../actions';

const useStyles = makeStyles({
    stepActive: {
        color: "#455a64 !important"
    }
});

const AddViaZebraDataService = (props) => {
    const canEdit = useSelector(state => state.user.permissions['edit-infrastructure-devices']);
    const {pathParam} = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const [currentStep, setCurrentStep] = useState(0);
    const changeStep = (numberStep) => {
        setCurrentStep(numberStep);
    }


    const initAddDevices = () => {
        //mounting
        dispatch(cleanDevicesBatch());
        dispatch(loadDeviceManagerScreen(ADD_DEVICES_SCREEN));
        
        const unmount = () => {
            //unmounting
            dispatch(cleanDevicesBatch());
            dispatch(detectSaveChanges(false));
        };
        return unmount;
    }

    useEffect(initAddDevices, []);

    function getStepContent(stepIndex){
        switch(stepIndex){
            case 0:
                return <FirstStepDataService currentStep={currentStep} changeStep={changeStep} />;
            case 1:
                return <ThirdStepContent />
            default:
                return 'No content'
        }
    }

    return(
        <div className={"device-manager-container"}>
            <div className={"device-manager-header"}>
                <DeviceManagerToolbar location={pathParam}/>
            </div>
            
            {!canEdit ? <Redirect to={'/device-manager/devices'} /> : 
            <div className={"device-manager-content"}>
                <div className={"add-devices-page"}>
                    <Stepper className={"container-steppers-add-device"}
                        nonLinear activeStep={currentStep}
                        connector={<StepConnector style={{maxWidth:'100px',minWidth:'20px'}} />} >
                        {STEPS_ADD_DEVICE[pathParam].map((item, index) =>(
                            <Step key={index}>
                                <StepLabel StepIconProps={{classes: {active: classes.stepActive}}}>
                                    <span style={{fontWeight: (currentStep === index)?'bold':'normal'}}>{item}</span>                                        
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    {getStepContent(currentStep)}
                </div>
            </div>}
        </div>      
    )
}

export default AddViaZebraDataService;