const  ParameterAsyncConfigurations = require('./ParameterAsyncConfigurations');
const {
    MOTIONWORKS_BLUE
} = require('../../constants/Colors');

const MAIN_COLOR = MOTIONWORKS_BLUE;



module.exports = {
    type:"GRAPH",
    reportLink:"/reports/C0FB7CAD-8C25-11D5-9066-00105A6AE011", // resources report
    title:'Resources by Custom Attribute Value',
    dataProvider:{
        service:"report",
        url:"/dashboards/widgets/11/data"
    },
    parameters:[
        {
            type:'enum',
            name:"type",
            label:"Resource Type",
            async:ParameterAsyncConfigurations.RESOURCE_TYPE,
            required: true
        },
        {
            type:'multi-enum',
            name:"site",
            label:"Site",
            async:ParameterAsyncConfigurations.SITE_NAMES,
            required: true
        },{
            type:'enum',
            name:"attribute",
            label:"Attribute",
            async:ParameterAsyncConfigurations.CUSTOM_ATTRIBUTES,
            required: true
        }
    ],
    data: (data=[])=>({
        type: 'bar',
        data: {
            labels:data.map(d=>d["value"]? d["value"] : "[No Value]"),
            datasets: [{
                data:data.map(d=>d["cnt"]),
                backgroundColor:data.map(()=>MAIN_COLOR)
            }],
        },
        options: {
            layout:{
                padding:{
                    //top: 50
                }
            },
            legend:{
                display:false
            },
            scales:{
                xAxes:[
                    {
                        ticks:{
                            autoSkip: false,
                        }
                    }
                ],
                yAxes:[
                    {
                        ticks:{
                            beginAtZero:true,
                            callback: function(value) {if (value % 1 === 0) {return value;}},
                        }
                    }
                ]
            }
        }
    })
};
