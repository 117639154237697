import React from 'react';
import PropTypes from 'prop-types';

import SitesTreeList from './tree';

import AddIcon from '@material-ui/icons/Add';

import SearchButton from './SearchButton';
import FlatButton from '@material-ui/core/Button';

import {SITE_FORMS} from './dataentry/SitesDataEntryDialog';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../actions';


/******************************************************************************
███████ ██ ████████ ███████ ███████     ██████   █████  ███    ██ ███████ ██
██      ██    ██    ██      ██          ██   ██ ██   ██ ████   ██ ██      ██
███████ ██    ██    █████   ███████     ██████  ███████ ██ ██  ██ █████   ██
     ██ ██    ██    ██           ██     ██      ██   ██ ██  ██ ██ ██      ██
███████ ██    ██    ███████ ███████     ██      ██   ██ ██   ████ ███████ ███████
******************************************************************************/
class SitesTreePanel extends React.Component{

    static get propTypes(){
        return {
            actions: PropTypes.object,
            sites: PropTypes.array,
            siteGroups: PropTypes.array,
            maps: PropTypes.array,
            canEdit: PropTypes.bool,

            searchActive: PropTypes.bool,
            searchVal: PropTypes.any,
            hoverUID: PropTypes.any,

            onHoverSite: PropTypes.func,
            onSelectMap: PropTypes.func
        };
    }

    constructor(){
        super();
        this.state= {
            searchVal: "",
            searchActive: false
        };

        this.toggleSearch = this.toggleSearch.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }

    render(){
        const {
            actions,
            sites,
            siteGroups,
            maps,
            canEdit,

            hoverUID,
            onHoverSite,
            onSelectMap,
            upload_status
        } = this.props;

        const {
            searchActive,
            searchVal
        } = this.state;


        const headerHeight = "2.5em";

        let editActions = canEdit ? (
            <div style={{display:'inline-block'}}>
                <FlatButton
                    label="Group"
                    onClick={()=>actions.openSiteForm(SITE_FORMS.ADD_GROUP)}
                    style={{textTransform:'none', padding:'8px'}}
                    variant="text"
                ><AddIcon style={{marginRight:'3px'}}/>
                    Group</FlatButton>
                <FlatButton
                    label="Site"
                    style={{textTransform:'none',padding:'8px'}}
                    onClick={()=>actions.openSiteForm(SITE_FORMS.ADD_SITE)}
                    variant="text"
                >
                    <AddIcon style={{marginRight:'3px'}}/>Site</FlatButton>
            </div>

        ) : null;

        return (
            <div style={{top:'0',bottom:0,left:0,width:'100%',position:'absolute',zIndex:1,paddingTop:headerHeight,borderRight:'1px solid rgba(0,0,0,0.2)'}}>

                <div style={{height:headerHeight,position:'absolute',top:0,left:0,right:0}}>
                    <div style={{padding:'0.75em'}}>
                        <h3 style={{padding:0,margin:0,fontSize:'1.2em',color:'#777',fontWeight:'500',display:'inline-block',verticalAlign:'middle'}}>{"My Sites"}</h3>
                        <div style={{position:'absolute',top:0,right:0,padding:'0.25em'}}>
                            {editActions}
                            <SearchButton
                                searchActive={searchActive}
                                toggleSearch={this.toggleSearch}
                            />
                        </div>
                    </div>

                    <div style={{
                        height:'3em',
                        padding:'0.5em',
                        position:'absolute',
                        boxSizing:'border-box',
                        background:'#ddd',
                        top:0,
                        right:'2em',
                        left:0,
                        display:((searchActive) ? "block" : "none"),
                        zIndex:2
                    }}>
                        <div className="filled-up">
                            <input
                                className="filled-up"
                                style={{padding:'0.25em 2em 0.25em 0.5em',boxSizing:'border-box',height:'2em',border:'1px solid #aaa'}}
                                onChange={this.handleSearch}
                            />
                        </div>
                    </div>

                </div>
                <div className="filled-up">

                    <SitesTreeList
                        upload_status={upload_status}
                        searchVal={searchVal}
                        actions={actions}
                        hoverUID={hoverUID}
                        siteManagerSiteSelected={this.props.siteManagerSiteSelected}
                        sites={sites}
                        siteGroups={siteGroups}
                        maps={maps}
                        onHoverSite={onHoverSite}
                        onSelectMap={onSelectMap}
                        canEdit={canEdit}
                    />
                </div>
            </div>
        );
    }


    handleSearch(e){
        const val = e.currentTarget.value;

        this.setState({
            searchVal: (val || "")
        });
    }

    toggleSearch(){
        this.setState({
            searchActive: !this.state.searchActive
        });
    }
}


const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions,dispatch)
});

const mapStateToProps = state => ({
    sites: state.sites.sites,
    siteGroups: state.sites.siteGroups,
    maps: state.sites.maps,
    canEdit: state.user.permissions['edit-infrastructure-site manager'],
    siteManagerSiteSelected: state.reportMap.siteManagerSiteSelected
});

export default connect(mapStateToProps,mapDispatchToProps)(SitesTreePanel);
