export const APPLIANCES_ADD_APPLIANCE = "FORM_APPLIANCES_ADD_APPLIANCE";
export const APPLIANCES_UPDATE_APPLIANCE = "FORM_APPLIANCES_UPDATE_APPLIANCE";
export const APPLIANCES_UPDATE_CONFIG = "FORM_APPLIANCES_UPDATE_CONFIG";
export const APPLIANCES_UPDATE_FIRMWARE = "FORM_APPLIANCES_UPDATE_FIRMWARE";
export const APPLIANCES_DELETE_APPLIANCE = "FORM_APPLIANCES_DELETE_APPLIANCE";
export const APPLIANCES_DEBUG_PACKAGE = "FORM_APPLIANCES_DEBUG_PACKAGE";
export const APPLIANCES_COLLECT_LOGS = "FORM_APPLIANCES_COLLECT_LOGS";
export const APPLIANCES_FIRMWARE_HISTORY = "FORM_APPLIANCES_FIRMWARE_HISTORY";
export const APPLIANCES_SERVICES = "FORM_APPLIANCES_SERVICES";
export const APPLIANCES_REBOOT = "FORM_APPLIANCES_REBOOT";

export const APPLIANCES_VIEW_CONFIG = "FORM_APPLIANCES_VIEW_CONFIG";
export const APPLIANCES_DOWNLOAD_SERVICES_LOGS = "APPLIANCES_DOWNLOAD_SERVICES_LOGS";


export const DYNAMIC_DATA_ENTRY = "FORM_DYNAMIC_DATA_ENTRY";
export const SAVE_REPORT = "FORM_SAVE_REPORT";
export const DELETE_SAVED_REPORT = "FORM_DELETE_SAVED_REPORT";

export const USERMGMT_UPDATE_GROUP = "FORM_USERMGMT_UPDATE_GROUP";
export const USERMGMT_ADD_GROUP = "FORM_USERMGMT_ADD_GROUP";
export const USERMGMT_DELETE_GROUP = "FORM_USERMGMT_DELETE_GROUP";

export const USERMGMT_ADD_USER = "FORM_USERMGMT_ADD_USER";
export const USERMGMT_UPDATE_USER = "FORM_USERMGMT_UPDATE_USER";
export const USERMGMT_DELETE_USER = "FORM_USERMGMT_DELETE_USER";
export const USERMGMT_RESET_PASSWORD = "FORM_USERMGMT_RESET_PASSWORD";

export const USER_SETTINGS = "FORM_USER_SETTINGS";
export const CHANGE_PASSWORD = "FORM_CHANGE_PASSWORD";

export const ZLA_FILTER_PROFILE_MANAGER = "ZLA_FILTER_PROFILE_MANAGER";

export const ZONE_BUILDER_SAVE_ZONES = "ZONE_BUILDER_SAVE_ZONES";
export const ZONE_BUILDER_PUBLISH_ZONES = "ZONE_BUILDER_PUBLISH_ZONES";
