import React from 'react';
import PropTypes from 'prop-types';
import ZPage from './../../../../common/app/views/ZPage';

import SitesDataEntryDialog from './dataentry/SitesDataEntryDialog';
import styled from '@material-ui/styles/styled';

import Paper from "@material-ui/core/Paper";

import SitesTreePanel from './SitesTreePanel';
import HideResizePanels from './HideResizePanels';
import SitesMapPanelInfrastucture from './SitesMapPanelInfrastucture';

import {Toolbar} from '@material-ui/core';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../actions';
import {generateComponentId} from "../../utils/ComponentUtils";
import RightArrow from '@material-ui/icons/ChevronRight';
import LeftArrow from '@material-ui/icons/ChevronLeft';


/******************************************************************************
 ██ ██████   █████   ██████  ███████ ██
██  ██   ██ ██   ██ ██       ██       ██
██  ██████  ███████ ██   ███ █████    ██
██  ██      ██   ██ ██    ██ ██       ██
 ██ ██      ██   ██  ██████  ███████ ██
******************************************************************************/
class Sites extends React.Component{

    constructor(props,context){
        super(props,context);

        this.state = {
            t: (new Date()).getTime(),
            leftPanelVisible: !props.isMobile
        };

        this.handleTreeHoverSite = this.handleTreeHoverSite.bind(this);
        this.handleMapHoverSite = this.handleMapHoverSite.bind(this);

        this.handleSelectMap = this.handleSelectMap.bind(this);
        this.handlePanelResize = this.handlePanelResize.bind(this);
        this.handleLeftPanelVisible = this.handleLeftPanelVisible.bind(this);
    }

    static get propTypes(){
        return {
            actions: PropTypes.object.isRequired,
            selectedFloorMap: PropTypes.any,
            isMobile: PropTypes.bool,
        };
    }

    componentDidMount(){
        // may want to combine these later... not sure now.
        this.props.actions.requestSites();
        this.props.actions.requestSiteGroups();
        this.props.actions.requestMaps();

        // Invoke the first time so it does not wait the first 5 seconds
        this.props.actions.getMapUploadStatus();
        this.requestMapUploadStatusTimer = setInterval(this.props.actions.getMapUploadStatus, 5000);
    }

    componentWillUnmount(){
        clearInterval(this.requestMapUploadStatusTimer);
    }

    UNSAFE_componentWillUpdate(nextProps){
        if(nextProps !== this.props){
            // this.setState({
            //     leftPanelVisible: !nextProps.isMobile
            // })
        }
    }

    render (){
        const {isMobile,showMySites} = this.props;
        // const {leftPanelVisible} = this.state;
        const toolbar = (
            <ToolbarStyle disableGutters className="sites-toolbar">
                <button
                    id={generateComponentId("toggle_my_sites", "smr", "button")}
                    title="Toggle My Sites"
                    className="toggle-button"
                    onClick={(e)=>this.handleLeftPanelVisible()}
                >
                    {(showMySites)?<LeftArrow />:<RightArrow />}
                </button>
                <div className="title">
                    <span>Infrastructure</span>
                    <span> / </span>
                    <span>Site Manager</span>
                </div>
            </ToolbarStyle>
        );

        return (
            <ZPage>
                <div className="site-manager">
                    {toolbar}

                    <div className={"sites-page"} style={{padding:'1em 2em 0 2em'}}>
                        <HideResizePanels
                            leftPanel={this.renderTreePanel()}
                            rightPanel={this.renderMapPanel()}
                            onResize={this.handlePanelResize}
                            onLeftPanelVisible={this.handleLeftPanelVisible}
                            isMobile={isMobile}
                            leftPanelVisible={showMySites}
                        />
                    </div>
                </div>
                <SitesDataEntryDialog upload_status={this.props.upload_status} />
            </ZPage>

        );
    }

    handleLeftPanelVisible(){
        // this.setState({
        //     leftPanelVisible: !this.state.leftPanelVisible
        // });
        this.props.actions.toggleMySites();
    }

    handlePanelResize(){
        this.props.actions.updateMapSize();
        this.setState({
            t: (new Date()).getTime()
        });
    }

    renderTreePanel(){
        const {
            hoverUID
        } = this.state;

        return (
            <SitesTreePanel
                upload_status={this.props.upload_status}
                hoverUID={hoverUID}
                onHoverSite={this.handleTreeHoverSite}
                onSelectMap={this.handleSelectMap}
            />
        );
    }

    handleTreeHoverSite(siteUID,hovered){
        this.props.actions.changeSiteManagerSiteHover(siteUID,hovered);
        /*this.setState({
            hoverUID: hovered && siteUID
        });*/
    }

    handleSelectMap(mapId){
        this.props.actions.changeOlMap(mapId,true);
    }

    renderMapPanel(){

        const {
            hoverUID,
            t
        } = this.state;
        const {
            selectedFloorMap,
            upload_status
        } = this.props;

        return (
            <Paper className="vss-map" style={{position:'absolute',top:0,right:0,left:0,bottom:0}}>
                <SitesMapPanelInfrastucture
                    selectedFloorMap={selectedFloorMap}
                    uploadStatus={upload_status}
                    resizeTrigger={t}
                    hoverUID={hoverUID}
                    onHoverSite={this.handleMapHoverSite}
                    currentMap={this.props.currentMap}
                />
            </Paper>
        );
    }

    handleMapHoverSite(site){
        this.setState({hoverUID:site && site._id});
    }

}


const ToolbarStyle = styled(Toolbar)({
    minHeight: '48px'
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions,dispatch)
});

export default connect(state=>({
    showMySites: state.sites.showMySites,
    selectedFloorMap: state.sites.selectedFloorMap,
    upload_status: state.sites.upload_status,
    isMobile: state.resize.isMobile,
    currentMap: state.reportMap.currentMap
}),mapDispatchToProps)(Sites);
