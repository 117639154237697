import {cleanFilters, updateDatetimeFilters} from './main';
import {
    CHANGE_FILTER_VALUE,
    INITIAL_IMPORT_FILTERS,
    LOAD_IMPORT_HISTORY,
    REPORT_MOVE_FILTER,
    REPORT_TOGGLE_ALL_FILTERS,
    REPORT_TOGGLE_FILTER,
    REQUEST_REPORT
} from '../constants/ActionTypes';
import {displayErrorFromAxios, ImportService} from "./util";
import {
    exportImportReportResults,
    getImportFilters,
    parseFilterConstraints,
    parseFiltersToParams
} from './util/import-report';


export const getImportList = () => {
    return (dispatch, getState) => {
        const reportId = getState().report.reportId;
        updateDatetimeFilters(dispatch, getState());        
        const importFilters = getImportFilters(getState().filters, getState().report.sidebarColumnMeta[reportId]); 
        const filters = parseFiltersToParams(importFilters);
        return ImportService.instance().get('/report/imports', {params: filters})
            .then(response=>{
                dispatch({type:LOAD_IMPORT_HISTORY, importHistoryList: response.data});
            })
            .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};

export const getImportStatus = (reportId,formId,resultRowFrom) => {
    return dispatch => {
        return ImportService.instance().get('/report/status?reportId='+reportId+'&formId='+formId+'&resultRowFrom='+resultRowFrom)
            .then(response=>{
                return response;
            })
            .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};

export const getImportById = (importId) => {
    return dispatch => {
        return ImportService.instance().get('/report/status/'+importId)
            .then(response=>{
                return response;
            })
            .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};

export const importFromFile = (reportId,formId,inputFile,config) => {
    return dispatch => {
        return ImportService.instance().post('/report/upload?reportId='+reportId+'&formId='+formId,inputFile,config)
            .then(response=>{
                return response;
            })
            .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};

export const getImportFields = (importId) => {
    return dispatch => {
        return ImportService.instance().get('/report/'+importId)
            .then(response=>{
                return response;
            })
            .catch(displayErrorFromAxios.bind(null,dispatch));
    };  
};

/*
███████╗██╗██╗     ████████╗███████╗██████╗ ███████╗
██╔════╝██║██║     ╚══██╔══╝██╔════╝██╔══██╗██╔════╝
█████╗  ██║██║        ██║   █████╗  ██████╔╝███████╗
██╔══╝  ██║██║        ██║   ██╔══╝  ██╔══██╗╚════██║
██║     ██║███████╗   ██║   ███████╗██║  ██║███████║
╚═╝     ╚═╝╚══════╝   ╚═╝   ╚══════╝╚═╝  ╚═╝╚══════╝
*/

export const getFilterOptions = (name, statefilters) => {
    return (dispatch, getState) => {       
        const mainfilters = getState().filters;        
        const filtersImport = statefilters.map(item => mainfilters[item.filter]);
        const filterSelected = filtersImport.find(item => item.name === name);
        const constraints =  parseFilterConstraints(filtersImport, filterSelected);
        return ImportService.instance().get('/report/options/' + filterSelected.filterSelector, {
            params: constraints
        })
            .then(response => {
                const options = response.data.filter(option => option);
                return new Promise(resolve => resolve([options]));
                
            })
            .catch(displayErrorFromAxios.bind(null,dispatch));
    }
}

export const changeNormalFilterValue = (filterName, newValue) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        let filterId = null;
        for(var prop in filters) {
            if(filters[prop].name === filterName){
                filterId = prop
            }
        }
        //change default value in action filter when the user select other report action
        if(filterName==='report' && filters[filterId].value !== newValue){
            dispatch(changeNormalFilterValue('action','%'));
        }
        dispatch(changeFilterValueImport(filterId, newValue));
        dispatch(getImportList());     
    };
}

export const resetImportFilters = () => {
    return dispatch => {
        dispatch(cleanFilters());
        dispatch(getImportList());
    }   
}

export const toggleAllImportFilters = (event, checked) => {
    return dispatch => {
        const enabled = checked;
        dispatch({type: REPORT_TOGGLE_ALL_FILTERS, enabled});
    }
}

export const toggleImportFilter = (index, checked) => {
    return dispatch => {
       const enabled = checked;
        dispatch({type: REPORT_TOGGLE_FILTER, index, enabled});
    }
}

export const moveImportFilterItem = (dragIndex, hoverIndex) => {
    return dispatch => {
        dispatch({type: REPORT_MOVE_FILTER, dragIndex, hoverIndex});
        
    }
}

export const initialFiltersState = (filters) => {
    return dispatch => {
        dispatch({type: INITIAL_IMPORT_FILTERS, filters});
    }
}
export const exportImportResults = (format) => {
    return (dispatch, getState) => {
        const reportId = getState().report.reportId;
        const stateImport = getState().importReport;
        const importFilters = getImportFilters(getState().filters, getState().report.sidebarColumnMeta[reportId]);       
        exportImportReportResults(format, importFilters, stateImport.importHistoryList);
    }
}


export const requestImport = (reportId) => {
    return dispatch => {
        dispatch({type: REQUEST_REPORT, reportId});
    };
}

export const changeFilterValueImport = (filterId,value) => {
    return (dispatch, getState) => {
        //const state = getState();
        if(value){
            dispatch({type: CHANGE_FILTER_VALUE, filterId, value});
            dispatch(getImportList())       
        }
    };
};

export const changeFilterFromToValueImport = (fromId, fromValue, toId, toValue) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({type: CHANGE_FILTER_VALUE, filterId: fromId, value: fromValue});
        dispatch({type: CHANGE_FILTER_VALUE, filterId: toId, value: toValue});
        if(state.filters[fromId] && state.filters[fromId].value !== fromValue && state.filters[toId] && state.filters[toId].value !== toValue){            
            dispatch(getImportList())
        }
    };
};