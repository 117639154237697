import React from "react";

const ConfigurationDetails=(props)=>(
    <div className="dd-configuration-container" >
        <div className="dd-configuration-box">
            <div className="dd-configuration-box-title">
                Configuration state
            </div>
            <div className="dd-configuration-box-title" style={{fontStyle: 'oblique'}}>
                {props.configuration}
            </div>
        </div>
        <div className="dd-configuration-box">
            <div className="dd-configuration-box-title">
                Template
            </div>
            <div className="dd-configuration-box-title">
                {props.template?props.template:'No Template'}
            </div>
        </div>
        <div className="dd-configuration-box">
            <div className="dd-configuration-box-title">
                Configuration version
            </div>
            <div className="dd-configuration-box-title">
                {props.version}
            </div>
        </div>
        <div className="dd-configuration-box">
            <div className="dd-configuration-box-title">
                Operational status
            </div>
            <div className="dd-configuration-box-title">
                {props.operationalStatus}
            </div>
        </div>
    </div>
);

export default ConfigurationDetails;