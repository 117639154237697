import React, {useState} from "react";
import InfoIcon from '@material-ui/icons/Info';
import {useSelector} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
const DeviceStatus = ({status, rowIndex}) => {

    let styleIndicator={width:'100%',height:'100%',color:'#fff'};
    let label = status?status.title:"";
    let statusMessage = status?status.status:null;
    const device = useSelector(state => state.deviceManager.deviceList[rowIndex]);
    let currentStatus = device?.operation_status?.current_status;
    const [showMassageTooltip,setShowMassageTooltip]=useState(false);

    switch(label){
        case 'Running':
            styleIndicator.backgroundColor='#009c34';
            break;
        case 'Failure':
            styleIndicator.backgroundColor='#f02618';
            break;
        case 'Rebooting':
            styleIndicator.backgroundColor='#faa63a';
            break;
        case 'Stopped':
            styleIndicator.backgroundColor='#9b9b9b';
            break;
        case 'Disabled':
            styleIndicator.backgroundColor='#a9a9a9';
            styleIndicator.color='#eeeeee';
            break;
        case 'Initialized':
            styleIndicator.backgroundColor='#f3d745';
            styleIndicator.color='#000';
            break;
        case 'Publishing':
            styleIndicator.backgroundColor='#ff7c00';
            break;

        case '':
            break;
        default:
            styleIndicator={};
    }

    const showTooltip=()=>{
        setShowMassageTooltip(true);
    }
    const hiddenTooltip=()=>{
        setShowMassageTooltip(false);
    }

    const circularProgress = () => {
        return (
            <div style={{
                position: 'relative',
                alignItems: 'center',
                height: '34px',
                width: '22px',
                padding: '1px 0'
            }}>
                <CircularProgress style={{ marginTop:-12, color: "#00779f"}} variant={'indeterminate'} thickness={2.5} size={18}/>
            </div>
        )
    };


    return <div className={'device-status-container'} style={styleIndicator}>
            <span className={'device-status-label'}>{label}</span>
            {currentStatus === 'pending' ? circularProgress() :
                (statusMessage != null&&statusMessage!=='') ?
                    <div className={'device-status-tooltip-container'} onMouseOver={showTooltip} onMouseLeave={hiddenTooltip}>
                        <InfoIcon/>
                        {(showMassageTooltip)?<div className={'device-status-tooltip'}><span>{statusMessage}</span></div>:null}
                    </div> : null
            }
        </div>;
}

export default DeviceStatus;
