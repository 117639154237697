import React from 'react';
import PropTypes from 'prop-types';

import {connect} from "react-redux";
import {bindActionCreators} from 'redux';

import * as actions from '../../../actions';
import {Paper} from "@material-ui/core";

import ImportFilter from './filter/ImportFilter';
import ImportBox from './ImportBox';
import ImportResultsTable from './ImportResultsTable';

class FormImport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            //backgroundRow: [],
            backgroundErrorRows: [],
            filteredDataQuery: [],

            //options for animation divs
            sidebarCollapsed: this.props.isMobile,
        };

        //width of a column
        // this.getBaseColumnWidth = this.getBaseColumnWidth.bind(this);
        // this.handleResizeColumn = this.handleResizeColumn.bind(this);
    }

    static get propTypes() {
        return {
            name: PropTypes.string
        };
    };

    // //width of a column
    // getBaseColumnWidth({index}){
    //     const {errorColumns,columnWidths} = this.state;
    //     return columnWidths[errorColumns[index].name] || 200;
    // }

    // handleResizeColumn(index,width){
    //     const {errorColumns,columnWidths} = this.state;
    //     if (errorColumns[index] && errorColumns[index].name) {
    //         this.setState({
    //             columnWidths:{
    //                 ...columnWidths,
    //                 [errorColumns[index].name] : width
    //             }
    //         });
    //     }
    // }

    UNSAFE_componentWillReceiveProps(nextProps){
        this.props.countingResults(nextProps.importHistoryList.length);

        let sidebarCollapsed = this.state.sidebarCollapsed;
        if (!this.state.firstToggled) {
            sidebarCollapsed = nextProps.isMobile;
        }
        const changeset = {};
        if (nextProps.showRightSidebar !== this.props.showRightSidebar) {
            changeset.animationEnabled = true;
        }
        this.setState({
            sidebarCollapsed,
            ...changeset,
            filteredDataQuery: nextProps.importHistoryList
        });

    }

    render() {
        //For Import Results Table
        const {filteredDataQuery} = this.state;
        const {actions, collapsed} = this.props;
        const {windowWidth, windowHeight} = this.props;

        //For ImportBox
        const {userMenu, reloadReport} = this.props;

        //Shared propertie
        const isMobile = this.props.isMobile;

        //lets for animation divs
        const sidebarSizeStyle = {
            left: '0px',
        };
        //let totalWidthOffset = 0;

        if (!this.state.sidebarCollapsed) {
            sidebarSizeStyle.left = `${this.state.leftSidebarWidth}px`;
            //totalWidthOffset += this.state.leftSidebarWidth;
        }

        return (
            <div className={"home-page"}>
            <Paper style={{ boxShadow: "0", margin:0,minHeight:'5em', top: "48px"}}>
                <div className="home-container">

                    {
                        collapsed &&
                        <ImportFilter isMobile = {isMobile}/>
                    }

                    <div className={"import-container"} style={sidebarSizeStyle} >
                        <ImportBox
                            collapsed = {collapsed}
                            isMobile = {isMobile}
                            userMenu = {userMenu}
                            actions = {actions}
                            onFinishImport = {reloadReport}//optional
                        />
                        <hr style={{float: "left", width: "99%"}} />
                        <ImportResultsTable
                            data = {filteredDataQuery}
                            windowWidth = {windowWidth}
                            windowHeight = {windowHeight}
                            isMobile =  {isMobile}

                            getImportDetails = {actions.getImportById}
                        />
                    </div >
                </div>
            </Paper>
            </div>

        );
    };

}

// //delete actions.default;

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = state => ({
    windowWidth: state.resize.windowWidth,
    windowHeight:state.resize.windowHeight,
    isMobile: state.resize.isMobile,
    userMenu: state.user.menu,
    showRightSidebar:state.report.showRightSidebar,

    filters: state.importReport.filters
});

export default connect(mapStateToProps, mapDispatchToProps)(FormImport);