import {combineReducers} from 'redux';
import {user} from './user';
import {snackbar} from './snackbar';
import {view} from './view';
import {resize} from './resize';
import {dataEntry} from './dataEntry';
import {report} from './report';
import {sites} from './sites';
import {filters} from './filters';
import {errorDialog} from './errorDialog';
import {appliances} from './appliances';
import {userMgmt} from './userMgmt';
import {reportMap} from './reportMap';
import {zoneBuilder} from './zoneBuilder';
import {importReport} from './importReport';
import {resourceTypePrint} from './resourceTypePrint';
import {deviceManager} from './deviceManager';
import {notification} from "./notification";
import {dashboards} from "./dashboards";
import {zebraDataService} from "./zebraDataService";

const rootReducer = combineReducers({
    notification,
    user,
    snackbar,
    view,
    resize,
    dataEntry,
    report,
    reportMap,
    sites,
    filters,
    errorDialog,
    appliances,
    userMgmt,
    zoneBuilder,
    importReport,
    resourceTypePrint,
    deviceManager,
    dashboards,
    zebraDataService,
});

export default rootReducer;
