import React, {memo, useEffect, useRef, useState} from "react";
import {AutoSizer} from "react-virtualized";
import CustomVariableSizeGrid from "./CustomVariableSizeGrid";
import {defaultCellHeight, checkboxWidth, calculateAccumulatedWidth, calculateWidthOfExtraColumn} from "./Util";
import Cell from "./Cell";
import CheckboxCell from "./Checkboxcell";
import CheckAll from "./CheckAll";
// import AppliedFilters from "./AppliedFilters";

const TableIe=(props)=>{
    // const [computedHeaderInfo,setComputedHeaderInfo] = useState(calculateAccumulatedWidth(props.headerRow));
    const computedHeaderInfo = calculateAccumulatedWidth(props.headerRow);
    const [addedCellWidth, setAddedCellWidth] = useState(0);
    const [scrollMarginHeader,setScrollMarginHeader] = useState(0);
    const [scrollbarHorWidth, setScrollbarHorWidth] = useState(0);
    const headerGrid = useRef();
    const leftGrid = useRef();
    const centerGrid = useRef();

    useEffect(() => {
        // Calculate the data of width of each column.
        // setComputedHeaderInfo(calculateAccumulatedWidth(props.headerRow));
        // Reset cache of widths in react-window component.
        headerGrid.current.resetAfterColumnIndex(0);
        centerGrid.current.resetAfterColumnIndex(0);

    }, [props.headerRow, addedCellWidth]);
    const checkBoxWidth = (props.showCheckColumn===true)?checkboxWidth:0;

    const columnWidthByIndex=(index,addedCellWidth)=>{
        const widthCell = (props.showCheckColumn && index === 0)? props.headerRow[index].width : props.headerRow[index].width+addedCellWidth;
        // const widthCell = props.headerRow[index].width+addedCellWidth;
        return widthCell;
    };

    const HeaderRowIe = (propsHeader)=>{
        const {
            columnIndex,
            //rowIndex,
            style,
            tableWidth,
            tableHeight,
            headerRow,
        }=propsHeader;

        const text=headerRow[columnIndex]["value"];
        const itemKey=headerRow[columnIndex]["key"];

        return (<div key={"headerRow" + columnIndex} className={"table-sticky-row-cell"} style={style}>
            {(props.headerRender!=null)?props.headerRender({text,itemKey,rowIndex:0,columnIndex:columnIndex,tableWidth:tableWidth,tableHeight:tableHeight}):text}
        </div>);
    };


    const StickyColumnIe = memo(props=>{

        const {
            //columnIndex,
            rowIndex,
            style,
            selectedRows,
            onClick,
            //appliedFiltersHeight,
            rowDisabled,
        }=props;


        const rowSelected=(selectedRows[rowIndex]!=null)?true:false;
        const selectedClassName=(rowSelected)?" table-cell-selected":"";
        const styleRow=rowDisabled ? ((rowIndex%2===0)?" cell-error-even":" cell-error-odd") : ((rowIndex%2===0)?" cell-even":" cell-odd");
        return (

                <CheckboxCell
                              style={style}
                              checked={rowSelected}
                              className={selectedClassName+styleRow}
                              onClick={onClick}
                              columnIndex={0}

                              rowIndex={rowIndex}

                />

        )});

    return (
        <AutoSizer>
            {({ height, width }) => {
                // calculating the height of the table 
                const heightContent = props.rowCount ? defaultCellHeight * (props.rowCount + 1): 0;
                // assign a scrollbar width
                let scrollbarVerWidth = (heightContent > height)?20:0;

                const headerRow=computedHeaderInfo.headerRow;
                let allColumnWidth=(headerRow[Object.keys(headerRow).length-1].accumulatedWidth)+headerRow[Object.keys(headerRow).length-1].width+scrollbarVerWidth;
                if(props.fillWidth===true){
                    if(width>allColumnWidth){
                        setAddedCellWidth(Math.round((width-allColumnWidth)/(Object.keys(headerRow).length - 1)));
                        setScrollbarHorWidth(0);
                    }else{
                        setScrollbarHorWidth(16);
                    }
                }else{
                    calculateWidthOfExtraColumn(props.headerRow,width,allColumnWidth);
                    /*if(width>allColumnWidth){
                        setScrollbarHorWidth(0);
                    }else{
                        setScrollbarHorWidth(16);
                    }*/
                }

                return (
                <div className={"table-react-window-container table-ie-container"} style={{width:width,height:height}}>
                    <div className={"table-ie-row"} style={{height:defaultCellHeight}}>
                        {props.showCheckColumn&&<div className={"table-ie-row-all"} style={{position:'absolute',zIndex:4}}>
                        <div style={{width:checkboxWidth,height:defaultCellHeight,overflow:'hidden'}}>
                            <CheckAll
                                onSelectAll={props.onSelectAll}
                                checked={(props.rowCount===Object.keys(props.selectedRows).length)?true:false} />

                        </div>
                        </div>}


                        <div className={"table-ie-row-fixed"}>
                            <CustomVariableSizeGrid
                                ref={headerGrid}
                                className="table-sticky-row-container"
                                columnCount={props.headerRow.length}
                                rowCount={1}
                                columnWidth={(rest)=>columnWidthByIndex(rest,addedCellWidth)}
                                rowHeight={index=>defaultCellHeight}
                                width={width-checkboxWidth}
                                height={defaultCellHeight}
                                style={{
                                    overflow: 'hidden',
                                    position:'relative',
                                    zIndex:2,
                                    backgroundColor: 'lightgray',
                                    marginLeft:-scrollMarginHeader+'px',
                                    minWidth:'1000px',
                                }}
                            >
                                {({columnIndex, rowIndex, style})=><HeaderRowIe
                                                columnIndex={columnIndex}
                                                rowIndex={rowIndex}
                                                style={style}
                                                tableWidth={width}
                                                tableHeight={height}
                                                headerRow={props.headerRow}
                                            />}
                            </CustomVariableSizeGrid>
                        </div>
                    </div>
                    <div className={"table-ie-row"} style={{width:width}}>
                        {/*<AppliedFilters
                            appliedFilters={props.appliedFilters}
                            appliedFiltersHeight={props.appliedFiltersHeight}
                            width={width}
                        />*/}
                    </div>
                    {props.rowCount === 0 && <div className={"table-ie-row"}>No results</div>}
                    <div className={"table-ie-row"}>
                        {props.showCheckColumn&&<div className={"table-ie-column-fixed"} style={{position:'absolute',zIndex:4}}>
                            <CustomVariableSizeGrid
                                ref={leftGrid}
                                columnCount={1}
                                rowCount={props.rowCount}
                                columnWidth={index=>checkBoxWidth}
                                rowHeight={index=>defaultCellHeight}
                                width={checkboxWidth}
                                height={height-defaultCellHeight-props.appliedFiltersHeight-scrollbarHorWidth}
                                onClick={props.onClickRow}
                                selectedRows={props.selectedRows}
                                style={{
                                    overflowX: 'hidden',
                                    overflowY: 'hidden'
                                }}
                            >
                                {({columnIndex, rowIndex, style})=><StickyColumnIe
                                    columnIndex={columnIndex}
                                    rowIndex={rowIndex}
                                    style={style}
                                    tableWidth={width}
                                    tableHeight={height}
                                    headerRow={props.headerRow}
                                    selectedRows={props.selectedRows}
                                    cellRender={props.cellRender}
                                    dataArray={props.data}
                                    onClick={props.onClickRow}
                                    rowDisabled={props.rowsValidationError && props.rowsValidationError[rowIndex] && props.rowsValidationError[rowIndex].disabled}
                                />}
                            </CustomVariableSizeGrid>
                        </div>}
                        <div className={"table-ie-content"}>
                            <CustomVariableSizeGrid
                                ref={centerGrid}
                                columnCount={props.headerRow.length}
                                rowCount={props.rowCount}
                                columnWidth={(rest)=>columnWidthByIndex(rest,addedCellWidth)}
                                rowHeight={index=>defaultCellHeight}
                                width={width}
                                height={height-defaultCellHeight-props.appliedFiltersHeight}
                                stickyIndices={[0, 1]}
                                onScroll={({scrollLeft, scrollTop}) => {
                                    headerGrid.current.scrollTo({scrollLeft});
                                    setScrollMarginHeader(scrollLeft);
                                    props.showCheckColumn&&leftGrid.current.scrollTo({scrollTop});
                                }
                                }
                            >
                                {({columnIndex, rowIndex, style})=><Cell
                                    columnIndex={columnIndex}
                                    rowIndex={rowIndex}
                                    style={style}
                                    tableWidth={width}
                                    tableHeight={height}
                                    headerRow={props.headerRow}
                                    selectedRows={props.selectedRows}
                                    cellRender={props.cellRender}
                                    dataArray={props.data}
                                    onClick={props.onClickRow}
                                    rowDisabled={props.rowsValidationError && props.rowsValidationError[rowIndex] && props.rowsValidationError[rowIndex].disabled}
                                />}
                            </CustomVariableSizeGrid>
                        </div>
                    </div>
                </div>
            )}}
        </AutoSizer>
    )
}
export default TableIe;