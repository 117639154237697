import React from 'react';
import PropTypes from 'prop-types';
import StyledDialog, {CancelButton, SaveButton} from '../util/Dialog';

import {Field, Form} from '../util/DataEntryTabularForm';
import ZToggle from "./ZToggle";
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../../actions';
import * as forms from '../../constants/DataEntryForms';
import {withRouter} from 'react-router-dom';

class UserSettingsDialog extends React.Component{
    constructor(){
        super();
        this.handleClose = this.handleClose.bind(this);
        this.handleSave = this.handleSave.bind(this);

        this.handleRefreshRateChange = this.handleRefreshRateChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);

        this.handleZoomUserChoice = this.handleZoomUserChoice.bind(this);

        this.state = {
            autoRefreshEnabled: false,
            defaultRefreshRate: 10
        };
    }
    static get propTypes(){
        return {
            open: PropTypes.bool,
            close: PropTypes.func.isRequired,
            showError: PropTypes.func.isRequired,
            getRefreshRate: PropTypes.func.isRequired,
            setRefreshRate: PropTypes.func.isRequired
        };
    }

    componentDidMount(){
        this.fetchAndUpdateRefreshRate();

    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(nextProps.open && !this.props.open){
            this.fetchAndUpdateRefreshRate();
        }
    }

    fetchAndUpdateRefreshRate(){
        this.props.getZoomFeature();
        this.props.getRefreshRate()
        .then(rr=>{
            this.setState({
                autoRefreshEnabled: true,
                refreshRate: rr
            });
        }).catch(()=>{
            this.setState({
                autoRefreshEnabled: false
            });
        });
    }

    shouldComponentUpdate(nextProps){
        return (nextProps.open || this.props.open);
    }
    handleSave(){

        const cleanAfterSuccess = ()=>{
            this.props.close();
            this.props.showError({
                title:"Success",
                message: "User settings saved.",
                noconsole: true
            });
        };

        if(!this.state.autoRefreshEnabled){
            this.props.setRefreshRate(null)
            .then(cleanAfterSuccess);
            return;
        }


        const rr = parseInt(this.refs.refresh_interval.value);

        if(rr <= 0){
            this.props.showError({
                message:"Interval must be a positive integer"
            });
            return;
        }

        if(rr > 0 && rr < 5){
            this.props.showError({
                message:"The minimum value of the interval is 5"
            });
            return;
        }

        this.props.setRefreshRate(rr)
        .then(cleanAfterSuccess);
    }

    handleClose(){
        this.props.close();
    }

    render() {
        const {autoRefreshEnabled,refreshRate} = this.state;
        const { zoomObject } = this.props.feature;

        return (
            <StyledDialog
                onRequestClose={this.handleClose}
                onClose={this.handleClose}
                style={{overflowY:'auto'}}
                contentStyle={{maxWidth: 360}}
                title={'User Settings'}
                actions={
                    <>
                        <SaveButton id={"usersettings_save_btn"} onClick={this.handleSave} />
                        <CancelButton id={"usersettings_cancel_btn"} style={{position: 'absolute', left: 8}} onClick={this.handleClose} />
                    </>
                }
                {...this.props}
                >
                <Form>
                    <Field label={"Auto-Refresh"}>
                        <select id={"autorefresh_chk"} ref="enabled" value={autoRefreshEnabled} onChange={this.handleChangeSelect}>
                            <option value={false}>Disabled</option>
                            <option value={true}>Enabled</option>
                        </select>
                    </Field>
                    <Field label={"Refresh Interval (sec)"}>
                        <input id={"refreshintervalNum_txt"} style={{display:autoRefreshEnabled ? "block":"none"}} type={"number"} ref="refresh_interval" min="5"  value={refreshRate || ''} onChange={this.handleRefreshRateChange}/>
                        <input id={"refreshinterval_txt"} style={{display:autoRefreshEnabled ? "none":"block"}} type={"text"} disabled value={"N/A"}/>
                    </Field>
                    <Field label={"Map Zoom is Based on Selected Object(s)"}>
                        <ZToggle id={"mapzoom_chk"} onText={"Yes"} offText={"No"} on={zoomObject} onChange={this.handleZoomUserChoice}/>
                    </Field>
                </Form>
            </StyledDialog>
        );
    }

    handleRefreshRateChange(e){        
        this.setState({
            refreshRate: e.currentTarget.value
        });      
    }

    handleChangeSelect(e){
        const autoRefreshEnabled = e.currentTarget.value === "true";
        this.setState({
            autoRefreshEnabled,
            refreshRate:5
        });
    }

    handleZoomUserChoice(arg,e){
        this.props.zoomFeature({ zoomObject: arg, zoomReport: false})

    }
}

const mapStateToProps = state => ({
    open: state.dataEntry.open && (state.dataEntry.activeForm === forms.USER_SETTINGS),
    feature: state.user.feature

});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        close:actions.closeDataEntry,
        showError: actions.displayErrorDialog,
        getRefreshRate: actions.getRefreshRate,
        setRefreshRate: actions.setRefreshRate,
        zoomFeature: actions.zoomFeature,
        getZoomFeature: actions.getZoomFeature
    },dispatch)
});

export default withRouter( connect(mapStateToProps,mapDispatchToProps)(UserSettingsDialog) );
