import React, {useEffect,useState, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {loadSiteAndMap, unloadSiteAnMap, unloadDevicesOptions, loadDevicesOptions, reloadSitesMaps, getZones, loadCoordinatesOfZonesOnMap, cleanImportedFiles} from "../../../actions";
import WorldMapContainer from '../common/WorldMapContainer';
import LocalMapContainer from '../common/localMapComponent/LocalMapContainer';
import DevicesListContainer from '../common/DevicesListContainer';

// import MapDevicesScreen from '../common/MapDevicesScreen';


const ProvideDeviceDetails = (props) => {
    const dispatch = useDispatch();
    const localMapContainerRef = useRef();
    const mapRef = useRef();
    const sourceRef = useRef();
    const [showThumbnails, setShowThumbnails] = useState(false);
    const [showDeviceLabels, setShowDeviceLabels] = useState(false);
    const [idSelected, setIdSelected] = useState(null);

    const siteId = useSelector(state => state.deviceManager.siteId);
    const mapId = useSelector(state => state.deviceManager.mapId);
    const mapSelected = useSelector(state => state.deviceManager.mapSelected);
    const sites = useSelector(state => state.deviceManager.sites);
    const zones = useSelector(state => state.deviceManager.zones[siteId] || []);
    const mapDevices = useSelector(state => state.deviceManager.mapDevices);
    const mapBounds = useSelector(state => state.deviceManager.mapBounds);
    const mapZoom = useSelector(state => state.deviceManager.mapZoom);
    const devicesBatch = useSelector(state => state.deviceManager.devicesBatch);
    const deviceManagerScreen = useSelector(state => state.deviceManager.deviceManagerScreen);

    const getSiteAndMap = () => {
        //mounting
        if(!sites){
            dispatch(reloadSitesMaps(null, null, true));
        }

        const unmount = () => {
            dispatch(unloadSiteAnMap());
            dispatch(unloadDevicesOptions());
            dispatch(cleanImportedFiles());
        };
        return unmount;
    };

    useEffect(getSiteAndMap, []);

    const putSiteDefault = () => {
        let defaultSiteIdSelected, defaultMapIdSelected;
        if(mapSelected && mapSelected.siteId){
            defaultSiteIdSelected = mapSelected.siteId;
            defaultMapIdSelected = mapSelected.id?mapSelected.id:null;
        }else if(sites && sites.length > 0){
            defaultSiteIdSelected = siteId?siteId:sites[0].id;
            defaultMapIdSelected = mapId?mapId:null;
        }
        dispatch(loadSiteAndMap(defaultSiteIdSelected, defaultMapIdSelected));
    }

    useEffect(putSiteDefault, [sites]);

    const handleLoadObjectDevices = () => {
        dispatch(loadDevicesOptions(devicesBatch));
    };

    useEffect(handleLoadObjectDevices, [devicesBatch]);

    const loadZones = () => {
        if(siteId){
            dispatch(getZones(siteId));
        }

        if(siteId && mapId){
            const listDeviceIds = devicesBatch.map(device => device._id);
            dispatch(loadCoordinatesOfZonesOnMap(siteId, mapId, listDeviceIds));
        }
    }

    useEffect(loadZones, [siteId]);

    const selectItemPanel = (id) => {
        setIdSelected(id);
    }

    const addDeviceFeature = (evt, id) => {
        if(localMapContainerRef.current !== undefined){
            localMapContainerRef.current.addDeviceFeature(evt, id);
        }
    };

    const changeCoordinatesFeature = (evt, id, coordinates) => {
        if(localMapContainerRef.current !== undefined){
            localMapContainerRef.current.changeCoordinatesFeature(evt, id, coordinates);
        }
    }

    const callback = (val) => {
        setShowThumbnails(val)
    };

    const deviceLabelsCallback = (val) => {
        setShowDeviceLabels(val);
    };

    return (
        <div className={"container-body"}>
            <DevicesListContainer devicesBatch={devicesBatch}
                    idSelected={idSelected}
                    selectItemPanel={selectItemPanel}
                    mapRef={mapRef.current}
                    sourceLayer={sourceRef.current}
                    showDeviceLabels={showDeviceLabels}
                    zones={zones}
                    addDeviceFeature={mapBounds?addDeviceFeature:()=>{}}
                    changeCoordinatesFeature={mapBounds?changeCoordinatesFeature:()=>{}}
                    />
            <div className={"map-main-container"} >
                {siteId ? <LocalMapContainer
                    ref={localMapContainerRef}
                    siteId={siteId}
                    mapId={mapId}
                    mapDevices={mapDevices}
                    mapBounds={mapBounds}
                    mapZoom={mapZoom}
                    zonesOnMap={zones}
                    showThumbnails={showThumbnails}
                    parentCallback={callback}
                    showDeviceLabels={showDeviceLabels}
                    deviceLabelsCallback={deviceLabelsCallback}
                    devicesBatch={devicesBatch.filter(item => item.siteId === siteId && Number(item.location.mapID) === mapId)}
                    deviceManagerScreen={deviceManagerScreen}
                    idSelected={idSelected}
                    selectItemPanel={selectItemPanel}
                /> : <WorldMapContainer />}
            </div>
        </div>
    )
};

export default ProvideDeviceDetails;