import React, {useEffect} from 'react';
import { useSelector } from "react-redux";
// import { updateStateFormTemplate } from "../../../../actions";
// import {HIGHLIGHT_STYLE} from '../../../../constants/DeviceManager';

const DataUrlPanel = () => {
    // const dispatch = useDispatch();
    const dataURL = useSelector(state => state.deviceManager.templateForm.template.dataURL);
    // const handleChange = event => {
    //     const key = event.target.name;
    //     document.getElementById(`blink-${key}`).style["box-shadow"] = HIGHLIGHT_STYLE;
    //     dispatch(updateStateFormTemplate('dataURL', null, event.target.value));
    // };

    useEffect(() => {}, [dataURL]);
    // disabled the Data URL temporarily changed placeholder to N/A and removed handleChange event and value field, compare with previous version for all the changes 
    return (
        <div className="device-items">
            <div className="basic-input" style={{margin:'0 -0.5em'}}>
                <div className="field">
                    {/*<span className="items-header">Data URL</span>*/}
                    <input id='blink-url' type={"text"}
                        placeholder={"N/A"}
                        name={'url'}
                        value={''}
                        disabled/>
                </div>
            </div>
        </div>
    )
}

export default DataUrlPanel;
