import React from 'react';
import PropTypes from 'prop-types';
import HomePage from './Home';

import {ReportActionButton} from './toolbars/ReportsToolbar';
import DeleteIcon from '@material-ui/icons/Delete';

import Promise from 'promise';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../../actions';
import {DELETE_SAVED_REPORT} from '../../constants/DataEntryForms';

class SavedReportsPage extends React.Component{

    constructor(){
        super();
        this.handleDeleteReportClick = this.handleDeleteReportClick.bind(this);
    }

    static get propTypes(){
        return {
            match: PropTypes.object,
            requestSavedReport: PropTypes.func.isRequired,
            savedReport: PropTypes.object,
            columnOrder: PropTypes.array,
            displayErrorDialog: PropTypes.func.isRequired,
            openDeleteSavedReportForm: PropTypes.func.isRequired
        };
    }
    requestSavedReport(props){
        var report = props.savedReport;

        var pr = Promise.resolve(props.savedReport);

        if(!report || !report.id){
            pr = props.requestSavedReportDefinition(props.match.params.reportId);
        }

        return pr.then((report)=>{
            return props.requestSavedReport(report);
        })
        .catch(props.displayErrorDialog);
    }
    componentDidMount(){
        this.props.getZoomFeature();
        this.requestSavedReport(this.props);
    }
    UNSAFE_componentWillReceiveProps(nextProps){
        if(this.props.savedReport !== nextProps.savedReport){
            this.requestSavedReport(nextProps);
        }
    }

    handleDeleteReportClick(){
        this.props.openDeleteSavedReportForm();
    }

    render(){
        const {savedReport, columnOrder, sidebarColumnOrder} = this.props;

        if(!savedReport){
            return null;
        }
        return (
            <HomePage
                {...this.props}
                reportCategory={"Saved"}
                reportName={savedReport.name}
                columnOrder={columnOrder}
                sidebarColumnOrder={sidebarColumnOrder}
                additionalReportActions={[
                    <ReportActionButton onClick={this.handleDeleteReportClick} key="delete-saved-report">
                        <DeleteIcon />
                    </ReportActionButton>
                ]}
            />
        );
    }
}


const DEFAULT_COLUMN_ORDERS = {};
const DEFAULT_SIDEBAR_COLUMN_ORDER = {};

const mapStateToProps = (state,props) => {

    let columnOrders = state.report.columnOrder || DEFAULT_COLUMN_ORDERS;
    let sidebarColumnOrder = state.report.sidebarColumnOrder || DEFAULT_SIDEBAR_COLUMN_ORDER;
    let report = state.user.savedReports[props.match.params.reportId];

    return ({
        savedReport: report,
        feature: state.user.feature,
        columnOrder: (report && (columnOrders[report.id] || columnOrders[report.baseReportId])) || [],
        sidebarColumnOrder: (report && (sidebarColumnOrder[report.id] || sidebarColumnOrder[report.baseReportId])) || []
    });
};

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        getZoomFeature: actions.getZoomFeature,
        requestSavedReportDefinition: actions.requestSavedReportDefinition,
        requestSavedReport:actions.requestSavedReport,
        displayErrorDialog: actions.displayErrorDialog,
        loadSavedReport: actions.loadSavedReport,
        openDeleteSavedReportForm: actions.openDataEntry.bind(null,DELETE_SAVED_REPORT)
    },dispatch)
});

export default connect(mapStateToProps,mapDispatchToProps)(SavedReportsPage);
