import React from 'react';
import PropTypes from 'prop-types';

import Paper from "@material-ui/core/Paper";

export const TITLE_BAR_HEIGHT = "0.97em";
export const TITLE_BAR_TOP = "1.5em";

const PAPER_STYLES = {
    position:'absolute',
    top:0,
    left:0,
    right:0,
    height:TITLE_BAR_HEIGHT,
    padding:'0.1em 1em 0.4em',
    zIndex:2,
    color:'white',
    boxShadow: '0',
    borderRadius: '0'
};

export default class WidgetTitleBar extends React.Component{
    static get propTypes(){
        return {
            title: PropTypes.string,
            style: PropTypes.object,
            depth: PropTypes.number
        };
    }

    static get defaultProps(){
        return {
            depth:2
        };
    }

    render(){
        const {title,style} = this.props;
        return (
            <Paper className="tile-title" style={{...PAPER_STYLES,style}} >
                <span style={{letterSpacing:'0.5px',fontSize:'0.9em',fontWeight:'300'}}>{title}</span>
            </Paper>
        );
    }
}
