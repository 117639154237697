import React from "react";
import PropTypes from 'prop-types';
import CheckIcon from '@material-ui/icons/Check';
import StyledDialog from '../../Dialog';
import {Field, Form} from '../../DataEntryTabularForm';
import LinearProgress from "@material-ui/core/LinearProgress";
import {withStyles} from '@material-ui/core/styles';
import {isAllOptionLookup, isAllWrite} from "./util";
import {OPTION_ALL_TEXT, OPTION_ALL_WILDCARD} from "../../../../constants/Misc";
import {Button} from "@material-ui/core";
import Clear from '@material-ui/icons/Clear';
import MultiSelectIcon from '../../../icons/MultiSelectIcon';
import ReactToolTip from 'react-tooltip';

/*
██       ██████   ██████  ██   ██ ██    ██ ██████
██      ██    ██ ██    ██ ██  ██  ██    ██ ██   ██
██      ██    ██ ██    ██ █████   ██    ██ ██████
██      ██    ██ ██    ██ ██  ██  ██    ██ ██
███████  ██████   ██████  ██   ██  ██████  ██

██████  ██  █████  ██       ██████   ██████
██   ██ ██ ██   ██ ██      ██    ██ ██
██   ██ ██ ███████ ██      ██    ██ ██   ███
██   ██ ██ ██   ██ ██      ██    ██ ██    ██
██████  ██ ██   ██ ███████  ██████   ██████
*/

const loadingValuesFromService = () =>{
    return(
        <div style={{
            height: "100%",
            margin: "auto",
            lineHeight: "7em",
            textAlign: "center",
            width: "17em",
            fontSize: "0.9em",
        }}>Getting Data From the Server...<ColoredLinearProgress mode="indeterminate" style={{background:'rgba(0,0,0,0.25)',marginTop:'1em'}}/></div>
    );
};

const ColoredLinearProgress = withStyles({
    colorPrimary:{
        backgroundColor: 'gray'
    },
    barColorPrimary: {
        backgroundColor: '#00779F'
    }
})(LinearProgress);

const getSelectedValues = (value, isMultiselectAllowed) => {
    value = value === 'All' ? OPTION_ALL_WILDCARD : value;
    if (isMultiselectAllowed) {
        const valuesSelected = Array.isArray(value) ? [...value] : (value !== OPTION_ALL_WILDCARD && value !== "" ? value.split(',') : []);
        const newValuesSelected = [...new Set(valuesSelected)];
        return [valuesSelected, newValuesSelected]
    }

    const newValuesSelected = value && value !== OPTION_ALL_WILDCARD ? [...new Set([value])] : [];
    return [value, newValuesSelected];
}

class LookupDialog extends React.Component{

    constructor(props){
        super(props);
        this.state= {
            // value: [],
            value: props.value,
            options:[],
            lookup: props.isMultiselectAllowed ? '' : props.value || "%",
            loadingValues: props.loadingValues,
            optionSelected:false,
            valuesSelected: props.isMultiselectAllowed && props.value !== OPTION_ALL_WILDCARD && props.value !== '' ? props.value.split(",") : [],
        };

        this.handleSearch = this.handleSearch.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleTapValue = this.handleTapValue.bind(this);
        this.handleTapLookupValue = this.handleTapLookupValue.bind(this);
        this.handleClearInputSearch = this.handleClearInputSearch.bind(this);
        this.handleChangeValue = this.handleChangeValue.bind(this);
    }

    static get propTypes(){
        return {
            open: PropTypes.bool,
            lookupValue: PropTypes.string,
            queryForValues: PropTypes.func.isRequired,
            onSave: PropTypes.func.isRequired,
            onClose: PropTypes.func.isRequired,
            isMultiselectAllowed: PropTypes.bool
        };
    }

    static get defaultProps(){
        return {
            isMultiselectAllowed: false
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextState) {
        if (this.props.value !== nextProps.value) {
            let value = nextProps.isMultiselectAllowed ? (nextProps.value ? [...nextProps.value] : []) : null;
            if (nextProps.isMultiselectAllowed && value.length === 1 && value[0] === '%') {
                this.props.onSave([], this.state.optionSelected);
                this.setState({
                    value: nextProps.value,
                    valuesSelected: [],
                });
            } else {
                this.setState({
                    value: nextProps.value,
                    valuesSelected: nextProps.isMultiselectAllowed && nextProps.value !== OPTION_ALL_WILDCARD && nextProps.value !== '' ? nextProps.value.split(",") : [],
                });
            }

            this.setState({
                lookup: nextProps.isMultiselectAllowed ? "" : nextProps.value
            });
        }

        if (this.props.open !== nextProps.open && !nextProps.open) {
            this.setState({
                options: []
            });
        }
    }

    handleTapValue(val,i){
        const { value } = this.state;
        let newValue  = value[0] === OPTION_ALL_WILDCARD ? [] : Array.isArray(value)? [...value] : value.split(",");

        if (this.props.isMultiselectAllowed) {
            if (newValue.indexOf(val) === -1) {
                newValue.push(val);
            } else {
                let index = newValue.indexOf(val);
                newValue.splice(index, 1);
            }
        } else {
            newValue = value === val ? OPTION_ALL_WILDCARD : val;
        }

        this.setState({
            value: newValue,
            optionSelected:true,
        });
    }

    handleTapLookupValue(val,i){
        const value  = this.state.lookup[0] === '%' ? [] : [...this.state.lookup];

        if(!value[i]) {
            value[i] = val;
        } else {
            value.splice(i, 1);
        }
        this.setState({
            lookup: [...value],
            optionSelected:true,
        });
    }

    handleSearch(){
        //let val = (this.refs.input.value===OPTION_ALL_TEXT)?OPTION_ALL_WILDCARD:this.refs.input.value;
        let val = (isAllWrite(this.refs.input.value)) || (this.props.isMultiselectAllowed && this.refs.input.value === "")?OPTION_ALL_WILDCARD:this.refs.input.value;
        
        this.props.queryForValues(val)
            .then(([options,complete])=>{
                const optionSelected = options.some(option => option === val);
                const [valuesSelected, newValuesSelected] = getSelectedValues(this.state.value, this.props.isMultiselectAllowed);

                this.setState({
                    options,
                    // value: val!=="" && val.split(","),
                    value: valuesSelected,
                    lookup: this.props.isMultiselectAllowed ? (val!=="" ? val.split(",") : "") : val,
                    complete,
                    optionSelected,
                    valuesSelected: newValuesSelected
                });
            });
    }

    handleKeyPress(event) {
        if (event.key === 'Enter') {
            this.handleSearch();
        }
    }

    handleSave(){
        /*const valueArray = Object.keys(this.state.value).map(key => {
            return this.state.value[key];
        });*/
        const valuesSelected =
            Array.isArray(this.state.value) ? [...new Set([...this.state.valuesSelected, ...this.state.value])] : [];

        this.setState({
            valuesSelected: [...valuesSelected]
        })

        // const value = !Array.isArray(this.state.value) ? this.state.value : (this.state.value.length === 0 ? '%' : this.state.value);
        const value = this.props.isMultiselectAllowed ?
            (this.state.value.length === 0 ? "%" : (Array.isArray(this.state.value) ? this.state.value : this.state.value.split(","))) :
            this.state.value
        ;

        this.props.onSave( value,this.state.optionSelected);
        this.props.onClose();
    }

    handleChangeValue(event){
        const value = event.target.value;
        this.setState({
            lookup: value!=="" ? value.split(",") : "",
        });
    }

    handleClear(){
        this.refs.input.value = "All";
        this.setState({
            value: this.props.isMultiselectAllowed ? [] : OPTION_ALL_WILDCARD,
            lookup: OPTION_ALL_WILDCARD,
            options: [],
            valuesSelected: [],
        })
    };

    handleClearInputSearch(){
        this.refs.input.value = 'All';
        this.setState({
            lookup: '%'
        })
    }

    componentDidUpdate(prevProps){
        if(prevProps.loadingValues !== this.props.loadingValues){
            this.setState({
                loadingValues: this.props.loadingValues
            })
        }
    }

    render(){

        const {lookup,options,value,complete, loadingValues, valuesSelected} = this.state;
        const valuesArray = Array.isArray(value) ? [...value] : value.split(",");
        const lookupValue=(isAllOptionLookup(lookup))?OPTION_ALL_TEXT:lookup;
        const optionsValues = [...new Set([...valuesSelected, ...options])];
        // const _items = options.length !== 0 ? options.map((v,i)=>{
        const _items = optionsValues.map((v,i)=>{
            return (
                <button key={i} style={{
                    border:'none',
                    width:'100%',
                    background:'transparent',
                    textAlign:'left',
                    borderBottom:'1px solid #ddd',
                    padding:'0.5em',
                    paddingLeft:'3em',
                    position:'relative'
                }}
                        onClick={this.handleTapValue.bind(this,v,i)}
                >
                    <div style={{position:'absolute',top:0,bottom:0,left:0,padding:'0.25em 0.5em'}}>
                        {/*{v === value[i] ? (<CheckIcon style={{color:'#0f0'}}/>) : null}*/}
                        {this.props.isMultiselectAllowed ?
                            (valuesArray.indexOf(v) !== -1 ? (<CheckIcon style={{color:'#0f0'}}/>) : null)
                            :
                            (v === value ? (<CheckIcon style={{color:'#0f0'}}/>) : null)
                        }
                    </div>
                    {v}
                </button>
            );
        })
        ;

        return (
            <StyledDialog
                title={"Lookup Parameter Values"}
                actions={
                    <>
                        <Button
                            id={"cancelButton"}
                            label="Cancel"
                            style={{position: 'absolute', left: 8,bottom:"7px",boxSizing:"border-box"}}
                            onClick={this.props.onClose}>Cancel</Button>
                        <Button style={{boxSizing:"border-box", right: 55, top: 1}} className={value.length === 0 ? "clear-button-class-disabled":''} onClick={this.handleClear}>Clear</Button>
                        <Button style={{color: '#00779f'}} onClick={this.handleSave}>Set</Button>
                    </>
                }
                {...this.props}
            >
                <div style={{minHeight: 420, maxWidth: 350}}>
                    {this.props.isMultiselectAllowed && <div style={{width:'20px', height:'20px', position:'absolute', top:'20px', right:'135px'}} data-tip={"Multi-Select filter"} data-for={'filterInfo'}>
                        <MultiSelectIcon width={20} height={20} />
                    </div>}
                    <ReactToolTip className={"tooltip-toolbar-class"} place={"bottom"} id={"filterInfo"} effect={"solid"} />
                    <Form fullWidth>
                        <Field label="Search">
                            <input type={"text"} defaultValue={lookupValue} style={{paddingRight:"23px"}} onKeyPress={this.handleKeyPress} onChange={this.handleChangeValue} ref="input"/>
                            {lookup.length > 0 ? <div className={"clear-button"} onClick={this.handleClearInputSearch}><Clear/></div> : null}
                        </Field>
                        <Field>
                            <Button className="raised-button" fullWidth onClick={this.handleSearch} variant="contained">Search</Button>
                            <div style={{padding:'0.75em 0',textAlign:'center',fontSize:'0.9em'}}>
                                {options && `Displaying ${options.length} value(s). `}
                                {!complete && "Refine search criteria for more results."}
                            </div>
                        </Field>
                    </Form>
                    <div style={{border:'solid 1px #ddd',position:'absolute',top:'14.5em',bottom:'6em',left:'2em',right:'2em',overflow:'auto', minHeight: '50px'}}>
                        {loadingValues ? loadingValuesFromService() : _items}
                    </div>
                </div>
            </StyledDialog>
        );
    }
}

export default LookupDialog;