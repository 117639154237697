import {memo} from "react";
import React from "react";

const Cell = memo(props => {
    const { columnIndex,
        rowIndex,
        style,
        dataArray,
        headerRow,
        cellRender,
        selectedRows,
        onClick,
        rowDisabled,
        tableHeight
    }=props;
    const content=(dataArray&&dataArray[rowIndex][headerRow[columnIndex]["key"]])||null;
    const selectedClassName=(selectedRows[rowIndex]!=null)?" table-cell-selected":"";
    const clickable=headerRow[columnIndex].clickable;
    const headerKey=headerRow[columnIndex].key;
    const styleRow=rowDisabled ? ((rowIndex%2===0)?" cell-error-even":" cell-error-odd") :((rowIndex%2===0)?" cell-even":" cell-odd");

    const headerKeys = ['alert','actions','status','rfidAntennas','logLevel'];
    const actionStyle=(headerKeys.indexOf(headerKey)>-1)?" cell-actions": "";

    return (
        <div className={"table-cell"+selectedClassName+styleRow+actionStyle}
             style={style}
             onClick={()=>(onClick&&clickable&&onClick(rowIndex,columnIndex))||null}
        >
            {(cellRender!=null)?cellRender({columnIndex,rowIndex,content:content,headerKey:headerKey,tableHeight:tableHeight}):content}
        </div>
    )});

export default Cell;