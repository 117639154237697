import React from "react";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";

const CheckboxCell = ({ columnIndex, rowIndex, className,style,checked,onClick }) => (
    <div
        className={"table-sticky-column-checkbox-cell"+(className||"")}
        style={style}
        onClick={()=>(onClick&&onClick(rowIndex,columnIndex))}
    >
        <Checkbox className={"table-sticky-column-checkbox"}
                  checked={checked}

                  disableRipple/>
    </div>
);
export default CheckboxCell;