import * as types from '../constants/ActionTypes';
import Promise from 'promise';

import {AccessControlService, displayErrorFromAxios} from './util';

function handleACLErrorMessages(err){
    var message = (err.response && err.response.data) || err.message;
    //err.response.data = message.status.error;
    err.response.data = message;
    return Promise.reject(err);
}

// users page
export const clickUsersTab = (report) => ({type:types.USERS_CLICK_TAB, report});

export const loadUserGroups = (groups) => ({type:types.LOAD_USER_GROUPS, groups});

export const requestUserGroups = () => {
    return dispatch => {
        AccessControlService.instance().get("/groups")
        .then(d=>{
            dispatch(loadUserGroups(d.data.results));
        })
        .catch(handleACLErrorMessages)
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};

export const addUserGroup = (group) => {
    return dispatch => {
        return AccessControlService.instance().post("/groups",group)
        .then(()=>dispatch(requestUserGroups()))
        .catch(handleACLErrorMessages)
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};

export const deleteUserGroup = (guid) => {
    return dispatch => {
        return AccessControlService.instance().delete("/groups/" + guid)
        .then(()=>dispatch(requestUserGroups()))
        .catch(handleACLErrorMessages)
        .catch(err=>{
            displayErrorFromAxios(dispatch,err,{ignore403:true})
            .catch(()=>null); // allow it to go through. (don't keep form open)
        });
    };
};

export const updateUserGroup = (guid,group) => {
    return dispatch => {
        return AccessControlService.instance().put("/groups/" + guid,group)
        .then(()=>dispatch(requestUserGroups()))
        .catch(handleACLErrorMessages)
        .catch(err=>displayErrorFromAxios(dispatch,err,{ignore403:true}));
    };
};

export const zoomFeature = (feature) => {
    window.localStorage.setItem("feature",JSON.stringify(feature));
    return dispatch => {
        dispatch({
            type: types.ZOOM_FEATURE,
            feature
        })
    }
};

export const getZoomFeature = () => {
    let feature = window.localStorage.getItem("feature");
    if(feature){
        feature = JSON.parse(feature)
    }else{
        feature = {"zoomObject":true,"zoomReport":true}
    }


    return dispatch => {
        dispatch({
            type: types.ZOOM_FEATURE,
            feature
        })
    }
};

export const loadUsers = (users) => ({type:types.LOAD_USERS, users});

export const requestUsers = () => {
    return dispatch => {
        AccessControlService.instance().get("/users")
        .then(d=>{
            dispatch(loadUsers(d.data.results));
        })
        .catch(handleACLErrorMessages)
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};

export const deleteUser = (uuid) => {
    return dispatch => {
        return AccessControlService.instance().delete("/users/" + uuid)
        .then(()=>dispatch(requestUsers()))
        .catch(handleACLErrorMessages)
        .catch(err=>{
            displayErrorFromAxios(dispatch,err,{ignore403:true})
            .catch(()=>null); // allow it to go through. (don't keep form open)
        });
    };
};

export const addUser = (user) => {
    return dispatch => {
        return AccessControlService.instance().post("/users",user)
        .then(()=>dispatch(requestUsers()))
        .catch(handleACLErrorMessages)
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};

export const updateUser = (uuid,user) => {
    return dispatch => {
        return AccessControlService.instance().put("/users/" + uuid,user)
        .then(()=>dispatch(requestUsers()))
        .catch(handleACLErrorMessages)
        .catch(err=>displayErrorFromAxios(dispatch,err,{ignore403:true}));
    };
};

export const resetUserPassword = (uuid, password) => {
    return dispatch => {
        return AccessControlService.instance().put(`/users/${uuid}/password`, {password: password})
        .then(()=>dispatch(requestUsers()))
        .catch(handleACLErrorMessages)
        .catch(err=>displayErrorFromAxios(dispatch,err,{ignore403:true}));
    }
}

export const loadResourceTypes = (resourceTypes) => ({type:types.LOAD_RESOURCE_TYPES, resourceTypes});

export const requestResourceTypes = () => {
    return dispatch => {
        AccessControlService.instance().get('/resource-types')
        .then(d=>{
            dispatch(loadResourceTypes(d.data));
        })
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};

export const loadSitesForUserMgmt = (sites) => ({type:types.LOAD_SITES_USERMGMT, sites});

export const requestSitesForUserMgmt = () => {
    return dispatch => {
        AccessControlService.instance().get('/sites')
        .then(d=>{
            dispatch(loadSitesForUserMgmt(d.data));
        })
        .catch(displayErrorFromAxios.bind(null,dispatch));
    };
};
