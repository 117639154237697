import React from 'react';
import PropTypes from 'prop-types';

import dateFormat from 'dateformat';


import ErrorIcon from '@material-ui/icons/Warning';
import SuccessIcon from '@material-ui/icons/VerifiedUser';
import PendingUpdateIcon from '@material-ui/icons/SettingsBackupRestore';
import CancelIcon from '@material-ui/icons/NotInterested';

class Task extends React.Component{

    constructor(props){
        super(props);

        this.handleExpand = this.handleExpand.bind(this);

        this.state={
            expanded: false
        };
    }

    static get propTypes(){
        return {
            status: PropTypes.string,
            header: PropTypes.string,
            timestamp: PropTypes.string,
            consoleText: PropTypes.string,
            createdTime: PropTypes.object,
            actions: PropTypes.array,
            defaultExpanded: PropTypes.bool
        };
    }

    handleExpand(expanded){
        this.setState({
            expanded
        });
    }

    renderStatus(status){
        let color = "";
        let icon = "";
        let iconStyles={
            color:'white'
        };

        switch(status){
            case "completed":
                color = "green";
                icon = (<SuccessIcon style={iconStyles} />);
                break;

            case "error":
                color = "red";
                icon = (<ErrorIcon style={iconStyles} />);
                break;

            case "pending":
            case "processing":
                icon = (<PendingUpdateIcon style={iconStyles}/>);
                color = "#ffa403";
                break;

            case "cancelled":
                icon = (<CancelIcon style={iconStyles} />);
                color = "#aaa";
                break;

            default:
                break;

        }

        return (
            <div style={{position:'absolute',top:0,bottom:0,left:0,width:'2em',background:color}}>
                <div style={{height:'24px',width:'24px',position:'absolute',top:0,bottom:0,left:0,right:0,margin:'auto'}}>
                    {icon}
                </div>
            </div>
        );
    }

    renderExpander(props){
        const {onExpand, expanded} = props;

        const styles = {
            fontWeight:'normal',
            fontSize:'0.8em',
            cursor:'pointer',
            margin:'0 0.5em',
            verticalAlign:'middle'
        };

        return (
            <span onClick={()=>onExpand(!expanded)} style={styles}>
                {"[show "+(expanded ? "less" : "more")+"]"}
            </span>
        );
    }

    render(){

        const {
            status,
            header,
            timestamp,
            consoleText,
            actions,
            createdTime
        } = this.props;


        let actionButtonStyles = {fontSize:'0.9em',color:'inherit',margin:'3px 0',height:'30px',lineHeight:'30px'};
        const _actions = React.Children.toArray(actions).map( child => React.cloneElement(child,{style:actionButtonStyles}));

        // ----------------------------------------------

        let expandedContent = this.state.expanded ? (
            <div>
                <div style={{textIndent:'1em',marginTop:'0.5em',border:'solid 1px',fontFamily:'monospace',background:'rgba(0,0,0,0.05)',padding:'1em'}}>
                    {consoleText}
                </div>

                <div style={{float:'right',marginBottom:'-0.5em'}}>
                    {_actions}
                </div>

                <span style={{marginTop:'0.5em',fontSize:'0.9em',fontStyle:'italic',position:'absolute',bottom:0,left:'2.75em',marginBottom:'0.5em'}}>
                    {"Created: "+ dateFormat(createdTime,"mmmm dd, yyyy h:MM:ss TT")}
                </span>
            </div>
        ) : null;

        let Expander = this.renderExpander;

        return (
            <td style={{border:'1px solid #aaa',borderCollapse:'collapse',padding:'0.5em',paddingLeft:'2.5em',position:'relative'}}>

                {this.renderStatus(status)}

                <span style={{fontSize:'1.1em',fontWeight:'bold'}}>
                    {header}
                </span>
                <Expander onExpand={this.handleExpand} expanded={this.state.expanded}/>

                <span style={{fontSize:'0.9em',paddingLeft:'1em',float:'right'}}>
                    {timestamp}
                </span>

                {expandedContent}
            </td>
        );
    }
}

export default Task;
