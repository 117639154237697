import React from 'react';
import PropTypes from 'prop-types';
//openlayers
//import Source from 'ol/source/Source';
//import {Extent} from 'ol/extent';
//import Map from 'ol/Map';
import OLTileLayer from 'ol/layer/Tile';
//import Style from 'ol/style/Style';
import OLVectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

/**
 * A layer that can render tiles into the <Map/> component.
 */
class TileLayer extends React.Component{

    /**
     * @private
     */
    constructor(props){
        super(props);

        /**
         * @type {Object}
         * @property {Source} source - **Required.** OpenLayers source.
         * @property {Extent} extent - **Required.** OpenLayers, Area which the tiles can be displayed.
         * @property {float} maxResolution - **Required.** Maximum resolution that the layer supports.
         * @property {float} opacity - **Required.** Opacity of the layer.
         * @property {int} zIndex - Layer order. Passed by <Map />. **DO NOT SET.**
         * @property {Map} map - OpenLayers map. Passed by <Map />. **DO NOT SET.**
         */
        this.props = props;

        /**
         * @private
         * Underlying OpenLayers layer.
         */
        this.layer = new OLTileLayer({
            source: this.props.source,
            extent: this.props.extent,
            maxResolution: this.props.maxResolution,
            opacity: this.props.opacity || 1,
            zIndex:this.props.zIndexFn(this.props.zIndexKey)
        });

        if(this.props.mapId!=null){
            this.layer.set("mapId",this.props.mapId);
        }
        if(this.props.siteId!=null){
            this.layer.set("siteId",this.props.siteId);
        }
    }

    static get propTypes(){
        return {
            source: PropTypes.any,
            style: PropTypes.any,
            styleFn: PropTypes.func,
            active: PropTypes.bool,
            zIndexKey: PropTypes.oneOfType([PropTypes.number,PropTypes.string]),
            zIndexFn: PropTypes.func,
            map: PropTypes.any,
            children: PropTypes.any,
            opacity: PropTypes.any,
            extent: PropTypes.any,
            maxResolution: PropTypes.any
        };
    }

    /**
     * @private
     * Runs after mounting.
     */
    componentDidMount(){
        this.props.map.addLayer(
            this.layer
        );
    }

    /**
     * @private
     * Runs before unmounting.
     */
    componentWillUnmount(){
        this.props.map.removeLayer(this.layer);
    }

    /**
     * @private
     */
    UNSAFE_componentWillReceiveProps(nextProps){
        if(this.props.source !== nextProps.source){
            this.layer.setSource(nextProps.source);

            if(nextProps.mapId!=null){
                this.layer.set("mapId",nextProps.mapId);
            }
            if(nextProps.siteId!=null){
                this.layer.set("siteId",nextProps.siteId);
            }
        }
    }

    /**
     * @private
     * Renders the UI to the Virtual DOM (which updates the actual DOM)
     */
    render(){
        return (
            <div />
        );
    }
}

/**
 * A layer that can render vector images onto the <Map /> component.
 *
 * Children of the <Map /> component will be passed in the following properties:
 * - `layerSource`: The underlying OpenLayers layerSource with which to interact.
 */
class VectorLayer extends React.Component{

    static get propTypes(){
        return {
            source: PropTypes.any,
            style: PropTypes.any,
            styleFn: PropTypes.func,
            active: PropTypes.bool,
            zIndexKey: PropTypes.oneOfType([PropTypes.number,PropTypes.string]),
            zIndexFn:PropTypes.func,
            map: PropTypes.any,
            children: PropTypes.any
        };
    }

    /**
     * @private
     */
    static get defaultProps(){
        return {
            active: true
        };
    }

    /**
     * @private
     */
    constructor(props){
        super(props);

        /**
         * @type {Object}
         * @property {Source} source - OpenLayers source.
         * @property {Style} style - OpenLayers' Style object.
         * @property {function} styleFn - OpenLayers' Style function.
         * @property {int} zIndex - Layer order. Passed by <Map />. **DO NOT SET.**
         * @property {Map} map - OpenLayers map. Passed by <Map />. **DO NOT SET.**
         */
        this.props = props;

        let source = this.props.source || new VectorSource();


        /**
         * @private
         * Internal OpenLayers layer.
         */
        const zIndex = this.props.zIndexFn(this.props.zIndexKey);
        this.layer = new OLVectorLayer({
            source: source,
            style: this.props.style || this.props.styleFn,
            zIndex
        });

        this.layer.setVisible(this.props.active);

        if(this.props.name!=null){
            this.layer.set("name",this.props.name);
        }

        /**
         * @private
         */
        this.state = {
            source: source
        };

    }


    /**
     * @private
     * Runs after mounting.
     */
    componentDidMount(){
        this.props.map.addLayer( this.layer );
    }

    /**
     * @private
     * Runs after rendering.
     */
    componentDidUpdate(pp){
        if(pp.active !== this.props.active) this.layer.setVisible(this.props.active);
    }

    /**
     * @private
     * Runs before unmounting.
     */
    componentWillUnmount(){
        this.props.map.removeLayer( this.layer );
    }

    /**
     * @private
     * Renders the UI to the Virtual DOM (which updates the actual DOM)     *
     */
    render(){
        const childrenWithProps = React.Children.map(React.Children.toArray(this.props.children),
            (child) => React.cloneElement(child, {
                layerSource: this.state.source
            })
        );

        return (
            <div>
            {childrenWithProps}
            </div>
        );
    }


}
/*
exports.TileLayer = TileLayer;
exports.VectorLayer = VectorLayer;
*/
export {TileLayer,VectorLayer};
