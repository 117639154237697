import React, {useState, useEffect} from 'react';
import NavigateDetails from '../landing/NavigateDetails';
import CardsAntennas from '../landing/CardsAntennas';
import ProgressDetails from '../common/ProgressDetails';
import {useDispatch, useSelector} from 'react-redux';
import {changeStatusDetails, getSpecificTemplate} from '../../../actions';
import Resizer from '../../home/sidebar/Resizer';
import {DETAILS_TEMPLATE} from "../../../constants/DeviceManager";
import OperationPanel from "../landing/OperationPanel";

const TemplateDetails = (props) => {
    const dispatch = useDispatch();
    const showDetails = useSelector(state => state.deviceManager.showDetails);
    const isFetchingDetails = useSelector(state => state.deviceManager.isFetchingDetails);
    const template = useSelector(state => state.deviceManager.detailsTemplate);
    const windowHeight = useSelector(state => state.resize.windowHeight);
    const isMobile = useSelector(state => state.resize.isMobile);

    const [index, setIndex] = useState(0);
    const totalItems = props.selectedRows.length;
    const currentId = props.selectedRows[index];

    const loadTemplateDetails = () => {
        dispatch(getSpecificTemplate(currentId));
    }

    const handleClose = () => {
        props.onClose();
        dispatch(changeStatusDetails(false));
    }

    useEffect(loadTemplateDetails, [currentId]);

    useEffect(()=>{
        if(index+1 > totalItems){
            setIndex(index - 1);
        }

    },[totalItems, index, setIndex]);

    const destroyDetails=() => {
        // Load first time on mount component.
        const unmount = () => {
            handleClose();
        }
        return unmount;
    }

    // Only call once when mount.
    useEffect(destroyDetails, []);
   
    const width = showDetails ? props.width : 0;

    let detailsSidebarClassName = 'details-sidebar-container-wrapper';
    if (props.animationEnabled) {
        detailsSidebarClassName += ' animation';
    }

    return (
        <Resizer
            className={detailsSidebarClassName}
            width={width}
            handleResize={props.handleResize}
            resizing={props.resizing}
            collapsed={!showDetails}
            separatorPosition="left"
            identifier={props.identifier}
            onTransitionEnd={()=>props.onTransitionEnd()}
            isMobile={isMobile}>
            <div className="dd-main-component">
            {isFetchingDetails?<ProgressDetails width={width} height = {windowHeight - 108}/>: null}
                {template && <React.Fragment>
                    <NavigateDetails index={index} setIndex={setIndex} totalItems={totalItems} title={template.title} handleClose={handleClose}/>
                    <div style={{padding:' 5px 7px', maxHeight:windowHeight - 163, overflow:'auto'}}>
                        <IdentityPanel deviceType={template.deviceType} description={template.description}/>
                        <OperationPanel
                            operationMode={template ? template.template.operationMode : ''}
                            tagFilter={template ? template.template.tagFilter : {} }
                            inventoryInterval={template ? template.template.inventoryInterval : {}}
                            minRssi={template ? template.template.minRssi : ''}
                            portalSettings={template ? template.template.portalSettings : {}}
                        />
                        <CardsAntennas antennas={template.template.rfidAntennas} detailsScreen={DETAILS_TEMPLATE}/>
                    </div>
                </React.Fragment>}
            </div>
        </Resizer>
    )
}

export default TemplateDetails;

const IdentityPanel = ({deviceType, description}) => {
    return (
        <div className="dd-identity-main-container">
            <div className="dd-identity-container-title">
                <span className="dd-identity-title">
                Identity
                </span>
            </div>
            <div className="dd-identity-items">
                <span className="dd-identity-items-title">Device Type: </span>
                <span >{deviceType}</span>
            </div>
            <div style={{paddingLeft:'5px'}}> 
                <span style={{fontWeight: 'bold'}}>Description: </span>
                <span>{description}</span>
            </div>
        </div>
    )
}