import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
//import CircularProgress from 'material-ui/CircularProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
//import {DialogTitle} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";

class DeleteMapDialog extends React.Component{

    static get propTypes(){
        return {
            open: PropTypes.bool,
            text: PropTypes.string
        };
    }

    render(){
        const {text,open} = this.props;

        return (
            <Dialog
             // title={null}
             // actions={[]}
             // modal={true}
              open={open}
              style={{textAlign:'center', display:'inline-block', minWidth:'300px'}}
              className={"main-dialog-wait"}
            >
                <DialogContent className={"dialog-wait-content"}>
                    <div style={{textAlign:'center',padding:'0.5em',paddingBottom:'2em'}}>{text}</div>
                    <CircularProgress size={55} thickness={5}  style={{textAlign:'center', color: "#00779f"}}/>
                </DialogContent>
            </Dialog>
        );
    }
}

export default DeleteMapDialog;
