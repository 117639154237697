import React from 'react';
import {getToken} from "../../../actions/util";
import {useDispatch} from "react-redux";
import {loadSiteAndMap} from '../../../actions';
import ReactToolTip from 'react-tooltip';

const MapThumbnail = ({siteId, mapId, mapName, selected, disabled, src}) => {
    const dispatch = useDispatch();
    const className = selected ? "map-thumbnail map-thumbnail-active" : "map-thumbnail";
    const styleImage = {overflow: 'hidden', textOverflow: 'ellipsis', display:'block', fontSize: '11px'};
    let styleThumbnail = {};
    if(disabled){
        // styleThumbnail.pointerEvents = 'none';
        styleThumbnail.opacity = '0.4'
    }
    const timestamp = (new Date()).getTime();
    const img = React.cloneElement(<img alt={mapName} src={`${src}?timestamp=${timestamp}&jwt=${getToken()}`} style={styleImage}/>,{className:"filled-up mp-tmb"});

    const selectMap  = () => {
        if(!disabled){
            dispatch(loadSiteAndMap(siteId, mapId));
        }
    }

    return (
        <div className={className} onClick={selectMap} data-tip={"Coordinates are not set on this map. Please configure the map on SiteManager."} data-for={`mapInformation-${mapId}`}>
            {disabled && <ReactToolTip className={'tooltip-map-class'} place={"top"} id={`mapInformation-${mapId}`} effect={"solid"} multiline/>}
            <div className={"map-thumbnail-name-label"} style={styleThumbnail}>
                {mapName}
            </div>
            <div className={"map-thumbnail-image"} style={styleThumbnail}>
                {img}
            </div>
        </div>
    );
};

export default MapThumbnail;