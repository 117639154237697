import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { updateFormAntenna, addFormAntenna, addFormAntennaByPort, deleteFormAntennaByPort, updateStateFormTemplate, getZones} from "../../../../actions";
import { makeStyles } from '@material-ui/core/styles';
import {Divider, FormControlLabel, Checkbox, Button} from '@material-ui/core';
import {Delete} from '@material-ui/icons';
import { Add as AddIcon } from '@material-ui/icons';
import {ADD_TEMPLATE, HIGHLIGHT_STYLE, NUMBER_ANTENNAS, ANTENNAS_POWER_SLIDER_MARKS} from '../../../../constants/DeviceManager';
import Slider from '@material-ui/core/Slider';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import ReactToolTip from "react-tooltip";

const useStyles = makeStyles({
    checked: {
        color: "#007CB0 !important",
    },
    checkedDisabled: {
        color: '#b7b3b3 !important',
    },
    rootChecked: {
        color: '#000', padding: '3px', paddingLeft:'0.5em'
    },
    iconList:{
        minWidth:'30px'
    },
    select:{
        display: 'flex',
        paddingTop: '4px',
        paddingBottom: '4px'
    },
    label:{
        fontSize: '14px'
    },
    rootLabel:{
        marginRight: 0
    },
    tooltip:{
        color:'#000',
        backgroundColor:'#e6ee31',
        fontSize:'14px',
        width: '180px'
    },
    arrow:{
        color: '#e6ee31'
    },popper:{
        zIndex: 0
    }
});

const sliderStyle = makeStyles({
    root:{
        color: "#007CB0",
        width: '85%',
        left: '15px'
    },
    markLabel: {
        fontSize: '0.8rem',
    },
    disabled:{
        color: '#98CADF !important',
    }
});


const NAME_TRANSMIT_POWER_SLIDER = "transmitPower";

const NO_ZONE = 'No Zone';

const AntennasPanel = ({isTemplate}) => {
    const [transmitPowerGlobal, setTransmitPowerGlobal] = useState(1);
    const [useSameValue, setUseSameValue] = useState({useValue: false, index: null});
    const dispatch = useDispatch();
    const numberAntennaPorts = useSelector(state => state.deviceManager.templateForm.template.numberAntennaPorts || 0);
    const rfidType = useSelector(state => state.deviceManager.templateForm.template.rfidType);
    // const isDeviceChanging = useSelector(state => state.deviceManager.isDeviceChanging);
    const rfidAntennas = useSelector(state => state.deviceManager.templateForm.template.rfidAntennas || []);
    const siteId = useSelector(state => state.deviceManager.templateForm.template.siteId);
    const zones = useSelector(state => state.deviceManager.zones[siteId]);    
    const objectZoneTitles = zones?.reduce((acc, z) => {acc[z.locationGuid] = z.name; return acc}, {}) || {};
    const ANTENNA_PORTS_DEFAULT = [2,4,8,14];
    let portsOptions = rfidType && NUMBER_ANTENNAS[rfidType] ? NUMBER_ANTENNAS[rfidType]: ANTENNA_PORTS_DEFAULT;
    const operationMode = useSelector(state => state.deviceManager.templateForm.template.operationMode);

    const loadZones = () => {
        if(siteId && !zones){
            dispatch(getZones(siteId));
        }
    }

    useEffect(loadZones, [siteId]);

    const handleChangeAntennaPorts = (e) => {
        let newNumberOfPorts = Number(e.target.value);
        dispatch(updateStateFormTemplate('numberAntennaPorts', null, newNumberOfPorts));

        if(newNumberOfPorts>rfidAntennas.length){
            //create new antenas
            let i = rfidAntennas.length;
            while(i < newNumberOfPorts){
                i++;
                dispatch(addFormAntennaByPort(i));
            }
        }else{
            //delete last antennas
            dispatch(updateStateFormTemplate('rfidAntennas', null, rfidAntennas.slice(0, newNumberOfPorts)))
        }
    }

    const deleteAntenna = numberPort => {
        dispatch(deleteFormAntennaByPort(numberPort));
    }

    return  (
        <div style={{width: '100%'}}>
            <div className="basic-input" style={{margin: '0 -0.5em'}}>
                <div className="field">
                    <span className="items-header" style={{paddingBottom: '13px'}}># Antenna Ports</span>
                    <select disabled={rfidType.indexOf('ATR') > -1} style={{width:'112px'}} value={numberAntennaPorts} onChange={handleChangeAntennaPorts}>
                        <option value={0} style={{display:'none'}}></option>
                        {portsOptions.map((v,i) =><option key={i} value={v}>{v}</option>)}
                    </select>
                </div>
            </div>
            <div className="antennas-items">
                {rfidAntennas.map((item, index) => <AntennaItem key={index} item={item} index={index} deleteAntenna={deleteAntenna}
                        isTemplate={isTemplate} zoneName={item.autoZone ? objectZoneTitles[item.autoZone]: null} disabled={operationMode === 'advanced'}
                        setUseSameValue={setUseSameValue} transmitPowerGlobal={transmitPowerGlobal} setTransmitPowerGlobal={setTransmitPowerGlobal}
                        disableTransmitPower={useSameValue.useValue && useSameValue.index !== index}
                />)}
            </div>
            <Button 
                style={{marginTop: '15px', backgroundColor: '#007CB0', color: 'white', textTransform: 'none', padding: '5px 20px', display:'none'}}
                startIcon={<AddIcon />}
                onClick={() => dispatch(addFormAntenna())}>
                Add Antenna
            </Button>
        </div>
    );
}

export default AntennasPanel; 

const AntennaItem = props => {
    const {item, index, isTemplate, zoneName, deleteAntenna, disabled, setUseSameValue, transmitPowerGlobal, setTransmitPowerGlobal, disableTransmitPower} = props;
    const [transmitPower, setTransmitPower] = useState(1);
    const [useAntennaValue, setUseAntennaValue] = useState(false);
    const dispatch = useDispatch();
    const classes = useStyles();
    const classSlider = sliderStyle();
    const templateFormLoaded = useSelector(state => state.deviceManager.templateFormLoaded);    
    const validationRfidAntennas = useSelector(state => state.deviceManager.needsValidationFields.rfidAntennas.rfidAntennas);

    const handleChangeRangeValue = () => {
        if(disableTransmitPower) {
            setTransmitPower(transmitPowerGlobal);
            dispatch(updateFormAntenna(index, NAME_TRANSMIT_POWER_SLIDER, transmitPowerGlobal));
        }
    };

    useEffect(handleChangeRangeValue, [transmitPowerGlobal]);

    const handleInitializeValueSlider = () => {
        setTransmitPower(item.transmitPower)
    };

    useEffect(handleInitializeValueSlider, []);


    //flag to enable highlights in input fields
    const isHighlight = templateFormLoaded !== ADD_TEMPLATE;

    const changeAntennasValues = (name, value) => {
        //highlighting
        if(isHighlight && name){
            document.getElementById(`${name}-${index}`).style["box-shadow"] = HIGHLIGHT_STYLE;
        }
        
        if(name.startsWith('location')){
            const nameChildKey = name.split('-')[1];
            let updateLocation = {...item.location};
            updateLocation[nameChildKey] = value;
            dispatch(updateFormAntenna(index, 'location', updateLocation));
        }else{
            dispatch(updateFormAntenna(index, name, value));
        }
    }

    const inputCoordStyles = {flexBasis:'116px',flexShrink:1,textAlign: 'center',display:'inline-flex', padding: '0.2em'};
    const warningMessageStyle = {backgroundColor:'#e6ee31', fontSize: '12px', padding: '0.25', textAlign: 'justify'};
    let openTooltip = false;
    if(validationRfidAntennas.error && validationRfidAntennas.issues){
        openTooltip = validationRfidAntennas.issues.indexOf(index)>-1;
    }

    const valueText = (value) => {
        return value;
    };

    const valueLabelFormat = (value) => {
        return value;
    };

    const handleChangeTransmitPower= (event, newValue) => {
        setTransmitPower(newValue);
        // changeAntennasValues(NAME_TRANSMIT_POWER_SLIDER, newValue);
        dispatch(updateFormAntenna(index, NAME_TRANSMIT_POWER_SLIDER, newValue));
        if(useAntennaValue){
            setTransmitPowerGlobal(newValue);
        }
    };

    const handleSetUseAntennaValue = () => {
        setUseAntennaValue(!useAntennaValue);
        if(!useAntennaValue === true){
            setUseSameValue({useValue: true, index: index})
            setTransmitPowerGlobal(transmitPower);
        } else {
            setUseSameValue({useValue: false, index: null})
        }
    };

    return (
        <div className="antenna-item">
            <div className="antenna-items-header">
                <span>{`Port ${item.id}`}</span>
                <Delete style={{cursor:'pointer', display:'none'}} onClick={e => deleteAntenna(item.id)}/>
            </div>
            <div className="antenna-items-body">
                <FormControlLabel className={"center-highlight"} value="disabled" label={disabled ? "Based on custom configuration" : "Enable antenna data collection"} id={`disabled-${index}`}
                    classes={{root: classes.rootLabel, label: classes.label}}
                    control={<Checkbox disabled={disabled} checked={!item.disabled} name={'disabled'} onChange={e=>changeAntennasValues(e.target.name, !e.target.checked)}
                                 style={{backgroundColor:'transparent',margin:'0 -0.08em'}} classes={{checked:disabled ? classes.checkedDisabled : classes.checked, root: classes.rootChecked}} />} />
                {!isTemplate ? <>
                    <div className="basic-input" style={{display:'flex',flexDirection:'row',flexWrap:'wrap',margin:'0 -0.4em'}} >
                        <div className="field" style={{flexBasis:'355px'}}>
                            <div style={{display: 'flex'}} className="items-header">
                                <div style={{marginRight: '15px', lineHeight:'30px'}}>Transmit Power</div>
                                <div style={{marginRight: '5px'}}><input type="text" value={disabled ? '' : transmitPower || ''} style={{width:50, textAlign:'center', padding: 0}} disabled/></div>
                                <div style={{cursor:!disabled ? 'pointer' : 'default', lineHeight:'33px', margin:'0 10px'}} data-for={'info'}
                                    data-tip="Max allowed transmission power are different by Country. Make sure you are in the limit."
                                >
                                    <InfoIcon fontSize={"small"} htmlColor={!disabled ? "#007CB0" : "#98CADF"}/>
                                </div>
                                {!disabled ? <ReactToolTip className={"tooltip-class-transmit-power"} place={"top"} id={"info"} effect={"solid"} /> : null}
                            </div>
                            <div style={{border: '1px solid #cecece'}}>
                                <div style={{display: 'flex'}}>
                                    <Slider
                                        defaultValue={1}
                                        valueLabelFormat={valueLabelFormat}
                                        getAriaValueText={valueText}
                                        aria-labelledby="discrete-slider-custom"
                                        step={1}
                                        valueLabelDisplay="off"
                                        marks={ANTENNAS_POWER_SLIDER_MARKS}
                                        classes={classSlider}
                                        min={1}
                                        max={45}
                                        value={disabled ? 1 : (transmitPower || 1)}
                                        onChange={handleChangeTransmitPower}
                                        name={NAME_TRANSMIT_POWER_SLIDER}
                                        disabled={disabled || disableTransmitPower}
                                    />
                                    <span style={{
                                        fontSize: '0.75em',
                                        flex: '0 0 1px',
                                        padding: '6px 2px 6px 25px',
                                        display: 'inline-block'
                                    }}> </span>
                                </div>
                                <div style={{display: 'flex'}}>
                                    <Checkbox disabled={disabled || disableTransmitPower} checked={useAntennaValue} onChange={handleSetUseAntennaValue}
                                              style={{backgroundColor:'transparent',margin:'0 -0.08em'}} classes={{checked:disabled ? classes.checkedDisabled : classes.checked, root: classes.rootChecked}}
                                    />
                                    <span style={{marginTop: '5px', color: disabled || disableTransmitPower ? "#b7b3b3" : 'inherit'}}>Use this value for all antennas</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="basic-input" style={{display:'flex',flexDirection:'row',flexWrap:'wrap',margin:'0 -0.4em'}} >
                        <div className="field" style={{flexBasis:'355px'}}>
                            <span className="items-header">Name*</span>
                            <input id={`title-${index}`} name={'title'} type={"text"} maxLength={40} placeholder={"Name"}
                                value={item.title} onChange={e=>changeAntennasValues(e.target.name, e.target.value)} />
                            <span className="identity-char-indicator">{40 - item.title?.length} characters left.</span>
                            {openTooltip && <div style={warningMessageStyle}>{validationRfidAntennas.message}</div>}
                        </div>
                    </div>
                    <Divider style={{marginTop: 5, marginBottom: 5}}/>
                    
                    <div className="basic-input" style={{display:'flex',flexDirection:'row',flexWrap:'wrap',margin:'0 -0.4em'}} >
                        <div className="field" style={{flexBasis:'355px', padding: '0 0.5em'}}>
                            <span className="items-header">Coordinates</span>
                        </div>
                    </div>
                    <div className="basic-input" style={{display:'flex',flexDirection:'row',flexWrap:'wrap',margin:'0 -0.4em'}} >
                        <div className="field" style={inputCoordStyles}>
                            <span className="coordinates-text">X</span>
                            <input disabled={disabled} id={`location-x-${index}`} name={'location-x'} type={"number"} value={disabled ? '' : (item.location && item.location.x) || ''} placeholder={"0"}
                                    onChange={e=>changeAntennasValues(e.target.name, Number(e.target.value))} />
                        </div>
                        <div className="field" style={inputCoordStyles}>
                            <span className="coordinates-text">Y</span>
                            <input disabled={disabled} id={`location-y-${index}`} name={'location-y'} type={"number"} value={disabled ? '' : (item.location && item.location.y) || ''} placeholder={"0"}
                                    onChange={e=>changeAntennasValues(e.target.name, Number(e.target.value))} />
                        </div>
                        <div className="field" style={inputCoordStyles}>
                            <span className="coordinates-text">Z</span>
                            <input disabled={disabled} id={`location-z-${index}`} name={'location-z'} type={"number"} value={disabled ? '' : (item.location && item.location.z) || ''} placeholder={"0"}
                                    onChange={e=>changeAntennasValues(e.target.name, Number(e.target.value))} />
                        </div>
                    </div>

                    <div className="basic-input" style={{display:'flex',flexDirection:'row',flexWrap:'wrap',margin:'0 -0.4em'}} >
                        <div className="field" style={{flexBasis:'355px'}}>
                            <span className="items-header">Zone</span>
                            <span style={{fontSize: '14px'}}>{zoneName?zoneName:NO_ZONE}</span>
                        </div>
                    </div>
                </> : null}
            </div>
        </div>
    );
}
