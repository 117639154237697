import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
//import "core-js/es/array/fill";
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import './vss/sass/style.scss';
import './vss/sass/app/mainApp.scss';
import {Routes} from "./vss/app/routes";
import store from './vss/app/store';


ReactDOM.render((
    <Provider store={store}>
            {Routes}
    </Provider>
), document.getElementById('mainAppId'));