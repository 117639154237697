import React from 'react';
import PropTypes from 'prop-types';
//import {ListItem} from 'material-ui/List';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
//import Divider from 'material-ui/Divider';
import Divider from '@material-ui/core/Divider';
//import Checkbox from 'material-ui/Checkbox';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from "@material-ui/core/Collapse/Collapse";

const TreeListItem = (props) => {
    const {listLevel,...other} = props; // eslint-disable-line
    const divProps = Object.assign({}, other);
    delete divProps.primaryText;

    return (
        <ListItem {...divProps} />
    );
};

TreeListItem.propTypes = function(){
    return {
        listLevel: PropTypes.number
    };
};

function areChildrenAllChecked(value){
    if(value && value.checked) return value.checked; // if not dependent on children.
    if(!value || !value.children) return false;

    let i,l=value.children.length;
    for(i=0;i<l;i++){
        if(!areChildrenAllChecked(value.children[i])){
            return false;
        }
    }

    return true;
}

class TreeListNode extends React.Component{

    static get propTypes(){
        return {
            value: PropTypes.any,
            globalIndex: PropTypes.any,
            level: PropTypes.any,
            listItemComponent: PropTypes.any,
            onCheck: PropTypes.any,
        };
    }

    renderNestedItems(node,index,level){
        if(!node || !node.children) return undefined;

        return node.children.map((v2,i2)=>(
            <TreeListNode
                {...this.props}
                value={v2}
                globalIndex={index+","+i2}
                key={index+","+i2}
                level={level+1}
            />
        ));
    }


    render(){
        const {
            value,
            globalIndex,
            level,
            listItemComponent,
            onCheck,
            ...other
        } = this.props;

        const MyListItem = listItemComponent || ListItem;

        const _value = {...value, level, globalIndex};

        return (
            <div>
                <MyListItem
                    {...other}
                    data={_value}
                    style={{maxHeight: 30, cursor: 'pointer'}}
                    onClick={(e) => onCheck(e, !value.checked, globalIndex)}
                >
                    <ListItemIcon className={"tree-list-item-check-container"}>
                        <Checkbox
                            edge="start"
                            checked={areChildrenAllChecked(value)}
                            data-index={globalIndex}
                            className={"tree-list-item-check"}
                            style={{backgroundColor: 'transparent', color: areChildrenAllChecked(value)?'#43a047':'inherit'}}
                        />
                    </ListItemIcon>
                    <ListItemText primary={value.name}/>

                </MyListItem>
                <Collapse in={false} timeout="auto" unmountOnExit>
                    {this.renderNestedItems(value, globalIndex, level)}
                </Collapse>
            </div>
        );
    }
}

class TreeList extends React.Component{

    constructor(){
        super();
        this.handleCheck = this.handleCheck.bind(this);
    }

    static get propTypes(){
        return {
            listItemComponent: PropTypes.func,
            onSelect: PropTypes.func,
            tree:PropTypes.array,
            actions: PropTypes.object
        };
    }

    static get defaultProps(){
        const nop = function(){};
        return {
            onSelect: nop
        };
    }

    handleCheck(e,value,index){
        const indices = (index!=null)?[index]:[];
        const checked=(value!=null)?value:!areChildrenAllChecked({children:this.props.tree});
        this.props.onSelect(indices,checked);
    }

    renderTree(tree){
        return (
            <List className="checkbox-tree">
                {tree.map((v,i)=>(
                    <TreeListNode
                        key={i}
                        listItemComponent={this.props.listItemComponent}
                        value={v}
                        globalIndex={i}
                        level={1}
                        onCheck={this.handleCheck}
                    />
                ))}
            </List>
        );
    }

    render(){

        const {tree} = this.props;

        const MyListItem = this.props.listItemComponent || TreeListItem;
        const selectAllChecked = areChildrenAllChecked({children:tree});

        return (
            <div style={{boxSizing:'border-box',height:'100%'}}>
                <MyListItem
                    primaryText="All"
                    button
                    className="checkbox-tree all-list"
                    onClick={this.handleCheck}
                >
                    <ListItemIcon>
                        <Checkbox className={"tree-list-item-check"} checked={selectAllChecked} style={{color: selectAllChecked?'#43a047':'inherit', backgroundColor: 'transparent'}} onChange={this.handleCheck}/>
                    </ListItemIcon>
                    <ListItemText primary="All"/>
                </MyListItem>
                <Divider />
                <div style={{height:'100%',overflowY:'auto'}}>
                    {this.renderTree(tree)}
                </div>
            </div>
        );
    }
}

class StatefulTreeList extends React.Component{
    constructor(props){
        super(props);

        var _tree = this.weaveTree(this.props.options,this.props.defaultValue) || [];

        this.state = {
            tree: _tree
        };

        this.handleSelect = this.handleSelect.bind(this);
    }

    static get propTypes(){
        return {
            options: PropTypes.array,
            defaultValue: PropTypes.array,
            onChange: PropTypes.func
        };
    }

    static get defaultProps(){
        return {
            onChange: ()=>{}
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        var _tree = this.weaveTree(nextProps.options,nextProps.defaultValue) || [];
        this.setState({
            tree: _tree
        });
    }

    weaveTree(options,defaultValue){

        if(!options || !defaultValue) return;

        var selectedValues = {};
        defaultValue.forEach(v=>{
            selectedValues[v] = true;
        });

        return options.map(opt=>({
            name:opt,
            id:opt,
            checked: selectedValues[opt]
        }));
    }



    handleSelect(indices,checked){

        const tree = (state,indices,checked) => {

            // we've reached our node
            if(indices.length === 0){

                if(state.children && state.children.length > 0){
                    return {
                        ...state,
                        children: state.children.map((v)=>tree(v,indices,checked))
                    };
                }

                return {
                    ...state,
                    checked
                };

            }

            // Copy the array.
            let t = [].concat(state.children);

            // replace the value at the index with the subtree
            // produced by updating the subtree.
            t.splice(
                indices[0],
                1,
                tree(state.children[indices[0]],indices.slice(1),checked)
            );

            let node = {...state, children:t};

            return node;
        };

        var tr = tree(
            {
                children:this.state.tree
            },
            indices,
            checked
        ).children;

        this.setState({
            tree: tr
        });

        this.props.onChange(tr.filter(n=>n.checked).map(n=>n.name));
    }

    render(){
        return (
            <TreeList
                {...this.props}
                onSelect={this.handleSelect}
                tree={this.state.tree}
            />
        );
    }
}

export {TreeList,StatefulTreeList};
export default TreeList;
