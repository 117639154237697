import React from 'react';
import {Popover} from '@material-ui/core';
import SortedChecklist from '../../util/SortedChecklist';
import ListIcon from '@material-ui/icons/ViewList';
import ClassedIconButton from './ClassedIconButton';


class FiltersDropDown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            anchorEl: null,
        };
        this.handleTouchTap = this.handleTouchTap.bind(this);
        this.handleRequestClose = this.handleRequestClose.bind(this);
        this.handleMoveFilter = this.handleMoveFilter.bind(this);
        this.handleToggleFilter = this.handleToggleFilter.bind(this);
        this.handleToggleAllFilters = this.handleToggleAllFilters.bind(this);
    }
    handleTouchTap(event){
        event.preventDefault();

        this.setState({
            open: true,
            anchorEl: event.currentTarget,
        });
    }

    getOriginalIndex(index) {
        return this.props.filters[index].originalIndex;
    }

    handleMoveFilter(dragIndex, hoverIndex){
        this.props.moveFilter(this.getOriginalIndex(dragIndex), this.getOriginalIndex(hoverIndex));
    }

    handleToggleFilter(index, checked){
        this.props.toggleFilter(this.getOriginalIndex(index), checked);
    }

    handleToggleAllFilters(event, checked){
        this.props.toggleAllFilters(checked);
    }

    handleRequestClose(){
        this.setState({
            open: false,
        });
    }
    render() {
        const { filters } = this.props;
        return <div>

                <ClassedIconButton
                    id={"filtersdropdown_btn"}
                    onClick={this.handleTouchTap}
                    aria-controls="menu-list-grow"
                    aria-haspopup="true"
                    disableRipple
                    style={{paddingRight:'7px', paddingLeft:'2px', backgroundColor: 'transparent'}}
                >
                    <ListIcon/>
                </ClassedIconButton>
                <Popover open={this.state.open}
                         anchorEl={this.state.anchorEl}
                         anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                         transformOrigin={{horizontal: 'right', vertical: 'bottom'}}
                         onClose={this.handleRequestClose}
                         marginThreshold={0}
                         transitionDuration={100}
                >
                    <SortedChecklist
                        selectAllText="Filters"
                        className="columns-drop-down"
                        data={filters}
                        onMoveItem={this.handleMoveFilter}
                        onCheckItem={this.handleToggleFilter}
                        onCheckSelectAll={this.handleToggleAllFilters}
                        keyAttribute={"name"}
                        labelAttribute={"caption"}
                        checkedAttribute={"enabled"}/>
                </Popover>

        </div>;
    }
}

export default FiltersDropDown;
