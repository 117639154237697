import React, {useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { updateStateKeyFormTemplate } from "../../../../actions";

const DeviceTypePanel = () =>{
    const deviceType = useSelector(state => state.deviceManager.templateForm.deviceType);
    const deviceTypes = useSelector(state => state.deviceManager.deviceTypes);
    const dispatch = useDispatch();
    const handleChange = value => {
        if(value) dispatch(updateStateKeyFormTemplate('deviceType', value));
    };
    useEffect(() => {}, [deviceType]);
    useEffect(() => {}, [deviceTypes]);
    return (
        <div className={"device-type"}>
            {deviceTypes ? Object.keys(deviceTypes).map(v => {
                return (
                    <button 
                        className={"device-type-items"} 
                        key={deviceTypes[v].id} 
                        onClick={() => handleChange(deviceTypes[v].title)}
                        style={deviceType === deviceTypes[v].title ? {backgroundColor: '#007CB0',color: 'white',outline: 0}:null}>
                        <img style={{width: 20, height: 20}} alt={deviceTypes[v].id} src={deviceTypes[v].image} />
                        {deviceTypes[v].title}
                    </button>
                );
            }): null}
        </div>
    );
}

export default DeviceTypePanel;