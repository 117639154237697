import React from 'react';
import PropTypes from 'prop-types';
//openlayers
import {fromLonLat} from 'ol/proj';
import OSM from 'ol/source/OSM';

import * as ZMap from './../../../../../common/app/views/map/ZMap';
// layers
import PinLayer from './layers/PinLayer';

import {OSM_SOURCE} from '../../../constants/Misc';

import {getToken} from '../../../actions/util';
import {connect} from "react-redux";
import * as actions from "../../../actions";
import {bindActionCreators} from 'redux';

const {Map,TileLayer} = ZMap;



class WorldMap extends React.Component{

    static get propTypes(){
        return {
            onSelectSite: PropTypes.func,
            onDeselectSite: PropTypes.func,
            onHoverSite: PropTypes.func,
            isSelected: PropTypes.func,
            sites: PropTypes.array.isRequired,
            pinLayerRenderer: PropTypes.any,
            children: PropTypes.any
        };
    }

    static get defaultProps(){
        const nop = function(){};

        return {
            onSelectSite: nop,
            onDeselectSite:nop,
            onHoverSite: nop,
            pinLayerRenderer:PinLayer
        };
    }

    constructor(){
        super();

        this.state = {
            canClick:false,
            selected:"",
            tileSource: new OSM({
                url:`${OSM_SOURCE}/{z}/{x}/{y}.png?jwt=${getToken()}`
            }),
            center:[0,0],
            resolution:25000
        };

        // data providers ------------------------------------------------------
        this.isSelected = this.isSelected.bind(this);
        this.getPinFromPoint = this.getPinFromPoint.bind(this);

        // handlers ------------------------------------------------------------
        this.handlePointerMove = this.handlePointerMove.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    getMap(){
        return this.refs.map.getMap();
    }


    shouldComponentUpdate(nextProps,nextState){
        return this.props !== nextProps || this.state !== nextState;
    }

    componentDidMount(){
        this.map = window.MY_MAP;
        this.updateMapToFitSites(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(nextProps.sites !== this.props.sites){
            this.updateMapToFitSites(nextProps);
        }
    }

    updateMapToFitSites(props){

        const pins = this.getPins(props.sites);
        if(!pins || !pins.length) return; // no need to update resolution if there's no data.

        const boundingBox = this.getBoundingBox(pins);

        const center = [
            ( boundingBox[0] + boundingBox[2] ) / 2,
            ( boundingBox[1] + boundingBox[3] ) / 2
        ];

        const mapSize = this.refs.map.getPixelSize();

        if(mapSize.width === 0 || mapSize.height === 0){
            return; // map isn't visible anyway, so no need to update.
        }

        let constraintResolution = this.getSmallestResolutionForInclusiveMap(mapSize,boundingBox);
        constraintResolution = constraintResolution || 100;  // resolution of 0 is invalid.

        this.setState({
            center:center,
            resolution: constraintResolution*1.1 // 5% padding?
        });
    }

    getBoundingBox(pins){
        const firstPin = pins[0];
        return pins.reduce((bb, pin)=>{
            if(pin.x < bb[0]) bb[0] = pin.x;
            if(pin.x > bb[2]) bb[2] = pin.x;
            if(pin.y < bb[1]) bb[1] = pin.y;
            if(pin.y > bb[3]) bb[3] = pin.y;

            return bb;
        },[firstPin.x,firstPin.y,firstPin.x,firstPin.y]);
    }

    getSmallestResolutionForInclusiveMap(mapSize, boundingBox){
        const bbSize = {
            width:boundingBox[2]-boundingBox[0],
            height:boundingBox[3]-boundingBox[1]
        };

        const widthResolution = bbSize.width / mapSize.width;
        const heightResolution = bbSize.height / mapSize.height;

        // The larger resolution will include all the points.  If smaller, the other
        // dimension will be cut off.
        return (widthResolution > heightResolution) ? widthResolution : heightResolution;
    }

    // DATA PROVIDER -----------------------------------------------------------
    isSelected(point){
        const {isSelected} = this.props;
        if(isSelected) return isSelected(point);
        return this.state.selected === point.name;
    }

    getPinFromPoint(point){

        let coord = fromLonLat([point.long,point.lat]);
        return {
            ...point,
            x:coord[0],
            y:coord[1],
            selected: this.isSelected(point)
        };

    }

    // HANDLERS ----------------------------------------------------------------

    handlePointerMove(event,hoveredFeatures){

        const {onHoverSite} = this.props;

        let e = [];
        this.map.forEachFeatureAtPixel(event.pixel, (f) => {
            e.push(f);
        });

        if(e.length){
            let d = e[0].get('data');
            this.setState({
                canClick:true,
                selected:d.name
            });
            onHoverSite(d);
        }
        else{
            this.setState({
                canClick:false,
                selected:""
            });
            onHoverSite(null);
        }
    }

    handleClick(event,clickedFeatures){
        if(clickedFeatures[0]){
            this.props.onSelectSite(clickedFeatures[0].get('data'),clickedFeatures[0]);
            this.props.onHoverSite(null);

            const site=clickedFeatures[0].get('data');

            // Init local map by default with first local map.
            if(this.props.maps&&site) {

                // Find all the maps for the current site.
                let mapsSite=this.props.maps.filter(map=>map.siteId===site._id);

                // Assign the first map in site.
                const map=(mapsSite.length>0)?mapsSite[0]:null;
                this.props.changeLocalMap(this.props.reportId, map, site);

            }

        }else{
            this.props.onDeselectSite();
        }
    }

    // MAIN RENDER -------------------------------------------------------------

    getPins(sites){
        return sites.filter(s=>(s.lat && s.long)).map(this.getPinFromPoint);
    }

    render(){
        const {
            children,
            sites,
            pinLayerRenderer
        } = this.props;

        const {
            canClick,
            center,
            resolution,
            tileSource
        } = this.state;

        const pins = this.getPins(sites);

        let mapStyles = {
            height:'100%',
            width:'100%',
            cursor:(canClick ? 'pointer': 'default')
        };

        const PinLayer = pinLayerRenderer;

        return (
            <Map style={mapStyles}
                 center={center}
                 resolution={resolution}
                 {...this.props}
                 onClick={this.handleClick}
                 onPointerMove={this.handlePointerMove}
                 disableOLControls
                 ref="map"
            >
                <TileLayer source={tileSource}/>
                <PinLayer pins={pins} />
                {children}
            </Map>
        );
    }

}

const mapStateToProps = state =>({
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        changeLocalMap:actions.changeLocalMap,
    },dispatch)
});

export default connect(mapStateToProps,mapDispatchToProps)(WorldMap);