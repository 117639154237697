import {LOCAL_MAP, WORLD_MAP, DEFAULT_MAP} from "../utils/ol/olConstants";
import {SELECT_MAP, SITE_MANAGER_SITE_SELECTED} from "../constants/ActionTypes";
import {getMapCoordinates} from "./util/maps";


export const refStore= {};

export const changeRefMap = (ref)=>{
    return (dispatch) => {
        refStore.olMap=ref;
    }
}
export const changeSiteManagerSiteSelected = (siteId) =>({type:SITE_MANAGER_SITE_SELECTED,siteManagerSiteSelected:siteId});
export const changeOlMap = (mapId,defaultBounds=false)=>{
    return async (dispatch, getState) => {
        const state = getState();
        const sites = state.sites.sites;
        const maps = state.sites.maps;
        let currentMap = DEFAULT_MAP;
        let mapType = WORLD_MAP;
        let mapConfiguration=null;

        if (mapId != null) {
            mapType = LOCAL_MAP;
            mapConfiguration = await getMapCoordinates(mapId);
            let mapName = "";
            let siteId = "";
            let siteName = "";
            maps.forEach(item => {
                if(item._id === mapId) {
                    mapName = item.description;
                    siteId = item.siteId;
                }
            });

            if(siteId && siteId !== ""){
                sites.forEach(item => {
                    if(item._id === siteId){
                        siteName = item.name;
                    }
                })
            }

            currentMap = `${siteName} - ${mapName}`
        }

        let validConfiguration=true;
        let bounds=null;
        let maxZoom=0;


        let coordinates=null;
        if(mapConfiguration!=null) {
            if (mapConfiguration.extent==null) {
                validConfiguration = false;
                // Default bounds is used for show a map without configuration.
                if (defaultBounds === true) {
                    coordinates = {};
                    // Commented the mapCorners because is only needed the extent for use a map without configuration
                    /*coordinates.mapCorner1X = 0;
                    coordinates.mapCorner2Y = 0;
                    coordinates.mapCorner2X = 256;
                    coordinates.mapCorner1Y = 256;*/
                    coordinates.extent=[0,0,256,256];

                }
            } else {

                coordinates=mapConfiguration;
            }

            if(mapConfiguration.maxZoom!=null){
                maxZoom = mapConfiguration.maxZoom-1 // Tiles starts in 0.
            }

            if (coordinates != null&&coordinates.extent) {
                bounds=coordinates.extent;
            }
        }

        dispatch({
            type: SELECT_MAP,
            mapType: mapType,
            mapId: mapId,
            mapConfiguration: coordinates,
            validConfiguration: validConfiguration,
            bounds: bounds,
            maxZoom:maxZoom,
            currentMap: currentMap
        });
    }
};
