import React from 'react';
import PropTypes from 'prop-types';
import {Popover} from '@material-ui/core';

/*
███    ███ ███████ ███    ██ ██    ██
████  ████ ██      ████   ██ ██    ██
██ ████ ██ █████   ██ ██  ██ ██    ██
██  ██  ██ ██      ██  ██ ██ ██    ██
██      ██ ███████ ██   ████  ██████
*/

class FilterMenu extends React.Component{

    static get propTypes(){
        return {
            open: PropTypes.bool,
            anchorEl: PropTypes.any,
            onRequestClose: PropTypes.func.isRequired,
            onChange: PropTypes.func.isRequired,
            width: PropTypes.number,

            // trio of "values"
            options: PropTypes.array
        };
    }

    static get defaultProps(){
        return {
            options: []
        };
    }

    constructor(props) {
        super(props);
        this.renderOption = this.renderOption.bind(this);

        this.state = {
            selected:{}
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(this.props.options !== nextProps.options && nextProps.options > 0){
            this.setState({
                selected:{
                    [nextProps.options && nextProps.options[0].value] : true
                }
            });
        }
    }

    componentDidUpdate(){
        // this is to solve a render problem
        // with the time filter.  It probably should
        // be similar with the external datepicker.
        // (there was no problem with that...)
        var elm = this.refs.the_div;
        if(!elm) return;
        elm = elm.parentNode.parentNode;
        elm.style.overflowY = "visible";
    }

    render(){
        const {options} = this.props;

        return (
            <Popover open={this.props.open}
                 anchorEl={this.props.anchorEl}
                 anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
                 transformOrigin={{horizontal: 'left', vertical: 'top'}}
                 onClose={this.props.onRequestClose}
                 key={"datetimefilter"}
                 style={{overflow:'visible',overflowY:'visible'}}
             >
                 <div
                     ref="the_div"
                     style={{fontSize:'0.9em',padding:0,maxHeight:'200px',minWidth:this.props.width+'px',overflow:'auto'}}
                 >
                    <table>
                        <tbody>
                            {options.map(this.renderOption)}
                        </tbody>
                    </table>
                 </div>
             </Popover>
        );
    }

    renderOption(option,i){
        const {selected} = this.state;

        return (
            <tr key={i} onClick={this.handleSelect.bind(this,option.value)} className="multi-select-filter-menu-row">
                <td style={{padding:'0 0.5em',width:'1em',height:'1em'}}>
                    <span style={{visibility:(selected[option.value] ? 'visible' : 'hidden')}}>
                        {String.fromCharCode(0x2714)}
                    </span>
                </td>
                <td>
                    {option.label}
                </td>
            </tr>
        );
    }

    handleSelect(value){
        let selected = {
            ...this.state.selected,
            [value]: !this.state.selected[value]
        };

        this.setState({
            selected
        });

        let onlySelected = this.props.options.filter(o=>{
            return selected[o.value];
        });

        this.props.onChange(onlySelected);
    }
}

//
const styles={
    buttonStyle :{
        fontSize:'0.9em',
        width:'calc(100% - 13px)',
        margin:'3px 8px',
        height:'2.222em',
        borderRadius:'4px',
        backgroundColor:'white',
        border:'1px solid #aaa',
        textAlign:'left',
        whiteSpace:'nowrap',
        paddingRight:'2em',
        textOverflow:'ellipsis',
        overflow:'hidden',
        position:'relative'
    }
};

const Carot = (props)=>(
    <div style={{
        position:'absolute',
        right:'0.5em',
        top:0,
        bottom:0
    }}>
        <div style={{
            width:0,
            height:0,
            border: '5px solid transparent',
            borderBottomWidth:0,
            borderTopColor:'#777',
            position:'relative',
            top:'50%',
            transform:'translatey(-50%)',
            ...props.style
        }}>

        </div>
    </div>
);

Carot.propTypes = {style:PropTypes.object};

/*
███████ ██ ██   ████████ ███████ ██████
██      ██ ██      ██    ██      ██   ██
█████   ██ ██      ██    █████   ██████
██      ██ ██      ██    ██      ██   ██
██      ██ ███████ ██    ███████ ██   ██
*/

class Filter extends React.Component{

    static get propTypes(){
        return {
            onChange: PropTypes.func.isRequired,
            onChangeSet: PropTypes.func,
            options: PropTypes.array,
            name: PropTypes.string,
            buttonStyle:PropTypes.object,
            carotStyle: PropTypes.object
        };
    }

    static get defaultProps(){
        return {
            filter:{},
            buttonStyle:{
                width:'100%',
                height:"1.6666em",
                borderRadius:0,
                color:'black',
                margin:0
            },
            carotStyle:{
                borderColor:'black transparent transparent transparent',
                borderWidth:'6px 3px 0'
            },
            onChangeSet:()=>{},
            options:[],
            name:''
        };
    }

    constructor(props){
        super(props);

        this.state = {
            open: false,
            anchorEl: null,

            selected:[]
        };

        this.handleTouchTap = this.handleTouchTap.bind(this);
        this.handleRequestClose = this.handleRequestClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(this.props.options !== nextProps.options){
            this.setState({
                selected:[nextProps.options[0]]
            });
        }
    }


    handleChange(o){
        this.setState({
            selected:o
        });

        this.props.onChange({
            [this.props.name]:(()=>{
                return o.length ? o.map(t=>`<value>${t.value}</value>`).join('') : undefined;
            })()
        });
    }


    shouldComponentUpdate(nextProps, nextState){
        return (nextProps !== this.props) || (nextState !== this.state);
    }

    handleTouchTap(event){
        // This prevents ghost click.
        event.preventDefault();

        let width = this.refs.button.offsetWidth;

        this.setState({
            open: true,
            anchorEl: event.currentTarget,
            width
        });
    }

    handleRequestClose(){
        this.setState({
            open:false
        });
    }

    render(){
        const {buttonStyle,carotStyle,options} = this.props;

        return (
            <div>
                <button style={{...styles.buttonStyle,...buttonStyle}} onClick={this.handleTouchTap} ref="button">
                    {this.getFilterLabel()}
                    <Carot style={carotStyle} />
                </button>
                <FilterMenu
                    open={this.state.open}
                    anchorEl={this.state.anchorEl}
                    onRequestClose={this.handleRequestClose}
                    width={this.state.width}

                    onChange={this.handleChange}
                    options={options}
                />
            </div>
        );
    }

    getFilterLabel(){
        const {selected} = this.state;

        if(!selected || selected.length === 0 || !selected[0]){
            return "No sites";
        }

        if(selected.length > 2){
            return `${selected.length} sites`;
        }

        return selected.map(s=>s.label).join(", ");
    }
}

export const MultiSelectFilter = Filter;
