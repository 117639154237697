import React from 'react';
import PropTypes from 'prop-types';
import RaisedButton from '@material-ui/core/Button';

import StyledDialog from '../../util/Dialog';

import {DataEntryTabularField, DataEntryTabularForm} from '../../util/DataEntryTabularForm';
import Paper from "@material-ui/core/Paper";


const SERVICES_STATE_COLUMN = "serviceStatus";
const SERVICES_STATE_ON = "active";
const SERVICES_STATE_OFF = "inactive";
const SERVICES_STATE_OFFLINE = "offline"; //eslint-disable-line

class StartStopServicesDialog extends React.Component {

    constructor(){
        super();

        this.handleClose = this.handleClose.bind(this);

        this.state = {
            standardLogs: true,
            DETs: false,
            BARs: false,
            WTTs: false,
            duration: 0
        };
    }

    static get propTypes(){
        return {
            open: PropTypes.bool,
            actions: PropTypes.object.isRequired,
            data: PropTypes.object
        };
    }

    static get defaultProps(){
        return {
            open: false,
            data:{ // please remove this...
                appliances:[{
                    uuid:"94ec08bf-2f83-4181-8c91-119dbf53bec8"
                }]
            }
        };
    }

    handleClose ()  {
        this.props.actions.closeDataEntry();
    }

    renderForm(){

        const _data = this.props.data.appliances;
        if(!_data || !_data[0]) return null;
        const data = _data[0];

        const StartStopButton = (props) => (
            <RaisedButton
                className="raised-button services"
                variant="contained"
                {...props}
            >
                {props.label}
            </RaisedButton>
        );

        const servicesState = data[SERVICES_STATE_COLUMN];

        const stateText = ((servicesState === SERVICES_STATE_ON) ? "Running" : (this.isOff(servicesState) ? "Stopped" : "Offline"));

        return (
            <DataEntryTabularForm fullWidth>
                <DataEntryTabularField label={"Appliance"}>
                    {data.name}
                </DataEntryTabularField>
                <DataEntryTabularField>
                    <Paper style={{padding:'1em 1em 2em',backgroundColor:'#eee'}}>
                        <div>
                            {"Services are "}
                            <span style={{fontWeight:'bold',textDecoration:'underline'}}>{stateText}</span>
                        </div>
                        <div style={{textAlign:'center',paddingTop:'1em'}}>
                            <StartStopButton label="Start" disabled={this.cannotStart(servicesState)} style={{marginRight:'2vw'}} onClick={()=>this.props.actions.updateApplianceServices(data._id,true)}/>
                            <StartStopButton label="Stop" disabled={this.cannotStop(servicesState)} onClick={()=>this.props.actions.updateApplianceServices(data._id,false)}/>
                        </div>
                    </Paper>

                </DataEntryTabularField>
            </DataEntryTabularForm>
        );
    }

    isOff(state){
        switch(state){
            case SERVICES_STATE_OFF:
            case "failed":
                return true;
            default:
                return false;
        }
    }

    cannotStart(state){
        return !this.isOff(state);
    }

    cannotStop(state){
        return (state !== SERVICES_STATE_ON);
    }

    render() {

        const {
            actions, // eslint-disable-line
            ...other
        } = this.props;

        return (
            <StyledDialog
                title="Start / Stop Services"
                modal={false}
                open={this.props.open}
                onClose={this.handleClose}
                contentStyle={{maxWidth: 360}}
                actions={[]}
                {...other}
                >
                    {this.renderForm()}
            </StyledDialog>
        );
    }
}

export default StartStopServicesDialog;
