import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import CustomAction from "./CustomAction";
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener";

const DeviceActionSubMenu = props => {
    const {rowIndex, showUp, itemKey, handleActionLogs, handleCloseLogsSubMenu, options, heightMenu, hasScrolling} = props;
    const [subMenuOptions, setSubMenuOptions] = useState({});
    const device = useSelector(state => state.deviceManager.deviceList[rowIndex]);
    // const windowHeight = useSelector(state => state.resize.windowHeight);
    const labelOptions = {
        logs: {
            configureLogs: 'Configure Logs',
            downloadLogs: 'Download Logs',
            historical: "Logs History"
        }
    };

    const logOptions = options || {};
    const handleSetSubMenuOptions = () => {
        setSubMenuOptions(labelOptions[itemKey]);
    };

    const calculatePosition = () => {
        const positionLogs = Object.keys(options).reverse().indexOf('logs');    // 8
        const numberOptions = Object.keys(options).length;  // 14
        if(showUp){
            return hasScrolling?`${heightMenu + heightMenu*0.7+10}px`:`${heightMenu + heightMenu*(positionLogs/numberOptions)+10}px`;
        }else{
            return hasScrolling?`${heightMenu*0.5+10}px`:`${heightMenu*(positionLogs/numberOptions)+10}px`;
        }
    }

    useEffect(handleSetSubMenuOptions, [itemKey]);
    const styleCell = {bottom: calculatePosition(), left: hasScrolling?'-172px':'-155px'};

    return (
        <ClickAwayListener onClickAway={handleCloseLogsSubMenu}>
            <div className="ListMenu" style={styleCell}>
                <div className="liMenu" style={{minWidth:'115px'}}>
                    {subMenuOptions && Object.keys(subMenuOptions).map((key, index) =>
                        <CustomAction key={index}
                            value={subMenuOptions[key]} enabled={logOptions[key]}
                            handleAction={()=>handleActionLogs(key,rowIndex)}
                            protocol={device.protocol} actionKey={key}
                        />
                    )}
                </div>
            </div>
        </ClickAwayListener>
    )
};

export default DeviceActionSubMenu;