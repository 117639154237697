import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
// import DeleteIcon from '@material-ui/icons/Delete';
import DisassociateIcon from '@material-ui/icons/LinkOff';
import ReactToolTip from 'react-tooltip';
import DialogConfirm from './DialogConfirm';
import {v4 as uuidv4} from 'uuid';

const useStyles = makeStyles({
    paperDialog: {
        overflowY: "visible",
        marginTop: "4em"
    },
    scrollPaper:{
        alignItems: "flex-start"
    }
});

const DialogAutoZones = (props) => {
    const {open, device, closeDialog, configureZones, objectZones, antennasWithoutZone, zones, zonesWithAntennas} = props;
    const rfidAntennas = device.rfidAntennas;
    const classes = useStyles();
    const styleButton = {backgroundColor:'#4c4c4c',color:'#fff',border:'none',fontSize:'12px',padding:'0.75em'};
    const styleHeaderList = {backgroundColor: '#333d47', color: '#fff', paddingRight: '4px', paddingLeft: '4px'};
    const [listAntennas, setListAntennas] = useState([]);
    const [autozones, setAutozones] = useState({});
    const [antennaSelected, setAntennaSelected] = useState(rfidAntennas[0].title);
    const [zoneSelected, setZoneSelected] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [showRemoveZonesDialog, setShowRemoveZonesDialog] = useState(false);
    const [zoneNameValidation, setZoneNameValidation] = useState(null);
    const [deleteZoneValidation, setDeleteZoneValidation] = useState(null);
    const [zonesWithoutAntennasVal, setZonesWithoutAntennasVal] = useState(false);
    const [messsageNameVal, setMesssageNameVal] = useState(null);
    const [zonesOnMap, setZonesOnMap] = useState([]);
    const ALL = 'ALL';
    const [showExistZones, setShowExistZones] = useState(false);
    const [numberZones, setNumberZones] = useState(1);

    // messages
    const messageRecreate = 'Are you sure to remove all created auto zones?';
    const messageEmptyAutoZones = 'Auto zones without antennas were detected. \n All auto zones without antennas will be removed.';
    const messageDeleteZone = ` will be removed from list of available auto zones. \n Are you sure to remove this auto zone?`;
    const loadZones = () => setZonesOnMap(zones);
    const initAutoZones = () => {
        setAutozones({...objectZones});
        setZoneSelected(Object.keys(objectZones)[0]);
        setListAntennas(antennasWithoutZone);
    }

    useEffect(loadZones, [zones]);
    useEffect(initAutoZones, [objectZones, antennasWithoutZone]);

    const updateNumberMaxZones = () => {
        setNumberZones(rfidAntennas.length - Object.keys(autozones).length);
    }
    useEffect(updateNumberMaxZones, [autozones]);    

    const recreateZones = () => {
        let i = 0;
        const newAutozones = {};
        while(i < numberZones){
            let zonesOnDialog = {...autozones, ...newAutozones};
            const arrayAutoZones = Object.keys(zonesOnDialog).map(key => zonesOnDialog[key].name);
            let titleAutozone;
            let index = 1;

            // creating a auto zone name
            do{
                titleAutozone = `${device.ipAddress.length <= 85 ? device.ipAddress : device.ipAddress.substr(0, 35)}-${index}-az`;
                index++;
            }while(arrayAutoZones.indexOf(titleAutozone) > -1);

            // checking if auto zone name exists on redux store
            let existingAutoZone = zonesOnMap.find(z => z.name === titleAutozone);
            if(existingAutoZone){
                newAutozones[existingAutoZone.locationGuid] = {name: existingAutoZone.name, originalName: existingAutoZone.name, disabled: true, editable: true, antennas: []};
            }else{
                const newLocationGuid = '{' + uuidv4() + '}';
                newAutozones[newLocationGuid] = {name: titleAutozone, originalName: titleAutozone, disabled: true, editable: true, antennas: []};
            }
            i++;
        }
        setAutozones(oldState => ({...oldState, ...newAutozones}));
    }

    const handleRecreateZones = () => {
        setListAntennas(rfidAntennas.map(antenna => antenna.title));
        setAutozones({});
    }

    const addExistAutoZone = (keyZone) => {
        if(keyZone){
            const objectZonesOnMap = zonesOnMap.reduce((acc, zone) => ({...acc, [zone.locationGuid]: {locationGuid: zone.locationGuid, name: zone.name}}),{});
            const titleAutozone = objectZonesOnMap[keyZone].name;
            const newObjectZone = {name: titleAutozone, originalName: titleAutozone, locationGuid: keyZone, disabled: true, editable: true, antennas: []};
            setAutozones(oldState => ({...oldState, [keyZone]: newObjectZone}));
            setShowExistZones(false);
        }
    }

    const renderCloseButton = () => {
        return (
            <div style={{position:'absolute',top:'-0.5em',right:'-0.5em'}}>
                <IconButton aria-label="close"
                    size="small"
                    onClick={closeDialog}
                    style={{cursor:'pointer',position:'relative',zIndex:'100',backgroundColor:'#000'}}
                    >
                    <CloseIcon style={{color: '#fff'}} />
                </IconButton>
            </div>
        );
    }
    
    const handleSuccessButton = () => {
        setErrorMessage(null);
        const objectZonesOnMap = zonesOnMap.reduce((acc, zone) => ({...acc, [zone.locationGuid]: {locationGuid: zone.locationGuid, name: zone.name}}),{});
        const allZonesOnMap = {...objectZonesOnMap, ...autozones};
        const arrayAutoZones = Object.keys(allZonesOnMap).map(key => allZonesOnMap[key].name);
        const noValidateAutoZones = arrayAutoZones.reduce((acc, item, i, arr) => ((arr.indexOf(item) !== i && acc.indexOf(item) < 0) || item.trim() === '')? [...acc, item]: acc, []);
        const autoZonesWithoutAntennas = Object.keys(autozones).filter(key => autozones[key].antennas.length === 0);
        if(noValidateAutoZones.length > 0){
            setZoneNameValidation(ALL);
            setMesssageNameVal('Auto zone names must be unique and not empty.');
        }else if(autoZonesWithoutAntennas.length > 0){
            setZonesWithoutAntennasVal(true);
        }else{
            handleConfigureAutoZones();
        }
    }

    const handleConfigureAutoZones = () => {
        const NO_ZONE = 'No Zone';
        const zonesObject = {...autozones, [NO_ZONE]: {antennas: listAntennas}}
        configureZones(zonesObject);
        closeDialog();
    }

    const changeZoneName = (value, key) => {
        const updateZones = Object.keys(autozones).reduce((acc, zone, i) => {zone===key? acc[zone] = {...autozones[zone], name: value} : acc[zone] = autozones[zone]; return acc}, {});
        setAutozones(updateZones);
        setErrorMessage(null);
    }

    const enableZoneInput = (value, key) => {
        const updateZones = Object.keys(autozones).reduce((acc, zone, i) => {zone===key? acc[zone] = {...autozones[zone], disabled: value} : acc[zone] = autozones[zone]; return acc}, {});
        setAutozones(updateZones);
        setErrorMessage(null);
    }
    
    const disassociateZone = (key) => {
        // updating the allowed zones in the configuration
        if(!zonesWithAntennas[key]){
            setDeleteZoneValidation(key);
        }else{
            const updateZones = Object.keys(autozones).filter(z => z !== key).reduce((acc, zone) => {acc[zone] = autozones[zone]; return acc} ,{});
            const antennasZone = autozones[key].antennas;
            //updating and ordering antennas
            setListAntennas(orderAntennaList([...listAntennas, ...antennasZone]));
            setAutozones(updateZones);
            setErrorMessage(null);
        }
    }

    const handleRemoveAutoZone = () => {
        setZonesOnMap(oldState => oldState.filter(z => z.locationGuid !== deleteZoneValidation));
        const updateZones = Object.keys(autozones).filter(z => z !== deleteZoneValidation).reduce((acc, zone) => {acc[zone] = autozones[zone]; return acc} ,{});
        const antennasZone = autozones[deleteZoneValidation].antennas;
        //updating and ordering antennas
        setListAntennas(orderAntennaList([...listAntennas, ...antennasZone]));
        setAutozones(updateZones);
        setErrorMessage(null);
    }
    
    const addAntennaToZone = () => {
        if(listAntennas.indexOf(antennaSelected) > -1 && autozones[zoneSelected]){
            const updateAntennaList = listAntennas.filter(antenna => antenna !== antennaSelected);
            const valuesZone = autozones[zoneSelected];
            setListAntennas(updateAntennaList);        
            setAutozones({...autozones, [zoneSelected]: {...valuesZone, antennas: orderAntennaList([...valuesZone.antennas, antennaSelected])}});
            if(updateAntennaList.length > 0) {setAntennaSelected(updateAntennaList[0]);}
            setErrorMessage(null);
        }else{
            setErrorMessage('First select an auto zone, then an antenna of antennas list.');
        }
    }

    const orderAntennaList = (antennaList) => {
        const listAntennasOrdered = rfidAntennas.reduce((acc, antenna) =>  {
            if(antennaList.indexOf(antenna.title) > -1) acc.push(antenna.title);
            return acc;
        }, []);

        return listAntennasOrdered;
    }

    const addAntennaToList = () => {
        if(autozones[zoneSelected] && autozones[zoneSelected].antennas.indexOf(antennaSelected) > -1){
            const keyZone = zoneSelected;
            const valuesZone = autozones[keyZone];    
            setAutozones({...autozones, [keyZone]: {...valuesZone, antennas: valuesZone.antennas.filter(antenna => antenna !== antennaSelected)}});
            //updating and ordering antennas
            setListAntennas(orderAntennaList([...listAntennas, antennaSelected]));
            setErrorMessage(null);
        }else{
            setErrorMessage(`First select an antenna of auto zone's lists.`);
        }
    }

    const changeNumberZones = e => {
        if(Number(e.target.value) <= rfidAntennas.length){
            setNumberZones(Number(e.target.value));
        }
    }

    const changeDefaultName = (locationGuid) => {
        const updateZones = Object.keys(autozones).reduce((acc, zone, i) => {zone===locationGuid? acc[zone] = {...autozones[zone], name: autozones[zone].originalName, disabled: true} : acc[zone] = autozones[zone]; return acc}, {});
        setAutozones(updateZones);
    }

    const saveNameZone = (locationGuid) => {
        const objectZonesOnMap = zonesOnMap.reduce((acc, zone) => ({...acc, [zone.locationGuid]: {locationGuid: zone.locationGuid, name: zone.name}}),{});
        const allZonesOnMap = {...objectZonesOnMap, ...autozones};
        const numberSameNames = Object.keys(allZonesOnMap).reduce((acc, zone) => allZonesOnMap[zone].name === allZonesOnMap[locationGuid].name? acc+1: acc, 0);
        if(autozones[locationGuid].name.trim() === ''){
            setMesssageNameVal('Auto zone name can not be empty.');
            setZoneNameValidation(locationGuid);
        }else if(numberSameNames > 1){
            setMesssageNameVal(`"${autozones[locationGuid].name}" is already defined by another zone. \n Must define auto zone name.`);
            setZoneNameValidation(locationGuid);
        }else{
            enableZoneInput(true, locationGuid);
        }
    }

    const renderAutozoneContainer = (locationGuid, i) => {
        let styleAutozoneContainer = {border:'1px solid #333d47', marginBottom: '0.75em'};
        let styleHeaderZone = {...styleHeaderList, display: 'flex', alignItems: 'center'};
        if(zoneSelected === locationGuid) styleHeaderZone['backgroundColor'] = '#007CB0';
        return <div style={styleAutozoneContainer} key={i} onClick={() => setZoneSelected(locationGuid)}>
            <div style={styleHeaderZone}>
                {!autozones[locationGuid].disabled ? 
                    <input id={locationGuid} style={{flex: 1, color: '#000'}} maxLength={56} disabled={autozones[locationGuid].disabled} autoFocus
                            value={autozones[locationGuid].name} onChange={e => changeZoneName(e.target.value, locationGuid)} />:
                    <span style={{flex: 1}}>{autozones[locationGuid].name}</span>}
                {autozones[locationGuid].editable && <>
                    {autozones[locationGuid].disabled ? 
                    <>
                        <EditIcon style={{color: '#fff', cursor: 'pointer'}} onClick={e => enableZoneInput(false, locationGuid)} data-tip={"Edit auto zone name"} data-for={`editAutoZone-${locationGuid}`}/>
                        <ReactToolTip place={"bottom"} id={`editAutoZone-${locationGuid}`} effect={"solid"} />
                    </>: 
                    <>
                        <CheckIcon style={{color: '#fff', cursor: 'pointer'}} onClick={e => saveNameZone(locationGuid)} />
                        <CloseIcon style={{color: '#fff', cursor: 'pointer'}} onClick={e => changeDefaultName(locationGuid)} />
                    </>}
                </>}
                <DisassociateIcon style={{color: '#fff', cursor: 'pointer'}} onClick={() => disassociateZone(locationGuid)} data-tip={"Disassociate auto zone from device"} data-for={`disassociateAutoZone-${locationGuid}`}/>
                <ReactToolTip place={"bottom"} id={`disassociateAutoZone-${locationGuid}`} effect={"solid"} />
            </div>
            <div style={{padding: '4px', minHeight: '24px', display: 'flex', flexWrap:'wrap'}}>
                {autozones[locationGuid].antennas.map((antenna, i) => <AntennaChip key={i} label={antenna} selected={antennaSelected === antenna} clickAntenna={() => setAntennaSelected(antenna)}/>)}
            </div>
        </div>
    }

    const renderRecreateZonesContent = () => {
        let styleDivContent = {display: 'flex', width: '100%', alignItems: 'center', padding: '0em 0.25em 1em 0.25em', fontSize: '12px'};
        styleDivContent.opacity = rfidAntennas.length > Object.keys(autozones).length? 1: 0.4;
        styleDivContent.pointerEvents = rfidAntennas.length > Object.keys(autozones).length? 'auto': 'none';
        return (
            <div style={styleDivContent}>
                <span>Create
                <input type='number' min={0} max={rfidAntennas.length - Object.keys(autozones).length} value={numberZones} style={{marginRight: '6px', marginLeft: '6px'}} onChange={changeNumberZones}/>
                    new auto zones
                <button style={{background: '#007cb0', color: '#fff', margin: '0 0.25em', border: 'none'}} onClick={recreateZones}>OK</button>
                    or&nbsp;
                {renderAddExistingZoneContent()}
                </span>
            </div>
        );
    }

    const renderAddExistingZoneContent = () => {
        const styleAddExistingZoneButton = {...styleButton, background: '#fff',color: '#007CB0', padding: '0.2em 0.25em'};
        return !showExistZones ? <button style={styleAddExistingZoneButton} onClick={() => setShowExistZones(true)}>Add existing auto zone</button>: 
        <select style={{fontSize: '12px'}} value="" onChange={e => addExistAutoZone(e.target.value)}>
            <option value="" disabled>Select existing auto zone</option>
            {zonesOnMap.filter(z => Object.keys(autozones).indexOf(z.locationGuid) === -1).map((z, i) => <option key={i} value={z.locationGuid}>{z.name}</option>)}
        </select>;
    }

    const styleTitleDialog = {paddingBottom:0, fontSize:'1.2em', fontWeight: 'bold', whiteSpace:'nowrap', overflow:'hidden', textOverflow: 'ellipsis'};
    return (
        <React.Fragment>
            <Dialog
                fullWidth
                maxWidth={'md'}
                open={open}
                onClose={closeDialog}
                classes={{paper:classes.paperDialog, scrollPaper: classes.scrollPaper}}>
                <DialogTitle style={styleTitleDialog} disableTypography>
                    {`Auto zones for ${device.title}`}
                </DialogTitle>
                <DialogContent>
                    {renderRecreateZonesContent()}
                    <div style={{width: '100%', display:'flex',flexWrap:'nowrap', height:`${window.innerHeight - 300}px`,maxHeight:'100%'}}>
                        {Object.keys(autozones).length > 0?
                            <div style={{overflowY:'auto', flex: 1}}>
                                {Object.keys(autozones).map((zone, i) => renderAutozoneContainer(zone, i))}
                            </div>:
                            <div style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '18px', opacity: 0.7}}>
                                There are not auto zones created
                            </div>
                        }
                        <div style={{flexBasis: '120px', display:'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                            <Button style={{width:'33px', margin: '6px 0px', backgroundColor: '#9e9e9e', color: '#fff'}} onClick={addAntennaToZone} color="primary">{`<<`}</Button>
                            <Button style={{width:'33px', margin: '6px 0px', backgroundColor: '#9e9e9e', color: '#fff'}} onClick={addAntennaToList} color="primary">{`>>`}</Button>
                        </div>
                        <div style={{overflowY:'auto', flex: 1}}>
                            <div style={{border:'1px solid #333d47'}}>
                                <div style={styleHeaderList}>Antennas list</div>
                                <div style={{padding: '4px', minHeight: '24px', display: 'flex', flexDirection:'column', flexWrap: 'wrap'}}>
                                    {listAntennas.map((antenna, index) => {
                                        return <AntennaChip key={index} label={antenna} selected={antennaSelected === antenna} clickAntenna={() => setAntennaSelected(antenna)}/>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    {errorMessage && 
                    <div style={{display:'flex',alignItems:'center', backgroundColor: '#fdecea', color: 'red', padding:'0.25em'}}>
                        <ErrorOutlineIcon />
                        <span style={{marginLeft: '0.5em', flex: 1, fontSize: '12px', textAlign: 'justify'}}>{errorMessage}</span>
                    </div>}
                    <DialogContentText style={{margin: 0}}>
                        No changes will be committed until <b>Save All</b> is clicked.
                    </DialogContentText>
                </DialogContent>
                {renderCloseButton()}
                <DialogActions style={{padding:'0px 24px 8px 24px'}}>
                    <button style={{...styleButton, backgroundColor:'#fff',color:'red'}} onClick={() => setShowRemoveZonesDialog(true)} disabled={Object.keys(autozones).length === 0}>
                        Delete and Recreate
                    </button>
                    <button style={{...styleButton, backgroundColor:'#fff',color:'#007CB0'}} onClick={closeDialog}>
                        Cancel
                    </button>
                    <button style={{...styleButton, backgroundColor: '#007CB0',color: '#fff'}} onClick={handleSuccessButton}>
                        OK
                    </button>
                </DialogActions>           
            </Dialog>

            {zoneNameValidation && <DialogConfirm 
                open={Boolean(zoneNameValidation)}
                closeDialog={() => {setZoneNameValidation(null); setMesssageNameVal(null);}}
                actionSuccess={() => enableZoneInput(false, zoneNameValidation)}
                actionCancel={()=> zoneNameValidation !== ALL && changeDefaultName(zoneNameValidation)}
                message={messsageNameVal}
            />}

            {showRemoveZonesDialog && <DialogConfirm 
                open={showRemoveZonesDialog}
                closeDialog={() => setShowRemoveZonesDialog(false)}
                actionSuccess={handleRecreateZones}
                actionCancel={()=>{}}
                message={messageRecreate}
            />}

            {zonesWithoutAntennasVal && <DialogConfirm 
                open={zonesWithoutAntennasVal}
                closeDialog={() => setZonesWithoutAntennasVal(false)}
                actionSuccess={handleConfigureAutoZones}
                actionCancel={()=>{}}
                message={messageEmptyAutoZones}
            />}

            {deleteZoneValidation && <DialogConfirm 
                open={Boolean(deleteZoneValidation)}
                closeDialog={() => setDeleteZoneValidation(null)}
                actionSuccess={handleRemoveAutoZone}
                actionCancel={() => {}}
                message={<span><b>{autozones[deleteZoneValidation].name}</b>{messageDeleteZone}</span>}
            />}
        </React.Fragment>
    );
}

export default DialogAutoZones;


const AntennaChip = (props) => {
    const {label, selected, clickAntenna} = props;
    let styleChip = {padding: '0px 4px', margin: '2px', textAlign: 'center', cursor: 'pointer'};
    if(selected){
        styleChip.backgroundColor = '#007CB0';
        styleChip.color = '#fff';
    }else{
        styleChip.backgroundColor = '#fff';
        styleChip.border = '1px solid #007CB0';
        styleChip.color = '#007CB0';
    }
    return <div style={styleChip} onClick={clickAntenna}>{label}</div>
}