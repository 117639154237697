import React from 'react';
import PropTypes from 'prop-types';
import scrollbarSize from 'dom-helpers/scrollbarSize';
import {getToken} from '../../actions/util';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {requestMaps} from '../../actions';

import {MAP_VIEW_URL, SITE_VIEW_HOST} from '../../constants/Misc';

export const overlayHeight = 90+scrollbarSize();

/*
████████ ██   ██ ██    ██ ███    ███ ██████  ███    ██  █████  ██ ██
   ██    ██   ██ ██    ██ ████  ████ ██   ██ ████   ██ ██   ██ ██ ██
   ██    ███████ ██    ██ ██ ████ ██ ██████  ██ ██  ██ ███████ ██ ██
   ██    ██   ██ ██    ██ ██  ██  ██ ██   ██ ██  ██ ██ ██   ██ ██ ██
   ██    ██   ██  ██████  ██      ██ ██████  ██   ████ ██   ██ ██ ███████
*/



export class SiteMapThumbnail extends React.Component{

    static get propTypes(){
        return {
            name: PropTypes.string,
            img: PropTypes.node,
            style: PropTypes.object,
            tagsQty: PropTypes.number
        };
    }

    static get defaultProps(){
        return {
            style:{},
            tagsQty: 0
        };
    }

    handleOpenTooltip(){
        this.refs.thumbTooltip.style.display = 'block';
    }

    handleCloseTooltip(){
        this.refs.thumbTooltip.style.display = 'none';
    }

    render(){

        const {name, img, currentMapId, thumbnailId, verticalPosition, style, tagsQty, isPlayback,...other} = this.props;
        let _img = React.cloneElement(img,{className:"filled-up mp-tmb"});

        let classThumbnail = 'map-thumbnail';

        if(currentMapId === thumbnailId){
            classThumbnail += ' map-thumbnail-active';
        }

        const thumbStyle = {
            ...style,
            display: verticalPosition ? '' : 'inline-block'
        };

         return (

                 <div className={classThumbnail} style={thumbStyle} {...other}>
                     <div style={{fontSize:'0.8em',height:'20px',width:(window.innerWidth <= 375) && verticalPosition ? '60px' : '75px',overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap',lineHeight:'20px'}}>
                         {name || "Vic-01-Floor-01-Something-Else"}
                     </div>
                     <div style={{width:(window.innerWidth <= 375) && verticalPosition ? '60px' : '75px',height:'60px',background:'white',boxSizing:'border-box'}}>
                         {_img}
                     </div>
                     {isPlayback ?
                         <div>
                             <div data-tip data-for="tagInfo" data-event='click focus' className={"tags-number"} onMouseEnter={() => this.handleOpenTooltip()} onMouseLeave={() => this.handleCloseTooltip()}>{tagsQty}</div>
                             <div ref={"thumbTooltip"} className={"thumbnail-tooltip"} style={{display:"none"}}>Tags blinked on <br/> this date range: <br/> {tagsQty}</div>
                         </div>
                         : null}
                 </div>
         );
    }
}

class SiteMapThumbnailOverlay extends React.Component{
    static get propTypes(){
        return {
            maps: PropTypes.arrayOf(
                PropTypes.shape({
                    src: PropTypes.string.isRequired,
                    name: PropTypes.string.isRequired
                })
            ).isRequired,
            onSelectMap: PropTypes.func,
            timestamp: PropTypes.number
        };
    }

    static get defaultProps(){
        return {
            onSelectMap: ()=>{}
        };
    }

    render(){

        const {maps,onSelectMap,timestamp, currentMapId, verticalPosition, isPlayback, siteManager} = this.props;
        const thumbnails = maps.map((v,i)=>(
                <SiteMapThumbnail
                    key={i}
                    name={v.name}
                    img={
                        <img alt={v.name} style={{overflow:"hidden"}} src={`${v.src}?timestamp=${timestamp}&jwt=${getToken()}`}/>
                    }
                    onClick={onSelectMap.bind(null, i)}
                    currentMapId={currentMapId}
                    thumbnailId={v._id}
                    verticalPosition={verticalPosition}
                    isPlayback={isPlayback}
                    tagsQty={v.tagsQty}
                />

        ));
        let height = (window.innerWidth <= 375) && verticalPosition ? "80%" : "85%";
        let width = (window.innerWidth <= 375) && verticalPosition ? "92px" : overlayHeight+ "px";

        const styles = {
            marginLeft:verticalPosition || siteManager ? 0 : '10px',
            marginRight:verticalPosition || siteManager ? 0 : '10px',
            position:'absolute',
            bottom:0,
            left:verticalPosition ? 'auto' : 0,
            right:0,
            top:verticalPosition ? 0 : 'auto',
            height: verticalPosition ? height : overlayHeight+'px',
            width:verticalPosition ? width : '100%',
            padding:'0.25em 0',
            background:'rgba(0,0,0,0.7)',
            overflowX:verticalPosition ? 'hidden' : 'scroll',
            overflowY:verticalPosition ? 'scroll' : 'hidden',
            whiteSpace:'nowrap',
            display:((maps.length > 0) ? 'flex':'none'),
            flexDirection: verticalPosition ? 'column' : 'row'
        };

        return (
            <div style={styles}>
                {thumbnails}
            </div>
        );
    }
}

export default SiteMapThumbnailOverlay;



class DumbSiteMapThumbnailDrawer extends React.Component{
    static get propTypes(){
        return {
            onSelectMap: PropTypes.func,
            maps:PropTypes.array,
            requestMaps: PropTypes.func.isRequired
        };
    }

    static get defaultProps(){
        return {
            onSelectMap:()=>{}
        };
    }
    constructor(){
        super();
        this.handleSelectMap = this.handleSelectMap.bind(this);
    }

    componentDidMount(){
        this.props.requestMaps();
    }

    render(){
        const {maps, mapId, verticalPosition,isPlayback,isMobile} = this.props;
        return (
            <SiteMapThumbnailOverlay
                maps={maps}
                currentMapId={mapId}
                onSelectMap={this.handleSelectMap}
                verticalPosition={verticalPosition}
                isPlayback={isPlayback}
                isMobile={isMobile}
             />
        );
    }

    handleSelectMap(index){
        const {onSelectMap,maps} = this.props;
        onSelectMap(maps[index]);
    }
}


const SmartSiteMapThumbnailDrawer = connect(
    (state,{siteId, mapId, tagsQtyByMaps})=> ({
        maps: state.sites.maps.filter(m=>m.siteId === siteId).map(m=>({
                ...m,
                name: m.description,
                src:SITE_VIEW_HOST + MAP_VIEW_URL +"/maps/" + m._id + "/tiles/0_0_0",
                tagsQty: !!tagsQtyByMaps ? tagsQtyByMaps[m._id] : 0
            })),
        mapId: mapId,
    }),
    dispatch => ({
        ...bindActionCreators({
            requestMaps
        },dispatch)
    })
)(DumbSiteMapThumbnailDrawer);

export {SmartSiteMapThumbnailDrawer};
