import {
    TAG_HEALTH,
    ZLA_STATUS_ACTION,
    DEVICE_ALERT_CHART,
} from "../../../../constants/ActionTypes";

export const TAG_BLINK_HEALTH = {
    id: 8,
    url:"/dashboards/widgets/8/data",
    type: TAG_HEALTH,
    reportLink:"/reports/2D50E6C5-F1C7-4544-A91F-B1DA38045411"
};

export const ZLA_STATUS ={
    id:6,
    url:"/appliances/status",
    type: ZLA_STATUS_ACTION
};

export const DEVICE_ALERT = {
    id: 7,
    url: "/dashboards/widgets/7/data",
    type: DEVICE_ALERT_CHART,
    reportLink:"/reports/CF2768DA-D459-11D3-9019-00105A6AE011"
};