import React, {useRef, useEffect} from 'react';
import OLMap from 'ol/Map';
import OSM from 'ol/source/OSM';
import {OSM_SOURCE} from "../../constants/Misc";
import {Tile} from "ol/layer";
import {getToken} from "../../actions/util";
// import View from "ol/View";

const MapOSM = React.forwardRef((props, olMapRef) => {
    const {
        coordinates,
        onFinishLoadMap,
        updateMapSize = null,
        zoom = 9
    } = props;
    const olMap = useRef();
    const olMapContainer = useRef();

    const initMapLayers = () => {
        const tileLayer = new Tile({
            source: new OSM({
                url: `${OSM_SOURCE}/{z}/{x}/{y}.png?jwt=${getToken()}`
            })
        });


        let map = new OLMap({
            layers: [tileLayer],
            center:[0,0],
            resolution:25000,
            target: null
        });


        olMap.current = map;

        if(olMapRef!=null)
            olMapRef.current=map;

    };

    useEffect(initMapLayers, []);

    // const  getView = (coordinates,zoom) => {
    //     return new View({
    //         center: coordinates,
    //         zoom: zoom
    //     })
    // };

    const handleRenderComplete = () => {
        olMap.current.un('rendercomplete', handleRenderComplete);
        if (onFinishLoadMap != null) {
           onFinishLoadMap()
        }
    };

    const updateMapLayer = () => {
        olMap.current.on('rendercomplete', handleRenderComplete);
        olMap.current.setTarget(olMapContainer.current);

    };

    useEffect(updateMapLayer, [coordinates, zoom]);

    // update Map size.
    useEffect(()=>{
        if(olMap!=null&&olMap.current!=null) {
            olMap.current.updateSize();
        }
    }, [updateMapSize]);

    return (
        <div ref={olMapContainer} className="vss-map map-zoom-button">
        </div>
    );
});

export default React.memo(MapOSM,((prevProps,nextProps) => {
    return (prevProps.coordinates[0]===nextProps.coordinates[0]
        && prevProps.updateMapSize === nextProps.updateMapSize
        && prevProps.zoom === nextProps.zoom
    );
}));
// export default MapOSM;