import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { Warning as WarningIcon} from '@material-ui/icons';
import {selectAllLinkedDevices, clickRowLinkedDevice} from '../../../../actions';
//for table component
import {Link} from '@material-ui/core';
import CrossBrowserTable from '../tableComponent/CrossBrowserTable';
import DeviceStatus from "../DeviceStatus";
import AntennasStatus from "../AntennasStatus";


const LinkedDevices = (props) => {
    const {showWarning, checkColumn} = props;
    const linkedDevices = useSelector(state => state.deviceManager.linkedDevices);
    const renderItemList = (content) => {
        return (
            <div style={{display:'flex',flexDirection:'row'}}>
                <div style={{flexBasis:'25px'}}>*</div>
                <div style={{flex:1}}>{content}</div>
            </div>
        )
    }

    const NUMBER_MAX_ROWS = 4;
    return (
        <React.Fragment>
            {(linkedDevices && linkedDevices.length > 0) && 
            <div style={{width:'100%', padding:'0 0.3em'}}>
                {showWarning &&
                <div style={{display:'block',backgroundColor:'#ffe88a',padding:'1em',marginBottom:'1em'}}>
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start', alignItems:'center'}}>
                        <div style={{flexBasis:'auto', alignSelf: 'flex-start'}}><WarningIcon fontSize={'large'} /></div>
                        <div style={{paddingLeft:'0.6em', flex:1}}>
                            <span style={{fontWeight:'bold',fontSize:'18px',display:'block',paddingBottom:'1em'}}>Please confirm the devices to update with the changes</span>
                            {renderItemList('Checked devices will be updated with the template changes')}
                            {renderItemList('Unchecked devices will stay unchanged and be un-tied from this template')}
                        </div>
                    </div>
                </div>}

                <div style={{width:'100%', backgroundColor:'#fff', height: (linkedDevices.length > 4)?`${40 * NUMBER_MAX_ROWS + 60}px`:`${40 * linkedDevices.length + 60}px`}}>
                    <TableDevicesExample devices={linkedDevices} checkColumn={checkColumn}/>
                </div>
            </div>}
        </React.Fragment>
    );
}

export default LinkedDevices;

const TableDevicesExample = ({devices, checkColumn}) => {

    let headerRow = [{key:"deviceType",value:"Device Type",clickable:true,width:120},
                    {key:"rfidType",value:"Device Model",clickable:true,width:110},
                    {key:"title",value:"Name",clickable:false,width:250},
                    {key:"ipAddress",value:"Hostname",clickable:true,width:124},
                    {key:"status",value:"Status",clickable:true,width:114},
                    {key:"rfidAntennas",value:"Antennas",clickable:true,width:116},
                    {key:"configuration_state",value:"Conf. State",clickable:true,width:100}];

    const linkedSelectedRows = useSelector(state => state.deviceManager.linkedSelectedRows);
    const dispatch = useDispatch();
    const customCell = ({columnIndex, content}) => {
        let contentCell = null;
        switch(columnIndex){
            case 2:
                contentCell = <Link style={{color:'#007CB0'}}>{content}</Link>
            break;
            case 4:
                contentCell = <DeviceStatus status={content} />;
            break;
            case 5:
                contentCell = <AntennasStatus antennas={content} />
            break;
            default:
                contentCell = content;
            break;
         }
         return <React.Fragment>{contentCell}</React.Fragment>
    }

    const handleClickRow = (rowIndex) => {
        dispatch(clickRowLinkedDevice(rowIndex));
    }

    const handleSelectAll = () => {
        dispatch(selectAllLinkedDevices());
    }

    return (
        <CrossBrowserTable
            data={devices}
            showCheckColumn={checkColumn}
            headerRow={headerRow}
            rowCount={devices.length}
            cellRender={customCell}
            selectedRows={linkedSelectedRows}
            onClickRow={handleClickRow}
            onSelectAll={handleSelectAll}
            modernBrowser={true}
            appliedFilters={()=>null}
        />
    );
}

export {TableDevicesExample};