import {displayErrorDialog, expireSession} from '../general';
import Promise from 'promise';
import axios from 'axios';
import {
    ACCESS_HOST,
    ACL_HOST,
    ICS_HOST,
    IMPORT_SERVICE_HOST,
    REPORT_SERVICE_HOST,
    SITE_VIEW_HOST,
    ZONE_SERVICE_HOST,
    DEVICE_MANAGEMENT_SERVICE_HOST,
} from '../../constants/Misc';

export function displayErrorFromAxios(dispatch,err,{ignore403=false}={}){
    
    var message = getErrorMessageFromAxios(err);
    if(!ignore403 && sessionHasExpired(err)){
        dispatch(expireSession());
        return Promise.reject(err);
    }

    if(typeof message === "object"){
        console.log("UNKNOWN SERVER ERROR --", message); // eslint-disable-line
        if(message.error){
            message = message.error;
        }else{
            message = "Unknown Server Error.  See console for details.";
        }
    }
    
    dispatch(displayErrorDialog({
        message:message
    }));

    return Promise.reject(err);
}

export function sessionHasExpired(err){
    if(err && err.response){
        switch(err.response.status){
            case 401:
            case 403:
                return true;
            default:
                return false;
        }
    }

    return false;
}

export function getErrorMessageFromAxios(err){
    if(err.response && err.response.data){
        // checking data
        if(err.response.data.status?.error){
            return err.response.data.status.error;
        }else if(err.response.data.message){
            return err.response.data.message;
        }else{
            return err.response.data;
        }
    }else if(err.message){
        return err.message;
    }else{
        return 'Unknown Server Error';
    }
    // return (err.response && err.response.data) || err.message || "Unknown Server Error";
}

/*
███████ ███████ ██████  ██    ██ ██  ██████ ███████ ███████
██      ██      ██   ██ ██    ██ ██ ██      ██      ██
███████ █████   ██████  ██    ██ ██ ██      █████   ███████
     ██ ██      ██   ██  ██  ██  ██ ██      ██           ██
███████ ███████ ██   ██   ████   ██  ██████ ███████ ███████
*/


const reportServiceInstance = (username,token)=>{
    return axios.create({
        baseURL: REPORT_SERVICE_HOST,
        timeout:300000, // 5 minutes in milliseconds
        headers:{
            'X-Consumer-Username':username || "",
            'Authorization':'Bearer '+token,
            'Cache-Control':'no-cache, no-store, must-revalidate',
            'Pragma':'no-cache',
            'Expires': 0
        }
    });
};

const siteViewServiceInstance = (username,token)=>{
    return axios.create({
        baseURL: SITE_VIEW_HOST,
        timeout:300000,
        headers:{
            'X-Consumer-Username':username || "",
            'Authorization':'Bearer '+token,
            'Cache-Control':'no-cache, no-store, must-revalidate',
            'Pragma':'no-cache',
            'Expires': 0
        }
    });
};

function createStandardAxiosConnection(baseUrl,{ timeout, headers={} }={}){
    return (token)=>{
        const config = {
            baseURL: baseUrl,
            headers:{
                'Authorization':'Bearer '+token,
                /*'Cache-Control':'no-cache, no-store, must-revalidate',
                'Pragma':'no-cache',*/
                /*'Expires': 0,*/
                ...headers
            }
        };

        if(timeout){
            config.timeout = timeout;
        }
        return axios.create(config);
    };
}

function createStandardAxiosConnectionNoCache(baseUrl,{ timeout, headers={} }={}){
    return (token)=>{
        const config = {
            baseURL: baseUrl,
            headers:{
                'Authorization':'Bearer '+token,
                'Cache-Control':'no-cache, no-store, must-revalidate',
                'Pragma':'no-cache',
                'Expires': 0,
                ...headers
            }
        };

        if(timeout){
            config.timeout = timeout;
        }
        return axios.create(config);
    };
}

const authServiceInstance = createStandardAxiosConnection(ACCESS_HOST);
const icsInstance = createStandardAxiosConnection(ICS_HOST);
const accessControlServiceInstance = createStandardAxiosConnection(ACL_HOST);
const zoneServiceInstance = createStandardAxiosConnection(ZONE_SERVICE_HOST);
const importServiceInstance = createStandardAxiosConnection(IMPORT_SERVICE_HOST);
const deviceManagementServiceInstance = createStandardAxiosConnectionNoCache(DEVICE_MANAGEMENT_SERVICE_HOST, {timeout: 90000});

const icsMapInstance = createStandardAxiosConnection(ICS_HOST,{
    // timeout:180000 // 3 minutes
    timeout:  45 * 60000 // 3 minutes
});


let REPORT_SERVICE = reportServiceInstance(),
    AUTH_SERVICE = authServiceInstance(),
    ICS = icsInstance(),
    ICS_MAP = icsMapInstance(),
    ACL_SERVICE = accessControlServiceInstance(),
    ZONE_SERVICE = zoneServiceInstance(),
    IMPORT_SERVICE=importServiceInstance(),
    SITE_VIEW_SERVICE = siteViewServiceInstance(),
    DEVICE_MANAGEMENT_SERVICE = deviceManagementServiceInstance();


const ReportService = {
    instance:()=>{
        return REPORT_SERVICE;
    },
    renew:(username,token)=>{
        REPORT_SERVICE = reportServiceInstance(username,token);
    }
};

const AuthService = {
    instance:()=>{
        return AUTH_SERVICE;
    },
    renew:(token)=>{
        AUTH_SERVICE = authServiceInstance(token);
    }
};

const ICService = {
    instance:()=>{
        return ICS;
    },
    renew:(token)=>{
        ICS = icsInstance(token);
    }
};

const AccessControlService = {
    instance:()=>{
        return ACL_SERVICE;
    },
    renew:(token)=>{
        ACL_SERVICE = accessControlServiceInstance(token);
    }
};

const ICSMapService = {
    instance: ()=>{
        return ICS_MAP;
    },
    renew:(token)=>{
        ICS_MAP = icsMapInstance(token);
    }
};

const ZoneService = {
    instance:()=>{
        return ZONE_SERVICE;
    },
    renew:(token)=>{
        ZONE_SERVICE = zoneServiceInstance(token);
    }
};

const ImportService = {
    instance:()=>{
        return IMPORT_SERVICE;
    },
    renew:(token)=>{
        IMPORT_SERVICE = importServiceInstance(token);
    }
};

const SiteViewService = {
    instance:()=>{
        return SITE_VIEW_SERVICE;
    },
    renew:(username,token)=>{
        SITE_VIEW_SERVICE = siteViewServiceInstance(username,token);
    }
};

const DeviceManagementService = {
    instance:()=>{
        return DEVICE_MANAGEMENT_SERVICE;
    },
    renew:(token)=>{
        DEVICE_MANAGEMENT_SERVICE = deviceManagementServiceInstance(token);
    }
};

let TOKEN = null;

const getToken = ()=>{
    return TOKEN;
};


function renewServices({USERNAME,JWT_TOKEN}){
    TOKEN = JWT_TOKEN;
    ReportService.renew(USERNAME,JWT_TOKEN);
    AuthService.renew(JWT_TOKEN);
    ICService.renew(JWT_TOKEN);
    ICSMapService.renew(JWT_TOKEN);
    AccessControlService.renew(JWT_TOKEN);
    ZoneService.renew(JWT_TOKEN);
    ImportService.renew(JWT_TOKEN);
    SiteViewService.renew(USERNAME,JWT_TOKEN);
    DeviceManagementService.renew(JWT_TOKEN);
}

export {
    reportServiceInstance, // used for requesting the menu... (ReportService isn't updated in time.)
    ReportService,
    AuthService,
    ICService,
    AccessControlService,
    ICSMapService,
    ZoneService,
    ImportService,
    SiteViewService,
    DeviceManagementService,
    renewServices,
    getToken
};
