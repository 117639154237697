import React from 'react';
import {Paper} from "@material-ui/core";
import WrappedTable from "../../util/report/WrappedTable";
import ColumnTable from './Column.json';
import moment from 'moment/moment.js';
import ModalImportDetails from './ModalImportDetails';

class ImportResultsTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            //sorting initial params in Import Results table
            sortIndex: 4,//In the init sort the rows by 'Imported Date'
            sortDirection: -1,//In ascending order
            data: [],

            //show Import Details Modal
            showModal: false,
        }
        this.handleSelectRow = this.handleSelectRow.bind(this);
        this.handleSortByColumn = this.handleSortByColumn.bind(this);
        this.handleImportDetailsClose = this.handleImportDetailsClose.bind(this);
        this.compareRows = this.compareRows.bind(this);
    }
    
    UNSAFE_componentWillReceiveProps(nextProps) {
        const data = [...nextProps.data];
        data.sort(this.compareRows);
        this.setState({data});
    }

    //Warning: It depends of the order in ColumnTable
    compareRows (elem1, elem2) {
        const  {sortIndex, sortDirection} = this.state;
        const property = ColumnTable[sortIndex].name;
        let a = elem1[property], b = elem2[property];
        let result = 0;
        if (property.indexOf("Date") !== -1) {
            a = a ? moment(new Date(a)) : moment();
            b = b ? moment(new Date(b)) : moment();
            result = a.diff(b);
        } else {
            if ((typeof a === 'string') || (typeof b === 'string')) {
                if (!a) {
                    a = "";
                }
                if (!b) {
                    b = "";
                }
                result = a.localeCompare(b);
            } else {
                if (a === null && b === null) {
                    result = 0;
                } else if (a === null) {
                    result = -(b + 1);//This +1 put 0 values after null values in ascending order
                } else if (b === null) {
                    result = a + 1;//This +1 put 0 values before null values in descending order
                } else {
                    result = a - b;
                }
            }
        }
        return result * sortDirection;
    }

    handleSortByColumn (index, direction) {
        this.setState({
            sortIndex: index,
            sortDirection: direction
        }, () => {
            const data = [...this.state.data];
            data.sort(this.compareRows);
            this.setState({data : data});
        });
    }

    handleSelectRow(rowNumber){
        const {data} = this.state;
        if(data != null && data[rowNumber]) {
            const currentImport = data[rowNumber];
            if (currentImport != null) {
                this.props.getImportDetails(currentImport._id)
                    .then(result => {
                        this.setState({
                            showModal: true,
                            selectedImportData: result.data
                        });
                    });
            }
        }
    }

    handleImportDetailsClose() {
        this.setState({showModal: false});
    }

    render () {
        const {showModal, selectedImportData} = this.state;

        const {data, sortIndex, sortDirection} = this.state;
        const columns = ColumnTable;

        const backgroundErrorRows = data.map(item => {
            return item.rowsError;
        });

        const {isMobile} = this.props;
        const heightDiv = this.props.windowWidth < 700 ? 310 : this.props.windowWidth < 1100 ? 200 : 140;
        let minHeightWindows = this.props.windowHeight - heightDiv - 156;

        const actions = {};
        actions.selectAllRows = this.props.selectAllRows;
        actions.changeSort = this.handleSortByColumn;

        return (
            <div style={{position: "relative", float: "left", width: "100%"}} >
                <ModalImportDetails
                    data = {selectedImportData}
                    isMobile = {isMobile}
                    open = {showModal}
                    onRequestClose = {this.handleImportDetailsClose}
                />

                <div style={{ paddingBottom:  "1em", fontSize: "14px" }}>
                    <label htmlFor={"results"} className="label" style={{ paddingLeft: "1em" }}>
                        <b>Import Results</b>
                    </label>
                    <div className={"import-page"} >
                        <Paper className={"import-table-container"} style={{minHeight: (minHeightWindows < 165) ? '165px' : `${isMobile ? minHeightWindows + 10:minHeightWindows}px`}}>
                            {data && <WrappedTable
                                actions = {actions}
                                data = {data}
                                columns = {columns}
                                getRowHeight={this.getRowHeight}
                                isSelected={null}
                                maxInitialColumnSize={500}
                                initiaColumnSizeOffset={60}
                                adjustColumns
                                onSelectAll={null}
                                disableFillTable
                                disableFilters

                                sortIndex={sortIndex}
                                sortDirection={sortDirection}

                                backgroundRow={backgroundErrorRows}
                                onTouchTapRow={this.handleSelectRow}

                                sites={[]}//The component require this prop
                                maps={[]}//The component require this prop
                            />}
                        </Paper>
                    </div>
                </div>
            </div>
        );
    }
}

export default ImportResultsTable;