
//const {
//    STANDARD_GREY, MOTIONWORKS_BLUE, STANDARD_ORANGE
//} = require('../../constants/Colors');

//const RUNNING_COLOR = MOTIONWORKS_BLUE;
//const STOPPED_COLOR = STANDARD_GREY;
//const OFFLINE_COLOR = STANDARD_ORANGE;

module.exports = {
    type:"TABLE",
    reportLink:"/reports/C0FB7CAD-8C25-11D5-9066-00105A6AE011", // resources report
    title:'Resource Count by Type',
    dataProvider:{
        service:"report",
        url:"/dashboards/widgets/16/data"
    },
    columns:[
        {
            key:"resource_type",
            caption:"Resource Type",
            align:"left"
        },
        {
            key:"prev_quantity",
            caption:"Previous",
            align:'center'
        },
        {
            key:"quantity",
            caption:"Current",
            align:"center"
        },
        {
            key:"diff",
            caption:"+ / -",
            align:"center"
        }
    ],
    parameters:[
        {
            name:"fromTime", // in order to have the 'required' attribute work, it will check if 'fromTime' is included in query
            type:"from-to",
            label:"Time Range",
            fromName:"fromTime",
            toName:"toTime",
            required:true
        }
    ]
};
