import React from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';

import IconButton from '@material-ui/core/IconButton';


/******************************************************************************
███████ ███████  █████  ██████   ██████ ██   ██
██      ██      ██   ██ ██   ██ ██      ██   ██
███████ █████   ███████ ██████  ██      ███████
     ██ ██      ██   ██ ██   ██ ██      ██   ██
███████ ███████ ██   ██ ██   ██  ██████ ██   ██

██████  ██    ██ ████████ ████████  ██████  ███    ██
██   ██ ██    ██    ██       ██    ██    ██ ████   ██
██████  ██    ██    ██       ██    ██    ██ ██ ██  ██
██   ██ ██    ██    ██       ██    ██    ██ ██  ██ ██
██████   ██████     ██       ██     ██████  ██   ████
******************************************************************************/
const SearchButton = ({searchActive,toggleSearch})=>(
    <IconButton style={{
        height:'48px',
        width:'36px',
        padding:'4px 2px 4px 0',
        top:'-4px',
        right:'-4px',
        verticalAlign:'top',
        lineHeight:'36px',
        color: '#000',
        backgroundColor:(searchActive) ? "#fff" : "transparent",
        transition:'none'
    }}  onClick={toggleSearch} disableTouchRipple>
        <SearchIcon />
    </IconButton>
);

SearchButton.propTypes = {
    searchActive: PropTypes.bool,
    toggleSearch: PropTypes.func
};

export default SearchButton;
