import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {
    getReloadDeviceVersion,
    saveDeviceConfigurationVersion,
    unloadDeviceVersion
} from "../../../../actions";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import {localeTimestamp} from "../../../../utils/TimestampFormats";
import FileSaver from "file-saver";

const useStyles = makeStyles({
    paperDialog: {
        overflowY: "visible"
    }
});

const PublishedVersionDialog = ({nameDevice, open, closeDialog}) => {
    const classes = useStyles();
    const [configSelected, setConfigSelected] = useState(null);
    const versionHistory = useSelector(state => state.deviceManager.versionHistory);
    const dispatch = useDispatch();
    const {pathParam} = useParams();
    useEffect(() => {
        dispatch(getReloadDeviceVersion(pathParam));
        const unmount = () => {
            dispatch(unloadDeviceVersion())
        };
        return unmount();
    },[dispatch,pathParam]);

    const renderCloseButton = () => {
        return (
            <div style={{position:'absolute',top:'-0.5em',right:'-0.5em'}}>
                <IconButton aria-label="close"
                    size="small"
                    onClick={closeDialog}
                    style={{cursor:'pointer',position:'relative',zIndex:'100',backgroundColor:'#000'}}
                    >
                    <CloseIcon style={{color: '#fff'}} />
                </IconButton>
            </div>
        );
    }
    const downloadConfig = (configurationSelect,type)=>{
        const blob = new Blob([JSON.stringify(configurationSelect.rfidR2CReaders)], {type: `text/${type};charset='utf-8'`});
        const fileName =  configurationSelect?.name+'.'+type;
        FileSaver.saveAs(blob, fileName);
    }
    const chooseVersion = (configurationSelect)=>{
        dispatch(saveDeviceConfigurationVersion(pathParam,configurationSelect));
        closeDialog();
    }
    const linkStyle = {color:'#0000ee', cursor:'pointer'};
    const renderTable = () => {
        return(
            <table className={'dialog-table'}>
                <thead className={'dialog-table-header'}>
                    <tr>
                        <th className={'dialog-table-header-cell'}>Last Updated</th>
                        <th className={'dialog-table-header-cell'}>Version</th>
                        <th className={'dialog-table-header-cell'}></th>
                    </tr>
                </thead>
                <tbody>
                    { versionHistory?.configuration? versionHistory.configuration.map((item, index) => <tr key={index} style={{backgroundColor: (index%2===0)?'#fff':'#d8d8d8'}}>
                        <td className={'dialog-table-cell'}>{localeTimestamp(new Date(item.dateCreated))}</td>
                        <td className={'dialog-table-cell'}>{item.version}</td>
                        <td className={'dialog-table-cell'}>
                            <div style={{width:'100%',display: 'flex', flexDirection:'row',justifyContent:'space-between'}}>
                                <span style={linkStyle} onClick={()=>setConfigSelected(item)}>View config.</span>
                                <span style={linkStyle} onClick={()=>downloadConfig(item,'txt')}>Download config.</span>
                                <button style={{backgroundColor:'#4c4c4c',color:'#fff',border:'none',textTransform: 'none'}}
                                    onClick={()=>chooseVersion(item)}>Choose</button>
                            </div>
                        </td>
                    </tr>):<tr><td colSpan={3} className={'dialog-table-cell'}>No Results</td></tr>}
                </tbody>
            </table>
        )
    }
    const styleTitleDialog = {paddingBottom:0, fontSize:'1.2em', fontWeight: 'bold', whiteSpace:'nowrap', overflow:'hidden', textOverflow: 'ellipsis'};

    return (
        <Dialog
            fullWidth
            maxWidth={'sm'}
            open={open}
            onClose={closeDialog}
            classes={{paper:classes.paperDialog}}>
            <DialogTitle style={styleTitleDialog} disableTypography>
                {(configSelected===null)? `Published Version History - ${nameDevice}`:`Configuration ${configSelected?.name} - `}
            </DialogTitle>
            {renderCloseButton()}
            <DialogContent style={{fontSize:'0.9em',display:'flex',flexDirection:'column',maxHeight:'100%'}}>
                {(configSelected===null)?
                <div style={{marginTop: '10px', flex:'1 1 200px',width:'100%',backgroundColor:'#fff'}}>                    
                    {renderTable()}                   
                </div>:
                <div style={{backgroundColor:'#dadada',border:'solid 1px',overflow:'auto',maxHeight:window.innerHeight*0.7}}>
                    <span>{(configSelected===null)? `[XML configuration file]`:
                        <pre style={{margin:'0',overflow:'visible'}}>
                                {JSON.stringify(configSelected.rfidR2CReaders,null,'    ')}
                        </pre>
                    }
                    </span>
                </div>}
            </DialogContent> 
            <DialogActions style={{padding:'0 1em', paddingBottom:'0.5em'}}>
                {(configSelected===null)?
                    <Button style={{background: '#007CB0',color: '#fff', padding: '0.5em',textTransform:'none'}} 
                            onClick={closeDialog} component="span">
                        Close
                    </Button>:
                    <div style={{display:'flex',width:'100%',justifyContent:'space-between'}}>
                        <div style={{display:'flex', justifyContent:'flex-start'}}>
                        <Button style={{color: '#007CB0',background: '#fff', padding: '0.5em',textTransform:'none'}} 
                                onClick={()=>setConfigSelected(null)} component="span">
                            &#60;&nbsp; Back
                        </Button>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end'}}>
                            <Button style={{background: '#fff',color: '#007CB0', padding: '0.5em',textTransform:'none'}} component="span"
                                    onClick={()=>downloadConfig(configSelected,'txt')}>
                                Download config.
                            </Button>
                            <Button style={{background: '#007CB0',color: '#fff', padding: '0.5em',textTransform:'none'}} component="span"
                                    onClick={()=>chooseVersion(configSelected)}
                            >
                                Choose
                            </Button>
                        </div>
                    </div>
                }
            </DialogActions>               
        </Dialog>
    );
}

export default PublishedVersionDialog;