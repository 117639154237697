import React from 'react';
import PropTypes from 'prop-types';
import HomePage from './Home';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../../actions';

class ReportsPage extends React.Component{
    static get propTypes(){
        return {
            match: PropTypes.object,
            requestReport: PropTypes.func.isRequired
        };
    }
    requestReport(id){
        this.props.requestReport(id);
    }
    componentDidMount(){
        this.props.getZoomFeature();
        this.requestReport(this.props.match.params.reportId);
    }
    UNSAFE_componentWillReceiveProps(nextProps){
        if(this.props.match.params.reportId !== nextProps.match.params.reportId){
            this.requestReport(nextProps.match.params.reportId);
        }
    }
    render(){
        return <HomePage {...this.props} />;
    }
}

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        requestReport:actions.requestReport,
        getZoomFeature: actions.getZoomFeature
    },dispatch)
});

const mapStateToProps = (state) => ({
    columnOrder: state.report.columnOrder[state.report.reportId] || [],
    sidebarColumnOrder: state.report.sidebarColumnOrder[state.report.reportId] || [],
    feature: state.user.feature
});

export default connect(mapStateToProps,mapDispatchToProps)(ReportsPage);
