import React, {useRef} from 'react';
import StyledDialog from "../../util/Dialog";
import Button from "@material-ui/core/Button";
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';



const AlertMessage = ({openModal, message, onClose, text2, text3, isError, isWarning}) => {

    const associateAnchor = useRef();

    const actionButtons = (
        <div className='dialog-buttons'>
            <Button className={'alert-close-button'} onClick={onClose} style={{left: 8}}>Close</Button>
        </div>
    );

    return (
        <StyledDialog
            modal={true}
            open={openModal}
            onClose={onClose}
            maxWidth={false}
            contentStyle={{height: 'auto'}}
            actions={actionButtons}
            bodyStyle={{overflowY:'auto',minHeight:'inherit',height:'100%'}}
            title={isError ? "Error" : (isWarning ? "Warning" : "")}
        >
            <form ref={associateAnchor}>
                <div style={{margin:'1em auto'}}>
                    <div className={"label"} style={{display: 'flex'}}>
                        {isError ? <span><ErrorIcon color='error' />&nbsp;&nbsp;&nbsp;</span> : null}
                        {isWarning ? <span><WarningIcon htmlColor='#f7b32f' />&nbsp;&nbsp;&nbsp;</span>: null}
                        <span style={{lineHeight: '28px'}}>{message}</span>
                    </div>
                </div>
                <br/>
                {text2 &&<div className={"label"}>
                    {text2}
                </div>}
                {text3 &&<div className={"label"}>
                    {text3}
                </div>}
            </form>

        </StyledDialog>
    )
};

export default AlertMessage;