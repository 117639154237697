import React from 'react';
import { Autorenew as GPO, Assignment as Identity, Cast as Antennas, Style as Type, 
    DeveloperMode as Operation, DeviceHub as DeviceHubIcon} from '@material-ui/icons';
    
import LinkIcon from '@material-ui/icons/Link';
const items = [
    {
        id: "type",
        name: "Type",
        displayName: "Device Type",
        icon: <Type />
    },
    {
        id: "identity",
        name: "Identity",
        displayName: "Identity",
        icon: <Identity />
    },
    {
        id: "protocol",
        name: "Protocol",
        displayName: "Protocol",
        icon: <LinkIcon />
    },
    /*{
        id: "dataURL",
        name: "Data URL",
        displayName: "Data URL",
        icon: <LinkIcon />
    },*/

    {
        id: "operation",
        name: "Operation",
        displayName: "Operation",
        icon: <Operation />
    }, 
   {
        id: "rfidAntennas",
        name: "Antennas",
        displayName: "Antennas",
        icon: <Antennas />
   }, 
   {
        id: "triggers",
        name: "Triggers",
        displayName: "Trigger settings",
        icon: <Operation />
   },
   {
        id:'gpo',
        name: "GPO",
        displayName: "GPO (General Purpose Output) Programming",
        icon: <GPO />
   },
   {
        id: "linkedDevices",
        name: "Linked Devices",
        displayName: "Linked Devices",
        icon: <DeviceHubIcon />
   }
];
export default items;