import React, {Fragment} from "react";
import DeviceStatus from "../common/DeviceStatus";
import {MemoizedAntennasStatus} from "../common/AntennasStatus";
import EditMenu from "./EditMenu";
import FirmwareVersion from "../common/FirmwareVersion";
import CustomCellSiteMap from "./CustomCellSiteMap";
import CustomCellLogsHistory from '../common/CustomCellLogsHistory';
import CustomLinkLogsHistory from '../common/CustomLinkLogsHistory';
import AlertStatus from "../common/AlertStatus";
import CustomCellLogLevel from "../common/CustomCellLogLevel";
import CustomLinkLogsDownload from "../common/CustomLinkLogsDownload";

const CustomCell = (props) => {
    const {tableHeight, rowIndex, content, headerKey } = props;
    switch (headerKey) {
        case "status":
            return <DeviceStatus status={content} rowIndex={rowIndex} />
        // case "Identity":
        //     return <div></div>
        case "rfidAntennas":
            return <MemoizedAntennasStatus antennas={content}/>
        case "actions":
            return <EditMenu rowIndex={rowIndex} tableHeight={tableHeight} />
        case "firmwareVersion":
            return <FirmwareVersion rowIndex={rowIndex} firmwareVersion={content}/>;
        case "logData.filename":
            return <CustomCellLogsHistory itemKey={headerKey} rowIndex={rowIndex} />;
        case "logData.binary":
            return <CustomLinkLogsHistory itemKey={headerKey} rowIndex={rowIndex}/>;
        // case "siteId":
        case "location":
            return <CustomCellSiteMap value={content} typeColum={headerKey} rowIndex={rowIndex}/>;
        case "alert":
            return <AlertStatus rowIndex={rowIndex} value={content} />
        case "logLevel":
            return <CustomCellLogLevel itemKey={headerKey} rowIndex={rowIndex}/>;
        case "log.download":
            return <CustomLinkLogsDownload itemKey={headerKey} rowIndex={rowIndex}/>;
        default:
            return <Fragment>{content}</Fragment>;

    }
};
export default CustomCell;