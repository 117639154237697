import React from 'react';
import PropTypes from 'prop-types';

import PinIcon from '../../icons/PinIcon';

import AddSitesFormMap from './AddSitesFormMap';

import StyledDialog from '../../util/Dialog';
import Grid from '@material-ui/core/Grid';

import SiteMapThumbnailOverlay from '../../util/SiteMapThumbnailOverlay';

import Button from '@material-ui/core/Button';
import axios from 'axios';

import {MAP_VIEW_URL, SITE_VIEW_HOST} from '../../../constants/Misc';

import MultiMapManager from './floormaps/MultiMapManager';

/*
 █████  ██████  ██████  ██████  ███████ ███████ ███████
██   ██ ██   ██ ██   ██ ██   ██ ██      ██      ██
███████ ██   ██ ██   ██ ██████  █████   ███████ ███████
██   ██ ██   ██ ██   ██ ██   ██ ██           ██      ██
██   ██ ██████  ██████  ██   ██ ███████ ███████ ███████

██       ██████   ██████  ██   ██ ██    ██ ██████
██      ██    ██ ██    ██ ██  ██  ██    ██ ██   ██
██      ██    ██ ██    ██ █████   ██    ██ ██████
██      ██    ██ ██    ██ ██  ██  ██    ██ ██
███████  ██████   ██████  ██   ██  ██████  ██
*/



function getLocationFromStreetAddress(address,callback=()=>{}){
    axios.get("https://nominatim.openstreetmap.org/search",{
        params:{
            q:address,
            format:'json'
        }
    })
         .then(response => {
            if(response.data.length > 0){
                callback(null,response.data[0]);
                return;
            }
             callback("Not found.");
         });
}

/*
███████  ██████  ██████  ███    ███
██      ██    ██ ██   ██ ████  ████
█████   ██    ██ ██████  ██ ████ ██
██      ██    ██ ██   ██ ██  ██  ██
██       ██████  ██   ██ ██      ██
*/


class AddSiteForm extends React.Component{

    constructor(props){
        super(props);

        let loc = this.getLocation(props);

        this.state={
            movingPin: false,
            location: loc,
            floorMaps:[],
            latLong:props.latLong || [],
            name:props.name || "",
            zoomOnChange: true,
            resizeTrigger: (new Date()).getTime()
        };

        this.handleMouseUp = this.handleMouseUp.bind(this);

        this.handlePinDrop = this.handlePinDrop.bind(this);
        this.handleLocationChange = this.handleLocationChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);

        this.handleNameChange = this.handleNameChange.bind(this);
        
        this.handleSave = this.handleSave.bind(this);
    }

    static get propTypes(){
        return {
            name: PropTypes.string,
            id: PropTypes.any,
            location: PropTypes.string,
            latLong: PropTypes.array,
            onSave: PropTypes.func,
            siteGroups: PropTypes.array.isRequired,
            siteGroupId: PropTypes.string,
            update: PropTypes.bool,
            maps: PropTypes.array,
            actions: PropTypes.object.isRequired,
            onRequestClose: PropTypes.func,
            open: PropTypes.bool
        };
    }

    static get defaultProps(){
        return {
            onSave: function(){},
            siteGroupId: null,
            update: false,
            maps:[]
        };
    }

    getLocation(props){
        return props.location || (props.latLong && props.latLong.map(v=>(Math.round(v*100000)/100000)).join(", ")) || "";
    }

    UNSAFE_componentWillReceiveProps(nextProps){

        let loc = this.getLocation(nextProps);

        // only update state when opening the form.
        if(!this.props.open && nextProps.open){
            this.setState({
                location: loc,
                latLong:nextProps.latLong || [],
                zoomOnChange: true,
                name:nextProps.name || "",
            });
        }

    }

    componentDidMount(){
        document.addEventListener('mouseup',this.handleMouseUp);
        this.setState({
            resizeTrigger: (new Date()).getTime()
        });
    }
    componentWillUnmount(){
        document.removeEventListener('mouseup',this.handleMouseUp);
    }

    handleNameChange(e){
        const name = e.currentTarget.value;
        if(name.length<=50){
            this.setState({
                name
            });
        }
    }

    handleMouseUp(){
        this.setState({
            movingPin:false
        });
    }

    handlePinDrop(latlong){

        const ll = latlong.map(v=>(Math.round(v*100000)/100000));

        this.setState({
            location: ll.join(", "),
            latLong:latlong,
            zoomOnChange: false
        });
    }

    handleLocationChange(e){
        const loc = e.currentTarget.value;

        this.setState({
            location: loc,
            latLong: (loc === "") ? [] : this.state.latLong
        });
    }

    handleLocationSearch(e){
        if(!e.currentTarget.value.trim()) return;
        getLocationFromStreetAddress(e.target.value,(err,data)=>{
            if(err){
                window.alert(err); // not advisable...
                return;
            }
            this.setState({
                latLong:[parseFloat(data.lat),parseFloat(data.lon)],
                zoomOnChange: true
            });
        });
    }

    handleKeyPress(e){
        if(e.which === 13){ // enter key
            this.handleLocationSearch(e);
        }
    } 
    handleFloorMapChange(floorMaps){
        this.setState({
            floorMaps
        });
    }

    handleSave(){

        const {name,location,latLong} = this.state;

        const siteGroupId = this.refs.site_group_select.value;

        this.props.onSave({
            name,
            location,
            lat: latLong[0] || null,
            long: latLong[1] || null,
            siteGroupId: (siteGroupId === "Default") ? null : siteGroupId
        });
    }

    renderForm(){

        const {siteGroups,siteGroupId,id,upload_status} = this.props;

        const site_name = (
            <div style={{display:'table-cell'}}>
                <div className="label">Site Name</div>
                <div className="field"><input value={this.state.name} onChange={this.handleNameChange}  /></div>
            </div>
        );

        const site_location = (
            <div style={{display:'table-cell'}}>
                <div className="label">Location</div>
                <div className="field" style={{paddingRight:'3em',position:'relative'}}>
                    <div>
                        <input
                            placeholder={"Address, Lat Long, or Drag pin to map"}
                            onChange={this.handleLocationChange}
                            onKeyPress={this.handleKeyPress}
                            value={this.state.location}/>
                    </div>
                    <div style={{
                        position:'absolute',
                        top:0,
                        bottom:0,
                        right:0
                    }}>
                        <button
                            style={{padding:'0.75em',outline:'none', border:'none',background:'transparent',margin:0}}
                            onMouseDown={()=>{
                                this.setState({movingPin:true});
                            }}
                            >
                            <PinIcon
                                color="#269bc5"
                                borderColor="#2084a8"/>
                        </button>
                    </div>
                </div>
            </div>
        );

        const site_group_options = siteGroups.map((v,i)=>{
            return (
                <option key={i} label={v.name} value={v.id}>{v.name}</option>
            );
        });

        const site_group = (
            <div style={{display:'table-cell'}}>
                <div className="label">Site Group</div>
                <div className="field">
                    <select ref={"site_group_select"} defaultValue={siteGroupId}>
                        {site_group_options}
                    </select>
                </div>
            </div>
        );

        const site_maps = (
            <div style={{display:'table-cell',position:'relative'}}>
                <div className="field">
                    <MultiMapManager
                        upload_status={upload_status}
                        siteId={id}
                        maps={this.props.maps}
                        actions={this.props.actions}
                    />
                </div>
            </div>
        );


        const updateFields = this.props.update ? (
            <div>
                {site_maps}
            </div>
        ) : null;

        return (
            <div className="add-site-form" style={{width: '94%'}}>
                <div>
                    {site_name}
                </div>
                <div>
                    {site_location}
                </div>
                <div>
                    {site_group}
                </div>
                {updateFields}
            </div>
        );
    }

    render(){
        const {onRequestClose} = this.props;

        const action_buttons = (
            <div className="dialog-buttons" style={{position: 'absolute', bottom: 42}}>
                <Button key={"cancel-button"} onClick={onRequestClose} style={{position: 'absolute', left: 0}}>Cancel</Button>
                <Button key={"save-button"} style={{color:'#00779F', position: 'absolute', left: 270}} onClick={this.handleSave}>Save</Button>
            </div>
        );

        const actionsContainerStyles={
            width:352+2*24+'px'
        };

        return (
            <StyledDialog
                title="Add Site"
                {...this.props}
                contentClassName="add-site-dialog"
                actionsContainerStyle={actionsContainerStyles}
                disableTinyX
                actions={false}
                style={{height:'630px', minWidth: 1000}}
                contentStyle={{paddingRight: 0}}
                maxWidth={'md'}
                fullWidth
                onRequestClose={onRequestClose}
                onClose={onRequestClose}
            >

                <div style={{minWidth: '900px', height:400, maxWidth: 'auto' }}>
                    <Grid container spacing={0} direction="row" style={{height: '100%'}}>
                        <Grid item xs={5} style={{display: 'flex',flexDirection: 'column', maxHeight: "350px", overflowY: "auto"}}>
                            {this.renderForm()}
                            {action_buttons}
                        </Grid>
                        <Grid item xs={7} style={{position: 'absolute', top: 0, right: 0, width: 540}}>
                            <AddSitesFormMap
                                resizeTrigger={this.state.resizeTrigger}
                                movingPin={this.state.movingPin}
                                onPinDrop={this.handlePinDrop}
                                pinLatLong={this.state.latLong}
                                zoomOnChange={this.state.zoomOnChange}
                            />
                            <SiteMapThumbnailOverlay
                                siteManager={true}
                                timestamp={new Date().getTime()}
                                maps={this.props.maps.map(m=>({
                                name: m.description,
                                src: SITE_VIEW_HOST+MAP_VIEW_URL+"/maps/"+m._id+"/tiles/0_0_0"
                            }))}/>
                        </Grid>
                    </Grid>

                    </div>
            </StyledDialog>
        );
    }
}

export default AddSiteForm;
