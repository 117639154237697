import React from 'react';
import PropTypes from 'prop-types';

import StyledDialog, {CancelButton, SaveButton} from '../../util/Dialog';

import {DataEntryTabularField, DataEntryTabularForm} from '../../util/DataEntryTabularForm';
import TextFileInput from './TextFileInput';


class UpdateApplianceDialog extends React.Component {

    constructor(){
        super();

        this.handleSave = this.handleSave.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    static get propTypes(){
        return {
            open: PropTypes.bool,
            actions: PropTypes.object.isRequired,
            data: PropTypes.object
        };
    }

    static get defaultProps(){
        return {
            open: false,
            data:{
                appliances:[]
            }
        };
    }

    handleClose ()  {
        this.props.actions.closeDataEntry();
    }

    handleSave(){
        var obj = {};
        obj.name = this.refs.name.value;
        obj.uuid = this.props.data.appliances[0].uuid;
        obj.publicKey = this.refs.publicKey.getContent();
        obj.siteId = this.refs.site.value;

        if(!obj.publicKey) delete obj.publicKey; // don't include it if it's falsy.

        if(obj.siteId === "null") obj.siteId = null;

        this.props.actions.updateAppliance(obj);
    }

    renderForm(){

        const _data = this.props.data.appliances;
        if(!_data) return null;

        const data = _data[0] || {}; // only consider the first appliance.

        let sites = Object.keys(this.props.data.sites).map(k=>this.props.data.sites[k]).filter(s=>!!s).sort((a,b)=>{
            if(a.name < b.name) return -1;
            else if(a.name > b.name) return 1;
            return 0;
        });

        //MWE-1073: Don't show (Unassigned) if the appliance is assigned.  
        // This is a workaround, so that you can't change set an appliance's
        // site to (Unassinged) if it's already assigned to a site.  
        // The PUT /appliances API lets you change the site in that way.
        if (!data.siteId)
        {
            sites = [{_id:"null",name:"(Unassigned)"}].concat(sites);
        }

        return (
            <DataEntryTabularForm fullWidth>

                <DataEntryTabularField label={"Appliance Name"}>
                    <input type={"text"} ref="name" defaultValue={data.name}/>
                </DataEntryTabularField>

                <DataEntryTabularField label={"UUID"}>
                    {data.uuid}
                </DataEntryTabularField>

                <DataEntryTabularField label={"Files"}>
                    <TextFileInput ref="publicKey" label="upload pub key file" accept={".pub"} onError={(msg)=>{this.props.actions.displayErrorDialog({message:msg});}}/>
                </DataEntryTabularField>

                <DataEntryTabularField label={"Site"}>
                    <select ref="site" defaultValue={data.siteId}>
                        {sites.map((s,i) => (
                            <option key={i} value={s._id}>{s.name}</option>
                        ))}
                    </select>
                </DataEntryTabularField>

            </DataEntryTabularForm>
        );
    }

    render() {

        const {
            actions, // eslint-disable-line
            ...other
        } = this.props;

        return (
            <StyledDialog
                title="Update Appliance"
                modal={false}
                open={this.props.open}
                onClose={this.handleClose}
                contentStyle={{maxWidth: 360}}
                actions={
                    <>
                        <SaveButton onClick={this.handleSave} />
                        <CancelButton style={{position: 'absolute', left: 8, bottom: 8}} onClick={this.handleClose} />
                    </>
                }
                {...other}
                >
                {this.renderForm()}
            </StyledDialog>
        );
    }
}

export default UpdateApplianceDialog;
