import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/Icon';
import Maximizer from './Maximizer';
import {DialogActions, DialogTitle} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {DialogContent} from '@material-ui/core/es';
import Fade from "@material-ui/core/Fade/Fade";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} timeout={100}/>;
});

class StyledDialog extends React.Component{


    static get propTypes(){
        return {
            onRequestClose: PropTypes.func,
            children: PropTypes.node,
            fullHeight: PropTypes.bool,
            title: PropTypes.any,
            actions: PropTypes.any,
            bodyStyle: PropTypes.object,
            contentStyle: PropTypes.object,
            disableTinyX: PropTypes.bool,
            className: PropTypes.string,
        };
    }

    renderCloseButton(){
        return (
            <div style={{position:'absolute',top:'1em',right:'1em',color:'#777'}}>
                <IconButton aria-label="close"
                    onClick={this.props.onClose}
                    style={{cursor:'pointer',position:'relative',zIndex:'100'}}
                    >
                    <CloseIcon />
                </IconButton>
            </div>
        );
    }

    render(){

        const { fullHeight,
                disableTinyX,
                bodyStyle,
                title,
                children, 
                actions, 
                contentStyle,
                open,
                onClose,
                style,
                className } = this.props;
        
        let maxWidth = false;
        if (this.props.maxWidth || this.props.maxWidth === false) maxWidth = this.props.maxWidth;
        const content = (fullHeight) ? (
            <Maximizer style={{padding:'0 24px 24px',boxSizing:'border-box', overflowY: 'auto',maxWidth:'600px', ...bodyStyle}} hasTitle={!!title} hasActions={!!React.Children.count(actions)}>
                {children}
            </Maximizer>
        ) : children;

        return (
                <Dialog
                    open={open}
                    onClose={onClose}
                    maxWidth={maxWidth}
                    TransitionComponent={Transition}
                    style={style}
                    PaperProps={{style: {overflowY: 'visible'}}}
                    className={className}
                >   
                    {disableTinyX ? null : this.renderCloseButton()}
                    <DialogTitle style={{paddingRight: 50}}>
                        <div style={{fontWeight:'300',fontSize:'0.9em'}}>
                            {title}
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <div style={{color: '#0009', minHeight: 40, ...contentStyle}}>
                            {content}
                        </div>
                    </DialogContent>
                    {actions && <DialogActions className='dialog-buttons'>
                        {actions}
                    </DialogActions>}
                </Dialog>
        );
    }
}

export default StyledDialog;



class DefaultSaveButton extends React.Component{
    static get propTypes(){
        return {
            disabled: PropTypes.bool
        };
    }

    render(){
        const {disabled} = this.props;

        return (
            <Button
                label="Save"
                style={{
                    color: (disabled) ? "#aaa" : '#00779F'
                }}
                {...this.props}
            >{this.props.label?this.props.label:'Save'}</Button>
        );
    }
}


class DefaultCancelButton extends React.Component{
    render(){
        return (
            <Button label="Cancel" style={{float:'left'}} {...this.props}>Cancel</Button>
        );
    }
}

export {
    DefaultSaveButton as SaveButton,
    DefaultCancelButton as CancelButton
};
