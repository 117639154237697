import React from 'react';
import PropTypes from 'prop-types';


class HistoryTable extends React.Component{

    static get propTypes(){
        return {
            history: PropTypes.array,
            taskRenderer: PropTypes.func
        };
    }

    shouldComponentUpdate(nextProps){
        return (this.props.history !== nextProps.history);
    }

    render(){
        const {
            history,
            taskRenderer
        } = this.props;

        const Task = taskRenderer;

        function lastexecutedfirst(a,b){
            var a_exe = a.timeToExecute || a.timeCreated;
            var b_exe = b.timeToExecute || b.timeCreated;
            if(a_exe > b_exe) return -1;
            else if(b_exe > a_exe) return 1;

            return 0;
        }

        if(history.length === 0){
            return (
                <div style={{textAlign:'center',border:'solid #aaa',borderWidth:'1px 0',padding:'0.5em 0'}} key={2}>
                    No History.
                </div>
            );
        }else{
            return (
                <table style={{width:'100%'}} key={2}>
                    <tbody>
                        {history.sort(lastexecutedfirst).map((v,i)=>(
                            <tr key={v._id}>
                                <Task data={v} />
                            </tr>
                        ))}
                    </tbody>
                </table>
            );
        }
    }
}

export default HistoryTable;
