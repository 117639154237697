import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles({
    checked: {
        color: "#007CB0 !important",
    },
    rootChecked: {
        color: '#000', paddingTop: 3, paddingBottom: 3
    },
    tooltip:{
        color:'#000',
        backgroundColor:'#e6ee31',
        fontSize:'14px'
    },
    arrow:{
        color: '#e6ee31'
    },popper:{
        zIndex: 0
    }
});


export const downloadDeviceFileLogs = ({binary, filename}) => {

    let byteString = window.atob(binary);

    let ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    let file = new Blob([ia], {type: 'application/gzip'});
    if (window.navigator.msSaveOrOpenBlob) // IE10+
        window.navigator.msSaveOrOpenBlob(file, filename);
    else { // Others
        var a = document.createElement("a"),
            url = URL.createObjectURL(file);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }, 0);
    }
};