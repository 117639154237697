import React from 'react';
import {PERIOD} from "../../../../util/report/filter/util";


const NextPrevButtonsDialog = ({nameFilter,fromFilter,toFilter,reportId,filters,onChangeDatePredefinedTimeLast, deviceId, onChangeDateAndData}) => {
    const NEXT = 1;
    const PREV = 0;
    const handleGetNextRange = () => {
        eventsDateTimer(NEXT);
    };

    const handleGetPrevRange = () => {
        eventsDateTimer(PREV);
    };

    const eventsDateTimer = (TYPE) => {
        const dateTimeFilters = filters.datetimeFilters[reportId];
        const fromId = fromFilter.id;
        const toId = toFilter.id;

        let valueFromTo = PERIOD.TODAY;
        if( dateTimeFilters && dateTimeFilters[nameFilter]){
            const blinkTime = dateTimeFilters[nameFilter];
            valueFromTo = blinkTime.value;
        }else{
            onChangeDatePredefinedTimeLast(nameFilter,valueFromTo,fromId,toId);
        }
        const valueFrom = filters[fromId].value;
        const valueTo = filters[toId].value;
        const newDateTimeFrom = valueFrom?new Date(valueFrom):new Date();
        const newDateTimeTo = valueTo?new Date(valueTo):new Date();
        const differenceInTime = newDateTimeTo.getTime() - newDateTimeFrom.getTime();
        let differenceInDays = (differenceInTime / (1000 * 3600 * 24));
        differenceInDays = differenceInDays<1?1:valueFrom===PERIOD.TODAY?differenceInDays-1:differenceInDays;
        switch (valueFromTo) {
            case PERIOD.TODAY:
            case PERIOD.YESTERDAY:
            case PERIOD.LAST_WEEK:
            case PERIOD.CUSTOM:
                if (TYPE === NEXT) {
                    newDateTimeFrom.setDate(newDateTimeFrom.getDate() + differenceInDays);
                    newDateTimeTo.setDate(newDateTimeTo.getDate() + differenceInDays);
                } else {
                    newDateTimeFrom.setDate(newDateTimeFrom.getDate() - differenceInDays);
                    newDateTimeTo.setDate(newDateTimeTo.getDate() - differenceInDays);
                }
                break;
            default:
                break;
        }

        /*dispatch(changeDateFilterStatusHistory(fromId, newDateTimeFrom.toISOString(),toId, newDateTimeTo.toISOString(), deviceId));
        dispatch(getDeviceStatusHistory(deviceId, newDateTimeFrom.toISOString(), newDateTimeTo.toISOString(), fromId,toId));*/

        onChangeDateAndData(deviceId, newDateTimeFrom.toISOString(), newDateTimeTo.toISOString(), fromId,toId);

    };

    const styleNextPrev = {
        fontSize:'0.9em',
        width:'calc(100% - 6px)',
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between',
        marginLeft: '10px'
    };

    const styleButton ={
        width:'45%',
        backgroundColor: '#007cb0',
        color: '#fff',
        //margin:'3px 0px',
        height:'30px',
        border:'0',
        borderRadius: '3px'
    };

    return(
        <div style={styleNextPrev}>
            <button onClick={handleGetPrevRange} style={styleButton}  >{`<< Prev`}</button>
            <button onClick={handleGetNextRange} style={styleButton}  >{`Next >>`}</button>
        </div>
    )
};

export default NextPrevButtonsDialog;